// project imports

import { Box } from '@mui/system';

import MainCard from 'ui-component/cards/MainCard';

import ReportsForm from 'ui-component/reports/ReportsForm';
import 'ui-component/font/fonts';
// ==============================|| SAMPLE PAGE ||============================== //

const Report = () => {
    return (
        <MainCard>
            <Box sx={{ border: 'none', background: 'transparent' }}>
                <ReportsForm />
            </Box>
        </MainCard>
    );
};

export default Report;
