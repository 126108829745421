import { useState, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui

import { Button, Grid, MenuItem, Skeleton, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

// project imports

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

import { Stack } from '@mui/system';

import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';

import ReactApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';
import ReportModal from 'ui-component/modals/ReportModal';
import ReportLoadModal from 'ui-component/modals/ReportLoadModal';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const ReportsForm = () => {
    const [driverList, setDriverList] = useState([]);
    const [driver, setDriver] = useState('');

    const [truck, setTruck] = useState(0);

    const [loading, setLoading] = useState(false);
    const [loadingSf, setLoadingSf] = useState(false);

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [daysFilter, setDaysFilter] = useState(1825);
    const [daysFilterLoad, setDaysFilterLoad] = useState(1825);
    const [driverSaftey, setDriverSaftey] = useState(0);
    const [truckSaftey, setTruckSaftey] = useState(0);
    const [overSaftey, setOverSaftey] = useState(0);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cId = loginInfoFromStorage.company_id;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpenModal = () => {
        setOpen(true);
    };

    const handleLoadClose = () => {
        setOpen1(false);
    };
    const handleClickOpenLoad = () => {
        setOpen1(true);
    };

    const FaqSchema = Yup.object().shape({});

    useEffect(() => {
        fetchDriverList();
    }, []);

    useEffect(() => {
        // if (driverList.length === 0) {
        // fetchDriverList();
        // // }
        if (driverList.length > 0) {
            fetchData();
            fetchLoadData();
            fetchOvrSafteyData();
        }
        if (driverList.length > 0 && driver !== '') {
            fetchSafteyData(driver);
        }
    }, [driverList, driver, daysFilter, daysFilterLoad]);

    const fetchDriverList = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100&search=`,
                config
            );
            setDriverList(data.results);

            setLoading(false);
        } catch (error) {
            setLoading(false);
           
        }
    };

    const [chartOptions, setChartOptions] = useState({
        chart: {
            id: 'bar-chart',
            zoom: false,
            menu: false,
            toolbar: {
                show: false // Disable the toolbar/menu options
            }
        },
        xaxis: {
            categories: []
        }
    });

    const [chartSeries, setChartSeries] = useState([
        {
            name: '',
            data: []
        }
    ]);

    const [chartLineOptions, setChartLineOptions] = useState({
        chart: {
            id: 'area-chart',
            zoom: false,
            menu: false,
            toolbar: {
                show: false // Disable the toolbar/menu options
            }
        },
        xaxis: {
            categories: []
        }
    });

    const [chartLineSeries, setChartLineSeries] = useState([
        {
            name: '',
            data: []
        }
    ]);

    const fetchSafteyData = async (driver) => {
        setLoadingSf(true);
        try {
            const response = await axios.get(`/v1/api/fleet/safety/report/${cId}?uid=${driver}&days=${daysFilter}`, config);

            setDriverSaftey(response.data.drv_safe);
            setTruckSaftey(response.data.trk_safe);
            setOverSaftey(response.data.ovr_safe);
           
            setLoadingSf(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingSf(false);
        }
    };

    const fetchOvrSafteyData = async (driver) => {
        setLoadingSf(true);
        try {
            const response = await axios.get(`/v1/api/fleet/safety/report/ovr/${cId}/`, config);

            setOverSaftey(response.data.ovr_safe);
          
            setLoadingSf(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingSf(false);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`/v1/api/dispatch/revenue/report/${cId}?days=${daysFilter}`, config);
            const data = response.data;

            const dates = data.map((item) => item.date);
            const revenues = data.map((item) => item.revenue);

            setChartOptions({
                ...chartOptions,
                xaxis: {
                    categories: dates
                }
            });

            // Update chart series
            setChartSeries([
                {
                    name: 'Revenue',
                    data: revenues
                }
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchLoadData = async () => {
        try {
            const response = await axios.get(`/v1/api/dispatch/load/report/${cId}?days=${daysFilterLoad}`, config);
            const data = response.data;
           
            const dates = data.map((item) => item.date);
            const loads = data.map((item) => item.load);
           

            // Update chart options
            setChartLineOptions({
                ...chartLineOptions,
                xaxis: {
                    categories: dates
                }
            });

            // Update chart series
            setChartLineSeries([
                {
                    name: 'Load',
                    data: loads
                }
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleDriverChange = (e) => {
        const item = driverList.filter((i) => i.driver_id === e.target.value);
        setDriver(e.target.value);

        setTruck(driverList.filter((i) => i.driver_id === e.target.value)[0].truck_info.truck);
    };

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {},
        validationSchema: FaqSchema,

        onSubmit: async () => {}
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    const handleValueChange = (event, newValue) => {
        setDaysFilter(newValue);
    };
    const loadDayHandler = (event, newValue) => {
        setDaysFilterLoad(newValue);
    };

    /**
     * Handles the color stops based on percentage
     * @param {Number} val
     * @returns array
     */
    const handleColorStops = (val) => {
        let dat = [];
        if (val > 0 && val < 25) {
            dat = [
                {
                    offset: 0,
                    color: '#EB656F',
                    opacity: 1
                }
            ];
        } else if (val >= 25 && val < 50) {
            dat = [
                {
                    offset: 0,
                    color: '#EB656F',
                    opacity: 1
                },
                {
                    offset: 25,
                    color: '#FAD375',
                    opacity: 1
                }
            ];
        } else if (val >= 50 && val < 75) {
            dat = [
                {
                    offset: 0,
                    color: '#EB656F',
                    opacity: 1
                },
                {
                    offset: 25,
                    color: '#FAD375',
                    opacity: 1
                },
                {
                    offset: 75,
                    color: '#61DBC3',
                    opacity: 1
                }
            ];
        } else if (val >= 75) {
            dat = [
                {
                    offset: 0,
                    color: '#EB656F',
                    opacity: 1
                },
                {
                    offset: 25,
                    color: '#FAD375',
                    opacity: 1
                },
                {
                    offset: 50,
                    color: '#61DBC3',
                    opacity: 1
                },
                {
                    offset: 100,
                    color: '#95DA74',
                    opacity: 1
                }
            ];
        }

        return dat;
    };

    const optionsDriver = {
        chart: {
            type: 'radialBar',
            offsetY: -20,

            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '22px'
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'round',
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                // stops: [0, 50, 53, 91],
                colorStops: handleColorStops(driverSaftey ? driverSaftey : 0)
            }
        },
        stroke: {
            lineCap: 'round'
        },
        series: [driverSaftey ? driverSaftey : 0], // Set this value dynamically to update the gauge
        labels: ['Compliant'] // Set the label for the gauge
    };

    const optionsTruck = {
        chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '22px'
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: handleColorStops(truckSaftey ? truckSaftey : 0)
            }
        },
        stroke: {
            lineCap: 'round'
        },
        series: [truckSaftey ? truckSaftey : 0], // Set this value dynamically to update the gauge
        labels: ['Compliant'] // Set the label for the gauge
    };

    const optionsOver = {
        chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '22px'
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 0.5,
                gradientToColors: ['#FF0000'], // Set the default progress color to red

                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: handleColorStops(overSaftey ? overSaftey : 0)
            }
        },
        stroke: {
            lineCap: 'round'
        },
        series: [overSaftey ? overSaftey : 0], // Set the progress dynamically
        labels: ['Compliant']
    };

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography
                                sx={{
                                    width: '109px',
                                    height: '40px',
                                    fontWeight: '600',
                                    fontSize: '32px',
                                    fontFamily: 'Lexend'
                                }}
                            >
                                Report
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        {/* Revenue Report */}
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            {loading ? (
                                <Skeleton height="200px" />
                            ) : (
                                <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                                    <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                                        <Typography
                                            sx={{
                                                width: '233px',
                                                height: '27px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            Revenue Bar
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                            color="orange"
                                            size="small"
                                            onClick={handleClickOpenModal}
                                        >
                                            Compare
                                        </Button>
                                        <ToggleButtonGroup
                                            color="orange"
                                            size="small"
                                            value={daysFilter}
                                            exclusive
                                            onChange={handleValueChange}
                                            aria-label="button radio group"
                                        >
                                            <ToggleButton value={30} aria-label="1M">
                                                1M
                                            </ToggleButton>
                                            <ToggleButton value={180} aria-label="1M">
                                                6M
                                            </ToggleButton>
                                            <ToggleButton value={365} aria-label="1Y">
                                                1Y
                                            </ToggleButton>
                                            <ToggleButton value={1825} aria-label="all">
                                                All
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Stack>

                                    <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={350} />
                                </SubCard>
                            )}
                        </Grid>
                        {/* Load Report */}
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            {loading ? (
                                <Skeleton height="200px" />
                            ) : (
                                <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                                    <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                                        <Typography
                                            sx={{
                                                width: '133px',
                                                height: '27px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            Load
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                            color="orange"
                                            size="small"
                                            onClick={handleClickOpenLoad}
                                        >
                                            Compare
                                        </Button>
                                        <ToggleButtonGroup
                                            color="orange"
                                            size="small"
                                            value={daysFilterLoad}
                                            exclusive
                                            onChange={loadDayHandler}
                                            aria-label="button radio group"
                                        >
                                            <ToggleButton value={30} aria-label="1M">
                                                1M
                                            </ToggleButton>
                                            <ToggleButton value={180} aria-label="1M">
                                                6M
                                            </ToggleButton>
                                            <ToggleButton value={365} aria-label="1Y">
                                                1Y
                                            </ToggleButton>
                                            <ToggleButton value={1825} aria-label="all">
                                                All
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Stack>

                                    <ReactApexChart options={chartLineOptions} series={chartLineSeries} type="area" height={350} />
                                </SubCard>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <Stack direction="row" sx={{ mt: 2 }} justifyContent="flex-start" alignItems="center" spacing={2}>
                                    <TextField
                                        sx={{ width: '250px' }}
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        select
                                        label="Driver Name"
                                        value={driver}
                                        onChange={(e) => handleDriverChange(e)}
                                    >
                                        <MenuItem value={''} disabled>
                                            Select Driver
                                        </MenuItem>
                                        {driverList && driverList.map((i) => <MenuItem value={i.driver_id}>{i.name}</MenuItem>)}
                                    </TextField>

                                    <div style={{ backgroundColor: '#142953', padding: '15px', borderRadius: '10px' }}>
                                        <Typography
                                            sx={{
                                                height: '27px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend'
                                            }}
                                            color="white"
                                        >
                                            Truck # : {truck && truck}
                                        </Typography>
                                    </div>
                                </Stack>
                            )}
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            {loading || loadingSf ? (
                                <Skeleton height="150px" />
                            ) : (
                                <SubCard sx={{ mt: 2, borderRadius: '8px', mb: 2 }}>
                                    <Stack sx={{ mb: 2 }} direction="column" justifyContent="center" alignItems="center" spacing={4}>
                                        <Typography
                                            sx={{
                                                height: '20px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            Driver Compliance
                                        </Typography>
                                    </Stack>

                                    <Chart options={optionsDriver} series={optionsDriver.series} type="radialBar" height={optionsDriver.chart.height} />
                                </SubCard>
                            )}
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            {loading || loadingSf ? (
                                <Skeleton height="150px" />
                            ) : (
                                <SubCard sx={{ mt: 2, borderRadius: '8px', mb: 2 }}>
                                    <Stack sx={{ mb: 2 }} direction="column" justifyContent="center" alignItems="center" spacing={4}>
                                        <Typography
                                            sx={{
                                                height: '20px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            Vehicles Saftey
                                        </Typography>
                                    </Stack>

                                    <Chart options={optionsTruck} series={optionsTruck.series} type="radialBar" height={optionsTruck.chart.height} fi />
                                </SubCard>
                            )}
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            {loading || loadingSf ? (
                                <Skeleton height="150px" />
                            ) : (
                                <SubCard sx={{ mt: 2, borderRadius: '8px', mb: 2 }}>
                                    <Stack sx={{ mb: 2 }} direction="column" justifyContent="center" alignItems="center" spacing={4}>
                                        <Typography
                                            sx={{
                                                height: '20px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            Overall Company Safety
                                        </Typography>
                                    </Stack>

                                    <Chart options={optionsOver} series={optionsOver.series} type="radialBar" height={optionsOver.chart.height} />
                                </SubCard>
                            )}
                        </Grid>
                    </Grid>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>

            <ReportLoadModal fullWidth={true} handleClose={handleLoadClose} open={open1} sx={{ width: 300 }} />
            <ReportModal fullWidth={true} handleClose={handleClose} open={open} sx={{ width: 300 }} />
        </>
    );
};

export default ReportsForm;
