import { Container, Stack } from '@mui/material';

import React from 'react';

import BackFormButton from 'ui-component/buttons/BackFormButton';

/**
 * Page Layout
 * @param {prop} props.children
 * @param {prop} props.url
 *
 * @returns JSX
 */
function PageLayoutWithBack({ children, url }) {
    return (
        <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <BackFormButton />
            </Stack>
            {children}
        </Container>
    );
}

export default PageLayoutWithBack;
