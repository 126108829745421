import { Button, useMediaQuery, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Add button of forms
 * @param {prop} url
 * @param {prop} btnText
 * @returns
 */
function AddFormButton({ url, btnText, sx }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Button
            variant="contained"
            sx={{
                mr: matchDownMd ? 1 : 2,
                color: 'white',
                backgroundColor: '#EB812E',
                // width: { xs: '17rem', sm: '10rem' }
                // width: { xs: '60vw', sm: '13rem' }
                width: { xs: '47vw', sm: '13rem' },
                ...sx
            }}
            color="orange"
            onClick={() => navigate(url)}
            startIcon={<AddIcon />}
            // size={matchDownMd ? 'small' : 'medium'}
            // sx={{ pt: 1.5, pb: 1.5 }}
        >
            {btnText}
        </Button>
    );
}

export default AddFormButton;
