import { Divider, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

import MainCard from 'ui-component/cards/MainCard';

import { useNavigate } from 'react-router';
import 'ui-component/font/fonts';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLayout from 'layout/PageLayout';
// import ReceivePayment from './components/ReceivePayment';
import user_types from 'constant/user_types';
import SubExternalExpenses from './SubExternalExpenses';

// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        // backgroundColor: '#EB812E',
                        borderRadius: '8px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '18px',
                            fontWeight: 900,
                            marginRight: '5px',
                            color: 'white'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});
const ExternalExpenses = () => {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const settlement_view = permissions.dspdriversettlement && permissions.dspdriversettlement.view ? true : false;
    const ifta_view = permissions.dspdriverifta && permissions.dspdriverifta.view ? true : false;
    const invoice_view = permissions.dspcompanyloadinvoice && permissions.dspcompanyloadinvoice.view ? true : false;
    const tax_view = permissions.tax_config && permissions.tax_config.view ? true : false;
    const fuel_view = permissions.fuelreport && permissions.fuelreport.view ? true : false;

    const navigate = useNavigate();
    const [value, setValue] = useState(user_types.includes(user_type) ? 5 : settlement_view + ifta_view + invoice_view + tax_view + fuel_view + 0);

    // const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    // const params = new URLSearchParams(queryParams);

    // const expenseTab = queryParams.get("page")

    // changing the page query params
    // useEffect(() => {
    //     if (!expenseTab) {
    //         params.set("page", "");
    //         navigate({ search: `?${params.toString()}` });
    //     }
    // }, [expenseTab, navigate, params]);

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    // const handleExpenseTabChange = (_event, newValue)=>{
    //     params.set("page", newValue);
    //     params.delete("search")
    //     navigate({ search: `?${params.toString()}` });
    // }

    // External expenses menu
    const expense_view = permissions.expense && permissions.expense.view ? true : false;
    const bill_view = permissions.bill && permissions.bill.view ? true : false;
    const recurringbill_view = permissions.recurringbill && permissions.recurringbill.view ? true : false;
    const vendor_view = permissions.vendor && permissions.vendor.view ? true : false;
    const salesreceipt_view = permissions.salesreceipt && permissions.salesreceipt.view ? true : false;

    return (
        <MainCard>
            <PageLayout title="Accounting">
                <Box sx={{ border: 'none', background: 'transparent' }}>
                    <ThemeProvider theme={theme}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { display: 'none' } }}
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '1rem',
                                    mb: { xs: '1rem', sm: 0 }
                                }
                            }}
                        >
                            {(settlement_view || user_types.includes(user_type)) && (
                                <Tab label="Settlement" onClick={() => navigate(`/accounting/settlement`)} />
                            )}
                            {(ifta_view || user_types.includes(user_type)) && <Tab label="IFTA" onClick={() => navigate(`/accounting/ifta/all`)} />}
                            {(invoice_view || user_types.includes(user_type)) && (
                                <Tab label="My Invoices" onClick={() => navigate(`/accounting/my-invoice/all`)} />
                            )}
                            {(tax_view || user_types.includes(user_type)) && (
                                <Tab label="Tax Configuration" onClick={() => navigate(`/accounting/configuration/tax`)} />
                            )}
                            {(fuel_view || user_types.includes(user_type)) && <Tab label="Fuel Reports" onClick={() => navigate(`/accounting/fuel-reports`)} />}
                            {(expense_view || bill_view || recurringbill_view || vendor_view || salesreceipt_view || user_types.includes(user_type)) && (
                                <Tab label="External Expenses" onClick={() => navigate(`/accounting/external-expenses`)} />
                            )}
                        </Tabs>
                    </ThemeProvider>
                </Box>

                <Divider />

                {/* sub expenses  */}
                <SubExternalExpenses />
            </PageLayout>
        </MainCard>
    );
};

export default ExternalExpenses;
