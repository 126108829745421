import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

import MainCard from 'ui-component/cards/MainCard';

import { useNavigate } from 'react-router';
import 'ui-component/font/fonts';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import user_types from 'constant/user_types';
import PageLayout from 'layout/PageLayout';
import AllMyPaymentsForm from 'ui-component/factoring/forms/AllMyPaymentsForm';
import ReceivePayment from 'views/accounting/components/ReceivePayment';
// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '8px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '18px',
                            fontWeight: 900,
                            marginRight: '5px',
                            color: 'white'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});

const Factoring = () => {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions;

    const payment_view = permissions.payment && permissions.payment.view ? true : false;
    const received_payment_view = permissions.receivedpayment && permissions.receivedpayment.view ? true : false;

    const navigate = useNavigate();
    const [value, setValue] = useState(0);

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainCard>
            <PageLayout title="Factoring">
                <Box sx={{ border: 'none', background: 'transparent' }}>
                    <ThemeProvider theme={theme}>
                        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                            {/* {(payment_view || user_types.includes(user_type)) && <Tab label="My Payments" onClick={() => navigate(`/factoring/my-payments`)} />} */}
                            {user_type == 'super_dispatcher_admin' && <Tab label="Invoice Tracking" onClick={() => navigate(`/factoring/invoice-tracking`)} />}
                            {user_type == 'super_dispatcher_admin' && <Tab label="payment Tracking" onClick={() => navigate(`/factoring/payment-tracking`)} />}
                            {(received_payment_view || user_types.includes(user_type)) && (
                                <Tab label="Received Payment" onClick={() => navigate(`/factoring/received-payment`)} />
                            )}
                        </Tabs>
                    </ThemeProvider>
                </Box>

                {(received_payment_view || user_types.includes(user_type)) && <ReceivePayment />}
            </PageLayout>
        </MainCard>
    );
};

export default Factoring;
