import { Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
function ViewTextFields({ sx, viewText, fileName, file, icon, handleClickOpen, handleDownloadClick }) {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ mt: 2, height: '30px', ...sx }}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
                <span style={{ fontFamily: 'Lexend', fontWeight: '500' }}>{viewText}</span>
            </Grid>
            {file ? (
                <Grid item lg={9} md={9} sm={12} xs={12}>
                    <div style={{ flexDirection: 'row' }}>
                        <span style={{ width: '228px', height: '30px', color: '#5C5C5C', fontFamily: 'Lexend', py: '8px' }}>
                            {fileName}
                        </span>

                        <IconButton disabled={!file} onClick={() => handleClickOpen(file)}>
                            <VisibilityIcon />
                        </IconButton>

                        <IconButton disabled={!file} onClick={() => handleDownloadClick(file)}>
                            <DownloadIcon />
                        </IconButton>
                    </div>
                </Grid>
            ) : (
                ''
            )}
        </Stack>
    );
}

export default ViewTextFields;
