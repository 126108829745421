import { Divider, List, ListItemText, Stack, useTheme, Box, ListItem, TextField, FormGroup, FormControlLabel, Checkbox, Button, Popover } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PropTypes from 'prop-types';
function UserFilter({ yearFilter, yearHandler, filterHandler, handleStatus, statusChecked, reset }) {
    const theme = useTheme();
    return (
        <PopupState variant="popover" popupId="filter-popup">
            {(popupState) => (
                <>
                    <Button
                        variant="contained"
                        sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                        color="orange"
                        startIcon={<FilterAltIcon />}
                        {...bindTrigger(popupState)}
                    >
                        FILTER
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                            {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                            <Box sx={{ px: 2 }}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        maxWidth: 350,
                                        minWidth: 300,
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            minWidth: '100%'
                                        },
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5
                                        }
                                    }}
                                >
                                    {/* <ListItem>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                openTo="day"
                                                views={['year']}
                                                label="Year"
                                                value={yearFilter}
                                                onChange={yearHandler}
                                                renderInput={(params) => (
                                                    <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </ListItem> */}
                                    {/* <Divider variant="middle" component="li" /> */}
                                    <ListItem>
                                        <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="flex-start">
                                            <ListItemText id="switch-list-label-bluetooth" primary="Status" />
                                        </Stack>
                                    </ListItem>
                                    <ListItem>
                                        <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="flex-start">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={handleStatus('active')}
                                                            checked={statusChecked.indexOf('active') !== -1}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    }
                                                    label="Active"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={handleStatus('inactive')}
                                                            checked={statusChecked.indexOf('inactive') !== -1}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    }
                                                    label="Inactive"
                                                />
                                            </FormGroup>
                                        </Stack>
                                    </ListItem>
                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={{ xs: 12, sm: 20 }}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    reset();
                                                    popupState.close();
                                                }}
                                            >
                                                {' '}
                                                Reset
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                                color="orange"
                                                onClick={() => {
                                                    filterHandler();
                                                    popupState.close();
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </Stack>
                                    </ListItem>
                                </List>
                            </Box>
                        </MainCard>
                    </Popover>
                </>
            )}
        </PopupState>
    );
}
UserFilter.propTypes = {
    yearFilter: PropTypes.string,
    yearHandler: PropTypes.func,
    filterHandler: PropTypes.func,
    handleStatus: PropTypes.any,
    statusChecked: PropTypes.any
};
export default UserFilter;
