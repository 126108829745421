import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

import PageLayout from 'layout/PageLayout';
import FuelReportsByTruckId from 'ui-component/accounting/forms/FuelReportsByTruckId';

function FuelDriverListById() {
    return (
        <>
            <MainCard>
                <PageLayout title="Fuel Reports">
                    <FuelReportsByTruckId />
                </PageLayout>
            </MainCard>
        </>
    );
}

export default FuelDriverListById;
