// project imports

import MainCard from 'ui-component/cards/MainCard';
import ViewLoad from 'ui-component/dispatchCenter/forms/ViewLoadOld';
import 'ui-component/font/fonts';
import PageLayout from 'layout/PageLayout';
import ViewLoadTest from 'ui-component/dispatchCenter/forms/ViewLoad';
// ==============================|| SAMPLE PAGE ||============================== //

const ViewMyLoadStatusTest = () => {
    return (
        <MainCard sx={{ height: '100%' }}>
            <PageLayout title="Dispatch Center">
                <ViewLoadTest />
            </PageLayout>
        </MainCard>
    );
};

export default ViewMyLoadStatusTest;
