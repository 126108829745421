import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import { useDispatch } from 'react-redux';

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    InputAdornment,
    Stack,
    TablePagination,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import axios from 'axios';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

import { createRef, useEffect } from 'react';
import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import NewExpenseItemModal from 'ui-component/modals/NewExpenseItemModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import user_types from 'constant/user_types';
import { Box } from '@mui/system';

const AllSettlementExpenseItemForm = () => {
    // Access parameters from the params object

    const tableRef = createRef();
    const [open1, setOpen1] = useState(false);

    const dispatch = useDispatch();

    const [searchFltr, setSearchFltr] = useState('');
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const cid = loginInfoFromStorage.company_id;
    const permissions = loginInfoFromStorage.permissions;
    const [fileId, setFileId] = useState();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const handleCloseModal = () => {
        setOpen1(false);
    };
    const handleClickOpenModal = () => {
        setFileId('');
        setOpen1(true);
    };

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const deleteFile = async (id) => {
        try {
            await axios
                .delete(`/v1/api/users/company/expense-type/view/${id}`, {
                    data: {},
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${loginInfoFromStorage.token}`
                    }
                })

                .then(dispatch(openSuccessSnackbar('Expense Item deleted sucessfully')));
            // .then(tableRef.current.onQueryChange());
            tableRef.current.onQueryChange();
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const editFile = (id) => {
        setFileId(id);

        setOpen1(true);
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}

                    {/* <th className="headerTd ">Type</th> */}
                    <th className="headerTd ">Name</th>
                    <th className="headerTd ">Default value</th>
                    <th className="headerTd ">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                {/* <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                {rowData.data.exp_type.charAt(0).toUpperCase() + rowData.data.exp_type.slice(1)}

                </td> */}
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.exp_item_type_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.default_value}
                </td>

                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    {(permissions.settlement_expense_item && permissions.settlement_expense_item.change) || user_types.includes(user_type) ? (
                        <IconButton onClick={() => editFile(rowData.data.exp_item_type_id)}>
                            <EditIcon />
                        </IconButton>
                    ) : null}
                    {(permissions.settlement_expense_item && permissions.settlement_expense_item.delete) || user_types.includes(user_type) ? (
                        <IconButton
                            color="error"
                            onClick={() => deleteFile(rowData.data.exp_item_type_id)}
                            disabled={rowData.data.exp_type === 'settlement' && rowData.data.is_common}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    ) : null}
                </td>
            </tr>
        );
    };
    const fetchData = async (query) => {
        setLoading(true);

        try {
            const url = `/v1/api/users/company/expense-type/list/${cid}/settlement/?search=${searchFltr}`;

            const response = await fetch(url, config);
            const result = await response.json();

            console.log(response);
            console.log(result);

            // Set data
            setSelected(result || []);

            return {
                data: result,
                page: query.page
                // totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page
                // totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile) {
            fetchData({ page: 0, pageSize: 10 })
                .then((result) => {
                    setSelected(result.data || {});
                    // setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
        console.log(selected);
    }, [isMobile]);

    return (
        // <PageLayout url="/setup/add-user">
        <>
            <Stack sx={{ mt: 5, mb: 2, flexDirection: { xs: 'column', sm: 'row' } }} justifyContent="space-between" alignItems="baseline" spacing={4}>
                <TextField
                    type="text"
                    placeholder="Search"
                    size="small"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    className="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    // sx={{ width: '300px' }}
                    // sx={{ minWidth: { xs: '22.3rem', sm: '10rem' } }}
                    sx={{ width: { xs: '83vw', sm: '45vw', lg: '20vw' } }}
                />
                <div>
                    {(permissions.settlement_expense_item && permissions.settlement_expense_item.add) || user_types.includes(user_type) ? (
                        <Button
                            variant="contained"
                            sx={{
                                mr: { xs: 0, sm: 2 },
                                color: 'white',
                                backgroundColor: '#EB812E',
                                // minWidth: { xs: '22.3rem', sm: '10rem' }
                                width: { xs: '80vw', sm: '7rem' }
                            }}
                            color="orange"
                            onClick={handleClickOpenModal}
                        >
                            ADD ITEM
                        </Button>
                    ) : null}
                </div>
            </Stack>
            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    {/* <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>

                                        <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div style={{ float: 'right', marginTop: '1px' }}>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        {(permissions.dispatcheradmin && permissions.dispatcheradmin.change) ||
                                                        user_types.includes(user_type) ? (
                                                            <MenuItem onClick={() => editUser(driver.dis_admin_id)}>Edit User</MenuItem>
                                                        ) : null}
                                                        <MenuItem onClick={() => viewUser(driver.dis_admin_id)}>View User</MenuItem>
                                                        {(permissions.dispatcheradmin && permissions.dispatcheradmin.delete) ||
                                                        user_types.includes(user_type) ? (
                                                            <MenuItem onClick={() => deleteMember(driver.dis_admin_id)}>Remove User</MenuItem>
                                                        ) : null}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box> */}

                                    <Box sx={{ clear: 'both' }} />

                                    <Typography sx={{ marginBottom: '.5rem' }}> Name : {driver.exp_item_type_name}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Default value : {driver.default_value}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Action :{' '}
                                        {(permissions.settlement_expense_item && permissions.settlement_expense_item.change) ||
                                        user_types.includes(user_type) ? (
                                            <IconButton onClick={() => editFile(driver.exp_item_type_id)}>
                                                <EditIcon />
                                            </IconButton>
                                        ) : null}
                                        {(permissions.settlement_expense_item && permissions.settlement_expense_item.delete) ||
                                        user_types.includes(user_type) ? (
                                            <IconButton
                                                color="error"
                                                onClick={() => deleteFile(driver.exp_item_type_id)}
                                                disabled={driver.exp_type === 'settlement' && driver.is_common}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        ) : null}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    {/* <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                maxWidth: '19rem',
                                flexWrap: 'wrap',
                                justifyContent: 'right',
                                marginLeft: '4vw'
                            },
                            '.MuiTablePagination-selectLabel': {
                                ml: '4rem'
                            },
                            '.MuiTablePagination-select': {
                                width: '6rem'
                            },
                            '.css-1ioyjal-MuiInputBase-root-MuiTablePagination-select': {
                                marginRight: 0,
                                marginLeft: '-12vw'
                            }
                        }}
                    /> */}
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    // data={(query) =>
                    //     new Promise((resolve, reject) => {
                    //         const url = `/v1/api/users/company/expense-type/list/${cid}/settlement/?search=${searchFltr}`;

                    //         fetch(url, config)
                    //             .then((response) => response.json())
                    //             .then((result) => {
                    //                 resolve({
                    //                     data: result
                    //                     // page: query.page,
                    //                     // totalCount: result.count
                    //                 });
                    //             });
                    //     })
                    // }
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchData(query)
                                .then((result) => resolve(result))
                                .catch((error) => reject(error));
                        })
                    }
                    title="All Expense"
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,

                        toolbar: false,
                        draggable: false,
                        columnResizable: true
                        // pageSize: 50,
                        // pageSizeOptions: [50]
                    }}
                />
            )}

            <NewExpenseItemModal
                open={open1}
                handleClose={handleCloseModal}
                type="settlement"
                fileId={fileId}
                reloadHandler={() => {
                    tableRef.current.onQueryChange();
                }}
            />
        </>
    );
};

export default AllSettlementExpenseItemForm;
