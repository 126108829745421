import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, LinearProgress, Typography, Box, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Link } from 'react-router-dom';

function ImportPanel({ onChangeHandler, loadingFile, subTitle, subBody, dlTxt, file }) {
    return (
        <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="24px" sx={{ mb: 1, color: '#142953', fontFamily: 'Lexend' }}>
                    Import
                </Typography>
                <Typography variant="h5" gutterBottom color="primary">
                    {subTitle}
                </Typography>
                <Typography variant="caption">
                    {subBody}{' '}
                    <Link to={file} download={dlTxt} target="_blank" rel="noreferrer">
                        Sample file
                    </Link>
                </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Stack direction="row" sx={{ justifyContent: { sm: 'flex-start', md: 'flex-end' } }} alignItems="flex-end" spacing={2}>
                    <FormControl variant="outlined" align="right">
                        <input
                            color="primary"
                            type="file"
                            // value={file}
                            onChange={onChangeHandler}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                            // onInputCapture={(e) => fileUploadHandler(e)}
                            id="icon-button-file"
                            style={{ display: 'none' }}
                            // disabled={values.customer === 0 ? true : false}
                        />
                        <label htmlFor="icon-button-file">
                            <LoadingButton
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    fontFamily: 'Lexend',
                                    fontWeight: 500,
                                    lineHeight: '1.3rem',
                                    fontSize: '16px',
                                    fontStyle: 'normal'
                                }}
                                component="span"
                                size="large"
                                color="orange"
                                endIcon={<CloudUploadIcon />}
                                loadingPosition="end"
                                loading={loadingFile}
                            >
                                Upload
                            </LoadingButton>
                        </label>
                    </FormControl>
                </Stack>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                {loadingFile ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress sx={{ mt: 2 }} />
                        <Typography variant="h5" sx={{ mt: 2, color: 'red' }}>
                            This may take a while, Please do not close or refresh this page!
                        </Typography>
                    </Box>
                ) : (
                    ''
                )}
            </Grid>
        </Grid>
    );
}

ImportPanel.propTypes = {
    onChangeHandler: PropTypes.func,
    loadingFile: PropTypes.bool,
    subBody: PropTypes.string,
    subTitle: PropTypes.string,
    dlTxt: PropTypes.string,
    file: PropTypes.any
};

export default ImportPanel;
