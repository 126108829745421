import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { forwardRef } from 'react';
import OtherDocloadForm from 'ui-component/dispatchCenter/forms/OtherDocloadForm';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function OtherDocModal({ fullWidth, fullScreen, maxWidth, open, handleClose, id }) {
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogTitle></DialogTitle>
            <DialogContent dividers>
                <OtherDocloadForm id={id} handleClose={handleClose}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

OtherDocModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'md'
};

export default OtherDocModal;
