import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Grid, IconButton, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import * as Yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';

// icon imports
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// project imports
import SubCard from 'ui-component/cards/SubCard';

import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const InvoiceMyloadForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cId = loginInfoFromStorage.company_id;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [loading, setLoading] = useState();
    const [loadData, setLoadData] = useState();
    const { id } = useParams();

    const [revenue, setRevenue] = useState(0);
    const [invoiceId, setInvoiceId] = useState(0);
    const [addError, setAddError] = useState();
    const [open, setOpen] = useState(false);
    const [create, setCreate] = useState();
    const [expList, setExpList] = useState('');
    // const [origin, setOrigin] = useState({ lat: 37.7749, lng: -122.4194 }); // New York City

    useEffect(() => {
        if (id && !loadData) {
            fetchInvLoad(id);
            fetchExpItem();
        }
    }, [loadData, id]);

    const fetchInvLoad = async (id) => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/dispatch/load-invoice/view/${id}/`, config);

            setLoadData(data);
            setRevenue(data.load_info.revenue);
            setInvoiceId(data.dsp_load_inv_id);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const fetchExpItem = async () => {
        // setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/company/expense-type/list/${cId}/invoice/`, config);

            setExpList(data);
            setRevenue(data.load_info.revenue);
            setInvoiceId(data.dsp_load_inv_id);

            // setLoading(false);
        } catch (error) {
            // setLoading(false);
        }
    };

    const deleteFile = async (id) => {
        if (id !== 0) {
            try {
                await axios
                    .delete(`/v1/api/users/company/expense-type/view/${id}`, {
                        data: {},
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: `Bearer ${loginInfoFromStorage.token}`
                        }
                    })

                    .then(dispatch(openSuccessSnackbar('Expense Item deleted sucessfully')));
            } catch (error) {
                dispatch(openErrorSnackbar(error.response.data.detail));
            }
        }
    };

    const invLoad = {
        dspli_exp_item_id: 0,
        exp_item_type_id: 0,
        amount: 0.0
    };

    const checkElInArr = (val) => {
        return values.invoice.some((item) => item.type === val);
    };

    const invoiceHanlder = () => {};
    const InvoiceSchema = Yup.object().shape({
        invoice: Yup.array().of(
            Yup.object().shape({
                exp_item_type_id: Yup.number().required('This field is required'),
                amount: Yup.number()
                    .required('This field is required')
                    .test({ name: 'cust-check', message: 'This field is required', test: (value) => value !== 0 })
            })
        )
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            invoice: id && loadData ? loadData.expenses : [invLoad],
            note: ''
        },
        validationSchema: InvoiceSchema,
        onSubmit: async () => {
            let invData = {
                dsp_load_inv_id: invoiceId,
                fixed_price: 0,
                lumper_fee: 0,
                detention_fee: 0,
                layover_fee: 0,
                other_expenses: 0,
                other_item: 0,
                notes: values.note,
                revenue: revenue,
                is_draft: create === 'create' ? false : true,
                is_sent: false,
                updated_at: new Date(),
                is_active: false,
                is_edit: true,
                company_id: loginInfoFromStorage.company_id,
                load_id: id,
                expenses: values.invoice
            };

            try {
                const { data } = await axios.put(`/v1/api/dispatch/load-invoice/update/${invoiceId}/`, invData, config);
                dispatch(openSuccessSnackbar('Invoice Added successfully'));
                if (create === 'save') {
                    navigate('/dispatch-center');
                } else if (create === 'create') {
                    navigate(`/dispatch-Center/other-doc/${id}/${invoiceId}`);
                } else {
                }
            } catch (error) {
                setAddError(error.response.data);
                dispatch(openErrorSnackbar('Something went wrong!'));
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={9} md={9} sm={9} xs={12}>
                            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                                <Grid container spacing={3}>
                                    {/* Customer */}
                                    {/* General Info */}

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography variant="h2">INVOICE</Typography>
                                    </Grid>

                                    {/* Customer */}

                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <TextField
                                                className="text-input"
                                                fullWidth
                                                type="text"
                                                label="Customer Name"
                                                color="orange"
                                                value={id && loadData && loadData.cust_info.name}
                                                inputProps={{ readOnly: true }}
                                            />
                                        )}
                                    </Grid>

                                    {/* weight */}

                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <TextField
                                                className="text-input"
                                                fullWidth
                                                type="text"
                                                label="Billing Email"
                                                color="orange"
                                                value={id && loadData && loadData.cust_info.email}
                                                inputProps={{ readOnly: true }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </SubCard>
                            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                                <Grid container spacing={3}>
                                    {/* Customer */}
                                    {/* Vehicle Info */}
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography variant="h2">Reveneue Details</Typography>
                                    </Grid>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                                                <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0}>
                                                    <Typography variant="h4">
                                                        {id && loadData && loadData.load_info.start_load}
                                                        <Button endIcon={<KeyboardArrowRightIcon />} />
                                                        {id && loadData && loadData.load_info.end_load}
                                                    </Typography>
                                                    <Typography variant="body1">{id && loadData && loadData.load_info.date}</Typography>
                                                </Stack>

                                                <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                                                    <Typography variant="h2" sx={{ fontSize: '18px' }}>
                                                        {id && loadData && loadData.load_info.revenue}{' '}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    )}
                                </Grid>
                                <FieldArray name="invoice">
                                    {({ push, remove }) => (
                                        <>
                                            {values.invoice.map((i, index) => {
                                                const invoiceErrors = (errors.invoice?.length && errors.invoice[index]) || {};
                                                const invoiceTouched = (touched.invoice?.length && touched.invoice[index]) || {};
                                                return (
                                                    <div key={index}>
                                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    borderRadius: '50%',
                                                                    backgroundColor: theme.palette.orange.main,
                                                                    color: '#ffffff',
                                                                    px: '10px',
                                                                    py: '7px'
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Typography>
                                                            <Typography
                                                                variant="h2"
                                                                sx={{
                                                                    ml: 1,
                                                                    mt: 0.8,
                                                                    fontWeight: 500,
                                                                    fontFamily: 'Lexend',
                                                                    letterSpacing: 2
                                                                }}
                                                            >
                                                                Expense
                                                            </Typography>
                                                        </Stack>
                                                        <Grid container spacing={3} sx={{ mb: 2 }}>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <TextField
                                                                        select
                                                                        fullWidth
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="Type"
                                                                        value={values.invoice[index].exp_item_type_id}
                                                                        onChange={(e) => setFieldValue(`invoice[${index}].exp_item_type_id`, e.target.value)}
                                                                        error={Boolean(
                                                                            (invoiceTouched.exp_item_type_id && invoiceErrors.exp_item_type_id) ||
                                                                                (addError && addError.invoice[index].exp_item_type_id)
                                                                        )}
                                                                        helperText={
                                                                            (invoiceTouched.exp_item_type_id && invoiceErrors.exp_item_type_id) ||
                                                                            (addError && addError.invoice[index].exp_item_type_id)
                                                                        }
                                                                    >
                                                                        <MenuItem value={0}>Select Type</MenuItem>
                                                                        {expList &&
                                                                            expList.map((i) => (
                                                                                <MenuItem value={i.exp_item_type_id}>{i.exp_item_type_name}</MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                )}
                                                            </Grid>

                                                            <Grid item lg={5} md={5} sm={11} xs={11}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label=" Fee"
                                                                        value={values.invoice[index].amount}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`invoice[${index}].amount`, e.target.value);
                                                                        }}
                                                                        error={Boolean(
                                                                            (invoiceTouched.amount && invoiceErrors.amount) ||
                                                                                (addError && addError.pickups[index].amount)
                                                                        )}
                                                                        helperText={
                                                                            (invoiceTouched.amount && invoiceErrors.amount) ||
                                                                            (addError && addError.pickups[index].amount)
                                                                        }
                                                                    />
                                                                )}
                                                            </Grid>

                                                            <Grid item lg={1} md={1} sm={1} xs={1}>
                                                                <IconButton
                                                                    disabled={isSubmitting && Boolean(errors.invoice)}
                                                                    onClick={() => {
                                                                        remove(index);
                                                                        deleteFile(values.invoice[index].exp_item_type_id);
                                                                    }}
                                                                    size="small"
                                                                    color="error"
                                                                >
                                                                    <DeleteForeverIcon fontSize="large" />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                );
                                            })}
                                            <Grid item xs={6}>
                                                <Button
                                                    disabled={isSubmitting}
                                                    variant="contained"
                                                    color="orange"
                                                    onClick={() => {
                                                        push(invLoad);
                                                    }}
                                                    size="large"
                                                    sx={{
                                                        borderRadius: '8px',
                                                        fontFamily: 'Lexend',
                                                        fontWeight: 700,
                                                        textTransform: 'uppercase',
                                                        mt: 2,
                                                        color: '#ffffff'
                                                    }}
                                                    startIcon={<AddIcon />}
                                                >
                                                    Add Expense
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </FieldArray>
                            </SubCard>
                            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography variant="h2" gutterBottom>
                                            Note
                                        </Typography>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <TextField
                                                type="textarea"
                                                className="text-input"
                                                color="orange"
                                                multiline
                                                fullWidth
                                                minRows={2}
                                                label="Note"
                                                {...getFieldProps('note')}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>

                        <Grid item lg={3} md={3} sm={3} xs={12}>
                            <SubCard sx={{ borderRadius: '8px', my: 5 }}>
                                <Stack direction="row" spacing={1} justifyContent="space-between">
                                    <Typography variant="h4" sx={{ fontSize: '18px' }}>
                                        {loadData && loadData.cust_info.name}
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: '16px' }}>
                                        Profile
                                        <Button endIcon={<KeyboardArrowRightIcon />} />
                                    </Typography>
                                </Stack>
                                <SubCard sx={{ borderRadius: '8px' }}>
                                    <Stack direction="row" spacing={1} justifyContent="space-between">
                                        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                            <Typography variant="body1" sx={{ fontSize: '14px' }}>
                                                Invoice No
                                            </Typography>
                                            <Typography variant="body1" sx={{ fontSize: '14px' }}>
                                                Invoice Date
                                            </Typography>
                                        </Stack>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                                <Typography variant="body1" sx={{ fontSize: '14px' }}>
                                                    {id && loadData && loadData.dsp_load_inv_id}
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontSize: '14px' }}>
                                                    {id && loadData && loadData.load_info.date}
                                                </Typography>
                                            </Stack>
                                        )}
                                    </Stack>
                                </SubCard>
                                <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                                    <Typography variant="h4" sx={{ fontSize: '18px' }}>
                                        Invoice
                                    </Typography>
                                </Stack>
                                <SubCard sx={{ borderRadius: '8px', mt: 2 }}>
                                    <Stack direction="row" spacing={1} justifyContent="space-between">
                                        <Typography variant="h4" sx={{ fontSize: '18px', mb: 2 }}>
                                            Revenue
                                        </Typography>
                                    </Stack>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <>
                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                                                <Typography variant="body1" sx={{ fontSize: '14px' }}>
                                                    {id && loadData && loadData.load_info.start_load}
                                                    <Button endIcon={<KeyboardArrowRightIcon />} />
                                                    {id && loadData && loadData.load_info.end_load}
                                                </Typography>

                                                <Typography variant="body1" sx={{ fontSize: '14px' }}>
                                                    {revenue}
                                                </Typography>
                                            </Stack>
                                            {values.invoice.map(
                                                (i) =>
                                                    i.exp_item_type_id !== 0 && (
                                                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ my: 1 }}>
                                                            <Typography variant="body1" sx={{ fontSize: '14px' }}>
                                                                {expList &&
                                                                    expList.filter(({ exp_item_type_id }) => exp_item_type_id === i.exp_item_type_id)[0]
                                                                        .exp_item_type_name}
                                                            </Typography>

                                                            <Typography variant="body1" sx={{ fontSize: '14px' }}>
                                                                {i.amount}
                                                            </Typography>
                                                        </Stack>
                                                    )
                                            )}
                                        </>
                                    )}
                                    <Divider
                                        sx={{
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'black'
                                        }}
                                    />
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                                        <Typography variant="h4" sx={{ fontSize: '18px' }}>
                                            Invoice Total
                                        </Typography>
                                        <Typography variant="h4" sx={{ fontSize: '14px' }}>
                                            {revenue +
                                                values.invoice.reduce((acc, item) => {
                                                    return Number(acc) + Number(item.amount);
                                                }, 0)}
                                        </Typography>
                                    </Stack>
                                </SubCard>
                            </SubCard>
                        </Grid>
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" sx={{ mt: 5 }} alignItems="flex-end" spacing={2}>
                        {/* <AnimateButton> */}
                        <LoadingFormButton type="submit" onClickHandler={() => setCreate('create')} btnText="Create Invoice" load={isSubmitting} />
                        {/* </AnimateButton> */}
                        {/* <AnimateButton> */}
                        <LoadingFormButton type="submit" onClickHandler={() => setCreate('save')} btnText="Draft" load={isSubmitting} />
                        {/* </AnimateButton> */}
                    </Stack>
                </Form>
            </FormikProvider>
        </>
    );
};

export default InvoiceMyloadForm;
