import { useState, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, useMediaQuery } from '@mui/material';

// third party
import * as Yup from 'yup';

import { Form, FormikProvider, useFormik } from 'formik';

// project imports

import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { useDispatch } from 'react-redux';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const MechanicBankInfoForm = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [companySetup, setCompanySetup] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorUpdate, setUpdateError] = useState();
    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();
    const [fileObject, setFileObject] = useState(null);

    const googleHandler = async () => {
        console.error('Register');
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    useEffect(() => {
        if (!companySetup) {
            fetchCompany();
        }
        if (companySetup && companySetup.logo) {
            handleFetchFile(companySetup.logo);
        }
    }, [companySetup]);

    const fetchCompany = async () => {
        setLoading(true);

        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);
           
            setCompanySetup(data);
            setLoading(false);
        } catch (error) {
           
            setLoading(false);
        }
    };

    const handleFetchFile = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const fileName = url.split('/').pop();

            // Create a File object from the blob

            const fileObject = new File([blob], fileName, { type: blob.type });

            setFileObject(fileObject);
        } catch (error) {
            console.error('Error fetching or creating File object:', error);
        }
    };

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^0-9 ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            routingNumber: '',
            accountNumber: '',
            bankName: '',
            bankAddress: ''
        },
        onSubmit: async () => {
            try {
                const setup = new FormData();
                setup.append('dsp_company_name', companySetup.dsp_company_name);
                setup.append('logo', fileObject);
                setup.append('email', companySetup.email);
                setup.append('fact_client_no', companySetup.fact_info.fact_client_no);
                // setup.append('fact_comp_name', companySetup.fact_info.fact_comp_name);
                // setup.append('fact_address', companySetup.fact_info.fact_address);
                // setup.append('fact_phone', companySetup.fact_info.fact_phone);
                // setup.append('fact_fax', companySetup.fact_info.fact_fax);
                setup.append('address', companySetup.address);
                setup.append('routing_no', values.routingNumber);
                setup.append('bank_name', values.bankName);
                setup.append('acc_no', values.accountNumber);
                setup.append('bank_address', values.bankAddress);
                const { data } = await axios.post(`/v1/api/users/company/setup/`, setup, config);

                dispatch(openSuccessSnackbar('Bank Info added successfully'));
                // localStorage.setItem('loginUserInfo', JSON.stringify(data));
                navigate('/mechanic/setup', { replace: true });
            } catch (error) {
              
                setUpdateError(error.response.data.detail);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
   
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="h1" gutterBottom>
                                    Mechanic Bank Information
                                </Typography>
                                {errorUpdate && (
                                    <Typography variant="h6" color="error" textAlign="center">
                                        {errorUpdate}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={matchDownSM ? 0 : 2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Routing Number"
                                margin="normal"
                                type="text"
                                {...getFieldProps('routingNumber')}
                                onInput={NumberOnlyHandler}
                                error={Boolean((touched.routingNumber && errors.routingNumber) || (errorUpdate && errorUpdate.routing_number))}
                                helperText={(touched.routingNumber && errors.routingNumber) || (errorUpdate && errorUpdate.routing_number)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Account Number"
                                margin="normal"
                                type="text"
                                {...getFieldProps('accountNumber')}
                                onInput={NumberOnlyHandler}
                                error={Boolean((touched.accountNumber && errors.accountNumber) || (errorUpdate && errorUpdate.company_name))}
                                helperText={(touched.accountNumber && errors.accountNumber) || (errorUpdate && errorUpdate.company_name)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Bank Name"
                                margin="normal"
                                type="text"
                                {...getFieldProps('bankName')}
                                error={Boolean((touched.bankName && errors.bankName) || (errorUpdate && errorUpdate.bank_name))}
                                helperText={(touched.bankName && errors.bankName) || (errorUpdate && errorUpdate.bank_name)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Bank Address"
                                margin="normal"
                                type="text"
                                {...getFieldProps('bankAddress')}
                                error={Boolean((touched.bankAddress && errors.bankAddress) || (errorUpdate && errorUpdate.bank_address))}
                                helperText={(touched.bankAddress && errors.bankAddress) || (errorUpdate && errorUpdate.bank_address)}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }} align="center">
                        <AnimateButton>
                            <LoadingFormButton load={isSubmitting ? true : false} type="submit" btnText="Next" />
                        </AnimateButton>
                    </Box>
                </Form>
            </FormikProvider>
        </>
    );
};

export default MechanicBankInfoForm;
