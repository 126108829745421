import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui

import {
    Box,
    Grid,
    MenuItem,
    Skeleton,
    TextField,
    Typography,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';

import { PatternFormat } from 'react-number-format';
import { NumberOnlyInput } from 'utils/NumberOnlyInput';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const MemberForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [userData, setUserData] = useState();
    const [loading, setLoading] = useState();
    const [loading1, setLoading1] = useState();
    const [errorData, setErrorData] = useState();

    const groupedItems = {
        Dispatch: ['dspcompanyload', 'dspcompanycustomer', 'driver', 'truck', 'trailer', 'assetowner'],
        Accounting: [
            'dspdriversettlement',
            'dspdriverifta',
            'dspcompanyloadinvoice',
            'tax_config',
            'fuelreport',
            'expense',
            'bill',
            'recurringbill',
            'vendor',
            'salesreceipt',
            'payment',
            'invoice_tracking',
            'payment_tracking',
            'receivedpayment'
        ],
        Setup: ['dispatcheradmin', 'integration', 'data_import', 'invoice_expense_item', 'settlement_expense_item'],
        Safety: ['safety_compliance'],
        Report: ['useractivitylogdetail', 'report']
    };

    const [rows, setRows] = useState([
        { name: 'Dispatch Center Load', api: 'dspcompanyload', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Dispatch Center Customer', api: 'dspcompanycustomer', permissions: { add: false, change: false, view: false, delete: false } },

        { name: 'Driver', api: 'driver', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Truck', api: 'truck', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Trailer', api: 'trailer', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Owner', api: 'assetowner', permissions: { add: false, change: false, view: false, delete: false } },

        { name: 'Settlement', api: 'dspdriversettlement', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'IFTA', api: 'dspdriverifta', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Invoices', api: 'dspcompanyloadinvoice', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Tax Config', api: 'tax_config', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Fuel Report', api: 'fuelreport', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Expense', api: 'expense', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Bill', api: 'bill', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Recurring Bill', api: 'recurringbill', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Vendor', api: 'vendor', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Sales Receipt', api: 'salesreceipt', permissions: { add: false, change: false, view: false, delete: false } },

        { name: 'Payment', api: 'payment', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Received Payment', api: 'receivedpayment', permissions: { add: false, change: false, view: false, delete: false } },

        { name: 'Users', api: 'dispatcheradmin', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Integration', api: 'integration', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Data Import', api: 'data_import', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Invoice Expense Item', api: 'invoice_expense_item', permissions: { add: false, change: false, view: false, delete: false } },
        { name: 'Settlement Expense Item', api: 'settlement_expense_item', permissions: { add: false, change: false, view: false, delete: false } },

        { name: 'Safety and Compliance', api: 'safety_compliance', permissions: { add: false, change: false, view: false, delete: false } },

        { name: 'Report', api: 'report', permissions: { add: false, change: false, view: false, delete: false } },

        { name: 'Audit Log', api: 'useractivitylogdetail', permissions: { add: false, change: false, view: false, delete: false } }
    ]);

    const handleCheckboxChange = (api, permission, checked) => {
        if (permission === 'all') {
            const newPermissions = { view: checked, add: checked, change: checked, delete: checked };
            setRows(rows.map((row) => (row.api === api ? { ...row, permissions: newPermissions } : row)));
        } else {
            setRows(rows.map((row) => (row.api === api ? { ...row, permissions: { ...row.permissions, [permission]: checked } } : row)));
        }
    };

    function handleSelectAllChange(group, checked) {
        const apis = groupedItems[group];
        setRows(
            rows.map((row) => {
                if (apis.includes(row.api)) {
                    return {
                        ...row,
                        permissions: {
                            view: checked,
                            add: checked,
                            change: checked,
                            delete: checked
                        }
                    };
                }
                return row;
            })
        );
    }

    function handleSelectAllChangeForPermission(group, permissionType, checked) {
        const apis = groupedItems[group];
        setRows(
            rows.map((row) => {
                if (apis.includes(row.api)) {
                    return {
                        ...row,
                        permissions: {
                            ...row.permissions,
                            [permissionType]: checked
                        }
                    };
                }
                return row;
            })
        );
    }

    const updatePermissions = (permissions) => {
        setRows(
            rows.map((row) => {
                const apiPermissions = permissions[row.api];
                if (apiPermissions) {
                    return { ...row, permissions: apiPermissions };
                }
                return row;
            })
        );
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    useEffect(() => {
        if (!stateList) {
            fetchState();
        }
        if (!userData) {
            fetchUser(id);
        }
        if (id && userData && stateList && !cityList) {
            if (userData.state_id) {
                getCityList(userData.state_id);
            }
            updatePermissions(userData.user_id.permissions);
        }
    }, [stateList, userData, cityList, id]);

    const fetchState = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchUser = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/company/users/${id}/`, config);

            setUserData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const changeStates = (value) => {
        setFieldValue('state', value);

        getCityList(value);
    };

    const getCityList = async (id) => {
        setLoading1(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);

            setCityList(data);
            setLoading1(false);
        } catch (error) {
            setLoading1(false);
        }
    };

    const MemberSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is a required field'),

        lastName: Yup.string().required('Last Name is a required field'),
        phone: Yup.string().required('Phone Number is Required'),
        email: Yup.string().required('Email is a required field'),
        department: Yup.string().required('Deparment is a required field')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            firstName: id && userData ? userData.user_id.first_name : '',
            lastName: id && userData ? userData.user_id.last_name : '',
            address: id && userData ? userData.address : '',
            state: id && userData ? userData.state_id : '',
            city: id && userData ? userData.city_id : '',
            zip: id && userData ? userData.zip_code : '',
            email: id && userData ? userData.user_id.email : '',
            phone: id && userData ? userData.user_id.phone : '',
            ext: id && userData ? userData.phone_ext : '',
            department: id && userData ? userData.department : '',
            note: id && userData ? userData.notes : '',
            user_type: id && userData ? userData.user_id.user_type : ''
        },
        validationSchema: MemberSchema,
        onSubmit: async () => {
            try {
                if (id) {
                    const { data } = await axios.put(
                        `/v1/api/users/company/users/${id}/`,
                        {
                            user_id: {
                                // user_id: 1,
                                first_name: values.firstName,
                                last_name: values.lastName,
                                username: values.email,
                                email: values.email,
                                phone: values.phone,
                                company_id: loginInfoFromStorage.company_id,
                                user_type: values.department,
                                is_edit: true,
                                permissions: rows.reduce((acc, row) => {
                                    acc[row.api] = { ...acc[row.api], ...row.permissions };
                                    return acc;
                                }, {})
                            },
                            company_id: loginInfoFromStorage.company_id,
                            address: values.address,
                            zip_code: values.zip,
                            phone_ext: values.ext,
                            notes: values.note,
                            department: values.department,
                            state_id: values.state,
                            city_id: values.city,
                            is_edit: true
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar('User updated successfully'));
                } else {
                    const { data } = await axios.post(
                        `/v1/api/users/company/users/add/`,
                        {
                            user_id: {
                                // user_id: 1,
                                first_name: values.firstName,
                                last_name: values.lastName,
                                username: values.email,
                                email: values.email,
                                phone: values.phone,
                                company_id: loginInfoFromStorage.company_id,
                                user_type: values.department,
                                permissions: rows.reduce((acc, row) => {
                                    acc[row.api] = { ...acc[row.api], ...row.permissions };
                                    return acc;
                                }, {})
                            },
                            company_id: loginInfoFromStorage.company_id,
                            address: values.address,
                            zip_code: values.zip,
                            phone_ext: values.ext,
                            notes: values.note,
                            department: values.department,
                            is_active: true,
                            state_id: values.state,
                            city_id: values.city
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar('User  added successfully'));
                }
                navigate('/setup/user/all', { replace: true });
            } catch (error) {
                setErrorData(error.response.data.error);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.',
        error: Boolean((touched.phone && errors.phone) || (errorData && errorData.phone)),
        helperText: (touched.phone && errors.phone) || (errorData && errorData.phone)
    };
    const LetterOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    return (
        <>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                        <Grid container spacing={3}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Typography
                                    sx={{
                                        mt: '20px',
                                        width: '255px',
                                        height: '30px',
                                        fontWeight: '500',
                                        fontSize: '24px',
                                        fontFamily: 'Lexend',
                                        color: '#142953'
                                    }}
                                >
                                    NEW TEAM MEMBER
                                </Typography>
                            </Grid>
                        </Grid>

                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            width: '255px',
                                            height: '30px',
                                            fontWeight: '500',
                                            fontSize: '23   px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Personal Information
                                    </Typography>
                                </Grid>
                                {/* first Name */}
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="First Name"
                                            onInput={LetterOnlyHandler}
                                            {...getFieldProps('firstName')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                        />
                                    )}
                                </Grid>
                                {/* Middle Name */}
                                {/* <Grid item lg={4} md={4} sm={4} xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Middle Name"
                                    {...getFieldProps('middleName')}
                                    // error={Boolean(
                                    //     (touched.companyName && errors.companyName) ||
                                    //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // )}
                                    // helperText={
                                    //     (touched.companyName && errors.companyName) ||
                                    //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // }
                                />
                            </Grid> */}
                                {/* last Name */}
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Last Name"
                                            {...getFieldProps('lastName')}
                                            onInput={LetterOnlyHandler}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        />
                                    )}
                                </Grid>
                                {/* last Name */}
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Address"
                                            {...getFieldProps('address')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                                {/* revenue */}
                                {/* State */}
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading || stateList ? (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            select
                                            type="text"
                                            label="State"
                                            value={values.state}
                                            onChange={(e) => changeStates(e.target.value)}
                                            // error={Boolean(
                                            //     (touched.companyCountry && errors.companyCountry) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.country) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.country)
                                            // )}
                                            // helperText={
                                            //     (touched.companyCountry && errors.companyCountry) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.country) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.country)
                                            // }
                                        >
                                            {stateList &&
                                                stateList.map((i) => (
                                                    <MenuItem key={i.id} value={i.id}>
                                                        {i.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    ) : (
                                        <Skeleton height="50px" />
                                    )}
                                </Grid>

                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading || loading1 ? (
                                        <Skeleton variant="rectangular" width="100%" height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            select
                                            type="text"
                                            label="City"
                                            value={values.city}
                                            onChange={(e) => setFieldValue('city', e.target.value)}
                                            // error={Boolean(
                                            //     (touched.companyCountry && errors.companyCountry) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.country) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.country)
                                            // )}
                                            // helperText={
                                            //     (touched.companyCountry && errors.companyCountry) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.country) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.country)
                                            // }
                                        >
                                            <MenuItem disabled selected>
                                                Select City
                                            </MenuItem>
                                            {cityList &&
                                                cityList.map((i) => (
                                                    <MenuItem key={i.id} value={i.id}>
                                                        {i.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    )}
                                </Grid>

                                {/* <Grid item lg={3} md={3} sm={3} xs={12}>
                                <TextField fullWidth type="text" label="State" {...getFieldProps('state')} />
                            </Grid> */}
                                {/* millage */}
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Zip"
                                            inputProps={{ maxLength: 5 }}
                                            value={values.zip}
                                            onChange={(e) => setFieldValue('zip', e.target.value)}
                                            onInput={NumberOnlyInput}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>

                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            width: '245px',
                                            height: '30px',
                                            fontWeight: '500',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Contact Information
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Email *"
                                            {...getFieldProps('email')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                            error={Boolean((touched.email && errors.email) || (errorData && errorData.email))}
                                            helperText={(touched.email && errors.email) || (errorData && errorData.email)}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <PatternFormat
                                            format="###-###-####"
                                            allowEmptyFormatting
                                            mask="_"
                                            value={values.phone}
                                            onValueChange={(value) => setFieldValue('phone', value.formattedValue)}
                                            customInput={TextField}
                                            {...textFieldProps}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Ext."
                                            {...getFieldProps('ext')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            width: '220px',
                                            height: '30px',
                                            fontWeight: '500',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Other Information
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            select
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Department *"
                                            {...getFieldProps('department')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                            error={Boolean((touched.department && errors.department) || (errorData && errorData.department))}
                                            helperText={(touched.department && errors.department) || (errorData && errorData.department)}
                                        >
                                            <MenuItem value="">Select Department</MenuItem>
                                            {user_type == 'super_dispatcher_admin' && <MenuItem value="dispatcher_admin">Admin</MenuItem>}
                                            <MenuItem value="dispatcher">Dispatcher</MenuItem>
                                            <MenuItem value="accountant">Accountants</MenuItem>
                                            <MenuItem value="hr">HR</MenuItem>
                                            <MenuItem value="auditor">Auditor</MenuItem>
                                        </TextField>
                                    )}
                                </Grid>
                                {((values.user_type == 'dispatcher_admin' && user_type == 'super_dispatcher_admin') ||
                                    values.user_type != 'dispatcher_admin') && (
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                width: '220px',
                                                height: '30px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            Permissions
                                        </Typography>
                                        {Object.entries(groupedItems).map(([group, apis]) => (
                                            <Accordion key={group}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>{group}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Table size="small" padding="none">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell>
                                                                    <Checkbox
                                                                        indeterminate={
                                                                            rows.filter((row) => apis.includes(row.api)).some((row) => row.permissions.view) &&
                                                                            !rows.filter((row) => apis.includes(row.api)).every((row) => row.permissions.view)
                                                                        }
                                                                        checked={rows
                                                                            .filter((row) => apis.includes(row.api))
                                                                            .every((row) => row.permissions.view)}
                                                                        onChange={(event) =>
                                                                            handleSelectAllChangeForPermission(group, 'view', event.target.checked)
                                                                        }
                                                                    />
                                                                    Read
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Checkbox
                                                                        indeterminate={
                                                                            rows.filter((row) => apis.includes(row.api)).some((row) => row.permissions.add) &&
                                                                            !rows.filter((row) => apis.includes(row.api)).every((row) => row.permissions.add)
                                                                        }
                                                                        checked={rows
                                                                            .filter((row) => apis.includes(row.api))
                                                                            .every((row) => row.permissions.add)}
                                                                        onChange={(event) =>
                                                                            handleSelectAllChangeForPermission(group, 'add', event.target.checked)
                                                                        }
                                                                    />
                                                                    Write
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Checkbox
                                                                        indeterminate={
                                                                            rows
                                                                                .filter((row) => apis.includes(row.api))
                                                                                .some((row) => row.permissions.change) &&
                                                                            !rows.filter((row) => apis.includes(row.api)).every((row) => row.permissions.change)
                                                                        }
                                                                        checked={rows
                                                                            .filter((row) => apis.includes(row.api))
                                                                            .every((row) => row.permissions.change)}
                                                                        onChange={(event) =>
                                                                            handleSelectAllChangeForPermission(group, 'change', event.target.checked)
                                                                        }
                                                                    />
                                                                    Update
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Checkbox
                                                                        indeterminate={
                                                                            rows
                                                                                .filter((row) => apis.includes(row.api))
                                                                                .some((row) => row.permissions.delete) &&
                                                                            !rows.filter((row) => apis.includes(row.api)).every((row) => row.permissions.delete)
                                                                        }
                                                                        checked={rows
                                                                            .filter((row) => apis.includes(row.api))
                                                                            .every((row) => row.permissions.delete)}
                                                                        onChange={(event) =>
                                                                            handleSelectAllChangeForPermission(group, 'delete', event.target.checked)
                                                                        }
                                                                    />
                                                                    Delete
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Checkbox
                                                                        checked={rows
                                                                            .filter((row) => apis.includes(row.api))
                                                                            .every(
                                                                                (row) =>
                                                                                    row.permissions.view &&
                                                                                    row.permissions.add &&
                                                                                    row.permissions.change &&
                                                                                    row.permissions.delete
                                                                            )}
                                                                        onChange={(event) => handleSelectAllChange(group, event.target.checked)}
                                                                        style={{ marginLeft: 'auto' }}
                                                                    />
                                                                    All
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows
                                                                .filter((row) => apis.includes(row.api))
                                                                .map((row) => (
                                                                    <TableRow key={row.api}>
                                                                        <TableCell>{row.name}</TableCell>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                checked={row.permissions.view || false}
                                                                                onChange={(e) => handleCheckboxChange(row.api, 'view', e.target.checked)}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                checked={row.permissions.add || false}
                                                                                onChange={(e) => handleCheckboxChange(row.api, 'add', e.target.checked)}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                checked={row.permissions.change || false}
                                                                                onChange={(e) => handleCheckboxChange(row.api, 'change', e.target.checked)}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                checked={row.permissions.delete || false}
                                                                                onChange={(e) => handleCheckboxChange(row.api, 'delete', e.target.checked)}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                checked={
                                                                                    (row.permissions.view &&
                                                                                        row.permissions.add &&
                                                                                        row.permissions.change &&
                                                                                        row.permissions.delete) ||
                                                                                    false
                                                                                }
                                                                                onChange={(e) => handleCheckboxChange(row.api, 'all', e.target.checked)}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                        </TableBody>
                                                    </Table>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </Grid>
                                )}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            multiline
                                            minRows={5}
                                            maxRows={5}
                                            type="textarea"
                                            label="Note"
                                            {...getFieldProps('note')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>

                        <Box sx={{ mt: 2 }} align="center">
                            <AnimateButton>
                                <LoadingFormButton type="submit" btnText="Save" load={isSubmitting} />
                            </AnimateButton>
                        </Box>

                        {/* </Grid> */}
                    </Form>
                </FormikProvider>
            </SubCard>
        </>
    );
};

export default MemberForm;
