// project imports
import {  useEffect } from 'react';

import MainCard from 'ui-component/cards/MainCard';

import OrganizationForm from 'ui-component/setup/forms/OrganizationForm';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';

// ==============================|| SAMPLE PAGE ||============================== //

const EditOrganization = () => {
       const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    return (
        <>
            <MainCard>
                      

                <OrganizationForm companySetup={companySetup} />
              
            </MainCard>
        </>
    );
};

export default EditOrganization;
