import ReactECharts from 'echarts-for-react';
import { MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';

function BolDoughnutChart({ send, isReady }) {
    const [selectedOption, setSelectedOption] = useState('yearly');
    const dashboardState = useSelector((state) => state.dashboard);
    const [isRequest, setIsRequest] = useState(true);

    const isMobile = window.innerWidth < 600;
    const isTablet = window.innerWidth >= 600 && window.innerWidth <= 1024;
    const option = {
        animationDuration: 1500,
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        tooltip: {
            trigger: 'item'
        },
        color: ['#5AC47C', '#D3505F', '#3886BB'],
        legend: {
            bottom: '5%',
            // bottom: isMobile ? '-1%' : '4%',
            left: 'center'
        },
        series: [
            {
                name: 'Status',
                type: 'pie',
                // radius: isMobile ? '40%' : isTablet ? '75%' : '75%',

                // radius: ['40%', '70%'],
                radius: isMobile ? ['30%', '50%'] : ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    fontSize: 40,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: dashboardState.bolsCount || [
                    { value: 0, name: 'Approved' },
                    { value: 0, name: 'Pending' },
                    { value: 0, name: 'Submitted' }
                ]
            }
        ]
    };

    const handleChange = (e) => {
        setIsRequest(true);
        setSelectedOption(e.target.value);
    };

    useEffect(() => {
        if (isRequest && isReady) {
            send({ event_type: 'bols_count', quarter: selectedOption });
            setIsRequest(false);
        }
    }, [isReady, selectedOption]);
    return (
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '20px', borderRadius: '15px', height: '400px' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: { xs: 'start', sm: 'center' },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    }
                }}
            >
                {' '}
                <Typography
                    sx={{
                        fontSize: { xs: '6vw', lg: '1.7vw', sm: '3vw' },
                        margin: 0
                    }}
                >
                    All BOL status
                </Typography>
                <TextField
                    select
                    value={selectedOption}
                    onChange={handleChange}
                    sx={{
                        padding: { xs: '0', sm: '10px 20px' },
                        borderRadius: '5px',
                        fontWeight: '800',
                        WebkitBackgroundOrigin: 'initial',
                        mt: { xs: '1rem', sm: '0' }
                    }}
                >
                    <MenuItem value={'weekly'}>Current Week</MenuItem>
                    <MenuItem value={'monthly'}>Current Month</MenuItem>
                    <MenuItem value={'yearly'}>Current Year</MenuItem>
                </TextField>
            </Box>
            {dashboardState.bolsCount !== undefined ? (
                <ReactECharts option={option} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
            ) : (
                <Skeleton height={'100%'} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
            )}
        </div>
    );
}

export default BolDoughnutChart;
