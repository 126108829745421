import { useState, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import { Button, FormControl, Grid, IconButton, InputAdornment, MenuItem, Skeleton, TextField, Typography, useTheme } from '@mui/material';

// project imports

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
// third party
import * as Yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { Stack } from '@mui/system';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const OtherDocloadForm = ({ id, handleClose }) => {
    const theme = useTheme();
    // const { id } = useParams();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const [loading, setLoading] = useState(false);
    const [loadData, setLoadData] = useState();

    const [addError, setAddError] = useState();

    const dispatch = useDispatch();

    const configAddress = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    function handleDownloadModalDoc(file) {
        if (!file) {
            toast.error('File not found');
            return;
        }
        window.open(file, '_blank');
    }

    const FaqSchema = Yup.object().shape({});

    let empFiles = {
        dsp_load_doc_id: 0,
        oth_doc: '',
        oth_doc_name: '',
        doc_type: ''
    };
    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            files: id && loadData ? loadData.docs : [empFiles]
        },
        validationSchema: FaqSchema,
        onSubmit: async () => {
            const formData = new FormData();

            values.files.forEach((i, index) => formData.append(`oth_docs[${index}].oth_doc`, i.oth_doc));
            values.files.forEach((i, index) => formData.append(`oth_docs[${index}].oth_doc_name`, i.oth_doc_name));
            values.files.forEach((i, index) => formData.append(`oth_docs[${index}].doc_type`, i.doc_type));
            values.files.forEach((i, index) => formData.append(`oth_docs[${index}].dsp_load_doc_id`, i.dsp_load_doc_id));

            try {
                const { data } = await axios.put(`/v1/api/dispatch/load-doc/edit/${id}/`, formData, configAddress);
                dispatch(openSuccessSnackbar('Load Other Doc Updated successfully'));
            } catch (error) {
                setAddError(error.response.data.error);
                if (error.response.data.street) {
                    dispatch(openErrorSnackbar('One of the given address was invalid'));
                } else {
                    dispatch(openErrorSnackbar('Something Went Wrong!'));
                }
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const fetchLoadData = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/dispatch/load/view/${id}/`, config);
            setLoadData(data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id && !loadData) {
            fetchLoadData(id);
            // loadData
        }
    }, [loadData, id]);

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography variant="h2">Edit Doc Load </Typography>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <LoadingFormButton
                                        btnText="Save"
                                        type="submit"
                                        load={isSubmitting && !addError}
                                        variant="contained"
                                        component="span"
                                        size="large"
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            {/* Ratecon upload */}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h2">Other Documents</Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <FieldArray name="files">
                                        {({ push, remove }) => (
                                            <div>
                                                {values.files.map((file, index) => (
                                                    <Stack key={index} direction="row" justifyContent="center" alignItems="center" sx={{ my: 2 }} spacing={2}>
                                                        <TextField
                                                            fullWidth
                                                            className="text-input"
                                                            color="orange"
                                                            select
                                                            label="Type"
                                                            value={values.files[index].doc_type}
                                                            onChange={(e) => {
                                                                setFieldValue(`files[${index}].doc_type`, e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value="other">Other Doc</MenuItem>
                                                            <MenuItem value="bol_doc">Bol Doc</MenuItem>
                                                            <MenuItem value="arc_doc">RC Doc</MenuItem>
                                                        </TextField>

                                                        <TextField
                                                            className="text-input"
                                                            color="orange"
                                                            fullWidth
                                                            value={values.files[index].oth_doc_name}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end">
                                                                            <Button component="label" color="orange" startIcon={<CloudUploadIcon />}>
                                                                                Upload
                                                                                <input
                                                                                    hidden
                                                                                    type="file"
                                                                                    onInputCapture={(event) => {
                                                                                        setFieldValue(`files[${index}].oth_doc`, event.target.files[0]);
                                                                                        setFieldValue(
                                                                                            `files[${index}].oth_doc_name`,
                                                                                            event.target.files[0].name
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            label="Other Documents"
                                                        />
                                                        <IconButton
                                                            onClick={() => handleDownloadModalDoc(values.files[index].oth_doc)}
                                                            endIcon={<FileDownloadIcon fontSize="small" />}
                                                        >
                                                            <FileDownloadIcon fontSize="small" style={{ marginRight: '-30px', marginLeft: '-10px' }} />
                                                        </IconButton>

                                                        {values.files.length > 1 && (
                                                            <IconButton
                                                                disabled={isSubmitting && Boolean(errors.files)}
                                                                onClick={() => {
                                                                    remove(index);
                                                                }}
                                                                size="small"
                                                                color="error"
                                                            >
                                                                <DeleteForeverIcon fontSize="large" />
                                                            </IconButton>
                                                        )}
                                                    </Stack>
                                                ))}

                                                <Grid item xs={6}>
                                                    <Button
                                                        disabled={isSubmitting}
                                                        variant="contained"
                                                        color="orange"
                                                        onClick={() => push(empFiles)}
                                                        size="large"
                                                        sx={{
                                                            borderRadius: '8px',
                                                            fontFamily: 'Lexend',
                                                            fontWeight: 700,
                                                            textTransform: 'uppercase',
                                                            mt: 1,
                                                            mr: 2,
                                                            color: 'white',
                                                            backgroundColor: '#EB812E'
                                                        }}
                                                        startIcon={<AddIcon />}
                                                    >
                                                        Add Other Doc
                                                    </Button>
                                                </Grid>
                                            </div>
                                        )}
                                    </FieldArray>
                                )}
                            </Grid>
                        </Grid>
                    </SubCard>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

export default OtherDocloadForm;
