import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { Button, Grid, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';

import { Card, CardContent, CircularProgress, IconButton, InputAdornment, Menu, MenuItem, Stack, TablePagination, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import axios from 'axios';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

import { createRef, useEffect } from 'react';
import { useState } from 'react';

import AddFormButton from 'ui-component/buttons/AddFormButton';
import UserFilter from 'ui-component/filters/UserFilter';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Status from 'ui-component/buttons/Status';
import user_types from 'constant/user_types';
import { Box, useMediaQuery, width } from '@mui/system';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const AllMemberForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // eslint-disable-next-line prefer-const

    const tableRef = createRef();

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(['doh']);
    const [statusChecked, setStatusChecked] = useState([]);
    const [yearFilter, setYearFilter] = useState('');
    const [searchSts, setSearchSts] = useState('');
    const [searchFltr, setSearchFltr] = useState('');
    const [selected, setSelected] = useState([]);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const cid = loginInfoFromStorage.company_id;
    const permissions = loginInfoFromStorage.permissions;

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const deleteMember = async (id) => {
        try {
            await axios
                .delete(`/v1/api/users/company/users/${id}/`, {
                    data: {},
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${loginInfoFromStorage.token}`
                    }
                })
                .then(dispatch(openSuccessSnackbar('Company User  Deleted')));
            tableRef.current.onQueryChange();
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        if (newChecked1.includes('active') && newChecked1.includes('inactive')) {
            setSearchSts('');
        } else if (newChecked1.includes('active')) {
            setSearchSts(true);
        } else if (newChecked1.includes('inactive')) {
            setSearchSts(false);
        } else {
            setSearchSts('');
        }
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    const editUser = (id) => {
        navigate(`/setup/user/edit/${id}`);
    };

    const viewUser = (id) => {
        navigate(`/setup/user/view/${id}`);
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const reset = () => {
        setYearFilter(null);
        setSearchSts('');
        setChecked([]);
        setStatusChecked([]);

        if (tableRef.current) {
            console.log('tableRef.current:', tableRef.current);
            tableRef.current.onQueryChange();
        } else {
            console.error('tableRef.current is undefined');
        }
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">First Name</th>
                    <th className="headerTd rightHidBor">Email</th>

                    <th className="headerTd rightHidBor">Department</th>

                    <th className="headerTd rightHidBor">Status</th>
                    <th className="headerTd">Actions</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user_id.first_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user_id.email}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.department}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="Inctive" />}
                </td>
                {/* Custom cell or additional cells */}
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <PopupState variant="popover" popupId={`demoMenu-${rowData.data.dis_admin_id}`}>
                        {(popupState) => (
                            <div>
                                <IconButton {...bindTrigger(popupState)} size="large">
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    {...bindMenu(popupState)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    {(permissions.dispatcheradmin && permissions.dispatcheradmin.change) || user_types.includes(user_type) ? (
                                        <MenuItem onClick={() => editUser(rowData.data.dis_admin_id)}>Edit User</MenuItem>
                                    ) : null}
                                    <MenuItem onClick={() => viewUser(rowData.data.dis_admin_id)}>View User</MenuItem>
                                    {(permissions.dispatcheradmin && permissions.dispatcheradmin.delete) || user_types.includes(user_type) ? (
                                        <MenuItem onClick={() => deleteMember(rowData.data.dis_admin_id)}>Remove User</MenuItem>
                                    ) : null}
                                </Menu>
                            </div>
                        )}
                    </PopupState>
                </td>
            </tr>
        );
    };

    const fetchData = async (query) => {
        setLoading(true);

        const filters = [];
        if (yearFilter) filters.push(`status=${encodeURIComponent(yearFilter)}`);
        if (statusChecked) filters.push(`status=${encodeURIComponent(statusChecked)}`);
        try {
            const url = `/v1/api/users/company/users?list?page=${query.page + 1}&page_size=${
                query.pageSize
            }&search=${searchFltr}&company_id=${cid}&is_active=${searchSts}`;

            const response = await fetch(url);
            const result = await response.json();

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 10 })
                .then((result) => {
                    setSelected(result.data || {});
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
        // console.log(selected);
    }, [isMobile, isTablet, currPage, cardPerPage]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    return (
        // <PageLayout url="/setup/add-user">
        <>
            <Stack
                sx={{ mt: 5, mb: 2, flexDirection: { sm: 'column', md: 'row' }, alignItems: { sm: 'flex-start', md: 'baseline' } }}
                justifyContent="space-between"
                spacing={4}
            >
                <TextField
                    type="text"
                    placeholder="Search"
                    size="small"
                    className="search-input"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        if (tableRef.current) {
                            console.log('tableRef.current:', tableRef.current);
                            tableRef.current.onQueryChange();
                        } else {
                            console.error('tableRef.current is undefined');
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    // sx={{ width: { xs: '310px', sm: '350px' } }}
                    sx={{ width: { xs: '84vw', sm: '45vw', lg: '20vw' } }}
                />
                <Box sx={{ display: 'block', whiteSpace: 'nowrap' }}>
                    <UserFilter
                        checked={checked}
                        handleClose={handleClose}
                        handleChecked={handleChecked}
                        yearFilter={yearFilter}
                        yearHandler={(e) => {
                            setYearFilter(e);
                        }}
                        statusChecked={statusChecked}
                        handleStatus={handleStatus}
                        filterHandler={() => {
                            setOpen((prevOpen) => !prevOpen);
                            if (tableRef.current) {
                                tableRef.current.onQueryChange();
                            } else {
                                console.error('tableRef.current is undefined on mobile');
                                const query = {
                                    page: 0,
                                    pageSize: 5
                                };

                                fetchData(query);
                            }
                        }}
                        reset={reset}
                    />

                    {(permissions.dispatcheradmin && permissions.dispatcheradmin.add) || user_types.includes(user_type) ? (
                        <AddFormButton url="/setup/user/add" btnText="ADD NEW USER" />
                    ) : null}
                </Box>
            </Stack>
            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>

                                        <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div style={{ float: 'right', marginTop: '1px' }}>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        {(permissions.dispatcheradmin && permissions.dispatcheradmin.change) ||
                                                        user_types.includes(user_type) ? (
                                                            <MenuItem onClick={() => editUser(driver.dis_admin_id)}>Edit User</MenuItem>
                                                        ) : null}
                                                        <MenuItem onClick={() => viewUser(driver.dis_admin_id)}>View User</MenuItem>
                                                        {(permissions.dispatcheradmin && permissions.dispatcheradmin.delete) ||
                                                        user_types.includes(user_type) ? (
                                                            <MenuItem onClick={() => deleteMember(driver.dis_admin_id)}>Remove User</MenuItem>
                                                        ) : null}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>

                                    <Box sx={{ clear: 'both' }} />

                                    <Typography sx={{ marginBottom: '.5rem' }}>First Name : {driver.user_id.first_name}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Email : {driver.user_id.email}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Department : {driver.department}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected?.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginTop: '2rem',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>

                                        <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div style={{ float: 'right', marginTop: '1px' }}>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        {(permissions.dispatcheradmin && permissions.dispatcheradmin.change) ||
                                                        user_types.includes(user_type) ? (
                                                            <MenuItem onClick={() => editUser(driver.dis_admin_id)}>Edit User</MenuItem>
                                                        ) : null}
                                                        <MenuItem onClick={() => viewUser(driver.dis_admin_id)}>View User</MenuItem>
                                                        {(permissions.dispatcheradmin && permissions.dispatcheradmin.delete) ||
                                                        user_types.includes(user_type) ? (
                                                            <MenuItem onClick={() => deleteMember(driver.dis_admin_id)}>Remove User</MenuItem>
                                                        ) : null}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>

                                    {/* </Box> */}
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}> First Name : {driver.user_id.first_name} </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Email : {driver.user_id.email}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Department : {driver.department} </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                    // data={(query) =>
                    //     new Promise((resolve, reject) => {
                    //         const url = `/v1/api/users/company/users?list?page=${query.page + 1}&page_size=${
                    //             query.pageSize
                    //         }&search=${searchFltr}&company_id=${cid}&is_active=${searchSts}`;

                    //         fetch(url)
                    //             .then((response) => response.json())
                    //             .then((result) => {
                    //                 resolve({
                    //                     data: result.results,
                    //                     page: query.page,
                    //                     totalCount: result.count
                    //                 });
                    //             });
                    //     })
                    // }
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchData(query)
                                .then((result) => resolve(result))
                                .catch((error) => reject(error));
                        })
                    }
                    title="All Truck"
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,
                        toolbar: false,
                        draggable: false,
                        columnResizable: true
                    }}
                />
            )}
            {/* </PageLayout> */}
        </>
    );
};

export default AllMemberForm;
