import MaterialTable from '@material-table/core';
import TABLE_ICONS from 'tableIcons';
import { useDispatch } from 'react-redux';

import {
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Stack,
    TablePagination,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

import AddFormButton from 'ui-component/buttons/AddFormButton';

import { useEffect, useState } from 'react';
import { createRef } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import ViewCustomerModal from 'ui-component/modals/ViewCustomerModal';
import SearchIcon from '@mui/icons-material/Search';
import user_types from 'constant/user_types';
import { Box } from '@mui/system';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';

const AllCustomerForm = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();

    const tableRef = createRef();
    const [open, setOpen] = useState(false);

    const [searchSts, setSearchSts] = useState(true);
    const [searchFltr, setSearchFltr] = useState('');

    const [customerId, setCustomerId] = useState();
    const [type, setType] = useState();

    const [selected, setSelected] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = [
        'dispatcher_admin',
        'accountant',
        'dispatcher',
        'auditor',
        'self_dispatch',
        'carrier',
        'mechanic_admin',
        'hr',
        'super_dispatcher_admin'
    ].includes(loginInfoFromStorage.user_type)
        ? loginInfoFromStorage.company_id
        : 0;
    const user_type = loginInfoFromStorage && loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};

    const handleCloseModal = () => {
        setOpen(false);
    };
    const handleClickOpenModal = (id) => {
        setType('view');
        setCustomerId(id);
        setOpen(true);
    };

    const deleteCustomer = async (id) => {
        try {
            await axios.delete(`/v1/api/dispatch/customer/view/${id}/`, {
                data: {},
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage.token}`
                }
            });

            dispatch(openSuccessSnackbar('Company User  Deleted'));
            tableRef.current.onQueryChange();
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const editCustomer = (id) => {
        setType('edit');
        setCustomerId(id);

        setOpen(true);
    };

    const tableRelaod = () => {
        tableRef.current.onQueryChange();
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Customer Name</th>
                    <th className="headerTd rightHidBor">MC#</th>
                    <th className="headerTd rightHidBor">DOT#</th>
                    <th className="headerTd rightHidBor">Full Address</th>
                    <th className="headerTd rightHidBor">Phone</th>
                    <th className="headerTd rightHidBor">Billing Email</th>
                    <th className="headerTd">Actions</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.customer_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.mc_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.dot_no}
                </td>
                {/* <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.dot_no}
                </td> */}
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.location}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.phone && rowData.data.phone !== '' ? (
                        <PatternFormat value={rowData.data.phone} displayType={'text'} format="###-###-####" />
                    ) : (
                        ''
                    )}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.billing_email}
                </td>
                {/* Custom cell or additional cells */}
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <PopupState variant="popover" popupId={`demoMenu-${rowData.data.driver_id}`}>
                        {(popupState) => (
                            <div>
                                <IconButton {...bindTrigger(popupState)} size="large">
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    {...bindMenu(popupState)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    {(permissions.dspcompanycustomer && permissions.dspcompanycustomer.change) || user_types.includes(user_type) ? (
                                        <MenuItem onClick={() => editCustomer(rowData.data.dsp_customer_id, popupState)}>Edit Customer</MenuItem>
                                    ) : null}
                                    {(permissions.dspcompanycustomer && permissions.dspcompanycustomer.view) || user_types.includes(user_type) ? (
                                        <MenuItem onClick={() => handleClickOpenModal(rowData.data.dsp_customer_id)}>View Customer</MenuItem>
                                    ) : null}
                                    {(user_type === 'super_dispatcher_admin' || user_type == 'dispatcher_admin') && (
                                        <MenuItem onClick={() => deleteCustomer(rowData.data.dsp_customer_id, popupState)}>Remove Customer</MenuItem>
                                    )}
                                </Menu>
                            </div>
                        )}
                    </PopupState>
                </td>
            </tr>
        );
    };

    const fetchData = async (query) => {
        setLoading(true); // Start loading

        try {
            const url = `/v1/api/dispatch/customer/list?page=${query.page + 1}&page_size=${query.pageSize}&search=${searchFltr}&is_active=${searchSts}`;

            const response = await fetch(url);
            const result = await response.json();

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 10 })
                .then((result) => {
                    setSelected(result.data || {});
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
        // console.log(selected);
    }, [isMobile, isTablet, currPage, cardPerPage]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    return (
        <>
            <Stack
                sx={{ mt: 5, mb: 2 }}
                direction={matchDownMd ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={matchDownMd ? 'flex-start' : 'center'}
                spacing={4}
            >
                <TextField
                    type="text"
                    size="small"
                    placeholder="Search by Name, Email, MC, Address, Phone"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    // sx={{ width: '350px' }}
                    sx={{ width: { xs: '82vw', sm: '45vw', lg: '20vw' } }}
                    className="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                {/* <Box sx={{ width: '60vw' }}> */}
                {(permissions.dspcompanycustomer && permissions.dspcompanycustomer.add) || user_types.includes(user_type) ? (
                    <AddFormButton url="/dispatch-center/customer/add" btnText="ADD NEW CUSTOMER" />
                ) : // <AddFormButton url="/dispatch-center/customer/add" btnText="ADD NEW CUSTOMER" sx={{ width: '60vw' }} />
                null}
                {/* </Box> */}
            </Stack>

            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                        {(popupState) => (
                                            <div style={{ float: 'right', marginTop: '-14px' }}>
                                                <IconButton {...bindTrigger(popupState)} size="large">
                                                    <MoreHorizIcon />
                                                </IconButton>
                                                <Menu
                                                    {...bindMenu(popupState)}
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                >
                                                    {(permissions.dspcompanycustomer && permissions.dspcompanycustomer.change) ||
                                                    user_types.includes(user_type) ? (
                                                        <MenuItem onClick={() => editCustomer(driver.dsp_customer_id, popupState)}>Edit Customer</MenuItem>
                                                    ) : null}
                                                    {(permissions.dspcompanycustomer && permissions.dspcompanycustomer.view) ||
                                                    user_types.includes(user_type) ? (
                                                        <MenuItem onClick={() => handleClickOpenModal(driver.dsp_customer_id)}>View Customer</MenuItem>
                                                    ) : null}
                                                    {(user_type === 'super_dispatcher_admin' || user_type == 'dispatcher_admin') && (
                                                        <MenuItem onClick={() => deleteCustomer(driver.dsp_customer_id, popupState)}>Remove Customer</MenuItem>
                                                    )}
                                                </Menu>
                                            </div>
                                        )}
                                    </PopupState>

                                    <Typography sx={{ marginBottom: '.5rem' }}>Customer Name : {driver.customer_name}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>MC# : {driver.mc_no}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>DOT# : {driver.dot_no}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Full Address : {driver.location}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Phone : {driver.phone}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Billing Email : {driver.billing_email}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginTop: '2rem',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                        {(popupState) => (
                                            <div style={{ float: 'right', marginTop: '-14px' }}>
                                                <IconButton {...bindTrigger(popupState)} size="large">
                                                    <MoreHorizIcon />
                                                </IconButton>
                                                <Menu
                                                    {...bindMenu(popupState)}
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                >
                                                    {(permissions.dspcompanyload && permissions.dspcompanyload.change) || user_types.includes(user_type) ? (
                                                        <MenuItem onClick={() => editLoad(driver.dsp_load_id, popupState)}>Edit Load</MenuItem>
                                                    ) : null}
                                                    {(permissions.dspcompanyload && permissions.dspcompanyload.view) || user_types.includes(user_type) ? (
                                                        <MenuItem onClick={() => viewLoad(driver.dsp_load_id, popupState)}>View Load</MenuItem>
                                                    ) : null}
                                                    {(permissions.dspcompanyload && permissions.dspcompanyload.delete) || user_types.includes(user_type) ? (
                                                        <MenuItem onClick={() => deleteLoad(driver.dsp_load_id, popupState)}>Remove Load</MenuItem>
                                                    ) : null}
                                                </Menu>
                                            </div>
                                        )}
                                    </PopupState>
                                    {/* </Box> */}
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1.5fr 1fr',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Customer Name : {driver.customer_name}</Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>MC# : {driver.mc_no}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1.5fr 1fr',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>DOT# : {driver.dot_no}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Full Address : {driver.location}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1.5fr 1fr',
                                            gap: '1.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Billing Email : {driver.billing_email}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Phone : {driver.phone}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[20, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchData(query)
                                .then((result) => resolve(result))
                                .catch((error) => reject(error));
                        })
                    }
                    title="All Customer"
                    columns={[
                        {
                            title: 'Customer Name',
                            field: 'customer_name'
                        },
                        {
                            title: 'MC#',
                            field: 'mc_no'
                        },
                        {
                            title: 'DOT#',
                            field: 'dot_no'
                        },
                        {
                            title: 'Full Address',
                            field: 'address'
                        },

                        {
                            title: 'Phone No',
                            field: 'user_id.phone'
                        },

                        {
                            title: 'Billing Email',
                            field: 'billing_email'
                        },

                        {
                            title: 'Actions',
                            filtering: false,
                            align: 'center',
                            render: (rowData) => (
                                <>
                                    <IconButton aria-label="more" id={`cust-menu-${rowData.dsp_customer_id}`} onClick={(e) => setCaRef(e.currentTarget)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    {caRef && (
                                        <Menu
                                            id={`cust-menu-${rowData.dsp_customer_id}`}
                                            anchorEl={caRef}
                                            open={Boolean(caRef)}
                                            onClose={() => setCaRef(null)}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: 48 * 4.5,
                                                    width: '20ch'
                                                },
                                                elevation: 1
                                            }}
                                        >
                                            <MenuItem onClick={() => editCustomer(rowData.dsp_customer_id)}>Edit Customer</MenuItem>
                                            <MenuItem onClick={() => viewCustomer(rowData.dsp_customer_id)}>View Customer</MenuItem>
                                            <MenuItem onClick={() => deleteCustomer(rowData.dsp_customer_id)}>Remove Customer</MenuItem>
                                        </Menu>
                                    )}
                                </>
                            )
                        }
                    ]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    // isLoading={loadingJobsList}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,

                        toolbar: false,
                        draggable: false,
                        columnResizable: true,
                        pageSize: 25,
                        pageSizeOptions: [25, 50, 100]
                    }}
                />
            )}
            {/* </SubCard> */}
            <ViewCustomerModal open={open} handleClose={handleCloseModal} customerId={customerId} type={type} tableRelaod={tableRelaod} />
        </>
    );
};

export default AllCustomerForm;
