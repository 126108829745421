import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import { forwardRef } from 'react';

import AddExpenseItemForm from 'ui-component/setup/forms/AddExpenseItemForm';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function NewExpenseItemModal({
    fullWidth,
    fullScreen,
    maxWidth,
    open,
    handleClose,
    type,
    fileId = '',

    reloadHandler
}) {
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogTitle>
                <Typography variant="h4" color="primary">
                    Expense Type
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <AddExpenseItemForm handleClose={handleClose} fileId={fileId} reloadHandler={reloadHandler} type={type} />
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    );
}

NewExpenseItemModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'sm'
};

export default NewExpenseItemModal;
