// import MaterialTable from '@material-table/core';
import MaterialTable from '@material-table/core';
import TABLE_ICONS from 'tableIcons';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    InputAdornment,
    Link,
    TablePagination,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import { createRef, useState, useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router';
import Status from 'ui-component/buttons/Status';
import { Box, Stack, useTheme } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

const AllFuelDriverForm = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [companyStp, setCompanyStp] = useState();
    const [loading, setLoading] = useState(false);
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const tableRef = createRef();
    const [searchText, setSearchText] = useState('');
    const [selected, setSelected] = useState([]);

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    useEffect(() => {
        if (!companyStp) {
            fetchCompany();
        }
    }, []);

    const fetchCompany = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);

            setCompanyStp(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const syncFuel = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/sync-wex-transaction/`, config);
            dispatch(openSuccessSnackbar('Fuel Synced Successfully'));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error.response.data);
            dispatch(openErrorSnackbar(error.response.data.error));
        }
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* <th className="headerTd rightHidBor"></th> */}
                    <th className="headerTd rightHidBor">Truck Number</th>
                    <th className="headerTd rightHidBor ">Driver Name</th>
                    <th className="headerTd rightHidBor">Trailer</th>
                    <th className="headerTd rightHidBor">Staus</th>
                    <th className="headerTd rightHidBor" style={{ borderRight: '1px solid black' }}>
                        Fuel Details
                    </th>
                </tr>
            </thead>
        );
    };

    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Link
                        component="button"
                        onClick={() => {
                            navigate(`/fleet/truck/view/${rowData.data.truck_id}`);
                        }}
                    >
                        {rowData.data.unit_no}
                    </Link>
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Link
                        component="button"
                        onClick={() => {
                            navigate(`/fleet/driver/view/${rowData.data.driver.driver_id}`);
                        }}
                    >
                        {rowData.data.driver.driver_name}
                    </Link>
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {
                        <Link
                            component="button"
                            onClick={() => {
                                navigate(`/fleet/trailer/view/${rowData.data.driver.trailer_id}`);
                            }}
                        >
                            {rowData.data.driver.trailer_unit_no}
                        </Link>
                    }
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="Inctive" />}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData), borderRight: '1px solid black' }}>
                    <IconButton>
                        <Link
                            component="button"
                            onClick={() => {
                                navigate(
                                    `/accounting/fuel-reports/driver/${rowData.data.truck_id}/?driver_name=${
                                        rowData.data.driver.driver_name ? encodeURIComponent(rowData.data.driver.driver_name) : ''
                                    }&truck_number=${rowData.data.unit_no ? encodeURIComponent(rowData.data.unit_no) : ''}&trailer_number=${
                                        rowData.data.driver.trailer_unit_no ? encodeURIComponent(rowData.data.driver.trailer_unit_no) : ''
                                    }`
                                );
                            }}
                        >
                            <VisibilityIcon />
                        </Link>
                    </IconButton>
                </td>
            </tr>
        );
    };

    const fetchData = async (query) => {
        setLoading(true); // Start loading

        try {
            const url = `/v1/api/accounting/get_truck-drivers/?page=${query.page + 1}&page_size=${query.pageSize}&search=${searchText}`;

            const response = await fetch(url, config);
            const result = await response.json();

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 10 })
                .then((result) => {
                    setSelected(result.data || {});
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
        console.log(selected);
    }, [isMobile, isTablet, currPage, cardPerPage]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    console.log(selected);

    return (
        <>
            <Stack
                sx={{ mt: 5, border: 'none', mb: 2, background: 'transparent' }}
                direction={matchDownMd ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={matchDownMd ? 'flex-start' : 'center'}
                spacing={5}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        tableRef.current.onQueryChange();
                    }}
                >
                    <TextField
                        type="text"
                        size="small"
                        placeholder="Search by Truck, Driver or Trailer..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        // sx={{ width: { xs: '310px', sm: '350px' } }}
                        sx={{ width: { xs: '82vw', sm: '45vw', lg: '20vw' } }}
                        className="search-input"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            ml: { xs: 0, sm: 2 },
                            mt: { xs: '.5rem', sm: 0 },
                            borderRadius: '4px',
                            color: 'white',
                            backgroundColor: '#EB812E',
                            // minWidth: { xs: '19.3rem', sm: '10rem' }
                            minWidth: { xs: '80vw', sm: '10rem' }
                        }}
                        color="orange"
                    >
                        Search
                    </Button>
                </form>
                {companyStp && companyStp.is_fuel_integrated && (
                    <LoadingButton
                        // disabled={companySetup && companySetup.is_fuel_integrated === false}
                        loading={loading}
                        onClick={() => syncFuel()}
                        variant="contained"
                        sx={{
                            color: 'white',
                            backgroundColor: '#EB812E',
                            width: '15%',
                            fontWeight: 500,
                            fontSize: 14,
                            // minWidth: { xs: '19.3rem', sm: '10rem' }
                            minWidth: { xs: '80vw', sm: '10rem' }
                        }}
                        color="orange"
                    >
                        Sync Wex Data
                    </LoadingButton>
                )}
            </Stack>

            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        Array.isArray(selected) &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>
                                    </Box>

                                    <Box sx={{ clear: 'both' }} />

                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Truck Number :{' '}
                                        <Link
                                            component="button"
                                            onClick={() => {
                                                navigate(`/fleet/truck/view/${driver.truck_id}`);
                                            }}
                                        >
                                            {driver.unit_no}
                                        </Link>
                                    </Typography>

                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Driver Name :{' '}
                                        <Link
                                            component="button"
                                            onClick={() => {
                                                navigate(`/fleet/driver/view/${driver.driver.driver_id}`);
                                            }}
                                        >
                                            {driver.driver.driver_name}
                                        </Link>
                                    </Typography>
                                    <Typography>
                                        Trailer :{' '}
                                        <Link
                                            component="button"
                                            onClick={() => {
                                                navigate(`/fleet/trailer/view/${driver.driver.trailer_id}`);
                                            }}
                                        >
                                            {driver.driver.trailer_unit_no}
                                        </Link>
                                    </Typography>
                                    <Typography>
                                        Fuel Details :{' '}
                                        <IconButton>
                                            <Link
                                                component="button"
                                                onClick={() => {
                                                    navigate(
                                                        `/accounting/fuel-reports/driver/${driver.truck_id}/?driver_name=${
                                                            driver.driver.driver_name ? encodeURIComponent(driver.driver.driver_name) : ''
                                                        }&truck_number=${driver.unit_no ? encodeURIComponent(driver.unit_no) : ''}&trailer_number=${
                                                            driver.driver.trailer_unit_no ? encodeURIComponent(driver.driver.trailer_unit_no) : ''
                                                        }`
                                                    );
                                                }}
                                            >
                                                <VisibilityIcon />
                                            </Link>
                                        </IconButton>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected?.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginTop: '2rem',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>
                                    </Box>

                                    {/* </Box> */}
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '.5fr 1fr',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            Truck Number :{' '}
                                            <Link
                                                component="button"
                                                onClick={() => {
                                                    navigate(`/fleet/truck/view/${driver.truck_id}`);
                                                }}
                                            >
                                                {driver.unit_no}
                                            </Link>{' '}
                                        </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            Driver Name :{' '}
                                            <Link
                                                component="button"
                                                onClick={() => {
                                                    navigate(`/fleet/driver/view/${driver.driver.driver_id}`);
                                                }}
                                            >
                                                {driver.driver.driver_name}
                                            </Link>
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '.5fr 1fr',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            Trailer :{' '}
                                            <Link
                                                component="button"
                                                onClick={() => {
                                                    navigate(`/fleet/trailer/view/${driver.driver.trailer_id}`);
                                                }}
                                            >
                                                {driver.driver.trailer_unit_no}
                                            </Link>{' '}
                                        </Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw', alignItems: 'center' }}>
                                            Fuel Details :{' '}
                                            <IconButton>
                                                <Link
                                                    component="button"
                                                    onClick={() => {
                                                        navigate(
                                                            `/accounting/fuel-reports/driver/${driver.truck_id}/?driver_name=${
                                                                driver.driver.driver_name ? encodeURIComponent(driver.driver.driver_name) : ''
                                                            }&truck_number=${driver.unit_no ? encodeURIComponent(driver.unit_no) : ''}&trailer_number=${
                                                                driver.driver.trailer_unit_no ? encodeURIComponent(driver.driver.trailer_unit_no) : ''
                                                            }`
                                                        );
                                                    }}
                                                >
                                                    <VisibilityIcon />
                                                </Link>
                                            </IconButton>
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[20, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchData(query)
                                .then((result) => resolve(result))
                                .catch((error) => reject(error));
                        })
                    }
                    title=""
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,

                        toolbar: false,
                        draggable: false,
                        columnResizable: true
                    }}
                />
            )}
        </>
    );
};

export default AllFuelDriverForm;
