import { SvgIcon } from '@mui/material';
import React from 'react';

function MCNDriverIcon() {
    return (
        <SvgIcon>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_8003_24377)">
                    <path
                        d="M13.5586 14.7969V15.2879C13.8079 15.5882 14.6199 16.4156 16.0086 16.4156C17.3944 16.4156 18.2107 15.5847 18.4586 15.2879V14.7969C17.7368 15.2096 16.9013 15.4501 16.0086 15.4501C15.1159 15.4501 14.2804 15.2096 13.5586 14.7969Z"
                        fill="#142953"
                    />
                    <path
                        d="M11.1089 10.5308V7.8125C10.54 8.01239 10.1289 8.54347 10.1289 9.17191C10.1289 9.80058 10.54 10.331 11.1089 10.5308Z"
                        fill="#142953"
                    />
                    <path
                        d="M12.0652 19.4873L3.75957 11.3047V13.0358C3.75957 13.2671 3.71603 13.4858 3.6543 13.6977L10.6779 20.6176C11.0914 20.1874 11.5565 19.8079 12.0652 19.4873Z"
                        fill="#142953"
                    />
                    <path
                        d="M16.0092 20.278C18.8159 20.278 21.1257 22.4031 21.3743 25.1056H22.3543C22.1024 21.8706 19.3559 19.3125 16.0092 19.3125C12.6624 19.3125 9.916 21.8706 9.66406 25.1056H10.6441C10.8927 22.4031 13.2024 20.278 16.0092 20.278Z"
                        fill="#142953"
                    />
                    <path
                        d="M1.79883 8.29688V14.3945C2.36778 14.1951 2.77883 13.664 2.77883 13.0356V9.65628C2.77883 9.02785 2.36778 8.49677 1.79883 8.29688Z"
                        fill="#142953"
                    />
                    <path
                        d="M11.1089 5.7931H12.0889V6.27586H14.7325C15.7914 6.27586 16.8164 5.97037 17.6973 5.39143L18.0321 5.17174L18.3158 5.45178C18.7582 5.88763 19.3207 6.15942 19.9294 6.24451V5.7931H20.9094V6.80742C21.2735 6.88072 21.6032 7.04007 21.8894 7.2534V5.05553C21.8894 3.90803 21.2422 2.87581 20.2005 2.36264L20.1294 2.32728L19.3477 1.55789C18.3276 0.55324 16.9717 0 15.5292 0C12.5509 0 10.1289 2.38669 10.1289 5.32048V7.25387C10.4151 7.04054 10.7448 6.88072 11.1089 6.80789V5.7931Z"
                        fill="#142953"
                    />
                    <path
                        d="M19.9298 10.6201V7.21767C19.1884 7.1413 18.4931 6.8589 17.916 6.39453C16.9501 6.94919 15.8574 7.24077 14.7334 7.24077H12.0898V10.6201C12.0898 12.7496 13.8484 14.4822 16.0098 14.4822C18.1713 14.4822 19.9298 12.7496 19.9298 10.6201ZM13.5598 9.17181C13.2895 9.17181 13.0698 8.95541 13.0698 8.68905C13.0698 8.42268 13.2895 8.20629 13.5598 8.20629C13.8302 8.20629 14.0498 8.42268 14.0498 8.68905C14.0498 8.95541 13.8302 9.17181 13.5598 9.17181ZM16.0098 13.0339C14.9289 13.0339 14.0498 12.1678 14.0498 11.1028H15.0298C15.0298 11.6353 15.4694 12.0684 16.0098 12.0684C16.5503 12.0684 16.9898 11.6353 16.9898 11.1028H17.9698C17.9698 12.1678 17.0908 13.0339 16.0098 13.0339ZM17.9698 8.68905C17.9698 8.42268 18.1895 8.20629 18.4598 8.20629C18.7302 8.20629 18.9498 8.42268 18.9498 8.68905C18.9498 8.95541 18.7302 9.17181 18.4598 9.17181C18.1895 9.17181 17.9698 8.95541 17.9698 8.68905Z"
                        fill="#142953"
                    />
                    <path
                        d="M20.9082 10.5313C21.4772 10.3314 21.8882 9.80034 21.8882 9.17191C21.8882 8.54347 21.4772 8.01239 20.9082 7.8125V10.5313Z"
                        fill="#142953"
                    />
                    <path
                        d="M14.2263 21.6172C13.6638 21.8635 13.1655 22.223 12.7559 22.6652L18.1724 28.0017H18.732C18.5634 27.7165 18.4595 27.3895 18.4595 27.0362C18.4595 26.6788 18.5648 26.3488 18.7373 26.0615L14.2263 21.6172Z"
                        fill="#142953"
                    />
                    <path
                        d="M23.3595 25.1029H24.3395V26.0684H20.4195C19.879 26.0684 19.4395 26.5014 19.4395 27.0339C19.4395 27.5664 19.879 27.9995 20.4195 27.9995H25.3195V23.1719H23.3595V25.1029Z"
                        fill="#142953"
                    />
                    <path d="M9.63867 21.9915C9.765 21.7758 9.90233 21.5677 10.0497 21.3664L9.63867 20.9609V21.9915Z" fill="#142953" />
                    <path
                        d="M16.0089 21.2422C15.7653 21.2422 15.5277 21.2667 15.2949 21.3044L19.4295 25.3779C19.7133 25.2129 20.0392 25.1125 20.3904 25.1071C20.1468 22.9366 18.2761 21.2422 16.0089 21.2422Z"
                        fill="#142953"
                    />
                    <path
                        d="M24.3007 16.9286L19.1483 15.9766C18.7324 16.4454 17.6926 17.3801 16.0094 17.3801C14.3263 17.3801 13.2865 16.4454 12.8706 15.9773L10.3594 16.4412L12.962 19.0056C13.8918 18.586 14.9215 18.3456 16.0094 18.3456C18.7329 18.3456 21.1099 19.816 22.3794 21.9898V18.8283H23.3594V22.2076H26.2994V19.3009C26.2994 18.1405 25.4587 17.1424 24.3007 16.9286Z"
                        fill="#142953"
                    />
                    <path d="M8.65875 19.9925L6.33508 17.7031C5.94174 18.1416 5.71875 18.704 5.71875 19.2997V22.2064H8.65875V19.9925Z" fill="#142953" />
                    <path
                        d="M11.5992 26.0684H7.67922V25.1029H8.65922V23.1719H6.69922V27.9995H11.5992C12.1397 27.9995 12.5792 27.5664 12.5792 27.0339C12.5792 26.5014 12.1397 26.0684 11.5992 26.0684Z"
                        fill="#142953"
                    />
                    <path
                        d="M11.627 25.106C12.6945 25.1216 13.5585 25.9789 13.5585 27.0342C13.5585 27.3876 13.4546 27.7145 13.286 27.9997H16.7856L12.1708 23.4531C11.8842 23.9519 11.6935 24.5103 11.627 25.106Z"
                        fill="#142953"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_8003_24377">
                        <rect width="28" height="28" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}

export default MCNDriverIcon;
