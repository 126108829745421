import CloseIcon from '@mui/icons-material/Close';
import { Grid, Skeleton, TextField, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { queryClient } from 'index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

import { LoadingButton } from '@mui/lab';
import { MenuItem } from '@mui/material';

const validationSchema = Yup.object({
    id: Yup.number().notRequired(),
    transaction_date: Yup.string().required('Transaction Date is required'),
    // invoice: Yup.number().required('Invoice number is required'),
    // location_name: Yup.string().required('Location Name is required'),
    // city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    total_gallons: Yup.number().required('Total Gallons is required'),
    total_amount: Yup.number().required('Total Amount is required')
});

function EditFuelForm({ editDrawer, setEditDrawer, fuelReport, setClickedOptionId, changeEditStatus, search, page, rowsPerPage }) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const isMobile = useMediaQuery('(max-width:600px)');

    const editFuelReport = async (formData) => {
        const response = await axios.put(`/v1/api/accounting/fuel-reports/${fuelReport?.id}/`, formData, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response;
    };

    const fetchStateList = async () => {
        const { data } = await axios.get(`/v1/api/users/state/list/233/`);

        return data;
    };

    const getCityList = async (state) => {
        const { data } = await axios.get(`/v1/api/users/city/list/${state}/`);

        return data;
    };

    const { mutate: editFuelReportMutate, isPending: editFuelReportPending } = useMutation({
        mutationFn: editFuelReport,
        onSuccess: async () => {
            toast.success('fuel report updated successfully');
            setEditDrawer(false);
            resetForm();
            setClickedOptionId((prev) => ({ id: fuelReport?.id, open: !prev.open }));
            console.log('before edit status done');
            changeEditStatus({
                id: fuelReport?.id,
                status: 'idle'
            });

            console.log('before await done');

            await queryClient.invalidateQueries({ queryKey: ['fuel-reports', search, page, rowsPerPage] });

            console.log('await done');
        }
    });

    // Initial values for Formik form validation
    const initialValues = {
        id: fuelReport.id || '',
        transaction_date: fuelReport.transaction_date && fuelReport.transaction_date !== '' ? fuelReport.transaction_date + 'T00:00' : '',
        invoice: fuelReport.invoice || '',
        location_name: fuelReport.location_name || '',
        city: fuelReport.city || '',
        city_name: fuelReport.city_name || '',
        state: fuelReport.state || '',
        state_name: fuelReport.state_name || '',
        total_gallons: fuelReport.total_gallons || '',
        total_amount: fuelReport.total_amount || '',
        truck: fuelReport.truck || ''
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, _action) => {
            // Deep clone the values object
            const data = JSON.parse(JSON.stringify(values));

            data.transaction_date = format(new Date(values.transaction_date), 'yyyy-MM-dd');
            // Create a new FormData object
            const formData = new FormData();

            // Append the transformed data to the formData object
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            editFuelReportMutate(formData);
        }
    });

    const {
        data: states,
        isLoading: statesLoading,
        isFetching: statesFetching
    } = useQuery({
        queryKey: ['states'],
        queryFn: fetchStateList,
        staleTime: 1000 * 60 * 60 * 5
    });

    const {
        data: cities,
        isLoading: citiesLoading,
        isFetching: citiesFetching
    } = useQuery({
        queryKey: ['cities', values.state],
        queryFn: () => getCityList(values.state),
        enabled: !!values.state,
        staleTime: 1000 * 60 * 60 * 5
    });

    return (
        <Drawer
            open={editDrawer}
            anchor="right"
            onClose={() => {
                setEditDrawer(false);
            }}
        >
            <div style={{ width: isMobile ? '100vw' : '70vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Update fuel report
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setEditDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            {/* <input type="hidden" name="Id" value={(editRowData && editRowData['Id']) || values['Id']} /> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    className="text-input"
                                    label="Transaction Date"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={values.transaction_date}
                                    onChange={(newValue) => {
                                        setFieldValue('transaction_date', newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            name="transaction_date"
                                            color="orange"
                                            fullWidth
                                            {...params}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.transaction_date && errors.transaction_date)}
                                            helperText={touched.transaction_date && errors.transaction_date}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="invoice"
                                label="Invoice"
                                value={values.invoice}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.invoice && errors.invoice)}
                                helperText={touched.invoice && errors.invoice}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="location_name"
                                label="Location Name"
                                value={values.location_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.location_name && errors.location_name)}
                                helperText={touched.location_name && errors.location_name}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            {statesLoading && statesFetching ? (
                                <Skeleton height="50px" />
                            ) : (
                                <TextField
                                    select
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    name="state"
                                    label="State"
                                    value={values.state}
                                    onChange={(e) => {
                                        setFieldValue('state', e.target.value);
                                        setFieldValue('state_name', states.filter((state) => e.target.value === state.id)[0]?.name);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.state && errors.state)}
                                    helperText={touched.state && errors.state}
                                >
                                    {states &&
                                        states.map((state) => (
                                            <MenuItem key={state.id} value={state.id}>
                                                {state.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            )}
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            {citiesLoading && citiesFetching ? (
                                <Skeleton height="50px" />
                            ) : (
                                <TextField
                                    select
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    name="city"
                                    label="City"
                                    value={values.city}
                                    onChange={(e) => {
                                        setFieldValue('city', e.target.value);
                                        setFieldValue('city_name', cities.filter((city) => e.target.value === city.id)[0]?.name);
                                    }}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.city && errors.city)}
                                    helperText={touched.city && errors.city}
                                >
                                    {(cities?.length === 0 || cities === undefined) && <MenuItem value="">No City available</MenuItem>}
                                    {cities &&
                                        cities?.map((city) => (
                                            <MenuItem key={city.id} value={city.id}>
                                                {city.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            )}
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <TextField
                                type="number"
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="total_gallons"
                                label="Total Gallons"
                                value={values.total_gallons}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.total_gallons && errors.total_gallons)}
                                helperText={touched.total_gallons && errors.total_gallons}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                type="number"
                                className="text-input"
                                color="orange"
                                name="total_amount"
                                label="Total Amount"
                                value={values.total_amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.total_amount && errors.total_amount)}
                                helperText={touched.total_amount && errors.total_amount}
                            />
                        </Grid>
                    </Grid>
                    <LoadingButton
                        loading={editFuelReportPending}
                        type="submit"
                        fullWidth
                        variant="contained"
                        style={{ backgroundColor: '#EB812E', margin: '20px 0' }}
                    >
                        Update
                    </LoadingButton>
                </form>
            </div>
        </Drawer>
    );
}

export default EditFuelForm;
