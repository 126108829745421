import {
    Divider,
    List,
    ListItemText,
    Stack,
    useTheme,
    Box,
    Switch,
    ListItem,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Popover,
    useMediaQuery
} from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PropTypes from 'prop-types';
function DriverFilter({
    checked,
    handleChecked,
    startDoh,
    startdohHandler,
    endDoh,
    enddohHandler,
    startDot,
    startdotHandler,
    endDot,
    enddotHandler,
    filterHandler,
    handleStatus,
    statusChecked,
    reset
}) {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <PopupState variant="popover" popupId="filter-popup">
            {(popupState) => (
                <>
                    <Button
                        variant="contained"
                        sx={{ mr: matchDownMd ? 1 : 2, color: 'white', backgroundColor: '#EB812E' }}
                        color="orange"
                        startIcon={<FilterAltIcon />}
                        {...bindTrigger(popupState)}
                    >
                        FILTER
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                            {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                            <Box sx={{ px: 2 }}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        maxWidth: 350,
                                        minWidth: 300,
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            minWidth: '100%'
                                        },
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5
                                        }
                                    }}
                                >
                                    <ListItem>
                                        <ListItemText primary="Date of Hire" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('doh')}
                                            checked={checked.indexOf('doh') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('doh') !== -1 ? (
                                        <>
                                            <ListItem>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            className="text-input"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            label="Start Date"
                                                            value={startDoh}
                                                            onChange={startdohHandler}
                                                            renderInput={(params) => (
                                                                <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            className="text-input"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            label="End Date"
                                                            value={endDoh}
                                                            onChange={enddohHandler}
                                                            renderInput={(params) => (
                                                                <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Stack>
                                            </ListItem>
                                            <Divider variant="middle" component="li" />
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    <ListItem>
                                        {/* <ListItemIcon><WifiIcon /></ListItemIcon> */}

                                        <ListItemText primary="Date of Termination" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('dot')}
                                            checked={checked.indexOf('dot') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('dot') !== -1 ? (
                                        <ListItem>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        className="text-input"
                                                        openTo="day"
                                                        views={['year', 'month', 'day']}
                                                        label="Start Date"
                                                        value={startDot}
                                                        onChange={startdotHandler}
                                                        renderInput={(params) => (
                                                            <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        className="text-input"
                                                        openTo="day"
                                                        views={['year', 'month', 'day']}
                                                        label="End Date"
                                                        value={endDot}
                                                        onChange={enddotHandler}
                                                        renderInput={(params) => (
                                                            <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Stack>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        {/* <ListItemIcon>
                                                <BluetoothIcon />
                                            </ListItemIcon> */}
                                        <ListItemText id="switch-list-label-bluetooth" primary="Status" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('status')}
                                            checked={checked.indexOf('status') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-bluetooth'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('status') !== -1 ? (
                                        <ListItem>
                                            <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="flex-start">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('active')}
                                                                checked={statusChecked.indexOf('active') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="Active"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('inactive')}
                                                                checked={statusChecked.indexOf('inactive') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="Inactive"
                                                    />
                                                </FormGroup>
                                            </Stack>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={20}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    reset();
                                                    popupState.close();
                                                }}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                                variant="contained"
                                                color="orange"
                                                onClick={() => {
                                                    filterHandler();
                                                    popupState.close();
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </Stack>

                                        <Divider variant="middle" component="li" />
                                    </ListItem>
                                </List>
                            </Box>
                        </MainCard>
                    </Popover>
                </>
            )}
        </PopupState>
    );
}

DriverFilter.propTypes = {
    startdohHandler: PropTypes.func,
    handleChecked: PropTypes.func,
    enddohHandler: PropTypes.func,
    checked: PropTypes.any,
    startDoh: PropTypes.any,
    endDoh: PropTypes.any,
    startDot: PropTypes.any,
    endDot: PropTypes.any,
    enddotHandler: PropTypes.func,
    filterHandler: PropTypes.func,
    statusChecked: PropTypes.func,
    startdotHandler: PropTypes.func,
    handleStatus: PropTypes.func,
    reset: PropTypes.func
};
export default DriverFilter;
