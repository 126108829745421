import PageLayoutWithBack from 'layout/PageLayoutWithBack';

import DriverForm from 'ui-component/fleet/forms/DriverForm';
import OwnerForm from 'ui-component/fleet/forms/OwnerForm';

function editOwner() {
    return (
        <PageLayoutWithBack>
            {/* <CardLayout cardHeadTitle="Edit Driver"> */}
            <OwnerForm />
            {/* </CardLayout> */}
        </PageLayoutWithBack>
    );
}

export default editOwner;
