import { createRef, useEffect } from 'react';
import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import {
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Stack,
    TablePagination,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';

import axios from 'axios';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';
import AddFormButton from 'ui-component/buttons/AddFormButton';

// third-party
import MoreVertIcon from '@mui/icons-material/MoreVert';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Status from 'ui-component/buttons/Status';
import SearchIcon from '@mui/icons-material/Search';
import user_types from 'constant/user_types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, width } from '@mui/system';
import { PatternFormat } from 'react-number-format';

const AllOwnerForm = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    // eslint-disable-next-line prefer-const

    const tableRef = createRef();

    const [open, setOpen] = useState(false);

    const [checked, setChecked] = useState(['doh']);
    const [statusChecked, setStatusChecked] = useState([]);
    const [startDot, setStartDot] = useState('');
    const [endDot, setEndDot] = useState('');
    const [startDoh, setStartDoh] = useState('');
    const [endDoh, setEndDoh] = useState('');
    const [searchSts, setSearchSts] = useState('');
    const [searchFltr, setSearchFltr] = useState('');
    const [selected, setSelected] = useState([]);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const cid = loginInfoFromStorage.user_type === 'super_dispatcher_admin' ? '' : loginInfoFromStorage.company_id;

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const deleteDriver = async (id, popupState) => {
        try {
            const { data } = await axios.delete(`/v1/api/fleet/owner/view/${id}`, {
                data: {},
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage.token}`
                }
            });

            popupState.close();
            tableRef.current.onQueryChange();
            dispatch(openSuccessSnackbar('Owner  Deleted successfully'));
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        if (newChecked1.includes('active') && newChecked1.includes('inactive')) {
            setSearchSts();
        } else if (newChecked1.includes('active')) {
            setSearchSts(true);
        } else if (newChecked1.includes('inactive')) {
            setSearchSts(false);
        } else {
            setSearchSts('');
        }
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    const editDriver = (id, popupState) => {
        popupState.close();
        navigate(`/fleet/owner/edit/${id}`);
    };

    const viewDriver = (id, popupState) => {
        popupState.close();
        navigate(`/fleet/driver/view/${id}`);
    };

    const reset = () => {
        setStartDoh('');
        setEndDoh('');
        setStartDot('');
        setEndDot('');
        setSearchSts('');
        setChecked([]);
        setStatusChecked([]);
        tableRef.current.onQueryChange();
    };
    function downloadCSVHandler() {
        window.open(`/v1/api/users/company/drivers/download-csv/${cid}`, '_blank');
    }

    function downloadExcelHandler() {
        window.open(`/v1/api/users/company/drivers/download-excel/${cid}`, '_blank');
    }

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor ">Owner Name</th>
                    <th className="headerTd rightHidBor">Phone#</th>
                    <th className="headerTd rightHidBor">Email</th>
                    <th className="headerTd rightHidBor">Truck</th>
                    <th className="headerTd rightHidBor">Trailer</th>
                    <th className="headerTd rightHidBor">Bank Account</th>
                    <th className="headerTd rightHidBor">Status</th>
                    <th className="headerTd">Actions</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user.first_name} {rowData.data.user.last_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user.phone && rowData.data.user.phone !== '' && (
                        <PatternFormat value={rowData.data.user.phone} displayType={'text'} format="###-###-####" />
                    )}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user.email}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.truck_unit}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.trailer_unit}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.bank_acc}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="Inctive" />}
                </td>
                {/* Custom cell or additional cells */}
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <PopupState variant="popover" popupId={`demoMenu-${rowData.data.driver_id}`}>
                        {(popupState) => (
                            <div>
                                <IconButton {...bindTrigger(popupState)} size="large">
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    {...bindMenu(popupState)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    {((permissions.assetowner && permissions.assetowner.change) || user_types.includes(user_type)) && (
                                        <MenuItem onClick={() => editDriver(rowData.data.owner_id, popupState)}>Edit Owner</MenuItem>
                                    )}
                                    {/* <MenuItem onClick={() => viewDriver(rowData.data.driver_id, popupState)}>View Driver</MenuItem> */}
                                    {(cid === '' || (permissions.assetowner && permissions.assetowner.change)) && (
                                        <MenuItem onClick={() => deleteDriver(rowData.data.owner_id, popupState)}>Remove Owner</MenuItem>
                                    )}
                                </Menu>
                            </div>
                        )}
                    </PopupState>
                </td>
            </tr>
        );
    };

    const fetchData = async (query) => {
        setLoading(true); // Start loading

        try {
            const url = `/v1/api/fleet/owner/list?page=${query.page + 1}&page_size=${
                query.pageSize
            }&search=${searchFltr}&company_id=${cid}&is_active=${searchSts}&owner_type=owner`;

            const response = await fetch(url);
            const result = await response.json();

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 10 })
                .then((result) => {
                    setSelected(result.data || {});
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
        // console.log(selected);
    }, [isMobile, isTablet, currPage, cardPerPage]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    return (
        <>
            <Stack
                sx={{ mt: 5, border: 'none', mb: 2, background: 'transparent' }}
                direction={matchDownMd ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={matchDownMd ? 'flex-start' : 'center'}
                spacing={5}
            >
                <TextField
                    type="text"
                    size="small"
                    placeholder="Search"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    // sx={{ width: { xs: '310px', sm: '350px' } }}
                    sx={{ width: { xs: '82vw', sm: '45vw', lg: '15vw' } }}
                    className="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />

                <div>
                    {((permissions.assetowner && permissions.assetowner.add) || user_types.includes(user_type)) && (
                        <AddFormButton url="/fleet/owner/add" btnText="ADD NEW OWNER" sx={{ width: { xs: '82vw', sm: '45vw', lg: '10vw' } }} />
                    )}
                </div>
            </Stack>

            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>

                                        <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        {((permissions.assetowner && permissions.assetowner.change) || user_types.includes(user_type)) && (
                                                            <MenuItem onClick={() => editDriver(driver.owner_id, popupState)}>Edit Owner</MenuItem>
                                                        )}
                                                        {/* <MenuItem onClick={() => viewDriver(rowData.data.driver_id, popupState)}>View Driver</MenuItem> */}
                                                        {(cid === '' || (permissions.assetowner && permissions.assetowner.change)) && (
                                                            <MenuItem onClick={() => deleteDriver(driver.owner_id, popupState)}>Remove Owner</MenuItem>
                                                        )}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>

                                    <Box sx={{ clear: 'both' }} />

                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Owner Name : {driver.user.first_name} {driver.user.last_name}
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Phone# : {driver.user.phone}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Email : {driver.user.email}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Truck : {driver.truck_unit}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Trailer : {driver.trailer_unit}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Bank Account : {driver.bank_acc}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected?.map((driver) => (
                            <Card
                                key={driver.driver_id}
                                sx={{ marginTop: '2rem', marginBottom: '1.5rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '15px' }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>

                                        <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        {((permissions.assetowner && permissions.assetowner.change) || user_types.includes(user_type)) && (
                                                            <MenuItem onClick={() => editDriver(driver.owner_id, popupState)}>Edit Owner</MenuItem>
                                                        )}
                                                        {/* <MenuItem onClick={() => viewDriver(rowData.data.driver_id, popupState)}>View Driver</MenuItem> */}
                                                        {(cid === '' || (permissions.assetowner && permissions.assetowner.change)) && (
                                                            <MenuItem onClick={() => deleteDriver(driver.owner_id, popupState)}>Remove Owner</MenuItem>
                                                        )}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>
                                    {/* </Box> */}
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            Owner Name : {driver.user.first_name} {driver.user.last_name}
                                        </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Phone# : {driver.user.phone}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Email : {driver.user.email}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Truck : {driver.truck_unit}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '.6rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Trailer : {driver.trailer_unit}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Bank Account : {driver.bank_acc}</Typography>{' '}
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[20, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                    // data={(query) =>
                    //     new Promise((resolve, reject) => {
                    //         const url = `/v1/api/fleet/owner/list?page=${query.page + 1}&page_size=${
                    //             query.pageSize
                    //         }&search=${searchFltr}&company_id=${cid}&is_active=${searchSts}&owner_type=owner`;

                    //         fetch(url)
                    //             .then((response) => response.json())
                    //             .then((result) => {
                    //                 resolve({
                    //                     data: result.results,
                    //                     page: query.page,
                    //                     totalCount: result.count
                    //                 });
                    //             });
                    //     })
                    // }

                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchData(query)
                                .then((result) => resolve(result))
                                .catch((error) => reject(error));
                        })
                    }
                    title="All Drivers"
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,

                        toolbar: false,
                        draggable: false,
                        columnResizable: true
                    }}
                />
            )}
        </>
    );
};
export default AllOwnerForm;
