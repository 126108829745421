import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MaterialTable from '@material-table/core';
import { createRef, useState } from 'react';
import { forwardRef } from 'react';
import TABLE_ICONS from 'tableIcons';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import Status from 'ui-component/buttons/Status';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AzLoadData({ data, onAzSave }) {
    const tableRef = createRef();
    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const [ldData, setLdData] = useState(data);

    const [showForm, setShowForm] = useState(false);
    const [ldRow, setLdRow] = useState(null);

    const handleDelete = (rowData) => {
        setLdData(ldData.filter((item) => item.load_id !== rowData.load_id));
    };

    const LoadSchema = Yup.object().shape({
        load_id: Yup.string().required('Load No is required'),
        trip_stage: Yup.string().required('Trip Stage is required'),
        estimate_distance: Yup.string().required('Distance is required'),
        estimated_cost: Yup.string().required('Revenue is required'),
        stop_1: Yup.string().required('Stop 1 is required'),
        stop_1__actual_arrival_date: Yup.string().required('Stop 1 Date is required'),
        stop_1__actual_arrival_time: Yup.string().required('Stop 1 Time is required'),
        stop_2: Yup.string().required('Stop 2 is required'),
        stop_2__actual_arrival_date: Yup.string().required('Stop 2 Date is required'),
        stop_2__actual_arrival_time: Yup.string().required('Stop 2 Time is required')
    });

    const formik = useFormik({
        initialValues: ldRow ? ldRow : {},
        validationSchema: LoadSchema,
        onSubmit: (values) => {
            try {
                console.log('values');
                setLdData(ldData.map((item) => (item.id === values.id ? values : item)));
                setShowForm(false);
                setLdRow(null);
            } catch (error) {
                console.log(error);
            }
        }
    });

    const handleEdit = (rowData) => {
        try {
            console.log(rowData);
            setShowForm(true);
            formik.setValues(rowData);
        } catch (error) {
            console.log(error);
        }
    };

    // console.log(formik.values, ldRow);
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor ">
                        <span style={{ color: 'red' }}>*</span>Load ID
                    </th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>Trip Stage
                    </th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>Estimate Distance
                    </th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>Estimated Cost
                    </th>
                    <th className="headerTd rightHidBor">Planned Stop 1</th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>Actual Stop 1
                    </th>
                    <th className="headerTd rightHidBor">Planned Stop 2</th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>Actual Stop 2
                    </th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>Driver Name
                    </th>
                    <th className="headerTd rightHidBor">Status</th>
                    <th className="headerTd rightHidBor">Import Status</th>
                    <th className="headerTd rightHidBor">Remark</th>
                    <th className="headerTd">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.load_id}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.trip_stage}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.estimate_distance}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.estimated_cost}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.stop_1}, {formatDate(rowData.data.stop_1_planned_arrival_date)} {formatTime(rowData.data.stop_1_planned_arrival_time)}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.stop_1}, {formatDate(rowData.data.stop_1__actual_arrival_date)} {formatTime(rowData.data.stop_1__actual_arrival_time)}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.stop_2}, {formatDate(rowData.data.stop_2_planned_arrival_date)} {formatTime(rowData.data.stop_2_planned_arrival_time)}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.stop_2}, {formatDate(rowData.data.stop_2__actual_arrival_date)} {formatTime(rowData.data.stop_2__actual_arrival_time)}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.actual_driver_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="Inactive" />}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {<Status text={rowData.data.upload_status} />}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.remark}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => handleEdit(rowData.data)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(rowData.data)}>
                        <DeleteIcon />
                    </IconButton>
                </td>
                {/* Custom cell or additional cells */}
            </tr>
        );
    };

    const formatDate = (dt) => {
        if (dt instanceof Date) {
            return format(dt, 'yyyy-MM-dd');
        } else if (dt !== '') {
            return dt;
        } else {
            return '';
        }
    };

    const formatTime = (tm) => {
        if (tm instanceof Date) {
            return format(tm, 'HH:mm');
        } else if (tm !== '') {
            return tm;
        } else {
            return '';
        }
    };
    return (
        <>
            {showForm && (
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container sx={{ my: 3 }} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Load Data</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    label="Load ID"
                                    {...formik.getFieldProps('load_id')}
                                    InputLabelProps={{ shrink: true }}
                                    error={formik.touched.load_id && Boolean(formik.errors.load_id)}
                                    helperText={formik.touched.load_id && formik.errors.load_id}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    label="Trip Stage"
                                    {...formik.getFieldProps('trip_stage')}
                                    error={formik.touched.trip_stage && Boolean(formik.errors.trip_stage)}
                                    helperText={formik.touched.trip_stage && formik.errors.trip_stage}
                                />
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    label="Estimate Distance"
                                    {...formik.getFieldProps('estimate_distance')}
                                    error={formik.touched.estimate_distance && Boolean(formik.errors.estimate_distance)}
                                    helperText={formik.touched.estimate_distance && formik.errors.estimate_distance}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    label="Estimated Cost"
                                    {...formik.getFieldProps('estimated_cost')}
                                    error={formik.touched.estimated_cost && Boolean(formik.errors.estimated_cost)}
                                    helperText={formik.touched.estimated_cost && formik.errors.estimated_cost}
                                />
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Stop 1</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    label="Stop 1"
                                    {...formik.getFieldProps('stop_1')}
                                    error={formik.touched.stop_1 && Boolean(formik.errors.stop_1)}
                                    helperText={formik.touched.stop_1 && formik.errors.stop_1}
                                />
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Stop 1 Planned Departure Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={formik.values.stop_1_planned_arrival_date}
                                        onChange={(newValue) => {
                                            formik.setFieldValue(`stop_1_planned_arrival_date`, newValue);
                                        }}
                                        renderInput={(params) => <TextField color="orange" fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        className="text-input"
                                        color="orange"
                                        label="Stop 1 Planned Departure Time"
                                        value={formik.values.stop_1_planned_arrival_time}
                                        onChange={(newValue) => formik.setFieldValue(`stop_1_planned_arrival_time`, newValue)}
                                        renderInput={(params) => <TextField color="orange" fullWidth {...params} error={null} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Stop 1 Actual Arrival Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={formik.values.stop_1__actual_arrival_date}
                                        onChange={(newValue) => {
                                            formik.setFieldValue(`stop_1__actual_arrival_date`, newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                color="orange"
                                                fullWidth
                                                {...params}
                                                error={formik.touched.stop_1__actual_arrival_date && Boolean(formik.errors.stop_1__actual_arrival_date)}
                                                helperText={formik.touched.stop_1__actual_arrival_date && formik.errors.stop_1__actual_arrival_date}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        className="text-input"
                                        color="orange"
                                        label="Stop 1 Actual Arrival Time"
                                        value={formik.values.stop_1__actual_arrival_time}
                                        onChange={(newValue) => formik.setFieldValue(`stop_1__actual_arrival_time`, newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                color="orange"
                                                fullWidth
                                                {...params}
                                                error={formik.touched.stop_1__actual_arrival_time && Boolean(formik.errors.stop_1__actual_arrival_time)}
                                                helperText={formik.touched.stop_1__actual_arrival_time && formik.errors.stop_1__actual_arrival_time}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Stop 2</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    label="Stop 1"
                                    {...formik.getFieldProps('stop_2')}
                                    error={formik.touched.stop_2 && Boolean(formik.errors.stop_2)}
                                    helperText={formik.touched.stop_2 && formik.errors.stop_2}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Stop 2 Planned Departure Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={formik.values.stop_2_planned_arrival_date}
                                        onChange={(newValue) => {
                                            formik.setFieldValue(`stop_2_planned_arrival_date`, newValue);
                                        }}
                                        renderInput={(params) => <TextField color="orange" fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        className="text-input"
                                        color="orange"
                                        label="Stop 2 Planned Departure Time"
                                        value={formik.values.stop_2_planned_arrival_time}
                                        onChange={(newValue) => formik.setFieldValue(`stop_2_planned_arrival_time`, newValue)}
                                        renderInput={(params) => <TextField color="orange" fullWidth {...params} error={null} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Stop 2 Actual Arrival Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={formik.values.stop_2__actual_arrival_date}
                                        onChange={(newValue) => {
                                            formik.setFieldValue(`stop_2__actual_arrival_date`, newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                color="orange"
                                                fullWidth
                                                {...params}
                                                error={formik.touched.stop_2__actual_arrival_date && Boolean(formik.errors.stop_2__actual_arrival_date)}
                                                helperText={formik.touched.stop_2__actual_arrival_date && formik.errors.stop_2__actual_arrival_date}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        className="text-input"
                                        color="orange"
                                        label="Stop 2 Actual Arrival Time"
                                        value={formik.values.stop_2__actual_arrival_time}
                                        onChange={(newValue) => formik.setFieldValue(`stop_2__actual_arrival_time`, newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                color="orange"
                                                fullWidth
                                                {...params}
                                                error={formik.touched.stop_2__actual_arrival_time && Boolean(formik.errors.stop_2__actual_arrival_time)}
                                                helperText={formik.touched.stop_2__actual_arrival_time && formik.errors.stop_2__actual_arrival_time}
                                            />
                                        )}
                                    />
                                    } />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        color: 'white',
                                        fontFamily: 'Lexend',
                                        fontWeight: 500,
                                        lineHeight: '1.3rem',
                                        fontSize: '16px',
                                        fontStyle: 'normal'
                                    }}
                                    color="orange"
                                    size="large"
                                    type="submit"
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            )}
            <Typography variant="h5">
                <span style={{ color: 'red' }}>*</span> marks mandatory fields
            </Typography>
            <MaterialTable
                tableRef={tableRef}
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                data={ldData}
                title="All Truck"
                columns={[]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    sorting: true,
                    exportButton: false,
                    filtering: true,
                    toolbar: false,
                    draggable: false,
                    columnResizable: true,
                    paging: false
                }}
            />
            <Button
                variant="contained"
                sx={{
                    color: 'white',
                    fontFamily: 'Lexend',
                    fontWeight: 500,
                    lineHeight: '1.3rem',
                    fontSize: '16px',
                    fontStyle: 'normal'
                }}
                color="orange"
                size="large"
                type="button"
                onClick={() => onAzSave(ldData)}
            >
                Save Data
            </Button>
        </>
    );
}

export default AzLoadData;
