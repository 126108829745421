import { Dialog, DialogContent, Slide } from '@mui/material';
import { forwardRef } from 'react';
import InvoiceManagmentForm from 'ui-component/factoring/forms/InvoiceManagmentForm';
import InvoiceTrackingForm from 'ui-component/factoring/forms/InvoiceTrackingForm';
import MyPaymentTrackingForm from 'ui-component/factoring/forms/MyPaymentTrackingForm';
import PaymentTrackingForm from 'ui-component/factoring/forms/PaymentTrackingForm';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function InvoiceTrackingModal({ fullWidth, fullScreen, maxWidth, open, handleClose, type, factorId, renderTable }) {
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogContent dividers>
                {type === 'invTrack' ? (
                    <InvoiceTrackingForm factorId={factorId} handleClose={handleClose} renderTable={renderTable} />
                ) : type === 'invManage' ? (
                    <InvoiceManagmentForm factorId={factorId} handleClose={handleClose} renderTable={renderTable} />
                ) : type === 'payTrack' ? (
                    <PaymentTrackingForm factorId={factorId} handleClose={handleClose} renderTable={renderTable} />
                ) : type === 'myPay' ? (
                    <MyPaymentTrackingForm factorId={factorId} handleClose={handleClose} renderTable={renderTable} />
                ) : (
                    ''
                )}
            </DialogContent>
        </Dialog>
    );
}

InvoiceTrackingModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'md'
};

export default InvoiceTrackingModal;
