import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    CircularProgress,
    Box
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SyncIcon from '@mui/icons-material/Sync';
import PushPinIcon from '@mui/icons-material/PushPin';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlaceIcon from '@mui/icons-material/Place';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TruckIcon from '../../../assets/images/cargo-truck.png';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import useScriptRef from 'hooks/useScriptRef';

import axios from 'axios';
import { useDispatch } from 'react-redux';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';
// import WrappedMap from './Map';
// import Map from './Map';
import 'react-perfect-scrollbar/dist/css/styles.css';
import truckImg from 'assets/images/truck_img.png';

// ===========================|| FIREBASE - REGISTER ||=========================== //
const containerStyle = {
    width: '100%',
    height: '700px'
};
const defaultLocation = { lat: 40.756795, lng: -74.954298 };
let directionsService;

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 9,
        left: 'calc(-50% + 14px)',
        right: 'calc(50% + 14px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#00970F'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#00970F'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.orange.main,
        borderTopWidth: 4,
        borderRadius: 1
    }
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.orange,
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#00970F !important'
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#00970F',
        zIndex: 1,
        fontSize: 24
    },
    '& .QontoStepIcon-remainIcon': {
        color: ownerState.active ? '#00970F' : theme.palette.orange.main,
        zIndex: 1,
        fontSize: 24
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    }
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <Check className="QontoStepIcon-completedIcon" /> : <CircleOutlinedIcon className="QontoStepIcon-remainIcon" />}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool
};

const steps = ['New', 'Heading Pickup', 'Checked In', 'Loaded', 'In Transit', 'Delivered'];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

const ViewLoadTest = (props) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDDLFPFqXmtMzrzbPJrMn5osR4PA2swrtM'
    });
    const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDDLFPFqXmtMzrzbPJrMn5osR4PA2swrtM`;
    const [state, setState] = useState({
        directions: null,
        bounds: null
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [paths, setPaths] = useState();
    const [stops, setStops] = useState();
    const [progress, setProgress] = useState();

    const [loading, setLoading] = useState();
    const [loading1, setLoading1] = useState();
    const [loadData, setLoadData] = useState();
    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [msg, setMsg] = useState('');
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selectedTruck, setSelectedTruck] = useState(null);

    const [isMapLoading, setIsMapLoading] = useState(false);
    const [locationName, setLocationName] = useState('');

    const [isLoadLoading, setIsLoadLoading] = useState(false);

    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        setUrl(window.location.origin + `/dispatch-center/load/view-status-public/${id}`);
    }, []);

    const prevDocModalHanler = () => {
        setOpen(true);
    };

    const handleDocCloseModal = () => {
        setOpen(false);
    };

    // const [origin, setOrigin] = useState({ lat: 37.7749, lng: -122.4194 }); // New York City
    const [directions, setDirections] = useState(null);

    const centerPath = paths && parseInt(paths.length / 2);
    const centerPathLat = paths && paths[centerPath].lat;
    const centerpathLng = paths && paths[centerPath + 5].lng;

    const center = {
        lat: paths ? centerPathLat : 12.9771191896563,
        lng: paths ? centerpathLng : 77.5857120256672
    };

    const destinations = [
        {
            location: {
                id: 'ID of FIrst Place',
                lat: 36.04973979999999755819,
                lng: -94.17729500000000086857
            }
        },
        {
            location: {
                id: 'ID of Second Place',
                lat: 40.753167,
                lng: -73.96812
            }
        },
        {
            location: {
                id: 'ID of Third Place',
                lat: 40.853167,
                lng: -73.96812
            }
        }
    ];

    let icon1 = {
        url: 'https://images.vexels.com/media/users/3/154573/isolated/preview/bd08e000a449288c914d851cb9dae110-hatchback-car-top-view-silhouette-by-vexels.png',
        // scaledSize: new window.google.maps.Size(40, 40),
        // anchor: new window.google.maps.Point(20, 20),
        scale: 0.7
    };

    const directionsCallback = (response) => {
        if (response !== null) {
            setDirections(response);
        }
    };

    const copyHandler = () => {
        navigator.clipboard
            .writeText(url)
            .then(() => {
                setMsg('Text copied to clipboard');
            })
            .catch((error) => {
                setMsg('Failed to copy text: ', error);
            });
    };
    useEffect(() => {
        if (id && !loadData) {
            fetchLoad(id);
        }
    }, [loadData, id]);

    const fetchLoad = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/dispatch/load/view-maps/${id}/`, config);

            setLoadData(data);
            setPaths(data.map_data.paths);
            setProgress(data.map_data.progress);
            setStops(data.map_data.stops);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getActiveStep = (step) => {
        return steps.findIndex((i) => i === step);
    };

    let icon = {};

    const onMapLoad = (map) => {
        directionsService = new google.maps.DirectionsService();

        icon = {
            url: truckImg,
            scaledSize: new window.google.maps.Size(40, 40),
            anchor: new window.google.maps.Point(20, 20),
            scale: 0.7
        };

        // directionsService.route.

        // icon1.scaledSize = new window.google.maps.Size(40, 40);
        // icon1.anchor = new window.google.maps.Point(20, 20);

        const routesCopy =
            loadData && loadData.loads.length > 0
                ? loadData.loads.map((route) => {
                      return {
                          location: { lat: parseFloat(route.latitude), lng: parseFloat(route.longitude) },
                          stopover: true
                      };
                  })
                : [];

        //   loadData && loadData.loads.length > 0
        //       ? loadData.loads.map((route) => {
        //             return {
        //                 location: { lat: route.latitude, lng: route.longiutde },
        //                 stopover: true
        //             };
        //         })
        //       : [];

        const origin = routesCopy.length === 1 ? new google.maps.LatLng(routesCopy[0].location.lat, routesCopy[0].location.lng) : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1 ? new google.maps.LatLng(routesCopy[0].location.lat, routesCopy[0].location.lng) : routesCopy.pop().location;
        //put all the remaining coordinates in waypoints after(pop and shift)
        const waypoints = routesCopy;

        //call getDirection function
        getDirection(origin, destination, waypoints);
    };

    const fetchData = async () => {
        if (loginInfoFromStorage.user_type === 'dispatcher_admin') {
            try {
                setIsMapLoading(true);
                const { data } = await axios.get(`/v1/api/dispatch/load/eld-load-data/${id}`, {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${loginInfoFromStorage.token}`
                    }
                });
                setVehicles(data.vehicles);

                setIsMapLoading(false);
                fetchLoad(id);
                toast.success('Location updated successfully');
            } catch (error) {
                console.log(error.response.data.error);
                setIsMapLoading(false);
                if (error?.response?.data?.error) {
                    toast.error(error?.response?.data?.error);
                } else {
                    toast.error('Please contact with support');
                }
                console.log(error);
            }
        }
    };

    const getDirection = (origin, destination, waypoints) => {
        //this will check if there is a waypoint meaning the array  has 3 or more coordinates
        waypoints.length >= 1
            ? directionsService.route(
                  {
                      origin: origin,
                      destination: destination,
                      travelMode: google.maps.TravelMode.DRIVING,
                      waypoints: waypoints
                  },
                  (result, status) => {
                      if (status === google.maps.DirectionsStatus.OK) {
                          //changing the state of directions to the result of direction service
                          setState({
                              directions: result
                          });
                      } else {
                          console.error(`error fetching directions ${result}`);
                      }
                  }
              )
            : directionsService.route(
                  {
                      origin: origin,
                      destination: destination,
                      travelMode: google.maps.TravelMode.DRIVING
                  },
                  (result, status) => {
                      if (status === google.maps.DirectionsStatus.OK) {
                          //changing the state of directions to the result of direction service
                          setState({
                              directions: result
                          });
                      } else {
                          console.error(`error fetching directions ${result}`);
                      }
                  }
              );
    };
    return (
        <>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mb: 3 }}>
                <Button
                    variant="contained"
                    sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                    color="orange"
                    // onClick={() => navigate(`/dispatch-center/load/view-status-public/${id}`)}
                    onClick={prevDocModalHanler}
                    size="medium"
                    // sx={{ pt: 1.5, pb: 1.5 }}
                >
                    SHARE NOW
                </Button>
            </Stack>
            <Card sx={{ borderColor: 'primary', border: '3px solid', borderRadius: '10px', maxHeight: '800px' }}>
                <CardContent sx={{ padding: '0 !important' }}>
                    <Stack sx={{ width: '100%' }} spacing={4}>
                        {/* {loadData && ( */}
                        <Stepper
                            alternativeLabel
                            activeStep={loadData && getActiveStep(loadData.load_status)}
                            connector={<QontoConnector />}
                            sx={{ mt: 2, pb: 2, borderBottom: '3px solid', borderBottomColor: theme.palette.primary.main }}
                        >
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {/* )} */}
                    </Stack>
                    <Grid container spacing={0}>
                        <Grid xl={3} lg={3} md={3} sx={{ borderRight: '3px solid', borderRightColor: theme.palette.primary.main }}>
                            <div style={{ height: '700px' }}>
                                <PerfectScrollbar>
                                    <Card sx={{ borderColor: 'primary', border: '1px solid', borderRadius: '10px', m: 1.5 }}>
                                        <CardContent>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                <img src={loadData && loadData.comp_info.logo} alt="test" height="200px" width="300px" />
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{ borderColor: 'primary', border: '1px solid', borderRadius: '10px', m: 1.5 }}>
                                        <CardContent>
                                            <Stack direction="row" justifyContent="left" alignItems="center">
                                                <Typography variant="h2">Load # No: {loadData && loadData.load}</Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                    {/* Customer Info */}
                                    <Card sx={{ borderColor: 'primary', border: '1px solid', borderRadius: '10px', m: 1.5 }}>
                                        <CardContent>
                                            <Typography variant="h3" sx={{ mb: 2 }}>
                                                Customer Information
                                            </Typography>
                                            <Grid container spacing={2} sx={{ px: 2, py: 2 }}>
                                                <Grid xs>
                                                    <Typography variant="h4">{loadData && loadData.cust_name}</Typography>
                                                    {/* <Typography variant="body1">Eric R. Simmons</Typography>
                                                    <Typography variant="body1">Eric R. Simmons</Typography> */}
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} sx={{ px: 2, pt: 2 }}>
                                                <Grid xs>
                                                    <Typography variant="h4">Carrier</Typography>
                                                    <Typography variant="body1">{loadData && loadData.comp_info.name}</Typography>
                                                </Grid>
                                                <Grid xs sx={{ float: 'right' }}>
                                                    <Typography variant="h4">Status</Typography>
                                                    <Typography variant="body1">{loadData && loadData.load_status}</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    {/* Status */}
                                    <Card sx={{ borderColor: 'primary', border: '1px solid', borderRadius: '10px', m: 1.5 }}>
                                        <CardContent>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                                <Typography variant="h2">
                                                    Status: <span style={{ color: '#00970F' }}>Active</span>
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>

                                    {/* Time Info */}
                                    {/* <Card sx={{ borderColor: 'primary', border: '1px solid', borderRadius: '10px', m: 1.5 }}>
                                        <CardContent>
                                            <Typography variant="h2" sx={{ mb: 2 }}>
                                                Time
                                            </Typography>
                                            <Grid container spacing={2} sx={{ px: 2, pt: 2 }}>
                                                <Grid xs>
                                                    <Typography variant="h4">Pick Up Time In</Typography>
                                                    <Typography variant="body1">Waiting</Typography>
                                                </Grid>
                                                <Grid xs sx={{ float: 'right' }}>
                                                    <Typography variant="h4">Pick Up Time Out</Typography>
                                                    <Typography variant="body1">Waiting</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card> */}
                                    {/* Contact Info */}
                                    <Card sx={{ borderColor: 'primary', border: '1px solid', borderRadius: '10px', m: 1.5 }}>
                                        <CardContent>
                                            <Typography variant="h2" sx={{ mb: 2 }}>
                                                Contact Information
                                            </Typography>
                                            <Grid container spacing={2} sx={{ px: 2, pt: 2, mb: 2 }}>
                                                <Grid xs sx={{ mr: 3 }}>
                                                    <Typography variant="h4">Driver Name</Typography>
                                                    <Typography variant="body1">{loadData && loadData.driver_info.name}</Typography>
                                                </Grid>
                                                <Grid xs>
                                                    <Typography variant="h4">Mobile Number</Typography>
                                                    <Typography variant="body1">{loadData && loadData.driver_info.phone}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} sx={{ px: 2, pt: 2 }}>
                                                <Grid xs sx={{ mr: 3 }}>
                                                    <Typography variant="h4">Truck</Typography>
                                                    <Typography variant="body1">{loadData && loadData.truck_license}</Typography>
                                                </Grid>
                                                <Grid xs>
                                                    <Typography variant="h4">Trailer</Typography>
                                                    <Typography variant="body1">{loadData && loadData.trailer_license}</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    {/* Other Info */}
                                    <Card sx={{ borderColor: 'primary', border: '1px solid', borderRadius: '10px', m: 1.5 }}>
                                        <CardContent>
                                            {loadData && loadData.load_status == 'Delivered' ? (
                                                <Typography variant="h2" style={{ color: '#00970F', mb: 2, textAlign: 'center' }}>
                                                    Delivered
                                                </Typography>
                                            ) : (
                                                <>
                                                    <Typography variant="h2" sx={{ mb: 2 }}>
                                                        Other Information
                                                    </Typography>
                                                    <Grid container spacing={2} sx={{ px: 2, pt: 2, mb: 2 }}>
                                                        <Grid xs>
                                                            <Typography variant="h4">Distance Left</Typography>
                                                            <Typography variant="body1">{loadData && loadData.map_data.dist}</Typography>
                                                        </Grid>
                                                        <Grid xs>
                                                            <Typography variant="h4">ETA</Typography>
                                                            <Typography variant="body1">{loadData && loadData.map_data.eta}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} sx={{ px: 2, pt: 2 }}>
                                                        <Grid xs>
                                                            <Typography variant="h4">Arrival In</Typography>
                                                            <Typography variant="body1">{loadData && loadData.map_data.eta}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} sx={{ px: 2, pt: 2 }}>
                                                        <Grid xs>
                                                            <Typography variant="h4">Current Location</Typography>
                                                            <Typography variant="body1">{loadData && loadData.map_data.current_location}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )}
                                        </CardContent>
                                    </Card>
                                </PerfectScrollbar>
                            </div>
                        </Grid>
                        <Grid xl={9} lg={9} md={9}>
                            {/* {loading ? (
                                <Skeleton height="50px" />
                            ) : ( */}
                            {/* <LoadScript googleMapsApiKey="AIzaSyDDLFPFqXmtMzrzbPJrMn5osR4PA2swrtM"> */}
                            {/* </LoadScript> */}
                            {loadData && isLoaded && (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={4}
                                    options={{
                                        zoomControl: true,
                                        streetViewControl: false,
                                        mapTypeControl: false,
                                        fullscreenControl: false
                                    }}
                                    onLoad={(map) => {
                                        onMapLoad(map);
                                    }}
                                >
                                    {/* {state.directions !== null && <DirectionsRenderer directions={state.directions} options={{ strokeColor: 'green' }} />} */}

                                    {/* {paths && (
                                        <Polyline
                                            path={paths ? paths : []}
                                            options={{
                                                strokeColor: '#0088FF',
                                                strokeWeight: 6,
                                                strokeOpacity: 0.6,
                                                defaultVisible: true
                                            }}
                                        />
                                    )} */}

                                    {stops.map((stop, index) => (
                                        <Marker
                                            key={index}
                                            position={{
                                                lat: stop.lat,
                                                lng: stop.lng
                                            }}
                                            title={stop.id}
                                            label={`${index + 1}`}
                                            onClick={() => {
                                                setSelectedMarker(stop);
                                            }}
                                        />
                                    ))}
                                    {/* <MarkerClusterer>
                                        {(clusterer) =>
                                            stops.map((vehicle, index) => (
                                                <Marker
                                                    key={index}
                                                    position={{
                                                        lat: parseFloat(vehicle.lat),
                                                        lng: parseFloat(vehicle.lon)
                                                    }}
                                                    clusterer={clusterer}
                                                    icon={TruckIcon}
                                                    // title={vehicle.location.id}
                                                    label={`${index + 1}`}
                                                    onClick={() => {
                                                        setSelectedMarker(vehicle);
                                                        // fetchLoadInfo(vehicle?.vehicle?.id);
                                                        // fetchLocationNameByLatLon(
                                                        //     vehicle?.vehicle?.current_location?.lat,
                                                        //     vehicle?.vehicle?.current_location?.lon
                                                        // );
                                                    }}
                                                />
                                            ))
                                        }
                                    </MarkerClusterer> */}

                                    {selectedMarker && (
                                        <InfoWindow
                                            position={{
                                                lat: parseFloat(selectedMarker?.lat),
                                                lng: parseFloat(selectedMarker?.lng)
                                            }}
                                            options={{
                                                pixelOffset: new google.maps.Size(0, -40),
                                                closeButton: false,
                                                boxStyle: {
                                                    width: '150px',
                                                    backgroundColor: 'red'
                                                }
                                            }}
                                            onCloseClick={() => setSelectedMarker(null)}
                                        >
                                            <Box style={{ backgroundColor: '#0000001c', padding: '12px' }}>
                                                <div
                                                    style={{
                                                        backgroundColor: 'white',
                                                        display: 'flex',
                                                        padding: '10px',
                                                        flexDirection: 'column',
                                                        borderRadius: '5px'
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', gap: '30px' }}>
                                                        <div>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                                <PlaceIcon style={{ color: '#ff0000a3' }} />
                                                                {isLoadLoading ? (
                                                                    <Skeleton height={'30px'} width={'100px'} />
                                                                ) : (
                                                                    <div>
                                                                        <h3 style={{ margin: '0px' }}>{selectedMarker && selectedMarker.address}</h3>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                <BusinessIcon style={{ color: '#0b5b0bbd' }} />
                                                                {isLoadLoading ? (
                                                                    <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                ) : (
                                                                    <div>
                                                                        <h3 style={{ margin: '0px' }}>{selectedMarker && selectedMarker.cust} </h3>
                                                                        {/* <span style={{ color: '#00000091', fontWeight: '500', fontSize: '12px' }}>
                                                                            {selectedMarker && selectedMarker.cust}
                                                                        </span> */}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                <CalendarMonthIcon style={{ color: '#0b565bbd' }} />
                                                                {isLoadLoading ? (
                                                                    <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                ) : (
                                                                    <div>
                                                                        <h3 style={{ margin: '0px' }}>{selectedMarker && selectedMarker.date} </h3>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                <LocalShippingIcon style={{ color: '#0b565bbd' }} />
                                                                {isLoadLoading ? (
                                                                    <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                ) : (
                                                                    <div>
                                                                        <h3 style={{ margin: '0px', textTransform: 'capitalize' }}>
                                                                            {selectedMarker && selectedMarker.load_type}{' '}
                                                                        </h3>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            marginTop: '15px',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: '#00000091',
                                                                fontWeight: '500',
                                                                fontSize: '15px',
                                                                marginTop: '20px',
                                                                display: 'block'
                                                            }}
                                                        >
                                                            {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
                                                        </span>

                                                        <Button
                                                            variant="contained"
                                                            sx={{ color: 'white', backgroundColor: '#EB812E' }}
                                                            size="small"
                                                            color="orange"
                                                            onClick={() => setSelectedMarker(null)}
                                                        >
                                                            Close
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Box>
                                        </InfoWindow>
                                    )}

                                    {progress && (
                                        <>
                                            {/* <Polyline path={progress} options={{ strokeColor: 'orange', zIndex: 1000 }} /> */}

                                            <Marker
                                                icon={TruckIcon}
                                                position={progress[progress.length - 1]}
                                                onClick={() => setSelectedTruck(progress[progress.length - 1])}
                                            />
                                        </>
                                    )}

                                    {selectedTruck && (
                                        <InfoWindow
                                            position={{
                                                lat: parseFloat(selectedTruck?.lat),
                                                lng: parseFloat(selectedTruck?.lng)
                                            }}
                                            options={{
                                                pixelOffset: new google.maps.Size(0, -40),
                                                closeButton: false,
                                                boxStyle: {
                                                    width: '150px',
                                                    backgroundColor: 'red'
                                                }
                                            }}
                                            onCloseClick={() => setSelectedTruck(null)}
                                        >
                                            <Box style={{ backgroundColor: '#0000001c', padding: '12px' }}>
                                                <div
                                                    style={{
                                                        backgroundColor: 'white',
                                                        display: 'flex',
                                                        padding: '10px',
                                                        flexDirection: 'column',
                                                        borderRadius: '5px',
                                                        minWidth: '200px'
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', gap: '30px' }}>
                                                        <div>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                                <PlaceIcon style={{ color: '#ff0000a3' }} />
                                                                {isLoadLoading ? (
                                                                    <Skeleton height={'30px'} width={'100px'} />
                                                                ) : (
                                                                    <div>
                                                                        <h3 style={{ margin: '0px' }}>Located At</h3>
                                                                        <span style={{ color: '#00000091', fontWeight: '500', fontSize: '12px' }}>
                                                                            {selectedTruck && selectedTruck.description}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                <BusinessIcon style={{ color: '#0b5b0bbd' }} />
                                                                {isLoadLoading ? (
                                                                    <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                ) : (
                                                                    <div>
                                                                        <h3 style={{ margin: '0px' }}>{selectedMarker && selectedMarker.cust} </h3>
                                                                        
                                                                    </div>
                                                                )}
                                                            </div> */}
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                <CalendarMonthIcon style={{ color: '#0b565bbd' }} />
                                                                {isLoadLoading ? (
                                                                    <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                ) : (
                                                                    <div>
                                                                        <h3 style={{ margin: '0px' }}>Updated At </h3>
                                                                        <span style={{ color: '#00000091', fontWeight: '500', fontSize: '12px' }}>
                                                                            {selectedTruck && new Date(selectedTruck.located_at).toLocaleTimeString()}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                <AccessTimeIcon style={{ color: '#0b565bbd' }} />
                                                                {isLoadLoading ? (
                                                                    <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                ) : (
                                                                    <div>
                                                                        <h3 style={{ margin: '0px', textTransform: 'capitalize' }}>ETA</h3>
                                                                        <span style={{ color: '#00000091', fontWeight: '500', fontSize: '12px' }}>
                                                                            {loadData && loadData.map_data.eta}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            marginTop: '15px',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <span></span>
                                                        {/* <span
                                                            style={{
                                                                color: '#00000091',
                                                                fontWeight: '500',
                                                                fontSize: '15px',
                                                                marginTop: '20px',
                                                                display: 'block'
                                                            }}
                                                        >
                                                            {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
                                                        </span> */}

                                                        <Button
                                                            variant="contained"
                                                            sx={{ color: 'white', backgroundColor: '#EB812E' }}
                                                            size="small"
                                                            color="orange"
                                                            onClick={() => setSelectedTruck(null)}
                                                        >
                                                            Close
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Box>
                                        </InfoWindow>
                                    )}

                                    {loadData && loadData.is_active && (
                                        <Tooltip title="Get Location Update" placement="top-start">
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '15vh',
                                                    right: '0.5vw',
                                                    backgroundColor: 'white',
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: '5px'
                                                }}
                                            >
                                                {isMapLoading ? (
                                                    <CircularProgress size={35} />
                                                ) : (
                                                    <SyncIcon
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: 'orange',
                                                            fontSize: '35px'
                                                        }}
                                                        onClick={fetchData}
                                                    />
                                                )}
                                            </div>
                                        </Tooltip>
                                    )}
                                </GoogleMap>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Dialog
                open={open}
                onClose={handleDocCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle variant="h3" id="alert-dialog-title">
                    {'Dispatch Center Load View'}
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            type="text"
                            value={`${url}`}
                            InputProps={{
                                readOnly: true
                            }}
                        />
                        <Button variant="text" onClick={copyHandler}>
                            Copy
                        </Button>
                        <Button
                            variant="text"
                            onClick={() => navigate(`/dispatch-center/load/view-status-public/${id}`)}
                            size="medium"
                            // sx={{ pt: 1.5, pb: 1.5 }}
                        >
                            Preview
                        </Button>
                    </Stack>
                    <Typography sx={{ mt: 1 }} variant="h5">
                        {msg ? msg : ''}
                    </Typography>
                </DialogContent>
            </Dialog>

            {/* {loadData && loadData.is_active && (
                <Tooltip title="Get Location Update" placement="top-start">
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '5vh',
                            right: '5.7vw',
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '5px'
                        }}
                    >
                        {isMapLoading ? (
                            <CircularProgress size={50} />
                        ) : (
                            <SyncIcon
                                style={{
                                    cursor: 'pointer',
                                    color: 'orange',
                                    fontSize: '50px'
                                }}
                                onClick={fetchData}
                            />
                        )}
                    </div>
                </Tooltip>
            )} */}
        </>
    );
};

export default ViewLoadTest;
