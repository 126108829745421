import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  driverProfileDetails: {},
  driverGeneralInformation: {},
  driverAddress: {},
  licenseInformation: {},
  drivingExperience: {},
  accidentRecord: {},
  trafficConviction: {},
  drugTestInformation: {},
  educations: {},
  signed: {},
  acknowledgementOfPast: {},
  authorizationForBackgroundInformation: {},
  dotMandatedInformation: {},
  motorCarrierSafetyRegulation: {},
  errors: {}
};

export const driverRegisterSlice = createSlice({
  name: 'driverRegister',
  initialState,
  reducers: {
    updateDriverProfileDetails(state, action) {
      state.driverProfileDetails = action.payload;
    
    },
    updateDriverGeneralInformation(state, action) {
      state.driverGeneralInformation = action.payload;
    },
    updateDriverAddress(state, action) {
      state.driverAddress = action.payload;
    },
    updateLicenseInformation(state, action) {
      state.licenseInformation = action.payload;
    },
    updateDrivingExperience(state, action) {
      state.drivingExperience = action.payload;
    },
    updateAccidentRecord(state, action) {
      state.accidentRecord = action.payload;
    },
    updateTrafficConviction(state, action) {
      state.trafficConviction = action.payload;
    },
    updateDrugTestInformation(state, action) {
      state.drugTestInformation = action.payload;
    },
    updateEducations(state, action) {
      state.educations = action.payload;
    },
    updateSigned(state, action) {
      state.signed = action.payload;
    },
    updateAcknowledgementOfPast(state, action) {
      state.acknowledgementOfPast = action.payload;
    },
    updateAuthorizationForBackgroundInformation(state, action) {
      state.authorizationForBackgroundInformation = action.payload;
    },
    updateDotMandatedInformation(state, action) {
      state.dotMandatedInformation = action.payload;
    },
    updateMotorCarrierSafetyRegulation(state, action) {
      state.motorCarrierSafetyRegulation = action.payload;
    },
    updateErrors(state, action){
        state.errors = action.payload;
    }
  }
});

export const {
  updateDriverProfileDetails,
  updateDriverGeneralInformation,
  updateDriverAddress,
  updateLicenseInformation,
  updateDrivingExperience,
  updateAccidentRecord,
  updateTrafficConviction,
  updateDrugTestInformation,
  updateEducations,
  updateSigned,
  updateAcknowledgementOfPast,
  updateAuthorizationForBackgroundInformation,
  updateDotMandatedInformation,
  updateMotorCarrierSafetyRegulation,
  updateErrors
} = driverRegisterSlice.actions;

export default driverRegisterSlice.reducer;
