import { configureStore } from '@reduxjs/toolkit';


import companySetupReducer from './slice/companySlice';
import customizationReducer from './ui/customization';
import snackbarReducer from './ui/snackbarSlice';
// Custom slice to handle the driver register all the data
import activeStepSlice from './driverSlice/activeStepSlice';
import driverRegisterReducer from './driverSlice/driverRegisterSlice';
import stepsSlice from './driverSlice/stepsSlice';
// dashboard slice to handle dashboard data
import dashboardReducer from './dashboardSlice';

export const store = configureStore({
    reducer: {
        customization: customizationReducer,
        companySetup: companySetupReducer,
        snackbar: snackbarReducer,
        driverRegister: driverRegisterReducer,
        activeStep: activeStepSlice,
        steps: stepsSlice,
        dashboard: dashboardReducer
    },
});
