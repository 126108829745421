import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';

import PageLayout from 'layout/PageLayout';
import AddFuelForm from 'ui-component/accounting/forms/AddFuelForm';
import { useLocation, useParams } from 'react-router';
import { Button, Skeleton, Stack, Typography } from '@mui/material';

function AddFuelReportsToTruck() {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const driver_name = queryParams.get('driver_name');
    const truck_number = queryParams.get('truck_number');
    const trailer_number = queryParams.get('trailer_number');

    const [loading, setLoading] = useState(false);
    return (
        <>
            <MainCard>
                <PageLayout title="Add Fuel Reports">
                    <Stack sx={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                        {loading ? (
                            <Skeleton height="50px" width="250px" />
                        ) : (
                            <Typography variant="h5" fontSize="16px" sx={{ height: '20px', width: '250px', fontFamily: 'Lexend' }}>
                                Driver:
                                <span style={{ width: '228px', height: '20px', color: '#5C5C5C', fontFamily: 'Lexend', paddingLeft: '10px' }}>
                                    {driver_name && `${driver_name}`}
                                </span>
                            </Typography>
                        )}
                        {loading ? (
                            <Skeleton height="50px" width="250px" />
                        ) : (
                            <Typography variant="h5" fontSize="16px" sx={{ height: '20px', width: '250px', fontFamily: 'Lexend' }}>
                                Truck Number:
                                <span style={{ width: '228px', height: '20px', color: '#5C5C5C', fontFamily: 'Lexend', paddingLeft: '10px' }}>
                                    {truck_number && `${truck_number}`}
                                </span>
                            </Typography>
                        )}
                        {loading ? (
                            <Skeleton height="50px" width="250px" />
                        ) : (
                            <Typography variant="h5" fontSize="16px" sx={{ height: '20px', width: '250px', fontFamily: 'Lexend' }}>
                                Trailer Number:
                                <span style={{ width: '228px', height: '20px', color: '#5C5C5C', fontFamily: 'Lexend', paddingLeft: '10px' }}>
                                    {trailer_number && `${trailer_number}`}
                                </span>
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            href={`/accounting/fuel-reports/driver/${id}/?driver_name=${driver_name ? driver_name : ''}&truck_number=${
                                truck_number ? truck_number : ''
                            }&trailer_number=${trailer_number ? trailer_number : ''}`}
                            sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                            color="orange"
                        >
                            Go Back
                        </Button>
                    </Stack>
                    <AddFuelForm />
                </PageLayout>
            </MainCard>
        </>
    );
}

export default AddFuelReportsToTruck;
