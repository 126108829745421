import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

import PropTypes from 'prop-types';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const CustomerViewForm = ({ handleClose, custInfo }) => {
   

    return (
        <>
            <Box>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography
                        sx={{
                            mb: 5,
                            textAlign: 'center',
                            width: '375px',
                            height: '40px',
                            fontWeight: '600',
                            fontSize: '32px',
                            fontFamily: 'Lexend'
                        }}
                    >
                        Customer Details
                    </Typography>
                </Stack>
                <Grid container spacing={3}>
                    {/* DQ type */}

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h3">
                            {custInfo && custInfo.cust_name}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography variant="h4">Email</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Typography variant="body1">
                            {custInfo && custInfo.billing_email}
                        </Typography>
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography variant="h4">Phone</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Typography variant="body1">
                            {custInfo && custInfo.phone}
                        </Typography>
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography variant="h4">Address</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Typography variant="body1">
                            {custInfo && custInfo.address}
                        </Typography>
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography variant="h4">MC No.</Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Typography variant="body1">
                            {custInfo && custInfo.mc_dot}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 2 }} align="center">
                    <AnimateButton>
                        <Button
                            variant="contained"
                            sx={{
                                mr: 2,
                                color: 'white',
                                backgroundColor: '#EB812E'
                            }}
                            color="orange"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </AnimateButton>
                </Box>
            </Box>
        </>
    );
};

CustomerViewForm.propTypes = {
    reloadData: PropTypes.any,
    id: PropTypes.any,
    docId: PropTypes.any
};

export default CustomerViewForm;
