
// material-ui
import { useTheme } from '@mui/material/styles';
import {  Grid, useMediaQuery } from '@mui/material';

// project imports

import AuthCardWrapper from '../AuthCardWrapper';
import SubCard from 'ui-component/cards/SubCard';
import MechanicBankInfoForm from 'ui-component/auth-component/forms/MechanicBankInfoForm';

// assets

// ===============================|| AUTH3 - REGISTER ||=============================== //

const MechanicBankInfo = () => {
    
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Grid container direction="column" justifyContent="flex-end" alignItems="center" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                                        <MechanicBankInfoForm />
                                    </SubCard>
                                 
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MechanicBankInfo;
