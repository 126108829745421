import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LoopIcon from '@mui/icons-material/Loop';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import { Card, CardContent, InputAdornment, Menu, MenuItem, TextField, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import user_types from 'constant/user_types';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { queryClient } from 'index';
import 'react-toastify/dist/ReactToastify.css';
import AddFormButton from 'ui-component/buttons/AddFormButton';
import FuelFilter from 'ui-component/filters/FuelFilter';
import TableRowAnimation from '../../../views/accounting/components/TableRowAnimation';
import '../../../views/accounting/components/expenses.css';
import EditFuelForm from './EditFuelForm';
import { toast } from 'react-toastify';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { height } from '@mui/system';

const headCells = [
    {
        id: 'id',
        label: 'Id'
    },
    {
        id: 'date',
        label: 'Date'
    },
    {
        id: 'city',
        label: 'City'
    },
    {
        id: 'state',
        label: 'State'
    },

    {
        id: 'invoice',
        label: 'Invoice #'
    },
    {
        id: 'total_gallon',
        label: 'Total Gallon'
    },
    {
        id: 'total_price',
        label: 'Total Price'
    },
    {
        id: 'location_name',
        label: 'Location Name'
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
        <TableHead>
            <TableRow sx={{ backgroundColor: '#DDE6FF' }}>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ))}
                <TableCell key={'action'} sortDirection={false}>
                    Actions
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
    const { numSelected, handleBulkDelete, deleteBulkPending, handleChecked, search, page, rowsPerPage, checked, setChecked, params } = props;

    const { id } = useParams();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const navigate = useNavigate();
    const driver_name = params.get('driver_name') || '';
    const truck_number = params.get('truck_number') || '';
    const trailer_number = params.get('trailer_number') || '';

    // Reset handler for reset all the filter field value
    const reset = async () => {
        setChecked([]);
        params.delete('search');
        params.delete('start_transaction_date');
        params.delete('end_transaction_date');
        params.delete('location_name');
        params.delete('city');
        params.delete('state');

        navigate({ search: `?${params.toString()}` });
        await queryClient.invalidateQueries({ queryKey: ['fuel-reports', search, page, rowsPerPage] });
    };

    const startDate = params.get('start_transaction_date') || '';
    const endDate = params.get('end_transaction_date') || '';
    const location = params.get('location_name') || '';
    const city = params.get('city') || '';
    const state = params.get('state') || '';

    const setStartDate = (data) => {
        params.set('start_transaction_date', data);
    };

    const setEndDate = (data) => {
        params.set('end_transaction_date', data);
    };
    const setLocation = (data) => {
        params.set('location_name', data);
    };
    const setCity = (data) => {
        params.set('city', data);
    };
    const setState = (data) => {
        params.set('state', data);
    };

    const handleFilter = async () => {
        navigate({ search: `?${params.toString()}` });
        await queryClient.invalidateQueries({ queryKey: ['fuel-reports', search, page, rowsPerPage] });
    };

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>

                {((permissions.fuelreport && permissions.fuelreport.delete) || user_types.includes(user_type)) && (
                    <Tooltip title="Delete">
                        <IconButton onClick={handleBulkDelete} disabled={deleteBulkPending}>
                            {deleteBulkPending ? <LoopIcon className="rotation-icon" /> : <DeleteIcon />}
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <Box
                sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 2, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
            >
                <TextField
                    type="text"
                    size="small"
                    placeholder="Search by location, city, state invoice, date"
                    // sx={{ width: '400px' }}
                    sx={{ width: { xs: '80vw', sm: '45vw', lg: '20vw' } }}
                    onChange={debounce((e) => {
                        params.set('search', e.target.value);
                        navigate({ search: `?${params.toString()}` });
                    }, 1000)}
                    className="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />

                <Box
                    sx={{
                        display: 'flex',
                        gap: '20px',
                        marginTop: { xs: '1rem', sm: '1rem', md: 0 },
                        flexDirection: { xs: 'column', sm: 'row' }
                    }}
                >
                    <FuelFilter checked={checked} handleChecked={handleChecked} reset={reset} filterHandler={handleFilter} params={params} />

                    {((permissions.fuelreport && permissions.fuelreport.add) || user_types.includes(user_type)) && (
                        <AddFormButton
                            url={`/accounting/fuel-reports/driver/${id}/add?driver_name=${driver_name}&truck_number=${truck_number}&trailer_number=${trailer_number}`}
                            btnText="ADD NEW FUEL"
                            sx={{
                                maxHeight: { xs: '6vh', sm: '4vh' },
                                minWidth: { xs: '17rem', sm: '14rem' }
                            }}
                        />
                    )}
                </Box>
            </Box>
        </Toolbar>
    );
}

function AllFuelDriverViewForm() {
    const { id } = useParams();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteBulkPending, setDeleteBulkPending] = useState(false);

    const currentLocation = useLocation();
    const queryParams = new URLSearchParams(currentLocation.search);
    const params = new URLSearchParams(queryParams);
    const search = params.get('search') || '';

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const [checked, setChecked] = useState([]);

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const fetchFuelReports = async () => {
        const startDate = params.get('start_transaction_date') || '';
        const endDate = params.get('end_transaction_date') || '';
        const location = params.get('location_name') || '';
        const city = params.get('city') || '';
        const state = params.get('state') || '';

        const response = await axios.get(
            `/v1/api/accounting/fuel-reports/?page=${page + 1}&page_size=${rowsPerPage}&truck=${id}${search && `&search=${search}`}${city && `&city=${city}`}${
                state && `&state=${state}`
            }${location && `&location_name=${location}`}${startDate && `&start_transaction_date=${startDate}`}${endDate && `&end_transaction_date=${endDate}`}`,
            {
                headers: {
                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                }
            }
        );
        return response.data;
    };
    const [editStatus, setEditStatus] = useState({
        id: null,
        status: null
    });

    const changeEditStatus = (status) => {
        setEditStatus(status);
    };

    const {
        data: fuelReports,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['fuel-reports', search, page, rowsPerPage],
        queryFn: fetchFuelReports,
        staleTime: 1000 * 60 * 60
    });

    const [clickedOptionId, setClickedOptionId] = useState({ id: null, open: false });

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = fuelReports?.results?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (_event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const deleteBulkFuelReport = async (formData) => {
        try {
            setDeleteBulkPending(true);
            const response = await axios.post('/v1/api/accounting/fuel-reports/bulk_delete/', formData, {
                headers: {
                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                }
            });
            await queryClient.invalidateQueries({ queryKey: ['fuel-reports', search, page, rowsPerPage] });
            return response;
        } catch (error) {
            throw new Error("Can't delete the item");
        } finally {
            setDeleteBulkPending(false);
        }
    };

    const handleBulkDelete = async () => {
        if (selected.length == 0) {
            toast.error('0 Item selected');
            return;
        }
        await toast.promise(deleteBulkFuelReport({ fuel_report_ids: selected }), {
            pending: `Deleting ${selected.length} Item...`,
            success: `${selected.length} Item deleted successfully`,
            error: `Failed to delete Item`
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const visibleRows = fuelReports?.results;

    const deleteFuelReport = async (id) => {
        return await axios.delete(`/v1/api/accounting/fuel-reports/${id}`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
    };

    const { mutate: deleteFuelReportMutate, isPending: deleteFuelReportPending } = useMutation({
        mutationFn: deleteFuelReport,
        onSuccess: async () => {
            toast.success('Fuel report deleted successfully.');
            return await queryClient.invalidateQueries({ queryKey: ['fuel-reports', search, page, rowsPerPage] });
        },
        onError: (error) => {
            toast.error(error.response.data.detail || "Can't deleted");
        }
    });

    const [editDrawer, setEditDrawer] = useState(false);

    const totalAmount = useMemo(() => {
        return fuelReports?.results?.reduce((accumulator, current) => accumulator + Number(current?.total_amount || 0), 0);
    }, [fuelReports]);

    return (
        <div style={{ width: '100%' }}>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        handleBulkDelete={handleBulkDelete}
                        deleteBulkPending={deleteBulkPending}
                        handleChecked={handleChecked}
                        search={search}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        checked={checked}
                        setChecked={setChecked}
                        params={params}
                    />

                    {isMobile ? (
                        <>
                            {visibleRows?.map((driver, idx) => {
                                const isItemSelected = isSelected(driver.id);
                                const labelId = idx;
                                console.log(driver);
                                return (
                                    <>
                                        <Card
                                            key={driver.driver_id}
                                            sx={{
                                                marginBottom: '1.5rem',
                                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                borderRadius: '15px',
                                                backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                            }}
                                        >
                                            <CardContent>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                                    <Checkbox
                                                        sx={{
                                                            padding: 0
                                                        }}
                                                        onClick={(event) => handleClick(event, driver.id)}
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId
                                                        }}
                                                    />

                                                    <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                                        {(popupState) => (
                                                            <div style={{ float: 'right', marginTop: '1px' }}>
                                                                <IconButton {...bindTrigger(popupState)} size="large">
                                                                    <MoreHorizIcon />
                                                                </IconButton>
                                                                <Menu
                                                                    {...bindMenu(popupState)}
                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                                >
                                                                    {((permissions.driver && permissions.driver.change) || user_types.includes(user_type)) && (
                                                                        <>
                                                                            <MenuItem onClick={() => setEditDrawer(true)}>Edit Expense</MenuItem>

                                                                            <EditFuelForm
                                                                                editDrawer={editDrawer}
                                                                                setEditDrawer={setEditDrawer}
                                                                                fuelReport={driver}
                                                                                setClickedOptionId={setClickedOptionId}
                                                                                changeEditStatus={changeEditStatus}
                                                                                search={search}
                                                                                page={page}
                                                                                rowsPerPage={rowsPerPage}
                                                                            />
                                                                        </>
                                                                    )}

                                                                    <MenuItem
                                                                        disabled={deleteFuelReportPending}
                                                                        onClick={() => deleteFuelReportMutate(driver.id)}
                                                                    >
                                                                        {deleteFuelReportPending ? 'Loop Expense' : 'Delete Expense'}
                                                                    </MenuItem>

                                                                    <MenuItem
                                                                        onClick={() => setClickedOptionId((prev) => ({ id: driver.id, open: !prev.open }))}
                                                                    >
                                                                        Close
                                                                    </MenuItem>
                                                                </Menu>
                                                            </div>
                                                        )}
                                                    </PopupState>
                                                </Box>

                                                <Box sx={{ clear: 'both' }} />

                                                <Typography sx={{ marginBottom: '.5rem' }}>Id : {driver.id}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Date : {driver.transaction_date}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>City : {driver.city_name}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>State : {driver.state_name}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Invoice# : {driver.invoice}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Total Gallon : {driver.total_gallons}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Total Price : ${driver.total_amount}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Location Name : {driver.location_name}</Typography>
                                            </CardContent>
                                        </Card>
                                    </>
                                );
                            })}
                            <Box sx={{ padding: '1rem', fontSize: '4vw', fontWeight: 6000 }}>Total $&nbsp;{Number(totalAmount || 0).toFixed(2)}</Box>
                        </>
                    ) : isTablet ? (
                        <>
                            {visibleRows?.map((driver, idx) => {
                                const isItemSelected = isSelected(driver.id);
                                const labelId = idx;
                                return (
                                    <>
                                        <Card
                                            key={driver.driver_id}
                                            sx={{
                                                marginTop: '2rem',
                                                marginBottom: '1.5rem',
                                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                borderRadius: '15px',
                                                backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                            }}
                                        >
                                            <CardContent>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        marginBottom: '1rem'
                                                    }}
                                                >
                                                    <Checkbox
                                                        sx={{
                                                            padding: 0
                                                        }}
                                                        onClick={(event) => handleClick(event, driver.id)}
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId
                                                        }}
                                                    />

                                                    <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                                        {(popupState) => (
                                                            <div style={{ float: 'right', marginTop: '1px' }}>
                                                                <IconButton {...bindTrigger(popupState)} size="large">
                                                                    <MoreHorizIcon />
                                                                </IconButton>
                                                                <Menu
                                                                    {...bindMenu(popupState)}
                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                                >
                                                                    {((permissions.driver && permissions.driver.change) || user_types.includes(user_type)) && (
                                                                        <MenuItem onClick={() => setEditDrawer(true)}>Edit Expense</MenuItem>
                                                                    )}

                                                                    <MenuItem
                                                                        disabled={deleteFuelReportPending}
                                                                        onClick={() => deleteFuelReportMutate(driver.id)}
                                                                    >
                                                                        {deleteFuelReportPending ? 'Loop Expense' : 'Delete Expense'}
                                                                    </MenuItem>

                                                                    <MenuItem
                                                                        onClick={() =>
                                                                            setClickedOptionId((prev) => ({
                                                                                id: driver.id,
                                                                                open: !prev.open
                                                                            }))
                                                                        }
                                                                    >
                                                                        Close
                                                                    </MenuItem>
                                                                </Menu>
                                                            </div>
                                                        )}
                                                    </PopupState>
                                                </Box>

                                                <Box sx={{ clear: 'both' }} />
                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                                        gap: '0.5rem',
                                                        marginBottom: '0.5rem'
                                                    }}
                                                >
                                                    <Typography sx={{ marginBottom: '.5rem' }}>Id : {driver.id}</Typography>
                                                    <Typography sx={{ marginBottom: '.5rem' }}>Date : {driver.transaction_date}</Typography>
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                                        gap: '0.5rem',
                                                        marginBottom: '0.5rem'
                                                    }}
                                                >
                                                    <Typography sx={{ marginBottom: '.5rem' }}>City : {driver.city_name}</Typography>
                                                    <Typography sx={{ marginBottom: '.5rem' }}>State : {driver.state_name}</Typography>
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                                        gap: '.6rem',
                                                        marginBottom: '0.5rem'
                                                    }}
                                                >
                                                    <Typography sx={{ marginBottom: '.5rem' }}>Invoice# : {driver.invoice}</Typography>
                                                    <Typography sx={{ marginBottom: '.5rem' }}>Total Gallon : {driver.total_gallons}</Typography>
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                                        gap: '.6rem',
                                                        marginBottom: '0.5rem'
                                                    }}
                                                >
                                                    <Typography sx={{ marginBottom: '.5rem' }}>Total Price : ${driver.total_amount}</Typography>
                                                    <Typography sx={{ marginBottom: '.5rem' }}>Location Name : {driver.location_name}</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </>
                                );
                            })}
                        </>
                    ) : (
                        <TableContainer>
                            <table className="expense-table" style={{ minWidth: 750, borderSpacing: '0 10px' }} aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    onSelectAllClick={handleSelectAllClick}
                                    rowCount={fuelReports?.results?.length || 0}
                                />
                                <AnimatePresence>
                                    {isLoading || isFetching ? (
                                        // laoding table skeleton
                                        <motion.tbody
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0.5, height: 0 }}
                                        >
                                            <TableRowAnimation column={10} row={4} />
                                        </motion.tbody>
                                    ) : (
                                        <motion.tbody
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0.5, height: 0 }}
                                        >
                                            {visibleRows?.map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                if (editStatus.id === row.id && editStatus.status == 'pending') {
                                                    return <TableRowAnimation row={1} column={10} />;
                                                } else {
                                                    return (
                                                        <TableRow
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            sx={{
                                                                backgroundColor: isItemSelected ? '#8080801f' : '',
                                                                paddingTop: 0,
                                                                '&:hover': {
                                                                    backgroundColor: '#8080801f'
                                                                }
                                                            }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    onClick={(event) => handleClick(event, row.id)}
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        'aria-labelledby': labelId
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{row.id}</TableCell>
                                                            <TableCell>{row.transaction_date}</TableCell>
                                                            <TableCell>{row.city_name}</TableCell>
                                                            <TableCell>{row.state_name}</TableCell>
                                                            <TableCell>{row.invoice}</TableCell>
                                                            <TableCell>{row.total_gallons}</TableCell>
                                                            <TableCell>${row.total_amount}</TableCell>
                                                            <TableCell>{row.location_name}</TableCell>
                                                            <TableCell sx={{ position: 'relative' }}>
                                                                <AnimatePresence>
                                                                    {clickedOptionId.open && clickedOptionId.id === row.id && (
                                                                        <motion.div
                                                                            initial={{
                                                                                scale: 0
                                                                            }}
                                                                            animate={{
                                                                                scale: 1
                                                                            }}
                                                                            exit={{
                                                                                scale: 0
                                                                            }}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: '35%',
                                                                                bottom: 5,
                                                                                display: 'flex',
                                                                                gap: 10,
                                                                                alignItems: 'center',
                                                                                background: '#0b172e',
                                                                                borderRadius: 10,
                                                                                padding: 0
                                                                            }}
                                                                        >
                                                                            {((permissions.fuelreport && permissions.fuelreport.change) ||
                                                                                user_types.includes(user_type)) && (
                                                                                <Tooltip title="Edit Fuel Report" placement="top">
                                                                                    <IconButton onClick={() => setEditDrawer(true)}>
                                                                                        <DriveFileRenameOutlineIcon
                                                                                            sx={{
                                                                                                bgcolor: '#8080806b',
                                                                                                color: 'white',
                                                                                                borderRadius: '50%',
                                                                                                padding: 0.5,
                                                                                                fontSize: 35
                                                                                            }}
                                                                                        />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            )}
                                                                            <EditFuelForm
                                                                                editDrawer={editDrawer}
                                                                                setEditDrawer={setEditDrawer}
                                                                                fuelReport={row}
                                                                                setClickedOptionId={setClickedOptionId}
                                                                                changeEditStatus={changeEditStatus}
                                                                                search={search}
                                                                                page={page}
                                                                                rowsPerPage={rowsPerPage}
                                                                            />

                                                                            {permissions.fuelreport && permissions.fuelreport.delete && (
                                                                                <Tooltip title="Delete Fuel Report" placement="top">
                                                                                    <IconButton
                                                                                        disabled={deleteFuelReportPending}
                                                                                        onClick={() => deleteFuelReportMutate(row.id)}
                                                                                    >
                                                                                        {deleteFuelReportPending ? (
                                                                                            <LoopIcon
                                                                                                className="rotation-icon"
                                                                                                sx={{
                                                                                                    bgcolor: '#8080806b',
                                                                                                    color: deleteFuelReportPending ? 'gray' : 'white',
                                                                                                    borderRadius: '50%',
                                                                                                    padding: 0.5,
                                                                                                    fontSize: 35
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            <DeleteIcon
                                                                                                sx={{
                                                                                                    bgcolor: '#8080806b',
                                                                                                    color: deleteFuelReportPending ? 'gray' : 'white',
                                                                                                    borderRadius: '50%',
                                                                                                    padding: 0.5,
                                                                                                    fontSize: 35
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            )}
                                                                            <Tooltip title="Close" placement="top">
                                                                                <IconButton
                                                                                    onClick={() =>
                                                                                        setClickedOptionId((prev) => ({ id: row.id, open: !prev.open }))
                                                                                    }
                                                                                >
                                                                                    <CloseIcon
                                                                                        sx={{
                                                                                            bgcolor: '#8080806b',
                                                                                            color: 'white',
                                                                                            borderRadius: '50%',
                                                                                            padding: 0.5,
                                                                                            fontSize: 35,
                                                                                            zIndex: 2
                                                                                        }}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </motion.div>
                                                                    )}
                                                                </AnimatePresence>
                                                                <Tooltip title="Open Option" placement="right-start">
                                                                    <IconButton
                                                                        onClick={() => setClickedOptionId((prev) => ({ id: row.id, open: !prev.open }))}
                                                                    >
                                                                        <MoreHorizIcon sx={{ fontSize: 35, padding: 0.5 }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            })}
                                            <tr className="total-row">
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell colSpan={2} sx={{ fontWeight: 'bolder' }}>
                                                    Total
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 'bolder' }}>$&nbsp;{Number(totalAmount || 0).toFixed(2)}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </tr>
                                        </motion.tbody>
                                    )}
                                </AnimatePresence>
                            </table>
                        </TableContainer>
                    )}

                    {fuelReports?.results?.length === 0 && !isLoading && (
                        <Typography variant="h3" align="center" sx={{ py: 3, backgroundColor: '#14295317' }}>
                            No fuel reports found
                        </Typography>
                    )}

                    {isMobile ? (
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={fuelReports?.count || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            // sx={{
                            //     '.MuiTablePagination-toolbar': {
                            //         width: '18rem',
                            //         flexWrap: 'wrap'
                            //     },
                            //     '.MuiTablePagination-selectLabel': {
                            //         mr: '4rem'
                            //     }
                            // }}
                            sx={{
                                '.MuiTablePagination-toolbar': {
                                    display: 'grid',
                                    gridTemplateColumns: '4rem 11rem',
                                    gridTemplateRows: 'auto auto',
                                    alignItems: 'center',
                                    gap: '8px'
                                },
                                '.MuiTablePagination-selectLabel': {
                                    gridColumn: '2/3',
                                    gridRow: '1 / 2',
                                    textWrap: 'nowrap',
                                    textAlign: isSmall ? 'center' : 'end'
                                },
                                '.MuiTablePagination-input': {
                                    gridColumn: '2 / 4',
                                    gridRow: '1 / 2',
                                    marginRight: 0
                                },
                                '.MuiTablePagination-displayedRows': {
                                    gridColumn: '1 / 2',
                                    gridRow: '2 / 3',
                                    textWrap: 'nowrap',
                                    textAlign: isSmall ? 'center' : 'end',
                                    marginLeft: '30vw'
                                },
                                '.MuiTablePagination-actions': {
                                    gridColumn: '1/4',
                                    gridRow: '2 / 3',
                                    display: 'flex',
                                    alignItems: 'center',
                                    // gap: '8px',
                                    justifyContent: 'flex-end'
                                }
                            }}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    ) : (
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={fuelReports?.count || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Paper>
            </Box>
        </div>
    );
}

export default AllFuelDriverViewForm;
