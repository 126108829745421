import MaterialTable from '@material-table/core';

// import AddBoxIcon from '@mui/icons-material/AddBox';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

import TABLE_ICONS from 'tableIcons';
import { format } from 'date-fns';

// import { Button, Grid, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';

import { IconButton, InputAdornment, Link, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { createRef } from 'react';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import LoadStatus from 'ui-component/buttons/LoadStatus';

import PdfModal from 'ui-component/modals/PdfModal';
import { Viewer, Worker } from '@react-pdf-viewer/core';

import EditIcon from '@mui/icons-material/Edit';
import InvoiceTrackingModal from 'ui-component/modals/InvoiceTrackingModal';
import CarrierModal from 'ui-component/modals/CarrierModal';
import CustomerViewModal from 'ui-component/modals/CustomerViewModal';
import AllInvoiceTrackingFilter from 'ui-component/filters/AllInvoiceTrackingFilter';
const AllInvoiceManagmentForm = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const tableRef = createRef();

    const [carrierOpen, setCarrierOpen] = useState(false);
    const [custOpen, setCustOpen] = useState(false);
    const [invOpen, setInvOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);

    const [checked, setChecked] = useState(['doc']);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [searchFltr, setSearchFltr] = useState('');
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.user_type === 'super_dispatcher_admin' ? '' : loginInfoFromStorage.company_id;

    const [loading, setLoading] = useState(false);

    const [fileName, setFileName] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const [startPrice, setStartPrice] = useState('');
    const [endPrice, setEndPrice] = useState('');
    const [carrierInfo, setCarrierInfo] = useState('');
    const [custInfo, setCustInfo] = useState('');
    const [statusChecked, setStatusChecked] = useState([]);
    const [factorId, setFactorId] = useState(0);

    const handleCloseCarrier = () => {
        setCarrierOpen(false);
    };
    const handleClickOpenCarrier = (info) => {
        setCarrierOpen(true);
        setCarrierInfo(info);
    };

    const handleCloseCust = () => {
        setCustOpen(false);
    };
    const handleClickOpenCust = (info) => {
        setCustOpen(true);
        setCustInfo(info);
    };

    const handleCloseInv = () => {
        setInvOpen(false);
    };
    const handleClickOpenInv = (info) => {
        setInvOpen(true);
        setFileName(info.inv_doc);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };
    const handleClickOpenModal = (id) => {
        setFactorId(id);
        setOpen(true);
    };

    const handleCloseDoc = () => {
        setModalOpen(false);
    };

    function handleDownloadModalDoc(file) {
        window.open(file, '_blank');
    }
    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        setSearchSts(newChecked1);
    };

    const reset = () => {
        setStartDate('');
        setEndDate('');
        setStartPrice('');
        setEndPrice('');
        setChecked([]);
        setStatusChecked([]);

        tableRef.current.onQueryChange();
    };

    const approveHandler = async () => {
        try {
            const { data } = await axios.patch(`/v1/api/dispatch/load/bol-update/`, { dsp_load_id: loadId }, config);

            setLoading(false);
            dispatch(openSuccessSnackbar('Approved successfully'));
            setOpen1(false);
            handleCloseDoc();
            tableRef.current.onQueryChange();
        } catch (error) {
            dispatch(openErrorSnackbar(error));
        }
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderTable = () => {
        tableRef.current.onQueryChange();
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Customer Name</th>
                    <th className="headerTd rightHidBor">Carrier</th>
                    <th className="headerTd rightHidBor">Load#</th>
                    <th className="headerTd rightHidBor">Invoice#</th>
                    <th className="headerTd rightHidBor">Invoice Status#</th>
                    <th className="headerTd rightHidBor">Payment Status#</th>
                    <th className="headerTd rightHidBor">Amount</th>
                    <th className="headerTd rightHidBor"> Date</th>
                    <th className="headerTd rightHidBor">Description</th>
                    <th className="headerTd "> Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Link
                        component="button"
                        onClick={() => {
                            handleClickOpenCust(rowData.data.cust_info);
                        }}
                    >
                        {rowData.data.cust_info.cust_name}
                    </Link>
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Link
                        component="button"
                        onClick={() => {
                            handleClickOpenCarrier(rowData.data.carrier_info);
                        }}
                    >
                        {rowData.data.carrier_info.carrier_name}
                    </Link>
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.load_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Link
                        component="button"
                        onClick={() => {
                            handleClickOpenInv(rowData.data.inv_info);
                        }}
                    >
                        {rowData.data.inv_info.inv_no}
                    </Link>
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <LoadStatus
                        text={rowData.data.fact_status}
                        type={
                            rowData.data.fact_status === 'approved'
                                ? 'success'
                                : rowData.data.fact_status === 'submitted'
                                ? 'inprog'
                                : rowData.data.fact_status === 'pending'
                                ? 'error'
                                : rowData.data.fact_status === 'rejected'
                                ? 'error'
                                : rowData.data.fact_status === 'disputed'
                                ? 'error'
                                : ''
                        }
                    />
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <LoadStatus
                        text={rowData.data.payment_status}
                        type={
                            rowData.data.payment_status === 'generated'
                                ? 'success'
                                : rowData.data.payment_status === 'approved'
                                ? 'success'
                                : rowData.data.payment_status === 'submitted'
                                ? 'inprog'
                                : rowData.data.payment_status === 'pending'
                                ? 'error'
                                : ''
                        }
                    />
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.amount}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.payment_date && format(new Date(rowData.data.payment_date), 'dd/MM/yyyy')}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.description}
                </td>

                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => handleClickOpenModal(rowData.data.factor_id)}>
                        <EditIcon />
                    </IconButton>
                </td>
            </tr>
        );
    };

    return (
        <>
            <Stack
                sx={{ mt: 5, mb: 2 }}
                direction={matchDownMd ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={matchDownMd ? 'flex-start' : 'center'}
                spacing={4}
            >
                <TextField
                    type="text"
                    size="small"
                    placeholder="Search"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    sx={{ width: '350px' }}
                    className="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <div>
                    <AllInvoiceTrackingFilter
                        checked={checked}
                        handleClose={handleClose}
                        handleChecked={handleChecked}
                        startDate={startDate}
                        startDateHandler={(e) => setStartDate(format(new Date(e), 'yyyy-MM-dd'))}
                        endDate={endDate}
                        endDateHandler={(e) => setEndDate(format(new Date(e), 'yyyy-MM-dd'))}
                        handleStatus={handleStatus}
                        statusChecked={statusChecked}
                        reset={reset}
                        filterHandler={() => {
                            tableRef.current.onQueryChange();
                        }}
                    />
                </div>
            </Stack>

            <MaterialTable
                tableRef={tableRef}
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none'
                }}
                data={(query) =>
                    new Promise((resolve, reject) => {
                        fetch(
                            `/v1/api/dispatch/factor-comp/list?page=${query.page + 1}&page_size=${
                                query.pageSize
                            }&fct_id__fct_comp_id=${cid}&search=${searchFltr}&start_amount=${startPrice}&end_amount=${endPrice}&start_inv_date=${startDate}&end_inv_date=${endDate}`,
                            {
                                method: 'get',

                                headers: new Headers({
                                    'Content-type': 'application/json',
                                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                                })
                            }
                        )
                            .then((response) => response.json())
                            .then((result) => {
                                resolve({
                                    data: result.results,
                                    page: query.page,
                                    totalCount: result.count
                                });
                            });
                    })
                }
                title=""
                columns={[]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    sorting: true,
                    exportButton: false,

                    toolbar: false,
                    draggable: false,
                    columnResizable: true
                }}
            />
            <InvoiceTrackingModal open={open} handleClose={handleCloseModal} factorId={factorId} type="invManage" renderTable={renderTable} />
            <CarrierModal open={carrierOpen} handleClose={handleCloseCarrier} carrierInfo={carrierInfo} />
            <CustomerViewModal open={custOpen} handleClose={handleCloseCust} custInfo={custInfo} />

            <PdfModal
                open={invOpen}
                fullWidth
                fullScreen
                maxWidth="md"
                handleClose={() => handleCloseInv()}
                handleDownload={() => handleDownloadModalDoc(fileName)}
                approveHandler={() => {
                    approveHandler();
                }}
                disapproveHandler={() => {
                    // disapproveHandler();
                    // handleCloseDoc();
                }}
                fileName={fileName}
                type="invoiceTracking"
                content={
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer fileUrl={fileName} />
                    </Worker>
                }
            />
        </>
    );
};

export default AllInvoiceManagmentForm;
