// project imports

import MainCard from 'ui-component/cards/MainCard';

import DriverSafetyComplianceForm from 'ui-component/safetyCompliance/DriverSafetyComplianceForm';
import PageLayout from 'layout/PageLayout';

// ==============================|| SAMPLE PAGE ||============================== //

const DriverSafteyCompliance = () => {
    return (
        <>
            <MainCard>
                <PageLayout title="Saftey and Compliance">
                    <DriverSafetyComplianceForm />
                </PageLayout>
            </MainCard>
        </>
    );
};

export default DriverSafteyCompliance;
