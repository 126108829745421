
import MainCard from 'ui-component/cards/MainCard';
import MaintenanceForm from 'ui-component/mechanic/forms/MaintenanceForm';



function Maintenance() {
    return (
        <MainCard>
      <MaintenanceForm/>
        </MainCard>
    );
}

export default Maintenance;
