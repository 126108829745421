import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { LoadingButton } from '@mui/lab';
import { Button, Skeleton, TextField, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { queryClient } from 'index';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import AddCategoryModal from './AddCategoryModal';
import AddTermsModal from './AddTermsModal';
import AddVendorModal from './AddVendorModal';
import './expenses.css';
import { NumberOnlyInput } from 'utils/NumberOnlyInput';
import { useMemo } from 'react';

const billSchema = Yup.object().shape({
    vendor: Yup.string().required('Vendor is required'),
    bill_no: Yup.string().required('Bill no is required'),
    bill_date: Yup.date().typeError('Invalid date').required('Bill date is required'),
    // due_date: Yup.date().typeError('Invalid date').required('Due date is required'),
    // terms: Yup.string().required('Terms is required'),
    // mailing_address: Yup.string().required('Mailing address is required'),
    // attachment: Yup.mixed().required('attachment is required'),
    bill_amount: Yup.array().of(
        Yup.object().shape({
            // category: Yup.string().required('Category is required'),
            // description: Yup.string().required('Description is required'),
            amount: Yup.string().required('Amount is required')
        })
    )
});

function EditBillModal({ editBillDrawer, setEditBillDrawer, changeEditStatus, bill, setClickedOptionId, search, page, rowsPerPage }) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const isMobile = useMediaQuery('(max-width:600px)');

    const addAmountDescription = (index) => {
        formik.setValues((prev) => ({
            ...prev,
            bill_amount: [
                ...prev.bill_amount,
                {
                    category: '',
                    description: '',
                    amount: '',
                    open: true
                }
            ]
        }));
    };

    const getVendorList = async () => {
        const response = await axios.get(`/v1/api/accounting/vendors/?page=1&page_size=500`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });

        return response.data.results;
    };

    const getCategories = async () => {
        const response = await axios.get(`/v1/api/accounting/categories/?page=1&page_size=100`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });

        return response.data.results;
    };

    const getTerms = async () => {
        const response = await axios.get(`/v1/api/accounting/terms/?page=1&page_size=100`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });

        return response.data.results;
    };

    const {
        data: vendors,
        isLoading: vendorsLoading,
        isFetching: vendorsFetching
    } = useQuery({
        queryKey: ['vendors', '', 1, 500],
        queryFn: getVendorList,
        staleTime: 1000 * 60 * 60 * 5
    });

    const {
        data: categories,
        isLoading: categoriesLoading,
        isFetching: categoriesFetching
    } = useQuery({
        queryKey: ['categories'],
        queryFn: getCategories,
        staleTime: 1000 * 60 * 60 * 5
    });

    const {
        data: terms,
        isLoading: termsLoading,
        isFetching: termsFetching
    } = useQuery({
        queryKey: ['terms'],
        queryFn: getTerms,
        staleTime: 1000 * 60 * 60 * 5
    });

    const editBill = async (formData) => {
        const response = await axios.put(`/v1/api/accounting/bills/${bill?.id}/`, formData, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response;
    };

    const {
        mutate: editBillMutate,
        isPending: editBillPending,
        status: editBillStatus
    } = useMutation({
        mutationFn: editBill,
        onSuccess: async () => {
            toast.success('bill updated successfully');
            setEditBillDrawer(false);
            formik.resetForm();
            setClickedOptionId((prev) => ({ id: bill?.id, open: !prev.open }));
            changeEditStatus({
                id: bill?.id,
                status: 'idle'
            });
            return await queryClient.invalidateQueries({ queryKey: ['bills', search, page, rowsPerPage] });
        }
    });

    // passing the editBill status to parent for better user experience
    useEffect(() => {
        changeEditStatus({
            id: bill?.id,
            status: editBillStatus
        });
    }, [editBillStatus]);

    const removeAmountDescription = (index) => {
        formik.setValues((prev) => ({
            ...prev,
            bill_amount: prev.bill_amount.filter((_amount, i) => i !== index)
        }));
    };
    const [vendorOpen, setVendorOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);

    // Initial values for Formik form validation
    const billInitialValues = {
        vendor: bill?.vendor,
        vendor_name: bill?.vendor_name,
        bill_no: bill?.bill_no,
        bill_date: bill?.bill_date,
        due_date: bill?.due_date,
        terms: bill?.terms,
        terms_name: terms.filter((term) => term.id == bill.terms)[0]?.name,
        mailing_address: bill?.mailing_address,
        bill_amount: bill?.bill_amount?.map((item) => ({
            ...item,
            amount: parseFloat(item.amount).toFixed(2)
        }))
    };

    if (!bill.bill_amount[0]) {
        billInitialValues['bill_amount'] = [
            {
                category: '',
                category_name: '',
                description: '',
                amount: '',
                open: true,
                category_open: false
            }
        ];
    }

    const formik = useFormik({
        initialValues: billInitialValues,
        validationSchema: billSchema,
        onSubmit: (values, _action) => {
            // Deep clone the values object
            const data = JSON.parse(JSON.stringify(values));

            // Map over the bill_amount array and transform each item
            data.bill_amount = data.bill_amount.map((amountDes) => {
                const transformed_amount = {
                    amount: amountDes.amount
                };
                if (amountDes.category) {
                    transformed_amount['category'] = amountDes.category;
                }
                if (amountDes.description) {
                    transformed_amount['description'] = amountDes.description;
                }

                if (amountDes.id) {
                    transformed_amount['id'] = amountDes.id;
                }

                return transformed_amount;
            });

            delete data.vendor_name;
            delete data.terms_name;
            delete data.terms;
            delete data.bill_date;
            delete data.due_date;

            if (values.terms) {
                data.terms = values.terms;
            }

            if (values.bill_date) {
                data.bill_date = format(new Date(values.bill_date), 'yyyy-MM-dd');
            }
            if (values.due_date) {
                data.due_date = format(new Date(values.due_date), 'yyyy-MM-dd');
            }

            data.bill_amount = JSON.stringify(data.bill_amount);

            // Create a new FormData object
            const formData = new FormData();

            // Append the transformed data to the formData object
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            editBillMutate(formData);
        }
    });

    const totalAmount = useMemo(() => {
        return formik.values.bill_amount.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0).toFixed(2);
    }, [formik.values.bill_amount]);

    const [addVendorDrawer, setAddVendorDrawer] = useState(false);
    const [addCategoryDrawer, setAddCategoryDrawer] = useState(false);
    const [addTermsDrawer, setAddTermsDrawer] = useState(false);

    return (
        <Drawer
            open={editBillDrawer}
            anchor="right"
            onClose={() => {
                setEditBillDrawer(false);
            }}
        >
            <div style={{ width: isMobile ? '100vw' : '70vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Update bill
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setEditBillDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <div style={{ width: '100%', position: 'relative' }}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="vendor"
                                label="Vendor"
                                value={formik.values.vendor_name}
                                onChange={() => setVendorOpen(true)}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.vendor && formik.errors.vendor)}
                                helperText={formik.touched.vendor && formik.errors.vendor}
                            />
                            <button type="button" className="payee-arrow" onClick={() => setVendorOpen((prev) => !prev)}>
                                {vendorOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </button>
                            {vendorOpen && (
                                <div className="payee-option">
                                    <Button
                                        style={{ color: '#FF7200', display: 'flex', justifyContent: 'start' }}
                                        startIcon={<AddIcon />}
                                        onClick={() => setAddVendorDrawer(true)}
                                    >
                                        Add New...
                                    </Button>
                                    {(vendorsLoading || vendorsFetching) && <Skeleton height={50} />}
                                    {vendors?.map((vendor) => (
                                        <button
                                            className="dropdown-button"
                                            key={vendor.id}
                                            onClick={() => {
                                                formik.setFieldValue('vendor', vendor.id);
                                                formik.setFieldValue('vendor_name', vendor.company_name);
                                                setVendorOpen(false);
                                            }}
                                        >
                                            {vendor.company_name}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div style={{ width: '100%', position: 'relative' }}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="bill_no"
                                label="Bill No"
                                value={formik.values.bill_no}
                                onBlur={formik.handleBlur}
                                onInput={NumberOnlyInput}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.bill_no && formik.errors.bill_no)}
                                helperText={formik.touched.bill_no && formik.errors.bill_no}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                className="text-input"
                                label="Bill Date"
                                openTo="day"
                                views={['year', 'month', 'day']}
                                value={formik.values.bill_date}
                                onChange={(newValue) => {
                                    formik.setFieldValue('bill_date', newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        name="bill_date"
                                        color="orange"
                                        fullWidth
                                        {...params}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.bill_date && formik.errors.bill_date)}
                                        helperText={formik.touched.bill_date && formik.errors.bill_date}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                className="text-input"
                                label="Due Date"
                                openTo="day"
                                views={['year', 'month', 'day']}
                                value={formik.values.due_date}
                                onChange={(newValue) => {
                                    formik.setFieldValue('due_date', newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        name="due_date"
                                        color="orange"
                                        fullWidth
                                        {...params}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.due_date && formik.errors.due_date)}
                                        helperText={formik.touched.due_date && formik.errors.due_date}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <div style={{ width: '100%', position: 'relative' }}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="terms"
                                label="Terms"
                                value={formik.values.terms_name}
                                onChange={() => setTermsOpen(true)}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.terms && formik.errors.terms)}
                                helperText={formik.touched.terms && formik.errors.terms}
                            />
                            <button type="button" className="payee-arrow" onClick={() => setTermsOpen((prev) => !prev)}>
                                {termsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </button>
                            {termsOpen && (
                                <div className="payee-option">
                                    <Button
                                        style={{ color: '#FF7200', display: 'flex', justifyContent: 'start' }}
                                        startIcon={<AddIcon />}
                                        onClick={() => setAddTermsDrawer(true)}
                                    >
                                        Add New...
                                    </Button>
                                    {(termsLoading || termsFetching) && <Skeleton height={50} />}
                                    {terms?.map((term) => (
                                        <button
                                            className="dropdown-button"
                                            key={term.id}
                                            onClick={() => {
                                                formik.setFieldValue('terms', term.id);
                                                formik.setFieldValue('terms_name', term.name);
                                                setTermsOpen(false);
                                            }}
                                        >
                                            {term.name}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="mailing_address"
                            label="Mailing Address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mailing_address}
                            error={Boolean(formik.touched.mailing_address && formik.errors.mailing_address)}
                            helperText={formik.touched.mailing_address && formik.errors.mailing_address}
                        />
                    </div>

                    <div>
                        <div style={{ borderBottom: '1px solid #80808052', display: 'flex', justifyContent: 'space-evenly', fontWeight: '600' }}>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}></div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}>#</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Category</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Description</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Amount</div>
                            <div style={{ textAlign: 'center', width: '10%' }}></div>
                        </div>
                        <AnimatePresence>
                            {formik.values.bill_amount?.map((amountDes, index) => (
                                <div key={index}>
                                    <AnimatePresence>
                                        {amountDes.open ? (
                                            <motion.div
                                                style={{
                                                    borderBottom: '1px solid #80808052',
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0.5 }}
                                            >
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            bill_amount: prev.bill_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    <ViewCompactIcon sx={{ cursor: 'pointer' }} />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            bill_amount: prev.bill_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {index + 1}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            bill_amount: prev.bill_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {amountDes.category_name}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            bill_amount: prev.bill_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {amountDes.description}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            bill_amount: prev.bill_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    ${amountDes.amount ? parseFloat(amountDes.amount).toFixed(2) : ''}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }}>
                                                    <RemoveIcon
                                                        sx={{
                                                            backgroundColor: formik.values.bill_amount.length == 1 ? '#ff8995' : 'red',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            fontSize: '20px'
                                                        }}
                                                        onClick={() => {
                                                            if (formik.values.bill_amount.length > 1) {
                                                                removeAmountDescription(index);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </motion.div>
                                        ) : (
                                            <motion.div
                                                style={{
                                                    borderBottom: '1px solid #80808052',
                                                    borderTop: '1px solid #80808052',
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                                initial={{ scale: 1, opacity: 0 }}
                                                animate={{ scale: 1.05, opacity: 1 }}
                                                exit={{ scale: 1, opacity: 0.5 }}
                                            >
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <AddIcon
                                                        sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%', fontSize: '20px' }}
                                                        onClick={() => addAmountDescription(index)}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            bill_amount: prev.bill_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {index + 1}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        onBlur={formik.handleBlur}
                                                        name={`bill_amount[${index}].category`}
                                                        value={amountDes.category_name}
                                                        onChange={() => {
                                                            formik.setFieldValue(`bill_amount[${index}].category_open`, true);
                                                        }}
                                                        onClick={() => {
                                                            formik.setFieldValue(`bill_amount[${index}].category_open`, true);
                                                        }}
                                                        className={
                                                            formik.touched?.bill_amount?.[index]?.category &&
                                                            formik.errors?.bill_amount?.[index]?.category &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderRight: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter category"
                                                    />
                                                    <span
                                                        style={{
                                                            borderTop: '2px solid #80808052',
                                                            borderBottom: '2px solid #80808052',
                                                            borderLeft: '0px',
                                                            borderRight: '0px',
                                                            height: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        {amountDes.category_open ? (
                                                            <KeyboardArrowUpIcon
                                                                sx={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    formik.setValues((prev) => ({
                                                                        ...prev,
                                                                        bill_amount: prev.bill_amount.map((amount, i) => {
                                                                            if (i === index) {
                                                                                return { ...amount, category_open: !amount.category_open };
                                                                            } else {
                                                                                return amount;
                                                                            }
                                                                        })
                                                                    }));
                                                                }}
                                                            />
                                                        ) : (
                                                            <KeyboardArrowDownIcon
                                                                sx={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    formik.setValues((prev) => ({
                                                                        ...prev,
                                                                        bill_amount: prev.bill_amount.map((amount, i) => {
                                                                            if (i === index) {
                                                                                return { ...amount, category_open: !amount.category_open };
                                                                            } else {
                                                                                return amount;
                                                                            }
                                                                        })
                                                                    }));
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                    {amountDes.category_open && (
                                                        <div className="category-choice">
                                                            <Button
                                                                style={{ color: '#FF7200', display: 'flex', justifyContent: 'start' }}
                                                                startIcon={<AddIcon />}
                                                                onClick={() => setAddCategoryDrawer(true)}
                                                            >
                                                                Add New...
                                                            </Button>
                                                            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                                                {(categoriesLoading || categoriesFetching) && <Skeleton height={50} />}
                                                                {categories?.map((category) => (
                                                                    <button
                                                                        className="dropdown-button"
                                                                        key={category.id}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            formik.setFieldValue(`bill_amount[${index}].category`, category.id);
                                                                            formik.setFieldValue(`bill_amount[${index}].category_name`, category.name);
                                                                            formik.setFieldValue(`bill_amount[${index}].category_open`, false);
                                                                        }}
                                                                    >
                                                                        {category.name}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        onBlur={formik.handleBlur}
                                                        name={`bill_amount[${index}].description`}
                                                        value={amountDes.description}
                                                        className={
                                                            formik.touched?.bill_amount?.[index]?.description &&
                                                            formik.errors?.bill_amount?.[index]?.description &&
                                                            'form-error'
                                                        }
                                                        onChange={formik.handleChange}
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter description"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="number"
                                                        name={`bill_amount[${index}].amount`}
                                                        onBlur={formik.handleBlur}
                                                        value={amountDes.amount}
                                                        onChange={formik.handleChange}
                                                        className={
                                                            formik.touched?.bill_amount?.[index]?.amount &&
                                                            formik.errors?.bill_amount?.[index]?.amount &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderLeft: '0px',
                                                            borderRight: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter the amount"
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }}>
                                                    <RemoveIcon
                                                        sx={{
                                                            backgroundColor: formik.values.bill_amount.length == 1 ? '#ff8995' : 'red',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            fontSize: '20px'
                                                        }}
                                                        onClick={() => {
                                                            if (formik.values.bill_amount.length > 1) {
                                                                removeAmountDescription(index);
                                                            } else {
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    bill_amount: billInitialValues.bill_amount
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            ))}
                        </AnimatePresence>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: 100, fontSize: 20, marginTop: 20, marginRight: 150 }}>
                        <strong>Total</strong>
                        <strong>${totalAmount}</strong>
                    </div>

                    <LoadingButton loading={editBillPending} type="submit" fullWidth variant="contained" style={{ backgroundColor: '#EB812E', marginTop: 30 }}>
                        Update
                    </LoadingButton>
                </form>
            </div>
            {/* Add Vendor Modal */}
            <AddVendorModal
                addDrawerOpen={addVendorDrawer}
                setAddDrawerOpen={setAddVendorDrawer}
                addVendorStatusChange={() => {}}
                search={''}
                page={1}
                rowsPerPage={500}
            />

            {/* Add Category Modal */}
            <AddCategoryModal addCategoryDrawer={addCategoryDrawer} setAddCategoryDrawer={setAddCategoryDrawer} />

            {/* Add Terms Modal */}
            <AddTermsModal addTermsDrawer={addTermsDrawer} setAddTermsDrawer={setAddTermsDrawer} />
        </Drawer>
    );
}

export default EditBillModal;
