import {
    Box,
    Button,
    Card,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
   
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

function InvPdfModal({ open, handleClose, fileName, content, approveHandler, type }) {
    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
            <DialogTitle sx={{ direction: 'row', alignItems: 'center' }}>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
                    {/* <Typography variant="h5" color="#000" fontWeight={500} fontFamily="Jost" fontSize="24px">
                        {modalText}
                    </Typography> */}

                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Box sx={{ mt: 3 }}>
                    <DialogContent dividers>
                        {fileName.split('.').pop() === 'jpeg' ||
                        fileName.split('.').pop() === 'jpg' ||
                        fileName.split('.').pop() === 'png' ? (
                            <Card>
                                <CardMedia component="img"  style={{ objectFit: 'cover' }} image={fileName} alt={`Image `} />
                            </Card>
                        ) : (
                            content
                        )}
                    </DialogContent>
                </Box>
            </DialogContent>
            {/* {type === 'loadDoc' ? (
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                        color="orange"
                        onClick={approveHandler}
                    >
                        Submit
                    </Button>
                </DialogActions>
            ) : (
                ''
            )} */}
        </Dialog>
    );
}

export default InvPdfModal;
