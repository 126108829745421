import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Slide,
    TextField,
    Typography
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MaterialTable from '@material-table/core';
import { createRef, useEffect, useState } from 'react';
import { forwardRef } from 'react';
import TABLE_ICONS from 'tableIcons';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import Status from 'ui-component/buttons/Status';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { PatternFormat } from 'react-number-format';
import axios from 'axios';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CsLoadData({ data, onSave }) {
    const tableRef = createRef();
    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
        // responseType: 'arraybuffer'
    };

    const [ldData, setLdData] = useState(data);

    const [showForm, setShowForm] = useState(false);
    const [ldRow, setLdRow] = useState(null);

    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [ssnShow, setSsnShow] = useState(true);

    useEffect(() => {
        if (!stateList) {
            fetchState();
        }
    }, [stateList]);

    const handleDelete = (rowData) => {
        setLdData(ldData.filter((item) => item.id !== rowData.id));
    };

    const fetchState = async () => {
        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
        } catch (error) {
            console.log(error.response.data);
        }
    };
    const changeStates = (value) => {
        formik.setFieldValue('state', value);

        //  setFieldValue('companyCity', '');
        getCityList(value);
    };

    const getCityList = async (id) => {
        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);
            setCityList(data);
        } catch (error) {
            console.log(error.response.data);
        }
    };

    const CustomerSchema = Yup.object().shape({
        customer_name: Yup.string().required('Customer Name is required'),
        mc_no: Yup.string().required('MC No is required'),
        dot_no: Yup.string().required('DOT No is required')
    });

    const formik = useFormik({
        initialValues: ldRow ? ldRow : {},
        validationSchema: CustomerSchema,
        onSubmit: (values) => {
            try {
                setLdData(ldData.map((item) => (item.id === values.id ? values : item)));
                setShowForm(false);
                setLdRow(null);
            } catch (error) {
                console.log(error);
            }
        }
    });

    const handleEdit = (rowData) => {
        try {
            console.log(rowData);
            setShowForm(true);
            formik.setValues(rowData);
        } catch (error) {
            console.log(error);
        }
    };

    // console.log(formik.values, ldRow);
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor ">
                        <span style={{ color: 'red' }}>*</span>Customer Name
                    </th>
                    <th className="headerTd rightHidBor">Billing Email</th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>MC No
                    </th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>DOT No
                    </th>
                    <th className="headerTd rightHidBor">Phone</th>
                    <th className="headerTd rightHidBor">Fax</th>
                    <th className="headerTd rightHidBor">Import Status</th>
                    <th className="headerTd rightHidBor">Remark</th>
                    <th className="headerTd">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.customer_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.billing_email}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.mc_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.dot_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.phone}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.fax}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Status text={rowData.data.upload_status} />
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.remark}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => handleEdit(rowData.data)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(rowData.data)}>
                        <DeleteIcon />
                    </IconButton>
                </td>
                {/* Custom cell or additional cells */}
            </tr>
        );
    };

    const formatDate = (dt) => {
        if (dt instanceof Date) {
            return format(dt, 'yyyy-MM-dd');
        } else if (dt !== '') {
            return dt;
        } else {
            return '';
        }
    };

    const formatTime = (tm) => {
        if (tm instanceof Date) {
            return format(tm, 'HH:mm');
        } else if (tm !== '') {
            return tm;
        } else {
            return '';
        }
    };

    const LetterOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const AlphanumericOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z0-9 ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.'
    };
    return (
        <>
            {showForm && (
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container sx={{ my: 3 }} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Load Data</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Customer Name"
                                    {...formik.getFieldProps('customer_name')}
                                    error={formik.touched.customer_name && Boolean(formik.errors.customer_name)}
                                    helperText={formik.touched.customer_name && formik.errors.customer_name}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="Billing Email"
                                    {...formik.getFieldProps('billing_email')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Make Year"
                                    {...formik.getFieldProps('make_year')}
                                    onInput={NumberOnlyHandler}
                                    inputProps={{ maxLength: 4 }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="MC No"
                                    {...formik.getFieldProps('mc_no')}
                                    error={formik.touched.mc_no && Boolean(formik.errors.mc_no)}
                                    helperText={formik.touched.mc_no && formik.errors.mc_no}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="DOT No"
                                    {...formik.getFieldProps('dot_no')}
                                    error={formik.touched.dot_no && Boolean(formik.errors.dot_no)}
                                    helperText={formik.touched.dot_no && formik.errors.dot_no}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField className="text-input" color="orange" fullWidth type="text" label="Phone" {...formik.getFieldProps('phone')} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField className="text-input" color="orange" fullWidth type="text" label="Fax" {...formik.getFieldProps('fax')} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        color: 'white',
                                        fontFamily: 'Lexend',
                                        fontWeight: 500,
                                        lineHeight: '1.3rem',
                                        fontSize: '16px',
                                        fontStyle: 'normal'
                                    }}
                                    color="orange"
                                    size="large"
                                    type="submit"
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            )}
            <Typography variant="h5">
                <span style={{ color: 'red' }}>*</span> marks mandatory fields
            </Typography>
            <MaterialTable
                tableRef={tableRef}
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                data={ldData}
                title="All Truck"
                columns={[]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    sorting: true,
                    exportButton: false,
                    filtering: false,
                    toolbar: false,
                    draggable: false,
                    columnResizable: true,
                    paging: false
                }}
            />
            <Button
                variant="contained"
                sx={{
                    color: 'white',
                    fontFamily: 'Lexend',
                    fontWeight: 500,
                    lineHeight: '1.3rem',
                    fontSize: '16px',
                    fontStyle: 'normal'
                }}
                color="orange"
                size="large"
                type="button"
                onClick={() => onSave(ldData)}
            >
                Save Data
            </Button>
        </>
    );
}

export default CsLoadData;
