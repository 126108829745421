import React from 'react';
import ViewDriverForm from 'ui-component/fleet/forms/ViewDriverForm';
import PageLayoutWithBack from 'layout/PageLayoutWithBack';

function ViewDriver() {
    return (
        <PageLayoutWithBack>
            {/* <CardLayout cardHeadTitle="Driver Details"> */}
            <ViewDriverForm />
            {/* </CardLayout> */}
        </PageLayoutWithBack>
    );
}

export default ViewDriver;
