import { useState, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, useMediaQuery } from '@mui/material';

// third party
import * as Yup from 'yup';

import { Form, FormikProvider, useFormik } from 'formik';

// project imports

import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { useDispatch } from 'react-redux';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const ServiceForm = ({ fetchService, handleClose, serviceId }) => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [companySetup, setCompanySetup] = useState();
    const [serviceInfo, setServiceInfo] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorUpdate, setUpdateError] = useState();
    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const googleHandler = async () => {
        console.error('Register');
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    useEffect(() => {
        if (!companySetup) {
            fetchCompany();
        }

        if (serviceId) {
            fetchServiceInfo();
        }
    }, [companySetup]);

    const fetchServiceInfo = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/mechanic/mechanic-service-type/view/${serviceId}/`, config);
          
            setServiceInfo(data);
            setLoading(false);
        } catch (error) {
          
            setLoading(false);
        }
    };

    const fetchCompany = async () => {
        setLoading(true);

        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);
          
            setCompanySetup(data);
            setLoading(false);
        } catch (error) {
          
            setLoading(false);
        }
    };

    const RegisterSchema = Yup.object().shape({
        serviceName: Yup.string().required('Service Name is a required field')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            serviceName: serviceInfo ? serviceInfo.service_type_name : ''
        },
        validationSchema: RegisterSchema,
        onSubmit: async () => {
            try {
                if (!serviceId) {
                    const { data } = await axios.post(
                        `/v1/api/mechanic/mechanic-service-type/add/`,
                        {
                            service_type_name: values.serviceName,
                            service_type: values.serviceName,
                            is_common: true,
                            mcn_company_id: companySetup.dsp_company_id
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar('Service Type added successfully'));

                    handleClose();
                    fetchService();
                } else {
                    const { data } = await axios.put(
                        `/v1/api/mechanic/mechanic-service-type/view/${serviceId}/`,
                        {
                            service_type_name: values.serviceName,
                            service_type: values.serviceName,
                            is_common: true,
                            mcn_company_id: companySetup.dsp_company_id,
                            created_by: loginInfoFromStorage.user_id
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar('Service Type updated successfully'));

                    handleClose();
                    fetchService();
                }
            } catch (error) {
              
                setUpdateError(error.response.data.detail);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
    
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="h1" gutterBottom>
                                    Add Service
                                </Typography>
                                {errorUpdate && (
                                    <Typography variant="h6" color="error" textAlign="center">
                                        {errorUpdate}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={matchDownSM ? 0 : 2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Service Name"
                                margin="normal"
                                type="text"
                                {...getFieldProps('serviceName')}
                                error={Boolean((touched.serviceName && errors.serviceName) || (errorUpdate && errorUpdate.service_name))}
                                helperText={(touched.serviceName && errors.serviceName) || (errorUpdate && errorUpdate.service_name)}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }} align="center">
                        <AnimateButton>
                            <LoadingFormButton load={isSubmitting ? true : false} type="submit" btnText="Save" />
                        </AnimateButton>
                    </Box>
                </Form>
            </FormikProvider>
        </>
    );
};

export default ServiceForm;
