import { MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function GallonBurnedPerDriverChart({ isReady, send }) {
    const [selectedOption, setSelectedOption] = useState('yearly');
    const dashboardState = useSelector((state) => state.dashboard);
    const [isRequest, setIsRequest] = useState(true);

    const isMobile = window.innerWidth < 600;
    const isTablet = window.innerWidth >= 600 && window.innerWidth <= 1024;

    const option = {
        animationDuration: 1500,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#283b56'
                }
            },
            textStyle: {
                fontSize: isMobile ? 8 : isTablet ? 13 : 12
            }
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        color: ['#f48120', '#5AC47C', '#142953'],
        dataset: {
            source: dashboardState.gallonsBurned || [
                ['gallons', 'driver'],
                [0, 'Driver 1'],
                [0, 'Driver 2'],
                [0, 'Driver 3'],
                [0, 'Driver 4'],
                [0, 'Driver 5'],
                [0, 'Driver 6'],
                [0, 'Driver 7'],
                [0, 'Driver 8'],
                [0, 'Driver 9']
            ]
        },
        grid: {
            containLabel: true,
            left: 5,
            // bottom: 20,
            // top: 30,
            // right: 60,
            bottom: isMobile ? 60 : isTablet ? 45 : 20,
            top: isMobile ? 40 : isTablet ? 35 : 30,
            left: isMobile ? 12 : isTablet ? 20 : 5,
            right: isMobile ? 52 : isTablet ? 65 : 60
        },
        xAxis: {
            name: 'gallons',
            axisLabel: {
                fontSize: isMobile ? 9 : isTablet ? 13 : 12
            }
        },
        yAxis: {
            type: 'category',
            axisLabel: {
                fontSize: isMobile ? 9 : isTablet ? 13 : 12
            }
        },
        series: [
            {
                type: 'bar',
                encode: {
                    x: 'gallons',
                    y: 'driver'
                },
                barMaxWidth: isMobile ? 20 : 30
            }
        ]
    };

    const handleChange = (e) => {
        setIsRequest(true);
        setSelectedOption(e.target.value);
    };

    useEffect(() => {
        if (isRequest && isReady) {
            send({ event_type: 'gallons_burned', quarter: selectedOption });
            setIsRequest(false);
        }
    }, [isReady, selectedOption]);
    return (
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '20px', borderRadius: '15px', height: '400px' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: { xs: 'start', sm: 'center' },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    }
                }}
            >
                {' '}
                <Typography
                    sx={{
                        fontSize: { xs: '6vw', lg: '1.7vw', sm: '3vw' },
                        margin: 0
                    }}
                >
                    Gallons Burned Per Driver
                </Typography>
                <TextField
                    select
                    value={selectedOption}
                    onChange={handleChange}
                    sx={{
                        padding: { xs: '0', sm: '10px 20px' },
                        borderRadius: '5px',
                        fontWeight: '800',
                        WebkitBackgroundOrigin: 'initial',
                        mt: { xs: '1rem', sm: '0' }
                    }}
                >
                    <MenuItem value={'weekly'}>Current Week</MenuItem>
                    <MenuItem value={'monthly'}>Current Month</MenuItem>
                    <MenuItem value={'yearly'}>Current Year</MenuItem>
                </TextField>
            </Box>
            {dashboardState.gallonsBurned?.length === 1 ? (
                <div style={{ display: 'grid', height: '100%', placeItems: 'center' }}>No Driver Found</div>
            ) : (
                <>
                    {dashboardState.gallonsBurned !== undefined ? (
                        <ReactECharts option={option} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
                    ) : (
                        <Skeleton height={'100%'} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
                    )}
                </>
            )}
        </div>
    );
}

export default GallonBurnedPerDriverChart;
