import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Fleet from 'views/fleet';
import Setup from 'views/setup';
import Accounting from 'views/accounting';
import AddDriver from 'views/fleet/addDriver';
import AddOwner from 'views/fleet/addOwner';
import EditDriver from 'views/fleet/editDriver';
import EditOwner from 'views/fleet/editOwner';
import AddTruck from 'views/fleet/addTruck';
import EditTruck from 'views/fleet/editTruck';
import AddUser from 'views/setup/addUser';
import EditUser from 'views/setup/editUser';
import AddCustomer from 'views/dispatchCenter/addCustomer';
import EditCustomer from 'views/dispatchCenter/editCustomer';
import AddTrailer from 'views/fleet/addTrailer';
import EditTrailer from 'views/fleet/editTrailer';
import AllCustomer from 'views/dispatchCenter/allCustomer';
import MyLoad from 'views/dispatchCenter/myLoad';
import AllDriver from 'views/fleet/AllDriver';
import AllOwner from 'views/fleet/AllOwner';
import AllTrailer from 'views/fleet/allTrailer';
import AllTruck from 'views/fleet/allTruck';
import ViewDriver from 'views/fleet/viewDriver';
import ViewTrailer from 'views/fleet/viewTrailer';
import ViewTruck from 'views/fleet/viewTruck';
import AllUser from 'views/setup/allUser';
import EditOrganization from 'views/setup/editOrganization';
import UserProfile from 'views/setup/UserProfile';
import EditAccounting from 'views/setup/editAccounting';
import ViewAccounting from 'views/setup/viewAccounting';
import AddMyLoad from 'views/dispatchCenter/AddMyLoad';
import EditMyLoad from 'views/dispatchCenter/EditMyLoad';
import ViewMyLoad from 'views/dispatchCenter/ViewMyLoad';
import ViewMyLoadStatus from 'views/dispatchCenter/ViewMyLoadStatus';
import ViewMyLoadStatusTest from 'views/dispatchCenter/ViewMyLoadStatusTest';
import InvMyLoad from 'views/dispatchCenter/InvMyLoad';
import AllOtherDoc from 'views/dispatchCenter/allOtherDoc';
import ViewCustomer from 'views/dispatchCenter/ViewCustomer';
import ViewMember from 'views/setup/ViewMember';
import AddSettlement from 'views/accounting/addSettlement';
import AddIfta from 'views/accounting/addIfta';
import AddTaxConfig from 'views/accounting/addTaxConfig';
import AllIfta from 'views/accounting/allIfta';
import SafteyCompliance from 'views/safteyCompliance';
import DriverSafteyCompliance from 'views/safteyCompliance/driverSafteyCompliance';
import TruckSafteyCompliance from 'views/safteyCompliance/truckSafteyCompliance';
import AllFileType from 'views/setup/allFileType';
import AllExpenseItem from 'views/setup/allExpenseItem';
import EditSettlement from 'views/accounting/editSettlement';
import Report from 'views/reports';
import CompanyProfile from 'views/setup/companyProfile';
import DataImport from 'views/setup/dataImport';
import AllMyinvoice from 'views/accounting/allMyinvoice';
import AuditLog from 'views/auditLog';
import FuelReports from 'views/accounting/FuelReports';
import ExternalExpenses from 'views/accounting/ExternalExpenses';

import Factoring from 'views/factoring';
import AllInvoiceTracking from 'views/factoring/allInvoiceTracking';
import AllInvoiceManagement from 'views/factoring/allInvoiceManagement';
import AllPaymentTracking from 'views/factoring/allPaymentTracking';
import Param from 'views/pages/authentication/authentication/Param';
import Integration from 'views/setup/integration';
import MechanicSetup from 'views/pages/authentication/authentication/MechanicSetup';
import AllMechanics from 'views/pages/mechanic/AllMechanics';
import AllServices from 'views/pages/mechanic/AllServices';
import MechanicCompanyProfile from 'views/pages/mechanic/mechanicCompanyProfile';
import EditMechanicCompanyProfile from 'views/pages/mechanic/editmechanicCompanyProfile';
import AllMaintenance from 'views/pages/mechanic/AllMaintenance';
import Maintenance from 'views/pages/mechanic/maintenance';
import MechanicDriver from 'views/pages/authentication/authentication/MechanicDriver';
import AllMechanicDriver from 'views/pages/mechanic/AllMechanicDriver';
import AllSchedule from 'views/pages/mechanic/AllSchedule';
import Schedule from 'views/pages/mechanic/schedule';

import MechanicCompanySetup from 'views/pages/mechanic/mechanicCompanySetup';
import FuelDriverListById from 'views/accounting/FuelDriverListById';
import AddFuelReportsToTruck from 'views/accounting/AddFuelReportsToTruck';
import HomeMapView from 'views/accounting/HomeMapView';
import AllSettlementExpenseItem from 'views/setup/allSettlementExpenseItem';
import DriverIfta from 'views/accounting/DriverIfta';
import StateIfta from 'views/accounting/StateIfta';
import AddNewIfta from 'ui-component/accounting/forms/AddNewIfta';
import AllReceivedPayment from 'views/factoring/allReceivedPayment';

// new dashboard
import Dashboard from 'views/dashboard-new/Dashboard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const DispatchCenter = Loadable(lazy(() => import('views/dispatchCenter')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <HomeMapView />
        },
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'dispatch-center',
            element: <DispatchCenter />
        },
        {
            path: 'dispatch-center/load/add',
            element: <AddMyLoad />
        },
        {
            path: 'dispatch-center/load/edit/:id',
            element: <EditMyLoad />
        },
        {
            path: 'dispatch-center/load/invoice/:id',
            element: <InvMyLoad />
        },
        {
            path: 'dispatch-center/load/view/:id',
            element: <ViewMyLoad />
        },
        {
            path: 'dispatch-center/other-doc/:id/:inv/',
            element: <AllOtherDoc />
        },
        {
            path: 'dispatch-center/load/view-status/:id',
            element: <ViewMyLoadStatus />
        },
        {
            path: 'dispatch-center/load/view-status-test/:id',
            element: <ViewMyLoadStatusTest />
        },
        {
            path: 'fleet',
            element: <Fleet />
        },
        {
            path: 'user/profile',
            element: <UserProfile />
        },
        {
            path: 'company/profile/edit',
            element: <EditOrganization />
        },
        {
            path: 'accounting/settlement',
            element: <Accounting />
        },
        {
            path: 'accounting/settlement/add',
            element: <AddSettlement />
        },
        {
            path: 'accounting/settlement/edit/:id',
            element: <EditSettlement />
        },
        {
            path: 'accounting/ifta/add',
            element: <AddIfta />
        },
        {
            path: 'accounting/ifta/new/add',
            element: <AddNewIfta />
        },
        {
            path: 'accounting/ifta/all',
            element: <AllIfta />
        },
        {
            path: 'accounting/ifta/driver/:year/:quarter',
            element: <DriverIfta />
        },
        {
            path: 'accounting/ifta/state/:year/:quarter',
            element: <StateIfta />
        },
        {
            path: 'accounting/my-invoice/all',
            element: <AllMyinvoice />
        },
        {
            path: 'accounting/configuration/tax',
            element: <AddTaxConfig />
        },
        {
            path: '/accounting/fuel-reports',
            element: <FuelReports />
        },
        {
            path: '/accounting/fuel-reports/driver/:id',
            element: <FuelDriverListById />
        },
        {
            path: '/accounting/fuel-reports/driver/:id/add',
            element: <AddFuelReportsToTruck />
        },
        {
            path: '/accounting/external-expenses',
            element: <ExternalExpenses />
        },
        {
            path: 'factoring/my-payments',
            element: <Factoring />
        },
        {
            path: 'factoring/invoice-tracking',
            element: <AllInvoiceTracking />
        },
        {
            path: 'factoring/invoice-managment',
            element: <AllInvoiceManagement />
        },
        {
            path: 'factoring/payment-tracking',
            element: <AllPaymentTracking />
        },
        {
            path: 'factoring/received-payment',
            element: <AllReceivedPayment />
        },
        {
            path: 'company/accounting',
            element: <ViewAccounting />
        },
        {
            path: 'company/accounting/edit',
            element: <EditAccounting />
        },
        {
            path: 'fleet/driver/add',
            element: <AddDriver />
        },

        {
            path: 'fleet/driver/all',
            element: <AllDriver />
        },
        {
            path: 'fleet/driver/edit/:id',
            element: <EditDriver />
        },
        {
            path: 'fleet/driver/view/:id',
            element: <ViewDriver />
        },
        {
            path: 'fleet/truck/add',
            element: <AddTruck />
        },

        {
            path: 'fleet/truck/all',
            element: <AllTruck />
        },
        {
            path: 'fleet/truck/edit/:id',
            element: <EditTruck />
        },
        {
            path: 'fleet/truck/view/:id',
            element: <ViewTruck />
        },
        {
            path: 'setup/user/all',
            element: <AllUser />
        },
        {
            path: 'setup/user/add',
            element: <AddUser />
        },

        {
            path: 'setup/user/edit/:id',
            element: <EditUser />
        },

        {
            path: 'setup/user/view/:id',
            element: <ViewMember />
        },
        {
            path: 'setup/configuration/file',
            element: <AllFileType />
        },
        {
            path: 'setup/invoice/expense/item',
            element: <AllExpenseItem />
        },
        {
            path: 'setup/settlement/expense/item',
            element: <AllSettlementExpenseItem />
        },
        {
            path: 'dispatch-center/customer/all',

            element: <AllCustomer />
        },

        {
            path: 'dispatch-center/my-load',

            element: <MyLoad />
        },
        {
            path: 'dispatch-center/load/add',

            element: <MyLoad />
        },
        {
            path: 'dispatch-center/customer/add',
            element: <AddCustomer />
        },
        {
            path: 'dispatch-center/customer/edit/:id',
            element: <EditCustomer />
        },
        {
            path: 'dispatch-center/customer/view/:id',
            element: <ViewCustomer />
        },
        {
            path: 'fleet/trailer/all',
            element: <AllTrailer />
        },
        {
            path: 'fleet/trailer/add',
            element: <AddTrailer />
        },
        {
            path: 'fleet/trailer/edit/:id',
            element: <EditTrailer />
        },
        {
            path: 'fleet/trailer/view/:id',
            element: <ViewTrailer />
        },
        {
            path: 'fleet/owner/add',
            element: <AddOwner />
        },

        {
            path: 'fleet/owner/all',
            element: <AllOwner />
        },
        {
            path: 'fleet/owner/edit/:id',
            element: <EditOwner />
        },
        {
            path: 'safety-compliance',
            element: <SafteyCompliance />
        },
        {
            path: 'safety-compliance/driver/:id',
            element: <DriverSafteyCompliance />
        },
        {
            path: 'safety-compliance/truck/:id/:tId',
            element: <TruckSafteyCompliance />
        },
        {
            path: 'reports',
            element: <Report />
        },
        {
            path: 'company/profile',
            element: <CompanyProfile />
        },
        {
            path: 'setup/data/import',
            element: <DataImport />
        },
        {
            path: 'setup/integration',
            element: <Integration />
        },
        {
            path: 'mechanic/maintenance',
            element: <AllMaintenance />
        },
        {
            path: 'mechanic/mechanics',
            element: <AllMechanics />
        },
        {
            path: 'mechanic/services',
            element: <AllServices />
        },
        {
            path: 'mechanic/company/setup',
            element: <MechanicCompanySetup />
        },
        {
            path: 'mechanic/company/profile',
            element: <MechanicCompanyProfile />
        },
        {
            path: 'mechanic/company/profile/edit',
            element: <EditMechanicCompanyProfile />
        },
        {
            path: 'mechanic/maintenance/add',
            element: <Maintenance />
        },
        {
            path: 'mechanic/driver',
            element: <AllMechanicDriver />
        },
        {
            path: 'mechanic/maintenance/edit/:id',
            element: <Maintenance />
        },

        {
            path: 'mechanic/schedule',
            element: <AllSchedule />
        },

        {
            path: 'mechanic/schedule/add',
            element: <Schedule />
        },
        {
            path: 'mechanic/schedule/edit/:id',
            element: <Schedule />
        },
        {
            path: 'audit-log',
            element: <AuditLog />
        }
        // {
        //     path: 'fleet/edit-driver/:id',
        //     element: <EditDriver />
        // }

        // {
        //     path: 'dashboard',
        //     children: [
        //         {
        //             path: 'default',
        //             element: <DashboardDefault />
        //         }
        //     ]
        // },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-typography',
        //             element: <UtilsTypography />
        //         }
        //     ]
        // },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-color',
        //             element: <UtilsColor />
        //         }
        //     ]
        // },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-shadow',
        //             element: <UtilsShadow />
        //         }
        //     ]
        // },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'tabler-icons',
        //             element: <UtilsTablerIcons />
        //         }
        //     ]
        // },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'material-icons',
        //             element: <UtilsMaterialIcons />
        //         }
        //     ]
        // },

        // {
        //     path: 'driver/sign-up',
        //     element: <Register />
        // }
    ]
};

export default MainRoutes;
