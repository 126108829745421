import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { IconButton, LinearProgress, MenuItem, Skeleton, TextField, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import { Form, FormikProvider, useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const initialValues = {
    index: '',
    truck: '',
    mileage: '',
    state: '',
    date: ''
};

const validationSchema = Yup.object().shape({
    truck: Yup.string().required('Vin No is required'),
    mileage: Yup.string().required('Milage is required'),
    state: Yup.string().required('State is required'),
    date: Yup.date().typeError('Invalid date').required('Driven Date is required')
});

function AddNewIfta() {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const navigate = useNavigate();
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [excelFile, setExcelFile] = useState(null);
    const [clickedOptionId, setClickedOptionId] = useState({ id: null, open: false });

    const [data, setData] = useState([]);

    const { data: states, isLoading: statesLoading } = useQuery({
        queryFn: async () => {
            const { data } = await axios.get('/v1/api/users/state/list/233/');
            return data;
        },
        queryKey: ['states'],
        staleTime: 60 * 60 * 24
    });

    const { data: trucks, isLoading: trucksLoading } = useQuery({
        queryFn: async () => {
            const { data } = await axios.get('/v1/api/accounting/ifta/trcuk-vin-list/', {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                }
            });
            return data;
        },
        queryKey: ['trucks'],
        staleTime: 60 * 60 * 24
    });

    const getTruckId = (vin_no) => {
        const obj = trucks.find((truck) => truck.vin_no === vin_no);
        return obj ? obj.truck_id : '';
    };

    const getTruckVin = (truck_id) => {
        const obj = trucks.find((truck) => truck.truck_id === truck_id);
        return obj ? obj.vin_no : '';
    };

    const getStateId = (iso2) => {
        const obj = states.find((item) => item.iso2 === iso2);
        return obj ? obj.id : '';
    };

    const handleFileUpload = async () => {
        if (!excelFile) {
            toast.error('Excel file is required');
            return;
        }
        setIsFileUploading(true);
        const formData = new FormData();
        formData.append('truck_id', '1234');
        formData.append('excel_file', excelFile);

        try {
            const response = await axios.post('/v1/api/accounting/ifta/upload-excel/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                }
            });
            const result = [];

            const responseData = response.data.data;
            for (const [vinNo, stateData] of Object.entries(responseData)) {
                for (const [state, dateData] of Object.entries(stateData)) {
                    for (const [date, miles] of Object.entries(dateData)) {
                        result.push({
                            truck: getTruckId(vinNo),
                            mileage: miles,
                            state: getStateId(state),
                            date: date
                        });
                    }
                }
            }

            setData((prev) => [...prev, ...result]);
        } catch (error) {
            console.log('Error uploading file:', error);
            toast.error(error?.response?.data?.error);
        } finally {
            setIsFileUploading(false);
        }
    };

    // const { data: cities, isLoading: citiesLoading } = useQuery({
    //     queryKey: ['cities', selectedState],
    //     queryFn: async () => {
    //         const { data } = await axios.get(`/v1/api/users/city/list/${selectedState}/`);
    //         return data;
    //     },
    //     enabled: !!selectedState,
    //     staleTime: 60 * 60 * 24
    // });

    const handleDelete = (index) => {
        if (index >= 0 && index < data.length) {
            setData((prevData) => prevData.filter((_, i) => i !== index));
        }
    };

    const getStateName = (id) => {
        const obj = states.find((item) => item.id === id);
        return obj ? obj.name : 'not found';
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, action) => {
            const { index, ...newValues } = values;
            newValues['date'] = format(new Date(values.date), 'yyyy-MM-dd');

            if (index !== '') {
                let newItemToRender = [...data];
                newItemToRender.splice(index, 1, newValues);
                setData(newItemToRender);
            } else {
                setData([...data, { ...newValues, index: '' }]);
            }
            action.resetForm();
        }
    });
    const { values, setValues, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } = formik;

    const postData = async (data) => {
        const response = await axios.post('/v1/api/accounting/ifta/save-ifta-fuel-mileage/', data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response.data;
    };

    const { mutateAsync, isPending } = useMutation({
        mutationFn: postData,
        onError: (error) => {
            console.error('Error:', error);
        },
        onSuccess: () => {
            setTimeout(() => {
                navigate('/accounting/ifta/all');
            }, 2000);
        }
    });

    const submitAllData = () => {
        if (data.length === 0) {
            toast.error('No data is available');
            return;
        }

        toast.promise(mutateAsync(data), {
            pending: 'Saving data...',
            success: 'Data saved successfully! 👌',
            error: 'Error saving data. 🤯'
        });
    };

    return (
        <>
            <Box sx={{ padding: '1rem!important' }}>
                <Button
                    variant="contained"
                    onClick={() => navigate('/accounting/ifta/all')}
                    startIcon={<ArrowBackIcon />}
                    color="orange"
                    sx={{ color: 'white', marginTop: { xs: '1.5rem', sm: '1.5rem', md: 0 } }}
                >
                    Go Back
                </Button>
                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Box
                                sx={{
                                    border: excelFile ? '2px solid green' : '2px solid orange',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '0px 10px'
                                }}
                            >
                                <p style={{ fontSize: '16px', fontWeight: '500' }}>{excelFile ? excelFile.name : 'Upload Excel File'}</p>

                                <Button component="label" color="orange" startIcon={<CloudUploadIcon />}>
                                    Upload
                                    <input hidden type="file" accept=".xlsx, .xls" onChange={(e) => setExcelFile(e.target.files[0])} />
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'right' }}>
                            <LoadingButton
                                loading={isFileUploading}
                                variant="contained"
                                sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                color="orange"
                                endIcon={<SendIcon />}
                                onClick={handleFileUpload}
                            >
                                Generate Report
                            </LoadingButton>
                        </Grid>
                    </Grid>
                    {isFileUploading && <LinearProgress style={{ marginTop: '10px', width: '100%', backgroundColor: 'orange' }} />}

                    <Divider style={{ margin: '20px 0' }} />
                </Box>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                        <Grid container spacing={3}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {trucksLoading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        select
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Vin number"
                                        name="truck"
                                        value={values.truck}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(touched.truck && errors.truck)}
                                        helperText={touched.truck && errors.truck}
                                    >
                                        <MenuItem value="">Select a truck</MenuItem>
                                        {trucks?.map((truck) => (
                                            <MenuItem key={truck.truck_id} value={truck.truck_id}>
                                                {truck.vin_no}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="number"
                                    label="Mileage"
                                    name="mileage"
                                    value={values.mileage}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.mileage && errors.mileage)}
                                    helperText={touched.mileage && errors.mileage}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {statesLoading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        select
                                        fullWidth
                                        className="text-input"
                                        name="state"
                                        color="orange"
                                        type="text"
                                        label="State"
                                        value={values.state}
                                        onBlur={handleBlur}
                                        onChange={(e) => setFieldValue('state', e.target.value)}
                                        error={Boolean(touched.state && errors.state)}
                                        helperText={touched.state && errors.state}
                                    >
                                        <MenuItem value="">Select a state</MenuItem>
                                        {states?.map((state) => (
                                            <MenuItem key={state.id} value={state.id}>
                                                {state.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            </Grid>
                            {/* <Grid item lg={4} md={6} sm={6} xs={12}>
                            {citiesLoading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <TextField select fullWidth className="text-input" color="orange" type="text" label="City">
                                    <MenuItem value="">Select a city</MenuItem>
                                    {cities?.map((city) => (
                                        <MenuItem key={city.id} value={city.id}>
                                            {city.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        </Grid> */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Driven Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        onChange={(newValue) => {
                                            setFieldValue('date', newValue);
                                        }}
                                        value={values.date}
                                        renderInput={(params) => (
                                            <TextField
                                                className="text-input"
                                                color="orange"
                                                name="date"
                                                fullWidth
                                                {...params}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.date && errors.date)}
                                                helperText={touched.date && errors.date}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <LoadingButton type="submit" color="orange" sx={{ mt: 3, color: 'white', width: '100%' }} variant="contained">
                            Submit
                        </LoadingButton>
                    </Form>
                </FormikProvider>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700, mt: 5 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Ser</StyledTableCell>
                                <StyledTableCell>Vin Number</StyledTableCell>
                                <StyledTableCell>Mileage</StyledTableCell>
                                <StyledTableCell>State</StyledTableCell>
                                <StyledTableCell>Driven Date</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                        {index}
                                    </StyledTableCell>
                                    <StyledTableCell>{getTruckVin(row.truck)}</StyledTableCell>
                                    <StyledTableCell>{row.mileage}</StyledTableCell>
                                    <StyledTableCell>{getStateName(row.state)}</StyledTableCell>
                                    <StyledTableCell>{row.date}</StyledTableCell>
                                    <StyledTableCell sx={{ position: 'relative' }}>
                                        <AnimatePresence>
                                            {clickedOptionId.open && clickedOptionId.id === index && (
                                                <motion.div
                                                    initial={{
                                                        scale: 0
                                                    }}
                                                    animate={{
                                                        scale: 1
                                                    }}
                                                    exit={{
                                                        scale: 0
                                                    }}
                                                    style={{
                                                        position: 'absolute',
                                                        left: '-45%',
                                                        bottom: 18,
                                                        display: 'flex',
                                                        gap: 10,
                                                        alignItems: 'center',
                                                        background: '#0b172e',
                                                        borderRadius: 10,
                                                        padding: 0
                                                    }}
                                                >
                                                    <Tooltip title="Edit Row" placement="top">
                                                        <IconButton
                                                            onClick={() => {
                                                                setValues({ ...row, index });
                                                            }}
                                                        >
                                                            <DriveFileRenameOutlineIcon
                                                                sx={{
                                                                    bgcolor: '#8080806b',
                                                                    color: 'white',
                                                                    borderRadius: '50%',
                                                                    padding: 0.5,
                                                                    fontSize: 35
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title="Delete Row" placement="top">
                                                        <IconButton
                                                            onClick={() => {
                                                                handleDelete(index);
                                                            }}
                                                        >
                                                            <DeleteIcon
                                                                sx={{
                                                                    bgcolor: '#8080806b',
                                                                    color: 'white',
                                                                    borderRadius: '50%',
                                                                    padding: 0.5,
                                                                    fontSize: 35
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Close" placement="top">
                                                        <IconButton onClick={() => setClickedOptionId((prev) => ({ id: index, open: !prev.open }))}>
                                                            <CloseIcon
                                                                sx={{
                                                                    bgcolor: '#8080806b',
                                                                    color: 'white',
                                                                    borderRadius: '50%',
                                                                    padding: 0.5,
                                                                    fontSize: 35,
                                                                    zIndex: 2
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                        <Tooltip title="Open Option" placement="right-start">
                                            <IconButton
                                                onClick={() => setClickedOptionId((prev) => ({ id: index, open: !prev.open }))}
                                                disabled={clickedOptionId.open && clickedOptionId.id === index}
                                            >
                                                <MoreHorizIcon sx={{ fontSize: 35, padding: 0.5 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <LoadingButton onClick={() => submitAllData()} loading={isPending} fullWidth variant="contained" color="orange" sx={{ color: 'white', mt: 5 }}>
                    Save data
                </LoadingButton>
            </Box>
        </>
    );
}

export default AddNewIfta;
