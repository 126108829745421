// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, useMediaQuery } from '@mui/material';

// project imports

// import AuthCardWrapper from '../AuthCardWrapper';
// import SubCard from 'ui-component/cards/SubCard';
// import MechanicRegisterForm from 'ui-component/auth-component/forms/MechanicRegisterForm';
import ViewLoadPublic from 'ui-component/dispatchCenter/forms/ViewLoadPublic';

// assets

// ===============================|| AUTH3 - REGISTER ||=============================== //

const ViewMyLoadPublic = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <div style={{ marginTop: '1rem' }}>
                <ViewLoadPublic />
            </div>
        </>
    );
};

export default ViewMyLoadPublic;
