import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

import MechanicBankInfo from 'views/pages/authentication/authentication/MechanicBankInfo';
import MechanicLayout from 'layout/MechanicLayout';
import MechanicSetup from 'views/pages/authentication/authentication/MechanicSetup';
import AllMechanics from 'views/pages/mechanic/AllMechanics';
import ViewMyLoadPublic from 'views/dispatchCenter/ViewMyLoadPublic';
const MechanicRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/MechanicRegister')));
const AuthRegisterInfo = Loadable(lazy(() => import('views/pages/authentication/authentication/DrvInfo')));
const AuthRegisterQualf = Loadable(lazy(() => import('views/pages/authentication/authentication/DrvQualf')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const MechanicRoutes = {
    path: '/',
    element: <MechanicLayout />,
    children: [
        {
            path: '/mechanic/register',
            element: <MechanicRegister />
        },
        {
            path: '/mechanic/bank-info',
            element: <MechanicBankInfo />
        },
        {
            path: '/mechanic/setup',
            element: <MechanicSetup />
        },
        {
            path: '/register/info',
            element: <AuthRegisterInfo />
        },
        {
            path: '/register/qualification',
            element: <AuthRegisterQualf />
        }
    ]
};

export default MechanicRoutes;
