import {
    Divider,
    List,
    ListItemText,
    Stack,
    useTheme,
    Box,
    ListItem,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Popover,
    Switch
} from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PropTypes from 'prop-types';

function DriverSafteyFilter({
    filterHandler,
    handleStatus,
    statusChecked,
    checked,
    handleChecked,

    startDoc,
    startdocHandler,
    endDoc,
    enddocHandler,
    startDoe,
    startdoeHandler,
    endDoe,
    enddoeHandler,
    reset
}) {
    const theme = useTheme();
    return (
        <PopupState variant="popover" popupId="filter-popup">
            {(popupState) => (
                <>
                    <Button
                        variant="contained"
                        sx={{ color: 'white', backgroundColor: '#EB812E' }}
                        color="orange"
                        startIcon={<FilterAltIcon />}
                        {...bindTrigger(popupState)}
                    >
                        FILTER
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                            <Box sx={{ px: 2 }}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        maxWidth: 350,
                                        minWidth: 300,
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            minWidth: '100%'
                                        },
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5
                                        }
                                    }}
                                >
                                    <ListItem>
                                        {/* <ListItemIcon>
                                                <BluetoothIcon />
                                            </ListItemIcon> */}
                                        <ListItemText id="switch-list-label-bluetooth" primary="DQ Status" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('dqstatus')}
                                            checked={checked.indexOf('dqstatus') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-bluetooth'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('dqstatus') !== -1 ? (
                                        <ListItem>
                                            <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="flex-start">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('submited')}
                                                                checked={statusChecked.indexOf('submited') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="Submited"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('not submited')}
                                                                checked={statusChecked.indexOf('not submited') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="Not Submited"
                                                    />
                                                </FormGroup>
                                            </Stack>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        <ListItemText primary="Date " />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('doc')}
                                            checked={checked.indexOf('doc') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('doc') !== -1 ? (
                                        <>
                                            <ListItem>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            className="text-input"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            label="Start Date"
                                                            value={startDoc}
                                                            onChange={startdocHandler}
                                                            renderInput={(params) => (
                                                                <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            className="text-input"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            label="End Date"
                                                            value={endDoc}
                                                            onChange={enddocHandler}
                                                            renderInput={(params) => (
                                                                <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Stack>
                                            </ListItem>
                                            <Divider variant="middle" component="li" />
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    <ListItem>
                                        {/* <ListItemIcon><WifiIcon /></ListItemIcon> */}

                                        <ListItemText primary="Expire Date" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('doe')}
                                            checked={checked.indexOf('doe') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('doe') !== -1 ? (
                                        <ListItem>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        className="text-input"
                                                        openTo="day"
                                                        views={['year', 'month', 'day']}
                                                        label="Start Date"
                                                        value={startDoe}
                                                        onChange={startdoeHandler}
                                                        renderInput={(params) => (
                                                            <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        className="text-input"
                                                        openTo="day"
                                                        views={['year', 'month', 'day']}
                                                        label="End Date"
                                                        value={endDoe}
                                                        onChange={enddoeHandler}
                                                        renderInput={(params) => (
                                                            <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Stack>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}

                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        {/* <ListItemIcon>
                                                <BluetoothIcon />
                                            </ListItemIcon> */}
                                        <ListItemText id="switch-list-label-bluetooth" primary="File Status" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('status')}
                                            checked={checked.indexOf('status') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-bluetooth'
                                            }}
                                        />
                                    </ListItem>

                                    <ListItem>
                                        {checked.indexOf('status') !== -1 ? (
                                            <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="flex-start">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('active')}
                                                                checked={statusChecked.indexOf('active') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="Active"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('inactive')}
                                                                checked={statusChecked.indexOf('inactive') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="Inactive"
                                                    />
                                                </FormGroup>
                                            </Stack>
                                        ) : (
                                            ''
                                        )}

                                        <Divider sx={{ mt: 2 }} />
                                    </ListItem>
                                    <ListItem>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={{ xs: 12, sm: 20 }}>
                                            <Button variant="outlined" onClick={reset}>
                                                Reset
                                            </Button>
                                            <Button
                                                sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                                variant="contained"
                                                color="orange"
                                                onClick={() => {
                                                    filterHandler();
                                                    popupState.close();
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </Stack>

                                        <Divider sx={{ mt: 2 }} />
                                    </ListItem>
                                </List>
                            </Box>
                        </MainCard>
                    </Popover>
                </>
            )}
        </PopupState>
    );
}

DriverSafteyFilter.propTypes = {
    startdocHandler: PropTypes.func,
    handleChecked: PropTypes.func,
    enddocHandler: PropTypes.func,
    checked: PropTypes.any,
    startDoc: PropTypes.any,
    endDoc: PropTypes.any,
    startDoe: PropTypes.any,
    endDoe: PropTypes.any,
    enddoeHandler: PropTypes.func,
    filterHandler: PropTypes.func,
    statusChecked: PropTypes.func,
    startdoeHandler: PropTypes.func,
    handleStatus: PropTypes.func,
    reset: PropTypes.func
};
export default DriverSafteyFilter;
