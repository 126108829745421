import { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormLabel,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    MenuItem,
    Skeleton,
    TextField,
    Typography,
    RadioGroup,
    Radio,
    Divider,
    CardContent,
    Card,
    Menu,
    IconButton
} from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// third party
import * as Yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { Box, Stack, useMediaQuery } from '@mui/system';
import SubCard from 'ui-component/cards/SubCard';
import MaterialTable from '@material-table/core';
import { createRef } from 'react';

import TABLE_ICONS from 'tableIcons';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
// import moment from 'moment';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import PreViewSettlememntModal from 'ui-component/modals/PreViewSettlememntModal';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const AddSettlementForm = () => {
    const tableRef = createRef();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [driverList, setDriverList] = useState();
    const [loadList, setLoadList] = useState();
    const [errorData, setErrorData] = useState();
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');
    const [revenue, setRevenue] = useState(0);
    const [dispatcherExpense, setDispatcherExpense] = useState(0);
    const [fuelExpense, setFuelExpense] = useState(0);
    const [pmeExpense, setPmeExpense] = useState(0);
    const [insExpense, setInsExpense] = useState(0);
    const [iftaExpense, setIftaExpense] = useState(0);
    const [othExpense, setOthExpense] = useState(0);
    const [searchDriver, setSearchDriver] = useState('');
    const [options, setOptions] = useState([]);

    const [mainExpenseList, setMainExpenseList] = useState([]);
    const [expenseOptionOpen, setExpenseOptionOpen] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [owner, setOwner] = useState([]);
    const [expenseList, setExpenseList] = useState([]);
    const [addNew, setAddNew] = useState(false);

    const [expenseOpen, setExpenseOpen] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [truckId, setTruckId] = useState('');

    // keep track of settlementList and dateLoading
    const [settlementList, setSettlementList] = useState([]);
    const [dateLoading, setDateLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const [stlmData, setStlmData] = useState();
    const [selected, setSelected] = useState([]);

    const [fuelList, setFuelList] = useState([]);

    const handleCloseModal = () => {
        setOpen(false);
        tableRef.current.onQueryChange();
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const OrganizationSchema = Yup.object().shape({
        // billingEmail: Yup.string().email().required('Email is a required field'),
        fuelExpense: Yup.number().typeError('Only number allowed'),
        pme: Yup.number().typeError('Only number allowed'),
        insurenceExpense: Yup.number().typeError('Only number allowed'),
        ifta: Yup.number().typeError('Only number allowed'),
        otherExpense: Yup.number().typeError('Only number allowed'),
        paymentStatus: Yup.string().required('Payment is a required field')
    });

    const FetchDataSchema = Yup.object().shape({
        driver: Yup.mixed().required('driver is a required field'),
        startDate: Yup.date().required('Start Date is a required field').typeError('Invalid Start Date'),
        endDate: Yup.date()
            .required('End Date is a required field')
            .typeError('Invalid End Date')
            .test('is-greater', 'End date must be later than start date', function (value) {
                const { startDate } = this.parent;
                return value && startDate && new Date(value) >= new Date(startDate);
            })
        // stlmType: Yup.string().required('Settlement Type is a required field')
    });

    // useEffect(() => {
    //     if (!driverList) {
    //         fetchDriverList();
    //     }

    //     expenseHanlder();
    // }, [driverList, options, revenue, dispatcherExpense, fuelExpense, pmeExpense, insExpense, iftaExpense, othExpense]);

    useEffect(() => {
        if (!driverList) {
            fetchDriverList();
        }

        // expenseHanlder();
    }, [driverList]);

    const fetchDriverList = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&is_main=true&page=1&page_size=100&search=${searchDriver}`,
                config
            );
            setDriverList(data.results);
            setOptions(data.results);
            setLoading(false);
        } catch (error) {
            setLoading1(false);
        }
    };

    const expenseHanlder = () => {
        const result = parseFloat((revenue * dispatcherExpense) / 100);
        const grandExp =
            parseFloat(result) + parseFloat(fuelExpense) + parseFloat(pmeExpense) + parseFloat(insExpense) + parseFloat(iftaExpense) + parseFloat(othExpense);

        setFieldValue('netIncome', parseFloat(revenue - grandExp).toFixed(2));
    };

    const formik1 = useFormik({
        enableReinitialize: true,

        initialValues: {
            driver: 0,
            startDate: '',
            endDate: '',
            settlement_type: ''
        },
        validationSchema: FetchDataSchema,
        onSubmit: async (values, action) => {
            const start_date = format(new Date(formik1.values.startDate), 'yyyy-MM-dd');
            const end_date = format(new Date(formik1.values.endDate), 'yyyy-MM-dd');
            let flag = true;

            settlementList.forEach((settlement) => {
                // if the selected start date and end date is between the existing paid days then adding the errors
                if (start_date >= settlement.start_date && start_date < settlement.end_date) {
                    action.setErrors({
                        ...action.errors,
                        startDate: "You've already paid for this date"
                    });
                    flag = false;
                    return;
                }
                if (end_date >= settlement.start_date && end_date < settlement.end_date) {
                    action.setErrors({
                        ...action.errors,
                        endDate: "You've already paid for this date"
                    });
                    flag = false;
                    return;
                }
            });

            if (flag) {
                try {
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    } else {
                        console.error('tableRef.current is null');
                        // You could handle the case where tableRef is not ready
                    }
                    // tableRef.current.onQueryChange();
                    const { data } = await axios.post(
                        `/v1/api/accounting/stlm/fetch-data/`,
                        {
                            driver_id: values.driver ? values.driver.driver_id : '',
                            truck_id: values.driver ? values.driver.truck_info.truck_id : '',
                            stlm_type: values.stlmType,
                            start_date: values.startDate !== '' ? format(new Date(values.startDate), 'yyyy-MM-dd') : null,
                            end_date: values.endDate !== '' ? format(new Date(values.endDate), 'yyyy-MM-dd') : null,
                            created_by: loginInfoFromStorage.user_id,
                            company_id: loginInfoFromStorage.company_id
                        },
                        config
                    );
                    // formik1.setFieldValue('settlement_type', data.drivers[0].stlm_type);

                    console.log(data);
                    setDrivers(data.drivers);
                    setOwner(data.owner);
                    setExpenseList(data.expenses);
                    setMainExpenseList(data.expenses);
                    setFuelList(data.fuel_data);
                } catch (error) {
                    console.log(error);
                    setErrorData(error.response.data);
                }
            }
        }
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            driver: 0,
            companyName: '',
            totalRevenue: revenue,
            payDate: format(new Date(), 'MM-dd-yyyy'),
            startDate: '',
            endDate: '',
            billingEmail: '',
            fuelExpense: 0,
            dispatcherExpense: 0,
            pme: 0,
            insurenceExpense: 0,
            ifta: 0,
            otherExpense: 0,
            drvSplit: drivers,
            ownerSplit: owner,
            expenses: expenseList,
            netIncome: 0,
            paymentStatus: ''
        },
        validationSchema: OrganizationSchema,
        onSubmit: async () => {
            const totalPercentage = values.drvSplit.reduce((acc, item) => parseFloat(acc) + parseFloat(item.percent), 0);
            if (totalPercentage > 100) {
                toast.error('percentage should not greater then 100');
                return;
            }

            if (values.ownerSplit[0]?.net_income < 0) {
                toast.error('owner income should not less then 0');
                return;
            }
            const expenses = values.expenses.map((expense) => ({
                ...expense,
                amount: Number.isInteger(expense.amount) ? expense.amount : parseFloat(expense.amount).toFixed(2)
            }));
            const data = {
                driver_id: formik1.values.driver ? values.driver.driver_id : '',
                start_date: formik1.values.startDate !== '' ? format(new Date(formik1.values.startDate), 'yyyy-MM-dd') : null,
                end_date: formik1.values.endDate !== '' ? format(new Date(formik1.values.endDate), 'yyyy-MM-dd') : null,
                stlm_type: formik1.values.settlement_type ? formik1.values.settlement_type : 'flat',
                payment_date: values.payDate !== '' ? format(new Date(values.payDate), 'yyyy-MM-dd') : null,
                total_revenue: values.totalRevenue,
                income: values.netIncome,
                payment_status: values.paymentStatus,
                drivers: values.drvSplit,
                owner: values.ownerSplit,
                loadList: loadList ? loadList : [],
                fuelList: fuelList ? fuelList : [],
                expenses: expenses,
                truck_id: truckId,
                created_by: loginInfoFromStorage.user_id,
                company_id: loginInfoFromStorage.company_id,
                is_active: true,
                is_edit: false
            };

            try {
                const response = await axios.post(`/v1/api/accounting/stlm/calculate/`, data, config);

                setStlmData(response.data.data);
                setOpen(true);
            } catch (error) {
                toast.error("can't save the data");
                setErrorData(error.response.data);
            }
        }
    });

    const expenseFormik = useFormik({
        initialValues: {
            FileName: '',
            fileType: 'settlement',
            default: '',
            isPercent: false
        },
        validationSchema: Yup.object().shape({
            FileName: Yup.string().required('File Type Name is a required field'),
            default: Yup.number().max(99999).min(0).required()
        }),

        onSubmit: async (values, action) => {
            try {
                {
                    const { data } = await axios.post(
                        `/v1/api/users/company/expense-type/add/`,
                        {
                            exp_item_type_name: values.FileName,
                            exp_type: values.fileType,
                            default_value: values.default,
                            is_percent: values.isPercent ? values.isPercent : false,
                            created_by: loginInfoFromStorage.user_id,
                            company_id: loginInfoFromStorage.company_id,
                            is_common: false
                        },
                        config
                    );
                    action.resetForm();
                    toast.success('Expense Type Added successfully');

                    setExpenseOpen(false);
                    setAddNew(false);
                    setExpenseOptionOpen(false);
                    setExpenseList([
                        ...expenseList,
                        {
                            company_id: data.company_id,
                            exp_item_type_id: data.exp_item_type_id,
                            exp_name: data.exp_item_type_name,
                            exp_item_type: data.exp_item_type,
                            amount: data.default_value,
                            is_percent: data.is_percent
                        }
                    ]);
                }
                setExpenseOpen(false);
            } catch (error) {
                console.log(error);
                toast.error("Can't save please contact with support");
            }
        }
    });

    const { errors, touched, values, setFieldValue, getFieldProps, handleSubmit } = formik;

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Load</th>
                    <th className="headerTd rightHidBor">Pickup Date</th>
                    <th className="headerTd rightHidBor">Delivery Date</th>
                    <th className="headerTd rightHidBor">From</th>
                    <th className="headerTd rightHidBor">To</th>
                    <th className="headerTd rightHidBor">Revenue</th>
                    <th className="headerTd rightHidBor">Driver Name</th>
                    <th className="headerTd">Customer Name</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.dsp_load_id}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.pickup.date}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.delivery.date}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.pickup.location}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.delivery.location}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.revenue}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_name}
                </td>
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.cust_name}
                </td>
            </tr>
        );
    };

    const renderFuelHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Date</th>
                    <th className="headerTd rightHidBor">Invoice</th>
                    <th className="headerTd rightHidBor">Total Gallons</th>
                    <th className="headerTd rightHidBor">Total Amount</th>
                    <th className="headerTd rightHidBor">Location</th>
                </tr>
            </thead>
        );
    };
    const renderFuelRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.transaction_date}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.invoice}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.total_gallons}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.total_amount}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.location_name}, {rowData.data.city_name} {rowData.data.state_name}
                </td>
            </tr>
        );
    };

    const handleDriverChange = (event) => {
        const selectedValue = event;
        const selectedDriver = driverList.find((driver) => driver.driver_id === selectedValue);
        const selectedDriverEmail = selectedDriver ? selectedDriver.email : '';
        const selectedDriverTruckId = selectedDriver ? selectedDriver.truck_id : '';

        setTruckId(selectedDriverTruckId);
        setFieldValue('billingEmail', selectedDriverEmail);
    };

    const onChangeHandle = async (value) => {
        setLoading1(true);
        const { data } = await axios.get(
            `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100&search=${value}`,
            config
        );
        setLoading1(false);
        setOptions(data.results);
    };

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;

        const updatedValue = inputValue.replace(/[^0-9.]/g, '');

        const decimalCount = (updatedValue.match(/\./g) || []).length;
        if (decimalCount > 1) {
            const indexOfLastDecimal = updatedValue.lastIndexOf('.');
            const integerValue = updatedValue.substring(0, indexOfLastDecimal);
            const fractionalValue = updatedValue.substring(indexOfLastDecimal + 1);
            const sanitizedFractionalValue = fractionalValue.replace(/\./g, '');
            e.target.value = integerValue + '.' + sanitizedFractionalValue;
        } else {
            e.target.value = updatedValue;
        }
    };

    const fetchSettlementData = async (truckId) => {
        // fetching settlement list which are paid for this driver
        setDateLoading(true);
        try {
            const response = await axios.get(`/v1/api/accounting/settlement-list/${truckId}/`, config);
            if (response.status === 200) {
                setSettlementList(response.data);
            }
        } catch (error) {
            console.log(error);
        }
        setDateLoading(false);
    };

    // useEffect(() => {
    //     if (formik1.values.driver?.truck_id) {
    //         fetchSettlementData(formik1.values.driver.truck_id);
    //     }
    // }, [formik1.values.driver]);

    const handleRemove = (index) => {
        setExpenseList(expenseList.filter((_, i) => index !== i));
    };

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const fetchData = async (query) => {
        setLoading(true);

        try {
            const url = `/v1/api/dispatch/load/list?page=${query.page + 1}&page_size=100&company_id=${
                loginInfoFromStorage.company_id
            }&bol_status=&load_status=Delivered&invoice_status=&created_start=&created_end=&price_start=&price_end=&pickup_start=${format(
                startDateFilter,
                'yyyy-MM-dd'
            )}&pickup_end=&delivery_start=&delivery_end=${format(endDateFilter, 'yyyy-MM-dd')}&driver=${
                formik1.values.driver ? formik1.values.driver.driver_id : 0
            }&stlm_status=Pending`;

            const response = await fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                })
            });

            const result = await response.json();
            setSelected(result.results || []);

            console.log(result);
            // Calculate total revenue
            const totalRevenue = result.results.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.revenue), 0);
            setFieldValue('totalRevenue', totalRevenue.toFixed(2));
            setRevenue(totalRevenue);
            setLoadList(result.results.map((i) => i.dsp_load_id));

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };
    // console.log(selected);

    useEffect(() => {
        console.log('object');
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 10 })
                .then((result) => {
                    setSelected(result.data || []);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
    }, [isMobile, isTablet]);
    // console.log(expenseList);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                        sx={{
                            mt: '20px',
                            width: '375px',
                            height: '40px',
                            fontWeight: '600',
                            fontSize: '30px',
                            fontFamily: 'Lexend'
                        }}
                    >
                        Add New Settlement
                    </Typography>
                </Grid>
            </Grid>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                    <FormikProvider value={formik1}>
                        <Form autoComplete="off" onSubmit={formik1.handleSubmit} encType="multipart/form-data">
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Stack
                                        sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'flex-start', gap: { xs: '1rem' } }}
                                        justifyContent="space-between"
                                        spacing={2}
                                    >
                                        <Typography
                                            sx={{
                                                width: '375px',
                                                height: '40px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend',
                                                paddingTop: '1.5rem'
                                            }}
                                        >
                                            Search for Loads
                                        </Typography>
                                        <AnimateButton>
                                            <LoadingFormButton load={formik1.isSubmitting && !errorData} type="submit" btnText="Search" />
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                                {/* Driver */}
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                className="text-input"
                                                // multiple
                                                id="driver-set"
                                                options={options}
                                                loading={loading1}
                                                isOptionEqualToValue={(option, value) => option.driver_id === value.driver_id}
                                                getOptionLabel={(option) => (option.name ? option.name : '')}
                                                inputValue={inputValue}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue(newInputValue);
                                                }}
                                                value={formik1.values.driver}
                                                onChange={(_event, newValue) => {
                                                    // setDriverValue(newValue.name);
                                                    formik1.setFieldValue('driver', newValue);
                                                    handleDriverChange(newValue.driver_id);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="Driver Name"
                                                        onChange={(ev) => {
                                                            if (ev.target.value !== '' || ev.target.value !== null || ev.target.value.length > 1) {
                                                                onChangeHandle(ev.target.value);
                                                            }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            )
                                                        }}
                                                        error={Boolean(formik1.touched.driver && formik1.errors.driver)}
                                                    />
                                                )}
                                            />
                                            <FormHelperText
                                                error={Boolean(formik1.touched.driver && formik1.errors.driver)}
                                                sx={{ marginRight: { sm: '-9rem' } }}
                                            >
                                                {formik1.touched.driver && formik1.errors.driver
                                                    ? formik1.errors.driver
                                                    : 'Type atleast 2 characters to populate the list'}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Grid>

                                {/* Start Date */}
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    {loading || dateLoading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                label="Start Date"
                                                disabled={formik1.values.driver === 0 || formik1.values.driver === null}
                                                disableFuture={true}
                                                value={formik1.values.startDate}
                                                onChange={(date) => {
                                                    formik1.setFieldValue('startDate', date);
                                                    setStartDateFilter(date);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="text-input"
                                                        color="orange"
                                                        fullWidth
                                                        {...params}
                                                        error={
                                                            Boolean(formik1.touched.startDate && formik1.errors.startDate) ||
                                                            (errorData && errorData.start_date)
                                                        }
                                                        helperText={
                                                            (formik1.touched.startDate && formik1.errors.startDate) || (errorData && errorData.start_date)
                                                        }
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>
                                {/* End Date */}
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    {loading || dateLoading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                label="End Date"
                                                disabled={formik1.values.driver === 0 || formik1.values.driver === null}
                                                disableFuture={true}
                                                value={formik1.values.endDate}
                                                onChange={(date) => {
                                                    formik1.setFieldValue('endDate', date);
                                                    setEndDateFilter(date);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="text-input"
                                                        color="orange"
                                                        fullWidth
                                                        InputProps={{ readOnly: true }}
                                                        {...params}
                                                        error={Boolean(formik1.touched.endDate && formik1.errors.endDate) || (errorData && errorData.end_date)}
                                                        helperText={(formik1.touched.endDate && formik1.errors.endDate) || (errorData && errorData.end_date)}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>

                                {/* <Grid item lg={3} md={3} sm={3} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            select
                                            label="Settlement Type"
                                            value={formik1.values.stlmType}
                                            onChange={(e) => formik1.setFieldValue('stlmType', e.target.value)}
                                        >
                                            <MenuItem value="">Select Type</MenuItem>
                                            <MenuItem value="flat">Flat</MenuItem>
                                            <MenuItem value="split">Split</MenuItem>
                                            <MenuItem value="mileage">Mileage</MenuItem>
                                        </TextField>
                                    )}
                                </Grid> */}
                            </Grid>
                        </Form>
                    </FormikProvider>
                </SubCard>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Stack direction="row" sx={{ mt: '20px' }} justifyContent="space-between" alignItems="center" spacing={2}>
                                        <Typography
                                            sx={{
                                                width: '375px',
                                                height: '40px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend',
                                                marginBottom: { xs: '1rem', sm: 0 }
                                            }}
                                        >
                                            General Information
                                        </Typography>
                                    </Stack>
                                </Grid>

                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                label="Pay Date"
                                                value={values.payDate}
                                                onChange={(date) => {
                                                    setFieldValue('payDate', date);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="text-input"
                                                        color="orange"
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(touched.payDate && errors.payDate) || (errorData && errorData.payment_date)}
                                                        helperText={(touched.payDate && errors.payDate) || (errorData && errorData.payment_date)}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>

                                {/* revenue */}
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            fullWidth
                                            type="text"
                                            label="Total Revenue"
                                            {...getFieldProps('totalRevenue')}
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                            }}
                                            error={Boolean((touched.totalRevenue && errors.totalRevenue) || (errorData && errorData.total_revenue))}
                                            helperText={(touched.totalRevenue && errors.totalRevenue) || (errorData && errorData.total_revenue)}
                                        />
                                    )}
                                </Grid>

                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            select
                                            label="Payment Status"
                                            value={values.paymentStatus}
                                            onChange={(e) => setFieldValue('paymentStatus', e.target.value)}
                                            error={Boolean(touched.paymentStatus && errors.paymentStatus) || (errorData && errorData.payment_status)}
                                            helperText={(touched.paymentStatus && errors.paymentStatus) || (errorData && errorData.payment_status)}
                                        >
                                            <MenuItem value="pending">Pending</MenuItem>
                                            <MenuItem value="paid">Paid</MenuItem>
                                            <MenuItem value="rejected">Rejected</MenuItem>
                                        </TextField>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>

                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            height: '40px',
                                            fontWeight: '500',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend',
                                            mb: 5
                                        }}
                                    >
                                        Additional Settlement Information
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* <Divider sx={{ mt: 2, mb: 3 }} /> */}
                            {isMobile || isTablet ? (
                                loading ? (
                                    <>
                                        <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                            <CircularProgress />
                                        </Box>
                                    </>
                                ) : (
                                    expenseList.length > 0 && (
                                        <>
                                            {values.expenses.map((expense, index) => (
                                                <Box key={expense.exp_item_type_id} sx={{ marginTop: '2rem' }}>
                                                    <Card
                                                        sx={{
                                                            marginBottom: '1.5rem',
                                                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                            borderRadius: '15px',
                                                            backgroundColor: index % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <Typography
                                                                sx={{
                                                                    marginBottom: '.5rem'
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    marginBottom: '.5rem'
                                                                }}
                                                            >
                                                                Expense Type: {expense.exp_name}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    marginBottom: '.5rem',
                                                                    display: 'flex',
                                                                    gap: '1rem',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                Amount:
                                                                <div
                                                                    style={{
                                                                        borderRight: '1px solid #80808052',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        width: '25%',
                                                                        position: 'relative'
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value;
                                                                            if (!newValue.startsWith('0') || newValue === '0') {
                                                                                setFieldValue(`expenses[${index}].amount`, newValue);
                                                                            } else {
                                                                                setFieldValue(`expenses[${index}].amount`, newValue.replace(/^0+/, ''));
                                                                            }
                                                                        }}
                                                                        value={values.expenses[index].amount}
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            width: '100%',
                                                                            borderWidth: '1px',
                                                                            borderLeft: 0,
                                                                            borderRight: 0,
                                                                            borderColor: '#80808052',
                                                                            outline: 0,
                                                                            padding: '5px 0'
                                                                        }}
                                                                    />
                                                                    {expense.is_percent && <span style={{ position: 'absolute', right: 0 }}>%</span>}
                                                                </div>
                                                            </Typography>

                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'baseline',
                                                                    justifyContent: 'space-between',
                                                                    marginBottom: '1rem'
                                                                }}
                                                            >
                                                                <Typography>
                                                                    {values.expenses.length === index + 1 ? (
                                                                        <AddIcon
                                                                            sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%' }}
                                                                            onClick={() => setAddNew((prev) => !prev)}
                                                                        />
                                                                    ) : (
                                                                        <ViewCompactIcon />
                                                                    )}
                                                                </Typography>

                                                                <Typography
                                                                    sx={{
                                                                        marginBottom: '.5rem'
                                                                    }}
                                                                >
                                                                    {!(
                                                                        expense.exp_name === 'Dispatcher Expense' ||
                                                                        expense.exp_name === 'Insurance Expense' ||
                                                                        expense.exp_name === 'Fuel Expense'
                                                                    ) && (
                                                                        <RemoveIcon
                                                                            sx={{ backgroundColor: 'red', color: 'white', borderRadius: '50%' }}
                                                                            onClick={() => handleRemove(index)}
                                                                        />
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            ))}

                                            {addNew && (
                                                <Card
                                                    sx={{
                                                        marginBottom: '1.5rem',
                                                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                        borderRadius: '15px'
                                                        // backgroundColor: index % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                                    }}
                                                >
                                                    <CardContent>
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    marginBottom: '.5rem'
                                                                }}
                                                            >
                                                                {values.expenses.length + 1}
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    marginBottom: '.5rem',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: '1rem'
                                                                }}
                                                            >
                                                                Expense Type:{' '}
                                                                <div
                                                                    style={{
                                                                        borderRight: '1px solid #80808052',
                                                                        display: 'flex',
                                                                        justifyContent: 'end',
                                                                        alignItems: 'center',
                                                                        width: '47%',
                                                                        position: 'relative',
                                                                        backgroundColor: '#80808040'
                                                                        // paddingRight: 10
                                                                    }}
                                                                >
                                                                    {expenseOptionOpen ? (
                                                                        <KeyboardArrowUpIcon
                                                                            style={{ color: 'black', width: '100%' }}
                                                                            onClick={() => setExpenseOptionOpen(!expenseOptionOpen)}
                                                                        />
                                                                    ) : (
                                                                        <KeyboardArrowDownIcon
                                                                            style={{ color: 'black', width: '100%' }}
                                                                            onClick={() => setExpenseOptionOpen(!expenseOptionOpen)}
                                                                        />
                                                                    )}

                                                                    {expenseOptionOpen && (
                                                                        <div
                                                                            style={{
                                                                                position: 'absolute',
                                                                                bottom: 34,
                                                                                left: 0,
                                                                                backgroundColor: 'white',
                                                                                padding: '0px 6px',
                                                                                borderRadius: 5,
                                                                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                                                width: '100%',
                                                                                zIndex: 1
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                style={{ color: 'orange' }}
                                                                                onClick={() => {
                                                                                    setExpenseOpen(true);
                                                                                }}
                                                                            >
                                                                                Add new...
                                                                            </Button>
                                                                            {mainExpenseList.map((expense, index) => {
                                                                                if (!expenseList.includes(expense)) {
                                                                                    return (
                                                                                        <button
                                                                                            key={index}
                                                                                            style={{
                                                                                                display: 'block',
                                                                                                width: '100%',
                                                                                                border: 0,
                                                                                                outline: 0,
                                                                                                boxShadow:
                                                                                                    'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                setExpenseList([...expenseList, expense]);
                                                                                                setExpenseOptionOpen(false);
                                                                                            }}
                                                                                        >
                                                                                            {expense.exp_name}
                                                                                        </button>
                                                                                    );
                                                                                }
                                                                                return null;
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    marginBottom: '.5rem',
                                                                    display: 'flex',
                                                                    gap: '1rem',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                Amount:
                                                                <div
                                                                    style={{
                                                                        borderRight: '1px solid #80808052',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        width: '25%',
                                                                        position: 'relative'
                                                                    }}
                                                                >
                                                                    <input
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            width: '100%',
                                                                            borderWidth: '1px',
                                                                            borderLeft: 0,
                                                                            borderRight: 0,
                                                                            borderColor: '#80808052',
                                                                            outline: 0,
                                                                            padding: '5px 0'
                                                                        }}
                                                                        placeholder="0"
                                                                    />
                                                                </div>
                                                            </Typography>

                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'baseline',
                                                                    justifyContent: 'space-between',
                                                                    marginBottom: '1rem'
                                                                }}
                                                            >
                                                                <Typography>
                                                                    <div
                                                                        style={{
                                                                            borderRight: '1px solid #80808052',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            width: '20%'
                                                                        }}
                                                                    >
                                                                        <AddIcon
                                                                            sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%' }}
                                                                            onClick={() => console.log('Added')}
                                                                        />
                                                                    </div>
                                                                </Typography>

                                                                <Typography
                                                                    sx={{
                                                                        marginBottom: '.5rem'
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            width: '20%'
                                                                        }}
                                                                    >
                                                                        <RemoveIcon
                                                                            sx={{ backgroundColor: 'red', color: 'white', borderRadius: '50%' }}
                                                                            onClick={() => setAddNew(false)}
                                                                        />
                                                                    </div>
                                                                </Typography>
                                                            </Box>
                                                        </>
                                                    </CardContent>
                                                </Card>
                                            )}
                                        </>
                                    )
                                )
                            ) : (
                                expenseList.length > 0 && (
                                    <>
                                        <div
                                            style={{ borderBottom: '1px solid #80808052', display: 'flex', justifyContent: 'space-evenly', padding: '10px 0' }}
                                        >
                                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '20%' }}></div>
                                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}>#</div>
                                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Expense Type</div>
                                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Amount</div>
                                            <div style={{ textAlign: 'center', width: '20%' }}></div>
                                        </div>
                                        <AnimatePresence>
                                            {values.expenses.map((expense, index) => (
                                                <motion.div
                                                    style={{
                                                        borderBottom: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly',
                                                        padding: '10px 0'
                                                    }}
                                                    initial={{ scale: 0, opacity: 0, height: 0 }}
                                                    animate={{ scale: 1, opacity: 1, height: 'auto' }}
                                                    exit={{ scale: 0, backgroundColor: 'gray', opacity: 0.5, height: 0 }}
                                                    key={expense.exp_item_type_id}
                                                >
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '20%'
                                                        }}
                                                    >
                                                        {values.expenses.length == index + 1 ? (
                                                            <AddIcon
                                                                sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%' }}
                                                                onClick={() => setAddNew((prev) => !prev)}
                                                            />
                                                        ) : (
                                                            <ViewCompactIcon />
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '10%'
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%'
                                                        }}
                                                    >
                                                        {expense.exp_name}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="number"
                                                            onChange={(e) => {
                                                                // const updatedExpenses = [...values.expenses];
                                                                // updatedExpenses[index].amount = Number(e.target.value); // Parsing value to float, defaulting to 0 if parsing fails
                                                                // setExpenseList(updatedExpenses);
                                                                const newValue = e.target.value;
                                                                // Check if the input is not starting with 0 and is a valid number or empty
                                                                if (!newValue.startsWith('0') || newValue === '0') {
                                                                    setFieldValue(`expenses[${index}].amount`, newValue);
                                                                } else {
                                                                    setFieldValue(`expenses[${index}].amount`, newValue.replace(/^0+/, ''));
                                                                }
                                                                // setFieldValue(`expenses[${index}].amount`, Number(e.target.value));
                                                            }}
                                                            value={values.expenses[index].amount}
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                borderWidth: '1px',
                                                                borderLeft: 0,
                                                                borderRight: 0,
                                                                borderColor: '#80808052',
                                                                outline: 0,
                                                                padding: '5px 0'
                                                            }}
                                                        />
                                                        {expense.is_percent && <span style={{ position: 'absolute', right: 20 }}>%</span>}
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                        {!(
                                                            expense.exp_name == 'Dispatcher Expense' ||
                                                            expense.exp_name == 'Insurance Expense' ||
                                                            expense.exp_name == 'Fuel Expense'
                                                        ) && (
                                                            <RemoveIcon
                                                                sx={{ backgroundColor: 'red', color: 'white', borderRadius: '50%' }}
                                                                onClick={() => handleRemove(index)}
                                                            />
                                                        )}
                                                    </div>
                                                </motion.div>
                                            ))}
                                        </AnimatePresence>
                                        <AnimatePresence>
                                            {addNew && (
                                                <motion.div
                                                    style={{
                                                        borderBottom: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly',
                                                        padding: '10px 0'
                                                    }}
                                                    initial={{ opacity: 0, backgroundColor: 'green', height: 0 }}
                                                    animate={{ opacity: 1, backgroundColor: 'white', height: 50 }}
                                                    exit={{ opacity: 0, backgroundColor: 'red', height: 0 }}
                                                >
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '20%'
                                                        }}
                                                    >
                                                        <AddIcon
                                                            sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%' }}
                                                            onClick={() => console.log('Added')}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '10%'
                                                        }}
                                                    >
                                                        {values.expenses.length + 1}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'end',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative',
                                                            backgroundColor: '#80808040'
                                                            // paddingRight: 10
                                                        }}
                                                    >
                                                        {expenseOptionOpen ? (
                                                            <KeyboardArrowUpIcon
                                                                style={{ color: 'black', width: '100%' }}
                                                                onClick={() => setExpenseOptionOpen(!expenseOptionOpen)}
                                                            />
                                                        ) : (
                                                            <KeyboardArrowDownIcon
                                                                style={{ color: 'black', width: '100%' }}
                                                                onClick={() => setExpenseOptionOpen(!expenseOptionOpen)}
                                                            />
                                                        )}

                                                        {expenseOptionOpen && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: 39,
                                                                    left: 0,
                                                                    backgroundColor: 'white',
                                                                    padding: 10,
                                                                    borderRadius: 5,
                                                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                                    width: '100%',
                                                                    zIndex: 1
                                                                }}
                                                            >
                                                                <Button
                                                                    style={{ color: 'orange' }}
                                                                    onClick={() => {
                                                                        setExpenseOpen(true);
                                                                    }}
                                                                >
                                                                    Add new...
                                                                </Button>
                                                                {mainExpenseList.map((expense, index) => {
                                                                    if (!expenseList.includes(expense)) {
                                                                        return (
                                                                            <button
                                                                                key={index}
                                                                                style={{
                                                                                    display: 'block',
                                                                                    width: '100%',
                                                                                    border: 0,
                                                                                    outline: 0,
                                                                                    boxShadow:
                                                                                        'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                                                                }}
                                                                                onClick={() => {
                                                                                    setExpenseList([...expenseList, expense]);
                                                                                    setExpenseOptionOpen(false);
                                                                                }}
                                                                            >
                                                                                {expense.exp_name}
                                                                            </button>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                borderWidth: '1px',
                                                                borderLeft: 0,
                                                                borderRight: 0,
                                                                borderColor: '#80808052',
                                                                outline: 0,
                                                                padding: '6px 0'
                                                            }}
                                                            placeholder="0"
                                                        />
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                        <RemoveIcon
                                                            sx={{ backgroundColor: 'red', color: 'white', borderRadius: '50%' }}
                                                            onClick={() => setAddNew(false)}
                                                        />
                                                    </div>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </>
                                )
                            )}
                        </SubCard>

                        <SubCard title="Drivers Income" sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <FieldArray name="pickup">
                                {({}) => {
                                    return (
                                        <>
                                            {values.drvSplit.map((i, index) => {
                                                const earn =
                                                    i.stlm_type === 'split'
                                                        ? ((values.netIncome * values.drvSplit[index].percent) / 100).toFixed(2) -
                                                          values.drvSplit[index].deduction
                                                        : i.stlm_type === 'mileage'
                                                        ? (values.drvSplit[index].mileage * values.drvSplit[index].mileage_multiplier).toFixed(2) -
                                                          values.drvSplit[index].deduction
                                                        : values.drvSplit[index].income - values.drvSplit[index].deduction;

                                                return (
                                                    <div key={`split-${index}`}>
                                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    borderRadius: '50%',
                                                                    backgroundColor: theme.palette.orange.main,
                                                                    color: '#ffffff',
                                                                    px: '10px',
                                                                    py: '6px'
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    ml: 1,
                                                                    mt: 0.8,

                                                                    width: '375px',
                                                                    height: '40px',
                                                                    fontWeight: '500',
                                                                    fontSize: '24px',
                                                                    fontFamily: 'Lexend'
                                                                }}
                                                            >
                                                                {i.driver_name !== '' ? i.driver_name : i.owner_name}
                                                            </Typography>
                                                        </Stack>
                                                        <Grid container spacing={2}>
                                                            {i.stlm_type === 'split' && (
                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                    {loading ? (
                                                                        <Skeleton height="50px" />
                                                                    ) : (
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Split Percent"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].percent}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`drvSplit[${index}].percent`, e.target.value);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            )}

                                                            {i.stlm_type === 'mileage' && (
                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                    {loading ? (
                                                                        <Skeleton height="50px" />
                                                                    ) : (
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Mileage"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].mileage}
                                                                            onChange={(e) => setFieldValue(`drvSplit[${index}].mileage`, e.target.value)}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            )}

                                                            {i.stlm_type === 'mileage' && (
                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                    {loading ? (
                                                                        <Skeleton height="50px" />
                                                                    ) : (
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Mileage Multiplier"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].mileage_multiplier}
                                                                            onChange={(e) =>
                                                                                setFieldValue(`drvSplit[${index}].mileage_multiplier`, e.target.value)
                                                                            }
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            )}

                                                            {i.stlm_type === 'flat' && (
                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                    {loading ? (
                                                                        <Skeleton height="50px" />
                                                                    ) : (
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Income"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].income}
                                                                            onChange={(e) => setFieldValue(`drvSplit[${index}].income`, e.target.value)}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            )}
                                                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Deduction"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].deduction}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`drvSplit[${index}].deduction`, e.target.value);
                                                                            }}
                                                                        />
                                                                        {values.drvSplit[index].deduction > 0 && (
                                                                            <TextField
                                                                                multiline
                                                                                sx={{ mt: 2 }}
                                                                                minRows={2}
                                                                                fullWidth
                                                                                className="text-input"
                                                                                color="orange"
                                                                                type="text"
                                                                                label="Reason"
                                                                                value={values.drvSplit[index].deduction_reason}
                                                                                onChange={(e) =>
                                                                                    setFieldValue(`drvSplit[${index}].deduction_reason`, e.target.value)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Bonus"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].bonus_amt}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`drvSplit[${index}].bonus_amt`, e.target.value);
                                                                            }}
                                                                        />
                                                                        {values.drvSplit[index].bonus_amt > 0 && (
                                                                            <TextField
                                                                                multiline
                                                                                sx={{ mt: 2 }}
                                                                                minRows={2}
                                                                                fullWidth
                                                                                className="text-input"
                                                                                color="orange"
                                                                                type="text"
                                                                                label="Bonus Explanation"
                                                                                value={values.drvSplit[index].bonus_reason}
                                                                                onChange={(e) =>
                                                                                    setFieldValue(`drvSplit[${index}].bonus_reason`, e.target.value)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Reimbursement"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].rmb_amt}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`drvSplit[${index}].rmb_amt`, e.target.value);
                                                                            }}
                                                                        />
                                                                        {values.drvSplit[index].rmb_amt > 0 && (
                                                                            <TextField
                                                                                multiline
                                                                                sx={{ mt: 2 }}
                                                                                minRows={2}
                                                                                fullWidth
                                                                                className="text-input"
                                                                                color="orange"
                                                                                type="text"
                                                                                label="Reimbursement Explanation"
                                                                                value={values.drvSplit[index].rmb_reason}
                                                                                onChange={(e) => setFieldValue(`drvSplit[${index}].rmb_reason`, e.target.value)}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    );
                                }}
                            </FieldArray>
                        </SubCard>

                        <SubCard title="Owner Income" sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <FieldArray name="pickup">
                                {({}) => {
                                    return (
                                        <>
                                            {values.ownerSplit.map((i, index) => {
                                                return (
                                                    <div key={`split-${index}`}>
                                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }} spacing={2}>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    borderRadius: '50%',
                                                                    backgroundColor: theme.palette.orange.main,
                                                                    color: '#ffffff',
                                                                    px: '10px',
                                                                    py: '6px'
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    ml: 1,
                                                                    mt: 0.8,
                                                                    height: '40px',
                                                                    fontWeight: '500',
                                                                    fontSize: '24px',
                                                                    fontFamily: 'Lexend'
                                                                }}
                                                            >
                                                                {i.owner_name}
                                                            </Typography>
                                                        </Stack>
                                                        <Grid container spacing={2} sx={{ mt: { xs: '2rem', sm: 0 } }}>
                                                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Deduction"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.ownerSplit[index].deduction}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`ownerSplit[${index}].deduction`, e.target.value);
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <>
                                                                        {values.ownerSplit[index].deduction > 0 && (
                                                                            <TextField
                                                                                multiline
                                                                                minRows={3}
                                                                                fullWidth
                                                                                className="text-input"
                                                                                color="orange"
                                                                                type="text"
                                                                                label="Reason"
                                                                                value={values.ownerSplit[index].deduction_reason}
                                                                                onChange={(e) =>
                                                                                    setFieldValue(`ownerSplit[${index}].deduction_reason`, e.target.value)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    );
                                }}
                            </FieldArray>
                        </SubCard>

                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <LoadingButton
                                loading={formik.isSubmitting}
                                onClick={() => formik.submitForm()}
                                variant="contained"
                                color="orange"
                                sx={{ color: 'white' }}
                            >
                                Preview
                            </LoadingButton>
                        </Grid>
                    </Form>
                </FormikProvider>

                {/* </Grid> */}
            </SubCard>

            {fuelList && fuelList.length > 0 && (
                <SubCard title="Fuel Expenses" sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                    {loading ? (
                        <Skeleton height="50px" />
                    ) : (
                        <MaterialTable
                            // tableRef={tableRef}
                            style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                            data={fuelList}
                            title=""
                            columns={[]}
                            icons={TABLE_ICONS}
                            components={{ Row: renderFuelRow, Header: renderFuelHeader }}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: <CustomNoRowsOverlay />
                                }
                            }}
                            options={{
                                selection: false,
                                paging: false,
                                filtering: false,
                                search: false
                            }}
                        />
                    )}
                </SubCard>
            )}
            <SubCard title="Loads Delivered" sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                {loading ? (
                    <Skeleton height="50px" />
                ) : isMobile ? (
                    selected &&
                    selected.map((driver, idx) => (
                        <Card
                            key={driver.driver_id}
                            sx={{
                                mt: '1rem',
                                marginBottom: '1.5rem',
                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                borderRadius: '15px',
                                backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                            }}
                        >
                            <CardContent>
                                <Typography sx={{ marginBottom: '.5rem' }}>Load : {driver.dsp_load_id}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Pickup Date : {driver.pickup.date}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Delivery Date : {driver.delivery.date}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>From : {driver.pickup.location}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>To : {driver.delivery.location}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Revenue : {driver.revenue}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Driver Name : {driver.driver_name}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Customer Name : {driver.cust_name}</Typography>
                            </CardContent>
                        </Card>
                    ))
                ) : isTablet ? (
                    <>
                        {selected &&
                            selected?.map((driver, idx) => (
                                <Card
                                    key={driver.driver_id}
                                    sx={{
                                        marginTop: '2rem',
                                        marginBottom: '1.5rem',
                                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                        borderRadius: '15px',
                                        backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                    }}
                                >
                                    <CardContent>
                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                            }}
                                        >
                                            <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}> Load : {driver.dsp_load_id} </Typography>

                                            <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Revenue : {driver.revenue}</Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                            }}
                                        >
                                            <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Pickup Date : {driver.pickup.date} </Typography>
                                            <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Delivery Date : {driver.delivery.date} </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                            }}
                                        >
                                            <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>From : {driver.pickup.location} </Typography>
                                            <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>To : {driver.delivery.location} </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                            }}
                                        >
                                            <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Driver Name : {driver.driver_name} </Typography>
                                            <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Customer Name : {driver.cust_name} </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))}
                    </>
                ) : (
                    <MaterialTable
                        tableRef={tableRef}
                        style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                        data={(query) =>
                            new Promise((resolve, _reject) => {
                                fetch(
                                    `/v1/api/dispatch/load/list?page=1&page_size=100&company_id=${
                                        loginInfoFromStorage.company_id
                                    }&bol_status=&load_status=Delivered&invoice_status=&created_start=&created_end=&price_start=&price_end=&pickup_start=${format(
                                        startDateFilter,
                                        'yyyy-MM-dd'
                                    )}&pickup_end=&delivery_start=&delivery_end=${format(endDateFilter, 'yyyy-MM-dd')}&driver=${
                                        formik1.values.driver ? formik1.values.driver.driver_id : 0
                                    }&stlm_status=Pending`,
                                    {
                                        method: 'get',
                                        headers: new Headers({
                                            'Content-type': 'application/json',
                                            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                                        })
                                    }
                                )
                                    .then((response) => response.json())
                                    .then((result) => {
                                        const totalRevenue = result.results.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.revenue), 0);
                                        setFieldValue('totalRevenue', totalRevenue.toFixed(2));
                                        setRevenue(totalRevenue);
                                        setLoadList(result.results.map((i) => i.dsp_load_id));

                                        resolve({
                                            data: result.results,
                                            page: query.page,
                                            totalCount: result.count
                                        });
                                    });
                            })
                        }
                        // data={dataPromise}
                        title=""
                        columns={[]}
                        icons={TABLE_ICONS}
                        components={{ Row: renderRow, Header: renderHeader }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: <CustomNoRowsOverlay />
                            }
                        }}
                        options={{
                            selection: false,
                            paging: false,
                            filtering: false,
                            search: false
                        }}
                    />
                )}
            </SubCard>

            <PreViewSettlememntModal open={open} handleCloseModal={handleCloseModal} stlmData={stlmData} maxWidth="lg" />

            {/* add new expense item modal start */}
            <Dialog
                fullWidth
                open={expenseOpen}
                onClose={() => {
                    expenseFormik.handleReset();
                    setExpenseOpen(false);
                }}
                scroll="paper"
            >
                <DialogTitle>
                    <Typography variant="h4" color="primary">
                        Expense Type
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <>
                        <FormikProvider value={expenseFormik}>
                            <Form autoComplete="off" onSubmit={expenseFormik.handleSubmit} encType="multipart/form-data">
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton variant="rectangular" width="100%" height="50px" />
                                        ) : (
                                            <TextField
                                                fullWidth
                                                className="text-input"
                                                color="orange"
                                                type="text"
                                                label="Type Name"
                                                name="FileName"
                                                value={expenseFormik.values.FileName}
                                                onChange={expenseFormik.handleChange}
                                                onBlur={expenseFormik.handleBlur}
                                                error={Boolean(expenseFormik.touched.FileName && expenseFormik.errors.FileName)}
                                                helperText={expenseFormik.touched.FileName && expenseFormik.errors.FileName}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormLabel id="demo-radio-buttons-group-label" className="radio-label">
                                            Default Value type?
                                        </FormLabel>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                row
                                                className="radio-group"
                                                value={expenseFormik.values.isPercent}
                                                onChange={(e) => expenseFormik.setFieldValue('isPercent', e.target.value == 'true')}
                                            >
                                                <FormControlLabel value={true} control={<Radio />} label="Percent" />
                                                <FormControlLabel value={false} control={<Radio />} label="Flat" />
                                            </RadioGroup>
                                        )}
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton variant="rectangular" width="100%" height="50px" />
                                        ) : (
                                            <TextField
                                                fullWidth
                                                className="text-input"
                                                color="orange"
                                                type="text"
                                                label="Default Value"
                                                name="default"
                                                onChange={(e) => {
                                                    expenseFormik.setFieldValue('default', !isNaN(e.target.value) ? e.target.value : 0);
                                                }}
                                                error={Boolean(expenseFormik.touched.default && expenseFormik.errors.default)}
                                                helperText={expenseFormik.touched.default && expenseFormik.errors.default}
                                            />
                                        )}
                                    </Grid>
                                </Grid>

                                <Box sx={{ mt: 2 }} align="center">
                                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                                        <LoadingButton
                                            type="submit"
                                            loading={expenseFormik.isSubmitting}
                                            color="orange"
                                            variant="contained"
                                            sx={{ color: 'white' }}
                                        >
                                            Save
                                        </LoadingButton>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                mr: 2,

                                                backgroundColor: '#EB812E',
                                                color: 'white',
                                                fontFamily: 'Lexend',
                                                fontWeight: 400,
                                                lineHeight: '20px',

                                                fontStyle: 'normal',

                                                height: '36px',
                                                fontSize: '16px',
                                                borderRadius: '4px'
                                            }}
                                            color="orange"
                                            onClick={() => {
                                                expenseFormik.handleReset();
                                                setExpenseOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                </DialogContent>
            </Dialog>
            {/* add new expense item modal end */}
        </>
    );
};

export default AddSettlementForm;
