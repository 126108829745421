// assets
import { IconDashboard } from '@tabler/icons';

import FactoringIcon from 'ui-component/icons/FactoringIcon';
import AccountingIcon from 'ui-component/icons/AccountingIcon';
import FleetIcon from 'ui-component/icons/FleetIcon';
import DSPIcon from 'ui-component/icons/DSPIcon';
import SetupIcon from 'ui-component/icons/SetupIcon';
import SafetyIcon from 'ui-component/icons/SafetyIcon';
import ReportIcon from 'ui-component/icons/ReportIcon';
import MCNDriverIcon from 'ui-component/icons/MCNDriverIcon';
import MCNMaintenanceIcon from 'ui-component/icons/MCNMaintenanceIcon';
import MCNScheduleIcon from 'ui-component/icons/MCNScheduleIcon';
import AuditLogIcon from 'ui-component/icons/AuditLogIcon';
import DashboardIcon from 'ui-component/icons/DashboardIcon';

export const getDashboardData = () => {

    const icons = {
        IconDashboard,
        FactoringIcon,
        AccountingIcon,
        FleetIcon,
        DSPIcon,
        SetupIcon,
        SafetyIcon,
        ReportIcon,
        MCNDriverIcon,
        MCNMaintenanceIcon,
        MCNScheduleIcon,
        AuditLogIcon,
        DashboardIcon
    };
    
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage ? loginInfoFromStorage.user_type : null;
    const permissions = loginInfoFromStorage ? loginInfoFromStorage.permissions : null;
    
    const dispatch_load = permissions && permissions.dspcompanyload && permissions.dspcompanyload.view ? true : false;
    const dispatch_customer = permissions && permissions.dspcompanycustomer && permissions.dspcompanycustomer.view ? true : false;
    
    
    const fleet_driver = permissions && permissions.driver && permissions.driver.view ? true : false;
    const fleet_truck = permissions && permissions.truck && permissions.truck.view ? true : false;
    const fleet_trailer = permissions && permissions.trailer && permissions.trailer.view ? true : false;
    const fleet_owner = permissions && permissions.owner && permissions.owner.view ? true : false;
    
    const accounting_settlement = permissions && permissions.dspdriversettlement && permissions.dspdriversettlement.view ? true : false;
    
    const accounting_ifta = permissions && permissions.dspdriverifta && permissions.dspdriverifta.view ? true : false;
    const accounting_invoice = permissions && permissions.dspcompanyloadinvoice && permissions.dspcompanyloadinvoice.view ? true : false;
    const accounting_tax = permissions && permissions.tax_config && permissions.tax_config.view ? true : false;
    const accounting_fuel = permissions && permissions.fuelreport && permissions.fuelreport.view ? true : false;
    const accounting_expense = permissions && permissions.expense && permissions.expense.view ? true : false;
    const accounting_bill = permissions && permissions.bill && permissions.bill.view ? true : false;
    const accounting_recurringbill = permissions && permissions.recurringbill && permissions.recurringbill.view ? true : false;
    const accounting_vendor = permissions && permissions.vendor && permissions.vendor.view ? true : false;
    const accounting_salesreceipt = permissions && permissions.salesreceipt && permissions.salesreceipt.view ? true : false;

    
    
    const factoring_payment = permissions && permissions.payment && permissions.payment.view ? true : false;
    const factoring_invoice_tracking = permissions && permissions.invoice_tracking && permissions.invoice_tracking.view ? true : false;
    const factoring_payment_tracking = permissions && permissions.payment_tracking && permissions.payment_tracking.view ? true : false;
    
    
    const setup_users = permissions && permissions.dispatcheradmin && permissions.dispatcheradmin.view ? true : false;
    const setup_integration = permissions && permissions.integration && permissions.integration.view ? true : false;
    const setup_data_import = permissions && permissions.data_import && permissions.data_import.view ? true : false;
    const setup_invoice_expense_item = permissions && permissions.invoice_expense_item && permissions.invoice_expense_item.view ? true : false;
    const setup_settlement_expense_item = permissions && permissions.settlement_expense_item && permissions.settlement_expense_item.view ? true : false;
    
    const safety_compliance = permissions && permissions.safety_compliance && permissions.safety_compliance.view ? true : false;
    
    
    const report = permissions && permissions.report && permissions.report.view ? true : false;
    
    const audit_log = permissions && permissions.useractivitylogdetail && permissions.useractivitylogdetail.view ? true : false;
    
    
    // ==============================|| DASHBOARD MENU ITEMS ||============================== //
    

    return {
        id: 'dashboard',
        title: '',
        type: 'group',
        children: [
            // adding dashboard icon
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/dashboard',
                icon: icons.DashboardIcon,
                breadcrumbs: false,
                user_type: user_type,
                is_active: (user_type === "dispatcher_admin" || user_type === "self_dispatch"),
            },
            {
                id: 'dispatch-center',
                title: 'Dispatch Center',
                type: 'item',
                url: '/dispatch-center',
                icon: icons.DSPIcon,
                breadcrumbs: false,
                user_type: user_type,
                is_active: dispatch_load || dispatch_customer,
            },
            {
                id: 'fleet',
                title: 'Fleet',
                type: 'item',
                url: '/fleet',
                icon: icons.FleetIcon,
                breadcrumbs: false,
                user_type: user_type,
                is_active: fleet_driver || fleet_truck || fleet_trailer || fleet_owner,
            },
            {
                id: 'accounting',
                title: 'Accounting',
                type: 'item',
                url: '/accounting/settlement',
                icon: icons.AccountingIcon,
                breadcrumbs: false,
                user_type: user_type,
                is_active: accounting_settlement || accounting_ifta || accounting_invoice || accounting_tax || accounting_fuel  || accounting_expense || accounting_bill || accounting_recurringbill || accounting_vendor || accounting_salesreceipt,
            },
            {
                id: 'factoring',
                title: 'Factoring',
                type: 'item',
                url: '/factoring/my-payments',
                icon: icons.FactoringIcon,
                breadcrumbs: false,
                user_type: user_type,
                is_active: factoring_payment || factoring_invoice_tracking || factoring_payment_tracking,
            },
            {
                id: 'setup',
                title: 'Setup',
                type: 'item',
                url: '/setup/user/all',
                icon: icons.SetupIcon,
                breadcrumbs: false,
                user_type: user_type,
                is_active: setup_users || setup_integration || setup_data_import || setup_invoice_expense_item || setup_settlement_expense_item,
            },
            {
                id: 'safety-compliance',
                title: 'Safety and Compliance',
                type: 'item',
                url: '/safety-compliance',
                icon: icons.SafetyIcon,
                breadcrumbs: false,
                user_type: user_type,
                is_active: safety_compliance,
            },
            // {
            //     id: 'reports',
            //     title: 'Report',
            //     type: 'item',
            //     url: '/reports',
            //     icon: icons.ReportIcon,
            //     breadcrumbs: false,
            //     user_type: user_type,
            //     is_active: report,
            // },
            ////////////////////////// New Created Start //////////////////////////
            {
                id: 'audit-log',
                title: 'Audit Log',
                type: 'item',
                url: '/audit-log',
                icon: icons.AuditLogIcon,
                breadcrumbs: false,
                user_type: user_type,
                is_active: audit_log,
            },
            ////////////////////////// New Created End //////////////////////////
            {
                id: 'dispatch-center-self',
                title: 'Dispatch Center',
                type: 'item',
                url: '/dispatch-center',
                icon: icons.DSPIcon,
                breadcrumbs: false,
                user_type: 'self_dispatch',
                is_active:true
            },
            {
                id: 'fleet-self',
                title: 'Fleet',
                type: 'item',
                url: '/fleet',
                icon: icons.FleetIcon,
                breadcrumbs: false,
                user_type: 'self_dispatch',
                is_active:true
            },
            {
                id: 'accounting-self',
                title: 'Accounting',
                type: 'item',
                url: '/accounting/settlement',
                icon: icons.AccountingIcon,
                breadcrumbs: false,
                user_type: 'self_dispatch',
                is_active:true
            },
            {
                id: 'factoring-self',
                title: 'Factoring',
                type: 'item',
                url: '/factoring/my-payments',
                icon: icons.FactoringIcon,
                breadcrumbs: false,
                user_type: 'self_dispatch',
                is_active:true
            },
            {
                id: 'setup-self',
                title: 'Setup',
                type: 'item',
                url: '/setup/user/all',
                icon: icons.SetupIcon,
                breadcrumbs: false,
                user_type: 'self_dispatch',
                is_active:true
            },
            {
                id: 'safety-compliance-self',
                title: 'Safety and Compliance',
                type: 'item',
                url: '/safety-compliance',
                icon: icons.SafetyIcon,
                breadcrumbs: false,
                user_type: 'self_dispatch',
                is_active:true
            },
            {
                id: 'reports-self',
                title: 'Report',
                type: 'item',
                url: '/reports',
                icon: icons.ReportIcon,
                breadcrumbs: false,
                user_type: 'self_dispatch',
                is_active:true
            },
            {
                id: 'audit-log',
                title: 'Audit Log',
                type: 'item',
                url: '/audit-log',
                icon: icons.AuditLogIcon,
                breadcrumbs: false,
                user_type: 'self_dispatch',
                is_active: true,
            },
            {
                id: 'dispatch-center-carrier',
                title: 'Dispatch Center',
                type: 'item',
                url: '/dispatch-center',
                icon: icons.DSPIcon,
                breadcrumbs: false,
                user_type: 'carrier',
                is_active:true
            },
            {
                id: 'fleet-carrier',
                title: 'Fleet',
                type: 'item',
                url: '/fleet',
                icon: icons.FleetIcon,
                breadcrumbs: false,
                user_type: 'carrier',
                is_active:true
            },
            {
                id: 'accounting-carrier',
                title: 'Accounting',
                type: 'item',
                url: '/accounting/settlement',
                icon: icons.AccountingIcon,
                breadcrumbs: false,
                user_type: 'carrier',
                is_active:true
            },
            {
                id: 'factoring-carrier',
                title: 'Factoring',
                type: 'item',
                url: '/factoring/my-payments',
                icon: icons.FactoringIcon,
                breadcrumbs: false,
                user_type: 'carrier',
                is_active:true
            },
            {
                id: 'setup-carrier',
                title: 'Setup',
                type: 'item',
                url: '/setup/user/all',
                icon: icons.SetupIcon,
                breadcrumbs: false,
                user_type: 'carrier',
                is_active:true
            },
            {
                id: 'safety-compliance-carrier',
                title: 'Safety and Compliance',
                type: 'item',
                url: '/safety-compliance',
                icon: icons.SafetyIcon,
                breadcrumbs: false,
                user_type: 'carrier',
                is_active:true
            },
            {
                id: 'reports-carrier',
                title: 'Report',
                type: 'item',
                url: '/reports',
                icon: icons.ReportIcon,
                breadcrumbs: false,
                user_type: 'carrier',
                is_active:true
            },
            {
                id: 'mechanic/driver',
                title: 'Drivers',
                type: 'item',
                url: 'mechanic/driver',
                icon: icons.MCNDriverIcon,
                breadcrumbs: false,
                user_type: 'mechanic_admin',
                is_active:true
            },
            {
                id: 'mechanic/maintenance',
                title: 'Maintenance',
                type: 'item',
                url: 'mechanic/maintenance',
                icon: icons.MCNMaintenanceIcon,
                breadcrumbs: false,
                user_type: 'mechanic_admin',
                is_active:true
            },
            {
                id: 'mechanic-schedule',
                title: 'Schedule',
                type: 'item',
                url: 'mechanic/schedule',
                icon: icons.MCNScheduleIcon,
                breadcrumbs: false,
                user_type: 'mechanic_admin',
                is_active:true
            },
            {
                id: 'mechanic-mechanics',
                title: 'Mechanics',
                type: 'item',
                url: 'mechanic/mechanics',
                icon: icons.MCNDriverIcon,
                breadcrumbs: false,
                user_type: 'mechanic_admin',
                is_active:true
            },
            {
                id: 'mechanic-service',
                title: 'Services',
                type: 'item',
                url: 'mechanic/services',
                icon: icons.MCNMaintenanceIcon,
                breadcrumbs: false,
                user_type: 'mechanic_admin',
                is_active:true
            },
            {
                id: 'mechanic-setup',
                title: ' Setup',
                type: 'item',
                url: '/setup/user/all',
                icon: icons.SetupIcon,
                breadcrumbs: false,
                user_type: 'mechanic_admin',
                is_active:true
            },
            {
                id: 'dispatch-center-super-admin',
                title: 'Dispatch Center',
                type: 'item',
                url: '/dispatch-center',
                icon: icons.DSPIcon,
                breadcrumbs: false,
                user_type: 'super_dispatcher_admin',
                is_active: true,
            },
            {
                id: 'fleet-super-admin',
                title: 'Fleet',
                type: 'item',
                url: '/fleet',
                icon: icons.FleetIcon,
                breadcrumbs: false,
                user_type: 'super_dispatcher_admin',
                is_active: true,
            },
            {
                id: 'accounting-super-admin',
                title: 'Accounting',
                type: 'item',
                url: '/accounting/settlement',
                icon: icons.AccountingIcon,
                breadcrumbs: false,
                user_type: 'super_dispatcher_admin',
                is_active: true,
            },
            {
                id: 'factoring-super-admin',
                title: 'Factoring',
                type: 'item',
                url: '/factoring/my-payments',
                icon: icons.FactoringIcon,
                breadcrumbs: false,
                user_type: 'super_dispatcher_admin',
                is_active: true,
            },
            {
                id: 'setup-super-admin',
                title: 'Setup',
                type: 'item',
                url: '/setup/user/all',
                icon: icons.SetupIcon,
                breadcrumbs: false,
                user_type: 'super_dispatcher_admin',
                is_active: true,
            },
            {
                id: 'safety-compliance-super-admin',
                title: 'Safety and Compliance',
                type: 'item',
                url: '/safety-compliance',
                icon: icons.SafetyIcon,
                breadcrumbs: false,
                user_type: 'super_dispatcher_admin',
                is_active: true,
            },
            // {
            //     id: 'reports-super-admin',
            //     title: 'Report',
            //     type: 'item',
            //     url: '/reports',
            //     icon: icons.ReportIcon,
            //     breadcrumbs: false,
            //     user_type: 'super_dispatcher_admin',
            //     is_active: true,
            // },
            {
                id: 'audit-log-super-admin',
                title: 'Audit Log',
                type: 'item',
                url: '/audit-log',
                icon: icons.AuditLogIcon,
                breadcrumbs: false,
                user_type: 'super_dispatcher_admin',
                is_active: audit_log,
            },
            // {
            //     id: 'accounting-accountant',
            //     title: 'Accounting',
            //     type: 'item',
            //     url: '/accounting/settlement',
            //     icon: icons.AccountingIcon,
            //     breadcrumbs: false,
            //     user_type: 'accountant',
            //     is_active: accounting_settlement || accounting_ifta || accounting_invoice || accounting_tax || accounting_fuel,
            // },
            // {
            //     id: 'factoring-accountant',
            //     title: 'Factoring',
            //     type: 'item',
            //     url: '/factoring/my-payments',
            //     icon: icons.FactoringIcon,
            //     breadcrumbs: false,
            //     user_type: 'accountant',
            //     is_active:  factoring_payment || factoring_invoice_tracking || factoring_payment_tracking,
            // },
            // {
            //     id: 'safety-compliance-auditor',
            //     title: 'Safety and Compliance',
            //     type: 'item',
            //     url: '/safety-compliance',
            //     icon: icons.SafetyIcon,
            //     breadcrumbs: false,
            //     user_type: 'auditor',
            //     is_active: safety_compliance,
            // },
            // {
            //     id: 'dispatch-center',
            //     title: 'Dispatch Center',
            //     type: 'item',
            //     url: '/dispatch-center',
            //     icon: icons.DSPIcon,
            //     breadcrumbs: false,
            //     user_type: 'dispatcher_admin',
            //     is_active:true,
            // },
            // {
            //     id: 'fleet',
            //     title: 'Fleet',
            //     type: 'item',
            //     url: '/fleet',
            //     icon: icons.FleetIcon,
            //     breadcrumbs: false,
            //     user_type: 'dispatcher_admin',
            //     is_active:true,
            // },
            // {
            //     id: 'accounting',
            //     title: 'Accounting',
            //     type: 'item',
            //     url: '/accounting/settlement',
            //     icon: icons.AccountingIcon,
            //     breadcrumbs: false,
            //     user_type: 'dispatcher_admin',
            //     is_active:true,
            // },
            // {
            //     id: 'factoring',
            //     title: 'Factoring',
            //     type: 'item',
            //     url: '/factoring/my-payments',
            //     icon: icons.FactoringIcon,
            //     breadcrumbs: false,
            //     user_type: 'dispatcher_admin',
            //     is_active:true,
            // },
            // {
            //     id: 'setup',
            //     title: 'Setup',
            //     type: 'item',
            //     url: '/setup/user/all',
            //     icon: icons.SetupIcon,
            //     breadcrumbs: false,
            //     user_type: 'dispatcher_admin',
            //     is_active:true,
            // },
            // {
            //     id: 'safety-compliance',
            //     title: 'Safety and Compliance',
            //     type: 'item',
            //     url: '/safety-compliance',
            //     icon: icons.SafetyIcon,
            //     breadcrumbs: false,
            //     user_type: 'dispatcher_admin',
            //     is_active:true,
            // },
            // {
            //     id: 'reports',
            //     title: 'Report',
            //     type: 'item',
            //     url: '/reports',
            //     icon: icons.ReportIcon,
            //     breadcrumbs: false,
            //     user_type: 'dispatcher_admin',
            //     is_active:true,
            // },{
            //     id: 'audit-log',
            //     title: 'Audit Log',
            //     type: 'item',
            //     url: '/audit-log',
            //     icon: icons.AuditLogIcon,
            //     breadcrumbs: false,
            //     user_type: 'dispatcher_admin',
            //     is_active: true,
            // },
        ]
    };
}