import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LoopIcon from '@mui/icons-material/Loop';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Card, CardContent, CircularProgress, Menu, MenuItem, TextField, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { queryClient } from 'index';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddVendorModal from './AddVendorModal';
import EditVendorModal from './EditVendorModal';
import TableRowAnimation from './TableRowAnimation';
import './expenses.css';
import { useLocation, useNavigate } from 'react-router';
import debounce from 'lodash.debounce';
import user_types from 'constant/user_types';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

const headCells = [
    {
        id: 'company_name',
        label: 'Company Name'
    },
    {
        id: 'first_name',
        label: 'First Name'
    },
    {
        id: 'last_name',
        label: 'Last Name'
    },
    {
        id: 'title',
        label: 'Title'
    },
    {
        id: 'email',
        label: 'Email'
    },
    {
        id: 'phone_number',
        label: 'Phone Number'
    }
];

function EnhancedTableHead(props) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
        <TableHead>
            <TableRow sx={{ backgroundColor: '#DDE6FF' }}>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ))}
                <TableCell key={'action'} sortDirection={false}>
                    Actions
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const { numSelected, handleBulkDelete, setAddDrawerOpen, deleteBulkPending } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const params = new URLSearchParams(queryParams);

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>

                {((permissions.vendor && permissions.vendor.delete) || user_types.includes(user_type)) && (
                    <Tooltip title="Delete">
                        <IconButton onClick={handleBulkDelete} disabled={deleteBulkPending}>
                            {deleteBulkPending ? <LoopIcon className="rotation-icon" /> : <DeleteIcon />}
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 2,
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: '1rem', sm: 0 }
                }}
            >
                <TextField
                    type="text"
                    onChange={debounce((e) => {
                        params.set('search', e.target.value);
                        navigate({ search: `?${params.toString()}` });
                    }, 1000)}
                    size="small"
                    placeholder="Search..."
                    // sx={{ width: { xs: '310px', sm: '350px' } }}
                    sx={{ width: { xs: '74vw', sm: '45vw', lg: '20vw' } }}
                    className="search-input"
                />

                {((permissions.vendor && permissions.vendor.add) || user_types.includes(user_type)) && (
                    <Button
                        variant="contained"
                        onClick={() => setAddDrawerOpen(true)}
                        startIcon={<AddIcon />}
                        color="orange"
                        sx={{
                            color: 'white',
                            // minWidth: { xs: '19.3rem', sm: '10rem' }
                            maxWidth: { xs: '18rem', sm: '11rem' }
                        }}
                    >
                        Add new Vendor
                    </Button>
                )}
            </Box>
        </Toolbar>
    );
}

function Vendors() {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const params = new URLSearchParams(queryParams);
    const search = params.get('search') || '';
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const fetchVendors = async () => {
        const response = await axios.get(`/v1/api/accounting/vendors/?page=${page + 1}&page_size=${rowsPerPage}&search=${search}`, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response.data;
    };

    const {
        data: vendorList,
        isLoading,
        error
    } = useQuery({
        queryKey: ['vendors', search, page, rowsPerPage],
        queryFn: fetchVendors,
        staleTime: 1000 * 60 * 60
    });

    const [clickedOptionId, setClickedOptionId] = React.useState({ id: null, open: false });

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = vendorList?.results?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (_event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const [deleteBulkPending, setDeleteBulkPending] = useState(false);
    const deleteBulkVendor = async (formData) => {
        setDeleteBulkPending(true);
        try {
            const response = await axios.post('/v1/api/accounting/vendors/bulk_delete/', formData, {
                headers: {
                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                }
            });
            await queryClient.invalidateQueries({ queryKey: ['vendors', search, page, rowsPerPage] });
            return response;
        } catch (error) {
            throw new Error("Can't delete the vendor");
        } finally {
            setDeleteBulkPending(false);
        }
    };

    const handleBulkDelete = async () => {
        if (selected.length == 0) {
            toast.error('0 Item selected');
            return;
        }
        await toast.promise(deleteBulkVendor({ vendor_ids: selected }), {
            pending: `Deleting ${selected.length} Vendor...`,
            success: `${selected.length} Vendor deleted successfully`,
            error: `Failed to delete Vendor`
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const visibleRows = vendorList?.results;

    const [addDrawerOpen, setAddDrawerOpen] = useState(false);

    const deleteVendor = async (id) => {
        return await axios.delete(`/v1/api/accounting/vendors/${id}/`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
    };

    const { mutate: deleteExpenseMutate, isPending: deleteVendorPending } = useMutation({
        mutationFn: deleteVendor,
        onSuccess: async () => {
            toast.success('Vendor deleted successfully.');
            return await queryClient.invalidateQueries({ queryKey: ['vendors', search, page, rowsPerPage] });
        },
        onError: (error) => {
            toast.error(error.response.data.detail || "Can't deleted");
        }
    });

    const [editVendorDrawer, setEditVendorDrawer] = React.useState(false);
    const [addVendorStatus, setAddVendorStatus] = React.useState(null);

    const addVendorStatusChange = (status) => {
        setAddVendorStatus(status);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    handleBulkDelete={handleBulkDelete}
                    deleteBulkPending={deleteBulkPending}
                    setAddDrawerOpen={setAddDrawerOpen}
                />
                {isMobile ? (
                    visibleRows?.map((driver, idx) => {
                        const isItemSelected = isSelected(driver.id);
                        const labelId = idx;
                        return (
                            <>
                                <Card
                                    key={driver.driver_id}
                                    sx={{
                                        marginBottom: '1.5rem',
                                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                        borderRadius: '15px',
                                        backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                    }}
                                >
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                            <Checkbox
                                                sx={{ padding: 0 }}
                                                onClick={(event) => handleClick(event, driver.id)}
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId
                                                }}
                                            />
                                            <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                                {(popupState) => (
                                                    <div style={{ float: 'right', marginTop: '1px' }}>
                                                        <IconButton {...bindTrigger(popupState)} size="large">
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                        <Menu
                                                            {...bindMenu(popupState)}
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                        >
                                                            <MenuItem onClick={() => setEditVendorDrawer(true)}>Edit Expense</MenuItem>

                                                            <MenuItem disabled={deleteVendorPending} onClick={() => deleteExpenseMutate(row.id)}>
                                                                {deleteVendorPending ? 'Loop Expense' : 'Delete Expense'}
                                                            </MenuItem>

                                                            <MenuItem onClick={() => setClickedOptionId((prev) => ({ id: row.id, open: !prev.open }))}>
                                                                Close
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </Box>

                                        <Box sx={{ clear: 'both' }} />

                                        <Typography sx={{ marginBottom: '.5rem' }}>Company Name : {driver.company_name}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>First Name : {driver.first_name}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Last Name : {driver.last_name}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Title : {driver.title}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Email : {driver.email}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Phone Number : {driver.type}</Typography>
                                    </CardContent>
                                </Card>
                            </>
                        );
                    })
                ) : isTablet ? (
                    <>
                        {isLoading ? (
                            <>
                                <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress />
                                </Box>
                            </>
                        ) : (
                            visibleRows?.map((driver, idx) => {
                                const isItemSelected = isSelected(driver.id);
                                const labelId = idx;
                                return (
                                    <Card
                                        key={driver.driver_id}
                                        sx={{
                                            marginTop: '2rem',
                                            marginBottom: '1.5rem',
                                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                            borderRadius: '15px',
                                            backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                        }}
                                    >
                                        <CardContent>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                                <Checkbox
                                                    sx={{ padding: 0 }}
                                                    onClick={(event) => handleClick(event, driver.id)}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                                <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                                    {(popupState) => (
                                                        <div style={{ float: 'right', marginTop: '1px' }}>
                                                            <IconButton {...bindTrigger(popupState)} size="large">
                                                                <MoreHorizIcon />
                                                            </IconButton>
                                                            <Menu
                                                                {...bindMenu(popupState)}
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                            >
                                                                <MenuItem onClick={() => setEditVendorDrawer(true)}>Edit Expense</MenuItem>

                                                                <MenuItem disabled={deleteVendorPending} onClick={() => deleteExpenseMutate(row.id)}>
                                                                    {deleteVendorPending ? 'Loop Expense' : 'Delete Expense'}
                                                                </MenuItem>

                                                                <MenuItem onClick={() => setClickedOptionId((prev) => ({ id: row.id, open: !prev.open }))}>
                                                                    Close
                                                                </MenuItem>
                                                            </Menu>
                                                        </div>
                                                    )}
                                                </PopupState>
                                            </Box>

                                            {/* </Box> */}
                                            <Box sx={{ clear: 'both' }} />

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Company Name : {driver.company_name}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw', alignItems: 'center' }}>
                                                    Title : {driver.title}{' '}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                {' '}
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>First Name : {driver.first_name}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Last Name : {driver.last_name}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Email : {driver.email} </Typography>
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw', alignItems: 'center' }}>
                                                    Phone Number : {driver.type}{' '}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                );
                            })
                        )}
                    </>
                ) : (
                    <TableContainer>
                        <table className="expense-table" style={{ minWidth: 750, borderSpacing: '0 10px' }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={vendorList?.results?.length || 0}
                            />
                            <AnimatePresence>
                                {isLoading ? (
                                    // laoding table skeleton
                                    <motion.tbody
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0.5, height: 0 }}
                                    >
                                        <TableRowAnimation row={4} />
                                    </motion.tbody>
                                ) : (
                                    <motion.tbody
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0.5, height: 0 }}
                                    >
                                        {addVendorStatus == 'pending' && <TableRowAnimation />}
                                        {visibleRows?.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    // selected={isItemSelected}
                                                    sx={{
                                                        backgroundColor: isItemSelected ? '#8080801f' : '',
                                                        paddingTop: 0,
                                                        '&:hover': {
                                                            backgroundColor: '#8080801f'
                                                        }
                                                    }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            onClick={(event) => handleClick(event, row.id)}
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{row.company_name}</TableCell>
                                                    <TableCell>{row.first_name}</TableCell>
                                                    <TableCell>{row.last_name}</TableCell>
                                                    <TableCell id={labelId}>{row.title}</TableCell>
                                                    <TableCell>{row.email}</TableCell>
                                                    <TableCell>{row.phone_number}</TableCell>
                                                    <TableCell sx={{ position: 'relative' }}>
                                                        <AnimatePresence>
                                                            {clickedOptionId.open && clickedOptionId.id === row.id && (
                                                                <motion.div
                                                                    initial={{
                                                                        scale: 0
                                                                    }}
                                                                    animate={{
                                                                        scale: 1
                                                                    }}
                                                                    exit={{
                                                                        scale: 0
                                                                    }}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: '35%',
                                                                        bottom: 5,
                                                                        display: 'flex',
                                                                        gap: 10,
                                                                        alignItems: 'center',
                                                                        background: '#0b172e',
                                                                        borderRadius: 10,
                                                                        padding: 0
                                                                    }}
                                                                >
                                                                    {((permissions.vendor && permissions.vendor.change) || user_types.includes(user_type)) && (
                                                                        <Tooltip title="Edit Vendor" placement="top">
                                                                            <IconButton onClick={() => setEditVendorDrawer(true)}>
                                                                                <DriveFileRenameOutlineIcon
                                                                                    sx={{
                                                                                        bgcolor: '#8080806b',
                                                                                        color: 'white',
                                                                                        borderRadius: '50%',
                                                                                        padding: 0.5,
                                                                                        fontSize: 35
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                    {/* Edit expense modal */}
                                                                    <EditVendorModal
                                                                        editVendorDrawer={editVendorDrawer}
                                                                        setEditVendorDrawer={setEditVendorDrawer}
                                                                        vendor={row}
                                                                        setClickedOptionId={setClickedOptionId}
                                                                        search={search}
                                                                        page={page}
                                                                        rowsPerPage={rowsPerPage}
                                                                    />

                                                                    {((permissions.vendor && permissions.vendor.delete) || user_types.includes(user_type)) && (
                                                                        <Tooltip title="Delete Vendor" placement="top">
                                                                            <IconButton
                                                                                disabled={deleteVendorPending}
                                                                                onClick={() => deleteExpenseMutate(row.id)}
                                                                            >
                                                                                {deleteVendorPending ? (
                                                                                    <LoopIcon
                                                                                        className="rotation-icon"
                                                                                        sx={{
                                                                                            bgcolor: '#8080806b',
                                                                                            color: deleteVendorPending ? 'gray' : 'white',
                                                                                            borderRadius: '50%',
                                                                                            padding: 0.5,
                                                                                            fontSize: 35
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <DeleteIcon
                                                                                        sx={{
                                                                                            bgcolor: '#8080806b',
                                                                                            color: deleteVendorPending ? 'gray' : 'white',
                                                                                            borderRadius: '50%',
                                                                                            padding: 0.5,
                                                                                            fontSize: 35
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}

                                                                    <Tooltip title="Close" placement="top">
                                                                        <IconButton
                                                                            onClick={() => setClickedOptionId((prev) => ({ id: row.id, open: !prev.open }))}
                                                                        >
                                                                            <CloseIcon
                                                                                sx={{
                                                                                    bgcolor: '#8080806b',
                                                                                    color: 'white',
                                                                                    borderRadius: '50%',
                                                                                    padding: 0.5,
                                                                                    fontSize: 35,
                                                                                    zIndex: 2
                                                                                }}
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </motion.div>
                                                            )}
                                                        </AnimatePresence>
                                                        <Tooltip title="Open Option" placement="right-start">
                                                            <IconButton onClick={() => setClickedOptionId((prev) => ({ id: row.id, open: !prev.open }))}>
                                                                <MoreHorizIcon sx={{ fontSize: 35, padding: 0.5 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </motion.tbody>
                                )}
                            </AnimatePresence>
                        </table>
                    </TableContainer>
                )}{' '}
                {vendorList?.results?.length === 0 && !isLoading && (
                    <Typography variant="h3" align="center" sx={{ py: 3, backgroundColor: '#14295317' }}>
                        No vendors found
                    </Typography>
                )}
                {isMobile ? (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={vendorList?.count || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: '30vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                ) : (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={vendorList?.count || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Paper>
            {/* Add Vendor Modal */}
            <AddVendorModal
                addDrawerOpen={addDrawerOpen}
                setAddDrawerOpen={setAddDrawerOpen}
                addVendorStatusChange={addVendorStatusChange}
                search={search}
                page={page}
                rowsPerPage={rowsPerPage}
            />
        </Box>
    );
}

export default Vendors;
