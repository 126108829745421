// project imports
import { useEffect } from 'react';

import MainCard from 'ui-component/cards/MainCard';
import AccountingForm from 'ui-component/setup/forms/AccountingForm';

import { useDispatch, useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';

// ==============================|| SAMPLE PAGE ||============================== //

const EditAccounting = () => {
    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    return (
        <>
            <MainCard>
                <AccountingForm companySetup={companySetup} />
            </MainCard>
        </>
    );
};

export default EditAccounting;
