import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// material-ui
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Skeleton,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import MaterialTable from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { Stack, useMediaQuery } from '@mui/system';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { createRef } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import * as Yup from 'yup';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import TABLE_ICONS from 'tableIcons';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
// import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { openErrorSnackbar } from 'store/ui/snackbarSlice';
import PreViewSettlememntModal from 'ui-component/modals/PreViewSettlememntModal';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const EditSettlementForm = () => {
    const { id } = useParams();
    const tableRef = createRef();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [driverList, setDriverList] = useState();
    const [loadList, setLoadList] = useState();
    const [fuelList, setFuelList] = useState();
    const [errorData, setErrorData] = useState();
    const [settlementData, setSettlementData] = useState(null);
    const [revenue, setRevenue] = useState(0);
    const [dispatcherExpense, setDispatcherExpense] = useState(0);
    const [fuelExpense, setFuelExpense] = useState(0);
    const [pmeExpense, setPmeExpense] = useState(0);
    const [insExpense, setInsExpense] = useState(0);
    const [iftaExpense, setIftaExpense] = useState(0);
    const [othExpense, setOthExpense] = useState(0);
    const [searchDriver, setSearchDriver] = useState('');
    const [stlmData, setStlmData] = useState();
    const [options, setOptions] = useState([]);

    const [expenseList, setExpenseList] = useState([]);
    const [expenseOptionOpen, setExpenseOptionOpen] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [expenseOpen, setExpenseOpen] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [truckId, setTruckId] = useState('');
    const [open, setOpen] = useState(false);

    const [selected, setSelected] = useState([]);
    const [loadData, setLoadData] = useState([]);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

    const handleCloseModal = () => {
        setOpen(false);
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const OrganizationSchema = Yup.object().shape({
        billingEmail: Yup.string().email().required('Email is a required field'),
        fuelExpense: Yup.number().typeError('Only number allowed'),

        pme: Yup.number().typeError('Only number allowed'),

        insurenceExpense: Yup.number().typeError('Only number allowed'),

        ifta: Yup.number().typeError('Only number allowed'),
        otherExpense: Yup.number().typeError('Only number allowed')
    });

    const FetchDataSchema = Yup.object().shape({
        driver: Yup.mixed().required('driver is a required field'),
        startDate: Yup.date().required('Start Date is a required field').typeError('Invalid Start Date'),
        endDate: Yup.string().required('End Date is a required field').typeError('Invalid End Date')
    });

    useEffect(() => {
        if (id && !driverList) {
            fetchDriverList();
        }
        if (id && driverList && !settlementData) {
            fetchSettlement();
        }

        if (id && driverList && settlementData) {
            handleDriverChange(settlementData.driver_id);
            if (tableRef.current) {
                tableRef.current.onQueryChange();
            }
        }
    }, [driverList, settlementData, id, tableRef.current]);

    useEffect(() => {
        expenseHanlder();
    }, [revenue, dispatcherExpense, fuelExpense, pmeExpense, insExpense, iftaExpense, othExpense]);

    const fetchSettlement = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/accounting/stlm/view/${id}`, config);

            setExpenseList(data.expense_items);
            setSettlementData(data.stlm_data);

            tableRef.current.onQueryChange();

            setDriverValue(options[options.findIndex((i) => i.driver_id === data.driver_id)]);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchDriverList = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&is_main=true&page=1&page_size=100&search=${searchDriver}`,
                config
            );
            setDriverList(data.results);
            setOptions(data.results);
            setLoading(false);
        } catch (error) {
            setLoading1(false);
        }
    };

    const expenseHanlder = () => {
        try {
            const result = parseFloat((revenue * values.dispatcherExpense) / 100);

            const grandExp =
                parseFloat(result) +
                parseFloat(values.fuelExpense) +
                parseFloat(values.pme) +
                parseFloat(values.insurenceExpense) +
                parseFloat(values.ifta) +
                parseFloat(values.otherExpense);

            setFieldValue('netIncome', parseFloat(parseFloat(revenue) - grandExp).toFixed(2));
        } catch (error) {
            console.log(error);
        }
    };

    const formik1 = useFormik({
        enableReinitialize: true,

        initialValues: {
            driver: id && settlementData && driverList ? driverList.filter(({ driver_id }) => driver_id === settlementData.driver_id)[0] : null,
            startDate: id && settlementData ? settlementData.start_date + 'T00:00' : '',
            endDate: id && settlementData ? settlementData.end_date + 'T00:00' : '',
            stlmType: id && settlementData ? settlementData.stlm_type : 'flat',
            paymentStatus: id && settlementData ? settlementData.payment_status : 0
        },
        validationSchema: FetchDataSchema,
        onSubmit: async () => {
            try {
                const formData = {
                    driver_id: formik1.values.driver ? formik1.values.driver.driver_id : '',
                    truck_id: formik1.values.driver ? formik1.values.driver.truck_info.truck_id : '',
                    stlm_type: formik1.values.stlmType,
                    start_date: formik1.values.startDate !== '' ? format(new Date(formik1.values.startDate), 'yyyy-MM-dd') : null,
                    end_date: formik1.values.endDate !== '' ? format(new Date(formik1.values.endDate), 'yyyy-MM-dd') : null,
                    created_by: loginInfoFromStorage.user_id,
                    company_id: loginInfoFromStorage.company_id
                };
                if (settlementData && settlementData.drivers.length) {
                    formData['drivers'] = settlementData.drivers[0];
                }
                if (settlementData && settlementData.owner.length) {
                    formData['owner'] = settlementData.owner[0];
                }
                const { data } = await axios.post(`/v1/api/accounting/stlm/fetch-data/`, formData, config);

                setSettlementData((prevData) => ({
                    ...prevData,
                    drivers: data.drivers,
                    owner: data.owner
                }));
                setExpenseList(data.expenses);
            } catch (error) {
                console.log(error);
                setErrorData(error.response.data);
            }
        }
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            driver: id && settlementData && driverList ? driverList.filter(({ driver_id }) => driver_id === settlementData.driver_id)[0] : '',
            companyName: '',
            totalRevenue: id && settlementData ? settlementData.total_revenue : '',
            payDate: id && settlementData ? settlementData.payment_date : new Date(),
            netIncome: id && settlementData ? settlementData.income : 0,
            billingEmail: id && settlementData && driverList ? driverList.filter(({ driver_id }) => driver_id === settlementData.driver_id)[0].email : '',
            drvSplit: id && settlementData ? settlementData.drivers : [],
            ownerSplit: id && settlementData ? settlementData.owner : [],
            expenses: id && settlementData ? settlementData.stlm_expenses : []
        },
        validationSchema: OrganizationSchema,
        onSubmit: async (values) => {
            const totalPercentage = values.drvSplit.reduce((acc, item) => parseFloat(acc) + parseFloat(item.percent), 0);
            if (totalPercentage > 100) {
                dispatch(openErrorSnackbar('percentage should not greater then 100'));
                return;
            }

            const expenses = values.expenses.map((expense) => ({
                ...expense,
                amount: Number.isInteger(expense.amount) ? expense.amount : parseFloat(expense.amount).toFixed(2)
            }));

            const formData = {
                driver_id: formik1.values.driver ? values.driver.driver_id : '',
                start_date: formik1.values.startDate !== '' ? format(new Date(formik1.values.startDate), 'yyyy-MM-dd') : null,
                end_date: formik1.values.endDate !== '' ? format(new Date(formik1.values.endDate), 'yyyy-MM-dd') : null,
                stlm_type: formik1.values.stlmType ? formik1.values.stlmType : 'flat',
                payment_date: values.payDate !== '' ? format(new Date(values.payDate), 'yyyy-MM-dd') : null,
                total_revenue: values.totalRevenue,
                income: values.netIncome,
                payment_status: formik1.values.paymentStatus,
                drivers: values.drvSplit,
                owner: values.ownerSplit,
                expenses: expenses,
                loadList: loadList ? loadList : [],
                fuelList: fuelList ? fuelList : [],
                truck_id: truckId,
                created_by: loginInfoFromStorage.user_id,
                company_id: loginInfoFromStorage.company_id,
                is_active: true,
                is_edit: false
            };

            try {
                const { data } = await axios.post(`/v1/api/accounting/stlm/calculate/`, formData, config);
                setStlmData(data.data);
                setOpen(true);
            } catch (error) {
                setErrorData(error);
            }
        }
    });

    const { errors, touched, values, setFieldValue, getFieldProps, handleSubmit } = formik;

    const expenseFormik = useFormik({
        initialValues: {
            FileName: '',
            fileType: 'settlement',
            default: '',
            isPercent: false
        },
        validationSchema: Yup.object().shape({
            FileName: Yup.string().required('File Type Name is a required field'),
            default: Yup.number().max(99999).min(0).required()
        }),

        onSubmit: async (values, action) => {
            try {
                {
                    const { data } = await axios.post(
                        `/v1/api/users/company/expense-type/add/`,
                        {
                            exp_item_type_name: values.FileName,
                            exp_type: values.fileType,
                            default_value: values.default,
                            is_percent: values.isPercent ? values.isPercent : false,
                            created_by: loginInfoFromStorage.user_id,
                            company_id: loginInfoFromStorage.company_id,
                            is_common: false
                        },
                        config
                    );
                    action.resetForm();
                    toast.success('Expense Type Added successfully');

                    setExpenseOpen(false);
                    setAddNew(false);
                    setExpenseOptionOpen(false);
                    setExpenseList([
                        ...expenseList,
                        {
                            company_id: data.company_id,
                            exp_item_type_id: data.exp_item_type_id,
                            exp_name: data.exp_item_type_name,
                            exp_item_type: data.exp_item_type,
                            amount: data.default_value,
                            is_percent: data.is_percent
                        }
                    ]);
                }
                setExpenseOpen(false);
            } catch (error) {
                console.log(error);
                toast.error("Can't save please contact with support");
            }
        }
    });

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Load</th>
                    <th className="headerTd rightHidBor">Pickup Date</th>
                    <th className="headerTd rightHidBor">Delivery Date</th>
                    <th className="headerTd rightHidBor">From</th>
                    <th className="headerTd rightHidBor">To</th>
                    <th className="headerTd rightHidBor">Revenue</th>
                    <th className="headerTd rightHidBor">Driver Name</th>
                    <th className="headerTd">Customer Name</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.load}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.pickup.date}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.delivery.date}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.pickup.location}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.delivery.location}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.revenue}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_name}
                </td>
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.cust_name}
                </td>
            </tr>
        );
    };

    const renderFuelHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Date</th>
                    <th className="headerTd rightHidBor">Invoice</th>
                    <th className="headerTd rightHidBor">Total Gallons</th>
                    <th className="headerTd rightHidBor">Total Amount</th>
                    <th className="headerTd rightHidBor">Location</th>
                </tr>
            </thead>
        );
    };
    const renderFuelRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.transaction_date}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.invoice}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.total_gallons}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.total_amount}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.location_name}, {rowData.data.city_name} {rowData.data.state_name}
                </td>
            </tr>
        );
    };

    const handleDriverChange = (event) => {
        const selectedValue = event;
        const selectedDriver = driverList.find((driver) => driver.driver_id === selectedValue);
        const selectedDriverEmail = selectedDriver ? selectedDriver.email : '';
        const selectedDriverTruckId = selectedDriver ? selectedDriver.truck_id : '';

        setTruckId(selectedDriverTruckId);

        setFieldValue('billingEmail', selectedDriverEmail);
    };

    const onChangeHandle = async (value) => {
        setLoading1(true);
        const { data } = await axios.get(
            `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100&search=${value}`,
            config
        );
        setLoading1(false);
        setOptions(data.results);
    };

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;

        const updatedValue = inputValue.replace(/[^0-9.]/g, '');

        const decimalCount = (updatedValue.match(/\./g) || []).length;
        if (decimalCount > 1) {
            const indexOfLastDecimal = updatedValue.lastIndexOf('.');
            const integerValue = updatedValue.substring(0, indexOfLastDecimal);
            const fractionalValue = updatedValue.substring(indexOfLastDecimal + 1);
            const sanitizedFractionalValue = fractionalValue.replace(/\./g, '');
            e.target.value = integerValue + '.' + sanitizedFractionalValue;
        } else {
            e.target.value = updatedValue;
        }
    };

    const handleRemove = (index) => {
        formik.setValues((prevValues) => ({
            ...prevValues,
            expenses: prevValues.expenses.filter((_, i) => index !== i)
        }));
    };

    const fetchData = async (query) => {
        setLoading(true);

        try {
            const url = `/v1/api/accounting/fuel-reports/?page=${query.page + 1}&page_size=100&stlm_id=${id}`;

            const response = await fetch(url, {
                method: 'get',
                headers: new Headers({
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                })
            });

            const result = await response.json();
            console.log(result);

            // Set data
            setSelected(result.results || []);
            // setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    const fetchLoadData = async (query) => {
        setLoading(true);
        try {
            const url = `/v1/api/dispatch/load/list?page=1&page_size=100&company_id=${
                loginInfoFromStorage.company_id
            }&bol_status=&load_status=Delivered&invoice_status=&created_start=&created_end=&price_start=&price_end=&pickup_start=${
                settlementData && formik1.values.startDate !== '' ? format(new Date(formik1.values.startDate), 'yyyy-MM-dd') : settlementData.start_date
            }&pickup_end=&delivery_start=&delivery_end=${
                settlementData && formik1.values.endDate !== '' ? format(new Date(formik1.values.endDate), 'yyyy-MM-dd') : settlementData.end_date
            }&driver=${formik1.values.driver.driver_id}`;
            const response = await fetch(url, {
                method: 'get',
                headers: new Headers({
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage?.token}`
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log(result);

            // Update state with the fetched data
            setLoadData(result.results || []);
            // setTotalCount(result.count || 0);
            console.log(result);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            // console.log(selected);
            console.log(loadData);
            fetchData({ page: 0, pageSize: 10 }).catch((error) => console.error('Error fetching data:', error));
            fetchLoadData({ page: 0, pageSize: 10 })
                .then((result) => {
                    // console.log(result);
                    setLoadData(result.data || {});
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
    }, [isMobile, isTablet]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                        sx={{
                            mt: '20px',
                            width: '375px',
                            height: '40px',
                            fontWeight: '600',
                            fontSize: '32px',
                            fontFamily: 'Lexend'
                        }}
                    >
                        Edit Settlement
                    </Typography>
                </Grid>
            </Grid>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                    <FormikProvider value={formik1}>
                        <Form autoComplete="off" onSubmit={formik1.handleSubmit} encType="multipart/form-data">
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Stack
                                        sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'flex-start', gap: { xs: '1rem' } }}
                                        justifyContent="space-between"
                                        spacing={2}
                                    >
                                        <Typography
                                            sx={{
                                                width: '375px',
                                                height: '40px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend',
                                                paddingTop: '1.5rem'
                                            }}
                                        >
                                            Search for Loads
                                        </Typography>
                                        <AnimateButton>
                                            <LoadingButton
                                                loading={formik1.isSubmitting}
                                                type="button"
                                                onClick={() => formik1.submitForm()}
                                                variant="contained"
                                                color="orange"
                                                sx={{ color: 'white' }}
                                            >
                                                Search
                                            </LoadingButton>
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                                <Grid container spacing={2} mt={2} pl={4}>
                                    {/* Driver */}
                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    className="text-input"
                                                    // multiple
                                                    id="driver-set"
                                                    options={options}
                                                    loading={loading1}
                                                    isOptionEqualToValue={(option, value) => option.driver_id === value.driver_id}
                                                    getOptionLabel={(option) => (option.name ? option.name : '')}
                                                    inputValue={inputValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setInputValue(newInputValue);
                                                    }}
                                                    value={formik1.values.driver}
                                                    onChange={(event, newValue) => {
                                                        // setDriverValue(newValue.name);
                                                        formik1.setFieldValue('driver', newValue);
                                                        handleDriverChange(newValue.driver_id);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label="Driver Name"
                                                            onChange={(ev) => {
                                                                if (ev.target.value !== '' || ev.target.value !== null || ev.target.value.length > 1) {
                                                                    onChangeHandle(ev.target.value);
                                                                }
                                                            }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                )
                                                            }}
                                                            error={Boolean(formik1.touched.driver && formik1.errors.driver)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText
                                                    error={Boolean(formik1.touched.driver && formik1.errors.driver)}
                                                    sx={{ marginRight: { sm: '-9rem' } }}
                                                >
                                                    {formik1.touched.driver && formik1.errors.driver
                                                        ? formik1.errors.driver
                                                        : 'Type atleast 2 characters to populate the list'}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    </Grid>

                                    {/* Start Date */}
                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    className="text-input"
                                                    openTo="day"
                                                    views={['year', 'month', 'day']}
                                                    label="Start Date"
                                                    value={formik1.values.startDate}
                                                    onChange={(date) => {
                                                        formik1.setFieldValue('startDate', date);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            className="text-input"
                                                            color="orange"
                                                            fullWidth
                                                            {...params}
                                                            error={
                                                                Boolean(formik1.touched.startDate && formik1.errors.startDate) ||
                                                                (errorData && errorData.start_date)
                                                            }
                                                            helperText={
                                                                (formik1.touched.startDate && formik1.errors.startDate) || (errorData && errorData.start_date)
                                                            }
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    </Grid>
                                    {/* End Date */}
                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    className="text-input"
                                                    openTo="day"
                                                    views={['year', 'month', 'day']}
                                                    label="End Date"
                                                    value={formik1.values.endDate}
                                                    onChange={(date) => {
                                                        formik1.setFieldValue('endDate', date);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            className="text-input"
                                                            color="orange"
                                                            fullWidth
                                                            InputProps={{ readOnly: true }}
                                                            {...params}
                                                            error={
                                                                Boolean(formik1.touched.endDate && formik1.errors.endDate) || (errorData && errorData.end_date)
                                                            }
                                                            helperText={
                                                                (formik1.touched.endDate && formik1.errors.endDate) || (errorData && errorData.end_date)
                                                            }
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </SubCard>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                        {/* <Stack direction="row" sx={{ mt: '20px' }} justifyContent="space-between" alignItems="center" spacing={2}> */}
                                        <Typography
                                            sx={{
                                                // width: '375px',
                                                height: '40px',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            General Information
                                        </Typography>
                                    </Stack>
                                </Grid>

                                <Grid container spacing={2} mt={2} pl={4} alignItems={'baseline'}>
                                    <Grid item lg={4} md={4} sm={4} xs={12} sx={{ mt: '1rem' }}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    className="text-input"
                                                    openTo="day"
                                                    views={['year', 'month', 'day']}
                                                    label="Pay Date"
                                                    value={values.payDate}
                                                    onChange={(date) => {
                                                        setFieldValue('payDate', date);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            className="text-input"
                                                            color="orange"
                                                            fullWidth
                                                            {...params}
                                                            error={Boolean(touched.payDate && errors.payDate) || (errorData && errorData.payment_date)}
                                                            helperText={(touched.payDate && errors.payDate) || (errorData && errorData.payment_date)}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    </Grid>

                                    {/* revenue */}
                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <TextField
                                                className="text-input"
                                                fullWidth
                                                type="text"
                                                label="Total Revenue"
                                                {...getFieldProps('totalRevenue')}
                                                InputProps={{
                                                    readOnly: true,
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                }}
                                                error={Boolean((touched.totalRevenue && errors.totalRevenue) || (errorData && errorData.total_revenue))}
                                                helperText={(touched.totalRevenue && errors.totalRevenue) || (errorData && errorData.total_revenue)}
                                            />
                                        )}
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={4} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <TextField
                                                fullWidth
                                                className="text-input"
                                                select
                                                label="Payment Status"
                                                value={formik1.values.paymentStatus}
                                                onChange={(e) => formik1.setFieldValue('paymentStatus', e.target.value)}
                                                error={
                                                    Boolean(formik1.touched.paymentStatus && formik1.errors.paymentStatus) ||
                                                    (errorData && errorData.payment_status)
                                                }
                                                helperText={
                                                    (formik1.touched.paymentStatus && formik1.errors.paymentStatus) || (errorData && errorData.payment_status)
                                                }
                                            >
                                                <MenuItem value="pending">Pending</MenuItem>
                                                <MenuItem value="paid">Paid</MenuItem>
                                                <MenuItem value="rejected">Rejected</MenuItem>
                                            </TextField>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SubCard>

                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3} sx={{ mb: '4.1rem' }}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            height: '40px',
                                            fontWeight: '500',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Additional Settlement Information
                                    </Typography>
                                </Grid>
                            </Grid>

                            {expenseList.length > 0 &&
                                (isMobile ? (
                                    <>
                                        {values.expenses.map((expense, index) => (
                                            <Card
                                                key={index}
                                                sx={{
                                                    marginBottom: '1.5rem',
                                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                    borderRadius: '15px'
                                                }}
                                            >
                                                <CardContent>
                                                    <AnimatePresence>
                                                        <motion.div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                alignItems: 'flex-start',
                                                                flexDirection: 'column',
                                                                gap: '.4rem'
                                                            }}
                                                            initial={{ scale: 1, opacity: 0 }}
                                                            animate={{ scale: 1.05, opacity: 1 }}
                                                            exit={{ scale: 1, opacity: 0.5 }}
                                                        >
                                                            <Box
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center',
                                                                    width: '100%',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        borderRight: '1px solid #80808052',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        width: '20%'
                                                                    }}
                                                                >
                                                                    {values.expenses.length == index + 1 ? (
                                                                        <AddIcon
                                                                            sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%' }}
                                                                            onClick={() => setAddNew((prev) => !prev)}
                                                                        />
                                                                    ) : (
                                                                        <ViewCompactIcon />
                                                                    )}
                                                                </div>

                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                                    {!(
                                                                        expense.exp_name == 'Dispatcher Expense' ||
                                                                        expense.exp_name == 'Insurance Expense' ||
                                                                        expense.exp_name == 'Fuel Expense'
                                                                    ) && (
                                                                        <RemoveIcon
                                                                            sx={{ backgroundColor: 'red', color: 'white', borderRadius: '50%' }}
                                                                            onClick={() => handleRemove(index)}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Box>
                                                            <Box sx={{ clear: 'both' }} />
                                                            <Typography
                                                                sx={{
                                                                    marginBottom: '.5rem',
                                                                    display: 'flex',
                                                                    gap: '.4rem',
                                                                    alignItems: 'center',
                                                                    // whiteSpace: 'nowrap'
                                                                    whiteSpace: 'balance'
                                                                }}
                                                            >
                                                                Expense Type : {expense.exp_name}
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    marginBottom: '.5rem',
                                                                    display: 'flex',
                                                                    gap: '.4rem',
                                                                    alignItems: 'center',
                                                                    whiteSpace: 'nowrap'
                                                                }}
                                                            >
                                                                Amount :{' '}
                                                                <input
                                                                    type="number"
                                                                    onChange={(e) => {
                                                                        const updatedExpenses = [...values.expenses];
                                                                        updatedExpenses[index].amount = Number(e.target.value); // Parsing value to float, defaulting to 0 if parsing fails
                                                                        setExpenseList(updatedExpenses);
                                                                    }}
                                                                    value={
                                                                        Number.isInteger(expense.amount)
                                                                            ? expense.amount
                                                                            : parseFloat(expense.amount).toFixed(2)
                                                                    }
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        width: '100%',
                                                                        border: '1px solid #80808052',
                                                                        // borderWidth: '1px',
                                                                        // borderLeft: 0,
                                                                        // borderRight: 0,
                                                                        // borderColor: '#80808052',
                                                                        outline: 0,
                                                                        padding: '5px 0'
                                                                    }}
                                                                />
                                                                {expense.is_percent && <span style={{ position: 'absolute', right: 5 }}>%</span>}
                                                            </Typography>
                                                        </motion.div>
                                                    </AnimatePresence>
                                                </CardContent>
                                            </Card>
                                        ))}
                                        {addNew && (
                                            <Card
                                                sx={{
                                                    marginBottom: '1.5rem',
                                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                    borderRadius: '15px'
                                                }}
                                            >
                                                <CardContent>
                                                    <AnimatePresence>
                                                        <motion.div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                alignItems: 'flex-start',
                                                                flexDirection: 'column',
                                                                gap: '.4rem'
                                                            }}
                                                            initial={{ scale: 1, opacity: 0 }}
                                                            animate={{ scale: 1.05, opacity: 1 }}
                                                            exit={{ scale: 1, opacity: 0.5 }}
                                                        >
                                                            <Box
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center',
                                                                    width: '100%',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        borderRight: '1px solid #80808052',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        width: '20%'
                                                                    }}
                                                                >
                                                                    <AddIcon
                                                                        sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%' }}
                                                                        onClick={() => console.log('Added')}
                                                                    />
                                                                </div>

                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                                    <RemoveIcon
                                                                        sx={{ backgroundColor: 'red', color: 'white', borderRadius: '50%' }}
                                                                        onClick={() => setAddNew(false)}
                                                                    />
                                                                </div>
                                                            </Box>
                                                            <Box sx={{ clear: 'both' }} />
                                                            <Typography
                                                                sx={{
                                                                    marginBottom: '.5rem',
                                                                    display: 'flex',
                                                                    gap: '.4rem',
                                                                    alignItems: 'center',
                                                                    // whiteSpace: 'nowrap'
                                                                    whiteSpace: 'balance'
                                                                }}
                                                            >
                                                                Expense Type :{' '}
                                                                <div
                                                                    style={{
                                                                        borderRight: '1px solid #80808052',
                                                                        display: 'flex',
                                                                        justifyContent: 'end',
                                                                        alignItems: 'center',
                                                                        width: '25%',
                                                                        position: 'relative',
                                                                        backgroundColor: '#80808040'
                                                                        // paddingRight: 10
                                                                    }}
                                                                >
                                                                    {expenseOptionOpen ? (
                                                                        <KeyboardArrowUpIcon
                                                                            style={{ color: 'black', width: '100%' }}
                                                                            onClick={() => setExpenseOptionOpen(!expenseOptionOpen)}
                                                                        />
                                                                    ) : (
                                                                        <KeyboardArrowDownIcon
                                                                            style={{ color: 'black', width: '100%' }}
                                                                            onClick={() => setExpenseOptionOpen(!expenseOptionOpen)}
                                                                        />
                                                                    )}

                                                                    {expenseOptionOpen && (
                                                                        <div
                                                                            style={{
                                                                                position: 'absolute',
                                                                                bottom: 39,
                                                                                left: 0,
                                                                                backgroundColor: 'white',
                                                                                padding: 10,
                                                                                borderRadius: 5,
                                                                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                                                width: '100%',
                                                                                zIndex: 1
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                style={{ color: 'orange' }}
                                                                                onClick={() => {
                                                                                    setExpenseOpen(true);
                                                                                }}
                                                                            >
                                                                                Add new...
                                                                            </Button>
                                                                            {expenseList.map((expense, index) => {
                                                                                if (
                                                                                    !values.expenses.find(
                                                                                        (item) => item.exp_item_type_id === expense.exp_item_type_id
                                                                                    )
                                                                                ) {
                                                                                    return (
                                                                                        <button
                                                                                            key={index}
                                                                                            style={{
                                                                                                display: 'block',
                                                                                                width: '100%',
                                                                                                border: 0,
                                                                                                outline: 0,
                                                                                                boxShadow:
                                                                                                    'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                formik.setValues((prevValues) => ({
                                                                                                    ...prevValues,
                                                                                                    expenses: [...values.expenses, expense]
                                                                                                }));
                                                                                                setExpenseOptionOpen(false);
                                                                                            }}
                                                                                        >
                                                                                            {expense.exp_name}
                                                                                        </button>
                                                                                    );
                                                                                }
                                                                                return null;
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    marginBottom: '.5rem',
                                                                    display: 'flex',
                                                                    gap: '.4rem',
                                                                    alignItems: 'center',
                                                                    whiteSpace: 'nowrap'
                                                                }}
                                                            >
                                                                Amount :{' '}
                                                                <div
                                                                    style={{
                                                                        borderRight: '1px solid #80808052',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        width: '25%',
                                                                        position: 'relative'
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        style={{
                                                                            textAlign: 'center',
                                                                            width: '100%',
                                                                            borderWidth: '1px',
                                                                            borderLeft: 0,
                                                                            borderRight: 0,
                                                                            borderColor: '#80808052',
                                                                            outline: 0,
                                                                            padding: '6px 0'
                                                                        }}
                                                                        placeholder="0"
                                                                    />
                                                                </div>
                                                            </Typography>
                                                        </motion.div>
                                                    </AnimatePresence>
                                                </CardContent>
                                            </Card>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                borderBottom: '1px solid #80808052',
                                                display: 'flex',
                                                justifyContent: 'space-evenly',
                                                padding: '10px 0'
                                            }}
                                        >
                                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '20%' }}></div>
                                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}>#</div>
                                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Expense Type</div>
                                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Amount</div>
                                            <div style={{ textAlign: 'center', width: '20%' }}></div>
                                        </div>
                                        <AnimatePresence>
                                            {values.expenses.map((expense, index) => (
                                                <motion.div
                                                    style={{
                                                        borderBottom: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly',
                                                        padding: '10px 0'
                                                    }}
                                                    initial={{ scale: 0, opacity: 0, height: 0 }}
                                                    animate={{ scale: 1, opacity: 1, height: 'auto' }}
                                                    exit={{ scale: 0, backgroundColor: 'gray', opacity: 0.5, height: 0 }}
                                                    key={expense.exp_item_type_id}
                                                >
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '20%'
                                                        }}
                                                    >
                                                        {values.expenses.length == index + 1 ? (
                                                            <AddIcon
                                                                sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%' }}
                                                                onClick={() => setAddNew((prev) => !prev)}
                                                            />
                                                        ) : (
                                                            <ViewCompactIcon />
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '10%'
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%'
                                                        }}
                                                    >
                                                        {expense.exp_name}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="number"
                                                            onChange={(e) => {
                                                                const updatedExpenses = [...values.expenses];
                                                                updatedExpenses[index].amount = Number(e.target.value); // Parsing value to float, defaulting to 0 if parsing fails
                                                                setExpenseList(updatedExpenses);
                                                            }}
                                                            value={Number.isInteger(expense.amount) ? expense.amount : parseFloat(expense.amount).toFixed(2)}
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                borderWidth: '1px',
                                                                borderLeft: 0,
                                                                borderRight: 0,
                                                                borderColor: '#80808052',
                                                                outline: 0,
                                                                padding: '5px 0'
                                                            }}
                                                        />
                                                        {expense.is_percent && <span style={{ position: 'absolute', right: 5 }}>%</span>}
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                        {!(
                                                            expense.exp_name == 'Dispatcher Expense' ||
                                                            expense.exp_name == 'Insurance Expense' ||
                                                            expense.exp_name == 'Fuel Expense'
                                                        ) && (
                                                            <RemoveIcon
                                                                sx={{ backgroundColor: 'red', color: 'white', borderRadius: '50%' }}
                                                                onClick={() => handleRemove(index)}
                                                            />
                                                        )}
                                                    </div>
                                                </motion.div>
                                            ))}
                                        </AnimatePresence>
                                        <AnimatePresence>
                                            {addNew && (
                                                <motion.div
                                                    style={{
                                                        borderBottom: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly',
                                                        padding: '10px 0'
                                                    }}
                                                    initial={{ opacity: 0, backgroundColor: 'green', height: 0 }}
                                                    animate={{ opacity: 1, backgroundColor: 'white', height: 50 }}
                                                    exit={{ opacity: 0, backgroundColor: 'red', height: 0 }}
                                                >
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '20%'
                                                        }}
                                                    >
                                                        <AddIcon
                                                            sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%' }}
                                                            onClick={() => console.log('Added')}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '10%'
                                                        }}
                                                    >
                                                        {values.expenses.length + 1}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'end',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative',
                                                            backgroundColor: '#80808040'
                                                            // paddingRight: 10
                                                        }}
                                                    >
                                                        {expenseOptionOpen ? (
                                                            <KeyboardArrowUpIcon
                                                                style={{ color: 'black', width: '100%' }}
                                                                onClick={() => setExpenseOptionOpen(!expenseOptionOpen)}
                                                            />
                                                        ) : (
                                                            <KeyboardArrowDownIcon
                                                                style={{ color: 'black', width: '100%' }}
                                                                onClick={() => setExpenseOptionOpen(!expenseOptionOpen)}
                                                            />
                                                        )}

                                                        {expenseOptionOpen && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: 39,
                                                                    left: 0,
                                                                    backgroundColor: 'white',
                                                                    padding: 10,
                                                                    borderRadius: 5,
                                                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                                    width: '100%',
                                                                    zIndex: 1
                                                                }}
                                                            >
                                                                <Button
                                                                    style={{ color: 'orange' }}
                                                                    onClick={() => {
                                                                        setExpenseOpen(true);
                                                                    }}
                                                                >
                                                                    Add new...
                                                                </Button>
                                                                {expenseList.map((expense, index) => {
                                                                    if (!values.expenses.find((item) => item.exp_item_type_id === expense.exp_item_type_id)) {
                                                                        return (
                                                                            <button
                                                                                key={index}
                                                                                style={{
                                                                                    display: 'block',
                                                                                    width: '100%',
                                                                                    border: 0,
                                                                                    outline: 0,
                                                                                    boxShadow:
                                                                                        'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                                                                }}
                                                                                onClick={() => {
                                                                                    formik.setValues((prevValues) => ({
                                                                                        ...prevValues,
                                                                                        expenses: [...values.expenses, expense]
                                                                                    }));
                                                                                    setExpenseOptionOpen(false);
                                                                                }}
                                                                            >
                                                                                {expense.exp_name}
                                                                            </button>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                borderWidth: '1px',
                                                                borderLeft: 0,
                                                                borderRight: 0,
                                                                borderColor: '#80808052',
                                                                outline: 0,
                                                                padding: '6px 0'
                                                            }}
                                                            placeholder="0"
                                                        />
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                        <RemoveIcon
                                                            sx={{ backgroundColor: 'red', color: 'white', borderRadius: '50%' }}
                                                            onClick={() => setAddNew(false)}
                                                        />
                                                    </div>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </>
                                ))}
                            {/* <FieldArray name="expenses">
                                {({ push, remove }) => (
                                    <>
                                        {values.expenses.map((i, index) => (
                                            <Grid container spacing={3} key={`exp-${index}`} sx={{ mt: 2 }}>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    {loading ? (
                                                        <Skeleton height="50px" />
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                fontSize: '18px',
                                                                fontFamily: 'Lexend'
                                                            }}
                                                        >
                                                            {values.expenses[index].exp_name}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        className="text-input"
                                                        color="orange"
                                                        type="text"
                                                        label="Amount"
                                                        onInput={NumberOnlyHandler}
                                                        value={values.expenses[index].amount}
                                                        onChange={(e) => {
                                                            setFieldValue(`expenses[${index}].amount`, e.target.value);
                                                        }}
                                                        InputProps={
                                                            values.expenses[index].is_percent
                                                                ? { endAdornment: <InputAdornment position="end">%</InputAdornment> }
                                                                : { startAdornment: <InputAdornment position="start">$</InputAdornment> }
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </FieldArray> */}
                        </SubCard>

                        <SubCard title="Drivers Income" sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <FieldArray name="pickup">
                                {({ push, remove }) => {
                                    return (
                                        <>
                                            {values.drvSplit.map((i, index) => {
                                                const earn =
                                                    i.stlm_type === 'split'
                                                        ? ((values.netIncome * values.drvSplit[index].percent) / 100).toFixed(2) -
                                                          values.drvSplit[index].deduction
                                                        : i.stlm_type === 'mileage'
                                                        ? (values.drvSplit[index].mileage * values.drvSplit[index].mileage_multiplier).toFixed(2) -
                                                          values.drvSplit[index].deduction
                                                        : values.drvSplit[index].income - values.drvSplit[index].deduction;

                                                return (
                                                    <div key={`split-${index}`}>
                                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    borderRadius: '50%',
                                                                    backgroundColor: theme.palette.orange.main,
                                                                    color: '#ffffff',
                                                                    px: '10px',
                                                                    py: '6px'
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    ml: 1,
                                                                    mt: 0.8,

                                                                    width: '375px',
                                                                    height: '40px',
                                                                    fontWeight: '500',
                                                                    fontSize: '24px',
                                                                    fontFamily: 'Lexend'
                                                                }}
                                                            >
                                                                {i.driver_name !== '' ? i.driver_name : i.owner_name}
                                                            </Typography>
                                                        </Stack>
                                                        <Grid container spacing={5} alignItems={'end'}>
                                                            {i.stlm_type === 'split' && (
                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                    {loading ? (
                                                                        <Skeleton height="50px" />
                                                                    ) : (
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Split Percent"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].percent}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`drvSplit[${index}].percent`, e.target.value);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            )}

                                                            {i.stlm_type === 'mileage' && (
                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                    {loading ? (
                                                                        <Skeleton height="50px" />
                                                                    ) : (
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Mileage"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].mileage}
                                                                            onChange={(e) => setFieldValue(`drvSplit[${index}].mileage`, e.target.value)}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            )}

                                                            {i.stlm_type === 'mileage' && (
                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                    {loading ? (
                                                                        <Skeleton height="50px" />
                                                                    ) : (
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Mileage Multiplier"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].mileage_multiplier}
                                                                            onChange={(e) =>
                                                                                setFieldValue(`drvSplit[${index}].mileage_multiplier`, e.target.value)
                                                                            }
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            )}

                                                            {i.stlm_type === 'flat' && (
                                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                    {loading ? (
                                                                        <Skeleton height="50px" />
                                                                    ) : (
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Income"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].income}
                                                                            onChange={(e) => setFieldValue(`drvSplit[${index}].income`, e.target.value)}
                                                                            sx={{ mt: '1rem' }}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            )}
                                                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Deduction"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].deduction}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`drvSplit[${index}].deduction`, e.target.value);
                                                                            }}
                                                                        />
                                                                        {values.drvSplit[index].deduction > 0 && (
                                                                            <TextField
                                                                                multiline
                                                                                sx={{ mt: 2 }}
                                                                                minRows={2}
                                                                                fullWidth
                                                                                className="text-input"
                                                                                color="orange"
                                                                                type="text"
                                                                                label="Reason"
                                                                                value={values.drvSplit[index].deduction_reason}
                                                                                onChange={(e) =>
                                                                                    setFieldValue(`drvSplit[${index}].deduction_reason`, e.target.value)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Bonus"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].bonus_amt}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`drvSplit[${index}].bonus_amt`, e.target.value);
                                                                            }}
                                                                        />
                                                                        {values.drvSplit[index].bonus_amt > 0 && (
                                                                            <TextField
                                                                                multiline
                                                                                sx={{ mt: 2 }}
                                                                                minRows={2}
                                                                                fullWidth
                                                                                className="text-input"
                                                                                color="orange"
                                                                                type="text"
                                                                                label="Bonus Explanation"
                                                                                value={values.drvSplit[index].bonus_reason}
                                                                                onChange={(e) =>
                                                                                    setFieldValue(`drvSplit[${index}].bonus_reason`, e.target.value)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Reimbursement"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.drvSplit[index].rmb_amt}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`drvSplit[${index}].rmb_amt`, e.target.value);
                                                                            }}
                                                                        />
                                                                        {values.drvSplit[index].rmb_amt > 0 && (
                                                                            <TextField
                                                                                multiline
                                                                                sx={{ mt: 2 }}
                                                                                minRows={2}
                                                                                fullWidth
                                                                                className="text-input"
                                                                                color="orange"
                                                                                type="text"
                                                                                label="Reimbursement Explanation"
                                                                                value={values.drvSplit[index].rmb_reason}
                                                                                onChange={(e) => setFieldValue(`drvSplit[${index}].rmb_reason`, e.target.value)}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    );
                                }}
                            </FieldArray>
                        </SubCard>

                        <SubCard title="Owner Income" sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <FieldArray name="pickup">
                                {({ push, remove }) => {
                                    return (
                                        <>
                                            {values.ownerSplit.map((i, index) => {
                                                let earn = 0;
                                                let sum = 0;
                                                let ddc = 0;

                                                if (i.stlm_type === 'split') {
                                                    sum = values.drvSplit.reduce((accumulator, currentValue) => {
                                                        return Number(accumulator) + Number(currentValue.percent);
                                                    }, 0);

                                                    ddc = values.drvSplit.reduce((accumulator, currentValue) => {
                                                        return Number(accumulator) + Number(currentValue.deduction);
                                                    }, 0);

                                                    {
                                                        /* earn = ((values.netIncome * sum) / 100).toFixed(2); */
                                                    }
                                                    earn = values.netIncome - ((values.netIncome * sum) / 100 - ddc).toFixed(2);
                                                } else if (i.stlm_type === 'mileage') {
                                                    sum = values.drvSplit.reduce((accumulator, currentValue) => {
                                                        return Number(accumulator) + Number(currentValue.mileage * currentValue.mileage_multiplier);
                                                    }, 0);

                                                    ddc = values.drvSplit.reduce((accumulator, currentValue) => {
                                                        return Number(accumulator) + Number(currentValue.deduction);
                                                    }, 0);

                                                    earn = values.netIncome - sum - ddc;
                                                } else if (i.stlm_type === 'flat') {
                                                    sum = values.drvSplit.reduce((accumulator, currentValue) => {
                                                        return Number(accumulator) + Number(currentValue.income);
                                                    }, 0);
                                                    ddc = values.drvSplit.reduce((accumulator, currentValue) => {
                                                        return Number(accumulator) + Number(currentValue.deduction);
                                                    }, 0);
                                                    earn = values.netIncome - sum - ddc;
                                                } else {
                                                    earn = 0;
                                                }

                                                {
                                                }

                                                return (
                                                    <div key={`split-${index}`}>
                                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    borderRadius: '50%',
                                                                    backgroundColor: theme.palette.orange.main,
                                                                    color: '#ffffff',
                                                                    px: '10px',
                                                                    py: '6px'
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    ml: 1,
                                                                    mt: 0.8,

                                                                    width: '375px',
                                                                    height: '40px',
                                                                    fontWeight: '500',
                                                                    fontSize: '24px',
                                                                    fontFamily: 'Lexend'
                                                                }}
                                                            >
                                                                {i.owner_name}
                                                            </Typography>
                                                        </Stack>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <>
                                                                        <TextField
                                                                            fullWidth
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="Deduction"
                                                                            onInput={NumberOnlyHandler}
                                                                            value={values.ownerSplit[index].deduction}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`ownerSplit[${index}].deduction`, e.target.value);
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <>
                                                                        {values.ownerSplit[index].deduction > 0 && (
                                                                            <TextField
                                                                                multiline
                                                                                // minRows={3}
                                                                                fullWidth
                                                                                className="text-input"
                                                                                color="orange"
                                                                                type="text"
                                                                                label="Reason"
                                                                                value={values.ownerSplit[index].deduction_reason}
                                                                                onChange={(e) =>
                                                                                    setFieldValue(`ownerSplit[${index}].deduction_reason`, e.target.value)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    );
                                }}
                            </FieldArray>
                        </SubCard>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <LoadingButton
                                loading={formik.isSubmitting}
                                onClick={() => formik.submitForm()}
                                variant="contained"
                                color="orange"
                                sx={{ color: 'white' }}
                            >
                                Preview
                            </LoadingButton>
                        </Grid>
                    </Form>
                </FormikProvider>
            </SubCard>

            <SubCard title="Fuel Expenses" sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                {loading ? (
                    <Skeleton height="50px" />
                ) : isMobile ? (
                    <>
                        {/* {selected &&
                            selected.map((driver, idx) => ( */}
                        <Card
                            // key={driver.driver_id}
                            sx={{
                                marginBottom: '1.5rem',
                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                borderRadius: '15px'
                                // backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                            }}
                        >
                            <CardContent>
                                {/* <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                            <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>

                                            <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                                {(popupState) => (
                                                    <div style={{ float: 'right', marginTop: '1px' }}>
                                                        <IconButton {...bindTrigger(popupState)} size="large">
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                        <Menu
                                                            {...bindMenu(popupState)}
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                        >
                                                            {(permissions.dispatcheradmin && permissions.dispatcheradmin.change) ||
                                                            user_types.includes(user_type) ? (
                                                                <MenuItem onClick={() => editUser(driver.dis_admin_id)}>Edit User</MenuItem>
                                                            ) : null}
                                                            <MenuItem onClick={() => viewUser(driver.dis_admin_id)}>View User</MenuItem>
                                                            {(permissions.dispatcheradmin && permissions.dispatcheradmin.delete) ||
                                                            user_types.includes(user_type) ? (
                                                                <MenuItem onClick={() => deleteMember(driver.dis_admin_id)}>Remove User</MenuItem>
                                                            ) : null}
                                                        </Menu>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </Box> */}

                                <Box sx={{ clear: 'both' }} />

                                {/* <Typography sx={{ marginBottom: '.5rem' }}>Date : {driver.transaction_date}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Invoice : {driver.invoice}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Total Gallons : {driver.total_gallons}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Total Amount : {driver.total_amount}</Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>
                                    Location : {driver.location_name}, {driver.city_name} {driver.state_name}
                                </Typography> */}
                                <Typography sx={{ marginBottom: '.5rem' }}>Date : </Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Invoice : </Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Total Gallons : </Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Total Amount : </Typography>
                                <Typography sx={{ marginBottom: '.5rem' }}>Location :</Typography>
                            </CardContent>
                        </Card>
                        {/* ))} */}
                    </>
                ) : isTablet ? (
                    <>
                        {/* {loading ? ( */}
                        {/* // <> */}
                        {/* <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center"> */}
                        {/* <CircularProgress /> */}
                        {/* </Box> */}
                        {/* </> */}
                        {/* // ) : (
                            // selected &&
                            // selected?.map((driver, idx) => ( */}
                        <Card
                            // key={driver.driver_id}
                            sx={{
                                marginTop: '2rem',
                                marginBottom: '1.5rem',
                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                borderRadius: '15px'
                                // backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                            }}
                        >
                            <CardContent>
                                {/* <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                            <Box sx={{ float: 'left' }}>{driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}</Box>

                                            <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                                {(popupState) => (
                                                    <div style={{ float: 'right', marginTop: '1px' }}>
                                                        <IconButton {...bindTrigger(popupState)} size="large">
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                        <Menu
                                                            {...bindMenu(popupState)}
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                        >
                                                            {(permissions.dispatcheradmin && permissions.dispatcheradmin.change) ||
                                                            user_types.includes(user_type) ? (
                                                                <MenuItem onClick={() => editUser(driver.dis_admin_id)}>Edit User</MenuItem>
                                                            ) : null}
                                                            <MenuItem onClick={() => viewUser(driver.dis_admin_id)}>View User</MenuItem>
                                                            {(permissions.dispatcheradmin && permissions.dispatcheradmin.delete) ||
                                                            user_types.includes(user_type) ? (
                                                                <MenuItem onClick={() => deleteMember(driver.dis_admin_id)}>Remove User</MenuItem>
                                                            ) : null}
                                                        </Menu>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </Box> */}

                                {/* </Box> */}
                                <Box sx={{ clear: 'both' }} />

                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                        gap: '0.5rem',
                                        marginBottom: '0.5rem'
                                    }}
                                >
                                    <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}> Date : </Typography>

                                    <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Invoice : </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                        gap: '0.5rem',
                                        marginBottom: '0.5rem'
                                    }}
                                >
                                    <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Total Gallons : </Typography>
                                    <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Total Amount : </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                        gap: '0.5rem',
                                        marginBottom: '0.5rem'
                                    }}
                                >
                                    <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Location : </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                        {/* // ) */}
                    </>
                ) : (
                    <MaterialTable
                        // tableRef={tableRef}
                        style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                        data={(query) =>
                            new Promise((resolve, reject) => {
                                fetch(`/v1/api/accounting/fuel-reports/?page=1&page_size=100&stlm_id=${id}`, {
                                    method: 'get',

                                    headers: new Headers({
                                        'Content-type': 'application/json',
                                        Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                                    })
                                })
                                    .then((response) => response.json())
                                    .then((result) => {
                                        setFuelList(result.results);
                                        resolve({
                                            data: result.results,
                                            page: query.page,
                                            totalCount: result.count
                                        });
                                    });
                            })
                        }
                        title=""
                        columns={[]}
                        icons={TABLE_ICONS}
                        components={{ Row: renderFuelRow, Header: renderFuelHeader }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: <CustomNoRowsOverlay />
                            }
                        }}
                        options={{
                            selection: false,
                            paging: false,
                            filtering: false,
                            search: false
                        }}
                    />
                )}
            </SubCard>
            <SubCard title="Loads Delivered" sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                {loading ? (
                    <Skeleton height="50px" />
                ) : (
                    <>
                        {isMobile ? (
                            <>
                                {loading ? (
                                    <>
                                        <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                            <CircularProgress />
                                        </Box>
                                    </>
                                ) : (
                                    loadData &&
                                    loadData.map((driver, idx) => (
                                        <Card
                                            key={driver.driver_id}
                                            sx={{
                                                marginBottom: '1.5rem',
                                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                borderRadius: '15px',
                                                backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                            }}
                                        >
                                            <CardContent>
                                                <Box sx={{ clear: 'both' }} />

                                                <Typography sx={{ marginBottom: '.5rem' }}>Load : {driver.load}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Pickup Date : {driver.pickup.date}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Delivery Date : {driver.delivery.date}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>From : {driver.pickup.location}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>To : {driver.delivery.location}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Revenue : {driver.revenue}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Driver Name : {driver.driver_name}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Customer Name : {driver.cust_name}</Typography>
                                            </CardContent>
                                        </Card>
                                    ))
                                )}
                            </>
                        ) : isTablet ? (
                            loadData &&
                            loadData?.map((driver, idx) => (
                                <Card
                                    key={driver.driver_id}
                                    sx={{
                                        marginTop: '2rem',
                                        marginBottom: '1.5rem',
                                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                        borderRadius: '15px',
                                        backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                    }}
                                >
                                    <CardContent>
                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                            }}
                                        >
                                            <Typography sx={{ marginBottom: '.5rem' }}>Load : {driver.load}</Typography>
                                            <Typography sx={{ marginBottom: '.5rem' }}>Revenue : {driver.revenue}</Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                            }}
                                        >
                                            <Typography sx={{ marginBottom: '.5rem' }}>Pickup Date : {driver.pickup.date}</Typography>
                                            <Typography sx={{ marginBottom: '.5rem' }}>Delivery Date : {driver.delivery.date}</Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                            }}
                                        >
                                            <Typography sx={{ marginBottom: '.5rem' }}>From : {driver.pickup.location}</Typography>
                                            <Typography sx={{ marginBottom: '.5rem' }}>To : {driver.delivery.location}</Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                            }}
                                        >
                                            <Typography sx={{ marginBottom: '.5rem' }}>Driver Name : {driver.driver_name}</Typography>
                                            <Typography sx={{ marginBottom: '.5rem' }}>Customer Name : {driver.cust_name}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))
                        ) : (
                            <MaterialTable
                                tableRef={tableRef}
                                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                                // data={(query) =>
                                //     new Promise((resolve, reject) => {
                                //         fetchLoadData(query)
                                //             .then((result) => resolve(result))
                                //             .catch((error) => reject(error));
                                //     })
                                // }
                                data={(query) =>
                                    new Promise((resolve, reject) => {
                                        fetch(
                                            `/v1/api/dispatch/load/list?page=1&page_size=100&company_id=${
                                                loginInfoFromStorage.company_id
                                            }&bol_status=&load_status=Delivered&invoice_status=&created_start=&created_end=&price_start=&price_end=&pickup_start=${
                                                settlementData && formik1.values.startDate !== ''
                                                    ? format(new Date(formik1.values.startDate), 'yyyy-MM-dd')
                                                    : settlementData.start_date
                                            }&pickup_end=&delivery_start=&delivery_end=${
                                                settlementData && formik1.values.endDate !== ''
                                                    ? format(new Date(formik1.values.endDate), 'yyyy-MM-dd')
                                                    : settlementData.end_date
                                            }&driver=${formik1.values.driver.driver_id}`,
                                            {
                                                method: 'get',

                                                headers: new Headers({
                                                    'Content-type': 'application/json',
                                                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                                                })
                                            }
                                        )
                                            .then((response) => response.json())
                                            .then((result) => {
                                                setFuelList(result.results);
                                                resolve({
                                                    data: result.results,
                                                    page: query.page,
                                                    totalCount: result.count
                                                });
                                            });
                                    })
                                }
                                title=""
                                columns={[]}
                                icons={TABLE_ICONS}
                                // components={{ Row: renderFuelRow, Header: renderFuelHeader }}
                                components={{ Row: renderRow, Header: renderHeader }}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                                    }
                                }}
                                options={{
                                    selection: false,
                                    paging: false,
                                    filtering: false,
                                    search: false
                                }}
                            />
                        )}
                    </>
                )}
            </SubCard>

            <PreViewSettlememntModal open={open} handleCloseModal={handleCloseModal} truckId={truckId} stlmData={stlmData} id={id} maxWidth="lg" />

            {/* add new expense item modal start */}
            <Dialog
                fullWidth
                open={expenseOpen}
                onClose={() => {
                    expenseFormik.handleReset();
                    setExpenseOpen(false);
                }}
                scroll="paper"
            >
                <DialogTitle>
                    <Typography variant="h4" color="primary">
                        Expense Type
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <>
                        <FormikProvider value={expenseFormik}>
                            <Form autoComplete="off" onSubmit={expenseFormik.handleSubmit} encType="multipart/form-data">
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton variant="rectangular" width="100%" height="50px" />
                                        ) : (
                                            <TextField
                                                fullWidth
                                                className="text-input"
                                                color="orange"
                                                type="text"
                                                label="Type Name"
                                                name="FileName"
                                                value={expenseFormik.values.FileName}
                                                onChange={expenseFormik.handleChange}
                                                onBlur={expenseFormik.handleBlur}
                                                error={Boolean(expenseFormik.touched.FileName && expenseFormik.errors.FileName)}
                                                helperText={expenseFormik.touched.FileName && expenseFormik.errors.FileName}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormLabel id="demo-radio-buttons-group-label" className="radio-label">
                                            Default Value type?
                                        </FormLabel>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                row
                                                className="radio-group"
                                                value={expenseFormik.values.isPercent}
                                                onChange={(e) => expenseFormik.setFieldValue('isPercent', e.target.value == 'true')}
                                            >
                                                <FormControlLabel value={true} control={<Radio />} label="Percent" />
                                                <FormControlLabel value={false} control={<Radio />} label="Flat" />
                                            </RadioGroup>
                                        )}
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton variant="rectangular" width="100%" height="50px" />
                                        ) : (
                                            <TextField
                                                fullWidth
                                                className="text-input"
                                                color="orange"
                                                type="text"
                                                label="Default Value"
                                                name="default"
                                                onChange={(e) => {
                                                    expenseFormik.setFieldValue('default', !isNaN(e.target.value) ? e.target.value : 0);
                                                }}
                                                error={Boolean(expenseFormik.touched.default && expenseFormik.errors.default)}
                                                helperText={expenseFormik.touched.default && expenseFormik.errors.default}
                                            />
                                        )}
                                    </Grid>
                                </Grid>

                                <Box sx={{ mt: 2 }} align="center">
                                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                                        <LoadingButton
                                            type="submit"
                                            loading={expenseFormik.isSubmitting}
                                            color="orange"
                                            variant="contained"
                                            sx={{ color: 'white' }}
                                        >
                                            Save
                                        </LoadingButton>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                mr: 2,

                                                backgroundColor: '#EB812E',
                                                color: 'white',
                                                fontFamily: 'Lexend',
                                                fontWeight: 400,
                                                lineHeight: '20px',

                                                fontStyle: 'normal',

                                                height: '36px',
                                                fontSize: '16px',
                                                borderRadius: '4px'
                                            }}
                                            color="orange"
                                            onClick={() => {
                                                expenseFormik.handleReset();
                                                setExpenseOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                </DialogContent>
            </Dialog>
            {/* add new expense item modal end */}
        </>
    );
};

export default EditSettlementForm;
