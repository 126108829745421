import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, Button, Grid, IconButton, Skeleton, Typography } from '@mui/material';
const { format, parse } = require('date-fns');
import { Stack } from '@mui/system';
import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

import ViewFields from 'ui-component/ViewFields';
import EditIcon from '@mui/icons-material/Edit';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent } from '@mui/lab';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LoadModal from 'ui-component/modals/LoadModal';
import OtherDocModal from 'ui-component/modals/OtherDocModal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const ViewMyLoadForm = (props) => {
    const theme = useTheme();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [loading, setLoading] = useState();
    const [loadData, setLoadData] = useState();
    const { id } = useParams();

    const [alertOpen, setAlertOpen] = useState(true);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const handleALertClose = () => {
        setAlertOpen(false);
    };
    const editDocModalHanler = () => {
        setOpen(true);
    };

    const handleDocCloseModal = () => {
        setOpen(false);
        fetchLoad(id);
    };

    const editModalHanler = () => {
        setOpen1(true);
    };

    const handleCloseModal = () => {
        setOpen1(false);
        fetchLoad(id);
    };
    // const [origin, setOrigin] = useState({ lat: 37.7749, lng: -122.4194 }); // New York City

    useEffect(() => {
        if (id && !loadData) {
            fetchLoad(id);
        }
    }, [loadData, id]);

    const fetchLoad = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/dispatch/load/view/${id}/`, config);
            setLoadData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mt: 5 }}>
                    <Typography variant="h2">Load Details</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mt: 5 }}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={2}>
                        {loadData && loadData.bol_status === 0 ? (
                            <>
                                {alertOpen && (
                                    <Alert
                                        severity=""
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    handleALertClose();
                                                }}
                                            >
                                                <HighlightOffIcon
                                                    fontSize="small"
                                                    sx={{
                                                        backgroundColor: '#F48120',
                                                        borderColor: '#F48120',
                                                        borderRadius: '10px',
                                                        color: '#FFFFFF'
                                                    }}
                                                />
                                            </IconButton>
                                        }
                                        sx={{
                                            width: '350px',
                                            backgroundColor: '#FEFBE8',
                                            borderColor: '#F3DC41', // Add border color here
                                            borderWidth: '1px', // Optional: Set border width if needed
                                            borderStyle: 'solid',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        BOl status is pending!
                                    </Alert>
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            {/* Customer */}
                            {/* General Info */}

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h2">General Information</Typography>
                            </Grid>

                            {/* Customer */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? <Skeleton height="50px" /> : <ViewFields viewText={id && loadData && loadData.load} title="Load No." />}
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? <Skeleton height="50px" /> : <ViewFields viewText={id && loadData && loadData.cust_name} title="Customer" />}
                            </Grid>

                            {/* weight */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? <Skeleton height="50px" /> : <ViewFields viewText={id && loadData && loadData.revenue} title="Revenue" />}
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? <Skeleton height="50px" /> : <ViewFields viewText={id && loadData && loadData.weight} title="Weight" />}
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? <Skeleton height="50px" /> : <ViewFields viewText={id && loadData && loadData.mileage} title="Millage" />}
                            </Grid>
                            {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <ViewFields viewText={id && loadData && loadData.millage} title="Millage" />
                                        )}
                                    </Grid> */}
                        </Grid>
                    </SubCard>
                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            {/* Customer */}
                            {/* Vehicle Info */}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h2">Assign To Drive</Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? <Skeleton height="50px" /> : <ViewFields viewText={id && loadData && loadData.driver_name} title="Driver" />}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <ViewFields viewText={id && loadData && loadData.truck_license} title="License Plate" />
                                )}
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 3 }}>
                        <Typography variant="h2" sx={{ fontSize: '24px' }}>
                            Route Preview
                        </Typography>
                        <Button
                            variant="contained"
                            color="orange"
                            // onClick={() => navigate(`/dispatch-center/edit-load/${id}`)}
                            onClick={editModalHanler}
                            endIcon={<EditIcon />}
                            sx={{
                                '.MuiButton-endIcon': {
                                    marginRight: 0,
                                    marginLeft: 0
                                }
                            }}
                        />
                    </Stack>
                    {loading ? (
                        <Skeleton height="50px" />
                    ) : (
                        <Timeline
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0
                                }
                            }}
                        >
                            {loadData &&
                                loadData.loads &&
                                loadData.loads.map((i, index) => (
                                    <TimelineItem key={`${i.dsp_load_type_id}-${i.company_name}`}>
                                        <TimelineOppositeContent style={{ display: 'none' }} />

                                        <TimelineSeparator>
                                            <TimelineDot
                                                sx={{
                                                    height: '2rem',
                                                    width: '2rem',
                                                    padding: '6px 10px',
                                                    margin: '0',
                                                    backgroundColor: index === 0 ? '#DCE6FF' : '#FFFFFF',
                                                    color: '#000000'
                                                }}
                                            >
                                                <Typography> {index + 1}</Typography>
                                                {/* <span> {index + 1}</span> */}
                                            </TimelineDot>
                                            {index !== loadData.loads.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>

                                        <TimelineContent>
                                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <Typography variant="h5" sx={{ fontSize: '20px', mb: 2 }}>
                                                        {i.load_type}
                                                    </Typography>

                                                    <Typography variant="body1" color="primary" sx={{ fontSize: '14px', mb: 1 }}>
                                                        {i.city_name}, {i.state_name}
                                                    </Typography>
                                                    <Typography variant="body1" color="primary" sx={{ fontSize: '14px', mb: 1 }}>
                                                        {i.address}, {i.zip_code}
                                                    </Typography>
                                                    <Typography variant="body1" color="primary" spacing={2} sx={{ fontSize: '14px', mb: 1 }}>
                                                        {format(new Date(i.date + 'T00:00'), 'MMM d yyyy')}
                                                        <span> {format(parse(i.time, 'HH:mm:ss', new Date()), 'h:mm a')}</span>
                                                    </Typography>
                                                    <Typography variant="body1" color="primary" sx={{ fontSize: '14px', mb: 1 }}>
                                                        {i.company_name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                        </Timeline>
                    )}

                    <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 3 }}>
                        <Typography variant="h3"> File Manager ({loadData && loadData.docs.length} )</Typography>
                        <Button
                            variant="contained"
                            color="orange"
                            onClick={editDocModalHanler}
                            endIcon={<EditIcon />}
                            sx={{
                                '.MuiButton-endIcon': {
                                    marginRight: 0,
                                    marginLeft: 0
                                }
                            }}
                        />
                    </Stack>

                    {loadData &&
                        loadData.docs &&
                        loadData.docs.length > 0 &&
                        loadData.docs.map((i) => (
                            <Stack spacing={2}>
                                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                                    {i.oth_doc_name.split('.').pop() === 'jpeg' || i.oth_doc_name.split('.').pop() === 'jpg' ? (
                                        <ImageIcon size="meduim" />
                                    ) : (
                                        <PictureAsPdfIcon size="meduim" />
                                    )}
                                    <Typography variant="subtitle" sx={{ fontSize: '16px', mb: 2 }}>
                                        {i.oth_doc_name}
                                    </Typography>
                                </Stack>
                            </Stack>
                        ))}
                </Grid>
            </Grid>
            <LoadModal open={open1} handleClose={handleCloseModal} id={id} />
            <OtherDocModal open={open} handleClose={handleDocCloseModal} id={id} />
        </>
    );
};

export default ViewMyLoadForm;
