import { Divider, List, ListItemText, Stack, useTheme, Box, Switch, ListItem, TextField, Button, Popover } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PropTypes from 'prop-types';
function SettlementFilter({
    checked,
    handleChecked,

    startDoc,
    startDocHandler,
    endDoc,
    endDocHandler,
    startDop,
    startDopHandler,
    endDop,
    endDopHandler,
    startAmt,
    startAmtHandler,
    endAmt,
    endAmtHandler,
    filterHandler,

    reset
}) {
    const theme = useTheme();
    return (
        <PopupState variant="popover" popupId="filter-popup">
            {(popupState) => (
                <>
                    <Button
                        variant="contained"
                        sx={{
                            mr: 2,
                            color: 'white',
                            backgroundColor: '#EB812E',
                            minWidth: { xs: '17rem', sm: '10rem' },
                            maxHeight: { xs: '6vh', sm: '4vh' }
                        }}
                        color="orange"
                        startIcon={<FilterAltIcon />}
                        {...bindTrigger(popupState)}
                    >
                        FILTER
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                            {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                            <Box sx={{ px: 2 }}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        maxWidth: 350,
                                        minWidth: 300,
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            minWidth: '100%'
                                        },
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5
                                        }
                                    }}
                                >
                                    <ListItem>
                                        {/* <ListItemIcon><WifiIcon /></ListItemIcon> */}

                                        <ListItemText primary="Date of Creation" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('doc')}
                                            checked={checked.indexOf('doc') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('doc') !== -1 ? (
                                        <>
                                            <ListItem>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            className="text-input"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            label="Start Date"
                                                            value={startDoc}
                                                            onChange={startDocHandler}
                                                            renderInput={(params) => (
                                                                <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            className="text-input"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            label="End Date"
                                                            value={endDoc}
                                                            onChange={endDocHandler}
                                                            renderInput={(params) => (
                                                                <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Stack>

                                                <Divider sx={{ mt: 2 }} />
                                            </ListItem>
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    <ListItem>
                                        {/* <ListItemIcon><WifiIcon /></ListItemIcon> */}

                                        <ListItemText primary="Date of Payment" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('dop')}
                                            checked={checked.indexOf('dop') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('dop') !== -1 ? (
                                        <ListItem>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        className="text-input"
                                                        openTo="day"
                                                        views={['year', 'month', 'day']}
                                                        label="Start Date"
                                                        value={startDop}
                                                        onChange={startDopHandler}
                                                        renderInput={(params) => (
                                                            <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        className="text-input"
                                                        openTo="day"
                                                        views={['year', 'month', 'day']}
                                                        label="End Date"
                                                        value={endDop}
                                                        onChange={endDopHandler}
                                                        renderInput={(params) => (
                                                            <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Stack>

                                            <Divider sx={{ mt: 2 }} />
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}

                                    <ListItem>
                                        {/* <ListItemIcon><WifiIcon /></ListItemIcon> */}

                                        <ListItemText primary="Amount" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('doa')}
                                            checked={checked.indexOf('doa') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>

                                    {checked.indexOf('doa') !== -1 ? (
                                        <ListItem>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <TextField
                                                    fullWidth
                                                    className="text-input"
                                                    color="orange"
                                                    type="text"
                                                    label="Start Amount"
                                                    value={startAmt}
                                                    onChange={(e) => startAmtHandler(e)}
                                                />

                                                <TextField
                                                    fullWidth
                                                    className="text-input"
                                                    color="orange"
                                                    type="text"
                                                    label="End Amount"
                                                    value={endAmt}
                                                    onChange={(e) => endAmtHandler(e)}
                                                />
                                            </Stack>

                                            <Divider sx={{ mt: 2 }} />
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}

                                    <ListItem>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={20}>
                                            <Button variant="outlined" onClick={reset}>
                                                Reset
                                            </Button>
                                            <Button
                                                sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                                variant="contained"
                                                color="orange"
                                                onClick={() => {
                                                    filterHandler();
                                                    popupState.close();
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </Stack>

                                        <Divider sx={{ mt: 2 }} />
                                    </ListItem>
                                </List>
                            </Box>
                        </MainCard>
                    </Popover>
                </>
            )}
        </PopupState>
    );
}

SettlementFilter.propTypes = {
    handleClose: PropTypes.func,
    reloadHandler: PropTypes.func,
    handleChecked: PropTypes.func,
    checked: PropTypes.any,
    startDoc: PropTypes.any,
    startDocHandler: PropTypes.func,
    endDoc: PropTypes.any,
    endDocHandler: PropTypes.func,
    startDop: PropTypes.any,
    startDopHandler: PropTypes.func,
    startAmt: PropTypes.any,
    startAmtHandler: PropTypes.func,
    endAmt: PropTypes.any,
    endAmtHandler: PropTypes.func,
    filterHandler: PropTypes.func,
    reset: PropTypes.func,
    endDop: PropTypes.any,
    endDopHandler: PropTypes.func
};

export default SettlementFilter;
