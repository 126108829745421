import { MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function RevenueExpenseLineChart({ send, isReady }) {
    const currentYear = Number(new Date().getFullYear());
    const years = [currentYear, currentYear - 1, currentYear - 2];

    const [year, setYear] = useState(currentYear);
    const [startMonth, setStartMonth] = useState(1);
    const [endMonth, setEndMonth] = useState(12);

    const dashboardState = useSelector((state) => state.dashboard);
    const [isRequest, setIsRequest] = useState(true);

    const isMobile = window.innerWidth < 600;
    // const isTablet = window.innerWidth >= 600 && window.innerWidth <= 1024;
    const isTablet = window.innerWidth >= 600 && window.innerWidth < 1024;
    const isLaptop = window.innerWidth >= 1024 && window.innerWidth <= 1400;
    const smallDevice = window.innerWidth >= 344 && window.innerWidth <= 390;

    const option = {
        animationDuration: 1500,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#283b56'
                }
            },
            textStyle: {
                fontSize: isMobile ? 8 : isTablet ? 16 : 12
            }
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        color: ['green', 'red'],
        xAxis: {
            type: 'category',
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisLabel: {
                fontSize: isMobile ? 10 : isTablet ? 16 : 12
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                fontSize: isMobile ? 9 : isTablet ? 16 : 12
            }
        },
        grid: {
            bottom: smallDevice ? 130 : isMobile ? 60 : isTablet ? 30 : isLaptop ? 125 : 110,
            top: smallDevice ? 30 : isMobile ? 50 : isTablet ? 35 : isLaptop ? 30 : 30,
            left: isMobile ? 40 : 50,
            right: isMobile ? 50 : isTablet ? 40 : 60
        },
        series: [
            {
                data: dashboardState.revenuesExpenses?.revenues || [820, 932, 901, 934, 1290, 1330, 1320, 300, 350, 300, 400, 700],
                type: 'line',
                smooth: true
            },
            {
                data: dashboardState.revenuesExpenses?.expenses || [620, 962, 955, 854, 1250, 1250, 1450, 1000, 1200, 1110, 1300, 1320],
                type: 'line',
                smooth: true
            }
        ]
    };

    const handleYearChange = (e) => {
        setIsRequest(true);
        setYear(e.target.value);
    };

    const handleStartMonthChange = (e) => {
        setIsRequest(true);
        setStartMonth(e.target.value);
    };

    const handleEndMonthChange = (e) => {
        setIsRequest(true);
        setEndMonth(e.target.value);
    };

    useEffect(() => {
        if (isRequest && isReady) {
            send({ event_type: 'revenues_expenses', year: Number(year), start_month: Number(startMonth), end_month: Number(endMonth) });
            setIsRequest(false);
        }
    }, [isReady, year, startMonth, endMonth]);
    return (
        <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '20px', borderRadius: '15px', height: { xs: '48vh', sm: '45.8vh', lg: '37.8vh' } }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: { sm: 'start', lg: 'center' },
                    flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        lg: 'row'
                    },
                    gap: { sm: '1rem' }
                }}
            >
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                <Typography
                    sx={{
                        fontSize: { xs: '6vw', lg: '1.7vw', sm: '3vw', md: '2vw' },
                        margin: 0
                    }}
                >
                    <span style={{ color: 'green' }}>Revenue</span> and <span style={{ color: 'red' }}>Expenses</span>
                </Typography>
                {/* <Box style={{ display: 'flex', gap: 10 }}> */}
                <Box
                    sx={{
                        display: 'flex',
                        gap: { xs: 1, sm: 2 },
                        mt: { xs: '1rem', sm: '0' }
                    }}
                >
                    <TextField select label="Year" value={year} onChange={handleYearChange}>
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                                {/* <Typography sx={{ fontSize: { xs: '3.3vw', sm: '1.6vw', lg: '1vw' } }}>{year}</Typography>{' '} */}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField select label="Start Month" value={startMonth} onChange={handleStartMonthChange}>
                        {monthList.map((startMonth, index) => (
                            <MenuItem key={startMonth} value={index + 1}>
                                {startMonth}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField select label="End Month" value={endMonth} onChange={handleEndMonthChange}>
                        {monthList.map((endMonth, index) => (
                            <MenuItem key={endMonth} value={index + 1}>
                                {endMonth}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
            {dashboardState.revenuesExpenses !== undefined ? (
                <ReactECharts option={option} sx={{ mt: { xs: '-3rem', sm: '0' }, height: '75%', width: '100%' }} />
            ) : (
                // <Skeleton height={'100%'} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
                <Skeleton height={'100%'} sx={{ mt: { xs: '-3rem', sm: '-2rem' } }} />
            )}
        </Box>
    );
}

export default RevenueExpenseLineChart;
