import { useState, useEffect } from 'react';
import axios from 'axios';

import { Box, Grid, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import CarRentalIcon from '@mui/icons-material/CarRental';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useParams } from 'react-router';

import ViewTextFields from 'ui-component/ViewTextFields';
import DocModal from 'ui-component/modals/DocModal';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import SubCard from 'ui-component/cards/SubCard';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />
        }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 60,
        width: '100%'
        // backgroundColor: '#0A95FF'
    },
    '& .Mui-selected': {
        color: '#0A95FF',
        background: '#98c1e094',
        borderRadius: '2rem'
    },
    '& .MuiButtonBase-root': {
        minHeight: '50px'
    }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16,
    marginRight: theme.spacing(1),
    color: '#697586',
    '&.Mui-selected': {
        color: '#0A95FF'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
}));

const ViewTruckForm = () => {
    const { id } = useParams();

    // const scriptedRef = useScriptRef();
    // const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);

    const [truckDetails, setTruckDetails] = useState();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const [fileName, setFileName] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const handleClickOpen = (file) => {
        setFileName(file);
        setModalOpen(true);
    };

    function handleDownloadClick(file) {
        if (file) {
            window.open(file, '_blank');
        }
    }

    function handleDownloadModal() {
        setModalOpen(false);
        window.open(fileName, '_blank');
        setFileName('');
    }
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    useEffect(() => {
        if (id && !truckDetails) {
            fetchTruck(id);
        }
    }, [id, truckDetails]);

    const fetchTruck = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/fleet/truck/view/${id}/`, config);

            setTruckDetails(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClose = () => {
        // setFileName();
        setModalOpen(false);
    };

    return (
        <>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                <Grid container spacing={3} sx={{ mb: 2, px: '20px' }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <Typography variant="h2" sx={{ color: '#0A95FF' }}>
                                {id && truckDetails && truckDetails.driver_name}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        {loading ? <Skeleton height="50px" /> : <Typography variant="h4">License No.</Typography>}
                    </Grid>

                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <Typography variant="body1" sx={{ color: '#0A95FF' }}>
                                {id && truckDetails && truckDetails.license_no}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        {loading ? <Skeleton height="50px" /> : <Typography variant="h4">Unit No.</Typography>}
                    </Grid>

                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <Typography variant="body1" sx={{ color: '#0A95FF' }}>
                                {id && truckDetails && truckDetails.unit_no}
                            </Typography>
                        )}
                    </Grid>
                </Grid>

                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    sx={{
                        '.MuiTabs-flexContainer': {
                            display: 'flex',
                            flexWrap: 'wrap'
                        }
                    }}
                >
                    <StyledTab label="Vehicle" icon={<CarRentalIcon />} iconPosition="start" value="1" />
                    <StyledTab label="Qualification Files" icon={<AssessmentIcon />} iconPosition="start" value="2" />
                    <StyledTab label="Additional Files" icon={<FileCopyIcon />} iconPosition="start" value="3" />
                </StyledTabs>

                <Box sx={{ px: '20px' }}>
                    {value == 1 && (
                        <Grid container spacing={1}>
                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Make
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && truckDetails && truckDetails.make}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Make Year
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && truckDetails && truckDetails.make_year}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Model
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && truckDetails && truckDetails.model}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        VIN No
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && truckDetails && truckDetails.vin_no}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Fuel Card Number
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && truckDetails && truckDetails.fuel_card}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {value == 2 && (
                        <Grid container spacing={3}>
                            {truckDetails && truckDetails.sft_q_docs ? (
                                truckDetails.sft_q_docs.map((data, index) => (
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <ViewTextFields
                                            file={data.sft_doc !== 'null' ? data.sft_doc : ''}
                                            fileName={data.sft_doc_name !== 'null' ? data.sft_doc_name : ''}
                                            viewText={data.doc_name}
                                            handleClickOpen={handleClickOpen}
                                            handleDownloadClick={handleDownloadClick}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <Skeleton height="50px" />
                            )}
                        </Grid>
                    )}

                    {value == 3 && (
                        <Grid container spacing={3}>
                            {truckDetails && truckDetails.sft_a_docs ? (
                                truckDetails.sft_a_docs.map((data, index) => (
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <ViewTextFields
                                            file={data.sft_doc !== 'null' ? data.sft_doc : ''}
                                            fileName={data.sft_doc_name !== 'null' ? data.sft_doc_name : ''}
                                            viewText={data.doc_name}
                                            handleClickOpen={handleClickOpen}
                                            handleDownloadClick={handleDownloadClick}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <Skeleton height="50px" />
                            )}
                        </Grid>
                    )}
                </Box>
            </SubCard>
            <DocModal
                open={modalOpen}
                fullWidth
                fullScreen
                maxWidth="md"
                handleClose={() => handleClose()}
                handleDownload={() => handleDownloadModal()}
                fileName={fileName && fileName}
                content={
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer fileUrl={fileName} />
                    </Worker>
                }
            />
        </>
    );
};

export default ViewTruckForm;
