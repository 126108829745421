import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import { LinearProgress, Skeleton, TableCell, TableRow, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'ui-component/accounting/forms/addfuelform.css';
import * as Yup from 'yup';
import '../../../views/accounting/components/expenses.css';
import debounce from 'lodash.debounce';
import AddDroppedColumnModal from './AddDroppedColumnModal';
import moment from 'moment';

const style = {
    marginTop: '30px',
    height: 'auto',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
    flexGrow: 1
};

// Initial values for Formik form validation
const initialValues = {
    Id: '',
    transaction_date: '',
    invoice: '',
    location_name: '',
    city: '',
    city_name: '',
    state: '',
    state_name: '',
    total_gallons: '',
    total_amount: ''
};

// Schemas for fuelReport form validation
export const fuelReportSchema = Yup.object({
    Id: Yup.number().notRequired(),
    transaction_date: Yup.string().required('Transaction Date is required').typeError('Invalid date'),
    // invoice: Yup.number().required('Invoice number is required'),
    // location_name: Yup.string().required('Location Name is required'),
    // city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required').typeError('Valid state is required'),
    total_gallons: Yup.number().required('Total Gallons is required'),
    total_amount: Yup.number().required('Total Amount is required')
});

export default function AddFuelForm() {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const { id } = useParams();
    const [bulkItemSavingToDb, setBulkItemSavingToDb] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [itemToRender, setItemToRender] = useState([]);
    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState('');
    const [editRowData, setEditRowData] = useState(null);
    const [fuelReportError, setFuelReportError] = useState([]);

    const deleteTableRow = (index, popupState) => {
        popupState.close();
        setItemToRender((prevItems) => prevItems.filter((_, i) => i !== index));
    };

    const editTableRow = (index, popupState) => {
        popupState.close();
        const newRowData = { ...itemToRender[index] }; // Create a copy of the object
        newRowData['Id'] = index; // Set the 'Id' field to the index value
        newRowData['transaction_date'] = newRowData['transaction_date'] + 'T00:00';
        setEditRowData(newRowData); // Update the editRowData state with the modified object
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    const fileInputHandler = (e) => {
        setExcelFile(e.target.files[0]);
    };

    const fetchStateList = async () => {
        const { data } = await axios.get(`/v1/api/users/state/list/233/`);

        return data;
    };

    const getCityList = async (state) => {
        const { data } = await axios.get(`/v1/api/users/city/list/${state}/`);

        return data;
    };

    // Function For
    const fileUploadHandler = async () => {
        if (excelFile) {
            setIsFileUploading(true);
            setExcelFileError('');
        } else {
            setExcelFileError('Please upload a file');
            return;
        }

        const formData = new FormData();
        formData.append('excel_file', excelFile);
        formData.append('truck_id', id);

        try {
            toast.success('Generating Report. Please wait.', {
                theme: 'light'
            });
            const response = await axios.post('/v1/api/accounting/xl-to-json-converter', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${loginInfoFromStorage.token}`
                }
            });
            if (response.status === 200) {
                const dat = response.data.map((item) => ({ ...item, transaction_date: moment.utc(item.transaction_date).format('YYYY-MM-DD') }));

                // console.log(dat);

                // setItemToRender(response.data.map((item) => ({ ...item, transaction_date: moment.utc(item.transaction_date).format('YYYY-MM-DD') })));
                setItemToRender(dat);
                window.scrollTo({
                    bottom: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                setExcelFileError(response.message);
            }

            setIsFileUploading(false);
        } catch (error) {
            console.log('Error uploading file:', error);
            setIsFileUploading(false);
        }
    };

    function formatDate(dateString) {
        // const date = new Date(dateString);
        // const year = date.getFullYear();
        // const month = String(date.getMonth() + 1).padStart(2, '0');
        // const day = String(date.getDate()).padStart(2, '0');

        // return `${year}-${month}-${day}`;
        return moment.utc(dateString).format('YYYY-MM-DD');
    }

    // post list of fuel report data into server handler
    const saveTableDataIntoServer = async (data) => {
        // Modify each item in the data array

        const fuelReportItem = JSON.parse(JSON.stringify(data));

        for (let d of fuelReportItem) {
            d['truck'] = Number(id);
            d['total_gallons'] = parseFloat(d['total_gallons']).toFixed(2);
            d['total_amount'] = parseFloat(d['total_amount']).toFixed(2);
            d['transaction_date'] = formatDate(d['transaction_date']);

            delete d.state_name;
            delete d.city_name;
        }
        // calling the api to post the data
        try {
            setBulkItemSavingToDb(true);
            const response = await axios.post(
                '/v1/api/accounting/fuel-reports/',
                {
                    items: fuelReportItem
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${loginInfoFromStorage.token}`
                    }
                }
            );
            if (response.status == 201) {
                setItemToRender([]);
                toast.success('Fuel reports save successfully');
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            return response.data;
        } catch (error) {
            console.log('Error posting data:', error);
            if (error.response.data) {
                setFuelReportError(error.response.data);
            }
            toast.error("File can't be saved due to invalid format");
        } finally {
            setBulkItemSavingToDb(false);
        }
    };

    // Table data submit handler
    const saveAllTableDataHandler = async () => {
        if (itemToRender.length > 0) {
            const data = await saveTableDataIntoServer(itemToRender);
        } else {
            toast.error('There is no data to post');
            return;
        }
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues, setFieldValue } = useFormik({
        initialValues,
        validationSchema: fuelReportSchema,
        onSubmit: (values, action) => {
            action.resetForm();
            setFuelReportError([]);

            // Destructure the 'Id' field from values, and store the rest of the fields in newValues
            const { Id, ...newValues } = values;

            newValues.transaction_date = format(new Date(newValues.transaction_date), 'yyyy-MM-dd');

            if (Id !== '') {
                // const newValues = { ...values };
                let newItemToRender = [...itemToRender];
                newItemToRender.splice(Id, 1, newValues);
                setItemToRender(newItemToRender);
            } else {
                // If index is not found, add newValues to itemToRender
                setItemToRender([...itemToRender, newValues]);
            }
            setEditRowData(null);
        }
    });

    const {
        data: states,
        isLoading: statesLoading,
        isFetching: statesFetching
    } = useQuery({
        queryKey: ['states'],
        queryFn: fetchStateList,
        staleTime: 1000 * 60 * 60 * 5
    });

    const {
        data: cities,
        isLoading: citiesLoading,
        isFetching: citiesFetching
    } = useQuery({
        queryKey: ['cities', values.state],
        queryFn: () => getCityList(values.state),
        enabled: !!values.state,
        staleTime: 1000 * 60 * 60 * 5
    });

    // When the edit button clicked it's populating the new data into form
    useEffect(() => {
        if (editRowData) {
            setValues(editRowData);
        }
    }, [editRowData, setValues]);

    const debouncedOnCityChange = useMemo(
        () =>
            debounce((value, cities) => {
                setFieldValue('city', value);
                const city_name = cities.filter((city) => value === city.id)[0]?.name;
                setFieldValue('city_name', city_name);
            }, 300),
        [setFieldValue]
    );

    const handleCityChange = useCallback(
        (e) => {
            const value = e.target.value;
            debouncedOnCityChange(value, cities);
        },
        [debouncedOnCityChange, cities]
    );

    const debouncedOnStateChange = useMemo(
        () =>
            debounce((value, states) => {
                setFieldValue('state', value);
                const state_name = states?.filter((state) => value === state.id)[0]?.name;
                setFieldValue('state_name', state_name);
            }, 300),
        [setFieldValue]
    );

    const handleStateChange = useCallback(
        (e) => {
            const value = e.target.value;
            debouncedOnStateChange(value, states);
        },
        [debouncedOnStateChange, states]
    );

    const totalAmount = useMemo(() => {
        return itemToRender?.reduce((accumulator, current) => accumulator + Number(current?.total_amount || 0), 0);
    }, [itemToRender]);

    // dropped columns starts
    const fetchDroppedColumns = async () => {
        const { data } = await axios.get('/v1/api/accounting/fuel-reports/dropped_columns/', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${loginInfoFromStorage.token}`
            }
        });
        return data;
    };

    const [addColumnDrawer, setAddColumnDrawer] = useState(false);

    const { data: droppedColumns, isFetching: droppedColumnsFetching } = useQuery({
        queryKey: ['dropped_columns'],
        queryFn: fetchDroppedColumns,
        staleTime: 1000 * 60 * 60
    });
    // dropped columns ends

    return (
        <>
            <>
                <Box sx={style}>
                    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                        <Grid item xs={8}>
                            <Box
                                sx={{
                                    border: excelFile ? '2px solid green' : '2px solid orange',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '0px 10px'
                                }}
                            >
                                <p style={{ fontSize: '16px', fontWeight: '500' }}>{excelFile ? excelFile.name : 'Upload Excel File'}</p>

                                <Button component="label" color="orange" startIcon={<CloudUploadIcon />}>
                                    Upload
                                    <input hidden type="file" accept=".xlsx, .xls" onChange={fileInputHandler} />
                                </Button>
                            </Box>
                            {excelFileError && <Typography style={{ color: 'red' }}>{excelFileError}</Typography>}
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                            <Button
                                variant="contained"
                                sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                color="orange"
                                disabled={isFileUploading === true}
                                onClick={fileUploadHandler}
                                endIcon={<SendIcon />}
                            >
                                {isFileUploading ? 'Generating.........' : 'Generate Report'}
                            </Button>
                        </Grid>
                        {user_type === 'super_dispatcher_admin' && (
                            <>
                                <Grid item xs={12} md={10} lg={10}>
                                    <Typography sx={{ mb: 1 }}>The following column will be dropped</Typography>
                                    {droppedColumnsFetching ? (
                                        <Skeleton height={70} />
                                    ) : droppedColumns?.dropped_columns[0] === '' ? (
                                        <Typography variant="h6" color={'red'}>
                                            No column found
                                        </Typography>
                                    ) : (
                                        <Typography>{JSON.stringify(droppedColumns?.dropped_columns)}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} lg={2} textAlign={'right'}>
                                    <Button
                                        variant="contained"
                                        sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                        color="orange"
                                        endIcon={<AddIcon />}
                                        onClick={() => setAddColumnDrawer((prev) => !prev)}
                                    >
                                        Add Column
                                    </Button>
                                    <AddDroppedColumnModal
                                        addColumnDrawer={addColumnDrawer}
                                        setAddColumnDrawer={setAddColumnDrawer}
                                        droppedColumns={droppedColumns}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {isFileUploading && <LinearProgress style={{ marginTop: '10px', width: '100%', backgroundColor: 'orange' }} />}

                    <Divider style={{ margin: '20px 0' }} />

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography
                            sx={{
                                fontWeight: '500',
                                fontSize: '20px',
                                fontFamily: 'Lexend'
                            }}
                        >
                            Add Fuel Report Manually
                        </Typography>
                    </Grid>
                    <Divider style={{ margin: '20px 0' }} />
                    {/* Form for Taking Input */}
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <input type="hidden" name="Id" value={(editRowData && editRowData['Id']) || values['Id']} />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Transaction Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={values.transaction_date}
                                        onChange={(newValue) => {
                                            setFieldValue('transaction_date', newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                name="transaction_date"
                                                color="orange"
                                                fullWidth
                                                {...params}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.transaction_date && errors.transaction_date)}
                                                helperText={touched.transaction_date && errors.transaction_date}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    name="invoice"
                                    label="Invoice"
                                    value={values.invoice}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.invoice && errors.invoice)}
                                    helperText={touched.invoice && errors.invoice}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    name="location_name"
                                    label="Location Name"
                                    value={values.location_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.location_name && errors.location_name)}
                                    helperText={touched.location_name && errors.location_name}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                {statesLoading && statesFetching ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        select
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        name="state"
                                        label="State"
                                        value={values.state}
                                        onChange={handleStateChange}
                                        onBlur={handleBlur}
                                        error={Boolean(touched.state && errors.state)}
                                        helperText={touched.state && errors.state}
                                    >
                                        {states &&
                                            states.map((state) => (
                                                <MenuItem key={state.id} value={state.id}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                )}
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                {citiesLoading && citiesFetching ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        select
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        name="city"
                                        label="City"
                                        value={values.city}
                                        onChange={handleCityChange}
                                        onBlur={handleBlur}
                                        error={Boolean(touched.city && errors.city)}
                                        helperText={touched.city && errors.city}
                                    >
                                        {(cities?.length === 0 || cities === undefined) && <MenuItem value="">No City available</MenuItem>}
                                        {cities &&
                                            cities?.map((city) => (
                                                <MenuItem key={city.id} value={city.id}>
                                                    {city.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                )}
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <TextField
                                    type="number"
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    name="total_gallons"
                                    label="Total Gallons"
                                    value={values.total_gallons}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.total_gallons && errors.total_gallons)}
                                    helperText={touched.total_gallons && errors.total_gallons}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    className="text-input"
                                    color="orange"
                                    name="total_amount"
                                    label="Total Amount"
                                    value={values.total_amount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.total_amount && errors.total_amount)}
                                    helperText={touched.total_amount && errors.total_amount}
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" style={{ backgroundColor: '#EB812E', margin: '20px 0' }}>
                            Add Into Table
                        </Button>
                    </form>
                    <Divider style={{ margin: '20px 0' }} />

                    <div className="table-container">
                        <div className="table-header">
                            <div>Id</div>
                            <div>Transaction Date</div>
                            <div>Invoice</div>
                            <div>Location Name</div>
                            <div>City</div>
                            <div>State</div>
                            <div>Total Gallons</div>
                            <div>Total Amount</div>
                            <div>Actions</div>
                        </div>
                        <div className="table-body">
                            {itemToRender.map((row, index) => (
                                <>
                                    <div key={index} className="table-row">
                                        <div>{index + 1}</div>
                                        <div>{row.transaction_date}</div>
                                        <div>{row.invoice}</div>
                                        <div>{row.location_name}</div>
                                        <div>{row.city_name}</div>
                                        <div>{row.state_name}</div>
                                        <div>{row.total_gallons}</div>
                                        <div>{row.total_amount}</div>
                                        <div>
                                            <PopupState variant="popover" popupId={`demoMenu-${index}`}>
                                                {(popupState) => (
                                                    <div>
                                                        <IconButton {...bindTrigger(popupState)} size="large">
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            {...bindMenu(popupState)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right'
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right'
                                                            }}
                                                        >
                                                            <MenuItem onClick={() => editTableRow(index, popupState)}>Edit Row</MenuItem>
                                                            <MenuItem onClick={() => deleteTableRow(index, popupState)}>Remove Row</MenuItem>
                                                        </Menu>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </div>
                                    </div>
                                    {fuelReportError?.[index] && Object.keys(fuelReportError?.[index]).length > 0 && (
                                        <span>{JSON.stringify(fuelReportError?.[index])}</span>
                                    )}
                                </>
                            ))}
                            <div style={{ display: 'flex', gap: '50px', justifyContent: 'end', fontWeight: 'bold', fontSize: '20px', margin: '15px 15%' }}>
                                <div sx={{ fontWeight: 'bolder' }}>Total</div>
                                <div sx={{ fontWeight: 'bolder' }}>$&nbsp;{Number(totalAmount || 0).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>

                    <Button
                        fullWidth
                        variant="contained"
                        disabled={bulkItemSavingToDb === true}
                        onClick={saveAllTableDataHandler}
                        style={{ backgroundColor: '#EB812E', margin: '10px 0' }}
                    >
                        {bulkItemSavingToDb ? 'Saving.........' : ' Save this data'}
                    </Button>
                    {bulkItemSavingToDb && <LinearProgress style={{ width: '100%', backgroundColor: 'orange' }} />}
                </Box>
            </>
        </>
    );
}
