// project imports
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { Box } from '@mui/system';
import PageLayout from 'layout/PageLayout';

import { useState } from 'react';

import MainCard from 'ui-component/cards/MainCard';

import AddloadForm from 'ui-component/dispatchCenter/forms/AddloadForm';
import AllCustomerForm from 'ui-component/dispatchCenter/forms/AllCustomerForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// ==============================|| SAMPLE PAGE ||============================== //

const MyLoad = () => {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainCard>
            <PageLayout>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="My Load" value="1" />
                            {/* <Tab label="Load Board" value="2" /> */}
                            <Tab label="Customer" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <AddloadForm />
                    </TabPanel>
                    <TabPanel value="2"></TabPanel>
                    <TabPanel value="3">
                        <AllCustomerForm />
                    </TabPanel>
                </TabContext>
            </PageLayout>
        </MainCard>
    );
};

export default MyLoad;
