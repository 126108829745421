// project imports

import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

import MainCard from 'ui-component/cards/MainCard';

import { useNavigate } from 'react-router';
import AddIFTAForm from 'ui-component/accounting/forms/AddIFTAForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLayout from 'layout/PageLayout';
// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '8px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '18px',
                            fontWeight: 900,
                            marginRight: '5px',
                            color: 'white'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});
const AddIfta = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <MainCard>

          <PageLayout title="Accounting">   
            <Box sx={{ border: 'none', background: 'transparent' }}>
                <ThemeProvider theme={theme}>
                    <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                        <Tab label="Settlement" onClick={() => navigate(`/accounting/settlement`)} />
                        <Tab label="IFTA" onClick={() => navigate(`/accounting/ifta/all`)} />
                        <Tab label="My Invoices" onClick={() => navigate(`/accounting/my-invoice/all`)} />
                        <Tab label="Tax Configuration" onClick={() => navigate(`/accounting/configuration/tax`)} />
                        <Tab label="Fuel Reports" onClick={() => navigate(`/accounting/fuel-reports`)} />
                        <Tab label="External Expenses" onClick={() => navigate(`/accounting/external-expenses`)} />
                    </Tabs>
                </ThemeProvider>
            </Box>

                <AddIFTAForm />
            </PageLayout>
        </MainCard>
    );
};

export default AddIfta;
