// project imports

import MainCard from 'ui-component/cards/MainCard';
import UserProfileForm from 'ui-component/setup/forms/UserProfileForm';
// ==============================|| SAMPLE PAGE ||============================== //

const UserProfile = () => {
    return (
        <>
            <MainCard>
                <UserProfileForm />
            </MainCard>
        </>
    );
};

export default UserProfile;
