// project imports
import { useEffect } from 'react';

import MainCard from 'ui-component/cards/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';

import CompanyProfileForm from 'ui-component/mechanic/forms/CompanyProfileForm';
// ==============================|| SAMPLE PAGE ||============================== //

const EditMechanicCompanyProfile = () => {
    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    return (
        <>
            <MainCard>
                <CompanyProfileForm />
            </MainCard>
        </>
    );
};

export default EditMechanicCompanyProfile;
