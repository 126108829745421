import { Container, Stack, Typography } from '@mui/material';

import React from 'react';

/**
 * Page Layout
 * @param {prop} props.children
 * @param {prop} props.url
 *
 * @returns JSX
 */
function PageLayout({ title, children }) {
    return (
        <Container maxWidth="xl" sx={{ ml: 0, pl: 0 }}>
            <Stack direction="row" sx={{ mb: 5 }} justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                <Typography
                    sx={{
                        mb: 5,
                        width: '500px',
                        height: '40px',
                        fontWeight: '400',
                        fontSize: '32px',
                        fontFamily: 'Lexend'
                    }}
                >
                    {title}
                </Typography>
            </Stack>
            {children}
        </Container>
    );
}

export default PageLayout;
