import MaterialTable from '@material-table/core';
import TABLE_ICONS from 'tableIcons';
import { Stack, useTheme, Link, ListItem, TextField, MenuItem, useMediaQuery, Card, CardContent, TablePagination, CircularProgress } from '@mui/material';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import { createRef } from 'react';
import { useState, useEffect } from 'react';
import InvoiceTrackingModal from 'ui-component/modals/InvoiceTrackingModal';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import './audit-logs.css';
import { Box } from '@mui/system';
const AuditLogs = () => {
    useEffect(() => {
        fetchUserList();
    }, []);

    const theme = useTheme();
    const navigate = useNavigate();
    const tableRef = createRef();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    const [user, setUser] = useState('');
    const [userList, setUserList] = useState([]);
    const [event, setEvent] = useState('');
    const [datetime, setDateTime] = useState('');
    const [datetimeAfter, setDateTimeAfter] = useState('');
    const [datetimeBefore, setDateTimeBefore] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selected, setSelected] = useState([]);

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const events = [
        'Added settlement',
        'Viewed settlement',
        'Edited settlement',
        'Removed settlement',
        'Added Fuel Reports',
        'User logged in',
        'Added Driver',
        'Viewing driver details',
        'Edited driver details',
        'Partially updating driver details',
        'Removed driver',
        'Added User',
        'Viewed User',
        'Edited User',
        'Removed User',
        'Added TAX Configuration',
        'Added invoice expenses',
        'Viewed invoice expenses',
        'Edited invoice expenses',
        'Removed invoice expenses',
        'User logged out',
        'Viewed customer details.',
        'Edited customer details.',
        'Removed customer details.',
        'Added New Load',
        'Edited load details.',
        'Removed load details.',
        'Viewed load details.',
        'Added other docs in invoice.',
        'New BOL document uploaded.',
        'BOL document approved.',
        'BOL document disapproved.',
        'Invoice Created.',
        'Invoice created with doc.',
        'Invoice conformed.',
        'Changed Invoice status',
        'Viewed Invoice Tracking',
        'Edited Invoice Tracking',
        'Created Truck',
        'Viewed Truck',
        'Edited Truck',
        'Failed to edit Truck',
        'Deleted truck',
        'Created Trailer',
        'Viewed Trailer',
        'Edited Trailer',
        'Failed to edit Trailer',
        'Deleted Trailer',
        'Added Owner',
        'Failed to add owner',
        'Edited Owner',
        'Failed to edit Owner'
    ];
    const date_time = ['Custom', 'Today', 'Yesterday', 'This Week', 'This Month', 'This Quater', 'This Year'];

    const handleCloseModal = () => {
        setOpen(false);
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const renderTable = () => {
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Date Changed</th>
                    <th className="headerTd rightHidBor">User</th>
                    <th className="headerTd rightHidBor">Event</th>
                    <th className="headerTd rightHidBor">History</th>
                    <th className="headerTd">IP Address</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                {/* Date Changed */}
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.action_datetime}
                </td>
                {/* User */}
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user_email}
                </td>
                {/* Event */}
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.change_summary}
                </td>
                {/* History */}
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    <Link
                        component="button"
                        onClick={(e) => {
                            if (isFile(rowData.data.url)) {
                                window.open(rowData.data.url, '_blank');
                            } else {
                                navigate('../' + rowData.data.url);
                            }
                        }}
                    >
                        {rowData.data.changed_object}
                    </Link>
                </td>
                {/* IP Address */}
                <td className="rowTr tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.login_IP}
                </td>
            </tr>
        );
    };

    const isFile = (url) => {
        const fileExtensions = [
            '.pdf',
            '.doc',
            '.docx',
            '.xls',
            '.xlsx',
            '.ppt',
            '.pptx',
            '.txt',
            '.csv',
            '.jpg',
            '.jpeg',
            '.png',
            '.gif',
            '.mp3',
            '.mp4',
            '.avi',
            '.mov'
        ];
        let isfile = false;
        fileExtensions.map((ext) => {
            if (url.endsWith(ext)) {
                isfile = true;
                return;
            }
        });
        return isfile;
    };

    const fetchUserList = async () => {
        const { data } = await axios.get(`/v1/api/users/activity-logs/`, config);
        setUserList(Array.from(new Set(data.results.map((user) => user.user_email))));
    };

    const handleUserChange = (e) => {
        if (e.target.value == '') {
            setEvent('');
            setDateTimeAfter('');
            setDateTimeBefore('');
        }
        setUser(e.target.value);
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        } else {
            console.error('tableRef.current is undefined on mobile');
            const query = {
                page: 0,
                pageSize: 5
            };

            fetchData(query);
        }
    };

    const handleDateTimeAfter = (e) => {
        console.log(formatDate(e));
        setDateTimeAfter(formatDate(e));
    };

    const handleDateTimeBefore = (e) => {
        console.log(formatDate(e));
        setDateTimeBefore(formatDate(e));
    };

    const handleEventChange = (e) => {
        if (e.target.value == '') {
            setUser('');
            setDateTimeAfter('');
            setDateTimeBefore('');
        }
        setEvent(e.target.value);
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        } else {
            console.error('tableRef.current is undefined on mobile');
            const query = {
                page: 0,
                pageSize: 5
            };

            fetchData(query);
        }
    };

    const handleDateTimeChange = (e) => {
        const now = new Date();
        let datetime_after = null;
        let datetime_before = null;
        setDateTime(e.target.value);
        setOpenDialog(false);
        switch (e.target.value) {
            case 'Today':
                datetime_after = new Date(now.setHours(0, 0, 0, 0));
                setDateTimeAfter(formatDate(datetime_after));
                datetime_before = new Date(now.setHours(23, 59, 59, 999));
                setDateTimeBefore(formatDate(datetime_before));
                break;

            case 'Yesterday':
                const yesterday = new Date(now);
                yesterday.setDate(now.getDate() - 1);
                datetime_after = new Date(yesterday.setHours(0, 0, 0, 0));
                setDateTimeAfter(formatDate(datetime_after));
                datetime_before = new Date(yesterday.setHours(23, 59, 59, 999));
                setDateTimeBefore(formatDate(datetime_before));
                break;

            case 'This Week':
                const firstDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
                datetime_after = new Date(firstDayOfWeek.setHours(0, 0, 0, 0));
                setDateTimeAfter(formatDate(datetime_after));
                const lastDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 6));
                datetime_before = new Date(lastDayOfWeek.setHours(23, 59, 59, 999));
                setDateTimeBefore(formatDate(datetime_before));
                break;

            case 'This Month':
                const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
                datetime_after = new Date(firstDayOfMonth.setHours(0, 0, 0, 0));
                setDateTimeAfter(formatDate(datetime_after));
                const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                datetime_before = new Date(lastDayOfMonth.setHours(23, 59, 59, 999));
                setDateTimeBefore(formatDate(datetime_before));
                break;

            case 'This Quarter':
                const quarter = Math.floor((now.getMonth() + 3) / 3);
                const firstDayOfQuarter = new Date(now.getFullYear(), (quarter - 1) * 3, 1);
                datetime_after = new Date(firstDayOfQuarter.setHours(0, 0, 0, 0));
                setDateTimeAfter(formatDate(datetime_after));
                const lastDayOfQuarter = new Date(now.getFullYear(), quarter * 3, 0);
                datetime_before = new Date(lastDayOfQuarter.setHours(23, 59, 59, 999));
                setDateTimeBefore(formatDate(datetime_before));
                break;

            case 'This Year':
                const firstDayOfYear = new Date(now.getFullYear(), 0, 1);
                datetime_after = new Date(firstDayOfYear.setHours(0, 0, 0, 0));
                setDateTimeAfter(formatDate(datetime_after));
                const lastDayOfYear = new Date(now.getFullYear(), 11, 31);
                datetime_before = new Date(lastDayOfYear.setHours(23, 59, 59, 999));
                setDateTimeBefore(formatDate(datetime_before));
                break;
            case 'Custom':
                setOpenDialog(true);
                setDateTime('');
                break;
        }
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        } else {
            console.error('tableRef.current is undefined on mobile');
            const query = {
                page: 0,
                pageSize: 5
            };

            fetchData(query);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSubmit = () => {
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }
        setOpenDialog(false);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    // const fetchData = async (query) => {
    //     const url = `../v1/api/users/activity-logs/?page=${query.page + 1}&page_size=${
    //         query.pageSize
    //     }&user_email=${user}&change_summary=${event}&action_datetime_after=${datetimeAfter}&action_datetime_before=${datetimeBefore}`;
    //     const response = await fetch(url, config);
    //     const result = await response.json();
    //     setSelected(result.results);

    //     return {
    //         data: result.results,
    //         page: query.page,
    //         totalCount: result.count
    //     };
    // };

    const fetchData = async (query) => {
        setLoading(true);

        try {
            const url = `../v1/api/users/activity-logs/?page=${query.page + 1}&page_size=${
                query.pageSize
            }&user_email=${user}&change_summary=${event}&action_datetime_after=${datetimeAfter}&action_datetime_before=${datetimeBefore}`;
            const response = await fetch(url, config);
            const result = await response.json();

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 5 })
                .then((result) => {
                    setSelected(result.data || {});
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
        // console.log(selected);
    }, [isMobile, isTablet, currPage, cardPerPage]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    return (
        <>
            <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'baseline', gap: { sm: '1rem' } }} spacing={3}>
                <TextField
                    sx={{ minWidth: { xs: '80vw', sm: '10rem' } }}
                    // sx={{ width: '250px' }}
                    fullWidth
                    className="text-input"
                    color="orange"
                    select
                    label="User"
                    value={user}
                    onChange={(e) => handleUserChange(e)}
                >
                    <MenuItem value={''}>All Users</MenuItem>
                    {userList &&
                        userList.map((i) => (
                            <MenuItem key={i} value={i}>
                                {i}
                            </MenuItem>
                        ))}
                </TextField>
                <TextField
                    sx={{ minWidth: { xs: '80vw', sm: '10rem' } }}
                    fullWidth
                    className="text-input"
                    color="orange"
                    select
                    label="Date Changed"
                    value={datetime}
                    onChange={(e) => handleDateTimeChange(e)}
                >
                    <MenuItem value={''} disabled>
                        All
                    </MenuItem>

                    {date_time &&
                        date_time.map((i) => (
                            <MenuItem key={i} value={i}>
                                {i}
                            </MenuItem>
                        ))}
                </TextField>
                <TextField
                    sx={{ minWidth: { xs: '80vw', sm: '10rem' } }}
                    fullWidth
                    className="text-input"
                    color="orange"
                    select
                    label="Events"
                    value={event}
                    onChange={(e) => handleEventChange(e)}
                >
                    <MenuItem value={''}>All Events</MenuItem>
                    {events &&
                        events.map((i) => (
                            <MenuItem key={i} value={i}>
                                {i}
                            </MenuItem>
                        ))}
                </TextField>
            </Stack>

            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginTop: '2rem',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ clear: 'both' }} />
                                    <Typography sx={{ marginBottom: '.5rem' }}>Date Changed : {driver.action_datetime}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>User : {driver.user_email}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Event : {driver.change_summary}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        History :{' '}
                                        <Link
                                            component="button"
                                            onClick={(e) => {
                                                if (isFile(driver.url)) {
                                                    window.open(driver.url, '_blank');
                                                } else {
                                                    navigate('../' + driver.url);
                                                }
                                            }}
                                        >
                                            {driver.changed_object}
                                        </Link>
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>IP Address : {driver.login_IP}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected?.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginTop: '2rem',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    {/* </Box> */}
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Date Changed : {driver.action_datetime}</Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>User : {driver.user_email}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Event : {driver.change_summary}</Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            History :{' '}
                                            <Link
                                                component="button"
                                                onClick={(e) => {
                                                    if (isFile(driver.url)) {
                                                        window.open(driver.url, '_blank');
                                                    } else {
                                                        navigate('../' + driver.url);
                                                    }
                                                }}
                                            >
                                                {driver.changed_object}
                                            </Link>
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>IP Address : {driver.login_IP}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    style={{
                        backgroundColor: 'white',
                        border: 'none',
                        boxShadow: 'none',
                        padding: '5px'
                    }}
                    // data={(query) =>
                    //     new Promise((resolve, reject) => {
                    //         const url = `../v1/api/users/activity-logs/?page=${query.page + 1}&page_size=${
                    //             query.pageSize
                    //         }&user_email=${user}&change_summary=${event}&action_datetime_after=${datetimeAfter}&action_datetime_before=${datetimeBefore}`;

                    //         fetch(url, config)
                    //             .then((response) => response.json())
                    //             .then((result) => {
                    //                 resolve({
                    //                     data: result.results,
                    //                     page: query.page,
                    //                     totalCount: result.count
                    //                 });
                    //             });
                    //     })
                    // }
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchData(query)
                                .then((result) => resolve(result))
                                .catch((error) => reject(error));
                        })
                    }
                    title="Audit Logs"
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,
                        filtering: true,
                        toolbar: false,
                        draggable: false,
                        columnResizable: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20]
                    }}
                />
            )}
            <Dialog
                fullWidth={false}
                fullScreen={false}
                maxWidth="md"
                open={openDialog}
                // onClose={handleClose}
                // TransitionComponent={Transition}
                scroll="paper"
            >
                <DialogTitle sx={{ direction: 'row', alignItems: 'center', mt: 1, padding: 0 }}>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h1" color="primary" sx={{ mt: 1, padding: 0 }}>
                            Custom Date Time
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                className="text-input"
                                openTo="day"
                                views={['year', 'month', 'day']}
                                label="Start Date"
                                value={datetimeAfter}
                                onChange={(e) => handleDateTimeAfter(e)}
                                renderInput={(params) => <TextField className="text-input" color="orange" fullWidth {...params} error={null} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                className="text-input"
                                openTo="day"
                                views={['year', 'month', 'day']}
                                label="End Date"
                                value={datetimeBefore}
                                onChange={(e) => handleDateTimeBefore(e)}
                                renderInput={(params) => <TextField className="text-input" color="orange" fullWidth {...params} error={null} />}
                            />
                        </LocalizationProvider>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleSubmit}>
                        Apply
                    </Button>
                    <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AuditLogs;
