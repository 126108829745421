import { Grid } from '@mui/material';
import { Box, useMediaQuery, useTheme } from '@mui/system';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import BolDoughnutChart from './BolDoughnutChart';
import DriverCount from './DriverCount';
import DriverGroup from './DriverGroup';
import DriverMoneyPaidChart from './DriverMoneyPaidChart';
import ExpenseDoughnutChart from './ExpenseDoughnutChart';
import GallonBurnedPerDriverChart from './GallonBurnedPerDriverChart';
import InvoiceDoughnutChart from './InvoiceDoughnutChart';
import LoadDoughnutChart from './LoadDoughnutChart';
import LoadsCount from './LoadsCount';
import ProfitLossStackedChart from './ProfitLossStackedChart';
import ProfitPerCustomerBarChart from './ProfitPerCustomerBarChart';
import RevenueDoughnutChart from './RevenueDoughnutChart';
import RevenueExpenseLineChart from './RevenueExpenseLineChart';
import TruckCount from './TruckCount';
import TruckMileageBarChart from './TruckMileageBarChart';
import useWebSocket from './useWebSocket';

// const PRODUCTION = process.env.NODE_ENV === 'development';
// TODO: change as per env
const PRODUCTION = true;

function Dashboard() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const padding = isMobile ? theme.spacing(2) : theme.spacing(0);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const navigate = useNavigate();

    const user_type = loginInfoFromStorage.user_type;
    useEffect(() => {
        if (user_type !== 'dispatcher_admin' && user_type !== 'self_dispatch') {
            navigate('/login', { replace: true });
        }
    }, [user_type]);

    const ACCESS_TOKEN = loginInfoFromStorage.token;

    const HOST = window.location.host;

    const PROTOCOL = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const DEVURL = '146.190.53.245:9977';
    const PRODURL = 'connect.infinitytruck.in';

    const WEBSOCKET_ENDPOINT =
        PROTOCOL === 'wss' ? `${PROTOCOL}://${PRODURL}/ws/dashboard/?token=${ACCESS_TOKEN}` : `${PROTOCOL}://${DEVURL}/ws/dashboard/?token=${ACCESS_TOKEN}`;

    // const WEBSOCKET_ENDPOINT = `wss://connect.infinitytruck.in/ws/dashboard/?token=${ACCESS_TOKEN}`;
    // const WEBSOCKET_ENDPOINT = `ws://146.190.53.245:9977/ws/dashboard/?token=${ACCESS_TOKEN}`;

    const [isReady, send] = useWebSocket(WEBSOCKET_ENDPOINT);

    return (
        <Box
            sx={{
                padding: padding
                // overflowX: 'hidden'
            }}
        >
            <Grid container spacing={2.5} sx={{ mt: 0 }}>
                <Grid item xs={12} md={4} lg={4}>
                    <TruckCount send={send} isReady={isReady} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <DriverCount send={send} isReady={isReady} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <LoadsCount send={send} isReady={isReady} />
                </Grid>
            </Grid>

            <Grid container spacing={2.5} sx={{ mt: 1 }}>
                <Grid item xs={12} md={6} lg={6}>
                    <ProfitLossStackedChart send={send} isReady={isReady} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <RevenueExpenseLineChart send={send} isReady={isReady} />
                </Grid>
            </Grid>

            <Grid container spacing={2.5} sx={{ mt: 1 }}>
                <Grid item xs={12} md={6} lg={6}>
                    <ExpenseDoughnutChart send={send} isReady={isReady} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <RevenueDoughnutChart send={send} isReady={isReady} />
                </Grid>
            </Grid>

            {/* Load Doughnut chart */}
            <Grid container spacing={2.5} sx={{ mt: 1 }}>
                <Grid item xs={12} md={6} lg={6}>
                    <LoadDoughnutChart send={send} isReady={isReady} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <BolDoughnutChart send={send} isReady={isReady} />
                </Grid>
            </Grid>

            {/* Driver MoneyPaid chart */}
            <Grid container spacing={2.5} sx={{ mt: 1 }}>
                <Grid item xs={12} md={12} lg={12}>
                    <DriverMoneyPaidChart send={send} isReady={isReady} />
                </Grid>
            </Grid>

            {/* Invoice Doughnut chart */}
            <Grid container spacing={2.5} sx={{ mt: 1 }}>
                <Grid item xs={12} md={6} lg={6}>
                    <InvoiceDoughnutChart send={send} isReady={isReady} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <ProfitPerCustomerBarChart send={send} isReady={isReady} />
                </Grid>
            </Grid>

            {/* Gallons Burned per driver chart */}
            <Grid container spacing={2.5} sx={{ my: 1 }}>
                <Grid item xs={12} md={6} lg={6}>
                    <GallonBurnedPerDriverChart send={send} isReady={isReady} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TruckMileageBarChart send={send} isReady={isReady} />
                </Grid>
            </Grid>

            {/* Driver Compliance group */}
            <DriverGroup send={send} isReady={isReady} />
        </Box>
    );
}

export default Dashboard;
