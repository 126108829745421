import { useLocation } from 'react-router';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import AllFuelDriverViewForm from 'ui-component/accounting/forms/AllFuelDriverViewFormNew';
import { useSearchParams } from 'react-router-dom';

function FuelReportsByTruckId() {
    const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    const [searchParams] = useSearchParams();
    const driver_name = searchParams.get('driver_name');
    const truck_number = searchParams.get('truck_number');
    const trailer_number = searchParams.get('trailer_number');

    const [loading, setLoading] = useState(false);
    return (
        <>
            <Stack sx={{ flexDirection: { sm: 'column', md: 'row' }, alignItems: 'baseline' }} justifyContent="flex-start" spacing={2}>
                {loading ? (
                    <Skeleton height="50px" width="250px" />
                ) : (
                    <Typography variant="h5" fontSize="16px" sx={{ height: '20px', width: '250px', fontFamily: 'Lexend' }}>
                        Driver:
                        <span style={{ width: '228px', height: '20px', color: '#5C5C5C', fontFamily: 'Lexend', paddingLeft: '10px' }}>
                            {driver_name != 'undefined' && `${driver_name}`}
                        </span>
                    </Typography>
                )}
                {loading ? (
                    <Skeleton height="50px" width="250px" />
                ) : (
                    <Typography variant="h5" fontSize="16px" sx={{ height: '20px', width: '250px', fontFamily: 'Lexend' }}>
                        Truck Number:
                        <span style={{ width: '228px', height: '20px', color: '#5C5C5C', fontFamily: 'Lexend', paddingLeft: '10px' }}>
                            {truck_number != 'undefined' && `${truck_number}`}
                        </span>
                    </Typography>
                )}
                {loading ? (
                    <Skeleton height="50px" width="250px" />
                ) : (
                    <Typography variant="h5" fontSize="16px" sx={{ height: '20px', width: '250px', fontFamily: 'Lexend' }}>
                        Trailer Number:
                        <span style={{ width: '228px', height: '20px', color: '#5C5C5C', fontFamily: 'Lexend', paddingLeft: '10px' }}>
                            {trailer_number != 'undefined' && `${trailer_number}`}
                        </span>
                    </Typography>
                )}
                <Button variant="contained" href="/accounting/fuel-reports" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange">
                    Go Back
                </Button>
            </Stack>
            <Stack sx={{ mt: 5, mb: 2 }} direction="column" alignItems="start" spacing={4}>
                <Typography variant="h4" color="primary">
                    Fuel Consumption Details
                </Typography>

                <AllFuelDriverViewForm />
            </Stack>
        </>
    );
}

export default FuelReportsByTruckId;
