import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import RemoveIcon from '@mui/icons-material/Remove';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { LoadingButton } from '@mui/lab';
import { MenuItem, TextField, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { queryClient } from 'index';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import './expenses.css';
import { useMemo } from 'react';

const Schema = Yup.object().shape({
    customer: Yup.string().required('Payee is required'),
    payment_date: Yup.date().typeError('Invalid date').required('Payment date is required'),
    payment_method: Yup.string().required('Payment method is required'),
    // refference_no: Yup.string().required('Refference no is required'),
    // deposit_to: Yup.string().required('Deposit to no is required'),
    // memo: Yup.string().required('Memo is required'),
    // amount_received: Yup.string().required('Amount received is required'),
    // attachment: Yup.mixed().required('attachment is required'),
    received_payment_item: Yup.array().of(
        Yup.object().shape({
            // description: Yup.string().required('Description is required'),
            // due_date: Yup.date().typeError('Invalid date').required('Due date is required'),
            original_amount: Yup.string().required('Original amount is required')
            // open_balance: Yup.string().required('Open balance is required'),
            // payment: Yup.string().required('Payment is required')
        })
    )
});

// Initial values for Formik form validation
function EditReceivedPaymentModal({
    editReceivedPaymentDrawer,
    setEditReceivedPaymentDrawer,
    receivedPayment,
    setClickedOptionId,
    changeEditReceivedPaymentStatus,
    search,
    page,
    rowsPerPage
}) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const isMobile = useMediaQuery('(max-width:600px)');

    const initialValues = {
        customer: receivedPayment?.customer,
        customer_name: receivedPayment?.customer_name,
        payment_date: receivedPayment?.payment_date,
        payment_method: receivedPayment?.payment_method,
        refference_no: receivedPayment?.refference_no,
        deposit_to: receivedPayment?.deposit_to,
        memo: receivedPayment?.memo,
        amount_received: receivedPayment?.amount_received,
        attachment: receivedPayment?.attachment,
        received_payment_item: receivedPayment?.received_payment_item || []
    };

    const addAmountDescription = () => {
        formik.setValues((prev) => ({
            ...prev,
            received_payment_item: [
                ...prev.received_payment_item,
                {
                    invoice: '',
                    description: '',
                    due_date: '',
                    original_amount: '',
                    open_balance: '',
                    payment: '',
                    open: false
                }
            ]
        }));
    };

    const editReceivedPayment = async (formData) => {
        const response = await axios.put(`/v1/api/accounting/received-payment/${receivedPayment?.id}/`, formData, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response;
    };

    const {
        mutate: editReceivedPaymentMutate,
        isPending: postReceivedPaymentPending,
        status: postReceivedPaymentStatus
    } = useMutation({
        mutationFn: editReceivedPayment,
        onSuccess: async () => {
            toast.success('Received payment updated successfully');
            setEditReceivedPaymentDrawer(false);
            formik.resetForm();
            setClickedOptionId((prev) => ({ id: receivedPayment.id, open: !prev.open }));
            changeEditReceivedPaymentStatus({
                id: receivedPayment?.id,
                status: 'idle'
            });

            return await queryClient.invalidateQueries({ queryKey: ['received-payment', search, page, rowsPerPage] });
        }
    });

    useEffect(() => {
        changeEditReceivedPaymentStatus(postReceivedPaymentStatus);
    }, [postReceivedPaymentStatus]);

    const removeAmountDescription = (index) => {
        formik.setValues((prev) => ({
            ...prev,
            received_payment_item: prev.received_payment_item.filter((_amount, i) => i !== index)
        }));
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Schema,
        onSubmit: (values, _action) => {
            // Deep clone the values object
            const data = JSON.parse(JSON.stringify(values));
            delete data.customer_name;

            // Map over the received_payment_item array and transform each item
            data.received_payment_item = data.received_payment_item.map((amountDes) => {
                const new_amount = { ...amountDes };
                delete new_amount["submitted_date"]
                delete new_amount["load"]

                // Remove keys with empty values from the new_amount object
                for (const key in new_amount) {
                    if (new_amount[key] === null || new_amount[key] === undefined || new_amount[key] === '') {
                        delete new_amount[key];
                    }
                }
                if (new_amount.due_date) {
                    new_amount.due_date = format(new Date(new_amount.due_date), 'yyyy-MM-dd');
                }
                return new_amount;
            });
            data.amount_received = values.received_payment_item.reduce((sum, item) => sum + parseFloat(item.payment || 0), 0).toFixed(2);

            // Remove keys with empty values from the data object
            for (const key in data) {
                if (data[key] === null || data[key] === undefined || data[key] === '') {
                    delete data[key];
                }
            }

            if (values.attachment) {
                data.attachment = values.attachment;
            }

            if (data.payment_date) {
                data.payment_date = format(new Date(data.payment_date), 'yyyy-MM-dd');
            }
            data.received_payment_item = JSON.stringify(data.received_payment_item);
            // Create a new FormData object
            const formData = new FormData();

            // Append the transformed data to the formData object
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            editReceivedPaymentMutate(formData);
        }
    });

    const totalAmount = useMemo(() => {
        return formik.values.received_payment_item.reduce((sum, item) => sum + parseFloat(item.payment || 0), 0).toFixed(2);
    }, [formik.values.received_payment_item]);

    return (
        <Drawer
            open={editReceivedPaymentDrawer}
            anchor="right"
            onClose={() => {
                setEditReceivedPaymentDrawer(false);
            }}
        >
            <div style={{ width: isMobile ? '100vw' : '80vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Update received payment
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setEditReceivedPaymentDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <TextField
                            select
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="customer"
                            label="Customer"
                            value={formik.values.customer}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.customer && formik.errors.customer)}
                            helperText={formik.touched.customer && formik.errors.customer}
                        >
                            <MenuItem value={formik.values.customer}>{formik.values.customer_name}</MenuItem>
                        </TextField>
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                className="text-input"
                                label="Payment Date"
                                openTo="day"
                                views={['year', 'month', 'day']}
                                value={formik.values.payment_date}
                                onChange={(newValue) => {
                                    formik.setFieldValue('payment_date', newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        name="payment_date"
                                        color="orange"
                                        fullWidth
                                        {...params}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.payment_date && formik.errors.payment_date)}
                                        helperText={formik.touched.payment_date && formik.errors.payment_date}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <TextField
                            fullWidth
                            select
                            className="text-input"
                            color="orange"
                            name="payment_method"
                            label="Payment Method"
                            value={formik.values.payment_method}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.payment_method && formik.errors.payment_method)}
                            helperText={formik.touched.payment_method && formik.errors.payment_method}
                        >
                            <MenuItem value={'Bank'}>Bank</MenuItem>
                            <MenuItem value={'Credit Card'}>Credit Card</MenuItem>
                        </TextField>
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="refference_no"
                            label="Refference No"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.refference_no}
                            error={Boolean(formik.touched.refference_no && formik.errors.refference_no)}
                            helperText={formik.touched.refference_no && formik.errors.refference_no}
                        />
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="deposit_to"
                            label="Deposit To"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.deposit_to}
                            error={Boolean(formik.touched.deposit_to && formik.errors.deposit_to)}
                            helperText={formik.touched.deposit_to && formik.errors.deposit_to}
                        />
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="memo"
                            label="Memo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.memo}
                            error={Boolean(formik.touched.memo && formik.errors.memo)}
                            helperText={formik.touched.memo && formik.errors.memo}
                        />
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="amount_received"
                            label="Amount Received"
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={totalAmount}
                            error={Boolean(formik.touched.amount_received && formik.errors.amount_received)}
                            helperText={formik.touched.amount_received && formik.errors.amount_received}
                        />
                    </div>

                    <div>
                        <div style={{ borderBottom: '1px solid #80808052', display: 'flex', justifyContent: 'space-evenly', fontWeight: '600' }}>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '20%' }}></div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}>#</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Description</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Load</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Submitted Date</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Due Date</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Original Amount</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Open Balance</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Payment</div>
                            <div style={{ textAlign: 'center', width: '20%' }}></div>
                        </div>
                        <AnimatePresence>
                            {formik.values.received_payment_item.map((amountDes, index) => (
                                <div key={index}>
                                    <AnimatePresence>
                                        {amountDes.open ? (
                                            <motion.div
                                                style={{
                                                    borderBottom: '1px solid #80808052',
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0.5 }}
                                            >
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '20%',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    <ViewCompactIcon sx={{ cursor: 'pointer' }} />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {index + 1}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {amountDes.description}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {amountDes.load}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {format(new Date(amountDes.submitted_date || new Date()), 'yyyy-MM-dd')}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {amountDes.due_date}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    ${amountDes.original_amount}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    ${amountDes.open_balance}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    ${amountDes.payment}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                    <RemoveIcon
                                                        sx={{
                                                            backgroundColor: formik.values.received_payment_item.length == 1 ? '#ff8995' : 'red',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            fontSize: '20px'
                                                        }}
                                                        onClick={() => {
                                                            if (formik.values.received_payment_item.length > 1) {
                                                                removeAmountDescription(index);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </motion.div>
                                        ) : (
                                            <motion.div
                                                style={{
                                                    borderBottom: '1px solid #80808052',
                                                    borderTop: '1px solid #80808052',
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                                initial={{ scale: 1, opacity: 0 }}
                                                animate={{ scale: 1.05, opacity: 1 }}
                                                exit={{ scale: 1, opacity: 0.5 }}
                                            >
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '20%',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <AddIcon
                                                        sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%', fontSize: '20px' }}
                                                        // onClick={() => addAmountDescription()}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {index + 1}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        onBlur={formik.handleBlur}
                                                        name={`received_payment_item[${index}].description`}
                                                        value={amountDes.description}
                                                        onChange={formik.handleChange}
                                                        className={
                                                            formik.touched?.received_payment_item?.[index]?.description &&
                                                            formik.errors?.received_payment_item?.[index]?.description &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderRight: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter description"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    {amountDes.load}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    {format(new Date(amountDes.submitted_date || new Date()), 'yyyy-MM-dd')}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="date"
                                                        onBlur={formik.handleBlur}
                                                        name={`received_payment_item[${index}].due_date`}
                                                        value={amountDes.due_date}
                                                        className={
                                                            formik.touched?.received_payment_item?.[index]?.due_date &&
                                                            formik.errors?.received_payment_item?.[index]?.due_date &&
                                                            'form-error'
                                                        }
                                                        onChange={formik.handleChange}
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter due date"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        disabled
                                                        type="number"
                                                        name={`received_payment_item[${index}].original_amount`}
                                                        onBlur={formik.handleBlur}
                                                        value={amountDes.original_amount}
                                                        onChange={formik.handleChange}
                                                        className={
                                                            formik.touched?.received_payment_item?.[index]?.original_amount &&
                                                            formik.errors?.received_payment_item?.[index]?.original_amount &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderLeft: '0px',
                                                            borderRight: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter original amount"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        disabled
                                                        type="number"
                                                        name={`received_payment_item[${index}].open_balance`}
                                                        onBlur={formik.handleBlur}
                                                        value={amountDes.open_balance}
                                                        onChange={formik.handleChange}
                                                        className={
                                                            formik.touched?.received_payment_item?.[index]?.open_balance &&
                                                            formik.errors?.received_payment_item?.[index]?.open_balance &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderLeft: '0px',
                                                            borderRight: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter open balance"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="number"
                                                        name={`received_payment_item[${index}].payment`}
                                                        onBlur={formik.handleBlur}
                                                        value={amountDes.payment}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`received_payment_item[${index}].payment`, e.target.value);
                                                            formik.setFieldValue(
                                                                `received_payment_item[${index}].open_balance`,
                                                                Number(formik.values.received_payment_item[index].original_amount) - Number(e.target.value)
                                                            );
                                                        }}
                                                        className={
                                                            formik.touched?.received_payment_item?.[index]?.payment &&
                                                            formik.errors?.received_payment_item?.[index]?.payment &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderLeft: '0px',
                                                            borderRight: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter payment"
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                                                    <button type='button' disabled style={{ outline: 'none', background: 'transparent', border: 'none'}}>

                                                        <RemoveIcon
                                                            sx={{
                                                                backgroundColor: formik.values.received_payment_item.length == 1 ? '#ff8995' : 'red',
                                                                color: 'white',
                                                                borderRadius: '50%',
                                                                fontSize: '20px'
                                                            }}
                                                            onClick={() => {
                                                                if (formik.values.received_payment_item.length > 1) {
                                                                    removeAmountDescription(index);
                                                                } else {
                                                                    formik.setValues((prev) => ({
                                                                        ...prev,
                                                                        received_payment_item: initialValues.received_payment_item
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                    </button>
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            ))}
                        </AnimatePresence>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: 100, fontSize: 20, marginTop: 20, marginRight: 50 }}>
                        <strong>Total</strong>
                        <strong>${totalAmount}</strong>
                    </div>

                    <div style={{ marginBottom: 20, marginTop: 50, width: '50%' }}>
                        <Typography variant="h4">Attachment</Typography>
                        <input
                            type="file"
                            onChange={(e) => {
                                formik.setFieldValue('attachment', e.target.files[0]);
                            }}
                            accept=".pdf, .png, .jpg, .jpeg"
                            style={{ display: 'none' }}
                            id="file-input"
                        />
                        <label htmlFor="file-input">
                            <Box
                                sx={{
                                    border: '2px dashed orange',
                                    borderRadius: '5px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    width: '100%',
                                    cursor: 'pointer',
                                    backgroundColor: '#f9f9f9'
                                }}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    formik.setFieldValue('attachment', e.dataTransfer.files[0]);
                                }}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                    <DownloadIcon />
                                    <Typography variant="h6" gutterBottom>
                                        <span style={{ fontWeight: 'bold' }}>Choose a file</span> or drag it here
                                    </Typography>
                                </div>
                                <span style={{ color: 'green' }}>{formik.values.attachment && formik.values.attachment.name}</span>
                            </Box>
                        </label>
                    </div>
                    <LoadingButton loading={postReceivedPaymentPending} type="submit" fullWidth variant="contained" style={{ backgroundColor: '#EB812E' }}>
                        Save received payment
                    </LoadingButton>
                </form>
            </div>
        </Drawer>
    );
}

export default EditReceivedPaymentModal;
