import PageLayoutWithBack from 'layout/PageLayoutWithBack';
import AdminTruckForm from 'ui-component/fleet/forms/AdminTruckForm';

import TruckForm from 'ui-component/fleet/forms/TruckForm';

function EditTruck() {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.user_type === 'super_dispatcher_admin' ? '' : loginInfoFromStorage.company_id;
    return <PageLayoutWithBack>{cid === '' ? <AdminTruckForm /> : <TruckForm />}</PageLayoutWithBack>;
}

export default EditTruck;
