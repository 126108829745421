// project imports
import { useState, useEffect } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import MainCard from 'ui-component/cards/MainCard';

import { useDispatch, useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';

import { useNavigate } from 'react-router';
import AllFileConfigurationForm from 'ui-component/setup/forms/AllFileConfigurationForm';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLayout from 'layout/PageLayout';
// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '8px',
                        borderBottom: 'none',
                        display: 'block'
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});
const AllFileType = () => {
    const [value, setValue] = useState(5);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    return (
        <>
            <MainCard>
            <PageLayout title="Setup">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <ThemeProvider theme={theme}>
                        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                            <Tab label="Users" onClick={() => navigate(`/setup/user/all`)} />
                            <Tab label="Integration"  onClick={() => navigate(`/setup/integration`)}/>
                            <Tab label="Data Import" onClick={() => navigate(`/setup/data/import`)} />
                            {/* <Tab label="File Configuration" onClick={() => navigate(`/setup/configuration/file`)} /> */}
                            <Tab label="Invoice Expense Item" onClick={() => navigate(`/setup/invoice/expense/item`)} />
                            <Tab label="Settlement Expense Item" onClick={() => navigate(`/setup/settlement/expense/item`)} />
                        </Tabs>
                    </ThemeProvider>
                </Box>

                <AllFileConfigurationForm />
                </PageLayout>
            </MainCard>
        </>
    );
};

export default AllFileType;
