import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, LinearProgress, Typography, Box, Stack, Autocomplete, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';

function ImportPanelAz({ onChangeHandler, loadingFile, subTitle, subBody, dlTxt, file, driver, changeDriver }) {
    const [options, setOptions] = useState([]);
    const [driverList, setDriverList] = useState();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    useEffect(() => {
        if (!driverList) {
            fetchDriver();
        }
    }, [driverList]);
    const fetchDriver = async () => {
        try {
            const { data } = await axios.get(
                `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100&search=`,
                config
            );
            setDriverList(data.results);
            setOptions(data.results);
        } catch (error) {
            console.log(error.response.data);
        }
    };
    return (
        <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography fontSize="24px" sx={{ mb: 1, color: '#142953', fontFamily: 'Lexend' }}>
                    Import
                </Typography>
                <Typography variant="h5" gutterBottom color="primary">
                    {subTitle}
                </Typography>
                <Typography variant="caption">
                    {subBody}{' '}
                    <Link to={file} download={dlTxt} target="_blank" rel="noreferrer">
                        Sample file
                    </Link>
                </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <Autocomplete
                    className="text-input"
                    fullWidth
                    options={options && options.length > 0 ? options : []}
                    value={driver}
                    getOptionLabel={(option) => (option ? option.name : '')}
                    renderInput={(params) => <TextField {...params} fullWidth label="Select Driver" />}
                    onChange={(event, values) => {
                        changeDriver(event, values);
                    }}
                />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <Stack sx={{ justifyContent: { sm: 'flex-start', md: 'flex-end' } }} direction="row" alignItems="flex-end" spacing={2}>
                    <FormControl variant="outlined" align="right">
                        <input
                            color="primary"
                            type="file"
                            // value={file}
                            onChange={onChangeHandler}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                            // onInputCapture={(e) => fileUploadHandler(e)}
                            id="icon-button-file"
                            style={{ display: 'none' }}
                            // disabled={values.customer === 0 ? true : false}
                        />
                        <label htmlFor="icon-button-file">
                            <LoadingButton
                                variant="contained"
                                sx={{
                                    color: 'white',
                                    fontFamily: 'Lexend',
                                    fontWeight: 500,
                                    lineHeight: '1.3rem',
                                    fontSize: '16px',
                                    fontStyle: 'normal'
                                }}
                                component="span"
                                size="large"
                                color="orange"
                                endIcon={<CloudUploadIcon />}
                                loadingPosition="end"
                                loading={loadingFile}
                            >
                                Upload
                            </LoadingButton>
                        </label>
                    </FormControl>
                </Stack>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                {loadingFile ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress sx={{ mt: 2 }} />
                        <Typography variant="h5" sx={{ mt: 2, color: 'red' }}>
                            This may take a while, Please do not close or refresh this page!
                        </Typography>
                    </Box>
                ) : (
                    ''
                )}
            </Grid>
        </Grid>
    );
}

ImportPanelAz.propTypes = {
    onChangeHandler: PropTypes.func,
    loadingFile: PropTypes.bool,
    subBody: PropTypes.string,
    subTitle: PropTypes.string,
    dlTxt: PropTypes.string,
    file: PropTypes.any
};

export default ImportPanelAz;
