import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { LoadingButton } from '@mui/lab';
import { MenuItem, TextField, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { queryClient } from 'index';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import './expenses.css';
import { NumberOnlyInput } from 'utils/NumberOnlyInput';
import { useMemo } from 'react';

const salesReceiptSchema = Yup.object().shape({
    customer: Yup.string().required('Customer is required'),
    email: Yup.string().email().required('Email is required'),
    // billing_address: Yup.string().required('Billing address is required'),
    sales_receipt_date: Yup.date().typeError('Invalid date').required('Sales receipt date is required'),
    // tags: Yup.string().required('Tags is required'),
    payment_method: Yup.string().required('Payment method is required'),
    // refference_number: Yup.string().required('Refference number is required'),
    // deposit_to: Yup.string().required('Deposit to is required'),
    sales_receipt_item: Yup.array().of(
        Yup.object().shape({
            // product: Yup.string().required('Product is required'),
            // description: Yup.string().required('Description is required'),
            quantity: Yup.string().required('Quantity is required'),
            rate: Yup.string().required('Rate is required')
            // amount: Yup.string().required('Amount is required')
        })
    )
});

// Initial values for Formik form validation
function EditSalesReceiptModal({
    editSalesReceiptDrawer,
    setEditSalesReceiptDrawer,
    salesReceipt,
    setClickedOptionId,
    changeEditSalesReceiptStatus,
    search,
    page,
    rowsPerPage
}) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const isMobile = useMediaQuery('(max-width:600px)');

    const salesReceiptInitialValues = {
        id: salesReceipt?.id || '',
        customer: salesReceipt?.customer || '',
        email: salesReceipt?.email || '',
        billing_address: salesReceipt?.billing_address || '',
        sales_receipt_date: salesReceipt?.sales_receipt_date || '',
        tags: salesReceipt?.tags || '',
        payment_method: salesReceipt?.payment_method || '',
        refference_number: salesReceipt?.refference_number || '',
        deposit_to: salesReceipt?.deposit_to || '',
        sales_receipt_item: salesReceipt?.sales_receipt_item || []
    };

    if (!salesReceipt?.sales_receipt_item[0]) {
        salesReceiptInitialValues['sales_receipt_item'] = [
            {
                product: '',
                description: '',
                quantity: '',
                rate: '',
                amount: '',
                open: true
            }
        ];
    }

    const addSalesReceiptItem = () => {
        formik.setValues((prev) => ({
            ...prev,
            sales_receipt_item: [
                ...prev.sales_receipt_item,
                {
                    product: '',
                    description: '',
                    quantity: '',
                    rate: '',
                    amount: '',
                    open: true
                }
            ]
        }));
    };

    const editSalesReceipt = async (formData) => {
        const response = await axios.put(`/v1/api/accounting/sales-receipt/${salesReceipt.id}/`, formData, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response;
    };

    const {
        mutate: editSalesReceiptMutate,
        isPending: postSalesReceiptPending,
        status: postSalesReceiptStatus
    } = useMutation({
        mutationFn: editSalesReceipt,
        onSuccess: async () => {
            toast.success('sales receipt updated successfully');
            formik.resetForm();
            setClickedOptionId((prev) => ({ id: salesReceipt.id, open: !prev.open }));
            await queryClient.invalidateQueries({ queryKey: ['sales-receipt', search, page, rowsPerPage] });
            setEditSalesReceiptDrawer(false);
            changeEditSalesReceiptStatus({
                id: salesReceipt?.id,
                status: 'idle'
            });
        }
    });

    useEffect(() => {
        changeEditSalesReceiptStatus({
            id: salesReceipt?.id,
            status: postSalesReceiptStatus
        });
    }, [postSalesReceiptStatus]);

    const removeSalesReceiptItem = (index) => {
        formik.setValues((prev) => ({
            ...prev,
            sales_receipt_item: prev.sales_receipt_item.filter((_amount, i) => i !== index)
        }));
    };

    const formik = useFormik({
        initialValues: salesReceiptInitialValues,
        validationSchema: salesReceiptSchema,
        onSubmit: (values, _action) => {
            // Deep clone the values object
            const data = JSON.parse(JSON.stringify(values));

            // Map over the sales_receipt_item array and transform each item
            data.sales_receipt_item = data.sales_receipt_item.map((item) => {
                const transformedItem = {
                    quantity: Number(item.quantity),
                    rate: item.rate,
                    amount: Number(item.quantity) * Number(item.rate)
                };
                if (item.product) {
                    transformedItem['product'] = item.product;
                }
                if (item.description) {
                    transformedItem['description'] = item.description;
                }

                if (item?.id) {
                    transformedItem.id = item.id;
                }

                return transformedItem;
            });

            data.sales_receipt_item = JSON.stringify(data.sales_receipt_item);

            if (data.sales_receipt_date) {
                data.sales_receipt_date = format(new Date(data.sales_receipt_date), 'yyyy-MM-dd');
            } else {
                delete data.sales_receipt_date;
            }

            // Create a new FormData object
            const formData = new FormData();

            // Append the transformed data to the formData object
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            editSalesReceiptMutate(formData);
        }
    });

    const totalAmount = useMemo(() => {
        return formik.values.sales_receipt_item.reduce((sum, item) => sum + parseFloat(item.rate || 0) * parseFloat(item.quantity || 0), 0).toFixed(2);
    }, [formik.values.sales_receipt_item]);

    return (
        <Drawer
            open={editSalesReceiptDrawer}
            anchor="right"
            onClose={() => {
                setEditSalesReceiptDrawer(false);
            }}
        >
            <div style={{ width: isMobile ? '100vw' : '70vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Update sales receipt
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setEditSalesReceiptDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="customer"
                            label="Customer"
                            value={formik.values.customer}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.customer && formik.errors.customer)}
                            helperText={formik.touched.customer && formik.errors.customer}
                        />
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="email"
                            label="Email"
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.email && formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                className="text-input"
                                label="Sales Receipt Date"
                                openTo="day"
                                views={['year', 'month', 'day']}
                                value={formik.values.sales_receipt_date}
                                onChange={(newValue) => {
                                    formik.setFieldValue('sales_receipt_date', newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        name="sales_receipt_date"
                                        color="orange"
                                        fullWidth
                                        {...params}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.sales_receipt_date && formik.errors.sales_receipt_date)}
                                        helperText={formik.touched.sales_receipt_date && formik.errors.sales_receipt_date}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <TextField
                            fullWidth
                            select
                            className="text-input"
                            color="orange"
                            name="payment_method"
                            label="Payment Method"
                            value={formik.values.payment_method}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.payment_method && formik.errors.payment_method)}
                            helperText={formik.touched.payment_method && formik.errors.payment_method}
                        >
                            <MenuItem value={'Bank'}>Bank</MenuItem>
                            <MenuItem value={'Credit Card'}>Credit Card</MenuItem>
                        </TextField>
                    </div>
                    <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        className="text-input"
                        color="orange"
                        name="billing_address"
                        label="Billing Address"
                        value={formik.values.billing_address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.touched.billing_address && formik.errors.billing_address)}
                        helperText={formik.touched.billing_address && formik.errors.billing_address}
                    />
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="tags"
                            label="Tags"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.tags}
                            error={Boolean(formik.touched.tags && formik.errors.tags)}
                            helperText={formik.touched.tags && formik.errors.tags}
                        />
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="refference_number"
                            label="Refference Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.refference_number}
                            error={Boolean(formik.touched.refference_number && formik.errors.refference_number)}
                            helperText={formik.touched.refference_number && formik.errors.refference_number}
                        />
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="deposit_to"
                            label="Deposit To"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.deposit_to}
                            error={Boolean(formik.touched.deposit_to && formik.errors.deposit_to)}
                            helperText={formik.touched.deposit_to && formik.errors.deposit_to}
                        />
                    </div>

                    <div>
                        <div style={{ borderBottom: '1px solid #80808052', display: 'flex', justifyContent: 'space-evenly', fontWeight: '600' }}>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}></div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}>#</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Product</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Description</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Quantity</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Rate</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Amount</div>
                            <div style={{ textAlign: 'center', width: '10%' }}></div>
                        </div>
                        <AnimatePresence>
                            {formik.values.sales_receipt_item.map((item, index) => (
                                <div key={index}>
                                    <AnimatePresence>
                                        {item.open ? (
                                            <motion.div
                                                style={{
                                                    borderBottom: '1px solid #80808052',
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0.5 }}
                                            >
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            sales_receipt_item: prev.sales_receipt_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    <ViewCompactIcon sx={{ cursor: 'pointer' }} />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            sales_receipt_item: prev.sales_receipt_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {index + 1}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            sales_receipt_item: prev.sales_receipt_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {item.product}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            sales_receipt_item: prev.sales_receipt_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {item.description}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            sales_receipt_item: prev.sales_receipt_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {item.quantity}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            sales_receipt_item: prev.sales_receipt_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    ${parseFloat(item.rate || 0).toFixed(2)}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            sales_receipt_item: prev.sales_receipt_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    ${parseFloat(item.quantity * item.rate || 0).toFixed(2)}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }}>
                                                    <RemoveIcon
                                                        sx={{
                                                            backgroundColor: formik.values.sales_receipt_item.length == 1 ? '#ff8995' : 'red',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            fontSize: '20px'
                                                        }}
                                                        onClick={() => {
                                                            if (formik.values.sales_receipt_item.length > 1) {
                                                                removeSalesReceiptItem(index);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </motion.div>
                                        ) : (
                                            <motion.div
                                                style={{
                                                    borderBottom: '1px solid #80808052',
                                                    borderTop: '1px solid #80808052',
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                                initial={{ scale: 1, opacity: 0 }}
                                                animate={{ scale: 1.05, opacity: 1 }}
                                                exit={{ scale: 1, opacity: 0.5 }}
                                            >
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%'
                                                    }}
                                                >
                                                    <AddIcon
                                                        sx={{
                                                            backgroundColor: 'green',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            fontSize: '20px',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => addSalesReceiptItem()}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            sales_receipt_item: prev.sales_receipt_item.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {index + 1}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        onBlur={formik.handleBlur}
                                                        name={`sales_receipt_item[${index}].product`}
                                                        value={item.product}
                                                        onChange={formik.handleChange}
                                                        className={
                                                            formik.touched?.sales_receipt_item?.[index]?.product &&
                                                            formik.errors?.sales_receipt_item?.[index]?.product &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderRight: '0px',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter product"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        onBlur={formik.handleBlur}
                                                        name={`sales_receipt_item[${index}].description`}
                                                        value={item.description}
                                                        className={
                                                            formik.touched?.sales_receipt_item?.[index]?.description &&
                                                            formik.errors?.sales_receipt_item?.[index]?.description &&
                                                            'form-error'
                                                        }
                                                        onChange={formik.handleChange}
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter description"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="number"
                                                        name={`sales_receipt_item[${index}].quantity`}
                                                        onBlur={formik.handleBlur}
                                                        value={item.quantity}
                                                        onChange={formik.handleChange}
                                                        className={
                                                            formik.touched?.sales_receipt_item?.[index]?.quantity &&
                                                            formik.errors?.sales_receipt_item?.[index]?.quantity &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderLeft: '0px',
                                                            borderRight: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter quantity"
                                                        onInput={NumberOnlyInput}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="number"
                                                        name={`sales_receipt_item[${index}].rate`}
                                                        onBlur={formik.handleBlur}
                                                        value={item.rate}
                                                        onChange={formik.handleChange}
                                                        className={
                                                            formik.touched?.sales_receipt_item?.[index]?.rate &&
                                                            formik.errors?.sales_receipt_item?.[index]?.rate &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderLeft: '0px',
                                                            borderRight: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter rate"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    {parseFloat(item.quantity * item.rate || 0).toFixed(2)}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }}>
                                                    <RemoveIcon
                                                        sx={{
                                                            backgroundColor: formik.values.sales_receipt_item.length == 1 ? '#ff8995' : 'red',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            fontSize: '20px'
                                                        }}
                                                        onClick={() => {
                                                            if (formik.values.sales_receipt_item.length > 1) {
                                                                removeSalesReceiptItem(index);
                                                            } else {
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    sales_receipt_item: salesReceiptInitialValues.sales_receipt_item
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            ))}
                        </AnimatePresence>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: 100, fontSize: 20, marginTop: 20, marginRight: 150 }}>
                        <strong>Total</strong>
                        <strong>${totalAmount}</strong>
                    </div>

                    <LoadingButton
                        loading={postSalesReceiptPending}
                        type="submit"
                        fullWidth
                        variant="contained"
                        style={{ backgroundColor: '#EB812E', marginTop: 20 }}
                    >
                        Save sales receipt
                    </LoadingButton>
                </form>
            </div>
        </Drawer>
    );
}

export default EditSalesReceiptModal;
