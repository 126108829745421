import MaterialTable from '@material-table/core';
// import MaterialTable from 'material-table';

import { format, parseISO } from 'date-fns';
import TABLE_ICONS from 'tableIcons';

// import { Button, Grid, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';

import {
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    InputAdornment,
    Link,
    Menu,
    MenuItem,
    Stack,
    TablePagination,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import AddFormButton from 'ui-component/buttons/AddFormButton';

import { createRef, useEffect } from 'react';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewSettlememntModal from 'ui-component/modals/ViewSettlememntModal';
import SettlementFilter from 'ui-component/filters/SettlementFilter';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';
import axios from 'axios';
import LoadStatus from 'ui-component/buttons/LoadStatus';
import moment from 'moment';
import user_types from 'constant/user_types';
import { Box } from '@mui/system';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Status from 'ui-component/buttons/Status';

const AllSettlementForm = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();
    const tableRef = createRef();
    const dispatch = useDispatch();

    const [open1, setOpen1] = useState(false);
    const [stlmId, setStlmId] = useState();
    const [deleting, setDeleting] = useState(false);

    const [statusChecked, setStatusChecked] = useState([]);
    const [checked, setChecked] = useState(['doh']);

    const [startDop, setStartDop] = useState('');
    const [endDop, setEndDop] = useState('');
    const [startDoc, setStartDoc] = useState('');
    const [endDoc, setEndDoc] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startAmt, setStartAmt] = useState('');
    const [endAmt, setEndAmt] = useState('');

    const [driver, setDriver] = useState('');

    const [searchSts, setSearchSts] = useState('');
    const [searchFltr, setSearchFltr] = useState('');
    const [selected, setSelected] = useState([]);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const cid = loginInfoFromStorage.user_type === 'super_dispatcher_admin' ? '' : loginInfoFromStorage.company_id;
    const permissions = loginInfoFromStorage.permissions || {};

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const handleCloseModal = () => {
        setOpen1(false);
    };
    const handleClickOpenModal = (startDate, endDate, id, stlmtid) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setDriver(id);
        setStlmId(stlmtid);

        setOpen1(true);
        // setStlmId(stId);
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        if (newChecked1.includes('active') && newChecked1.includes('inactive')) {
            setSearchSts();
        } else if (newChecked1.includes('active')) {
            setSearchSts(true);
        } else if (newChecked1.includes('inactive')) {
            setSearchSts(false);
        }
    };
    const deleteSettlement = async (id, popupState) => {
        try {
            setDeleting(true);
            const response = await axios.delete(`/v1/api/accounting/stlm/view/${id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage.token}`
                }
            });
            dispatch(openSuccessSnackbar('Settlement  Deleted successfully'));

            window.location.reload();
            popupState.close();
        } catch (error) {
            console.log(error);
            dispatch(openErrorSnackbar(error.response.data.error));
        } finally {
            setDeleting(false);
        }
    };
    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const handleDownload = async (id) => {
        const body = {
            action: 'GET',
            url: `v1/api/accounting/stlm/generate-pdf/${id}`,
            changed_object: 'Accounting Settlement',
            event_category: 'Get',
            change_summary: `Downloaded  Load Info`
        };
        window.open(`/v1/api/accounting/stlm/generate-pdf/${id}/`, '_blank', 'noreferrer');
        try {
            await axios.post(`../v1/api/users/activity-logs/`, body, config);
        } catch (error) {
            console.log(error);
        }
    };

    const reset = () => {
        setStartDop('');
        setEndDop('');
        setStartDoc('');
        setEndDoc('');
        setStartDate('');
        setEndDate('');
        setStartAmt('');
        setEndAmt('');
        setChecked([]);
        if (tableRef.current) {
            console.log('tableRef.current:', tableRef.current);
            tableRef.current.onQueryChange();
        } else {
            console.error('tableRef.current is undefined');
        }
    };

    const editSettlement = (id, popupState) => {
        popupState.close();
        navigate(`/accounting/settlement/edit/${id}`);
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Payment Id</th>
                    <th className="headerTd rightHidBor">Type</th>
                    <th className="headerTd rightHidBor">Truck</th>

                    <th className="headerTd rightHidBor "> Name</th>
                    <th className="headerTd rightHidBor">Pay Start Date</th>
                    <th className="headerTd rightHidBor">Pay End Date</th>
                    <th className="headerTd rightHidBor">Amount Paid</th>
                    <th className="headerTd rightHidBor">Pay Date</th>
                    <th className="headerTd rightHidBor">Payment Status</th>

                    <th className="headerTd rightHidBor">Bank A/C#</th>
                    <th className="headerTd rightHidBor">Load Info</th>
                    <th className="headerTd ">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.dsp_drv_stlmnt_id}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_id ? 'Driver' : 'Owner'}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Link
                        component="button"
                        onClick={() => {
                            navigate(`/fleet/truck/view/${rowData.data.truck_id}`);
                        }}
                    >
                        {rowData.data.truck_no}
                    </Link>
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_id ? (
                        <Link
                            component="button"
                            onClick={() => {
                                navigate(`/fleet/driver/view/${rowData.data.driver_id}`);
                            }}
                        >
                            {rowData.data.driver_name}
                        </Link>
                    ) : (
                        <span>{rowData.data.driver_name}</span>
                    )}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.start_date}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.end_date}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.income}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.payment_date ? moment.utc(rowData.data.payment_date).format('YYYY-MM-DD') : ''}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <LoadStatus
                            text={rowData.data.payment_status}
                            type={
                                rowData.data.payment_status === 'paid'
                                    ? 'success'
                                    : rowData.data.payment_status === 'rejected'
                                    ? 'error'
                                    : rowData.data.payment_status === 'pending'
                                    ? 'inprog'
                                    : ''
                            }
                        />
                    </Stack>
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_acc}
                </td>
                {/* Custom cell or additional cells */}
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton
                        onClick={() =>
                            handleClickOpenModal(rowData.data.start_date, rowData.data.end_date, rowData.data.driver_id, rowData.data.dsp_drv_stlmnt_id)
                        }
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDownload(rowData.data.dsp_drv_stlmnt_id)}>
                        <DownloadIcon />
                    </IconButton>
                </td>
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <PopupState variant="popover" popupId={`demoMenu-${rowData.data.dsp_drv_stlmnt_id}`}>
                        {(popupState) => (
                            <div>
                                <IconButton {...bindTrigger(popupState)} size="large">
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    {...bindMenu(popupState)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    {(permissions.dspdriversettlement && permissions.dspdriversettlement.change) || user_types.includes(user_type) ? (
                                        <MenuItem onClick={() => editSettlement(rowData.data.dsp_drv_stlmnt_id, popupState)}>Edit</MenuItem>
                                    ) : null}
                                    {(permissions.dspdriversettlement && permissions.dspdriversettlement.delete) || user_types.includes(user_type) ? (
                                        <MenuItem disabled={deleting} onClick={() => deleteSettlement(rowData.data.dsp_drv_stlmnt_id, popupState)}>
                                            Remove
                                        </MenuItem>
                                    ) : null}
                                </Menu>
                            </div>
                        )}
                    </PopupState>
                </td>
            </tr>
        );
    };

    const fetchData = async (query) => {
        setLoading(true);
        console.log(query);

        const filters = [];
        if (startDop) filters.push(`start_hire_date=${encodeURIComponent(startDop)}`);
        if (endDop) filters.push(`end_hire_date=${encodeURIComponent(endDop)}`);
        if (startDoc) filters.push(`start_end_date=${encodeURIComponent(startDoc)}`);
        if (endDoc) filters.push(`end_end_date=${encodeURIComponent(endDoc)}`);
        if (startAmt) filters.push(`end_end_date=${encodeURIComponent(startAmt)}`);
        if (endAmt) filters.push(`end_end_date=${encodeURIComponent(endAmt)}`);
        // if (startPrice) filters.push(`end_end_date=${encodeURIComponent(startPrice)}`);
        // if (endPrice) filters.push(`end_end_date=${encodeURIComponent(endPrice)}`);
        if (statusChecked) filters.push(`status=${encodeURIComponent(statusChecked)}`);

        try {
            const url = `/v1/api/accounting/stlm/list?page=${query.page + 1}&page_size=${
                query.pageSize
            }&search=${searchFltr}&company_id=${cid}&start_payment_date=${startDop !== '' ? format(new Date(startDop), 'yyyy-MM-dd') : ''}&end_payment_date=${
                endDop !== '' ? format(new Date(endDop), 'yyyy-MM-dd') : ''
            }&dsp_drv_stlmnt_id__start_date=${startDoc !== '' ? format(new Date(startDoc), 'yyyy-MM-dd') : ''}&dsp_drv_stlmnt_id__end_date=${
                endDoc !== '' ? format(new Date(endDoc), 'yyyy-MM-dd') : ''
            }&start_amount=${startAmt}&end_amount=${endAmt}&is_active=${searchSts}`;

            const response = await fetch(url, config);
            const result = await response.json();

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 5 })
                // console.log(selected);
                .then((result) => {
                    setSelected(result.data || []);
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
        // console.log(selected);
        console.log(tableRef);
    }, [isMobile, isTablet, currPage, cardPerPage, tableRef.current]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    return (
        <>
            <Stack
                sx={{ mt: 5, mb: 2 }}
                direction={matchDownMd ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={matchDownMd ? 'flex-start' : 'center'}
                spacing={4}
            >
                <TextField
                    type="text"
                    size="small"
                    placeholder="Search By Truck, Amount, Start Date, End Date"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    // sx={{ width: { xs: '310px', sm: '350px' } }}
                    sx={{ width: { xs: '82vw', sm: '45vw', lg: '20vw' } }}
                    className="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        gap: '.5rem',
                        // flexDirection: 'row'
                        flexDirection: { xs: 'column', sm: 'row' }
                    }}
                >
                    <SettlementFilter
                        checked={checked}
                        handleChecked={handleChecked}
                        startDoc={startDoc}
                        startDocHandler={(e) => setStartDoc(e)}
                        endDoc={endDoc}
                        endDocHandler={(e) => setEndDoc(e)}
                        startDop={startDop}
                        startDopHandler={(e) => setStartDop(e)}
                        endDop={endDop}
                        endDopHandler={(e) => setEndDop(e)}
                        startAmt={startAmt}
                        startAmtHandler={(e) => setStartAmt(e.target.value)}
                        endAmt={endAmt}
                        endAmtHandler={(e) => setEndAmt(e.target.value)}
                        statusChecked={statusChecked}
                        handleStatus={handleStatus}
                        reset={reset}
                        filterHandler={() => {
                            if (tableRef.current) {
                                tableRef.current.onQueryChange();
                            } else {
                                console.error('tableRef.current is undefined on mobile');
                                const query = {
                                    page: 0,
                                    pageSize: 5
                                };

                                fetchData(query);
                            }
                        }}
                    />

                    {(permissions.dspdriversettlement && permissions.dspdriversettlement.add) || user_types.includes(user_type) ? (
                        <AddFormButton
                            url="/accounting/settlement/add"
                            btnText="ADD NEW SETTLEMENT"
                            sx={{
                                maxHeight: { xs: '6vh', sm: '4vh' },
                                minWidth: { xs: '17rem', sm: '14rem' }
                            }}
                        />
                    ) : null}
                </Box>
            </Stack>

            {isMobile ? (
                <>
                    {loading ? (
                        <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <LoadStatus
                                            text={driver.payment_status}
                                            type={
                                                driver.payment_status === 'paid'
                                                    ? 'success'
                                                    : driver.payment_status === 'rejected'
                                                    ? 'error'
                                                    : driver.payment_status === 'pending'
                                                    ? 'inprog'
                                                    : ''
                                            }
                                        />

                                        <PopupState variant="popover" popupId={`demoMenu-${driver.dsp_drv_stlmnt_id}`}>
                                            {(popupState) => (
                                                <div>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        {(permissions.dspdriversettlement && permissions.dspdriversettlement.change) ||
                                                        user_types.includes(user_type) ? (
                                                            <MenuItem onClick={() => editSettlement(driver.dsp_drv_stlmnt_id, popupState)}>Edit</MenuItem>
                                                        ) : null}
                                                        {(permissions.dspdriversettlement && permissions.dspdriversettlement.delete) ||
                                                        user_types.includes(user_type) ? (
                                                            <MenuItem
                                                                disabled={deleting}
                                                                onClick={() => deleteSettlement(driver.dsp_drv_stlmnt_id, popupState)}
                                                            >
                                                                Remove
                                                            </MenuItem>
                                                        ) : null}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>

                                    <Box sx={{ clear: 'both' }} />

                                    <Typography sx={{ marginBottom: '.5rem' }}>Payment Id : {driver.dsp_drv_stlmnt_id}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Type : {driver.driver_id ? 'Driver' : 'Owner'}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Truck :{' '}
                                        <Link
                                            component="button"
                                            onClick={() => {
                                                navigate(`/fleet/truck/view/${driver.truck_id}`);
                                            }}
                                        >
                                            {driver.truck_no}
                                        </Link>
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Name :{' '}
                                        {driver.driver_id ? (
                                            <Link
                                                component="button"
                                                onClick={() => {
                                                    navigate(`/fleet/driver/view/${driver.driver_id}`);
                                                }}
                                            >
                                                {driver.driver_name}
                                            </Link>
                                        ) : (
                                            <span>{driver.driver_name}</span>
                                        )}
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Pay Start Date : {driver.start_date}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Pay End Date : {driver.end_date}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Amount Paid : {driver.income}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Pay Date : {driver.payment_date ? moment.utc(driver.payment_date).format('YYYY-MM-DD') : ''}
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Bank A/C# : {driver.driver_acc}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Load Info :{' '}
                                        <IconButton
                                            onClick={() => handleClickOpenModal(driver.start_date, driver.end_date, driver.driver_id, driver.dsp_drv_stlmnt_id)}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDownload(driver.dsp_drv_stlmnt_id)}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}
                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected?.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginTop: '2rem',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <LoadStatus
                                            text={driver.payment_status}
                                            type={
                                                driver.payment_status === 'paid'
                                                    ? 'success'
                                                    : driver.payment_status === 'rejected'
                                                    ? 'error'
                                                    : driver.payment_status === 'pending'
                                                    ? 'inprog'
                                                    : ''
                                            }
                                        />

                                        <PopupState variant="popover" popupId={`demoMenu-${driver.dsp_drv_stlmnt_id}`}>
                                            {(popupState) => (
                                                <div>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        {(permissions.dspdriversettlement && permissions.dspdriversettlement.change) ||
                                                        user_types.includes(user_type) ? (
                                                            <MenuItem onClick={() => editSettlement(driver.dsp_drv_stlmnt_id, popupState)}>Edit</MenuItem>
                                                        ) : null}
                                                        {(permissions.dspdriversettlement && permissions.dspdriversettlement.delete) ||
                                                        user_types.includes(user_type) ? (
                                                            <MenuItem
                                                                disabled={deleting}
                                                                onClick={() => deleteSettlement(driver.dsp_drv_stlmnt_id, popupState)}
                                                            >
                                                                Remove
                                                            </MenuItem>
                                                        ) : null}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>

                                    {/* </Box> */}
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}> Payment Id : {driver.dsp_drv_stlmnt_id} </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Type : {driver.driver_id ? 'Driver' : 'Owner'}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            Truck :{' '}
                                            <Link
                                                component="button"
                                                onClick={() => {
                                                    navigate(`/fleet/truck/view/${driver.truck_id}`);
                                                }}
                                            >
                                                {driver.truck_no}
                                            </Link>
                                        </Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            Name :{' '}
                                            {driver.driver_id ? (
                                                <Link
                                                    component="button"
                                                    onClick={() => {
                                                        navigate(`/fleet/driver/view/${driver.driver_id}`);
                                                    }}
                                                >
                                                    {driver.driver_name}
                                                </Link>
                                            ) : (
                                                <span>{driver.driver_name}</span>
                                            )}
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '.6rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Pay Start Date : {driver.start_date}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Pay End Date : {driver.end_date}</Typography>{' '}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '.6rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Amount Paid : {driver.income}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            Pay Date : {driver.payment_date ? moment.utc(driver.payment_date).format('YYYY-MM-DD') : ''}
                                        </Typography>{' '}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '.6rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Bank A/C# : {driver.driver_acc}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            Load Info :{' '}
                                            <IconButton
                                                onClick={() =>
                                                    handleClickOpenModal(driver.start_date, driver.end_date, driver.driver_id, driver.dsp_drv_stlmnt_id)
                                                }
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleDownload(driver.dsp_drv_stlmnt_id)}>
                                                <DownloadIcon />
                                            </IconButton>{' '}
                                        </Typography>{' '}
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[20, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                    data={(query) => fetchData(query)}
                    title=""
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,

                        toolbar: false,
                        draggable: false,
                        columnResizable: true
                    }}
                />
            )}
            <ViewSettlememntModal
                open={open1}
                handleCloseModal={handleCloseModal}
                startDate={startDate}
                endDate={endDate}
                driver={driver}
                stlmId={stlmId}
                maxWidth="lg"
            />
        </>
    );
};

export default AllSettlementForm;
