import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import PublicLayout from 'layout/PublicLayout';
import ViewMyLoadPublic from 'views/dispatchCenter/ViewMyLoadPublic';
// const MechanicRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/MechanicRegister')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const PublicRoutes = {
    path: '/',
    element: <PublicLayout />,
    children: [
        {
            path: 'dispatch-center/load/view-status-public/:id',
            element: <ViewMyLoadPublic />
        }
    ]
};

export default PublicRoutes;
