const { createSlice } = require('@reduxjs/toolkit');

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {},
  reducers: {
    storeData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    removeData: (state, action) => {
      const { key } = action.payload;
      delete state[key];
    }
  }
});

export const { storeData, removeData } = dashboardSlice.actions;
export default dashboardSlice.reducer;
