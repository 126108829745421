import { Button, Dialog, DialogActions, DialogContent, Slide } from '@mui/material';
import { forwardRef } from 'react';

import ReportsCompareForm from 'ui-component/reports/ReportsCompareForm';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function ReportModal({ fullWidth, fullScreen, maxWidth, open, handleClose }) {
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={true}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogContent dividers>
                <ReportsCompareForm handleClose={handleClose} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ReportModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'sm'
};

export default ReportModal;
