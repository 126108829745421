import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Add button of forms
 
 * @param {prop} btnText
 * @param {prop} data
 * @returns
 */
function Status({ text, data }) {
    return (
        <div
            style={{
                backgroundColor:
                    text === 'Active' || text === 'Submitted' || text === 'Success'
                        ? '#5AC47C'
                        : text === 'InActive' || text === 'Inactive' || text === 'Not Submitted' || text === 'Failed'
                        ? '#D3505F'
                        : text === 'Incomplete'
                        ? '#FFD366'
                        : '#FFF4E5',
                color: 'white',

                padding: '3px',
                width: '80px',
                fontSize: '14px',
                border: '1px solid',
                borderRadius: '5px',
                display: 'initial'
            }}
        >
            {text}
        </div>
    );
}

export default Status;
