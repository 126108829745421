// project imports
import { useEffect } from 'react';

import MainCard from 'ui-component/cards/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';

import PageLayout from 'layout/PageLayout';
import ViewCompanyProfile from 'ui-component/mechanic/forms/ViewCompanyProfileForm';
// ==============================|| SAMPLE PAGE ||============================== //

const MechanicCompanyProfile = () => {
   
    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    return (
        <>
            <MainCard>
            <PageLayout title="Company Profile">
               

                <ViewCompanyProfile/>
                </PageLayout>
            </MainCard>
        </>
    );
};

export default MechanicCompanyProfile;
