import {  Dialog, DialogContent, Slide } from '@mui/material';
import { forwardRef } from 'react';
import MechanicForm from 'ui-component/auth-component/forms/MechanicForm';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function MechanicModal({ fullWidth, fullScreen, maxWidth, open, handleClose, reset, fetchMechanic ,mechanicId}) {
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogContent dividers>
                <MechanicForm handleClose={handleClose} reset={reset} fetchMechanic={fetchMechanic} mechanicId={mechanicId}/>
            </DialogContent>
        </Dialog>
    );
}

MechanicModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'md'
};

export default MechanicModal;
