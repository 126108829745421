import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Back button of forms
 * @param {optional} props
 * @returns
 */
function BackFormButton() {
    const navigate = useNavigate();
    return (
        <Button
            variant="contained"
            // component={RouterLink}
            sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E', marginTop: { xs: '2rem' } }}
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIcon />}
            color="orange"
            size="medium"
            // sx={{ pt: 1.5, pb: 1.5 }}
        >
            Back
        </Button>
    );
}

export default BackFormButton;
