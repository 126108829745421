import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import { Button, FormControl, Grid, Link, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';

import ViewFields from 'ui-component/ViewFields';
import DocModal from 'ui-component/modals/DocModal';

import { Worker, Viewer } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import ViewTextFields from 'ui-component/ViewTextFields';
import { Box, Stack } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />
        }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 60,
        width: '100%'
        // backgroundColor: '#0A95FF'
    },
    '& .Mui-selected': {
        color: '#0A95FF',
        background: '#98c1e094',
        borderRadius: '2rem'
    },
    '& .MuiButtonBase-root': {
        minHeight: '50px'
    }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16,
    marginRight: theme.spacing(1),
    color: '#697586',
    '&.Mui-selected': {
        color: '#0A95FF'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
}));
const ViewCompanyProfile = (props) => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [stateName, setStateName] = useState();
    const [cityName, setCityName] = useState();
    const [fileName, setFileName] = useState('');
    const [companySetup, setCompanySetup] = useState();

    const handleClickOpen = (file) => {
        setFileName(file);
        setModalOpen(true);
    };

    const handleClose = () => {
        // setFileName();
        setModalOpen(false);
    };

    function handleDownloadModal() {
        window.open(fileName, '_blank');
        setFileName();
        setModalOpen(false);
    }

    function handleDownloadClick(file) {
        if (file) {
            window.open(file, '_blank');
        }
    }

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const navigate = useNavigate();
    // const { companySetup } = props;

    const editAccounting = () => {
        navigate(`/company/accounting/edit`);
    };

    const editOrganization = () => {
        navigate(`/mechanic/company/profile/edit`);
    };

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (!companySetup) {
            fetchCompany();
        }

        if (companySetup && !stateList) {
            fetchState();
        }

        if (companySetup && companySetup.state_id && stateList && !cityList) {
            const stateData = stateList.filter((i) => i.id === companySetup.state_id);

            setStateName(stateData[0].name);
            getCityList(companySetup.state_id);
        }

        if (cityList) {
            const cityData = cityList.filter((i) => i.id === companySetup.city_id);

            setCityName(cityData[0].name);
        }
    }, [stateList, cityList]);

    const fetchState = async () => {
        setLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchCompany = async () => {
        setLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);

            setCompanySetup(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getCityList = async (id) => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);

            setCityList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                <Grid container spacing={1}>
                    {/* Customer */}
                    {/* General Info */}

                    <Box
                        sx={{
                            width: '100%',
                            typography: 'body1',
                            border: 'none',
                            backgroundColor: 'transparent'
                        }}
                    >
                        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                            {/* <LoadingFormButton type="button" btnText="Edit" onClick={() => editOrganization} /> */}

                            <Button
                                sx={{ mt: '27px', color: 'white', backgroundColor: '#EB812E' }}
                                color="orange"
                                variant="contained"
                                onClick={editOrganization}
                            >
                                Edit Profile
                            </Button>
                        </Stack>
                        <div style={{ padding: 15 }}>
                            <div>
                                <img src={companySetup && companySetup.logo} alt="logo" width="200px" />
                            </div>
                            <Typography variant="h2" sx={{ mt: 3, fontWeight: 600 }} color="#0A95FF">
                                {companySetup ? companySetup.dsp_company_name : ''}
                            </Typography>
                        </div>
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="styled tabs example"
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    flexWrap: 'wrap'
                                }
                            }}
                        >
                            <StyledTab label="Contacts" icon={<PermContactCalendarIcon />} iconPosition="start" value="1" />
                            <StyledTab label="Address" icon={<HomeIcon />} iconPosition="start" value="2" />
                            <StyledTab label="Bank" icon={<AccountBalanceIcon />} iconPosition="start" value="3" />
                        </StyledTabs>
                        <Box sx={{ px: '20px' }}>
                            {value == 1 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Email Id
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `mailto:${companySetup ? companySetup.email : ''}`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.email : 'N/A'}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Phone Number
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `tel:${companySetup ? companySetup.phone : ''}`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.phone : 'N/A'}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                EXT.
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `tel:${companySetup ? companySetup.phone_ext : ''}`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.phone_ext : 'N/A'}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 2 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Address
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.address : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                State
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.state_name : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                City
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.city_name : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Zip
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.zip_code : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 3 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Routing No
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.routing_no : ''}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Bank Name
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.bank_name : ''}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Bank Account Number
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.acc_no : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Bank Address
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.bank_address : ''}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </SubCard>

            <DocModal
                open={modalOpen}
                fullWidth
                fullScreen
                maxWidth="md"
                handleClose={() => handleClose()}
                handleDownload={() => handleDownloadModal()}
                fileName={fileName}
                content={
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer fileUrl={fileName} />
                    </Worker>
                }
            />
        </>
    );
};

export default ViewCompanyProfile;
