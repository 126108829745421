import { useState, useEffect } from 'react';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import {
    Autocomplete,
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Skeleton,
    TextField,
    Typography,
    useTheme
} from '@mui/material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
// third party
import * as Yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { Stack, useMediaQuery } from '@mui/system';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { useNavigate, useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const EditloadForm = () => {
    const theme = useTheme();
    const { id } = useParams();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loadData, setLoadData] = useState();

    const [custList, setCustList] = useState();
    const [driverList, setDriverList] = useState();
    const [stateList, setStateList] = useState();
    const [loadingCity, setLoadingCity] = useState(false);
    const [custName, setCustName] = useState('');

    const [truck, setTruck] = useState('');
    const [addError, setAddError] = useState();
    const [options, setOptions] = useState([]);
    const [optionsCust, setOptionsCust] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [inputValueCust, setInputValueCust] = useState('');
    const [loadTypeList, setLoadTypeList] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // to keep track of automatic email sends
    const [emailList, setEmailList] = useState('');
    const [emailFlag, setEmailFlag] = useState(false);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 390px)');

    const jsonConfig = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const FaqSchema = Yup.object().shape({
        customer: Yup.mixed().required('Customer is a required field'),
        driver: Yup.mixed().required('driver is a required field'),
        pickup: Yup.array().of(
            Yup.object().shape({
                address: Yup.string().required('This field is required'),
                city_id: Yup.number().required('This field is required'),
                state_id: Yup.number().required('This field is required'),
                zip_code: Yup.string().required('This field is required')
            })
        ),
        delivery: Yup.array().of(
            Yup.object().shape({
                address: Yup.string().required('This field is required'),
                city_id: Yup.number().required('This field is required'),
                state_id: Yup.number().required('This field is required'),
                zip_code: Yup.string().required('This field is required')
            })
        )
    });
    let empPic = {
        code: '',
        company_name: '',
        address: '',
        city_list: [],
        city_id: '',
        state_id: '',
        zip_code: '',
        longitude: '',
        latitude: '',
        date: '',
        time: '',
        load_type_status: '',
        is_completed: false,
        load_type: 'pickup'
    };
    let empDel = {
        code: '',
        company_name: '',
        address: '',
        city_list: [],
        city_id: '',
        state_id: '',
        zip_code: '',
        longitude: '',
        latitude: '',
        date: '',
        time: '',
        load_type_status: '',
        is_completed: false,
        load_type: 'delivery'
    };
    let empFiles = {
        dsp_load_doc_id: 0,
        oth_doc: '',
        oth_doc_name: ''
    };
    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            custName: id && loadData ? loadData.customer_id : '',
            truck: id && loadData ? loadData.truck_license : '',

            pickup: loadData
                ? loadData.pickups?.map(
                      ({ dsp_load_type_id, company_name, address, city_list, city_id, state_id, zip_code, load_type_status, is_completed, date, time }) => {
                          return {
                              dsp_load_type_id,
                              company_name,
                              address,
                              city_list,
                              city_id,
                              state_id,
                              zip_code,
                              load_type_status,
                              is_completed,
                              date: new Date(date + ' ' + time),
                              time: new Date(date + ' ' + time)
                          };
                      }
                  )
                : [empPic],
            delivery: loadData
                ? loadData.delivery?.map(
                      ({ dsp_load_type_id, company_name, address, city_list, city_id, state_id, zip_code, load_type_status, is_completed, date, time }) => {
                          return {
                              dsp_load_type_id,
                              company_name,
                              address,
                              city_list,
                              city_id,
                              state_id,
                              zip_code,
                              load_type_status,
                              is_completed,
                              date: new Date(date + ' ' + time),
                              time: new Date(date + ' ' + time)
                          };
                      }
                  )
                : [empDel],
            weight: id && loadData ? loadData.weight : '',
            revenue: id && loadData ? loadData.revenue : '',
            millage: id && loadData ? loadData.mileage : '',
            load: id && loadData ? loadData.load : '',
            customer: id && loadData && custList ? custList.filter(({ customer_name }) => customer_name === loadData.cust_name)[0] : '',
            driver: id && loadData && driverList ? driverList.filter(({ driver_id }) => driver_id === loadData.driver_id)[0] : '',
            files: id && loadData ? loadData.docs : [empFiles],
            bolDoc: id && loadData ? loadData.bol_doc : '',
            bolDocName: id && loadData ? loadData.bol_doc_name : '',
            status: id && loadData ? loadData.load_status : '',
            loadType: '',
            view_status: id && loadData ? loadData.status : ''
        },
        validationSchema: FaqSchema,
        onSubmit: async () => {
            for (let i = 1; i < values.pickup.length; i++) {
                for (let j = 0; j < i; j++) {
                    if (
                        values.pickup[i].date < values.pickup[j].date ||
                        (values.pickup[i].date.toString() === values.pickup[j].date.toString() && values.pickup[i].time < values.pickup[j].time)
                    ) {
                        toast.error('Pickup dates and times must be in increasing order from start to end');
                        return false;
                    }
                }
            }

            for (let i = 0; i < values.pickup.length; i++) {
                for (let j = 0; j < values.delivery.length; j++) {
                    if (
                        values.delivery[j].date < values.pickup[i].date ||
                        (values.delivery[j].date.toString() === values.pickup[i].date.toString() && values.delivery[j].time < values.pickup[j].time)
                    ) {
                        toast.error('Delivery date should be after pickup date');
                        return false;
                    }
                }
            }
            const data = {
                dsp_load_id: loadData.dsp_load_id,
                company_id: loginInfoFromStorage.company_id,
                customer_id: values.customer.dsp_customer_id,
                driver_id: values.driver ? values.driver.driver_id : '',
                is_active: loadData && loadData.is_active,
                is_edit: false,
                load: values.load,
                mileage: values.millage,
                revenue: values.revenue,
                weight: values.weight,
                load_status: values.status,
                status: values.view_status,
                // adding the email info
                email_info: {
                    ...(loadData.email_info && { id: loadData.email_info.id }),
                    email_flag: emailFlag,
                    email_list: emailList,
                    load: loadData.dsp_load_id
                },
                pickups: values.pickup.map(
                    ({ dsp_load_type_id, company_name, address, city_id, state_id, zip_code, load_type_status, is_completed, date, time }) => ({
                        company_name,
                        dsp_load_type_id,
                        address,
                        city_id,
                        state_id,
                        zip_code,
                        load_type_status,
                        is_completed,
                        load_id: id,
                        date: format(new Date(date), 'yyyy-MM-dd'),
                        time: format(new Date(time), 'HH:mm'),
                        driver_id: values.driver ? values.driver.driver_id : '',
                        is_edit: true
                    })
                ),
                delivery: values.delivery.map(
                    ({ dsp_load_type_id, company_name, address, city_id, state_id, zip_code, load_type_status, is_completed, date, time }) => ({
                        company_name,
                        dsp_load_type_id,
                        address,
                        city_id,
                        state_id,
                        zip_code,
                        load_type_status,
                        is_completed,
                        load_id: id,
                        date: format(new Date(date), 'yyyy-MM-dd'),
                        time: format(new Date(time), 'HH:mm'),
                        driver_id: values.driver ? values.driver.driver_id : '',
                        is_edit: true
                    })
                )
                // bol_doc: values.bolDoc,
                // bol_doc_name: values.bolDocName
            };
            const formData = new FormData();
            formData.append('data', JSON.stringify(data));
            values.files.forEach((i, index) => formData.append(`oth_docs[${index}].oth_doc`, i.oth_doc));
            values.files.forEach((i, index) => formData.append(`oth_docs[${index}].doc_type`, i.doc_type));
            values.files.forEach((i, index) => formData.append(`oth_docs[${index}].oth_doc_name`, i.oth_doc_name));
            values.files.forEach((i, index) => formData.append(`oth_docs[${index}].dsp_load_doc_id`, i.dsp_load_doc_id));
            // formData.append('bol_doc', values.bolDoc);
            // formData.append('bol_doc_name', values.bolDocName);

            try {
                const { data } = await axios.put(`/v1/api/dispatch/load/view/${id}/`, formData, jsonConfig);
                dispatch(openSuccessSnackbar('Load Updated successfully'));
                navigate('/dispatch-center');
            } catch (error) {
                if (process.env.NODE_ENV == 'development') {
                    console.log(error.response.data);
                }

                setAddError(error.response.data);
                if (error.response.data.street) {
                    dispatch(openErrorSnackbar('One of the given address was invalid'));
                } else {
                    dispatch(openErrorSnackbar('Something Went Wrong!'));
                }
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const fetchCustList = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/dispatch/customer/list?company_id=`, config);
            setCustList(data.results);
            setOptionsCust(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchDriverList = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100&search=`,
                config
            );
            setDriverList(data.results);
            setOptions(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (process.env.NODE_ENV == 'development') {
                console.log(error.response.data);
            }
        }
    };

    const fetchStateList = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);
            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleDriverChange = (e) => {
        const item = driverList.filter((i) => i.driver_id === e);

        setTruck(item.length > 0 && item[0].truck_info.truck);
    };

    const handleCustChange = (e) => {
        const item = optionsCust.filter((i) => i.dsp_customer_id === e);

        setCustName(item[0] && item[0].customer_name);
    };

    const getCityList = async (state, cityList, id) => {
        setFieldValue(state, id);
        setLoadingCity(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);
            setLoadingCity(false);
            setFieldValue(cityList, data);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchLoadData = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/dispatch/load/view/${id}/`, config);
            setLoadData(data);

            // updating the local state with existing data if available
            setEmailFlag(data?.email_info?.email_flag === true);
            setEmailList(data?.email_info?.email_list);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!stateList) {
            fetchStateList();
        }
    }, [stateList]);

    useEffect(() => {
        if (id && !driverList) {
            fetchDriverList();
        }
    }, [id, driverList]);

    useEffect(() => {
        if (id && stateList && driverList && !loadData) {
            fetchLoadData(id);
        }
    }, [id, stateList, driverList, loadData]);

    useEffect(() => {
        if (id && stateList && driverList && loadData) {
            handleDriverChange(loadData.driver_id);
            onChangeCustomerHandle(loadData.cust_name);
        }
    }, [id, stateList, driverList, loadData]);

    useEffect(() => {
        if (optionsCust.length > 0 && loadData) {
            handleCustChange(loadData.customer_id);
        }
    }, [optionsCust.length, loadData]);

    const onChangeHandle = async (value) => {
        setLoading1(true);
        setTruck('');
        const { data } = await axios.get(
            `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100&search=${value}`,
            config
        );
        setLoading1(false);
        setOptions(data.results);
    };
    const onChangeCustomerHandle = async (value) => {
        setLoading2(true);

        const { data } = await axios.get(`/v1/api/dispatch/customer/list?page=1&page_size=100&search=${value}`, config);

        setLoading2(false);
        setOptionsCust(data.results);
        setCustList(data.results);
    };

    const validateEmails = (emails) => {
        const emailArray = emails?.split(',').map((email) => email.trim());
        const invalidEmails = emailArray?.filter((email) => !isValidEmail(email));
        if (invalidEmails?.length > 0) {
            return 'Invalid email(s): ' + invalidEmails.join(', ');
        }
        return undefined;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography variant="h2">Edit Load Form</Typography>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <LoadingFormButton
                                        btnText="Save"
                                        type="submit"
                                        load={isSubmitting && !addError}
                                        variant="contained"
                                        component="span"
                                        size="large"
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {/* Customer */}

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    className="text-input"
                                                    // multiple
                                                    id="customer-set"
                                                    options={optionsCust}
                                                    loading={loading2}
                                                    getOptionLabel={(option) => (option.customer_name ? option.customer_name : '')}
                                                    inputValue={inputValueCust}
                                                    onInputChange={(event, newInputValue) => {
                                                        setInputValueCust(newInputValue);
                                                    }}
                                                    value={values.customer}
                                                    onChange={(event, newValue) => {
                                                        if (newValue != null) {
                                                            handleCustChange(newValue.dsp_customer_id);
                                                            setFieldValue('customer', newValue);
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label="Customer Name"
                                                            onChange={(ev) => {
                                                                if (ev.target.value !== '' || ev.target.value !== null || ev.target.value?.length > 1) {
                                                                    onChangeCustomerHandle(ev.target.value);
                                                                }
                                                            }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {loading2 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                )
                                                            }}
                                                            error={Boolean(touched.customer && errors.customer)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText error={Boolean(touched.customer && errors.customer) || (addError && addError.customer_id)}>
                                                    {touched.customer && errors.customer ? errors.customer : 'Type atleast 2 characters to populate the list'}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Email sent options start */}
                            <Grid container mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    sx={{ textWrap: 'balance', mr: 0, mb: '1rem' }}
                                    control={<Checkbox checked={emailFlag} onChange={(e) => setEmailFlag(e.target.checked)} />}
                                    label="Do you want to send BOL and status update automatically to the broker with this email?"
                                />
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        label="Emails"
                                        value={emailList}
                                        onChange={(e) => setEmailList(e.target.value)}
                                        error={Boolean(emailList && validateEmails(emailList))}
                                        sx={{ mb: '1rem' }}
                                        helperText={validateEmails(emailList) || 'Enter multiple emails separated by commas'}
                                    />
                                )}
                                <div style={{ color: 'orange' }}>You can add multiple email addresses by separating them with semicolons</div>
                            </Grid>
                            {/* Email sent options end */}
                        </SubCard>

                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2">Assign To Driver</Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                className="text-input"
                                                // multiple
                                                id="driver-set"
                                                options={options}
                                                loading={loading1}
                                                // isOptionEqualToValue={(option, value) => option.driver_id === value.driver_id}
                                                getOptionLabel={(option) => (option.name ? option.name : '')}
                                                inputValue={inputValue}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue(newInputValue);
                                                }}
                                                value={values.driver}
                                                onChange={(event, newValue) => {
                                                    if (newValue != null) {
                                                        handleDriverChange(newValue.driver_id);
                                                        setFieldValue('driver', newValue);
                                                    } else {
                                                        setTruck('');
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="Driver Name"
                                                        onChange={(ev) => {
                                                            if (ev.target.value !== '' || ev.target.value !== null || ev.target.value.length > 1) {
                                                                onChangeHandle(ev.target.value);
                                                            }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            )
                                                        }}
                                                        error={Boolean(touched.driver && errors.driver)}
                                                    />
                                                )}
                                            />
                                            <FormHelperText error={Boolean(touched.driver && errors.driver)}>
                                                {touched.driver && errors.driver ? errors.driver : 'Type atleast 2 characters to populate the list'}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Grid>
                                {/* truck no */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            fullWidth
                                            type="text"
                                            label="Truck Number "
                                            color="orange"
                                            value={truck}
                                            inputProps={{ readOnly: true }}
                                            inputLabelProps={{ shrink: truck !== '' ? true : false }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>

                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {' '}
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2">General Information</Typography>
                                </Grid>
                                {/* customer */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            fullWidth
                                            type="text"
                                            label="Customer Name"
                                            color="orange"
                                            value={values.customer?.customer_name}
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                </Grid>
                                {/* Weight */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Weight"
                                            {...getFieldProps('weight')}
                                            error={Boolean((touched.weight && errors.weight) || (addError && addError.weight))}
                                            helperText={(touched.weight && errors.weight) || (addError && addError.weight)}
                                        />
                                    )}
                                </Grid>
                                {/* revenue */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Revenue"
                                            {...getFieldProps('revenue')}
                                            error={Boolean((touched.revenue && errors.revenue) || (addError && addError.revenue))}
                                            helperText={(touched.revenue && errors.revenue) || (addError && addError.revenue)}
                                        />
                                    )}
                                </Grid>
                                {/* Load */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Load"
                                            {...getFieldProps('load')}
                                            error={Boolean((touched.load && errors.load) || (addError && addError.load))}
                                            helperText={(touched.load && errors.load) || (addError && addError.load)}
                                        />
                                    )}
                                </Grid>
                                {/* millage */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Millage"
                                            {...getFieldProps('millage')}
                                            error={Boolean((touched.millage && errors.millage) || (addError && addError.millage))}
                                            helperText={(touched.millage && errors.millage) || (addError && addError.millage)}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            select
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Status"
                                            {...getFieldProps('view_status')}
                                        >
                                            <MenuItem value="">NULL</MenuItem>
                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                            <MenuItem value="Delayed">Delayed</MenuItem>
                                            <MenuItem value="Damaged">Damaged</MenuItem>
                                            <MenuItem value="Lost">Lost</MenuItem>
                                            <MenuItem value="Pending-Files">Pending-Files</MenuItem>
                                        </TextField>
                                    )}
                                </Grid>
                                {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            select
                                            type="text"
                                            label="Status"
                                            value={values.status}
                                            onChange={(e) => setFieldValue('status', e.target.value)}
                                            error={Boolean(touched.status && errors.status)}
                                            helperText={touched.status && errors.status}
                                        >
                                            <MenuItem disabled selected>
                                                Select Field - Options
                                            </MenuItem>
                                            <MenuItem value="Heading Pickup">Heading Pickup</MenuItem>
                                            <MenuItem value="Checked In">Checked In</MenuItem>
                                            <MenuItem value="Loaded">Loaded</MenuItem>
                                            <MenuItem value="In Transit">In Transit</MenuItem>
                                            {loadData && loadData.bol_status === 'Approved' && <MenuItem value="Delivered">Delivered</MenuItem>}

                                            <MenuItem value="New">New</MenuItem>
                                        </TextField>
                                    )}
                                </Grid> */}
                                {/* Pick Up */}
                            </Grid>
                        </SubCard>
                        {/* <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2">Load Status</Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={loadTypeList}
                                            className="text-input"
                                            color="orange"
                                            getOptionLabel={(option) => (option.address ? option.address : '')}
                                            renderInput={(params) => <TextField {...params} label="Address" />}
                                            value={values.loadType}
                                            onChange={(e, newValue) => setFieldValue('loadType', newValue)}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            select
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Status"
                                            value={values.status}
                                            onChange={(e) => setFieldValue('status', e.target.value)}
                                            error={Boolean(touched.status && errors.status)}
                                            helperText={touched.status && errors.status}
                                        >
                                            <MenuItem disabled selected>
                                                Select Field - Options
                                            </MenuItem>

                                            {values.loadType && values.loadType.load_type && values.loadType.load_type === 'pickup' && (
                                                <MenuItem value="Heading Pickup">Heading Pickup</MenuItem>
                                            )}
                                            {values.loadType && values.loadType.load_type && values.loadType.load_type === 'pickup' && (
                                                <MenuItem value="Checked In">Checked In</MenuItem>
                                            )}
                                            {values.loadType && values.loadType.load_type && values.loadType.load_type === 'pickup' && (
                                                <MenuItem value="Loaded">Loaded</MenuItem>
                                            )}
                                            {values.loadType && values.loadType.load_type && values.loadType.load_type === 'delivery' && (
                                                <MenuItem value="Heading Delivery">Heading Delivery</MenuItem>
                                            )}

                                            {values.loadType && values.loadType.load_type && values.loadType.load_type === 'delivery' && (
                                                <MenuItem value="Delivered">Delivered</MenuItem>
                                            )}
                                        </TextField>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard> */}

                        {/* 
                                            <MenuItem value="In Transit">In Transit</MenuItem>
                                            {loadData && loadData.bol_status === 'Approved' && <MenuItem value="Delivered">Delivered</MenuItem>} */}

                        {/* <MenuItem value="New">New</MenuItem> */}
                        {/* Pickup */}
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <FieldArray name="pickup">
                                    {({ push, insert, remove }) => {
                                        return (
                                            <>
                                                {values.pickup?.map((i, index) => {
                                                    const pickupErrors = (errors.pickup?.length && errors.pickup[index]) || {};
                                                    const pickupTouched = (touched.pickup?.length && touched.pickup[index]) || {};
                                                    return (
                                                        <div key={index} style={{ marginBottom: '25px' }}>
                                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
                                                                <Typography
                                                                    variant="h4"
                                                                    sx={{
                                                                        borderRadius: '50%',
                                                                        backgroundColor: theme.palette.orange.main,
                                                                        color: '#ffffff',
                                                                        px: '10px',
                                                                        py: '7px'
                                                                    }}
                                                                >
                                                                    {index + 1}
                                                                </Typography>
                                                                <Typography
                                                                    variant="h2"
                                                                    sx={{
                                                                        ml: 1,
                                                                        mt: 0.8,
                                                                        fontWeight: 500,
                                                                        fontFamily: 'Lexend',
                                                                        letterSpacing: 2
                                                                    }}
                                                                >
                                                                    PICKUP
                                                                </Typography>
                                                                {values.pickup.length > 1 && (
                                                                    <IconButton
                                                                        disabled={isSubmitting && Boolean(errors.pickup)}
                                                                        onClick={() => remove(index)}
                                                                        size="small"
                                                                        color="error"
                                                                    >
                                                                        <DeleteForeverIcon fontSize="large" />
                                                                    </IconButton>
                                                                )}
                                                            </Stack>
                                                            <Grid container spacing={3}>
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="Address"
                                                                        value={values.pickup[index].address}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`pickup[${index}].address`, e.target.value);
                                                                        }}
                                                                        error={Boolean(
                                                                            (pickupTouched.street && pickupErrors.street) ||
                                                                                (addError && addError.pickups[index].street)
                                                                        )}
                                                                        helperText={
                                                                            (pickupTouched.street && pickupErrors.street) ||
                                                                            (addError && addError.pickups[index].street)
                                                                        }
                                                                    />
                                                                </Grid>
                                                                {/* State */}
                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        select
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="State"
                                                                        value={values.pickup[index].state_id}
                                                                        onChange={(e) =>
                                                                            getCityList(
                                                                                `pickup[${index}].state_id`,
                                                                                `pickup[${index}].city_list`,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        error={Boolean(pickupTouched.state_id && pickupErrors.state_id)}
                                                                        helperText={pickupTouched.state_id && pickupErrors.state_id}
                                                                    >
                                                                        <MenuItem value="">Select State</MenuItem>
                                                                        {stateList && stateList.map((i) => <MenuItem value={i.id}>{i.name}</MenuItem>)}
                                                                    </TextField>
                                                                </Grid>
                                                                {/* City */}
                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                    {loading || loadingCity ? (
                                                                        <Skeleton height="50px" />
                                                                    ) : (
                                                                        <TextField
                                                                            fullWidth
                                                                            select
                                                                            className="text-input"
                                                                            color="orange"
                                                                            type="text"
                                                                            label="City"
                                                                            value={values.pickup[index].city_id}
                                                                            onChange={(e) => setFieldValue(`pickup[${index}].city_id`, e.target.value)}
                                                                            error={Boolean(pickupTouched.city_id && pickupErrors.city_id)}
                                                                            helperText={pickupTouched.city_id && pickupErrors.city_id}
                                                                        >
                                                                            <MenuItem value="">Select City</MenuItem>
                                                                            {values.pickup[index].city_list &&
                                                                                values.pickup[index].city_list.map((i) => (
                                                                                    <MenuItem value={i.id}>{i.name}</MenuItem>
                                                                                ))}
                                                                        </TextField>
                                                                    )}
                                                                </Grid>

                                                                {/* Zip */}
                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="Zip"
                                                                        value={values.pickup[index].zip_code}
                                                                        onChange={(e) =>
                                                                            setFieldValue(
                                                                                `pickup[${index}].zip_code`,
                                                                                !isNaN(e.target.value) ? e.target.value : ''
                                                                            )
                                                                        }
                                                                        error={Boolean(pickupTouched.zip_code && pickupErrors.zip_code)}
                                                                        helperText={pickupTouched.zip_code && pickupErrors.zip_code}
                                                                    />
                                                                </Grid>
                                                                {/* Date */}
                                                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                        <DesktopDatePicker
                                                                            className="text-input"
                                                                            label="Pickup Date"
                                                                            openTo="day"
                                                                            views={['day']}
                                                                            value={values.pickup[index].date}
                                                                            onChange={(newValue) => {
                                                                                setFieldValue(`pickup[${index}].date`, newValue);
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    color="orange"
                                                                                    fullWidth
                                                                                    {...params}
                                                                                    error={
                                                                                        Boolean(pickupTouched.date && pickupErrors.date) ||
                                                                                        (addError && addError.pickups[index].date)
                                                                                    }
                                                                                    helperText={
                                                                                        (pickupTouched.date && pickupErrors.date) ||
                                                                                        (addError && addError.pickups[index].date)
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </Grid>
                                                                {/* Time */}
                                                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                        <TimePicker
                                                                            className="text-input"
                                                                            color="orange"
                                                                            ampm={false}
                                                                            label={'Pickup Time'}
                                                                            views={['hours', 'minutes']}
                                                                            // format="HH:MM"
                                                                            value={values.pickup[index].time}
                                                                            onChange={(newValue) => setFieldValue(`pickup[${index}].time`, newValue)}
                                                                            renderInput={(params) => (
                                                                                <TextField color="orange" fullWidth {...params} error={null} />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </Grid>
                                                                {/* Company Name*/}
                                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="Company Name"
                                                                        value={values.pickup[index].company_name}
                                                                        onChange={(e) => setFieldValue(`pickup[${index}].company_name`, e.target.value)}
                                                                    />
                                                                </Grid>

                                                                {/* Load Status */}
                                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        select
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="Status"
                                                                        disabled={
                                                                            values.pickup[index].is_completed ||
                                                                            (index > 0 && values.pickup[index - 1].load_type_status !== 'Loaded')
                                                                        }
                                                                        value={values.pickup[index].load_type_status}
                                                                        onChange={(e) => setFieldValue(`pickup[${index}].load_type_status`, e.target.value)}
                                                                    >
                                                                        <MenuItem value="">Select</MenuItem>
                                                                        <MenuItem value="Heading Pickup">Heading Pickup</MenuItem>
                                                                        <MenuItem value="Checked In">Checked In</MenuItem>
                                                                        <MenuItem value="Loaded">Loaded</MenuItem>
                                                                    </TextField>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    );
                                                })}
                                                <Button
                                                    variant="contained"
                                                    color="orange"
                                                    onClick={() => push(empPic)}
                                                    size="large"
                                                    sx={{
                                                        borderRadius: '8px',
                                                        fontFamily: 'Lexend',
                                                        fontWeight: 700,
                                                        textTransform: 'uppercase',
                                                        mt: 1,
                                                        color: '#ffffff'
                                                    }}
                                                    startIcon={<AddIcon />}
                                                >
                                                    Add PickUp
                                                </Button>
                                            </>
                                        );
                                    }}
                                </FieldArray>
                            )}
                        </SubCard>
                        {/* Delivery */}
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            {/* <Grid container spacing={2}> */}
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <FieldArray name="delivery">
                                    {({ push, remove }) => (
                                        <>
                                            {values.delivery?.map((i, index) => {
                                                const deliveryErrors = (errors.delivery?.length && errors.delivery[index]) || {};
                                                const deliveryTouched = (touched.delivery?.length && touched.delivery[index]) || {};

                                                return (
                                                    <div key={index} style={{ marginBottom: '25px' }}>
                                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    borderRadius: '50%',
                                                                    backgroundColor: theme.palette.orange.main,
                                                                    color: '#ffffff',
                                                                    px: '10px',
                                                                    py: '7px'
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Typography>
                                                            <Typography
                                                                variant="h2"
                                                                sx={{
                                                                    ml: 1,
                                                                    mt: 0.8,
                                                                    fontWeight: 500,
                                                                    fontFamily: 'Lexend',
                                                                    letterSpacing: 2
                                                                }}
                                                            >
                                                                DELIVERY
                                                            </Typography>
                                                            {values.delivery.length > 1 && (
                                                                <IconButton
                                                                    disabled={isSubmitting && Boolean(errors.delivery)}
                                                                    onClick={() => remove(index)}
                                                                    size="small"
                                                                    color="error"
                                                                >
                                                                    <DeleteForeverIcon fontSize="large" />
                                                                </IconButton>
                                                            )}
                                                        </Stack>
                                                        <Grid container spacing={3}>
                                                            {/* <Grid item container spacing={2} xs={10} sm={10} lg={10} md={10}>
                                                <Grid item xs={12}> */}
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="Address"
                                                                    value={values.delivery[index].address}
                                                                    onChange={(e) => setFieldValue(`delivery[${index}].address`, e.target.value)}
                                                                    onBlur={(e) => {
                                                                        fetchAddress(
                                                                            e.target.value,
                                                                            `delivery[${index}].longitude`,
                                                                            `delivery[${index}].latitude`
                                                                        );
                                                                    }}
                                                                    // {...getFieldProps('address')}
                                                                    error={
                                                                        Boolean(deliveryTouched.street && deliveryErrors.street) ||
                                                                        (addError && addError.delivery.length && addError.delivery[index].street)
                                                                    }
                                                                    helperText={
                                                                        (deliveryTouched.street && deliveryErrors.street) ||
                                                                        (addError && addError.delivery.length && addError.delivery[index].street)
                                                                    }
                                                                />
                                                            </Grid>
                                                            {/* State */}
                                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                {loading ? (
                                                                    <Skeleton height="20px" />
                                                                ) : (
                                                                    <TextField
                                                                        select
                                                                        fullWidth
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="State"
                                                                        value={values.delivery[index].state_id}
                                                                        onChange={(e) =>
                                                                            getCityList(
                                                                                `delivery[${index}].state_id`,
                                                                                `delivery[${index}].city_list`,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        error={Boolean(deliveryTouched.state_id && deliveryErrors.state_id)}
                                                                        helperText={deliveryTouched.state_id && deliveryErrors.state_id}
                                                                    >
                                                                        <MenuItem value="">Select State</MenuItem>
                                                                        {stateList && stateList.map((i) => <MenuItem value={i.id}>{i.name}</MenuItem>)}
                                                                    </TextField>
                                                                )}
                                                            </Grid>
                                                            {/* City */}
                                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                {loading || loadingCity ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <TextField
                                                                        select
                                                                        fullWidth
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="City"
                                                                        value={values.delivery[index].city_id}
                                                                        onChange={(e) => setFieldValue(`delivery[${index}].city_id`, e.target.value)}
                                                                        error={Boolean(deliveryTouched.city_id && deliveryErrors.city_id)}
                                                                        helperText={deliveryTouched.city_id && deliveryErrors.city_id}
                                                                    >
                                                                        <MenuItem value="">Select City</MenuItem>
                                                                        {values.delivery[index].city_list &&
                                                                            values.delivery[index].city_list.map((i) => (
                                                                                <MenuItem value={i.id}>{i.name}</MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                )}
                                                            </Grid>

                                                            {/* Zip */}
                                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="Zip"
                                                                    value={values.delivery[index].zip_code}
                                                                    onChange={(e) =>
                                                                        setFieldValue(
                                                                            `delivery[${index}].zip_code`,
                                                                            !isNaN(e.target.value) ? e.target.value : ''
                                                                        )
                                                                    }
                                                                    error={Boolean(deliveryTouched.zip_code && deliveryErrors.zip_code)}
                                                                    helperText={deliveryTouched.zip_code && deliveryErrors.zip_code}
                                                                />
                                                            </Grid>
                                                            {/* Date */}
                                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DesktopDatePicker
                                                                        className="text-input"
                                                                        label="Delivery Date"
                                                                        openTo="day"
                                                                        views={['day']}
                                                                        value={values.delivery[index].date}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue(`delivery[${index}].date`, newValue);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField color="orange" fullWidth {...params} error={null} />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            {/* Time */}
                                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <TimePicker
                                                                        className="text-input"
                                                                        ampm={false}
                                                                        color="orange"
                                                                        label={'Delivery Time'}
                                                                        views={['hours', 'minutes']}
                                                                        format="mm:ss"
                                                                        value={values.delivery[index].time}
                                                                        onChange={(newValue) => setFieldValue(`delivery[${index}].time`, newValue)}
                                                                        renderInput={(params) => (
                                                                            <TextField color="orange" fullWidth {...params} error={null} />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            {/* Company Name*/}
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="Company Name"
                                                                    value={values.delivery[index].company_name}
                                                                    onChange={(e) => setFieldValue(`delivery[${index}].company_name`, e.target.value)}
                                                                />
                                                            </Grid>
                                                            {/* Load Status */}
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    select
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="Status"
                                                                    disabled={
                                                                        values.delivery[index].is_completed ||
                                                                        (index > 0 && values.delivery[index - 1].load_type_status !== 'Delivered')
                                                                    }
                                                                    value={values.delivery[index].load_type_status}
                                                                    onChange={(e) => setFieldValue(`delivery[${index}].load_type_status`, e.target.value)}
                                                                >
                                                                    <MenuItem value="">Select</MenuItem>
                                                                    <MenuItem value="Heading Delivery">Heading Delivery</MenuItem>
                                                                    <MenuItem value="Delivered">Delivered</MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                );
                                            })}
                                            <Grid item xs={6}>
                                                <Button
                                                    disabled={isSubmitting}
                                                    variant="contained"
                                                    color="orange"
                                                    onClick={() => push(empDel)}
                                                    size="large"
                                                    sx={{
                                                        borderRadius: '8px',
                                                        fontFamily: 'Lexend',
                                                        fontWeight: 700,
                                                        textTransform: 'uppercase',
                                                        mt: 1,
                                                        color: '#ffffff'
                                                    }}
                                                    startIcon={<AddIcon />}
                                                >
                                                    Add Delivery
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </FieldArray>
                            )}
                        </SubCard>

                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {/* Title */}
                                <Grid item xs={12}>
                                    <Typography variant="h2">Other Documents</Typography>
                                </Grid>

                                {/* File Upload Fields */}
                                <Grid item xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <FieldArray name="files">
                                            {({ push, remove }) => (
                                                <div>
                                                    {values.files.map((file, index) => {
                                                        const fileErrors = (errors.files?.length && errors.files[index]) || {};
                                                        const fileTouched = (touched.files?.length && touched.files[index]) || {};

                                                        return (
                                                            <Stack
                                                                key={index}
                                                                direction="row"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                sx={{ my: 2 }}
                                                                spacing={3}
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={3}
                                                                    sx={{
                                                                        '& > .MuiGrid-item': { paddingLeft: 0 },
                                                                        gap: { xs: 0, lg: '24px' }
                                                                    }}
                                                                >
                                                                    <Grid item lg={5} md={12} sm={12} xs={12}>
                                                                        {loading ? (
                                                                            <Skeleton height="50px" />
                                                                        ) : (
                                                                            <TextField
                                                                                fullWidth
                                                                                className="text-input"
                                                                                color="orange"
                                                                                select
                                                                                label="Type"
                                                                                value={values.files[index].doc_type}
                                                                                onChange={(e) => {
                                                                                    setFieldValue(`files[${index}].doc_type`, e.target.value);
                                                                                }}
                                                                                error={Boolean(fileTouched.doc_type && fileErrors.doc_type)}
                                                                                helperText={fileTouched.doc_type && fileErrors.doc_type}
                                                                            >
                                                                                <MenuItem value="other_doc">Other Doc</MenuItem>
                                                                                <MenuItem value="bol_doc">Bol Doc</MenuItem>
                                                                                <MenuItem value="arc_doc">RC Doc</MenuItem>
                                                                            </TextField>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item lg={6} md={12} sm={12} xs={12}>
                                                                        <TextField
                                                                            className="text-input"
                                                                            color="orange"
                                                                            sx={{
                                                                                '.MuiOutlinedInput-root': {
                                                                                    display: 'flex',
                                                                                    alignItems: 'self-start',
                                                                                    flexDirection: { xs: 'column', lg: 'row' }
                                                                                },
                                                                                '.MuiInputBase-input': {
                                                                                    height: { xs: '3.4375em', lg: '2.9375em' },
                                                                                    paddingBottom: { xs: '1.5rem', lg: '.3rem' },
                                                                                    paddingTop: { xs: 0, lg: '4px' }
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                            value={values.files[index].oth_doc_name}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton edge="end">
                                                                                            <Button
                                                                                                component="label"
                                                                                                color="orange"
                                                                                                startIcon={<CloudUploadIcon />}
                                                                                                sx={{ mt: { xs: '-2.5rem', md: '-2.7rem' } }}
                                                                                            >
                                                                                                Upload
                                                                                                <input
                                                                                                    hidden
                                                                                                    type="file"
                                                                                                    onInputCapture={(event) => {
                                                                                                        setFieldValue(
                                                                                                            `files[${index}].oth_doc`,
                                                                                                            event.target.files[0]
                                                                                                        );
                                                                                                        setFieldValue(
                                                                                                            `files[${index}].oth_doc_name`,
                                                                                                            event.target.files[0].name
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </Button>
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            label="Other Documents"
                                                                            error={Boolean(fileTouched.oth_doc_name && fileErrors.oth_doc_name)}
                                                                            helperText={fileTouched.oth_doc_name && fileErrors.oth_doc_name}
                                                                        />

                                                                        {values.files.length > 1 && (
                                                                            <IconButton
                                                                                disabled={isSubmitting && Boolean(errors.files)}
                                                                                onClick={() => remove(index)}
                                                                                size="small"
                                                                                color="error"
                                                                            >
                                                                                <DeleteForeverIcon
                                                                                    fontSize="large"
                                                                                    sx={{
                                                                                        position: 'relative',
                                                                                        left: isSmall
                                                                                            ? '9.5rem'
                                                                                            : isMobile
                                                                                            ? '13rem'
                                                                                            : { sm: '29rem', md: '36rem', lg: '36rem', xl: '44rem' },
                                                                                        // left: { xs: '10rem', sm: '29rem', md: '44rem' },
                                                                                        mt: { xs: 0, md: 0, lg: '-4.1vw' }
                                                                                        // ml: { xs: '44vw', sm: '59vw', lg: '30vw' }
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Stack>
                                                        );
                                                    })}

                                                    <Grid item xs={12}>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            variant="contained"
                                                            color="orange"
                                                            onClick={() => push({ doc_type: '', oth_doc_name: '', oth_doc: null })}
                                                            size="large"
                                                            sx={{
                                                                borderRadius: '8px',
                                                                fontFamily: 'Lexend',
                                                                fontWeight: 700,
                                                                textTransform: 'uppercase',
                                                                mt: 1,
                                                                color: '#ffffff'
                                                            }}
                                                            startIcon={<AddIcon />}
                                                        >
                                                            Add Other Doc
                                                        </Button>
                                                    </Grid>
                                                </div>
                                            )}
                                        </FieldArray>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>

                        {/* <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {/* Ratecon upload 
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2">Other Documents</Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <FieldArray name="files">
                                            {({ push, remove }) => (
                                                <div>
                                                    {values.files.map((file, index) => (
                                                        <Stack
                                                            key={index}
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            sx={{ my: 2 }}
                                                            spacing={3}
                                                        >
                                                            {/* Type  

                                                            {loading ? (
                                                                <Skeleton height="50px" />
                                                            ) : (
                                                                <TextField
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    select
                                                                    label="Type"
                                                                    value={values.files[index].doc_type}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`files[${index}].doc_type`, e.target.value);
                                                                    }}
                                                                >
                                                                    <MenuItem value="other_doc">Other Doc</MenuItem>
                                                                    <MenuItem value="bol_doc">Bol Doc</MenuItem>
                                                                    <MenuItem value="arc_doc">RC Doc</MenuItem>
                                                                </TextField>
                                                            )}

                                                            <TextField
                                                                className="text-input"
                                                                color="orange"
                                                                fullWidth
                                                                value={values.files[index].oth_doc_name}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton edge="end">
                                                                                <Button component="label" color="orange" startIcon={<CloudUploadIcon />}>
                                                                                    Upload
                                                                                    <input
                                                                                        hidden
                                                                                        type="file"
                                                                                        onInputCapture={(event) => {
                                                                                            setFieldValue(`files[${index}].oth_doc`, event.target.files[0]);
                                                                                            setFieldValue(
                                                                                                `files[${index}].oth_doc_name`,
                                                                                                event.target.files[0].name
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </Button>
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                label="Other Documents"
                                                            />

                                                            {values.files.length > 1 && (
                                                                <IconButton
                                                                    disabled={isSubmitting && Boolean(errors.files)}
                                                                    onClick={() => {
                                                                        remove(index);
                                                                        // deleteDocHandler(index);
                                                                    }}
                                                                    size="small"
                                                                    color="error"
                                                                >
                                                                    <DeleteForeverIcon fontSize="large" />
                                                                </IconButton>
                                                            )}
                                                        </Stack>
                                                    ))}

                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            variant="contained"
                                                            color="orange"
                                                            onClick={() => push(empFiles)}
                                                            size="large"
                                                            sx={{
                                                                borderRadius: '8px',
                                                                fontFamily: 'Lexend',
                                                                fontWeight: 700,
                                                                textTransform: 'uppercase',
                                                                mt: 1,
                                                                color: '#ffffff'
                                                            }}
                                                            startIcon={<AddIcon />}
                                                        >
                                                            Add Other Doc
                                                        </Button>
                                                    </Grid>
                                                </div>
                                            )}
                                        </FieldArray>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard> */}
                    </SubCard>
                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

export default EditloadForm;
