import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import { LoadingButton } from '@mui/lab';

/**
 * Loading Button for the Forms
 * @param {props} load
 * @param {props} btnText
 * @param {props} icon
 *
 * @returns JSX
 */
function LoadingFormButton({ load, btnText, icon, type, size, variant, color, onClickHandler }) {
    return (
        <LoadingButton
            //   fullWidth

            sx={{
                color: 'white',
                fontFamily: 'Lexend',
                fontWeight: 400,
                lineHeight: '20px',

                fontStyle: 'normal',

                height: '36px',
                fontSize: '16px',
                borderRadius: '4px',
                flex: 'none',
                order: '1',
                flexGrow: '0'
            }}
            size={size}
            type={type}
            color={color}
            variant={variant}
            endIcon={icon}
            loading={load}
            onClick={onClickHandler}
        >
            {btnText}
        </LoadingButton>
    );
}

LoadingFormButton.defaultProps = {
    color: 'orange',
    variant: 'contained',
    type: 'button',
    size: 'small'
};

export default LoadingFormButton;
