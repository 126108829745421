import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AuthLayout from 'layout/AuthLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const NewRegisterDriver = Loadable(lazy(() => import('views/pages/authentication/authentication/NewRegisterDriver')));
const AuthorityRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/AuthorityRegister')));
const SelfRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/SelfRegister')));
const PasswordVerify = Loadable(lazy(() => import('views/pages/authentication/authentication/PasswordVerify')));
const PasswordReset = Loadable(lazy(() => import('views/pages/authentication/authentication/PasswordReset')));
const DriverRegister = Loadable(lazy(() => import('views/pages/authentication/authentication/DriverRegister')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <AuthLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <NewRegisterDriver />
        },
        {
            path: '/carrier/register',
            element: <AuthorityRegister />
        },
        {
            path: '/driver/register',
            element: <DriverRegister />
        },
        {
            path: '/self/register',
            element: <SelfRegister />
        },
        {
            path: '/reset-password',
            element: <PasswordReset />
        },
        {
            path: '/reset-password/:slug',
            element: <PasswordVerify />
        }
    ]
};

export default AuthenticationRoutes;
