import PageLayoutWithBack from 'layout/PageLayoutWithBack';
import AdminTrailerForm from 'ui-component/fleet/forms/AdminTrailerForm';

import TrailerForm from 'ui-component/fleet/forms/TrailerForm';

function EditTrailer() {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.user_type === 'super_dispatcher_admin' ? '' : loginInfoFromStorage.company_id;
    return <PageLayoutWithBack>{cid === '' ? <AdminTrailerForm /> : <TrailerForm />}</PageLayoutWithBack>;
}

export default EditTrailer;
