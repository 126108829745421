import { SvgIcon } from '@mui/material';
import React from 'react';

function MCNMaintenanceIcon() {
    return (
        <SvgIcon>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_8003_24401)">
                    <path
                        d="M0.168311 4.25687C0.317617 3.63541 1.09177 3.41495 1.54598 3.86922L4.56799 6.89287H6.88962V4.57123L3.8716 1.5548C3.41953 1.10273 3.63742 0.329395 4.25575 0.177955C5.68286 -0.17152 7.99848 -0.119345 9.78879 1.67036C11.3551 3.23672 11.8583 5.57476 11.1222 7.64481L20.3573 16.8799C22.4273 16.1438 24.7654 16.647 26.3317 18.2133C28.0764 19.958 28.1792 22.2976 27.8306 23.7485C27.6813 24.3699 26.9072 24.5904 26.453 24.1361L23.431 21.1125H21.1093V23.4341L24.1266 26.4497C24.5788 26.9019 24.3607 27.6754 23.7422 27.8266C22.3157 28.1754 20.0009 28.125 18.2102 26.3349C16.6439 24.7686 16.1407 22.4305 16.8769 20.3605L7.64173 11.1253C5.57167 11.8615 3.23418 11.3583 1.66728 9.79198C-0.0774706 8.04734 -0.180235 5.70777 0.168311 4.25687Z"
                        fill="#142953"
                    />
                    <path
                        d="M13.9992 19.8011L7.00034 26.7993C5.5997 28.2 3.42956 28.3744 1.83806 27.3211L8.16088 20.9988C8.48083 20.6783 8.48083 20.1588 8.16088 19.8383C7.8404 19.5183 7.32083 19.5183 7.00034 19.8383L0.678058 26.1611C-0.375291 24.5696 -0.201373 22.3994 1.19981 20.9988L8.19807 14L13.9992 19.8011Z"
                        fill="#142953"
                    />
                    <path
                        d="M27.9133 2.3473L25.5927 6.98783C25.4724 7.22902 25.2416 7.39528 24.9747 7.43357L21.1852 7.97446L17.4793 11.6803L16.3193 10.5203L20.0247 6.81501L20.5661 3.02492C20.6044 2.75803 20.7706 2.52723 21.0118 2.40691L25.6524 0.0863727C25.9679 -0.0711375 26.3497 -0.00933662 26.5991 0.240054L27.7596 1.4006C28.009 1.64999 28.0708 2.03173 27.9133 2.3473Z"
                        fill="#142953"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_8003_24401">
                        <rect width="28" height="28" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}

export default MCNMaintenanceIcon;
