import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { Button, Grid, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';

import { Button, IconButton, Menu, MenuItem, Stack, TextField } from '@mui/material';

import axios from 'axios';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

import { createRef } from 'react';
import { useState } from 'react';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Status from 'ui-component/buttons/Status';
import AddIcon from '@mui/icons-material/Add';
import ServiceModal from 'ui-component/modals/ServiceModal';
const AllServiceForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // eslint-disable-next-line prefer-const

    const tableRef = createRef();

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(['doh']);
    const [statusChecked, setStatusChecked] = useState([]);
    const [yearFilter, setYearFilter] = useState('');
    const [searchSts, setSearchSts] = useState(true);
    const [searchFltr, setSearchFltr] = useState('');
    const [serviceId, setServiceId] = useState('');

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.company_id;

    const handleServiceCloseModal = () => {
        setOpen(false);
        tableRef.current.onQueryChange();
    };

    const handleServiceOpenModal = () => {
        setOpen(true);
    };
    const deleteService = async (id) => {
        try {
            await axios.delete(`/v1/api/mechanic/mechanic-service-type/view/${id}/`, {
                data: {},
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage.token}`
                }
            });

            tableRef.current.onQueryChange();
            dispatch(openSuccessSnackbar('Service  Deleted successfully'));
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        if (newChecked1.includes('active') && newChecked1.includes('inactive')) {
            setSearchSts();
        } else if (newChecked1.includes('active')) {
            setSearchSts(true);
        } else if (newChecked1.includes('inactive')) {
            setSearchSts(false);
        }
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    const viewUser = (id) => {
        navigate(`/setup/user/view/${id}`);
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const reset = () => {
        tableRef.current.onQueryChange();
    };

    const editService = (data) => {
        setOpen(true);
        setServiceId(data);
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Service type name</th>
                    <th className="headerTd rightHidBor">Status</th>
                    <th className="headerTd">Actions</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.service_type_name}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="Inctive" />}
                </td>
                {/* Custom cell or additional cells */}
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <PopupState variant="popover" popupId={`demoMenu-${rowData.data.service_type_id}`}>
                        {(popupState) => (
                            <div>
                                <IconButton {...bindTrigger(popupState)} size="large">
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    {...bindMenu(popupState)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    <MenuItem onClick={() => editService(rowData.data.service_type_id)}>Edit </MenuItem>

                                    <MenuItem onClick={() => deleteService(rowData.data.service_type_id)}>Remove </MenuItem>
                                </Menu>
                            </div>
                        )}
                    </PopupState>
                </td>
            </tr>
        );
    };

    return (
        // <PageLayout url="/setup/add-user">
        <>
            <Stack sx={{ mt: 5, mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                <TextField
                    type="text"
                    label="Search"
                    size="small"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    sx={{ width: '300px' }}
                />
                <div>
                    <Button
                        variant="contained"
                        sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                        color="orange"
                        onClick={() => handleServiceOpenModal()}
                        startIcon={<AddIcon />}
                        size="large"
                    >
                        ADD SERVICE TYPE
                    </Button>
                </div>
            </Stack>
            <MaterialTable
                tableRef={tableRef}
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                data={(query) =>
                    new Promise((resolve, reject) => {
                        const url = `/v1/api/mechanic/mechanic-service-type/list?page=${query.page + 1}&page_size=${
                            query.pageSize
                        }&search=${searchFltr}&mcn_company_id=${cid}`;

                        fetch(url)
                            .then((response) => response.json())
                            .then((result) => {
                          
                                resolve({
                                    data: result.results,
                                    page: query.page,
                                    totalCount: result.count
                                });
                            });
                    })
                }
                title="All Truck"
                columns={[]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    sorting: true,
                    exportButton: false,

                    toolbar: false,
                    draggable: false,
                    columnResizable: true
                }}
            />
            {/* </PageLayout> */}
            <ServiceModal open={open} handleClose={handleServiceCloseModal} fetchService="" reset={reset} serviceId={serviceId} />
        </>
    );
};

export default AllServiceForm;
