import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Skeleton, Tab, Tabs, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import CarRentalIcon from '@mui/icons-material/CarRental';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FileCopyIcon from '@mui/icons-material/FileCopy';

// third party

import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />
        }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 60,
        width: '100%'
        // backgroundColor: '#0A95FF'
    },
    '& .Mui-selected': {
        color: '#0A95FF',
        background: '#98c1e094',
        borderRadius: '2rem'
    },
    '& .MuiButtonBase-root': {
        minHeight: '50px'
    }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16,
    marginRight: theme.spacing(1),
    color: '#697586',
    '&.Mui-selected': {
        color: '#0A95FF'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
}));

// ===========================|| FIREBASE - REGISTER ||=========================== //

const ViewTrailerForm = () => {
    const theme = useTheme();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [loading, setLoading] = useState();
    const [trailerData, setTrailerData] = useState();
    const { id } = useParams();

    useEffect(() => {
        if (id && !trailerData) {
            fetchTrailer(id);
        }
    }, [trailerData, id]);

    const fetchTrailer = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/fleet/trailer/view/${id}/`, config);

            setTrailerData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                <Grid container spacing={3} sx={{ mb: 2, px: '20px' }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <Typography variant="h3" sx={{ color: '#0A95FF' }}>
                                {id && trailerData && trailerData.driver_name}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item lg={2} md={2} sm={6} xs={6}>
                        {loading ? <Skeleton height="50px" /> : <Typography variant="h4">Registration No.</Typography>}
                    </Grid>

                    <Grid item lg={10} md={10} sm={6} xs={6}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <Typography variant="body1" sx={{ color: '#0A95FF' }}>
                                {id && trailerData && trailerData.registration_no}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item lg={2} md={2} sm={6} xs={6}>
                        {loading ? <Skeleton height="50px" /> : <Typography variant="h4">Trailer No.</Typography>}
                    </Grid>

                    <Grid item lg={10} md={10} sm={6} xs={6}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <Typography variant="body1" sx={{ color: '#0A95FF' }}>
                                {id && trailerData && trailerData.unit_no}
                            </Typography>
                        )}
                    </Grid>
                </Grid>

                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    sx={{
                        '.MuiTabs-flexContainer': {
                            display: 'flex',
                            flexWrap: 'wrap'
                        }
                    }}
                >
                    <StyledTab label="Vehicle" icon={<CarRentalIcon />} iconPosition="start" value="1" />
                    <StyledTab label="Location" icon={<AssessmentIcon />} iconPosition="start" value="2" />
                    <StyledTab label="Additional" icon={<FileCopyIcon />} iconPosition="start" value="3" />
                </StyledTabs>
                <Box sx={{ px: '20px' }}>
                    {value == 1 && (
                        <Grid container spacing={1}>
                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Make
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && trailerData && trailerData.make}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Make Year
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && trailerData && trailerData.make_year}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Model
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && trailerData && trailerData.model}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Vin No
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && trailerData && trailerData.vin_no}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Service Date
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && trailerData && trailerData.service_date}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Trailer Type
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && trailerData && trailerData.trailer_type}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Trailer Length
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && trailerData && trailerData.trailer_length}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {value == 2 && (
                        <Grid container spacing={1}>
                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        State
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && trailerData && trailerData.state_name}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        City
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && trailerData && trailerData.city_name}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {value == 3 && (
                        <Grid container spacing={1}>
                            <Grid item lg={2} md={2} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Note
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        {id && trailerData && trailerData.notes}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </SubCard>
        </>
    );
};

export default ViewTrailerForm;
