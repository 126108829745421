// project imports
import { useEffect } from 'react';

import MainCard from 'ui-component/cards/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';

import ViewOrganizationForm from 'ui-component/setup/forms/ViewOrganizationForm';

import PageLayout from 'layout/PageLayout';
import ViewCompanyProfile from 'ui-component/mechanic/forms/ViewCompanyProfileForm';
// ==============================|| SAMPLE PAGE ||============================== //

const CompanyProfile = () => {
    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const userType = loginInfoFromStorage.user_type;

    return (
        <>
            <MainCard>
                <PageLayout title="Company Profile">
                    {userType === 'mechanic_admin' ? <ViewCompanyProfile /> : <ViewOrganizationForm companySetup={companySetup} />}
                </PageLayout>
            </MainCard>
        </>
    );
};

export default CompanyProfile;
