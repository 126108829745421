import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { storeData } from 'store/dashboardSlice';

const useWebSocket = (url) => {
    const dispatch = useDispatch();
    const [isReady, setIsReady] = useState(false);

    const handleStoreData = (key, value) => {
        // this will store data into the dashboard store
        dispatch(storeData({ key, value }));
    };
    const socketRef = useRef(null);

    useEffect(() => {
        socketRef.current = new WebSocket(url);

        socketRef.current.onopen = () => setIsReady(true);
        socketRef.current.onclose = () => setIsReady(false);

        // receiving the websocket event
        socketRef.current.onmessage = (e) => {
            const data = JSON.parse(e.data);

            if (data.request?.event_type === 'truck_count') {
                handleStoreData('truckCount', data.count);
            } else if (data.request?.event_type === 'driver_count') {
                handleStoreData('driverCount', data.count);
            } else if (data.request?.event_type === 'customer_count') {
                handleStoreData('customerCount', data.count);
            } else if (data.request?.event_type === 'total_loads_count') {
                handleStoreData('totalLoadsCount', data.count);
            } else if (data.request?.event_type === 'loads_count') {
                handleStoreData('loadsCount', data.data);
            } else if (data.request?.event_type === 'bols_count') {
                handleStoreData('bolsCount', data.data);
            } else if (data.request?.event_type === 'drivers_settlement') {
                handleStoreData('driversSettlement', data.data);
            } else if (data.request?.event_type === 'invoices_count') {
                handleStoreData('invoicesCount', data.data);
            } else if (data.request?.event_type === 'profit_per_customer') {
                handleStoreData('profitPerCustomer', data.data);
            } else if (data.request?.event_type === 'truck_mileage') {
                handleStoreData('truckMileage', data.data);
            } else if (data.request?.event_type === 'revenues_expenses') {
                handleStoreData('revenuesExpenses', data.data);
            } else if (data.request?.event_type === 'revenues') {
                handleStoreData('revenues', data.data);
            } else if (data.request?.event_type === 'expenses') {
                handleStoreData('expenses', data.data);
            } else if (data.request?.event_type === 'profit_loss') {
                handleStoreData('profitLoss', data.data);
            } else if (data.request?.event_type === 'gallons_burned') {
                handleStoreData('gallonsBurned', data.data);
            } else if (data.request?.event_type === 'safety_compliance') {
                handleStoreData('safetyCompliance', data.data);
            } else {
                console.log('default', data);
            }
        };

        return () => {
            socketRef.current.close();
        };
    }, [url]);

    const send = useCallback(
        (data) => {
            if (isReady && socketRef.current) {
                socketRef.current.send(JSON.stringify(data));
            } else {
                console.error('WebSocket is not ready to send messages');
            }
        },
        [isReady]
    );

    return [isReady, send];
};

export default useWebSocket;
