import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    successSnackbar: false,
    successSnackbarMessage: '',
    errorSnackbar: false,
    errorSnackbarMessage: ''
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSuccessSnackbar: (state, action) => {
            state.successSnackbar = true;
            state.successSnackbarMessage = action.payload;
        },
        openErrorSnackbar: (state, action) => {
            state.errorSnackbar = true;
            state.errorSnackbarMessage = action.payload;
        },
        resetSnackbar: (state, action) => {
            state.errorSnackbar = false;
            state.errorSnackbarMessage = '';
            state.successSnackbar = false;
            state.successSnackbarMessage = '';
        }
    }
});

export const { openSuccessSnackbar, openErrorSnackbar, resetSnackbar } = snackbarSlice.actions;

export const allsnackbar = (state) => state.snackbar;

export default snackbarSlice.reducer;
