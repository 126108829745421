import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui

import { Box, Button, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Skeleton, TextField } from '@mui/material';

// project imports
// import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';

import NewFileTypeModal from 'ui-component/modals/NewFileTypeModal';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const AddExpenseItemForm = ({ handleClose, fileId, reloadHandler, type }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [expenseData, setExpenseData] = useState('');

    const [loading, setLoading] = useState();

    const [open, setOpen] = useState(false);
    const [addError, setAddError] = useState();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const expenseDetails = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/company/expense-type/view/${fileId}`, config);

            setExpenseData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (fileId && !expenseData) {
            expenseDetails();
        }
    }, [expenseData]);

    const MemberSchema = Yup.object().shape({
        FileName: Yup.string().required('File Type Name is a required field'),        
        default: Yup.number().max(99999).min(0)
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            FileName: expenseData ? expenseData.exp_item_type_name : '',
            fileType: expenseData ? expenseData.exp_type : '',
            default: expenseData ? expenseData.default_value : 0,
            isPercent: expenseData ? expenseData.is_percent : '',
        },
        validationSchema: MemberSchema,

        onSubmit: async () => {
            try {
                if (fileId) {
                    const { data } = await axios.put(
                        `/v1/api/users/company/expense-type/view/${fileId}`,
                        {
                            exp_item_type_name: values.FileName,
                            exp_type: values.fileType,                            
                            default_value: values.default,
                            is_percent: values.isPercent,
                            created_by: loginInfoFromStorage.user_id,
                            company_id: loginInfoFromStorage.company_id,
                            is_common: expenseData && expenseData.is_common,
                            is_edit: true
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar(' Expense Type updated successfully'));
                } else {
                    const { data } = await axios.post(
                        `/v1/api/users/company/expense-type/add/`,
                        {
                            exp_item_type_name: values.FileName,
                            exp_type: values.fileType,                            
                            default_value: values.default,
                            is_percent: values.isPercent ? values.isPercent : false,
                            created_by: loginInfoFromStorage.user_id,
                            company_id: loginInfoFromStorage.company_id,
                            is_common: false
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar('  Expense Type Added successfully'));
                }
                reloadHandler();
                handleClose();
                if (type == 'settlement') {
                    navigate('/setup/settlement/expense/item', { replace: true });
                } else {
                    navigate('/setup/invoice/expense/item', { replace: true });
                }
            } catch (error) {
                setAddError(error.response.data);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>                                            
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton variant="rectangular" width="100%" height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="Type Name"
                                    {...getFieldProps('FileName')}
                                    error={Boolean((touched.FileName && errors.FileName) || (addError && addError.exp_item_type))}
                                    helperText={(touched.FileName && errors.FileName) || (addError && addError.exp_item_type)}
                                />
                            )}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton variant="rectangular" width="100%" height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    select
                                    label="Expense Type"
                                    value={values.fileType}
                                    onChange={(e) => setFieldValue('fileType', e.target.value)}
                                    error={Boolean(touched.fileType && errors.fileType) || (addError && addError.type)}
                                    helperText={(touched.fileType && errors.fileType) || (addError && addError.type)}
                                >
                                    <MenuItem value="settlement">Settlement</MenuItem>
                                    <MenuItem value="invoice">Invoice</MenuItem>
                                </TextField>
                            )}
                        </Grid>
                        {values.fileType && values.fileType === 'settlement' ? (
                            <>
                                {' '}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormLabel id="demo-radio-buttons-group-label" className="radio-label">
                                        Default Value type?
                                    </FormLabel>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            row
                                            className="radio-group"
                                            value={values.isPercent}
                                            onChange={(e) => setFieldValue('isPercent', e.target.value)}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Percent" />
                                            <FormControlLabel value="false" control={<Radio />} label="Flat" />
                                        </RadioGroup>
                                    )}
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton variant="rectangular" width="100%" height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Default Value"
                                            {...getFieldProps('default')}
                                            onChange={(e) => {
                                                setFieldValue('default', !isNaN(e.target.value) ? e.target.value : 0);
                                            }}
                                            error={Boolean((touched.default && errors.default) || (addError && addError.default))}
                                            helperText={(touched.default && errors.default) || (addError && addError.default)}
                                        />
                                    )}
                                </Grid>
                            </>
                        ) : (
                            ''
                        )}
                    </Grid>

                    <Box sx={{ mt: 2 }} align="center">
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <AnimateButton>
                                <LoadingFormButton type="submit" btnText="Save" load={isSubmitting} />
                            </AnimateButton>
                            <Button
                                variant="contained"
                                sx={{
                                    mr: 2,

                                    backgroundColor: '#EB812E',
                                    color: 'white',
                                    fontFamily: 'Lexend',
                                    fontWeight: 400,
                                    lineHeight: '20px',

                                    fontStyle: 'normal',

                                    height: '36px',
                                    fontSize: '16px',
                                    borderRadius: '4px'
                                }}
                                color="orange"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </Form>
            </FormikProvider>
            <NewFileTypeModal open={open} handleClose={handleCloseModal} />
        </>
    );
};

AddExpenseItemForm.propTypes = {
    handleClose: PropTypes.func,
    reloadHandler: PropTypes.func,
    fileId: PropTypes.any
};

export default AddExpenseItemForm;
