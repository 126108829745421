import { useState, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, useMediaQuery } from '@mui/material';

// third party
import * as Yup from 'yup';

import { Form, FormikProvider, useFormik } from 'formik';

// project imports

import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { useDispatch } from 'react-redux';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const MechanicForm = ({ handleClose, reset, fetchMechanic, mechanicId }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [companySetup, setCompanySetup] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorUpdate, setUpdateError] = useState();
    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();
    const [mechanicInfo, setMechanicInfo] = useState('');

    const googleHandler = async () => {
        console.error('Register');
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    useEffect(() => {
        if (!companySetup) {
            fetchCompany();
        }
        if (mechanicId) {
            fetchMechanicInfo();
        }
    }, [companySetup]);

    const fetchCompany = async () => {
        setLoading(true);

        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);
          
            setCompanySetup(data);
            setLoading(false);
        } catch (error) {
           
            setLoading(false);
        }
    };
    const fetchMechanicInfo = async () => {
        setLoading(true);

        // const id = 233; http://127.0.0.1:8000/v1/api/mechanic/view-mechanic/{id}/
        try {
            const { data } = await axios.get(`/v1/api/mechanic/view-mechanic/${mechanicId}/`, config);
          
            setMechanicInfo(data);
            setLoading(false);
        } catch (error) {
           
            setLoading(false);
        }
    };

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^0-9 ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const RegisterSchema = Yup.object().shape({
        mechanicNameFirst: Yup.string().required('Mechanic First Name is a required field'),
        mechanicNameLast: Yup.string().required('Mechanic Last Name is a required field'),
        email: Yup.string().required('Email is a required field'),
        phone: Yup.string().required('Phone is a required field')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            mechanicNameFirst: mechanicInfo ? mechanicInfo.user.first_name : '',
            mechanicNameLast: mechanicInfo ? mechanicInfo.user.last_name : '',
            phone: mechanicInfo ? mechanicInfo.user.phone : '',
            email: mechanicInfo ? mechanicInfo.user.email : ''
        },
        validationSchema: RegisterSchema,

        onSubmit: async () => {
            try {
               

                const user = {};
                if (!mechanicInfo) {
                    const { data } = await axios.post(
                        `/v1/api/mechanic/add-mechanic`,

                        {
                            user: {
                                username: values.email,
                                first_name: values.mechanicNameFirst,
                                last_name: values.mechanicNameLast,
                                email: values.email,
                                phone: values.phone,
                                company_id: companySetup.dsp_company_id,
                                user_type: 'mechanic'
                            },
                            zip_code: 0,
                            country_id: 33,
                            state_id: '',
                            city_id: '',
                            mcn_company_id: companySetup.dsp_company_id,
                            address: '',
                            acc_no: '',
                            hire_date: null,
                            end_date: null,
                            is_active: true,
                            is_edit: true
                        },

                        config
                    );
                    handleClose();
                    dispatch(openSuccessSnackbar('Mechanic added successfully'));
                } else {
                    const { data } = await axios.put(
                        `/v1/api/mechanic/view-mechanic/${mechanicId}/`,

                        {
                            user: {
                                username: values.email,
                                first_name: values.mechanicNameFirst,
                                last_name: values.mechanicNameLast,
                                email: values.email,
                                phone: values.phone,
                                company_id: companySetup.dsp_company_id,
                                user_type: 'mechanic',
                                is_edit: true
                            },
                            zip_code: 0,
                            country_id: 33,
                            state_id: '',
                            city_id: '',
                            mcn_company_id: companySetup.dsp_company_id,
                            address: '',
                            acc_no: '',
                            hire_date: null,
                            end_date: null,
                            is_active: true,
                            is_edit: true
                        },

                        config
                    );
                    dispatch(openSuccessSnackbar('Mechanic updated successfully'));
                }

                reset();

                handleClose();
                fetchMechanic();
            } catch (error) {
              
                setUpdateError(error.response.data);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="h1" gutterBottom>
                                    Add New Mechanic
                                </Typography>
                                {/* {errorUpdate && (
                                    <Typography variant="h6" color="error" textAlign="center">
                                        {errorUpdate}
                                    </Typography>
                                )} */}
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={matchDownSM ? 0 : 2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Mechanic First Name"
                                margin="normal"
                                type="text"
                                {...getFieldProps('mechanicNameFirst')}
                                error={Boolean((touched.mechanicNameFirst && errors.mechanicNameFirst) || (errorUpdate && errorUpdate.first_name))}
                                helperText={(touched.mechanicNameFirst && errors.mechanicNameFirst) || (errorUpdate && errorUpdate.first_name)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Mechanic Last Name"
                                margin="normal"
                                type="text"
                                {...getFieldProps('mechanicNameLast')}
                                error={Boolean((touched.mechanicNameLast && errors.mechanicNameLast) || (errorUpdate && errorUpdate.last_name))}
                                helperText={(touched.mechanicNameLast && errors.mechanicNameLast) || (errorUpdate && errorUpdate.last_name)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Phone Number"
                                margin="normal"
                                type="text"
                                onInput={NumberOnlyHandler}
                                {...getFieldProps('phone')}
                                inputProps={{ maxLength: 10 }}
                                error={Boolean((touched.phone && errors.phone) || (errorUpdate && errorUpdate.phone))}
                                helperText={(touched.phone && errors.phone) || (errorUpdate && errorUpdate.phone)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Email Id"
                                margin="normal"
                                type="email"
                                {...getFieldProps('email')}
                                error={Boolean((touched.email && errors.email) || (errorUpdate && errorUpdate.email))}
                                helperText={(touched.email && errors.email) || (errorUpdate && errorUpdate.email)}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }} align="center">
                        <AnimateButton>
                            <LoadingFormButton load={isSubmitting ? true : false} type="submit" btnText="Save" />
                        </AnimateButton>
                    </Box>
                </Form>
            </FormikProvider>
        </>
    );
};

export default MechanicForm;
