import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

import MainCard from 'ui-component/cards/MainCard';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLayout from 'layout/PageLayout';
import { useNavigate } from 'react-router';
import DriverIftaInfo from 'ui-component/accounting/forms/DriverIftaInfo';
import 'ui-component/font/fonts';
// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '8px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '18px',
                            fontWeight: 900,
                            marginRight: '5px',
                            color: 'white'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});
const DriverIfta = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <MainCard>
            <PageLayout title="Driver Total IFTA info">
                <Box sx={{ border: 'none', background: 'transparent', mt: { xs: '7vh', sm: 0 } }}>
                    <ThemeProvider theme={theme}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { display: 'none' } }}
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '1rem'
                                },
                                'MuiTabs-scroller': {
                                    marginTop: '1rem'
                                }
                            }}
                        >
                            <Tab label="Settlement" onClick={() => navigate(`/accounting/settlement`)} />
                            <Tab label="IFTA" onClick={() => navigate(`/accounting/ifta/all`)} />
                            <Tab label="My Invoices" onClick={() => navigate(`/accounting/my-invoice/all`)} />
                            <Tab label="Tax Configuration" onClick={() => navigate(`/accounting/configuration/tax`)} />
                            <Tab label="Fuel Reports" onClick={() => navigate(`/accounting/fuel-reports`)} />
                            <Tab label="External Expenses" onClick={() => navigate(`/accounting/external-expenses`)} />
                        </Tabs>
                    </ThemeProvider>
                </Box>
                <DriverIftaInfo />
            </PageLayout>
        </MainCard>
    );
};

export default DriverIfta;
