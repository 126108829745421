import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Button, FormControl, FormHelperText, Grid, IconButton, InputAdornment, Skeleton, TextField, Typography } from '@mui/material';

// project imports

// third party
import * as Yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { Stack } from '@mui/system';

import SubCard from 'ui-component/cards/SubCard';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';

import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const AdminTruckForm = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [truckDetails, setTruckDetails] = useState();
    const [driverList, setDriverList] = useState();
    const [cmpList, setCmpList] = useState();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const truckConfig = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [options, setOptions] = useState([]);
    const [cmpOptions, setCmpOptions] = useState([]);

    const [inputValue, setInputValue] = useState('');
    const [inputCmpValue, setInputCmpValue] = useState('');
    useEffect(() => {
        if (!cmpList) {
            fetchCompany();
        }
        if (id && cmpList && !truckDetails) {
            fetchTruck(id);
        }
        if (id && truckDetails && !driverList) {
            fetchDriver(truckDetails.company_id);
        }
    }, [driverList, cmpList, truckDetails, id]);

    const fetchCompany = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/company/list?cmp_type=dispatcher&cmp_type=carrier&page=1&page_size=100&search=`, config);

            setCmpOptions(data.results);
            setCmpList(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchDriver = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/company/drivers/slim?company_id=${id}&page=1&page_size=100&search=`, config);
            setDriverList(data.results);
            setOptions(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchTruck = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/fleet/truck/view/${id}/`, config);

            setTruckDetails(data);

            setLoading(false);
        } catch (error) {}
    };

    const onChangeHandle = async (value) => {
        setLoading1(true);
        const { data } = await axios.get(`/v1/api/users/company/drivers/slim?company_id=${cid}&page=1&page_size=100&search=${value}`, config);
        setLoading1(false);
        setOptions(data.results);
    };

    const onCmpChangeHandle = async (value) => {
        setLoading1(true);
        const { data } = await axios.get(`/v1/api/users/company/list?page=1&page_size=100&search=${value}`, config);
        setLoading1(false);
        setCmpOptions(data.results);
    };

    let aFiles = [
        {
            drv_doc_id: 0,
            doc_name: 'Every Three Month Inspection',
            doc_type_name: 'every_three_month_inspection',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Annual Periodic Inspection',
            doc_type_name: 'annual_periodic_inspection',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Reports of Any Maintenance',
            doc_type_name: 'reports_of_any_maintenance',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Road Side Inspection Report',
            doc_type_name: 'road_side_inspection_report',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        }
    ];

    let qFiles = [
        {
            drv_doc_id: 0,
            doc_name: 'Insurance',
            doc_type_name: 'insurance',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Annual Inspection',
            doc_type_name: 'annual_inspection',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Cab Card',
            doc_type_name: 'cab_card',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Initial Inspection',
            doc_type_name: 'initial_inspection',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Registration Card',
            doc_type_name: 'registration_card',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        }
    ];

    const TruckSchema = Yup.object().shape({
        driver: Yup.mixed().required('driver is a required field'),
        unit: Yup.string().required('unit is a required field'),
        licenseNo: Yup.string().required('license No is a required field'),
        vinNo: Yup.string().required('vin No is a required field')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            cmpId: id && truckDetails && cmpList ? cmpList.filter(({ dsp_company_id }) => dsp_company_id === truckDetails.company_id)[0] : '',
            unit: id && truckDetails ? truckDetails.unit_no : '',
            driver: id && truckDetails && driverList ? driverList.filter(({ driver_id }) => driver_id === truckDetails.driver_id)[0] : '',
            make: id && truckDetails ? truckDetails.make : '',
            year: id && truckDetails ? truckDetails.make_year : '',
            licenseNo: id && truckDetails ? truckDetails.license_no : '',
            model: id && truckDetails ? truckDetails.model : '',
            vinNo: id && truckDetails ? truckDetails.vin_no : '',
            filesQ: id && truckDetails ? truckDetails.sft_q_docs : qFiles,
            filesA: id && truckDetails ? truckDetails.sft_a_docs : aFiles
        },
        validationSchema: TruckSchema,
        onSubmit: async () => {
            try {
                const truckDet = {
                    driver_id: values.driver ? values.driver.driver_id : '',
                    unit_no: values.unit,
                    make: values.make,
                    model: values.model,
                    make_year: values.year ? format(new Date(values.year), 'yyyy') : '',
                    license_no: values.licenseNo,
                    vin_no: values.vinNo,
                    company_id: cid === '' ? values.cmpId.dsp_company_id : loginInfoFromStorage.company_id,
                    // company_id: 39,
                    is_active: true,
                    is_edit: true
                };

                const setup = new FormData();

                values.filesQ.forEach((i, index) => setup.append(`sft_q_docs[${index}].sft_doc`, i.sft_doc));
                values.filesQ.forEach((i, index) => setup.append(`sft_q_docs[${index}].sft_doc_name`, i.sft_doc_name));
                values.filesQ.forEach((i, index) => setup.append(`sft_q_docs[${index}].drv_doc_id`, i.drv_doc_id));
                values.filesQ.forEach((i, index) => setup.append(`sft_q_docs[${index}].doc_type_name`, i.doc_type_name));

                values.filesA.forEach((i, index) => setup.append(`sft_a_docs[${index}].sft_doc`, i.sft_doc));
                values.filesA.forEach((i, index) => setup.append(`sft_a_docs[${index}].sft_doc_name`, i.sft_doc_name));
                values.filesA.forEach((i, index) => setup.append(`sft_a_docs[${index}].drv_doc_id`, i.drv_doc_id));
                values.filesA.forEach((i, index) => setup.append(`sft_a_docs[${index}].doc_type_name`, i.doc_type_name));

                setup.append('truckData', JSON.stringify(truckDet));
                if (!id) {
                    const { data } = await axios.post(`/v1/api/fleet/truck/add`, setup, truckConfig);
                    dispatch(openSuccessSnackbar('Truck added successfully'));
                } else {
                    const { data } = await axios.put(`/v1/api/fleet/truck/view/${id}/`, setup, truckConfig);
                    dispatch(openSuccessSnackbar('Truck updated successfully'));
                }

                navigate('/fleet/truck/all', { replace: true });
            } catch (error) {}
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                <Typography
                                    sx={{
                                        mt: '20px',
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '32px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    Truck Details
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Stack direction="row" sx={{ mt: '20px' }} justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <LoadingFormButton load={isSubmitting} type="submit" btnText="Save" />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>
                    <SubCard
                        sx={{
                            mt: 5,
                            borderRadius: '8px',
                            mb: 2,
                            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)'
                        }}
                    >
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Company
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                className="text-input"
                                                // multiple
                                                id="driver-set"
                                                options={cmpOptions}
                                                loading={loading1}
                                                isOptionEqualToValue={(option, value) => option.dsp_company_id === value.dsp_company_id}
                                                getOptionLabel={(option) => (option.dsp_company_name ? option.dsp_company_name : '')}
                                                inputValue={inputCmpValue}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputCmpValue(newInputValue);
                                                }}
                                                value={values.cmpId}
                                                onChange={(event, newValue) => {
                                                    if (newValue != null) {
                                                        setFieldValue('cmpId', newValue);
                                                        fetchDriver(newValue.dsp_company_id);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="Company"
                                                        onChange={(ev) => {
                                                            if (ev.target.value !== '' || ev.target.value !== null || ev.target.value.length > 1) {
                                                                onCmpChangeHandle(ev.target.value);
                                                            }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            )
                                                        }}
                                                        error={Boolean(touched.cmpId && errors.cmpId)}
                                                    />
                                                )}
                                            />
                                            <FormHelperText error={Boolean(touched.cmpId && errors.cmpId)}>
                                                {touched.driver && errors.cmpId ? errors.cmpId : 'Type atleast 2 characters to populate the list'}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            // width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        General Information
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                className="text-input"
                                                // multiple
                                                id="driver-set"
                                                options={options}
                                                loading={loading1}
                                                isOptionEqualToValue={(option, value) => option.driver_id === value.driver_id}
                                                getOptionLabel={(option) => (option.name ? option.name : '')}
                                                inputValue={inputValue}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue(newInputValue);
                                                }}
                                                value={values.driver}
                                                onChange={(event, newValue) => {
                                                    // setDriverValue(newValue.name);

                                                    if (newValue != null) {
                                                        setFieldValue('driver', newValue);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="Driver Name"
                                                        onChange={(ev) => {
                                                            if (ev.target.value !== '' || ev.target.value !== null || ev.target.value.length > 1) {
                                                                onChangeHandle(ev.target.value);
                                                            }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            )
                                                        }}
                                                        error={Boolean(touched.driver && errors.driver)}
                                                    />
                                                )}
                                            />
                                            <FormHelperText error={Boolean(touched.driver && errors.driver)}>
                                                {touched.driver && errors.driver ? errors.driver : 'Type atleast 2 characters to populate the list'}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Grid>
                                {/* User Name */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="UNIT#"
                                            {...getFieldProps('unit')}
                                            error={Boolean(touched.unit && errors.unit)}
                                            helperText={touched.unit && errors.unit}
                                        />
                                    )}
                                </Grid>
                                {/* Weight */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Make"
                                            {...getFieldProps('make')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                                {/* revenue */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                label="Year"
                                                openTo="day"
                                                views={['year']}
                                                value={values.year}
                                                onChange={(newValue) => {
                                                    setFieldValue('year', newValue);
                                                }}
                                                renderInput={(params) => <TextField className="text-input" color="orange" fullWidth {...params} error={null} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>
                                {/* Load */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="License Plate"
                                            {...getFieldProps('licenseNo')}
                                            error={Boolean(touched.licenseNo && errors.licenseNo)}
                                            helperText={touched.licenseNo && errors.licenseNo}
                                        />
                                    )}
                                </Grid>
                                {/* millage */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Model"
                                            {...getFieldProps('model')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="VIN Number"
                                            {...getFieldProps('vinNo')}
                                            error={Boolean(touched.vinNo && errors.vinNo)}
                                            helperText={touched.vinNo && errors.vinNo}
                                            inputProps={{ maxLength: 17 }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>

                        {/* </Grid> */}
                        <Grid container spacing={3}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <SubCard
                                    sx={{
                                        mt: 5,
                                        borderRadius: '8px',
                                        mb: 2,
                                        boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        {/* <Grid item lg={6} md={6} sm={6} xs={12}> */}
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography
                                                sx={{
                                                    width: '375px',
                                                    height: '40px',
                                                    fontWeight: '600',
                                                    fontSize: '24px',
                                                    fontFamily: 'Lexend'
                                                }}
                                            >
                                                Qualification File
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            {loading ? (
                                                <Skeleton height="50px" />
                                            ) : (
                                                <FieldArray name="filesQ">
                                                    {({ push, remove }) => (
                                                        <Grid container spacing={2}>
                                                            {values.filesQ.map((file, index) => (
                                                                <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                                                    {/* Type  */}

                                                                    <TextField
                                                                        className="text-input"
                                                                        color="orange"
                                                                        fullWidth
                                                                        value={values.filesQ[index].sft_doc_name}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton edge="end">
                                                                                        <Button
                                                                                            component="label"
                                                                                            color="orange"
                                                                                            startIcon={<CloudUploadIcon />}
                                                                                        >
                                                                                            Upload
                                                                                            <input
                                                                                                hidden
                                                                                                type="file"
                                                                                                onInputCapture={(event) => {
                                                                                                    setFieldValue(
                                                                                                        `filesQ[${index}].sft_doc`,
                                                                                                        event.target.files[0]
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        `filesQ[${index}].sft_doc_name`,
                                                                                                        event.target.files[0].name
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        `filesQ[${index}].drv_doc_id`,
                                                                                                        values.filesQ[index].drv_doc_id
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </Button>
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        label={values.filesQ[index].doc_name}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </FieldArray>
                                            )}
                                        </Grid>

                                        {/* </Grid> */}
                                    </Grid>
                                </SubCard>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <SubCard
                                    sx={{
                                        mt: 5,
                                        borderRadius: '8px',
                                        mb: 2,
                                        boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography
                                                sx={{
                                                    width: '375px',
                                                    height: '40px',
                                                    fontWeight: '600',
                                                    fontSize: '24px',
                                                    fontFamily: 'Lexend'
                                                }}
                                            >
                                                Additional File
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            {loading ? (
                                                <Skeleton height="50px" />
                                            ) : (
                                                <FieldArray name="filesA">
                                                    {({ push, remove }) => (
                                                        <Grid container spacing={2}>
                                                            {values.filesA.map((file, index) => (
                                                                <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                                                    {/* Type  */}

                                                                    <TextField
                                                                        className="text-input"
                                                                        color="orange"
                                                                        fullWidth
                                                                        value={values.filesA[index].sft_doc_name}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton edge="end">
                                                                                        <Button
                                                                                            component="label"
                                                                                            color="orange"
                                                                                            startIcon={<CloudUploadIcon />}
                                                                                        >
                                                                                            Upload
                                                                                            <input
                                                                                                hidden
                                                                                                type="file"
                                                                                                onInputCapture={(event) => {
                                                                                                    setFieldValue(
                                                                                                        `filesA[${index}].sft_doc`,
                                                                                                        event.target.files[0]
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        `filesA[${index}].sft_doc_name`,
                                                                                                        event.target.files[0].name
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        `filesA[${index}].drv_doc_id`,
                                                                                                        values.filesA[index].drv_doc_id
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </Button>
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        label={values.filesA[index].doc_name}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </FieldArray>
                                            )}
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Form>
            </FormikProvider>
        </>
    );
};

export default AdminTruckForm;
