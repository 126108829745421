import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui

import { FormControl, Grid, MenuItem, Skeleton, TextField, Typography } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { useDispatch } from 'react-redux';
import { PatternFormat } from 'react-number-format';
import { Stack } from '@mui/system';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <PatternFormat
            {...other}
            allowEmptyFormatting
            getInputRef={ref}
            format="###-###-####"
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            // isNumericString
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

const EditCustomerForm = ({ id, tableRelaod, handleClose }) => {
    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [customerData, setCustomerData] = useState();
    const [errorData, setErrorData] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [loading1, setLoading1] = useState(false);
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    const fetchCustomer = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/dispatch/customer/view/${id}/`, config);

            setCustomerData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!stateList) {
            fetchState();
        }
        if (stateList && id && !customerData) {
            fetchCustomer(id);
        }
        if (id && stateList && customerData && !cityList) {
            getCityList(customerData.state_id);
        }
    }, [stateList, customerData, cityList, id]);

    const fetchState = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/state/list-multi?country=233,39`, config);

            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const changeStates = (value) => {
        setFieldValue('state', value);

        //  setFieldValue('companyCity', '');
        getCityList(value);
    };

    const getCityList = async (id) => {
        setLoading1(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);

            setCityList(data);
            setLoading1(false);
        } catch (error) {
            setLoading1(false);
        }
    };

    const CustomerSchema = Yup.object().shape({
        customerName: Yup.string().required('Customer Name is required'),
        bEmail: Yup.string().email('Must be a valid email').max(255),
        phone: Yup.string().required('Phone is required'),
        // fax: Yup.number().required(' fax is required'),
        // zip: Yup.number().required(' Zip code is required'),
        dotNo: Yup.number().typeError('This should be a number').required(' MC/DOT is required'),
        address: Yup.string().required('Address is required'),
        state: Yup.number().required('State is required'),
        city: Yup.number().required('City is required'),
        zip: Yup.string().required('ZIP Code is required')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            // faqQuestion: id && faqDetails ? faqDetails.faq_question : '',
            customerName: id && customerData ? customerData.customer_name : '',
            mcDot: id && customerData ? customerData.mc_no : '',
            dotNo: id && customerData ? customerData.dot_no : '',
            address: id && customerData ? customerData.address : '',

            state: id && customerData ? customerData.state_id : '',
            city: id && customerData ? customerData.city_id : '',
            zip: id && customerData ? customerData.zip_code : '',

            bEmail: id && customerData ? customerData.billing_email : '',
            invoiceMethod: id && customerData ? customerData.invoice_method : '',

            phone: id && customerData ? customerData.phone : '',
            note: id && customerData ? customerData.notes : '',
            fax: id && customerData ? customerData.fax : '',
            ext: id && customerData ? customerData.ext : ''
            // fax: '',
            // isActive: id && faqDetails ? faqDetails.is_active : true
        },
        validationSchema: CustomerSchema,
        onSubmit: async () => {
            if (!id) {
                try {
                    const { data } = await axios.post(
                        `/v1/api/dispatch/customer/add`,

                        {
                            customer_name: values.customerName,
                            mc_dot: values.mcDot,
                            address: values.address,
                            zip_code: values.zip,
                            billing_email: values.bEmail,
                            fax: values.fax,
                            phone: values.phone,
                            ext: values.ext,
                            invoice_method: values.invoiceMethod,
                            notes: values.note,
                            company_id: 233,
                            state_id: values.state,
                            city_id: values.city,
                            company_id: loginInfoFromStorage.company_id,
                            is_edit: false
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar('Customer user  added successfully'));
                    navigate('/dispatch-center/customer/all', {
                        replace: true
                    });
                } catch (error) {
                    setErrorData(error.response.data);
                }
            } else {
                try {
                    const { data } = await axios.put(
                        `/v1/api/dispatch/customer/view/${id}/`,

                        {
                            customer_name: values.customerName,
                            mc_no: values.mcDot,
                            dot_no: values.dotNo,
                            address: values.address,
                            zip_code: values.zip,
                            billing_email: values.bEmail,
                            fax: values.fax,
                            phone: values.phone,
                            ext: values.ext,
                            invoice_method: values.invoiceMethod,
                            notes: values.note,
                            company_id: 233,
                            state_id: values.state,
                            city_id: values.city,
                            company_id: loginInfoFromStorage.company_id,
                            is_edit: true
                        },
                        config
                    );

                    dispatch(openSuccessSnackbar('Customer  updated successfully'));
                    handleClose();
                    tableRelaod();

                    navigate('/dispatch-center/customer/all', {
                        replace: true
                    });
                } catch (error) {
                    setErrorData(error.response.data);
                }
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.',
        error: Boolean(touched.phone && errors.phone),
        helperText: touched.phone && errors.phone
    };
    const faxFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Fax.'
    };

    const LetterOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        {/* Customer */}
                        {/* General Info */}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography
                                fontSize="32px"
                                sx={{
                                    mt: '30px',
                                    fontFamily: 'Lexend',
                                    fontWeight: '600',
                                    lineHeight: '40px',
                                    letterSpacing: '0px',
                                    textAlign: 'left',
                                    height: '40px',
                                    width: '255px',
                                    left: '0px',
                                    top: '0px',
                                    color: '#142953'
                                }}
                            >
                                New Customer
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" sx={{ mt: '36px' }} justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <AnimateButton>
                                        <LoadingFormButton type="submit" btnText="Save" load={isSubmitting && !errorData} />
                                    </AnimateButton>
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SubCard
                        sx={{
                            mt: 5,
                            borderRadius: '8px',
                            mb: 2,
                            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)'
                        }}
                    >
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2">Customer Information</Typography>
                                </Grid>
                                {/* User Name */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Customer Name"
                                            {...getFieldProps('customerName')}
                                            onInput={LetterOnlyHandler}
                                            error={Boolean(touched.customerName && errors.customerName) || (errorData && errorData.customer_name)}
                                            helperText={(touched.customerName && errors.customerName) || (errorData && errorData.customer_name)}

                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                                {/* MC/DOT */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Search By MC #"
                                            value={values.mcDot}
                                            onInput={NumberOnlyHandler}
                                            onChange={(e) => setFieldValue('mcDot', e.target.value)}
                                            error={Boolean(touched.mcDot && errors.mcDot) || (errorData && errorData.mc_no)}
                                            helperText={(touched.mcDot && errors.mcDot) || (errorData && errorData.mc_no)}
                                        />
                                    )}
                                </Grid>

                                {/* MC/DOT */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="DOT #"
                                            value={values.dotNo}
                                            onInput={NumberOnlyHandler}
                                            onChange={(e) => setFieldValue('dotNo', e.target.value)}
                                            error={Boolean(touched.dotNo && errors.dotNo) || (errorData && errorData.dot_no)}
                                            helperText={(touched.dotNo && errors.dotNo) || (errorData && errorData.dot_no)}
                                        />
                                    )}
                                </Grid>
                                {/* MC/DOT */}
                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Address"
                                            value={values.address}
                                            onChange={(e) => setFieldValue('address', e.target.value)}
                                            error={Boolean(touched.address && errors.address)}
                                            helperText={touched.address && errors.address}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>

                                {/* State */}
                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            select
                                            type="text"
                                            label="State"
                                            value={values.state}
                                            onChange={(e) => changeStates(e.target.value)}
                                            error={Boolean(touched.state && errors.state)}
                                            helperText={touched.state && errors.state}
                                            // error={Boolean(
                                            //     (touched.companyCountry && errors.companyCountry) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.country) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.country)
                                            // )}
                                            // helperText={
                                            //     (touched.companyCountry && errors.companyCountry) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.country) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.country)
                                            // }
                                        >
                                            <MenuItem disabled selected>
                                                Select State
                                            </MenuItem>
                                            {stateList &&
                                                stateList.map((i) => (
                                                    <MenuItem key={i.id} value={i.id}>
                                                        {i.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    )}
                                </Grid>

                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    {loading || loading1 ? (
                                        <Skeleton width="100%" height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            select
                                            className="text-input"
                                            type="text"
                                            label="City"
                                            value={values.city}
                                            onChange={(e) => setFieldValue('city', e.target.value)}
                                            error={Boolean(touched.city && errors.city)}
                                            helperText={touched.city && errors.city}
                                            // error={Boolean(
                                            //     (touched.companyCountry && errors.companyCountry) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.country) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.country)
                                            // )}
                                            // helperText={
                                            //     (touched.companyCountry && errors.companyCountry) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.country) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.country)
                                            // }
                                        >
                                            <MenuItem disabled selected>
                                                Select City
                                            </MenuItem>
                                            {cityList &&
                                                cityList.map((i) => (
                                                    <MenuItem key={i.id} value={i.id}>
                                                        {i.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    )}
                                </Grid>
                                {/* millage */}
                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Zip"
                                            inputProps={{ maxLength: 5 }}
                                            value={values.zip}
                                            onChange={(e) => setFieldValue('zip', !isNaN(e.target.value) ? e.target.value : '')}
                                            error={Boolean(touched.zip && errors.zip)}
                                            helperText={touched.zip && errors.zip}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2">Contact Information</Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <PatternFormat
                                            format="###-###-####"
                                            allowEmptyFormatting
                                            // mask="_"
                                            value={values.phone}
                                            onValueChange={(value) => setFieldValue('phone', value.value)}
                                            customInput={TextField}
                                            {...textFieldProps}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <PatternFormat
                                            format="###-###-####"
                                            allowEmptyFormatting
                                            mask="_"
                                            value={values.fax}
                                            onValueChange={(value) => setFieldValue('fax', value.value)}
                                            customInput={TextField}
                                            {...faxFieldProps}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Ext.."
                                            {...getFieldProps('ext_1')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Email Address"
                                            {...getFieldProps('bEmail')}
                                            error={Boolean(touched.bEmail && errors.bEmail) || (errorData && errorData.billing_email)}
                                            helperText={(touched.bEmail && errors.bEmail) || (errorData && errorData.billing_email)}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2">Invoicing Method</Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Invoicing Method"
                                            onInput={LetterOnlyHandler}
                                            {...getFieldProps('invoiceMethod')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2">Note</Typography>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            multiline
                                            minRows={4}
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Note"
                                            {...getFieldProps('note')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                    </SubCard>
                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

export default EditCustomerForm;
