import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// material-ui
// import { useTheme } from '@mui/material/styles';
import { Box, Grid, Link, Skeleton, Stack, Tab, Tabs, Typography } from '@mui/material';

import axios from 'axios';

import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import HomeIcon from '@mui/icons-material/Home';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { styled } from '@mui/system';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const ViewMemberForm = () => {
    // const theme = useTheme();

    const StyledTabs = styled((props) => (
        <Tabs
            {...props}
            TabIndicatorProps={{
                children: <span className="MuiTabs-indicatorSpan" />
            }}
        />
    ))({
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent'
        },
        '.MuiTabs-flexContainer': {
            flexWrap: 'wrap'
        },
        '& .MuiTabs-indicatorSpan': {
            maxWidth: 60,
            width: '100%'
            // backgroundColor: '#0A95FF'
        },
        '& .Mui-selected': {
            color: '#0A95FF',
            background: '#98c1e094',
            borderRadius: '2rem'
        },
        '& .MuiButtonBase-root': {
            minHeight: '50px'
        }
    });

    const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
        textTransform: 'none',
        fontWeight: 500,
        fontSize: 16,
        marginRight: theme.spacing(1),
        color: '#697586',
        '&.Mui-selected': {
            color: '#0A95FF'
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)'
        }
    }));

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [stateList, setStateList] = useState();
    const [stateName, setStateName] = useState();
    const [cityName, setCityName] = useState();
    const [cityList, setCityList] = useState();

    const [loading, setLoading] = useState();

    const [customerData, setCustomerData] = useState();
    const { id } = useParams();
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        // if (!stateList) {
        //     fetchState();
        // }
        if (id && !customerData) {
            fetchCustomer(id);
        }
        // if (id && stateList && customerData && !cityList) {
        //     const stateData = stateList.filter((i) => i.id === customerData.state_id);

        //     setStateName(stateData.length > 0 && stateData[0].name);
        //     getCityList(customerData.state_id && customerData.state_id);
        // }

        // if (cityList.length > 0) {
        //     const cityData = cityList.filter((i) => i.id === customerData.city_id);

        //     setCityName(cityData[0].name);
        // }
    }, [customerData, id]);

    const fetchCustomer = async (id) => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/company/users/${id}/`, config);
            //
            setCustomerData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchState = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getCityList = async (id) => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);

            setCityList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                        sx={{
                            width: '375px',
                            height: '40px',
                            fontWeight: '600',
                            fontSize: '24px',
                            fontFamily: 'Lexend'
                        }}
                    >
                        User Details
                    </Typography>
                </Grid>
            </Grid>

            <Box
                sx={{
                    width: '100%',
                    typography: 'body1',
                    border: 'none',
                    backgroundColor: 'transparent'
                }}
            >
                <div style={{ padding: 15 }}>
                    <Typography variant="h2" sx={{ mt: 3, fontWeight: 600 }} color="#0A95FF">
                        {id && customerData && customerData.user_id.name}
                    </Typography>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center">
                        <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
                            {id && customerData && customerData.user_id.email}
                        </Typography>
                    </Stack>
                </div>
                <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example" sx={{ flexWrap: 'wrap' }}>
                    <StyledTab label="Contacts" icon={<PermContactCalendarIcon />} iconPosition="start" value="1" />
                    <StyledTab label="Address" icon={<HomeIcon />} iconPosition="start" value="2" />
                    <StyledTab label="Note" icon={<AssessmentIcon />} iconPosition="start" value="3" />
                </StyledTabs>
                <Box sx={{ px: '20px' }}>
                    {value == 1 && (
                        <Grid container spacing={1}>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Email Id
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography sx={{ mt: 3 }}>
                                        <Link
                                            underline="none"
                                            component="button"
                                            onClick={(e) => {
                                                window.location.href = `mailto:${customerData ? customerData.user_id.email : ''}`;
                                                e.preventDefault();
                                            }}
                                            sx={{
                                                color: '#0A95FF',
                                                height: '20px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            {customerData ? customerData.user_id.email : 'N/A'}
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Phone Number
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography sx={{ mt: 3 }}>
                                        <Link
                                            underline="none"
                                            component="button"
                                            onClick={(e) => {
                                                window.location.href = `tel:${customerData ? customerData.user_id.phone : 'N/A'}`;
                                                e.preventDefault();
                                            }}
                                            sx={{
                                                color: '#0A95FF',
                                                height: '20px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            {customerData ? customerData.user_id.phone : 'N/A'}
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        EXT.
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography sx={{ mt: 3 }}>
                                        <Link
                                            underline="none"
                                            component="button"
                                            onClick={(e) => {
                                                window.location.href = `tel:${companySetup ? companySetup.phone_ext : ''}`;
                                                e.preventDefault();
                                            }}
                                            sx={{
                                                color: '#0A95FF',
                                                height: '20px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            {customerData ? customerData.phone_ext : 'N/A'}
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {value == 2 && (
                        <Grid container spacing={1}>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Full Address
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography sx={{ mt: 3 }}>
                                        <Link
                                            underline="none"
                                            component="button"
                                            sx={{
                                                color: '#0A95FF',
                                                height: '20px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            {customerData ? customerData.user_id.address : 'N/A'}
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        State
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography sx={{ mt: 3 }}>
                                        <Link
                                            underline="none"
                                            component="button"
                                            sx={{
                                                color: '#0A95FF',
                                                height: '20px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            {customerData ? customerData.state_name : 'N/A'}
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        City
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography sx={{ mt: 3 }}>
                                        <Link
                                            underline="none"
                                            component="button"
                                            sx={{
                                                color: '#0A95FF',
                                                height: '20px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            {customerData ? customerData.city_name : 'N/A'}
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Zip Code
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography sx={{ mt: 3 }}>
                                        <Link
                                            underline="none"
                                            component="button"
                                            sx={{
                                                color: '#0A95FF',
                                                height: '20px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            {customerData ? customerData.user_id.zip_code : 'N/A'}
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {value == 3 && (
                        <Grid container spacing={1}>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography
                                        sx={{
                                            mt: 3,
                                            fontWeight: '500'
                                        }}
                                    >
                                        Note
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography sx={{ mt: 3 }}>
                                        <Link
                                            underline="none"
                                            component="button"
                                            sx={{
                                                color: '#0A95FF',
                                                height: '20px',
                                                fontFamily: 'Lexend'
                                            }}
                                        >
                                            {customerData ? customerData.notes : 'N/A'}
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default ViewMemberForm;
