import { MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function ExpenseDoughnutChart({ send, isReady }) {
    const currentYear = Number(new Date().getFullYear());
    const years = [currentYear, currentYear - 1, currentYear - 2];

    const [year, setYear] = useState(currentYear);
    const dashboardState = useSelector((state) => state.dashboard);
    const [isRequest, setIsRequest] = useState(true);

    const isMobile = window.innerWidth < 600;
    const isTablet = window.innerWidth >= 600 && window.innerWidth <= 1024;

    const option = {
        animationDuration: 1500,
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontSize: isMobile ? 8 : isTablet ? 16 : 12
            }
        },
        color: ['#5AC47C', '#142953', '#D3505F', '#FFD366', '#f48120'],
        series: [
            {
                name: 'Expenses',
                type: 'pie',
                radius: isMobile ? '50%' : isTablet ? '65%' : '75%',
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    position: 'outside',
                    fontSize: isMobile ? 10 : isTablet ? 14 : 14,
                    formatter: (params) => {
                        const label = params.name;
                        const maxLength = isMobile ? 10 : isTablet ? 20 : 25;
                        const words = label.split(' ');

                        let line1 = '';
                        let line2 = '';

                        for (let word of words) {
                            if (line1.length + word.length <= maxLength) {
                                line1 += (line1 ? ' ' : '') + word;
                            } else {
                                line2 += (line2 ? ' ' : '') + word;
                            }
                        }

                        if (!line2) {
                            return line1;
                        }

                        return `${line1}\n${line2}`;
                    }
                },

                data: dashboardState.expenses || [
                    { value: 0, name: 'bills amount' },
                    { value: 0, name: 'drivers expense amount' },
                    { value: 0, name: 'driver payment amount' },
                    { value: 0, name: 'expenses amount' },
                    { value: 0, name: 'ifta fuel tax amount' },
                    { value: 0, name: 'owner payment amount' }
                ]
            }
        ]
    };

    const handleYearChange = (e) => {
        setIsRequest(true);
        setYear(e.target.value);
    };

    useEffect(() => {
        if (isRequest && isReady) {
            send({ event_type: 'expenses', year: Number(year) });
            setIsRequest(false);
        }
    }, [isReady, year]);
    return (
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '20px', borderRadius: '15px', height: '400px' }}>
            {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: { xs: 'start', sm: 'center' },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    }
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: '6vw', lg: '1.7vw', sm: '3vw' },
                        margin: 0
                    }}
                >
                    All Expenses
                </Typography>
                <TextField select label="Year" value={year} onChange={handleYearChange} sx={{ mt: { xs: '2rem', sm: '0' } }}>
                    {years.map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            {dashboardState.expenses !== undefined ? (
                <ReactECharts option={option} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
            ) : (
                <Skeleton height={'100%'} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
            )}
        </div>
    );
}

export default ExpenseDoughnutChart;
