import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import { forwardRef } from 'react';

import AddDriverSafetyComplianceForm from 'ui-component/safetyCompliance/AddDriverSafetyComplianceForm';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function DQModal({ fullWidth, fullScreen, maxWidth, open, handleClose, id, docId, reloadData }) {
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogTitle>
                <Typography variant="h4" color="primary">
                    DQ File
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <AddDriverSafetyComplianceForm handleClose={handleClose} id={id} docId={docId} reloadData={reloadData} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DQModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'sm'
};

export default DQModal;
