import { useState, useEffect } from 'react';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import { Button, FormControl, Grid, IconButton, MenuItem, Skeleton, TextField, Typography, useTheme } from '@mui/material';

// project imports

import AddIcon from '@mui/icons-material/Add';
// third party
import * as Yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { Stack } from '@mui/system';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { useNavigate } from 'react-router';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const EditloadForm = ({ id, handleClose }) => {
    const theme = useTheme();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const [loading, setLoading] = useState(false);
    const [loadData, setLoadData] = useState();

    const [stateList, setStateList] = useState();
    const [loadingCity, setLoadingCity] = useState(false);
    const navigate = useNavigate();
    const [addError, setAddError] = useState();

    const dispatch = useDispatch();

    const configAddress = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const FaqSchema = Yup.object().shape({
        pickup: Yup.array().of(
            Yup.object().shape({
                address: Yup.string().required('This field is required'),
                city_id: Yup.number().required('This field is required'),
                state_id: Yup.number().required('This field is required'),
                zip_code: Yup.string().required('This field is required')
            })
        ),
        delivery: Yup.array().of(
            Yup.object().shape({
                address: Yup.string().required('This field is required'),
                city_id: Yup.number().required('This field is required'),
                state_id: Yup.number().required('This field is required'),
                zip_code: Yup.string().required('This field is required')
            })
        )
    });
    let empPic = {
        dsp_load_type_id: 0,
        code: '',
        company_name: '',
        address: '',
        city_list: [],
        city_id: '',
        state_id: '',
        zip_code: '',
        date: '',
        time: '',
        load_type: 'pickup'
    };
    let empDel = {
        dsp_load_type_id: 0,
        code: '',
        company_name: '',
        address: '',
        city_list: [],
        city_id: '',
        state_id: '',
        zip_code: '',
        date: '',
        time: '',
        load_type: 'delivery'
    };
    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            pickup: loadData
                ? loadData.pickups.map(({ dsp_load_type_id, company_name, address, city_list, city_id, state_id, zip_code, date, time }) => {
                      return {
                          dsp_load_type_id,
                          company_name,
                          address,
                          city_list,
                          city_id,
                          state_id,
                          zip_code,
                          date,
                          time: new Date(date + ' ' + time)
                      };
                  })
                : [empPic],
            delivery: loadData
                ? loadData.delivery.map(({ dsp_load_type_id, company_name, address, city_list, city_id, state_id, zip_code, date, time }) => {
                      return {
                          dsp_load_type_id,
                          company_name,
                          address,
                          city_list,
                          city_id,
                          state_id,
                          zip_code,
                          date,
                          time: new Date(date + ' ' + time)
                      };
                  })
                : [empDel]
        },
        validationSchema: FaqSchema,
        onSubmit: async () => {
            try {
                const { data } = await axios.put(
                    `/v1/api/dispatch/load-type/edit/${id}/`,
                    {
                        pickups: values.pickup.map(({ dsp_load_type_id, company_name, address, city_id, state_id, zip_code, date, time }) => ({
                            company_name,
                            dsp_load_type_id,
                            address,
                            city_id,
                            state_id,
                            zip_code,
                            date: format(new Date(date), 'yyyy-MM-dd'),
                            time: format(new Date(time), 'k:mm')
                        })),
                        delivery: values.delivery.map(({ dsp_load_type_id, company_name, address, city_id, state_id, zip_code, date, time }) => ({
                            company_name,
                            dsp_load_type_id,
                            address,
                            city_id,
                            state_id,
                            zip_code,
                            date: format(new Date(date), 'yyyy-MM-dd'),
                            time: format(new Date(time), 'k:mm')
                        }))
                    },
                    configAddress
                );
                dispatch(openSuccessSnackbar('Load Updated successfully'));
                navigate('/dispatch-center');
            } catch (error) {
                setAddError(error.response.data.error);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const fetchStateList = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);
            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getCityList = async (state, cityList, id) => {
        setFieldValue(state, id);
        setLoadingCity(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);
            setLoadingCity(false);
            setFieldValue(cityList, data);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchLoadData = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/dispatch/load/view/${id}/`, config);
            setLoadData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const deletePickup = async (remove, index, id) => {
        if (id !== 0) {
            try {
                const { data } = await axios.delete(`/v1/api/dispatch/load-type/view/${id}/`, config);
                remove(index);
            } catch (error) {
        
            }
        } else {
            remove(index);
        }
    };

    useEffect(() => {
        if (id && !loadData) {
            fetchLoadData(id);
            // loadData
        }

        if (!stateList) {
            fetchStateList();
        }
    }, [stateList, loadData, id]);

   

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography variant="h2">Edit Load Form</Typography>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <LoadingFormButton
                                        btnText="Save"
                                        type="submit"
                                        load={isSubmitting && !addError}
                                        variant="contained"
                                        component="span"
                                        size="large"
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    {/* Pickup */}
                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <FieldArray name="pickup">
                                {({ push, insert, remove }) => {
                                    return (
                                        <>
                                            {values.pickup.map((i, index) => {
                                                const pickupErrors = (errors.pickup?.length && errors.pickup[index]) || {};
                                                const pickupTouched = (touched.pickup?.length && touched.pickup[index]) || {};
                                                return (
                                                    <div key={index}>
                                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    borderRadius: '50%',
                                                                    backgroundColor: theme.palette.orange.main,
                                                                    color: '#ffffff',
                                                                    px: '10px',
                                                                    py: '7px'
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Typography>
                                                            <Typography
                                                                variant="h2"
                                                                sx={{
                                                                    ml: 1,
                                                                    mt: 0.8,
                                                                    fontWeight: 500,
                                                                    fontFamily: 'Lexend',
                                                                    letterSpacing: 2
                                                                }}
                                                            >
                                                                PICKUP
                                                            </Typography>
                                                            {values.pickup.length > 1 && (
                                                                <IconButton
                                                                    disabled={isSubmitting && Boolean(errors.pickup)}
                                                                    onClick={() => deletePickup(remove, index, i.dsp_load_type_id)}
                                                                    size="small"
                                                                    color="error"
                                                                >
                                                                    <DeleteForeverIcon fontSize="large" />
                                                                </IconButton>
                                                            )}
                                                        </Stack>
                                                        <Grid container spacing={3}>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="Address"
                                                                    value={values.pickup[index].address}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`pickup[${index}].address`, e.target.value);
                                                                    }}
                                                                    error={Boolean(
                                                                        (pickupTouched.street && pickupErrors.street) ||
                                                                            (addError && addError.pickups[index].street)
                                                                    )}
                                                                    helperText={
                                                                        (pickupTouched.street && pickupErrors.street) ||
                                                                        (addError && addError.pickups[index].street)
                                                                    }
                                                                />
                                                            </Grid>
                                                            {/* State */}
                                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    select
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="State"
                                                                    value={values.pickup[index].state_id}
                                                                    onChange={(e) =>
                                                                        getCityList(`pickup[${index}].state_id`, `pickup[${index}].city_list`, e.target.value)
                                                                    }
                                                                    error={Boolean(pickupTouched.state_id && pickupErrors.state_id)}
                                                                    helperText={pickupTouched.state_id && pickupErrors.state_id}
                                                                >
                                                                    <MenuItem value="">Select State</MenuItem>
                                                                    {stateList && stateList.map((i) => <MenuItem value={i.id}>{i.name}</MenuItem>)}
                                                                </TextField>
                                                            </Grid>
                                                            {/* City */}
                                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                {loading || loadingCity ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        select
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="City"
                                                                        value={values.pickup[index].city_id}
                                                                        onChange={(e) => setFieldValue(`pickup[${index}].city_id`, e.target.value)}
                                                                        error={Boolean(pickupTouched.city_id && pickupErrors.city_id)}
                                                                        helperText={pickupTouched.city_id && pickupErrors.city_id}
                                                                    >
                                                                        <MenuItem value="">Select City</MenuItem>
                                                                        {values.pickup[index].city_list &&
                                                                            values.pickup[index].city_list.map((i) => (
                                                                                <MenuItem value={i.id}>{i.name}</MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                )}
                                                            </Grid>

                                                            {/* Zip */}
                                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="Zip"
                                                                    value={values.pickup[index].zip_code}
                                                                    onChange={(e) => setFieldValue(`pickup[${index}].zip_code`, e.target.value)}
                                                                    error={Boolean(pickupTouched.zip_code && pickupErrors.zip_code)}
                                                                    helperText={pickupTouched.zip_code && pickupErrors.zip_code}
                                                                />
                                                            </Grid>
                                                            {/* Date */}
                                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DesktopDatePicker
                                                                        className="text-input"
                                                                        label="Pickup Date"
                                                                        openTo="day"
                                                                        views={['day']}
                                                                        value={values.pickup[index].date}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue(`pickup[${index}].date`, newValue);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField color="orange" fullWidth {...params} error={null} />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            {/* Time */}
                                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <TimePicker
                                                                        className="text-input"
                                                                        color="orange"
                                                                        label={'Pickup Time'}
                                                                        value={values.pickup[index].time}
                                                                        onChange={(newValue) => setFieldValue(`pickup[${index}].time`, newValue)}
                                                                        renderInput={(params) => (
                                                                            <TextField color="orange" fullWidth {...params} error={null} />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            {/* Company Name*/}
                                                            <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mb: 2 }}>
                                                                <TextField
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="Company Name"
                                                                    value={values.pickup[index].company_name}
                                                                    onChange={(e) => setFieldValue(`pickup[${index}].company_name`, e.target.value)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                );
                                            })}
                                            <Button
                                                variant="contained"
                                                color="orange"
                                                onClick={() => push(empPic)}
                                                size="large"
                                                sx={{
                                                    borderRadius: '8px',
                                                    fontFamily: 'Lexend',
                                                    fontWeight: 700,
                                                    textTransform: 'uppercase',
                                                    mt: 1,
                                                    color: '#ffffff'
                                                }}
                                                startIcon={<AddIcon />}
                                            >
                                                Add PickUp
                                            </Button>
                                        </>
                                    );
                                }}
                            </FieldArray>
                        )}
                    </SubCard>
                    {/* Delivery */}
                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        {/* <Grid container spacing={2}> */}
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <FieldArray name="delivery">
                                {({ push, remove }) => (
                                    <>
                                        {values.delivery.map((i, index) => {
                                            const deliveryErrors = (errors.delivery?.length && errors.delivery[index]) || {};
                                            const deliveryTouched = (touched.delivery?.length && touched.delivery[index]) || {};
                                            return (
                                                <div key={index}>
                                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                borderRadius: '50%',
                                                                backgroundColor: theme.palette.orange.main,
                                                                color: '#ffffff',
                                                                px: '10px',
                                                                py: '7px'
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </Typography>
                                                        <Typography
                                                            variant="h2"
                                                            sx={{ ml: 1, mt: 0.8, fontWeight: 500, fontFamily: 'Lexend', letterSpacing: 2 }}
                                                        >
                                                            DELIVERY
                                                        </Typography>
                                                        {values.delivery.length > 1 && (
                                                            <IconButton
                                                                disabled={isSubmitting && Boolean(errors.delivery)}
                                                                onClick={() => deletePickup(remove, index, i.dsp_load_type_id)}
                                                                size="small"
                                                                color="error"
                                                            >
                                                                <DeleteForeverIcon fontSize="large" />
                                                            </IconButton>
                                                        )}
                                                    </Stack>
                                                    <Grid container spacing={3}>
                                                        {/* <Grid item container spacing={2} xs={10} sm={10} lg={10} md={10}>
                                                <Grid item xs={12}> */}
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                className="text-input"
                                                                color="orange"
                                                                type="text"
                                                                label="Address"
                                                                value={values.delivery[index].address}
                                                                onChange={(e) => setFieldValue(`delivery[${index}].address`, e.target.value)}
                                                                onBlur={(e) => {
                                                                    fetchAddress(e.target.value, `delivery[${index}].longitude`, `delivery[${index}].latitude`);
                                                                }}
                                                                // {...getFieldProps('address')}
                                                                error={
                                                                    Boolean(deliveryTouched.street && deliveryErrors.street) ||
                                                                    (addError && addError.delivery.length && addError.delivery[index].street)
                                                                }
                                                                helperText={
                                                                    (deliveryTouched.street && deliveryErrors.street) ||
                                                                    (addError && addError.delivery.length && addError.delivery[index].street)
                                                                }
                                                            />
                                                        </Grid>
                                                        {/* State */}
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            {loading ? (
                                                                <Skeleton height="20px" />
                                                            ) : (
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="State"
                                                                    value={values.delivery[index].state_id}
                                                                    onChange={(e) =>
                                                                        getCityList(
                                                                            `delivery[${index}].state_id`,
                                                                            `delivery[${index}].city_list`,
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    error={Boolean(deliveryTouched.state_id && deliveryErrors.state_id)}
                                                                    helperText={deliveryTouched.state_id && deliveryErrors.state_id}
                                                                >
                                                                    <MenuItem value="">Select State</MenuItem>
                                                                    {stateList && stateList.map((i) => <MenuItem value={i.id}>{i.name}</MenuItem>)}
                                                                </TextField>
                                                            )}
                                                        </Grid>
                                                        {/* City */}
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            {loading || loadingCity ? (
                                                                <Skeleton height="50px" />
                                                            ) : (
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="City"
                                                                    value={values.delivery[index].city_id}
                                                                    onChange={(e) => setFieldValue(`delivery[${index}].city_id`, e.target.value)}
                                                                    error={Boolean(deliveryTouched.city_id && deliveryErrors.city_id)}
                                                                    helperText={deliveryTouched.city_id && deliveryErrors.city_id}
                                                                >
                                                                    <MenuItem value="">Select City</MenuItem>
                                                                    {values.delivery[index].city_list &&
                                                                        values.delivery[index].city_list.map((i) => <MenuItem value={i.id}>{i.name}</MenuItem>)}
                                                                </TextField>
                                                            )}
                                                        </Grid>

                                                        {/* Zip */}
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                className="text-input"
                                                                color="orange"
                                                                type="text"
                                                                label="Zip"
                                                                value={values.delivery[index].zip_code}
                                                                onChange={(e) => setFieldValue(`delivery[${index}].zip_code`, e.target.value)}
                                                                error={Boolean(deliveryTouched.zip_code && deliveryErrors.zip_code)}
                                                                helperText={deliveryTouched.zip_code && deliveryErrors.zip_code}
                                                            />
                                                        </Grid>
                                                        {/* Date */}
                                                        <Grid item lg={3} md={3} sm={3} xs={12}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DesktopDatePicker
                                                                    className="text-input"
                                                                    label="Delivery Date"
                                                                    openTo="day"
                                                                    views={['day']}
                                                                    value={values.delivery[index].date}
                                                                    onChange={(newValue) => {
                                                                        setFieldValue(`delivery[${index}].date`, newValue);
                                                                    }}
                                                                    renderInput={(params) => <TextField color="orange" fullWidth {...params} error={null} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        {/* Time */}
                                                        <Grid item lg={3} md={3} sm={3} xs={12}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <TimePicker
                                                                    className="text-input"
                                                                    color="orange"
                                                                    label={'Delivery Time'}
                                                                    value={values.delivery[index].time}
                                                                    onChange={(newValue) => setFieldValue(`delivery[${index}].time`, newValue)}
                                                                    renderInput={(params) => <TextField color="orange" fullWidth {...params} error={null} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        {/* Company Name*/}
                                                        <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mb: 2 }}>
                                                            <TextField
                                                                fullWidth
                                                                className="text-input"
                                                                color="orange"
                                                                type="text"
                                                                label="Company Name"
                                                                value={values.delivery[index].company_name}
                                                                onChange={(e) => setFieldValue(`delivery[${index}].company_name`, e.target.value)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            );
                                        })}
                                        <Grid item xs={6}>
                                            <Button
                                                disabled={isSubmitting}
                                                variant="contained"
                                                color="orange"
                                                onClick={() => push(empDel)}
                                            
                                                size="large"
                                                sx={{
                                                    borderRadius: '8px',
                                                    fontFamily: 'Lexend',
                                                    fontWeight: 700,
                                                    textTransform: 'uppercase',
                                                    mt: 1,
                                                    color: '#ffffff'
                                                }}
                                                startIcon={<AddIcon />}
                                            >
                                                Add Delivery
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </FieldArray>
                        )}
                    </SubCard>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

export default EditloadForm;
