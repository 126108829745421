import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, Grid, Link, MenuItem, Skeleton, Stack, Tab, Tabs, TextField, Typography, useMediaQuery } from '@mui/material';

// project imports
import useScriptRef from 'hooks/useScriptRef';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import HomeIcon from '@mui/icons-material/Home';
import NotesIcon from '@mui/icons-material/Notes';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { styled } from '@mui/material/styles';
import { PatternFormat } from 'react-number-format';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />
        }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 60,
        width: '100%'
        // backgroundColor: '#0A95FF'
    },
    '& .Mui-selected': {
        color: '#0A95FF',
        background: '#98c1e094',
        borderRadius: '2rem'
    },
    '& .MuiButtonBase-root': {
        minHeight: '50px'
    }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16,
    marginRight: theme.spacing(1),
    color: '#697586',
    '&.Mui-selected': {
        color: '#0A95FF'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
}));

const ViewCustomerForm = (props) => {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();

    const [loading, setLoading] = useState();
    const [loading1, setLoading1] = useState();
    const [customerData, setCustomerData] = useState();
    const { id } = useParams();

    useEffect(() => {
        if (!stateList) {
            fetchState();
        }
        if (id && !customerData) {
            fetchCustomer(id);
        }
        if (id && customerData && !cityList) {
            getCityList(customerData.state_id);
        }
    }, [stateList, customerData, cityList, id]);

    const fetchCustomer = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/dispatch/customer/view/${id}/`, config);

            setCustomerData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchState = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const changeStates = (value) => {
        setFieldValue('state', value);

        //  setFieldValue('companyCity', '');
        getCityList(value);
    };

    const getCityList = async (id) => {
        setLoading1(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);

            setCityList(data);
            setLoading1(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const TrailerSchema = Yup.object().shape({});

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            registrationNo: id && customerData ? customerData.registration_no : '',
            trilerNo: id && customerData ? customerData.trailer_no : '',
            inServiceDate: id && customerData ? customerData.service_date : '',

            make: id && customerData ? customerData.make : '',
            model: id && customerData ? customerData.model : '',
            year: id && customerData ? customerData.make_year : '',
            vin: id && customerData ? customerData.vin_no : '',

            tailerType: id && customerData ? customerData.trailer_type : '',
            tailerLenght: id && customerData ? customerData.trailer_length : '',

            note: id && customerData ? customerData.notes : '',
            state: id && customerData ? customerData.state_id : '',
            city: id && customerData ? customerData.city_id : '',
            city: id && customerData ? customerData.city_id : '',
            isActive: id && customerData ? customerData.is_active : false
        },
        validationSchema: TrailerSchema,
        onSubmit: async () => {}
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                <Grid container spacing={1}>
                    {/* Customer */}
                    {/* General Info */}

                    <Box
                        sx={{
                            width: '100%',
                            typography: 'body1',
                            border: 'none',
                            backgroundColor: 'transparent'
                        }}
                    >
                        <div style={{ padding: 15 }}>
                            <Typography variant="h2" sx={{ mt: 3, fontWeight: 600 }} color="#0A95FF">
                                {id && customerData ? customerData.customer_name : ''}
                            </Typography>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
                                    MC#
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 3,
                                        fontWeight: 500,
                                        marginLeft: 4
                                    }}
                                >
                                    {id && customerData ? customerData.mc_no : ''}
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
                                    DOT#
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 3,
                                        fontWeight: 500,
                                        marginLeft: 4
                                    }}
                                >
                                    {id && customerData ? customerData.dot_no : ''}
                                </Typography>
                            </Stack>
                        </div>
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="styled tabs example"
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    flexWrap: 'wrap'
                                }
                            }}
                        >
                            <StyledTab label="Contacts" icon={<PermContactCalendarIcon />} iconPosition="start" value="1" />
                            <StyledTab label="Address" icon={<HomeIcon />} iconPosition="start" value="2" />
                            <StyledTab label="Invoice Method" icon={<AssessmentIcon />} iconPosition="start" value="3" />
                            <StyledTab label="Note" icon={<NotesIcon />} iconPosition="start" value="4" />
                        </StyledTabs>
                        <Box sx={{ px: '20px' }}>
                            {value == 1 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={4} xs={4}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Billing Email Id
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={5} xs={5}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `mailto:${customerData ? customerData.billing_email : ''}`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {id && customerData ? customerData.billing_email : 'N/A'}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={4} xs={4}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Phone Number
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={5} xs={5}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `tel:${customerData ? customerData.phone : ''}`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {customerData ? (
                                                        <PatternFormat value={customerData.phone} displayType={'text'} format="###-###-####" />
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={4} xs={4}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                EXT.
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={5} xs={5}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `tel:${customerData ? customerData.ext : ''}`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {customerData ? customerData.ext : 'N/A'}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={4} xs={4}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                FAX
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={5} xs={5}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {customerData ? customerData.fax : 'N/A'}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 2 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Address
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {customerData ? customerData.address : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                State
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {customerData ? customerData.state_name : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                City
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {customerData ? customerData.city_name : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Zip
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {customerData ? customerData.zip_code : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 3 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Method
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {customerData ? customerData.invoice_method : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 4 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Note
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {customerData ? customerData.notes : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </SubCard>
        </>
    );
};

export default ViewCustomerForm;
