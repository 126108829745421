import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Link, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import logo from 'assets/images/logo.jpeg';
// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {/* logo & toggler button */}
            {/* <Box
                sx={{
                    background: '#ffffff',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        background: theme.palette.primary.main,
                        width: 220,

                        padding: '8px',

                        display: 'flex',
                        borderRadius: '10px',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto'
                        }
                    }}
                >
                    <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                        <LogoSection />
                    </Box>
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                </Box>
            </Box> */}
            {!matchDownMd && (
                <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
                    <Box component="img" src={logo} sx={{ width: 235, height: 70, cursor: 'pointer' }} />
                </Link>
            )}

            {/* <img src={logo} width={235} alt="souvenir" /> */}
            <Box
                sx={{
                    background: theme.palette.primary.main,
                    display: 'flex',
                    padding: '10px',
                    height: '70px',
                    ml: matchDownMd ? 0 : '15px',
                    xs: 'block',
                    width: 1
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                            '&:hover': {
                                background: theme.palette.primary[200],
                                color: theme.palette.primary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
                {/* header search */}
                {/* <SearchSection /> */}
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ flexGrow: 1 }} />

                {/* notification & profile */}
                {/* <NotificationSection /> */}
                <ProfileSection />
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
