import { useState, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { Autocomplete, Grid, Skeleton, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

// project imports

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Stack } from '@mui/system';

import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';

import ReactApexChart from 'react-apexcharts';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const ReportsLoadCompareForm = () => {
    const [driverList, setDriverList] = useState();

    const [loading, setLoading] = useState(false);
    const [loadingLo, setLoadingLo] = useState(false);

    const [driverLoad, setDriverLoad] = useState(0);

    const [daysFilterLoad, setDaysFilterLoad] = useState(1825);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const [options, setOptions] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [searchDriver, setSearchDriver] = useState('');
    const cId = loginInfoFromStorage.company_id;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const FaqSchema = Yup.object().shape({});

    useEffect(() => {
        if (!driverList) {
            fetchDriverList();
        }
        fetchDataDriver(driverLoad);
    }, [daysFilterLoad, driverLoad]);

    const fetchDriverList = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&is_main=true&page=1&page_size=100&search=${searchDriver}`,
                config
            );
            setDriverList(data.results);
            setOptions(data.results);
            setLoading(false);
        } catch (error) {}
    };

    const [chartOptions, setChartOptions] = useState({
        chart: {
            id: 'area-chart',
            zoom: false,
            menu: false,
            toolbar: {
                show: false // Disable the toolbar/menu options
            }
        },
        xaxis: {
            categories: []
        }
    });

    const [chartSeries, setChartSeries] = useState([
        {
            name: 'Series 1',
            data: []
        }
    ]);

    const fetchDataDriver = async (driverLoad) => {
        setLoadingLo(true);
        try {
            const response = await axios.get(`/v1/api/dispatch/load/report/driver/${cId}?uid=${driverLoad}&days=${daysFilterLoad}`, config);

            // Update chart options
            setChartOptions({
                ...chartOptions,
                xaxis: {
                    categories: response.data.category
                }
            });

            // Update chart series
            setChartSeries(response.data.data);
            setLoadingLo(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingLo(false);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {},
        validationSchema: FaqSchema,

        onSubmit: async () => {}
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    const handleFilterDriverChange = (e) => {
        if (e != '') {
            const selectedValue = e;

            setDriverLoad(selectedValue.map((i) => i.driver_id).join(','));
        } else {
            setDriverLoad(0);
        }
    };

    const loadDayHandler = (event, newValue) => {
        setDaysFilterLoad(newValue);
    };
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        {/* Customer */}

                        {/* Driver */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                                    <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                                        <Typography variant="h4"> Load Per Driver</Typography>
                                        <ToggleButtonGroup
                                            color="orange"
                                            size="small"
                                            value={daysFilterLoad}
                                            exclusive
                                            onChange={loadDayHandler}
                                            aria-label="button radio group"
                                        >
                                            <ToggleButton value={30} aria-label="1M">
                                                1M
                                            </ToggleButton>
                                            <ToggleButton value={180} aria-label="1M">
                                                6M
                                            </ToggleButton>
                                            <ToggleButton value={365} aria-label="1Y">
                                                1Y
                                            </ToggleButton>
                                            <ToggleButton value={1825} aria-label="all">
                                                All
                                            </ToggleButton>
                                        </ToggleButtonGroup>

                                        <Autocomplete
                                            className="text-input"
                                            multiple
                                            size="small"
                                            sx={{ width: '150px' }}
                                            id="driver-set"
                                            options={options}
                                            loading={loading1}
                                            isOptionEqualToValue={(option, value) => option.driver_id === value.driver_id}
                                            getOptionLabel={(option) => (option.name ? option.name : '')}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            value={values.driver}
                                            onChange={(event, newValue) => {
                                                // setDriverValue(newValue.name);
                                                setFieldValue('driver', newValue);
                                                handleFilterDriverChange(newValue);
                                                //   handleDriverChange(newValue.driver_id);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Driver Name"
                                                    onChange={(ev) => {
                                                        if (ev.target.value !== '' || ev.target.value !== null || ev.target.value.length > 1) {
                                                            onChangeHandle(ev.target.value);
                                                        }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                    error={Boolean(touched.driver && errors.driver)}
                                                />
                                            )}
                                        />
                                    </Stack>

                                    {driverLoad ? (
                                        loading || loadingLo ? (
                                            <Skeleton height="300px" />
                                        ) : (
                                            <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={350} />
                                        )
                                    ) : (
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                            <Typography variant="h5"> Please select driver to show the graph</Typography>
                                        </Stack>
                                    )}
                                </SubCard>
                            )}
                        </Grid>
                    </Grid>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

export default ReportsLoadCompareForm;
