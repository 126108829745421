const { createSlice } = require("@reduxjs/toolkit");

const stepsSlice = createSlice({
    name: 'stepsSlice',
    initialState: ['', '', '', '', '', '', '', '', ''],
    reducers: {

    }
})

export default stepsSlice.reducer