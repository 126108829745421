import React from 'react';
import MemberForm from 'ui-component/setup/forms/MemberForm';

import PageLayoutWithBack from 'layout/PageLayoutWithBack';

function EditUser() {
    return (
        <PageLayoutWithBack>        
                <MemberForm />          
        </PageLayoutWithBack>
    );
}

export default EditUser;
