import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui
// import { useTheme } from '@mui/material/styles';
import { Box, Grid, MenuItem, Skeleton, TextField } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';

import NewFileTypeModal from 'ui-component/modals/NewFileTypeModal';
import PropTypes from 'prop-types';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const AddFileTypeForm = ({ handleClose, fileId, fileType, fileTypeName, reloadHandler }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [docData, setDocData] = useState();

    const [loading, setLoading] = useState();
    const [open, setOpen] = useState(false);

    // const theme = useTheme();
    // const scriptedRef = useScriptRef();
    // const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
    };
    const handleClickOpenModal = (e) => {
        e === 'add' ? setOpen(true) : '';
    };

    const fileTypeData = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/fleet/safety/doc-type-view/${fileId}/`, config);

            setDocData(data);
            setLoading(false);
        } catch (error) {
         

            setLoading(false);
        }
    };
    useEffect(() => {
        if (!docData) {
            fileTypeData();
        }
    }, []);

    const MemberSchema = Yup.object().shape({
        FileName: Yup.string().required('File Type Name is a required field')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            FileName: docData ? docData.stf_doc_type_name : '',
            fileType: docData ? docData.doc_type : ''
        },
        validationSchema: MemberSchema,
        onSubmit: async () => {
            try {
                if (fileId) {
                    const { data } = await axios.put(
                        `/v1/api/fleet/safety/doc-type-view/${fileId}/`,
                        {
                            stf_doc_type_name: values.FileName,
                            stf_doc_type: values.fileType,
                            doc_type: values.fileType,

                            company_id: loginInfoFromStorage.company_id
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar(' File type updated successfully'));
                } else {
                    const { data } = await axios.post(
                        `/v1/api/fleet/safety/doc-type/add/`,
                        {
                            stf_doc_type_name: values.FileName,
                            stf_doc_type: values.fileType,
                            doc_type: values.fileType,
                            created_by: loginInfoFromStorage.user_id,
                            company_id: loginInfoFromStorage.company_id
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar(' File type Added successfully'));
                }
                reloadHandler();
                handleClose();
                navigate('/setup/configuration/file', { replace: true });
            } catch (response) {
              
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        {/* DQ type */}

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            {loading ? (
                                <Skeleton variant="rectangular" width="100%" height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    select
                                    type="text"
                                    label="File Type For"
                                    value={values.fileType}
                                    onChange={(e) => {
                                        setFieldValue('fileType', e.target.value);
                                        handleClickOpenModal(e.target.value);
                                    }}
                                >
                                    <MenuItem disabled selected>
                                        File Type For
                                    </MenuItem>

                                    <MenuItem value="dq">Drive</MenuItem>
                                    <MenuItem value="vq">Vehicle</MenuItem>
                                </TextField>
                            )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            {loading || loading1 ? (
                                <Skeleton variant="rectangular" width="100%" height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="File Type Name"
                                    {...getFieldProps('FileName')}
                                    error={Boolean(touched.FileName && errors.FileName)}
                                    helperText={touched.FileName && errors.FileName}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }} align="center">
                        <AnimateButton>
                            <LoadingFormButton type="submit" btnText="Save" load={isSubmitting} />
                        </AnimateButton>
                    </Box>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
            <NewFileTypeModal open={open} handleClose={handleCloseModal} />
        </>
    );
};
AddFileTypeForm.propTypes = {
    handleClose: PropTypes.func,
    reloadHandler: PropTypes.func,
    fileId: PropTypes.any,
    fileType: PropTypes.any,
    fileTypeName: PropTypes.any
};
export default AddFileTypeForm;
