import { useState, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { Autocomplete, CircularProgress, Grid, Skeleton, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

import { Stack } from '@mui/system';

import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';

import ReactApexChart from 'react-apexcharts';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const ReportsCompareForm = () => {
    const [driverList, setDriverList] = useState();
    const [driver, setDriver] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingRev, setLoadingRev] = useState(false);
    const [searchDriver, setSearchDriver] = useState('');
    const [daysFilter, setDaysFilter] = useState(1825);
    const [options, setOptions] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cId = loginInfoFromStorage.company_id;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const FaqSchema = Yup.object().shape({});

    useEffect(() => {
        if (!driverList) {
            fetchDriverList();
        }

        fetchData(driver);
    }, [driverList, daysFilter, driver]);

    const fetchDriverList = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&is_main=true&page=1&page_size=100&search=${searchDriver}`,
                config
            );
            setDriverList(data.results);
            setOptions(data.results);
            setLoading(false);
        } catch (error) {}
    };

    const [chartOptions, setChartOptions] = useState({
        chart: {
            id: 'area-chart',
            zoom: false,
            menu: false,
            toolbar: {
                show: false // Disable the toolbar/menu options
            }
        },
        xaxis: {
            categories: []
        }
    });

    const [chartSeries, setChartSeries] = useState([
        {
            name: 'Series 1',
            data: []
        }
    ]);

    const fetchData = async (driver) => {
        setLoadingRev(true);
        try {
            const response = await axios.get(`/v1/api/dispatch/revenue/report/driver/${cId}?uid=${driver}&days=${daysFilter}`, config);
            setChartOptions({
                ...chartOptions,

                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: response.data.category
                }
            });

            // Update chart series
            setChartSeries(response.data.data);
            setLoadingRev(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingRev(false);
        }
    };

    const handleFilterChange = (e) => {
        const selectedValue = e;

        setDriver(selectedValue.map((i) => i.driver_id).join(','));
    };

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {},
        validationSchema: FaqSchema,

        onSubmit: async () => {}
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    const [value, setValue] = useState('');

    const handleValueChange = (event, newValue) => {
        setValue(newValue);
        setDaysFilter(newValue);
    };
    const onChangeHandle = async (value) => {
        setLoading1(true);
        const { data } = await axios.get(
            `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100&search=${value}`,
            config
        );
        setLoading1(false);
        setOptions(data.results);
    };
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        {/* Customer */}

                        {/* Driver */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                                    <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                                        <Typography variant="h3"> Revenue Bar</Typography>

                                        <ToggleButtonGroup
                                            color="orange"
                                            size="small"
                                            value={daysFilter}
                                            exclusive
                                            onChange={handleValueChange}
                                            aria-label="button radio group"
                                        >
                                            <ToggleButton value={30} aria-label="1M">
                                                1M
                                            </ToggleButton>
                                            <ToggleButton value={180} aria-label="1M">
                                                6M
                                            </ToggleButton>
                                            <ToggleButton value={365} aria-label="1Y">
                                                1Y
                                            </ToggleButton>
                                            <ToggleButton value={1825} aria-label="all">
                                                All
                                            </ToggleButton>
                                        </ToggleButtonGroup>

                                        <Autocomplete
                                            className="text-input"
                                            multiple
                                            size="small"
                                            sx={{ width: '150px' }}
                                            id="driver-set"
                                            options={options}
                                            loading={loading1}
                                            isOptionEqualToValue={(option, value) => option.driver_id === value.driver_id}
                                            getOptionLabel={(option) => (option.name ? option.name : '')}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            value={values.driver}
                                            onChange={(event, newValue) => {
                                                // setDriverValue(newValue.name);
                                                setFieldValue('driver', newValue);
                                                handleFilterChange(newValue);
                                                //   handleDriverChange(newValue.driver_id);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Driver Name"
                                                    onChange={(ev) => {
                                                        if (ev.target.value !== '' || ev.target.value !== null || ev.target.value.length > 1) {
                                                            onChangeHandle(ev.target.value);
                                                        }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                    error={Boolean(touched.driver && errors.driver)}
                                                />
                                            )}
                                        />
                                    </Stack>

                                    {driver ? (
                                        loading || loadingRev ? (
                                            <Skeleton height="300px" />
                                        ) : (
                                            <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={350} />
                                        )
                                    ) : (
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                            <Typography variant="h5"> Please select driver to show the graph</Typography>
                                        </Stack>
                                    )}
                                </SubCard>
                            )}
                        </Grid>
                    </Grid>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

export default ReportsCompareForm;
