import PropTypes from 'prop-types';
import { useState } from 'react';
import axios from 'axios';
import { Button, Card, CardContent, Divider, FormControl, Skeleton, Typography } from '@mui/material';
import { Box, Stack, useMediaQuery } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';
// import { isMobile } from 'react-device-detect';

const SettlementPreViewForm = ({ handleCloseModal, settlementData, id }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const submitHandler = async () => {
        const data = JSON.parse(JSON.stringify(settlementData));

        data['total_revenue'] = Number(data['total_revenue']).toFixed(2);
        setIsSubmitting(true);
        try {
            if (id) {
                const response = await axios.put(`/v1/api/accounting/stlm/view/${id}`, data, config);
            } else {
                const response = await axios.post(`/v1/api/accounting/stlm/add/`, data, config);
            }
            toast.success('Settlement added successfully');
            setTimeout(() => {
                navigate(`/accounting/settlement`, { replace: true });
            }, 1000);
        } catch (error) {
            toast.error("Can't save the data");
            console.log(error);
        }
        setIsSubmitting(false);
    };

    return (
        <>
            {loading ? (
                <Skeleton height="200px" />
            ) : (
                <>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                        <Typography variant="subtitle1"> Total Revenue</Typography>
                        <Typography variant="body2" color="green" sx={{ fontWeight: 'bold' }}>
                            {settlementData && settlementData.total_revenue}
                        </Typography>
                    </Stack>
                    {settlementData &&
                        settlementData.expenses.map((i) => (
                            <div key={i.stlm_exp_item_id}>
                                <Divider sx={{ my: 1 }} />
                                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                                    <Typography variant="subtitle1">{i.exp_name}</Typography>
                                    <Typography variant="body1" color="error">
                                        {i.is_percent ? parseFloat((settlementData.total_revenue * i.amount) / 100).toFixed(2) : i.amount}
                                    </Typography>
                                </Stack>
                            </div>
                        ))}
                    <Divider sx={{ my: 1 }} />
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                        <Typography variant="subtitle1"> Net Income</Typography>
                        <Typography variant="body2" color="green" sx={{ fontWeight: 'bold' }}>
                            {settlementData && settlementData.income}
                        </Typography>
                    </Stack>
                </>
            )}

            <Stack direction="row" sx={{ mt: 5 }} justifyContent="center" alignItems="center" spacing={2}>
                <Typography align="center" fontWeight={500} fontSize={18}>
                    Driver Salary
                </Typography>
            </Stack>

            <Divider sx={{ mt: 3 }} />
            {loading ? (
                <Skeleton height="200px" />
            ) : (
                <>
                    {isMobile ? (
                        settlementData &&
                        settlementData.drivers.map((item, idx) => (
                            <Card
                                key={idx}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Driver : {item.driver_name !== '' ? item.driver_name : item.owner_name}
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        {' '}
                                        {settlementData && settlementData.stlm_type == 'split' ? Percentage : ''}{' '}
                                        {settlementData && settlementData.stlm_type == 'split' ? <TableCell align="center">{item.percent} %</TableCell> : ''}
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        {settlementData && settlementData.stlm_type == 'mileage' ? (
                                            <>
                                                <TableCell align="center">Mileage</TableCell>
                                                <TableCell align="center">Mileage Multiplier</TableCell>
                                            </>
                                        ) : (
                                            ''
                                        )}{' '}
                                        {settlementData && settlementData.stlm_type == 'mileage' ? (
                                            <>
                                                <TableCell align="center">{item.mileage} </TableCell>
                                                <TableCell align="center">{item.mileage_multiplier} </TableCell>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Income : {item.income}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Deduction : {item.deduction}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Reason : {item.deduction_reason}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Bonus : {item.bonus_amt}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Bonus Explanation : {item.bonus_reason}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Reimbursement : {item.rmb_amt}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Reimbursement Explanation : {item.rmb_reason}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Net Income : {item.net_income}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    ) : isTablet ? (
                        <>
                            {loading ? (
                                <>
                                    <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                        <CircularProgress />
                                    </Box>
                                </>
                            ) : (
                                settlementData &&
                                settlementData.drivers.map((item, idx) => (
                                    <Card
                                        key={idx}
                                        sx={{
                                            marginTop: '2rem',
                                            marginBottom: '1.5rem',
                                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                            borderRadius: '15px',
                                            backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                        }}
                                    >
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>
                                                    Driver : {item.driver_name !== '' ? item.driver_name : item.owner_name}
                                                </Typography>

                                                <Typography sx={{ marginBottom: '.5rem' }}>Income : {item.income}</Typography>
                                            </Box>

                                            {settlementData && settlementData.stlm_type === 'split' ? (
                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                                        gap: '0.5rem',
                                                        marginBottom: '0.5rem'
                                                    }}
                                                >
                                                    <Typography sx={{ marginBottom: '.5rem' }}>Percentage</Typography>
                                                    <Typography sx={{ marginBottom: '.5rem' }}>{item.percent} %</Typography>
                                                </Box>
                                            ) : null}

                                            {settlementData && settlementData.stlm_type === 'mileage' ? (
                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                                        gap: '0.5rem',
                                                        marginBottom: '0.5rem'
                                                    }}
                                                >
                                                    <Typography>Mileage</Typography>
                                                    <Typography>{item.mileage}</Typography>
                                                    <Typography>Mileage Multiplier</Typography>
                                                    <Typography>{item.mileage_multiplier}</Typography>
                                                </Box>
                                            ) : null}

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Deduction : {item.deduction}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Reason : {item.deduction_reason}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Bonus : {item.bonus_amt}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Bonus Explanation : {item.bonus_reason}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Reimbursement : {item.rmb_amt}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Reimbursement Explanation : {item.rmb_reason}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Net Income : {item.net_income}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                ))
                            )}
                        </>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Driver</TableCell>
                                        {settlementData && settlementData.stlm_type == 'split' ? <TableCell align="center">Percentage</TableCell> : ''}
                                        {settlementData && settlementData.stlm_type == 'mileage' ? (
                                            <>
                                                <TableCell align="center">Mileage</TableCell>
                                                <TableCell align="center">Mileage Multiplier</TableCell>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        <TableCell align="center">Income</TableCell>
                                        <TableCell align="center">Deduction</TableCell>
                                        <TableCell align="center">Reason</TableCell>
                                        <TableCell align="center">Bonus</TableCell>
                                        <TableCell align="center">Bonus Explanation</TableCell>
                                        <TableCell align="center">Reimbursement</TableCell>
                                        <TableCell align="center">Reimbursement Explanation</TableCell>
                                        <TableCell align="right">Net Income</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {settlementData &&
                                        settlementData.drivers.map((item, index) => (
                                            <>
                                                <TableRow key={index} sx={{ border: 0 }}>
                                                    <TableCell component="tr" scope="row">
                                                        {item.driver_name !== '' ? item.driver_name : item.owner_name}
                                                    </TableCell>
                                                    {settlementData && settlementData.stlm_type == 'split' ? (
                                                        <TableCell align="center">{item.percent} %</TableCell>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {settlementData && settlementData.stlm_type == 'mileage' ? (
                                                        <>
                                                            <TableCell align="center">{item.mileage} </TableCell>
                                                            <TableCell align="center">{item.mileage_multiplier} </TableCell>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <TableCell align="center">{item.income}</TableCell>
                                                    <TableCell align="center">{item.deduction}</TableCell>
                                                    <TableCell align="center">{item.deduction_reason}</TableCell>
                                                    <TableCell align="center">{item.bonus_amt}</TableCell>
                                                    <TableCell align="center">{item.bonus_reason}</TableCell>
                                                    <TableCell align="center">{item.rmb_amt}</TableCell>
                                                    <TableCell align="center">{item.rmb_reason}</TableCell>
                                                    <TableCell align="right">{item.net_income}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </>
            )}

            <Stack direction="row" sx={{ mt: 5 }} justifyContent="center" alignItems="center" spacing={2}>
                <Typography align="center" fontWeight={500} fontSize={18}>
                    Onwer Income
                </Typography>
                <Divider sx={{ mt: 5 }} />
            </Stack>
            <Divider sx={{ mt: 3 }} />
            {loading ? (
                <Skeleton height="200px" />
            ) : (
                <>
                    {isMobile ? (
                        settlementData &&
                        settlementData.owner.map((item, idx) => (
                            <Card
                                key={idx}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Owner : {item.owner_name !== '' ? item.owner_name : 0.0}</Typography>

                                    <Typography sx={{ marginBottom: '.5rem' }}>Deduction : {item.deduction}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Reason : {item.deduction_reason}</Typography>

                                    <Typography sx={{ marginBottom: '.5rem' }}>Net Income : {item.net_income}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    ) : isTablet ? (
                        <>
                            {loading ? (
                                <>
                                    <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                        <CircularProgress />
                                    </Box>
                                </>
                            ) : (
                                settlementData &&
                                settlementData.owner.map((item, idx) => (
                                    <Card
                                        key={idx}
                                        sx={{
                                            marginTop: '2rem',
                                            marginBottom: '1.5rem',
                                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                            borderRadius: '15px',
                                            backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                        }}
                                    >
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Owner : {item.owner_name !== '' ? item.owner_name : 0.0}</Typography>

                                                <Typography sx={{ marginBottom: '.5rem' }}>Deduction : {item.deduction}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Reason : {item.deduction_reason}</Typography>

                                                <Typography sx={{ marginBottom: '.5rem' }}>Net Income : {item.net_income}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                ))
                            )}
                        </>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Owner</TableCell>
                                        <TableCell align="center">Deduction</TableCell>
                                        <TableCell align="center">Reason</TableCell>
                                        <TableCell align="right">Net Income</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {settlementData &&
                                        settlementData.owner.map((item, index) => (
                                            <>
                                                <TableRow key={index} sx={{ border: 0 }}>
                                                    <TableCell component="tr" scope="row">
                                                        {item.owner_name !== '' ? item.owner_name : 0.0}
                                                    </TableCell>
                                                    <TableCell align="center">{item.deduction}</TableCell>
                                                    <TableCell align="center">{item.deduction_reason}</TableCell>
                                                    <TableCell align="right">{item.net_income}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </>
            )}

            <Stack direction="row" sx={{ mt: '20px' }} justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                <FormControl variant="outlined" align="right">
                    <LoadingButton
                        loading={isSubmitting}
                        variant="contained"
                        sx={{ color: 'white', backgroundColor: '#EB812E' }}
                        color="orange"
                        onClick={() => submitHandler()}
                    >
                        Save
                    </LoadingButton>
                </FormControl>
                <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleCloseModal}>
                    Cancel
                </Button>
            </Stack>
        </>
    );
};

SettlementPreViewForm.propTypes = {
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    driver: PropTypes.any
};
export default SettlementPreViewForm;
