import { useState, useEffect } from 'react';

import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, IconButton, InputAdornment, MenuItem, Skeleton, Stack, TextField, Typography } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import axios from 'axios';
import { useDispatch } from 'react-redux';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import PropTypes from 'prop-types';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const PaymentTrackingForm = ({ factorId, handleClose, renderTable }) => {
    const dispatch = useDispatch();

    const [factorData, setFactorData] = useState();

    const [loading, setLoading] = useState();

    const [addError, setAddError] = useState();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    // const scriptedRef = useScriptRef();
    // const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [docData, setDocData] = useState();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const driverConfig = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const getFactorData = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/dispatch/factor-comp/view/${factorId}/`, config);
            setFactorData(data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (factorId && !factorData) {
            getFactorData();
        }
    }, []);

    const InvoiceTrackingSchema = Yup.object().shape({});

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            carrierName: factorId && factorData ? factorData.carrier_info.carrier_name : '',
            invoice: factorId && factorData ? factorData.inv_info.inv_no : '',
            accNo: factorId && factorData ? factorData.carrier_info.acc_no : '',
            amount: factorId && factorData ? factorData.amount : '',

            paymentStatus: factorId && factorData ? factorData.payment_status : '',

            dop: factorId && factorData ? factorData.payment_date + 'T00:00' : '',
            description: factorId && factorData ? factorData.description : ''
        },
        validationSchema: InvoiceTrackingSchema,
        onSubmit: async () => {
            try {
                const { data } = await axios.put(
                    `/v1/api/dispatch/factor-comp/view/${factorId}/`,

                    {
                        payment_status: values.paymentStatus,
                        payment_date: values.dop ? format(new Date(values.dop), 'yyyy-MM-dd') : '',
                        description: values.description,
                        is_active: true,
                        is_edit: false,
                        fct_id: factorData.fct_id,
                        load_id: factorData.load_id,
                        invoice_id: factorData.invoice_id
                    },
                    config
                );
                dispatch(openSuccessSnackbar('Payment tracking updated successfully'));
                handleClose();
                renderTable();
            } catch (error) {
                setAddError(error.response.data);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <Typography
                            sx={{
                                mb: 5,
                                textAlign: 'center',
                                width: '375px',
                                height: '40px',
                                fontWeight: '600',
                                fontSize: '32px',
                                fontFamily: 'Lexend'
                            }}
                        >
                            Payment Tracking
                        </Typography>
                    </Stack>

                    <Grid container spacing={3}>
                        {/* DQ type */}

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    label="Carrier Name"
                                    {...getFieldProps('carrierName')}
                                    // error={Boolean(
                                    //     (touched.companyName && errors.companyName) ||
                                    //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // )}
                                    // helperText={
                                    //     (touched.companyName && errors.companyName) ||
                                    //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // }
                                />
                            )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    label="Invoice"
                                    {...getFieldProps('invoice')}
                                    // error={Boolean(
                                    //     (touched.companyName && errors.companyName) ||
                                    //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // )}
                                    // helperText={
                                    //     (touched.companyName && errors.companyName) ||
                                    //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // }
                                />
                            )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    label="Account#"
                                    {...getFieldProps('accNo')}
                                    // error={Boolean(
                                    //     (touched.companyName && errors.companyName) ||
                                    //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // )}
                                    // helperText={
                                    //     (touched.companyName && errors.companyName) ||
                                    //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // }
                                />
                            )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    label="Amount#"
                                    {...getFieldProps('amount')}
                                    // error={Boolean(
                                    //     (touched.companyName && errors.companyName) ||
                                    //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // )}
                                    // helperText={
                                    //     (touched.companyName && errors.companyName) ||
                                    //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // }
                                />
                            )}
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            {loading ? (
                                <Skeleton variant="rectangular" width="100%" height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    select
                                    type="text"
                                    label="Payment Status"
                                    value={values.paymentStatus}
                                    onChange={(e) => {
                                        setFieldValue('paymentStatus', e.target.value);
                                    }}
                                >
                                    <MenuItem disabled selected>
                                        Paymrnt Status
                                    </MenuItem>
                                    <MenuItem value="approved">Approved</MenuItem>
                                    <MenuItem value="pending">Pending</MenuItem>
                                    <MenuItem value="rejected">Rejected</MenuItem>
                                    <MenuItem value="submitted">Submitted</MenuItem>
                                    <MenuItem value="disputed">Disputed</MenuItem>
                                </TextField>
                            )}
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            {loading ? (
                                <Skeleton variant="rectangular" width="100%" height="50px" />
                            ) : (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Date of payment"
                                        openTo="day"
                                        views={['day']}
                                        value={values.dop}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        onChange={(newValue) => {
                                            setFieldValue(`dop`, newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                color="orange"
                                                fullWidth
                                                {...params}
                                                error={null}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    label="Description"
                                    {...getFieldProps('description')}
                                    // error={Boolean(
                                    //     (touched.companyName && errors.companyName) ||
                                    //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // )}
                                    // helperText={
                                    //     (touched.companyName && errors.companyName) ||
                                    //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // }
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={5} sx={{ mt: 3 }}>
                        <Button
                            variant="outlined"
                            sx={{
                                fontFamily: 'Lexend',
                                fontWeight: 400,
                                lineHeight: '20px',

                                fontStyle: 'normal',

                                height: '36px',
                                fontSize: '16px',
                                borderRadius: '4px'
                            }}
                            size="medium"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <AnimateButton>
                            <LoadingFormButton type="submit" btnText="Submit" load={isSubmitting} />
                        </AnimateButton>
                    </Stack>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

PaymentTrackingForm.propTypes = {
    reloadData: PropTypes.any,
    factorId: PropTypes.any
};

export default PaymentTrackingForm;
