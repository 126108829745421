import { useState, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Radio,
    RadioGroup,
    Skeleton,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';

import { Form, FormikProvider, useFormik } from 'formik';

// project imports

import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import MechanicModal from 'ui-component/modals/MechanicModal';
import ServiceModal from 'ui-component/modals/ServiceModal';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// ===========================|| FIREBASE - REGISTER ||=========================== //

const MechanicSetupForm = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [companySetup, setCompanySetup] = useState();

    const [loading1, setLoading1] = useState(false);
    const [loading, setLoading] = useState();
    const [errorUpdate, setUpdateError] = useState();

    const [level, setLevel] = useState();
    const [options, setOptions] = useState([]);
    const [options1, setOptions1] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [mechanicList, setMechanicList] = useState();
    const [serviceList, setServiceList] = useState();
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [fileObject, setFileObject] = useState(null);

    const handleMechanicsChange = (event, newValues) => {
        if (newValues) {
            setFieldValue('mechanics', newValues);
        }
    };

    const handleServiceChange = (event, newValues) => {
        if (newValues) {
            setFieldValue('service_types', newValues);
        }
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
   
    useEffect(() => {
        if (!companySetup) {
            fetchCompany();
            fetchMechanic();
            fetchService();
        }

        if (companySetup && companySetup.logo) {
            handleFetchFile(companySetup.logo);
        }
    }, [companySetup, mechanicList, serviceList]);

    const fetchMechanic = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/mechanic/add-mechanic`, config);

            setMechanicList(data);
            setOptions(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
           
        }
    };

    const fetchService = async () => {
        setLoading1(true);

        try {
            const { data } = await axios.get(`/v1/api/mechanic/mechanic-service-type/add/`, config);
           

            setServiceList(data);
            setOptions1(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
           
        }
    };

    const onChangeHandle = async (value) => {
        setLoading1(true);
        // setTruck('');
        const { data } = await axios.get(
            `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100&search=${value}`,
            config
        );
        setLoading1(false);
        setOptions(data.results);
    };

    const handleCloseModal = () => {
        setOpen(false);
        fetchMechanic();
    };

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleServiceCloseModal = () => {
        setOpen1(false);
        fetchService();
    };

    const handleServiceOpenModal = () => {
        setOpen1(true);
    };
    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^0-9 ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const cconfig = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const fetchCompany = async () => {
        setLoading(true);

        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);
           
            setCompanySetup(data);
            setLoading(false);
        } catch (error) {
          
            setLoading(false);
        }
    };

    const serviceFreqHandler = (e) => {
        setFieldValue('serviceFrequency', e.target.value);
    };

    const handleFetchFile = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const fileName = url.split('/').pop();

            // Create a File object from the blob

            const fileObject = new File([blob], fileName, { type: blob.type });

            setFileObject(fileObject);
        } catch (error) {
            console.error('Error fetching or creating File object:', error);
        }
    };

    const RegisterSchema = Yup.object().shape({
        // routingNumber: Yup.string().required('Routing Number is a required field'),
        // bankName: Yup.string().required('Bank Name is a required field'),
        // accountNumber: Yup.string().required('account Number is a required field'),
        // bankAddress: Yup.string().required('account Number is a required field')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            service_types: [],
            mechanics: [],
            companyName: companySetup ? companySetup.dsp_company_name : '',
            companyAddress: companySetup ? companySetup.address : '',
            phone: companySetup ? companySetup.phone : '',
            serviceFrequency: '',
            amount: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: async () => {
            try {
                const setup = new FormData();
                setup.append('dsp_company_name', values.companyName);
                setup.append('logo', fileObject);
                setup.append('email', companySetup.email);
                setup.append('fact_client_no', companySetup.fact_info.fact_client_no);
                // setup.append('fact_comp_name', companySetup.fact_info.fact_comp_name);
                // setup.append('fact_address', companySetup.fact_info.fact_address);
                // setup.append('fact_phone', companySetup.fact_info.fact_phone);
                // setup.append('fact_fax', companySetup.fact_info.fact_fax);
                setup.append('address', values.companyAddress);
                setup.append('phone', values.phone);
                setup.append('routing_no', companySetup.routing_no);
                setup.append('bank_name', companySetup.bank_name);
                setup.append('acc_no', companySetup.acc_no);
                setup.append('bank_address', companySetup.bank_address);
                const res = await axios.post(`/v1/api/users/company/setup/`, setup, cconfig);

               
                const { data } = await axios.post(
                    `/v1/api/mechanic/mechanic-service/add/`,
                    {
                        service_frequency: values.serviceFrequency,
                        service_amount: values.amount,
                        mechanics: values.mechanics,
                        service_types: values.service_types,
                        mcn_company_id: loginInfoFromStorage && loginInfoFromStorage.company_id,
                        created_by: loginInfoFromStorage && loginInfoFromStorage.user_id
                    },
                    config
                );

                navigate('/mechanic/driver', { replace: true });
            } catch (error) {
             
                setUpdateError(error.response.data.detail);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
   
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="h1" gutterBottom>
                                    Setup As Mechanic
                                </Typography>
                                {errorUpdate && (
                                    <Typography variant="h6" color="error" textAlign="center">
                                        {errorUpdate}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={matchDownSM ? 0 : 2}>
                        <Grid item md={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Company Name"
                                margin="normal"
                                type="text"
                                {...getFieldProps('companyName')}
                                error={Boolean((touched.companyName && errors.companyName) || (errorUpdate && errorUpdate.dsp_company_name))}
                                helperText={(touched.companyName && errors.companyName) || (errorUpdate && errorUpdate.dsp_company_name)}
                            />
                        </Grid>

                        <Grid item md={9} sm={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <FormControl fullWidth>
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={options}
                                        getOptionLabel={(option) => option.mcn_name}
                                        filterSelectedOptions
                                        onChange={handleMechanicsChange}
                                        renderInput={(params) => <TextField {...params} label="Select Mechanic" placeholder="Mechanic list" />}
                                    />
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
                                {' '}
                                <Button
                                    variant="contained"
                                    sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                    color="orange"
                                    onClick={() => handleOpenModal()}
                                    startIcon={<AddIcon />}
                                    size="large"
                                    // sx={{ pt: 1.5, pb: 1.5 }}
                                >
                                    Add Mechanic
                                </Button>{' '}
                            </Stack>
                        </Grid>

                        <Grid item md={9} sm={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <FormControl fullWidth>
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={options1}
                                        getOptionLabel={(option) => option.service_type_name}
                                        filterSelectedOptions
                                        onChange={handleServiceChange}
                                        renderInput={(params) => <TextField {...params} label="Select Service" placeholder="Service" />}
                                    />
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <Button
                                    variant="contained"
                                    sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                    color="orange"
                                    onClick={() => handleServiceOpenModal()}
                                    startIcon={<AddIcon />}
                                    size="large"
                                    // sx={{ pt: 1.5, pb: 1.5 }}
                                >
                                    Add Services
                                </Button>
                            </Stack>
                        </Grid>

                        <Grid item md={9} sm={12} xs={12}>
                            <FormLabel
                                id="demo-radio-buttons-group-label"
                                className="radio-label"
                                // error={Boolean(
                                //     (touched.permissionContact && errors.permissionContact) ||
                                //         (updateExpError && updateExpError.opt_for_diff_location)
                                // )}
                            >
                                Subscription amount Per:
                            </FormLabel>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    // defaultValue="Weekly"
                                    name="radio-buttons-group"
                                    row
                                    className="radio-group"
                                    value={values.serviceFrequency}
                                    onChange={(e) => serviceFreqHandler(e)}
                                >
                                    <FormControlLabel value="month" control={<Radio />} label="Month" />
                                    <FormControlLabel value="year" control={<Radio />} label="Year" />

                                    <FormHelperText>
                                        {/* {(touched.permissionContact && errors.permissionContact) ||
                                        (updateExpError && updateExpError.is_perm_prev_super)} */}
                                    </FormHelperText>
                                </RadioGroup>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                className="text-input"
                                fullWidth
                                label="Amount"
                                margin="normal"
                                type="text"
                                {...getFieldProps('amount')}
                                onInput={NumberOnlyHandler}
                                error={Boolean((touched.amount && errors.amount) || (errorUpdate && errorUpdate.amount))}
                                helperText={(touched.amount && errors.amount) || (errorUpdate && errorUpdate.amount)}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }} align="center">
                        <AnimateButton>
                            <LoadingFormButton load={isSubmitting ? true : false} type="submit" btnText="Next" />
                        </AnimateButton>
                    </Box>
                </Form>
            </FormikProvider>

            <MechanicModal open={open} handleClose={handleCloseModal} fetchMechanic={fetchMechanic} />
            <ServiceModal open={open1} handleClose={handleServiceCloseModal} fetchService={fetchService} />
        </>
    );
};

export default MechanicSetupForm;
