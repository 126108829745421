import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, Divider, List, ListItem, ListItemText, Popover, Stack, Switch, TextField, useTheme } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';

function FuelFilter({ checked, handleChecked, filterHandler, reset, params }) {
    const [startDate, setStartDate] = useState(params.get('start_transaction_date') || '');
    const [endDate, setEndDate] = useState(params.get('end_transaction_date') || '');
    const [location, setLocation] = useState(params.get('location_name') || '');
    const [city, setCity] = useState(params.get('city') || '');
    const [state, setState] = useState(params.get('state') || '');

    const handlechange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        params.set(name, value);
    };

    const handleEndDateChange = (newValue) => {
        try {
            const date = new Date(newValue);
            const formattedDate = format(date, 'yyyy-MM-dd');
            params.set('end_transaction_date', formattedDate);
        } catch (error) {
            params.set('end_transaction_date', '');
        }
        setEndDate(newValue);
    };

    const handleStartDateChange = (newValue) => {
        try {
            const date = new Date(newValue);
            const formattedDate = format(date, 'yyyy-MM-dd');
            params.set('start_transaction_date', formattedDate);
        } catch (error) {
            params.set('start_transaction_date', '');
        }
        setStartDate(newValue);
    };

    const theme = useTheme();
    return (
        <PopupState variant="popover" popupId="filter-popup">
            {(popupState) => (
                <>
                    <Button
                        variant="contained"
                        sx={{
                            mr: 2,
                            color: 'white',
                            backgroundColor: '#EB812E',
                            minWidth: { xs: '17rem', sm: '10rem' },
                            maxHeight: { xs: '6vh', sm: '4vh' }
                        }}
                        color="orange"
                        startIcon={<FilterAltIcon />}
                        {...bindTrigger(popupState)}
                    >
                        FILTER
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                            <Box sx={{ px: 2 }}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        maxWidth: 350,
                                        minWidth: 300,
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            minWidth: '100%'
                                        },
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5
                                        }
                                    }}
                                >
                                    <ListItem>
                                        <ListItemText primary="Date" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('date')}
                                            checked={checked.indexOf('date') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('date') !== -1 ? (
                                        <>
                                            <ListItem>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            className="text-input"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            label="Start Date"
                                                            value={startDate}
                                                            onChange={handleStartDateChange}
                                                            renderInput={(params) => (
                                                                <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            className="text-input"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            label="End Date"
                                                            value={endDate}
                                                            onChange={handleEndDateChange}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    className="text-input"
                                                                    name="end_transaction_date"
                                                                    color="orange"
                                                                    fullWidth
                                                                    {...params}
                                                                    error={null}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Stack>

                                                <Divider sx={{ mt: 2 }} />
                                            </ListItem>
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    <ListItem>
                                        <ListItemText primary="Location" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('location')}
                                            checked={checked.indexOf('location') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>

                                    {checked.indexOf('location') !== -1 ? (
                                        <ListItem>
                                            <TextField
                                                fullWidth
                                                name="location_name"
                                                className="text-input"
                                                color="orange"
                                                type="text"
                                                label="Enter Location"
                                                value={location}
                                                onChange={(e) => {
                                                    handlechange(e);
                                                    setLocation(e.target.value);
                                                }}
                                            />

                                            <Divider sx={{ mt: 2 }} />
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}

                                    <ListItem>
                                        <ListItemText primary="City" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('city')}
                                            checked={checked.indexOf('city') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>

                                    {checked.indexOf('city') !== -1 ? (
                                        <ListItem>
                                            <TextField
                                                fullWidth
                                                name="city"
                                                className="text-input"
                                                color="orange"
                                                type="text"
                                                label="City"
                                                value={city}
                                                onChange={(e) => {
                                                    handlechange(e);
                                                    setCity(e.target.value);
                                                }}
                                            />

                                            <Divider sx={{ mt: 2 }} />
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    <ListItem>
                                        <ListItemText primary="State" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('state')}
                                            checked={checked.indexOf('state') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>

                                    {checked.indexOf('state') !== -1 ? (
                                        <ListItem>
                                            <TextField
                                                fullWidth
                                                className="text-input"
                                                color="orange"
                                                name="state"
                                                type="text"
                                                label="State"
                                                value={state}
                                                onChange={(e) => {
                                                    handlechange(e);
                                                    setState(e.target.value);
                                                }}
                                            />

                                            <Divider sx={{ mt: 2 }} />
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}

                                    <ListItem>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={{ xs: 12, sm: 20 }}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    setStartDate('');
                                                    setEndDate('');
                                                    setLocation('');
                                                    setCity('');
                                                    setState('');
                                                    reset();
                                                }}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                                variant="contained"
                                                color="orange"
                                                onClick={() => {
                                                    filterHandler();
                                                    popupState.close();
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </Stack>

                                        <Divider sx={{ mt: 2 }} />
                                    </ListItem>
                                </List>
                            </Box>
                        </MainCard>
                    </Popover>
                </>
            )}
        </PopupState>
    );
}

export default FuelFilter;
