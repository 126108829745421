import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { Button, Grid, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';

import { Button, IconButton, Menu, MenuItem, Stack, TextField } from '@mui/material';

import axios from 'axios';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

import { createRef } from 'react';
import { useState } from 'react';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Status from 'ui-component/buttons/Status';
import MechanicModal from 'ui-component/modals/MechanicModal';
import AddIcon from '@mui/icons-material/Add';
const AllMechanicForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // eslint-disable-next-line prefer-const

    const tableRef = createRef();

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(['doh']);
    const [statusChecked, setStatusChecked] = useState([]);
    const [yearFilter, setYearFilter] = useState('');
    const [searchSts, setSearchSts] = useState(true);
    const [searchFltr, setSearchFltr] = useState('');
    const [mechanicId, setMechanicId] = useState('');

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.company_id;

    const deleteMechanic = async (id) => {
        try {
            await axios.delete(`/v1/api/mechanic/view-mechanic/${id}/`, {
                data: {},
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage.token}`
                }
            });

            tableRef.current.onQueryChange();
            dispatch(openSuccessSnackbar('Mechanic  Deleted successfully'));
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        if (newChecked1.includes('active') && newChecked1.includes('inactive')) {
            setSearchSts();
        } else if (newChecked1.includes('active')) {
            setSearchSts(true);
        } else if (newChecked1.includes('inactive')) {
            setSearchSts(false);
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleOpenModal = () => {
        setOpen(true);
    };

    const editMechanic = (data) => {
        setOpen(true);
        setMechanicId(data);
    };

    const viewUser = (id) => {
        navigate(`/setup/user/view/${id}`);
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const reset = () => {
        tableRef.current.onQueryChange();
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}

                    <th className="headerTd rightHidBor">Name</th>
                    <th className="headerTd rightHidBor">Email</th>
                    <th className="headerTd rightHidBor">Phone</th>
                    <th className="headerTd rightHidBor">Status</th>
                    <th className="headerTd">Actions</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.mcn_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user.email}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user.phone}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="Inctive" />}
                </td>
                {/* Custom cell or additional cells */}
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <PopupState variant="popover" popupId={`demoMenu-${rowData.data.mechanic_id}`}>
                        {(popupState) => (
                            <div>
                                <IconButton {...bindTrigger(popupState)} size="large">
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    {...bindMenu(popupState)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    <MenuItem onClick={() => editMechanic(rowData.data.mechanic_id)}>Edit </MenuItem>
                                    <MenuItem onClick={() => viewUser(rowData.data.mechanic_id)}>View </MenuItem>
                                    <MenuItem onClick={() => deleteMechanic(rowData.data.mechanic_id)}>Remove </MenuItem>
                                </Menu>
                            </div>
                        )}
                    </PopupState>
                </td>
            </tr>
        );
    };

    return (
        // <PageLayout url="/setup/add-user">
        <>
            <Stack sx={{ mt: 5, mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                <TextField
                    type="text"
                    label="Search"
                    size="small"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    sx={{ width: '300px' }}
                />
                <div>
                    {/* <UserFilter
                        checked={checked}
                        handleClose={handleClose}
                        handleChecked={handleChecked}
                        yearFilter={yearFilter}
                        yearHandler={(e) => {
                            setYearFilter(e);
                        }}
                        statusChecked={statusChecked}
                        handleStatus={handleStatus}
                        filterHandler={() => {
                            setOpen((prevOpen) => !prevOpen);
                            tableRef.current.onQueryChange();
                        }}
                        reset={reset}
                    /> */}
                    <Button
                        variant="contained"
                        sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                        color="orange"
                        onClick={() => handleOpenModal()}
                        startIcon={<AddIcon />}
                        size="large"
                        // sx={{ pt: 1.5, pb: 1.5 }}
                    >
                        Add Mechanic
                    </Button>{' '}
                </div>
            </Stack>

            <MaterialTable
                tableRef={tableRef}
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                data={(query) =>
                    new Promise((resolve, reject) => {
                        const url = `/v1/api/mechanic/list-mechanic?page=${query.page + 1}&page_size=${
                            query.pageSize
                        }&search=${searchFltr}&mcn_company_id=${cid}`;

                        fetch(url)
                            .then((response) => response.json())
                            .then((result) => {
                              
                                resolve({
                                    data: result.results,
                                    page: query.page,
                                    totalCount: result.count
                                });
                            });
                    })
                }
                title="All Truck"
                columns={[]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    sorting: true,
                    exportButton: false,

                    toolbar: false,
                    draggable: false,
                    columnResizable: true
                }}
            />
            {/* </PageLayout> */}
            <MechanicModal open={open} handleClose={handleCloseModal} fetchMechanic="" reset={reset} mechanicId={mechanicId} />
        </>
    );
};

export default AllMechanicForm;
