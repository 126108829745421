
// material-ui
import { useTheme } from '@mui/material/styles';
import {  Grid, useMediaQuery } from '@mui/material';

// project imports

import AuthCardWrapper from '../AuthCardWrapper';
import SubCard from 'ui-component/cards/SubCard';
import MechanicSetupForm from 'ui-component/auth-component/forms/MechanicSetupForm';
import { Stack } from '@mui/system';

// assets

// ===============================|| AUTH3 - REGISTER ||=============================== //

const MechanicSetup = () => {
    
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Grid container direction="column" justifyContent="flex-end" alignItems="center" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}                                 
                                    maxWidth={'60%'}
                                    >
                                    <SubCard sx={{  mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                                        <MechanicSetupForm />
                                    </SubCard>
                                    </Stack>    
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MechanicSetup;
