import {
    Box,
    Button,
    Card,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { LoadingButton } from '@mui/lab';

function PdfModal({
    open,
    handleClose,
    modalText,
    fileName,
    children,
    content,
    approveHandler,
    handleDownload,
    type,
    disapproveHandler,
    setHaveAdditional,
    haveAdditional,
    load
}) {
    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
            <DialogTitle sx={{ direction: 'row', alignItems: 'center' }}>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            {type === 'invoiceTracking' ? (
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    {' '}
                    <Typography
                        sx={{
                            textAlign: 'center',
                            width: '375px',
                            height: '40px',
                            fontWeight: '600',
                            fontSize: '32px',
                            fontFamily: 'Lexend'
                        }}
                    >
                        Invoice Preview
                    </Typography>
                </Stack>
            ) : (
                ''
            )}
            <DialogContent>
                <Box sx={{ mt: 3 }}>
                    <DialogContent dividers>
                        {fileName.split('.').pop() === 'jpeg' || fileName.split('.').pop() === 'jpg' || fileName.split('.').pop() === 'png' ? (
                            <Card>
                                <CardMedia component="img" style={{ objectFit: 'cover' }} image={fileName} alt={`Image `} />
                            </Card>
                        ) : (
                            content
                        )}
                    </DialogContent>
                </Box>
            </DialogContent>
            {type === 'loadDoc' ? (
                <>
                    <DialogActions sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <FormLabel sx={{ mr: 2, color: 'red' }} id="demo-radio-buttons-group-label" className="radio-label">
                            Do you have additional fees or files to attach to the this load’s invoice ?
                        </FormLabel>

                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            // defaultValue="Weekly"
                            name="radio-buttons-group"
                            row
                            className="radio-group"
                            value={haveAdditional}
                            onChange={(e) => setHaveAdditional(e.target.value === 'true')}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>

                        <Box>
                            <LoadingButton
                                variant="contained"
                                sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                color="orange"
                                loading={load}
                                onClick={() => approveHandler()}
                            >
                                Approve
                            </LoadingButton>
                            <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={disapproveHandler}>
                                Disapproved
                            </Button>
                        </Box>
                    </DialogActions>
                </>
            ) : type === 'invoiceTracking' ? (
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Button variant="contained" sx={{ mb: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleDownload}>
                        Download
                    </Button>
                </Stack>
            ) : (
                ''
            )}
        </Dialog>
    );
}

export default PdfModal;
