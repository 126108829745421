import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from '@mui/material';
import MaterialTable from '@material-table/core';
import { createRef, useState } from 'react';
import { forwardRef } from 'react';
import TABLE_ICONS from 'tableIcons';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import Status from 'ui-component/buttons/Status';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Form, FormikProvider, useFormik } from 'formik';
import { format } from 'date-fns';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function ImportDriverData({ fullWidth, fullScreen, maxWidth, open, handleCloseModal, data }) {
    const tableRef = createRef();
    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const [ldData, setLdData] = useState(data);

    const [showForm, setShowForm] = useState(false);
    const [ldRow, setLdRow] = useState(null);
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor ">Driver Name</th>
                    <th className="headerTd rightHidBor">Phone#</th>
                    <th className="headerTd rightHidBor">Email</th>
                    <th className="headerTd rightHidBor">CDL</th>
                    <th className="headerTd rightHidBor">Status</th>
                    <th className="headerTd rightHidBor">Import Status</th>
                    <th className="headerTd">Remark</th>
                    <th className="headerTd">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.phone}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.email}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.cdl_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="Inactive" />}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.upload_status === 'success' ? <Status text="Success" /> : <Status text="Failed" />}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.remark}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => handleEdit(rowData.data)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(rowData.data)}>
                        <DeleteIcon />
                    </IconButton>
                </td>
                {/* Custom cell or additional cells */}
            </tr>
        );
    };

    const handleDelete = (rowData) => {
        setLdData(ldData.filter((item) => item.email !== rowData.email));
    };

    const formik = useFormik({
        initialValues: ldRow ? ldRow : {},
        onSubmit: (values) => {
            try {
                console.log('values');
                setLdData(ldData.map((item) => (item.email === values.load_id ? values : item)));
                setShowForm(false);
                setLdRow(null);
            } catch (error) {
                console.log(error);
            }
        }
    });

    const handleEdit = (rowData) => {
        try {
            console.log(rowData);
            setShowForm(true);
            formik.setValues(rowData);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogTitle>Import Status</DialogTitle>
            <DialogContent dividers>
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                    data={ldData || []}
                    title="All Truck"
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,
                        filtering: true,
                        toolbar: false,
                        draggable: false,
                        columnResizable: true,
                        paging: false
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleCloseModal}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ImportDriverData.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'md'
};

export default ImportDriverData;
