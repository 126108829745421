import { SvgIcon } from '@mui/material';
import React from 'react';

function AuditLogIcon() {
    return (
        <SvgIcon>
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="512.000000pt"
                height="512.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#142953" stroke="none">
                    <path
                        d="M721 5103 c-24 -9 -63 -35 -86 -58 -79 -79 -75 -14 -75 -1132 l0
-993 2000 0 2000 0 0 365 0 364 -667 3 -668 3 -38 24 c-21 13 -50 42 -65 64
l-27 41 -3 668 -3 668 -1162 -1 c-1026 0 -1167 -2 -1206 -16z"
                    />
                    <path
                        d="M3290 4487 l0 -637 630 0 c347 0 630 3 630 8 0 4 -283 291 -630 637
l-630 630 0 -638z"
                    />
                    <path
                        d="M161 2703 c-24 -9 -63 -35 -86 -58 -78 -78 -75 -39 -75 -925 0 -886
-3 -847 75 -925 81 -82 -148 -75 2485 -75 2633 0 2404 -7 2485 75 78 78 75 39
75 925 0 886 3 847 -75 925 -81 82 149 75 -2489 74 -2103 0 -2356 -2 -2395
-16z m2417 -307 c151 -41 225 -134 252 -322 17 -119 8 -742 -12 -809 -59 -197
-182 -273 -423 -263 -225 10 -327 91 -370 293 -21 99 -21 710 -1 818 31 158
115 254 253 286 61 14 242 12 301 -3z m1047 -20 c33 -15 73 -39 88 -54 69 -66
107 -177 107 -320 l0 -83 -147 3 -148 3 -5 113 c-5 109 -6 115 -33 138 -50 43
-123 28 -151 -30 -15 -32 -17 -77 -14 -448 3 -383 4 -415 22 -440 26 -40 81
-54 128 -35 57 24 71 61 76 205 l4 122 -61 0 -61 0 0 90 0 90 195 0 195 0 0
-355 0 -355 -99 0 -99 0 -7 50 -7 49 -47 -48 c-55 -56 -94 -71 -192 -71 -166
0 -279 79 -331 230 -21 62 -22 84 -26 445 -3 331 -1 389 13 450 29 119 73 185
153 232 74 43 124 53 262 50 112 -3 131 -6 185 -31z m-2025 -571 l0 -585 160
0 160 0 0 -100 0 -100 -310 0 -310 0 0 685 0 685 150 0 150 0 0 -585z"
                    />
                    <path
                        d="M2360 2172 c-19 -19 -30 -44 -35 -73 -3 -24 -5 -217 -3 -429 3 -352
5 -388 21 -413 39 -60 126 -58 162 3 19 33 20 54 23 395 4 433 1 478 -38 517
-39 39 -91 39 -130 0z"
                    />
                    <path
                        d="M560 367 c0 -183 12 -230 75 -292 81 -81 -81 -75 1925 -75 2006 0
1844 -6 1925 75 63 62 75 109 75 292 l0 153 -2000 0 -2000 0 0 -153z"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
}

export default AuditLogIcon;
