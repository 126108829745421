import React from 'react';


import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';
import CustomerForm from 'ui-component/dispatchCenter/forms/CustomerForm';
import { useEffect } from 'react';
import 'ui-component/font/fonts';
import PageLayoutWithBack from 'layout/PageLayoutWithBack';
function EditCustomer() {
    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);
    return (
        <PageLayoutWithBack>
          
                <CustomerForm companySetup={companySetup} />
         
        </PageLayoutWithBack>
    );
}

export default EditCustomer;
