const { createSlice } = require("@reduxjs/toolkit");
import { initialState } from '../customizationReducer1';

const activeStepSlice = createSlice({
    name: 'activeStep',
    initialState: 0,
    reducers: {
        incrementActiveStep(state){
            return state + 1;
        },
        decrementActiveStep(state){
            return state - 1;
        }
    }
})

export const { incrementActiveStep, decrementActiveStep } = activeStepSlice.actions;

export default activeStepSlice.reducer