import MaterialTable from '@material-table/core';

// import AddBoxIcon from '@mui/icons-material/AddBox';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

import TABLE_ICONS from 'tableIcons';
import { format } from 'date-fns';

import { forwardRef } from 'react';
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// import { Button, Grid, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';

import {
    Button,
    IconButton,
    InputAdornment,
    LinearProgress,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
    Divider,
    DialogActions,
    Tooltip,
    Card,
    CardContent,
    Typography,
    Menu,
    MenuItem,
    TablePagination,
    CircularProgress
} from '@mui/material';
import { Dialog, DialogContent, Slide } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { createRef } from 'react';
import { useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';

import ViewIftaModal from 'ui-component/modals/ViewIftaModal';
import SearchIcon from '@mui/icons-material/Search';

import LoadStatus from 'ui-component/buttons/LoadStatus';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import AllMyInvoiceFilter from 'ui-component/filters/AllMyInvoiceFilter';
import InvPdfModal from 'ui-component/modals/InvPdfModal';

import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useMutation } from '@tanstack/react-query';
import './allmyinvoiceform.css';
import { Box, margin } from '@mui/system';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const initialValues = {
    to_email: '',
    cc_email: '',
    subject: '',
    additional_notes: ''
};
const emailSchema = Yup.object().shape({
    to_email: Yup.string().email('Invalid email format').required('To email is required'),
    cc_email: Yup.string().optional(),
    subject: Yup.string().required('Subject is required'),
    additional_notes: Yup.string()
});

const AllMyInvoiceForm = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const tableRef = createRef();

    const [open1, setOpen1] = useState(false);
    const [open, setOpen] = useState(false);

    const [checked, setChecked] = useState(['doc']);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [iftaId, setIftaId] = useState(0);
    const [driver, setDriver] = useState('');

    const [searchFltr, setSearchFltr] = useState('');
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = [
        'dispatcher_admin',
        'accountant',
        'dispatcher',
        'auditor',
        'self_dispatch',
        'carrier',
        'mechanic_admin',
        'hr',
        'super_dispatcher_admin'
    ].includes(loginInfoFromStorage.user_type)
        ? loginInfoFromStorage.company_id
        : 0;

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [loading, setLoading] = useState(false);
    const [loadId, setLoadId] = useState();
    const [invoiceId, setInvoiceId] = useState();
    const [fileName, setFileName] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const [startPrice, setStartPrice] = useState('');
    const [endPrice, setEndPrice] = useState('');
    const [statusChecked, setStatusChecked] = useState([]);
    const [searchSts, setSearchSts] = useState('');

    // for generating all pdf
    const [isGenerating, setIsGenerating] = useState({
        email: false,
        download: false
    });
    const [data, setData] = useState([]);

    // for table checked and unchecked
    const [selectAll, setSelectAll] = useState(false);
    const [checkboxes, setCheckboxes] = useState([]);

    const [selected, setSelected] = useState([]);

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    // Function to handle select/unselect all
    const handleSelectAll = () => {
        if (selectAll) {
            setCheckboxes([]);
            setSelectAll(false);
        } else {
            const newCheckboxes = [];

            data.forEach((item, index) => {
                if (item.inv_status !== 'submitted') {
                    newCheckboxes.push(item.dsp_load_inv_id);
                }
            });

            // Update the checkboxes state
            setCheckboxes(newCheckboxes);
            setSelectAll(true);
        }
    };
    // Function to handle checkbox change
    const handleCheckboxChange = (sdata, index) => {
        // if (data[index].status !== 'disabled') {
        //     const newCheckboxes = [...checkboxes];
        //     newCheckboxes[index] = !newCheckboxes[index];
        //     setCheckboxes(newCheckboxes);
        // }

        if (checkboxes.indexOf(sdata.data.dsp_load_inv_id) !== -1) {
            setCheckboxes(checkboxes.filter((item) => item !== sdata.data.dsp_load_inv_id));
        } else {
            setCheckboxes([...checkboxes, sdata.data.dsp_load_inv_id]);
        }
    };

    // Function to get the values of checked items
    const getCheckedItems = () => {
        const checkedItems = [];
        checkboxes.forEach((item, index) => {
            // if (isChecked) {
            checkedItems.push(data.filter((data) => data.dsp_load_inv_id === item)[0]);
            // }
        });
        console.log(checkedItems);
        return checkedItems;
    };

    // email input
    const [openEmail, setOpenEmail] = useState(false);
    const requestData = {};

    const formik = useFormik({
        initialValues,
        validationSchema: emailSchema,
        onSubmit: (values, action) => {
            // Set isGenerating.email to true when submitting the form
            setIsGenerating((prevState) => ({
                ...prevState,
                email: true
            }));
            requestData.email = values;
            action.resetForm();
            setOpenEmail(false);
            getMergedInvoice();
        }
    });

    const getMergedInvoice = async () => {
        // get all the checked item
        requestData.data = getCheckedItems();
        if (requestData.data.length === 0) {
            toast.error('0 item selected.');
        } else {
            try {
                setIsGenerating((prevState) => ({
                    ...prevState,
                    download: !prevState.email
                }));

                const response = await axios.post(`/v1/api/dispatch/get-merged-invoice/`, requestData, { ...config, responseType: 'blob' });

                if (response.status === 200) {
                    // Handle the Blob response
                    const blob = new Blob([response.data], { type: 'application/zip' });

                    // Create a URL for the Blob object
                    const url = window.URL.createObjectURL(blob);

                    // Create an anchor element
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'Invoices.zip'; // Set the filename

                    // Append the anchor element to the document body
                    document.body.appendChild(a);

                    // Click the anchor element to initiate download
                    a.click();

                    // Remove the anchor element from the document body
                    document.body.removeChild(a);
                    toast.success('Downloaded successfully');
                    // approveHandler();

                    // Revoke the Blob URL after a delay to free up memory
                    setTimeout(() => {
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                } else if (response.status === 202) {
                    // if it was email then send this snackbar
                    toast.success('Email sent successfully');
                    approveHandler();
                }
                window.location.reload();
            } catch (error) {
                const status = error.response.status;
                if (status === 403) {
                    toast.error("You don't have invoices for the selected amount");
                } else if (status === 404) {
                    toast.error('No pdf file are present in the given list');
                } else {
                    toast.error('Please contact with support.');
                }
                console.log(error);
            }
        }
        setIsGenerating((prevState) => ({
            ...prevState,
            download: false,
            email: false
        }));
    };

    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        formik.setFieldValue('to_email', companySetup?.email);
        formik.setFieldValue('cc_email', companySetup?.fact_info?.fact_email_address ? companySetup?.fact_info?.fact_email_address : '');
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    const handleCloseModal = () => {
        setOpen1(false);
    };

    const handleClickOpenDoc = (file, Lid, inv_id) => {
        if (!file) {
            toast.error('File not found');
            return;
        }
        setLoadId(Lid);
        setInvoiceId(inv_id);
        setFileName(file);

        setModalOpen(true);
    };

    const handleCloseDoc = () => {
        setModalOpen(false);
    };

    async function handleDownloadModalDoc(file) {
        if (!file) {
            toast.error('File not found');
            return;
        }
        const body = {
            action: 'GET',
            url: file,
            changed_object: 'Accounting Invoice',
            event_category: 'Get',
            change_summary: `Downloaded Invoice`
        };

        try {
            await axios.post(`../../v1/api/users/activity-logs/`, body, config);
        } catch (error) {
            console.log(error);
        }

        window.open(file, '_blank');
    }
    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        setSearchSts('&inv_status=' + newChecked1.join(`&inv_status=`));
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const reset = () => {
        setStartDate('');
        setEndDate('');
        setStartPrice('');
        setEndPrice('');
        setChecked([]);

        if (tableRef.current) {
            console.log('tableRef.current:', tableRef.current);
            tableRef.current.onQueryChange();
        } else {
            console.error('tableRef.current is undefined');
        }
    };

    const approveHandler = async () => {
        try {
            const { data } = await axios.post(
                `/v1/api/dispatch/factor/add/`,
                { load_id: loadId, invoice_id: invoiceId, company_id: cid, iq_idn: companySetup.client_no },
                config
            );

            setLoading(false);
            toast.success('Invoice Submitted successfully');
            setOpen1(false);
            handleCloseDoc();
            tableRef.current.onQueryChange();
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.invoice_id);
            // dispatch(openErrorSnackbar(error.response.data.invoice_id));
        }
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const [updateId, setUpdateId] = useState(null);

    const changeStatus = async (id) => {
        setUpdateId(id);
        const response = await axios.put(`/v1/api/dispatch/load-invoice-status-change/${id}/`, null, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });

        return response;
    };

    const { mutate, status } = useMutation({
        mutationFn: changeStatus,
        onError: (error) => {
            console.error('Error:', error);
            toast.error('Failed to update status. Please try again.');
            setUpdateId(null);
        },
        onSuccess: (data) => {
            toast.success('Status updated successfully');
            tableRef.current.onQueryChange();
            setUpdateId(null);
        }
    });

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">
                        <Checkbox checked={selectAll} onChange={handleSelectAll} />
                    </th>
                    <th className="headerTd rightHidBor">Invoice#</th>
                    <th className="headerTd rightHidBor">Load#</th>

                    <th className="headerTd rightHidBor"> Invoice Date</th>
                    <th className="headerTd rightHidBor">Customer</th>
                    <th className="headerTd rightHidBor">Amount</th>
                    <th className="headerTd rightHidBor">Status</th>

                    <th className="headerTd ">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr key={rowData.index}>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Checkbox
                        // checked={checkboxes[rowData.index] || false}
                        checked={checkboxes.findIndex((item) => item === rowData.data.dsp_load_inv_id) !== -1 || false}
                        onChange={() => handleCheckboxChange(rowData, rowData.index)}
                        disabled={rowData.data.inv_status === 'submitted' || rowData.data.inv_status === 'deposited'}
                    />
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.company_inv_id}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.load_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {format(new Date(rowData.data.created_at), 'dd/MM/yyyy')}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.cust_name}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    ${rowData.data.tot_amount || '0.00'}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <LoadStatus
                        text={rowData.data.inv_status}
                        type={
                            rowData.data.inv_status === 'deposited'
                                ? 'success'
                                : rowData.data.inv_status === 'submitted'
                                ? 'orange'
                                : rowData.data.inv_status === 'partial'
                                ? 'blue'
                                : rowData.data.inv_status === 'pending'
                                ? 'error'
                                : ''
                        }
                    />
                </td>

                {/* Custom cell or additional cells */}
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => handleClickOpenDoc(rowData.data.inv_doc, rowData.data.load_id, rowData.data.dsp_load_inv_id)}>
                        <VisibilityIcon fontSize="small" style={{ margin: '-3px' }} />
                    </IconButton>
                    <IconButton
                        onClick={() => handleDownloadModalDoc(rowData.data.inv_doc, rowData.data.dsp_load_inv_id)}
                        endIcon={<FileDownloadIcon fontSize="small" />}
                    >
                        <FileDownloadIcon fontSize="small" style={{ margin: '-3px' }} />
                    </IconButton>
                    <Tooltip title="Change Status to Pending" placement="top">
                        <IconButton
                            onClick={() => {
                                if (rowData.data.inv_status === 'pending') {
                                    toast.error('Already in pending state');
                                } else {
                                    mutate(rowData.data.dsp_load_inv_id);
                                }
                            }}
                            disabled={status === 'pending'}
                        >
                            <AutorenewIcon className={updateId == rowData.data.dsp_load_inv_id && status === 'pending' && 'spinner-loader'} />
                        </IconButton>
                    </Tooltip>
                </td>
            </tr>
        );
    };

    // const fetchData = async (query) => {
    //     try {
    //         const response = await fetch(
    //             `/v1/api/dispatch/load-invoice/list?page=${query.page + 1}&page_size=${
    //                 query.pageSize
    //             }&company_id=${cid}&search=${searchFltr}&start_amount=${startPrice}&end_amount=${endPrice}&start_inv_date=${
    //                 startDate !== '' ? format(new Date(startDate), 'yyyy-MM-dd') : ''
    //             }&end_inv_date=${endDate !== '' ? format(new Date(endDate), 'yyyy-MM-dd') : ''}${searchSts}`,
    //             {
    //                 method: 'GET',
    //                 headers: new Headers({
    //                     'Content-type': 'application/json',
    //                     Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
    //                 })
    //             }
    //         );

    //         const result = await response.json();
    //         setData(result.results);

    //         return {
    //             data: result.results,
    //             page: query.page,
    //             totalCount: result.count
    //         };
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         throw error;
    //     }
    // };

    const fetchData = async (query) => {
        setLoading(true);

        try {
            const response = await fetch(
                `/v1/api/dispatch/load-invoice/list?page=${query.page + 1}&page_size=${
                    query.pageSize
                }&company_id=${cid}&search=${searchFltr}&start_amount=${startPrice}&end_amount=${endPrice}&start_inv_date=${
                    startDate !== '' ? format(new Date(startDate), 'yyyy-MM-dd') : ''
                }&end_inv_date=${endDate !== '' ? format(new Date(endDate), 'yyyy-MM-dd') : ''}${searchSts}`,
                {
                    method: 'GET',
                    headers: new Headers({
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                    })
                }
            );

            const result = await response.json();
            console.log(result);
            setData(result.results);

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 5 })
                .then((result) => {
                    setSelected(result.data || []);
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
    }, [isMobile, isTablet, currPage, cardPerPage]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    return (
        <>
            <Stack
                sx={{ mt: 5, mb: 2 }}
                direction={matchDownMd ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={matchDownMd ? 'flex-start' : 'center'}
                spacing={4}
            >
                <TextField
                    type="text"
                    size="small"
                    placeholder="Search by Invoice No, Load, Customer Name"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    // sx={{ width: { xs: '310px', sm: '350px' } }}
                    sx={{ width: { xs: '82vw', sm: '45vw', lg: '20vw' } }}
                    className="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Box sx={{ display: 'flex', height: '40px', gap: '1rem', flexDirection: { xs: 'column', sm: 'row' }, minHeight: { xs: '9rem', sm: 0 } }}>
                    <Button
                        variant="contained"
                        sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E', minWidth: { xs: '80vw', sm: '10rem' } }}
                        color="orange"
                        onClick={() => {
                            setOpenEmail(true);
                        }}
                        disabled={isGenerating.email}
                    >
                        {isGenerating.email ? 'Sending.....' : 'Email Invoices'}
                    </Button>
                    <AllMyInvoiceFilter
                        checked={checked}
                        handleClose={handleClose}
                        handleChecked={handleChecked}
                        startDate={startDate}
                        startDateHandler={(e) => setStartDate(e)}
                        endDate={endDate}
                        endDateHandler={(e) => setEndDate(e)}
                        startPrice={startPrice}
                        startPriceHandler={(e) => setStartPrice(e.target.value)}
                        endPrice={endPrice}
                        endPriceHandler={(e) => setEndPrice(e.target.value)}
                        statusChecked={statusChecked}
                        handleStatus={handleStatus}
                        reset={reset}
                        filterHandler={() => {
                            if (tableRef.current) {
                                tableRef.current.onQueryChange();
                            } else {
                                console.error('tableRef.current is undefined on mobile');
                                const query = {
                                    page: 0,
                                    pageSize: 5
                                };

                                fetchData(query);
                            }
                        }}
                    />
                    <div>
                        <Button
                            variant="contained"
                            sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E', minWidth: { xs: '80vw', sm: '10rem' } }}
                            color="orange"
                            disabled={isGenerating.download}
                            onClick={getMergedInvoice}
                        >
                            {isGenerating.download ? 'Generating.....' : 'Download Invoices'}
                        </Button>
                        {isGenerating.download && <LinearProgress style={{ marginTop: '10px' }} />}
                    </div>
                </Box>
            </Stack>
            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>
                                            <Checkbox
                                                sx={{ p: 0 }}
                                                // checked={checkboxes[rowData.index] || false}
                                                checked={checkboxes.findIndex((item) => item === driver.dsp_load_inv_id) !== -1 || false}
                                                // onChange={() => handleCheckboxChange(rowData, rowData.index)}
                                                onChange={() => handleCheckboxChange(driver, driver.index)}
                                                disabled={driver.inv_status === 'submitted' || driver.inv_status === 'deposited'}
                                            />

                                            <LoadStatus
                                                sx={{ marginLeft: '1rem' }}
                                                text={driver.inv_status}
                                                type={
                                                    driver.inv_status === 'deposited'
                                                        ? 'success'
                                                        : driver.inv_status === 'submitted'
                                                        ? 'orange'
                                                        : driver.inv_status === 'partial'
                                                        ? 'blue'
                                                        : driver.inv_status === 'pending'
                                                        ? 'error'
                                                        : ''
                                                }
                                            />
                                        </Box>

                                        <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div style={{ float: 'right', marginTop: '1px' }}>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        <MenuItem onClick={() => handleClickOpenDoc(driver.inv_doc, driver.load_id, driver.dsp_load_inv_id)}>
                                                            View
                                                        </MenuItem>
                                                        <MenuItem onClick={() => handleDownloadModalDoc(driver.inv_doc, driver.dsp_load_inv_id)}>
                                                            Download
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => {
                                                                if (driver.inv_status === 'pending') {
                                                                    toast.error('Already in pending state');
                                                                } else {
                                                                    mutate(driver.dsp_load_inv_id);
                                                                }
                                                            }}
                                                            disabled={status === 'pending'}
                                                        >
                                                            Change Status
                                                        </MenuItem>
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>

                                    <Box sx={{ clear: 'both' }} />

                                    <Typography sx={{ marginBottom: '.5rem' }}>Invoice# : {driver.dsp_load_inv_id}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Load# # : {driver.load_no}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Invoice Date : {format(new Date(driver.created_at), 'dd/MM/yyyy')}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Customer : {driver.cust_name}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Amount : ${driver.tot_amount || '0.00'}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}
                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected?.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginTop: '2rem',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box
                                            sx={{
                                                float: 'left'
                                            }}
                                        >
                                            <Checkbox
                                                // checked={checkboxes[rowData.index] || false}
                                                sx={{
                                                    padding: 0
                                                }}
                                                checked={checkboxes.findIndex((item) => item === driver.dsp_load_inv_id) !== -1 || false}
                                                // onChange={() => handleCheckboxChange(rowData, rowData.index)}
                                                onChange={() => handleCheckboxChange(driver, driver.index)}
                                                disabled={driver.inv_status === 'submitted' || driver.inv_status === 'deposited'}
                                            />

                                            <LoadStatus
                                                text={driver.inv_status}
                                                sx={{ marginLeft: '1rem' }}
                                                type={
                                                    driver.inv_status === 'deposited'
                                                        ? 'success'
                                                        : driver.inv_status === 'submitted'
                                                        ? 'orange'
                                                        : driver.inv_status === 'partial'
                                                        ? 'blue'
                                                        : driver.inv_status === 'pending'
                                                        ? 'error'
                                                        : ''
                                                }
                                            />
                                        </Box>

                                        <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div style={{ float: 'right', marginTop: '1px' }}>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        <MenuItem onClick={() => handleClickOpenDoc(driver.inv_doc, driver.load_id, driver.dsp_load_inv_id)}>
                                                            View
                                                        </MenuItem>
                                                        <MenuItem onClick={() => handleDownloadModalDoc(driver.inv_doc, driver.dsp_load_inv_id)}>
                                                            Download
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => {
                                                                if (driver.inv_status === 'pending') {
                                                                    toast.error('Already in pending state');
                                                                } else {
                                                                    mutate(driver.dsp_load_inv_id);
                                                                }
                                                            }}
                                                            disabled={status === 'pending'}
                                                        >
                                                            Change Status
                                                        </MenuItem>
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>

                                    {/* </Box> */}
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}> Invoice# : {driver.dsp_load_inv_id} </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Load# # : {driver.load_no}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            Invoice Date : {format(new Date(driver.created_at), 'dd/MM/yyyy')}
                                        </Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Customer : {driver.cust_name}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '.6rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Amount : ${driver.tot_amount || '0.00'}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[20, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                    // data={(query) =>
                    //     new Promise((resolve, _reject) => {
                    //         fetch(
                    //             `/v1/api/dispatch/load-invoice/list?page=${query.page + 1}&page_size=${
                    //                 query.pageSize
                    //             }&company_id=${cid}&search=${searchFltr}&start_amount=${startPrice}&end_amount=${endPrice}&start_inv_date=${
                    //                 startDate !== '' ? format(new Date(startDate), 'yyyy-MM-dd') : ''
                    //             }&end_inv_date=${endDate !== '' ? format(new Date(endDate), 'yyyy-MM-dd') : ''}${searchSts}`,
                    //             {
                    //                 method: 'get',

                    //                 headers: new Headers({
                    //                     'Content-type': 'application/json',
                    //                     Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                    //                 })
                    //             }
                    //         )
                    //             .then((response) => response.json())
                    //             .then((result) => {
                    //                 setData(result.results);
                    //                 resolve({
                    //                     data: result.results,
                    //                     page: query.page,
                    //                     totalCount: result.count
                    //                 });
                    //             });
                    //     })
                    // }
                    data={(query) => fetchData(query)}
                    title=""
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,

                        toolbar: false,
                        draggable: false,
                        columnResizable: true
                    }}
                />
            )}
            <ViewIftaModal open={open1} handleCloseModal={handleCloseModal} id={iftaId} driver={driver} />

            <InvPdfModal
                open={modalOpen}
                fullWidth
                fullScreen
                maxWidth="md"
                handleClose={() => handleCloseDoc()}
                handleDownload={() => handleDownloadModalDoc()}
                approveHandler={() => {
                    approveHandler();
                }}
                fileName={fileName}
                type="loadDoc"
                content={
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer fileUrl={fileName} />
                    </Worker>
                }
            />

            {/* Modal for taking input email start */}
            <Dialog
                open={openEmail}
                onClose={() => {
                    setOpenEmail(false);
                }}
                TransitionComponent={Transition}
                scroll="paper"
            >
                <DialogContent dividers>
                    <Divider sx={{ mb: 2 }} />
                    <FormikProvider value={formik}>
                        <Form
                            autoComplete="on"
                            onSubmit={formik.handleSubmit}
                            encType="multipart/form-data"
                            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                        >
                            <TextField
                                type="email"
                                value={formik.values.to_email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="to_email"
                                label="To Email"
                                size="small"
                                error={formik.touched.to_email && formik.errors.to_email}
                                helperText={formik.touched.to_email && formik.errors.to_email}
                            />
                            <div>
                                <TextField
                                    fullWidth
                                    type="text"
                                    value={formik.values.cc_email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="cc_email"
                                    label="CC Email"
                                    size="small"
                                    error={formik.touched.cc_email && formik.errors.cc_email}
                                    helperText={formik.touched.cc_email && formik.errors.cc_email}
                                />
                                <span>You can send multiple email by separting with semicolon</span>
                            </div>
                            <TextField
                                type="text"
                                value={formik.values.subject}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="subject"
                                label="Subject"
                                size="small"
                                error={formik.touched.subject && formik.errors.subject}
                                helperText={formik.touched.subject && formik.errors.subject}
                            />
                            <TextField
                                multiline
                                fullWidth
                                minRows={4}
                                value={formik.values.additional_notes}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="additional_notes"
                                label="Additional Notes"
                                size="small"
                                error={formik.touched.additional_notes && !!formik.errors.additional_notes}
                                helperText={formik.touched.additional_notes && formik.errors.additional_notes}
                            />

                            <Button type="submit" variant="contained" fullWidth sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}>
                                Send
                            </Button>
                        </Form>
                    </FormikProvider>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setOpenEmail(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {/* Modal for taking input email end */}
        </>
    );
};

export default AllMyInvoiceForm;
