import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Stack, TextField } from '@mui/material';

import axios from 'axios';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

import { createRef } from 'react';
import { useState } from 'react';

import MechanicModal from 'ui-component/modals/MechanicModal';
import LoadStatus from 'ui-component/buttons/LoadStatus';
const AllMechanicDriverForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // eslint-disable-next-line prefer-const

    const tableRef = createRef();

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(['doh']);
    const [statusChecked, setStatusChecked] = useState([]);
    const [yearFilter, setYearFilter] = useState('');
    const [searchSts, setSearchSts] = useState(true);
    const [searchFltr, setSearchFltr] = useState('');
    const [mechanicId, setMechanicId] = useState('');

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.company_id;

    const deleteMechanic = async (id) => {
        try {
            await axios.delete(`/v1/api/mechanic/view-mechanic/${id}/`, {
                data: {},
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage.token}`
                }
            });

            tableRef.current.onQueryChange();
            dispatch(openSuccessSnackbar('Mechanic  Deleted successfully'));
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        if (newChecked1.includes('active') && newChecked1.includes('inactive')) {
            setSearchSts();
        } else if (newChecked1.includes('active')) {
            setSearchSts(true);
        } else if (newChecked1.includes('inactive')) {
            setSearchSts(false);
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleOpenModal = () => {
        setOpen(true);
    };

    const editMechanic = (data) => {
        setOpen(true);
        setMechanicId(data);
    };

    const viewUser = (id) => {
        navigate(`/setup/user/view/${id}`);
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const reset = () => {
        tableRef.current.onQueryChange();
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}

                    <th className="headerTd rightHidBor">ID#</th>
                    <th className="headerTd rightHidBor">Driver Name</th>
                    <th className="headerTd rightHidBor">Company Name</th>
                    <th className="headerTd rightHidBor">Model</th>
                    <th className="headerTd rightHidBor">Plate#</th>
                    <th className="headerTd rightHidBor">Monthly Rate($)</th>
                    <th className="headerTd rightHidBor">Payment Status</th>
                    <th className="headerTd">Date of Subscription</th>
                    {/* <th className="headerTd">Actions</th> */}
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData), paddingTop: 15, paddingBottom: 15 }}>
                    {rowData.data.mcn_srv_drv_id}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_cmp_name.company_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.model}
                </td>{' '}
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.truck_license.license_no}
                </td>{' '}
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.service_info.service_amount}
                </td>{' '}
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <LoadStatus
                        text={rowData.data.payment_status}
                        type={
                            rowData.data.payment_status === 'Generated'
                                ? 'success'
                                : rowData.data.payment_status === 'Submitted'
                                ? 'inprog'
                                : rowData.data.payment_status === 'Pending'
                                ? 'error'
                                : ''
                        }
                    />
                </td>
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.payment_date}
                </td>
            </tr>
        );
    };

    return (
        // <PageLayout url="/setup/add-user">
        <>
            <Stack sx={{ mt: 5, mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                <TextField
                    type="text"
                    label="Search"
                    size="small"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    sx={{ width: '300px' }}
                />
                <div>
                    {/* <UserFilter
                        checked={checked}
                        handleClose={handleClose}
                        handleChecked={handleChecked}
                        yearFilter={yearFilter}
                        yearHandler={(e) => {
                            setYearFilter(e);
                        }}
                        statusChecked={statusChecked}
                        handleStatus={handleStatus}
                        filterHandler={() => {
                            setOpen((prevOpen) => !prevOpen);
                            tableRef.current.onQueryChange();
                        }}
                        reset={reset}
                    /> */}
                </div>
            </Stack>

            <MaterialTable
                tableRef={tableRef}
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                data={(query) =>
                    new Promise((resolve, reject) => {
                        const url = `/v1/api/mechanic/mcn-srv-driver-map/list?page=${query.page + 1}&page_size=${
                            query.pageSize
                        }&search=${searchFltr}&mcn_company_id=${cid}`;

                        fetch(url)
                            .then((response) => response.json())
                            .then((result) => {
                                resolve({
                                    data: result.results,
                                    page: query.page,
                                    totalCount: result.count
                                });
                            });
                    })
                }
                title="All Truck"
                columns={[]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    sorting: true,
                    exportButton: false,

                    toolbar: false,
                    draggable: false,
                    columnResizable: true
                }}
            />
            {/* </PageLayout> */}
            <MechanicModal open={open} handleClose={handleCloseModal} fetchMechanic="" reset={reset} mechanicId={mechanicId} />
        </>
    );
};

export default AllMechanicDriverForm;
