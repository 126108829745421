import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

import MainCard from 'ui-component/cards/MainCard';

import { useNavigate } from 'react-router';
import 'ui-component/font/fonts';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLayout from 'layout/PageLayout';

import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

// import { Button, Grid, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';

import { Button, Stack } from '@mui/material';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { createRef } from 'react';
import { useParams } from 'react-router-dom';

// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '8px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '18px',
                            fontWeight: 900,
                            marginRight: '5px',
                            color: 'white'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});
function StateIfta() {
    const { year, quarter } = useParams()

    const tableRef = createRef();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">State</th>
                    <th className="headerTd rightHidBor">Total Mileage</th>

                    <th className="headerTd rightHidBor">Total Gallons</th>
                    <th className="headerTd rightHidBor">Overall Fuel Mileage</th>
                    <th className="headerTd rightHidBor">Gallons Burned</th>
                    <th className="headerTd rightHidBor">Fuel Surcharged</th>
                    <th className="headerTd rightHidBor">Fuel Tax Required</th>
                    <th className="headerTd rightHidBor">Fuel Tax Paid</th>
                    <th className="headerTd"> Fuel Tax Owed</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr style={rowData.data.is_total ? { backgroundColor: '#011f47', color: '#FFFF' } : null}>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.state_id__iso2}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.tot_millage}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.tot_gallons}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.fuel_millage}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.gallons_burned}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.fuel_surcharge}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.tax_req}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.tax_rate}
                </td>
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.fuel_tax}
                </td>
            </tr>
        );
    };
    const navigate = useNavigate();
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainCard>
            <PageLayout title="State Total IFTA info">
                <Box sx={{ border: 'none', background: 'transparent' }}>
                    <ThemeProvider theme={theme}>
                        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                            <Tab label="Settlement" onClick={() => navigate(`/accounting/settlement`)} />
                            <Tab label="IFTA" onClick={() => navigate(`/accounting/ifta/all`)} />
                            <Tab label="My Invoices" onClick={() => navigate(`/accounting/my-invoice/all`)} />
                            <Tab label="Tax Configuration" onClick={() => navigate(`/accounting/configuration/tax`)} />
                            <Tab label="Fuel Reports" onClick={() => navigate(`/accounting/fuel-reports`)} />
                            <Tab label="External Expenses" onClick={() => navigate(`/accounting/external-expenses`)} />
                        </Tabs>
                    </ThemeProvider>
                </Box>
                <>
                    <Stack sx={{ mt: 5, mb: 2 }} direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={4}>
                        <Button variant="contained" sx={{color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={() => navigate(`/accounting/ifta/all`)}>
                            Go Back
                        </Button>
                    </Stack>

                    <MaterialTable
                        tableRef={tableRef}
                        style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                        data={(query) =>
                            new Promise((resolve, reject) => {
                                fetch(`/v1/api/accounting/get-state-ifta/${year}/${quarter}/?page=${query.page + 1}&page_size=${query.pageSize}`, {
                                    method: 'get',

                                    headers: new Headers({
                                        'Content-type': 'application/json',
                                        Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                                    })
                                })
                                    .then((response) => response.json())
                                    .then((result) => {
                                        console.log(result.results);
                                        resolve({
                                            data: result.results,
                                            page: query.page,
                                            totalCount: result.count
                                        });
                                    });
                            })
                        }
                        title=""
                        columns={[]}
                        icons={TABLE_ICONS}
                        components={{ Row: renderRow, Header: renderHeader }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: <CustomNoRowsOverlay />
                            }
                        }}
                        options={{
                            sorting: true,
                            exportButton: false,
                            showFooter: true,
                            toolbar: false,
                            draggable: false,
                            columnResizable: true
                        }}
                    />
                </>
            </PageLayout>
        </MainCard>
    );
}

export default StateIfta;
