import {  useNavigate,  useSearchParams } from 'react-router-dom';

import {  Button, Card, CardContent,  Grid, LinearProgress,  Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthCardWrapper from '../AuthCardWrapper';

import success from 'assets/images/success.gif';
import fail from 'assets/images/fail.gif';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Param = () => {
    const [stat, setStat] = useState('pending');
  
  
    const [searchParams, setSearchParams] = useSearchParams();

    const code = searchParams.get('code');
    const isLoggedIn = localStorage.getItem('loginUserInfo') || null;
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const navigate = useNavigate();

    const loginPageURL = '/login';

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    useEffect(() => {
        if (code != '' && isLoggedIn) {
            fetchResponse();
        } else if (!isLoggedIn && code != '') {
            navigate(`${loginPageURL}?code=${code}`);
        }
    }, []);

    const fetchResponse = async () => {
        try {
            const { data } = await axios.post(`/v1/api/users/eld/response`, { code: code }, config);
           
            setStat('success');
        } catch (error) {
            setStat('failed');
        
        }
    };

    return (
        <>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                {stat === 'pending' && (
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h3" sx={{ mb: 3 }}>
                                                Installation in progress. Please wait
                                            </Typography>
                                            <LinearProgress />
                                        </CardContent>
                                    </Card>
                                )}
                                {stat === 'success' && (
                                    <Card>
                                        <CardContent>
                                            <Stack direction="column" justifyContent="center" alignItems="center">
                                                <img src={success} alt="success" />
                                                <Typography variant="h3" sx={{ mb: 3 }} color="green">
                                                    Installation completed successfully
                                                </Typography>
                                                <Button variant="contained" color="primary" onClick={() => navigate('/dispatch-center')}>
                                                    Go To Dashboard
                                                </Button>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                )}
                                {stat === 'failed' && (
                                    <Card>
                                        <CardContent>
                                            <Stack direction="column" justifyContent="center" alignItems="center">
                                                <img src={fail} alt="fail" width="480px" height="360px" />
                                                <Typography variant="h3" sx={{ mb: 3 }} color="error">
                                                    Installation couldn't be completed. Something Went Wrong.
                                                </Typography>
                                                <Button variant="contained" color="primary" onClick={() => navigate('/dispatch-center')}>
                                                    Go To Dashboard
                                                </Button>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                )}
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Param;
