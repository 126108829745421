import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import { useDispatch } from 'react-redux';

import { Button, Card, CardContent, CircularProgress, IconButton, InputAdornment, Stack, TablePagination, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import axios from 'axios';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

import { createRef, useEffect } from 'react';
import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import NewExpenseItemModal from 'ui-component/modals/NewExpenseItemModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast } from 'react-toastify';
import user_types from 'constant/user_types';
import { Box, useMediaQuery } from '@mui/system';

const AllExpenseItemForm = () => {
    const dispatch = useDispatch();

    const tableRef = createRef();
    const [open1, setOpen1] = useState(false);

    const [searchFltr, setSearchFltr] = useState('');
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const cid = loginInfoFromStorage.company_id;
    const permissions = loginInfoFromStorage.permissions;
    const [fileId, setFileId] = useState();
    const [startNum, setStartNum] = useState();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const handleCloseModal = () => {
        setOpen1(false);
    };
    const handleClickOpenModal = () => {
        setFileId('');
        setOpen1(true);
    };

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const fetchInvoiceConfig = async () => {
        const { data } = await axios.get(`/v1/api/dispatch/invoice-config/list/${cid}`, config);
        setStartNum(data.length > 0 ? data[0].starting_invoice_number : null);
    };

    useEffect(() => {
        fetchInvoiceConfig();
    }, []);

    const deleteFile = async (id) => {
        try {
            await axios
                .delete(`/v1/api/users/company/expense-type/view/${id}`, {
                    data: {},
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${loginInfoFromStorage.token}`
                    }
                })

                .then(dispatch(openSuccessSnackbar('Expense Item deleted sucessfully')));
            // .then(tableRef.current.onQueryChange());
            tableRef.current.onQueryChange();
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const addStartNum = async (startNum) => {
        if (startNum?.length < 6) {
            toast.error('Invoice Start Number must be at least 6 digits');
            return;
        }
        try {
            const invoiceStartNum = await axios.get(`/v1/api/dispatch/invoice-config/view/${startNum}?cid=${cid}`, config);
            if (invoiceStartNum.data.status == 400) {
                const data = {
                    starting_invoice_number: startNum,
                    company_id: cid,
                };
                const response = await axios.post(`/v1/api/dispatch/invoice-config/add`, data, config);
                toast.success('New Invoice Start Number Set');
                console.log(response);
            } else {
                console.log(invoiceStartNum);
                await axios.delete(`/v1/api/dispatch/invoice-config/view/${startNum}?cid=${cid}`, config);
                const data = {
                    starting_invoice_number: startNum,
                    company_id: cid,
                };
                const response = await axios.post(`/v1/api/dispatch/invoice-config/add`, data, config);
                console.log(response);

                toast.warning('Invoice Start Number Already Exist. Updated now...');
            }
        } catch (err) {
            toast.error('Something went wrong');
        }
    };

    const editFile = (id) => {
        setFileId(id);

        setOpen1(true);
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd ">Name</th>
                    {/* <th className="headerTd ">Default value</th> */}
                    <th className="headerTd ">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                {/* <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                {rowData.data.exp_type.charAt(0).toUpperCase() + rowData.data.exp_type.slice(1)}

                </td> */}
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.exp_item_type_name}
                </td>
                {/* <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.default_value}
                </td> */}

                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    {(permissions.invoice_expense_item && permissions.invoice_expense_item.change) || user_types.includes(user_type) ? (
                        <IconButton onClick={() => editFile(rowData.data.exp_item_type_id)}>
                            <EditIcon />
                        </IconButton>
                    ) : null}
                    {(permissions.invoice_expense_item && permissions.invoice_expense_item.delete) || user_types.includes(user_type) ? (
                        <IconButton
                            color="error"
                            onClick={() => deleteFile(rowData.data.exp_item_type_id)}
                            disabled={rowData.data.exp_type === 'settlement' && rowData.data.is_common}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    ) : null}
                </td>
            </tr>
        );
    };

    const fetchData = async (query) => {
        setLoading(true);

        try {
            const url = `/v1/api/users/company/expense-type/list/${cid}/invoice/?search=${searchFltr}`;

            const response = await fetch(url, config);
            const result = await response.json();

            console.log(result);
            // Set data
            setSelected(result || []);
            // setTotalCount(result.count || 0);

            return {
                data: result
                // page: query.page,
                // totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: []
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile) {
            fetchData({ page: 0, pageSize: 10 })
                .then((result) => {
                    console.log(result.data);
                    console.log('Fetched Data:', result);
                    setSelected(result.data || []);
                    // setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
        // console.log(selected);
    }, [isMobile]);

    console.log(selected);
    return (
        // <PageLayout url="/setup/add-user">
        <>
            <Stack
                sx={{ mt: 5, mb: 2, flexDirection: { xs: 'column', lg: 'row' }, gap: { xs: '1rem', lg: '0' } }}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={4}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                        width: { sm: '80vw', lg: '32vw' },
                        // width: { sm: '80vw', lg: '44vw' },
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: { xs: '1rem', sm: 0 }
                    }}
                >
                    <TextField
                        type="text"
                        placeholder="Search"
                        size="small"
                        value={searchFltr}
                        onChange={(e) => {
                            setSearchFltr(e.target.value);
                            tableRef.current.onQueryChange();
                        }}
                        className="search-input"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        sx={{ width: { xs: '80vw', sm: '55vw', lg: '20vw' } }}

                        // sx={{ minWidth: { xs: '22.3rem', sm: '10rem' } }}
                        // sx={{ width: { xs: '310px', sm: '350px' } }}
                    />

                    {(permissions.invoice_expense_item && permissions.invoice_expense_item.add) || user_types.includes(user_type) ? (
                        <Button
                            variant="contained"
                            sx={{
                                mr: 2,
                                color: 'white',
                                backgroundColor: '#EB812E',
                                // minWidth: { xs: '22.3rem', sm: '10rem' }
                                width: { xs: '80vw', sm: '7rem' }
                            }}
                            color="orange"
                            onClick={handleClickOpenModal}
                        >
                            ADD ITEM
                        </Button>
                    ) : null}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row', marginLeft: '0 !important', gap: '1rem' } }}>
                    {/* {(permissions.invoice_expense_item && permissions.invoice_expense_item.add) || user_types.includes(user_type) ? (
                        <Button
                            variant="contained"
                            sx={{
                                mr: 2,
                                color: 'white',
                                backgroundColor: '#EB812E',
                                // minWidth: { xs: '22.3rem', sm: '10rem' }
                                maxWidth: { xs: '18rem', sm: '12rem' }
                            }}
                            color="orange"
                            onClick={handleClickOpenModal}
                        >
                            ADD ITEM
                        </Button>
                    ) : null} */}
                    <TextField
                        className="text-input"
                        color="orange"
                        type="number"
                        label="Invoice Start Number"
                        sx={{ mr: 2, width: { xs: '80vw', sm: '20vw', lg: '20vw' } }}
                        size="small"
                        value={startNum}
                        onChange={(e) => setStartNum(e.target.value)}
                        error={startNum?.length < 6}
                        helperText={startNum?.length < 6 ? 'Invoice Start Number must be at least 6 digits' : ''}
                    ></TextField>
                    <Button
                        variant="contained"
                        sx={{
                            mr: 2,
                            color: 'white',
                            backgroundColor: '#EB812E',
                            maxWidth: { xs: '80vw', sm: '12rem' }
                        }}
                        color="orange"
                        onClick={() => addStartNum(startNum)}
                    >
                        SET START NUMBER
                    </Button>
                    {(permissions.invoice_expense_item && permissions.invoice_expense_item.add) || user_types.includes(user_type) ? (
                        <Button
                            variant="contained"
                            sx={{
                                mr: 2,
                                color: 'white',
                                backgroundColor: '#EB812E',
                                maxWidth: { xs: '80vw', sm: '12rem' }
                            }}
                            color="orange"
                            onClick={handleClickOpenModal}
                        >
                            ADD ITEM
                        </Button>
                    ) : null}
                </Box>
            </Stack>
            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Typography sx={{ marginBottom: '.5rem' }}> Name : {driver.exp_item_type_name}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        {' '}
                                        Action :{' '}
                                        {(permissions.invoice_expense_item && permissions.invoice_expense_item.change) || user_types.includes(user_type) ? (
                                            <IconButton onClick={() => editFile(driver.exp_item_type_id)}>
                                                <EditIcon />
                                            </IconButton>
                                        ) : null}
                                        {(permissions.invoice_expense_item && permissions.invoice_expense_item.delete) || user_types.includes(user_type) ? (
                                            <IconButton
                                                color="error"
                                                onClick={() => deleteFile(driver.exp_item_type_id)}
                                                disabled={driver.exp_type === 'settlement' && driver.is_common}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        ) : null}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    {/* <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                maxWidth: '19rem',
                                flexWrap: 'wrap',
                                justifyContent: 'right',
                                marginLeft: '4vw'
                            },
                            '.MuiTablePagination-selectLabel': {
                                ml: '4rem'
                            },
                            '.MuiTablePagination-select': {
                                width: '6rem'
                            },
                            '.css-1ioyjal-MuiInputBase-root-MuiTablePagination-select': {
                                marginRight: 0,
                                marginLeft: '-12vw'
                            }
                        }}
                    /> */}
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    // data={(query) =>
                    //     new Promise((resolve, reject) => {
                    //         const url = `/v1/api/users/company/expense-type/list/${cid}/invoice/?search=${searchFltr}`;

                    //         fetch(url, config)
                    //             .then((response) => response.json())
                    //             .then((result) => {
                    //                 resolve({
                    //                     data: result
                    //                     // page: query.page,
                    //                     // totalCount: result.count
                    //                 });
                    //             });
                    //     })
                    // }
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchData(query)
                                .then((result) => resolve(result))
                                .catch((error) => reject(error));
                        })
                    }
                    title="All Expense"
                    // columns={[]}
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,
                        toolbar: false,
                        draggable: false,
                        columnResizable: true
                        // paging: false
                        // pageSize: 50,
                        // pageSizeOptions: [50]
                    }}
                />
            )}

            <NewExpenseItemModal
                open={open1}
                handleClose={handleCloseModal}
                type="invoice"
                fileId={fileId}
                reloadHandler={() => {
                    tableRef.current.onQueryChange();
                }}
            />
        </>
    );
};

export default AllExpenseItemForm;
