// project imports
import { useState, useEffect } from 'react';

import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import MainCard from 'ui-component/cards/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';

import { useNavigate } from 'react-router';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLayout from 'layout/PageLayout';
import AllMemberForm from 'ui-component/setup/forms/AllMemberForm';
// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '8px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '18px',
                            fontWeight: 900,
                            marginRight: '5px',
                            color: 'white'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});
const MechanicCompanySetup = () => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    return (
        <>
            <MainCard>
                <PageLayout title="Company Setup">
                    <Box sx={{ border: 'none', background: 'transparent' }}>
                        <ThemeProvider theme={theme}>
                            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                                <Tab label="Users" onClick={() => navigate(`/setup/user/all`)} />
                            </Tabs>
                        </ThemeProvider>
                    </Box>

                    {/* <ViewCompanyProfile/> */}
                    <AllMemberForm companySetup={companySetup} />
                </PageLayout>
            </MainCard>
        </>
    );
};

export default MechanicCompanySetup;
