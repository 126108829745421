import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allsnackbar, resetSnackbar } from 'store/ui/snackbarSlice';

function ErrorSnackbar() {
    const dispatch = useDispatch();

    const snackbar = useSelector(allsnackbar);
    const { errorSnackbar, errorSnackbarMessage } = snackbar;

    const handleClose = () => {
        dispatch(resetSnackbar());
    };
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={errorSnackbar}
            autoHideDuration={4000}
            onClose={handleClose}
            TransitionComponent="slide"
            aria-describedby="client-snackbar"
        >
            <Alert severity="error" onClose={handleClose} style={{ backgroundColor: '#d74545', color: '#ffffff' }} sx={{ width: '100%' }}>
                {errorSnackbarMessage}
            </Alert>
        </Snackbar>
    );
}

export default ErrorSnackbar;
