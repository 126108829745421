import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
const initialState = {
    userLogin: loginInfoFromStorage,
    companySetup: null,
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
};

const config = {
    headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
    }
};
const setupConfig = {
    headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
    }
};
export const getCompanySetup = createAsyncThunk(
    //action type string

    'companySetup/getCompanySetup',
    // callback function

    async (obj, { getState }) => {
        const state = getState();
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${state.companySetup.userLogin.token}`
            }
        };
        try {
           
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);
            // console.log(data);
            return data;
        } catch (error) {
            console.log(error);
        }
    }
);

export const createCompanySetup = createAsyncThunk(
    //action type string
    'companySetup/createCompanySetup',
    // callback function
    async (obj, { getState }) => {
        const state = getState();
        const config = {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${state.companySetup.userLogin.token}`
            }
        };
        try {
            const { data } = await axios.post(`/v1/api/users/company/setup/`, obj, config);
            // console.log(data);
            return data;
        } catch (error) {
            console.log(error);
        }
    }
);

export const companyLogin = createAsyncThunk(
    //action type string
    'companySetup/companyLogin',
    // callback function
    async (obj, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(`/v1/api/users/login/`, obj, config);
            // console.log(data);
            localStorage.setItem('loginUserInfo', JSON.stringify(data));
            setTimeout(() => {
                localStorage.removeItem('loginUserInfo');
            },  24 * 60 * 60 * 1000);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data.detail);
        }
    }
);

// Test comment

export const companySetupSlice = createSlice({
    name: 'companySetup',
    initialState,
    reducers: {
        resetcompanySetup: (state) => {
            state.companySetup = initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanySetup.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getCompanySetup.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.companySetup = action.payload;
            })
            .addCase(getCompanySetup.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })

            .addCase(createCompanySetup.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(createCompanySetup.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.companySetup = action.payload;
            })
            .addCase(createCompanySetup.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })

            .addCase(companyLogin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(companyLogin.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userLogin = action.payload;
            })
            .addCase(companyLogin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    }
});

export const { resetcompanySetup } = companySetupSlice.actions;

export const selectCompanySetup = (state) => state.companySetup;

export default companySetupSlice.reducer;
