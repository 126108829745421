import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import { Button, Stack } from '@mui/material';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { createRef } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AllIftaItemViewForm = ({ id, driver }) => {
    const { year, quarter } = useParams();

    const tableRef = createRef();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;

    function downloadHandler() {
        // Replace 'yourEndpointURL' with the actual URL of the endpoint
        const endpointURL = `/v1/api/accounting/ifta-item/download/${year}/${quarter}/${driver.driver_id}/`;

        fetch(endpointURL, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'IFTA_Item_List.xlsx'; // You can specify the filename here
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                toast.error("Can't download please contact with support")
                console.error('There was a problem with the fetch operation:', error);
            });
    }

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">State</th>
                    <th className="headerTd rightHidBor">Total Mileage</th>

                    <th className="headerTd rightHidBor">Total Gallons</th>
                    <th className="headerTd rightHidBor">Overall Fuel Mileage</th>
                    <th className="headerTd rightHidBor">Gallons Burned</th>
                    <th className="headerTd rightHidBor">Fuel Surcharged</th>
                    <th className="headerTd rightHidBor">Fuel Tax Required</th>
                    <th className="headerTd rightHidBor">Fuel Tax Paid</th>
                    <th className="headerTd"> Fuel Tax Owed</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr style={rowData.data.is_total ? { backgroundColor: '#011f47', color: '#FFFF' } : null}>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_total ? 'Total' : rowData.data.state}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.tot_millage}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.tot_gallons}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.fuel_millage}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.gallons_burned}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.fuel_surcharge}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.tax_req}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.tax_rate}
                </td>
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.fuel_tax}
                </td>
            </tr>
        );
    };

    return (
        <>
            <Stack sx={{ mt: 5, mb: 2 }} direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={4}>
                <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={() => downloadHandler()}>
                    DOWNLOAD
                </Button>
            </Stack>

            <MaterialTable
                tableRef={tableRef}
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                data={(query) =>
                    new Promise((resolve, reject) => {
                        fetch(
                            `/v1/api/accounting/ifta-item/list/${year}/${quarter}/${driver.driver_id}/?page=${query.page + 1}&page_size=${
                                query.pageSize
                            }`,
                            {
                                method: 'get',

                                headers: new Headers({
                                    'Content-type': 'application/json',
                                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                                })
                            }
                        )
                            .then((response) => response.json())
                            .then((result) => {
                                // Appending the last total item
                                if (result.last_item) {
                                    result.results.push(result.last_item);
                                }
                                resolve({
                                    data: result.results,
                                    page: query.page,
                                    totalCount: result.count
                                });
                            });
                    })
                }
                title=""
                columns={[]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    sorting: true,
                    exportButton: false,
                    showFooter: true,
                    toolbar: false,
                    draggable: false,
                    columnResizable: true
                }}
            />
        </>
    );
};

export default AllIftaItemViewForm;
