import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import logo from 'assets/images/logo.jpeg';

// import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
// import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}

            <img src={logo} width={250} alt="souvenir" />
            <Box
                sx={{
                    background: theme.palette.primary.main,
                    display: 'flex',

                    height: '80px',
                    ml: '15px',
                    xs: 'block',
                    width: 1
                }}
            >
                {/* header search */}
                {/* <SearchSection /> */}
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ flexGrow: 1 }} />

                {/* notification & profile */}
                {/* <NotificationSection /> */}
                {/* <ProfileSection /> */}
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
