import React from 'react';

const typeColors = {
    success: '#5AC47C',
    inprog: '#3886BB',
    na: '#659CF5',
    error: '#D3505F',
    info: '#6141AC',
    warning: '#FFD366',
    orange: '#f48120',
    hp: '#9400d3',
    blue: '#142953'
};

function LoadStatus({ text, type, sx }) {
    return (
        <div
            style={{
                backgroundColor: typeColors[type] || '',
                color: 'white',
                padding: '3px',
                fontSize: '12px',
                border: '1px solid',
                borderRadius: '10px',
                display: 'initial',
                ...sx
            }}
        >
            {text}
        </div>
    );
}

export default LoadStatus;
