import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material-ui

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Skeleton,
    TextField,
    Typography
} from '@mui/material';

// third party
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Stack } from '@mui/system';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import axios from 'axios';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import SubCard from 'ui-component/cards/SubCard';
import * as Yup from 'yup';

import { LoadingButton } from '@mui/lab';
import { PatternFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const DriverForm = () => {
    const navigate = useNavigate();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    const driverConfig = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [showAccd, setShowAccd] = useState(false);
    const [driverDetails, setDriverDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [dError, setDError] = useState();
    const { id } = useParams();
    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [ssnShow, setSsnShow] = useState(true);

    useEffect(() => {
        if (!stateList) {
            fetchState();
        }

        if (stateList && !driverDetails && id) {
            fetchDriver(id);
        }

        if (id && driverDetails && !cityList) {
            if (driverDetails.state_id) getCityList(driverDetails.state_id);
        }
    }, [stateList, cityList, driverDetails, id]);

    const fetchDriver = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/company/driver/${id}/`, config);
            setDriverDetails(data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchState = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const changeStates = (value) => {
        setFieldValue('state', value);

        //  setFieldValue('companyCity', '');
        getCityList(value);
    };

    const getCityList = async (id) => {
        setLoading1(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);
            setLoading1(false);
            setCityList(data);
        } catch (error) {
            setLoading(false);
        }
    };

    const driveSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is a required field')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
        lastName: Yup.string()
            .required('Last Name is a required field')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
        email: Yup.string().email().required('Email is a required field'),
        phone: Yup.string().required('Phone is a required field'),
        cdl: Yup.string().required('CDL# is a required field').nullable(true),
        bankAc: Yup.string().matches(/^\d+$/, { message: 'This should be a valid number' }).max(20, 'Bank account must be less than 20 ').nullable(true),

        ssn: Yup.string().matches(/^\d+$/, { message: 'This should be a valid number' }).max(10, 'ssn must be less than 10').nullable(true)
    });

    const hadAccidentHandler = (e) => {
        setFieldValue('previousHisAc', e.target.value);
        setShowAccd(!showAccd);
    };

    let empFiles = [
        {
            drv_doc_id: 0,
            doc_name: 'Certificate of Road Test',
            doc_type_name: 'certificate_of_road_test',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Contract Agreement',
            doc_type_name: 'contract_agreement',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'DL Copy',
            doc_type_name: 'dl_copy',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Medical Card',
            doc_type_name: 'medical_card',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'MVR',
            doc_type_name: 'mvr',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Drug Test',
            doc_type_name: 'drug_test',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            doc_name: 'Pre Employement Driving Test',
            doc_type_name: 'pre_employement_driving_test',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        }
    ];

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            firstName: id && driverDetails ? driverDetails.user.first_name : '',
            lastName: id && driverDetails ? driverDetails.user.last_name : '',
            email: id && driverDetails ? driverDetails.user.email : '',
            phone: id && driverDetails ? driverDetails.user.phone : '',
            address: id && driverDetails ? driverDetails.address : '',
            state: id && driverDetails ? driverDetails.state_id : '',
            city: id && driverDetails ? driverDetails.city_id : '',
            zip: id && driverDetails ? driverDetails.zip_code : '',
            cdl: id && driverDetails ? driverDetails.cdl_no : '',
            ssn: id && driverDetails ? driverDetails.ssn_no : '',
            bankAc: id && driverDetails ? driverDetails.acc_no : '',
            doh: id && driverDetails?.hire_date ? `${driverDetails.hire_date}T00:00` : '',
            dot: id && driverDetails?.end_date ? `${driverDetails.end_date}T00:00` : '',
            dob: id && driverDetails?.birth_date ? `${driverDetails.birth_date}T00:00` : '',
            files: id && driverDetails && driverDetails?.safety_docs?.length !== 0 ? driverDetails.safety_docs : empFiles,
            previousHisAc: id && driverDetails ? driverDetails.had_accident : 'false',
            dateOfAccident: id && driverDetails ? driverDetails.accd_date : '',
            toa: id && driverDetails ? driverDetails.accd_type : '',
            sal: id && driverDetails ? driverDetails.severity : '',
            supportDoc: id && driverDetails ? driverDetails.spprt_doc_name : '',
            supportDocName: id && driverDetails ? driverDetails.spprt_doc_name : '',
            isActive: driverDetails ? driverDetails.is_active : 'true',
            isOwner: driverDetails ? driverDetails.is_owner : 'true'
        },
        validationSchema: driveSchema,
        onSubmit: async () => {
            if (values.dot !== '' && values.doh === '') {
                setFieldError('dot', 'Date of Hire cannot be blank when Date of Termination is selected');
                return;
            } else if (values.doh !== '' && values.dot !== '' && new Date(values.dot) < new Date(values.doh)) {
                setFieldError('dot', 'Date of Termination cannot be less than Date of Hire');
                return;
            }
            try {
                const user = {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    email: values.email,
                    username: values.email,
                    phone: values.phone,
                    user_type: 'driver',
                    company_id: loginInfoFromStorage.company_id,

                    is_edit: id ? true : false
                };

                const driverDet = {
                    address: values.address,
                    state_id: values.state,
                    city_id: values.city,
                    country_id: 233,
                    zip_code: values.zip,
                    ssn_no: values.ssn,
                    cdl_no: values.cdl,
                    acc_no: values.bankAc,
                    hire_date: values.doh ? format(new Date(values.doh), 'yyyy-MM-dd') : '',
                    end_date: values.dot ? format(new Date(values.dot), 'yyyy-MM-dd') : '',
                    birth_date: values.dob ? format(new Date(values.dob), 'yyyy-MM-dd') : '',

                    had_accident: values.previousHisAc,
                    accd_date: values.dateOfAccident ? format(new Date(values.dateOfAccident), 'yyyy-MM-dd') : '',
                    accd_type: values.toa,
                    severity: values.sal,
                    company_id: loginInfoFromStorage.company_id,
                    user_id: id && driverDetails && driverDetails.user.user_id,
                    is_active: values.isActive === 'true' ? true : false,
                    is_owner: values.isOwner === 'true' ? true : false,
                    is_edit: id ? true : false
                };

                let setup = new FormData();

                values.files.forEach((i, index) => setup.append(`saftey_docs[${index}].sft_doc`, i.sft_doc));
                values.files.forEach((i, index) => setup.append(`saftey_docs[${index}].sft_doc_name`, i.sft_doc_name));
                values.files.forEach((i, index) => setup.append(`saftey_docs[${index}].drv_doc_id`, i.drv_doc_id));
                values.files.forEach((i, index) => setup.append(`saftey_docs[${index}].doc_type_name`, i.doc_type_name));
                values.files.forEach((i, index) => setup.append(`saftey_docs[${index}].doc_name`, i.doc_name));
                // setup.append('safety_docs', JSON.stringify(values.files));

                setup.append('user', JSON.stringify(user));
                setup.append('driverData', JSON.stringify(driverDet));

                setup.append('spprt_doc', values.supportDoc);
                setup.append('spprt_doc_name', values.supportDocName);
                if (!id) {
                    await axios.post(`/v1/api/users/company/drivers/add/`, setup, driverConfig);
                    toast.success('Driver added successfully');
                } else {
                    await axios.put(`/v1/api/users/company/driver/${id}/`, setup, driverConfig);
                    toast.success('Driver updated successfully');
                }
                setTimeout(() => {
                    navigate('/fleet/driver/all');
                }, 1000);
            } catch (error) {
                setDError(error.response?.data);
                toast.error('Please contact with support');
            }
        }
    });

    const { errors, touched, values, setFieldValue, setFieldError, isSubmitting, getFieldProps, handleSubmit } = formik;

    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.',
        error: Boolean(touched.phone && errors.phone) || (dError && dError.phone),
        helperText: (touched.phone && errors.phone) || (dError && dError.phone)
    };
    const LetterOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const AlphanumericOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z0-9 ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                <Typography
                                    sx={{
                                        mt: '25px',
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '32px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    Driver Details
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Stack direction="row" sx={{ mt: '20px' }} justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <LoadingButton loading={isSubmitting} type="submit" variant="contained" color="orange" sx={{ color: 'white' }}>
                                        Save
                                    </LoadingButton>
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>
                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '20px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        General Information
                                    </Typography>
                                </Grid>

                                {/* Weight */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="First Name"
                                            {...getFieldProps('firstName')}
                                            onInput={LetterOnlyHandler}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                        />
                                    )}
                                </Grid>
                                {/* revenue */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            type="text"
                                            label="Last Name"
                                            {...getFieldProps('lastName')}
                                            onInput={LetterOnlyHandler}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        />
                                    )}
                                </Grid>
                                {/* Load */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            type="text"
                                            label="Email"
                                            {...getFieldProps('email')}
                                            error={Boolean(touched.email && errors.email) || (dError && dError.email)}
                                            helperText={(touched.email && errors.email) || (dError && dError.email)}
                                        />
                                    )}
                                </Grid>
                                {/* millage */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <PatternFormat
                                            format="###-###-####"
                                            allowEmptyFormatting
                                            mask="_"
                                            value={values.phone}
                                            onValueChange={(value) => setFieldValue('phone', value.value)}
                                            customInput={TextField}
                                            {...textFieldProps}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            type="text"
                                            label="Full US Address"
                                            {...getFieldProps('address')}
                                            error={Boolean(touched.address && errors.address)}
                                            helperText={touched.address && errors.address}
                                        />
                                    )}
                                </Grid>

                                {/* State */}
                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            select
                                            type="text"
                                            label="State"
                                            value={values.state}
                                            onChange={(e) => changeStates(e.target.value)}
                                        >
                                            {stateList &&
                                                stateList.map((i) => (
                                                    <MenuItem key={i.id} value={i.id}>
                                                        {i.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    )}
                                </Grid>

                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    {loading || loading1 ? (
                                        <Skeleton variant="rectangular" width="100%" height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            select
                                            type="text"
                                            label="City"
                                            value={values.city}
                                            onChange={(e) => setFieldValue('city', e.target.value)}
                                        >
                                            <MenuItem disabled selected>
                                                Select City
                                            </MenuItem>
                                            {cityList &&
                                                cityList.map((i) => (
                                                    <MenuItem key={i.id} value={i.id}>
                                                        {i.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Zip"
                                            inputProps={{ maxLength: 5 }}
                                            value={values.zip}
                                            onChange={(e) => setFieldValue('zip', !isNaN(e.target.value) ? e.target.value : '')}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            type="text"
                                            label="CDL #"
                                            {...getFieldProps('cdl')}
                                            error={Boolean(touched.cdl && errors.cdl)}
                                            helperText={touched.cdl && errors.cdl}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            type={ssnShow ? 'password' : 'text'}
                                            label="SSN"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton aria-label="toggle password visibility" onClick={() => setSsnShow(!ssnShow)} edge="end">
                                                        <InputAdornment position="end">{ssnShow ? <Visibility /> : <VisibilityOff />}</InputAdornment>
                                                    </IconButton>
                                                ),
                                                autoComplete: false
                                            }}
                                            {...getFieldProps('ssn')}
                                            onInput={AlphanumericOnlyHandler}
                                            error={Boolean(touched.ssn && errors.ssn)}
                                            helperText={touched.ssn && errors.ssn}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            type="text"
                                            label="Bank Account Number"
                                            {...getFieldProps('bankAc')}
                                            onInput={AlphanumericOnlyHandler}
                                            error={Boolean(touched.bankAc && errors.bankAc)}
                                            helperText={touched.bankAc && errors.bankAc}
                                        />
                                    )}
                                </Grid>
                                {/* start_date */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                label="Date Of Hire"
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                value={values.doh}
                                                onChange={(newValue) => {
                                                    setFieldValue('doh', newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        color="orange"
                                                        fullWidth
                                                        {...params}
                                                        error={dError && dError.hire_date}
                                                        helperText={dError && dError.hire_date}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                label="Date Of Termination"
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                value={values.dot}
                                                onChange={(newValue) => {
                                                    setFieldValue('dot', newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="text-input"
                                                        color="orange"
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean((touched.dot && errors.dot) || (dError && dError.end_date))}
                                                        helperText={(touched.dot && errors.dot) || (dError && dError.end_date)}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                label="Date Of Birth"
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                value={values.dob}
                                                disableFuture={true}
                                                onChange={(newValue) => {
                                                    setFieldValue('dob', newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="text-input"
                                                        color="orange"
                                                        fullWidth
                                                        {...params}
                                                        error={dError && dError.birth_date}
                                                        helperText={dError && dError.birth_date}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormLabel id="demo-radio-buttons-group-label" className="radio-label">
                                        Owns a truck?
                                    </FormLabel>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            // defaultValue="Weekly"
                                            name="radio-buttons-group"
                                            row
                                            className="radio-group"
                                            value={values.isOwner}
                                            onChange={(e) => setFieldValue('isOwner', e.target.value)}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {/* Ratecon upload */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '20px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Driver Qualification
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <FieldArray name="files">
                                            {({ push, remove }) => (
                                                <Grid container spacing={2}>
                                                    {values.files.map((file, index) => (
                                                        <Grid item lg={6} md={12} sm={12} xs={12} key={index}>
                                                            {/* Type  */}

                                                            <TextField
                                                                className="text-input"
                                                                color="orange"
                                                                sx={{
                                                                    '.MuiOutlinedInput-root': {
                                                                        display: 'flex',
                                                                        alignItems: 'self-start',
                                                                        flexDirection: { xs: 'column', sm: 'row' }
                                                                    },
                                                                    '.MuiInputBase-input': {
                                                                        height: { xs: '3.4375em', sm: '1.4375em' }
                                                                    }
                                                                }}
                                                                fullWidth
                                                                value={values.files[index].sft_doc_name}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton edge="end">
                                                                                <Button
                                                                                    component="label"
                                                                                    color="orange"
                                                                                    startIcon={<CloudUploadIcon />}
                                                                                    sx={{ mt: { xs: '-2.7rem', sm: '3rem' } }}
                                                                                >
                                                                                    Upload
                                                                                    <input
                                                                                        hidden
                                                                                        type="file"
                                                                                        onInputCapture={(event) => {
                                                                                            setFieldValue(`files[${index}].sft_doc`, event.target.files[0]);
                                                                                            setFieldValue(
                                                                                                `files[${index}].sft_doc_name`,
                                                                                                event.target.files[0].name
                                                                                            );
                                                                                            setFieldValue(
                                                                                                `files[${index}].drv_doc_id`,
                                                                                                values.files[index].drv_doc_id
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </Button>
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                label={values.files[index].doc_name}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            )}
                                        </FieldArray>
                                    )}
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormLabel id="demo-radio-buttons-group-label" className="radio-label">
                                        Previous History of accidents:
                                    </FormLabel>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            // defaultValue="Weekly"
                                            name="radio-buttons-group"
                                            row
                                            className="radio-group"
                                            value={values.previousHisAc}
                                            onChange={(e) => hadAccidentHandler(e)}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                </Grid>
                                {/* Date Of Accident */}
                                {showAccd && (
                                    <>
                                        <Grid item lg={4} md={4} sm={4} xs={12}>
                                            {loading ? (
                                                <Skeleton height="50px" />
                                            ) : (
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        className="text-input"
                                                        label="Date Of Accident"
                                                        openTo="day"
                                                        views={['year', 'month', 'day']}
                                                        value={values.dateOfAccident}
                                                        onChange={(newValue) => {
                                                            setFieldValue('dateOfAccident', newValue);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={12}>
                                            {loading ? (
                                                <Skeleton height="50px" />
                                            ) : (
                                                <TextField
                                                    className="text-input"
                                                    color="orange"
                                                    fullWidth
                                                    type="text"
                                                    label="Type of Accident"
                                                    {...getFieldProps('toa')}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={12}>
                                            {loading ? (
                                                <Skeleton height="50px" />
                                            ) : (
                                                <TextField
                                                    className="text-input"
                                                    color="orange"
                                                    fullWidth
                                                    type="text"
                                                    label="Severity and lose"
                                                    {...getFieldProps('sal')}
                                                />
                                            )}
                                        </Grid>
                                    </>
                                )}

                                {/*Supporting Document*/}

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            sx={{
                                                '.MuiOutlinedInput-root': {
                                                    display: 'flex',
                                                    alignItems: 'self-start',
                                                    flexDirection: { xs: 'column', sm: 'row' }
                                                },
                                                '.MuiInputBase-input': {
                                                    height: { xs: '3.4375em', sm: '1.4375em' }
                                                }
                                            }}
                                            value={values.supportDocName}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            <Button
                                                                component="label"
                                                                color="orange"
                                                                startIcon={<CloudUploadIcon />}
                                                                sx={{ mt: { xs: '-2.7rem', sm: '3rem' } }}
                                                            >
                                                                Uplaod
                                                                <input
                                                                    hidden
                                                                    type="file"
                                                                    onInputCapture={(event) => {
                                                                        setFieldValue('supportDoc', event.currentTarget.files[0]);
                                                                        setFieldValue('supportDocName', event.currentTarget.files[0].name);
                                                                    }}
                                                                />
                                                            </Button>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            label="Supporting Document"
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                    </SubCard>
                </Form>
            </FormikProvider>
        </>
    );
};

export default DriverForm;
