import React from 'react';
import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

// import { Button, Grid, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';

import {
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    InputAdornment,
    Stack,
    TablePagination,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { createRef } from 'react';
import { useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';

import ViewIftaModal from 'ui-component/modals/ViewIftaModal';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { Box } from '@mui/system';

function DriverIftaInfo() {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const tableRef = createRef();

    const { quarter, year } = useParams();

    const [open1, setOpen1] = useState(false);

    const [iftaId, setIftaId] = useState(0);
    const [driver, setDriver] = useState({
        driver_id: '',
        driver_name: ''
    });

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const [searchFltr, setSearchFltr] = useState('');
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.user_type === 'super_dispatcher_admin' ? '' : loginInfoFromStorage.company_id;

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const handleCloseModal = () => {
        setOpen1(false);
    };
    const handleClickOpenModal = (id, driver_name, driver_id) => {
        setIftaId(id);
        setDriver({
            driver_id,
            driver_name
        });
        setOpen1(true);
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Driver Name</th>
                    <th className="headerTd rightHidBor">Total IFTA tax</th>

                    <th className="headerTd">State IFTA info</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.fuel_tax}
                </td>

                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => handleClickOpenModal(rowData.data.dsp_drv_ifta_id, rowData.data.driver_name, rowData.data.driver_id)}>
                        <VisibilityIcon />
                    </IconButton>
                </td>
            </tr>
        );
    };

    const fetchData = async (query) => {
        setLoading(true);

        try {
            const url = `/v1/api/accounting/ifta/list?page=${query.page + 1}&page_size=${
                query.pageSize
            }&search=${searchFltr}&company_id=${cid}&quarter=${quarter}&year=${year}`;

            const response = await fetch(url, config);
            const result = await response.json();

            // Set data directly
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);

            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 10 }).catch((error) => console.error('Error fetching data:', error));
        }
    }, [isMobile, isTablet]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    return (
        <>
            <>
                <Stack
                    sx={{ mt: 5, mb: 2 }}
                    direction={matchDownMd ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems={matchDownMd ? 'flex-start' : 'center'}
                    spacing={4}
                >
                    <TextField
                        type="text"
                        size="small"
                        placeholder="Search"
                        value={searchFltr}
                        onChange={(e) => {
                            setSearchFltr(e.target.value);
                            tableRef.current.onQueryChange();
                        }}
                        sx={{ width: { xs: '82vw', sm: '45vw', lg: '20vw' } }}
                        className="search-input"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>

                {isMobile ? (
                    <>
                        {loading ? (
                            <>
                                <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress />
                                </Box>
                            </>
                        ) : (
                            selected &&
                            selected.map((driver, idx) => (
                                <Card
                                    key={driver.driver_id}
                                    sx={{
                                        marginBottom: '1.5rem',
                                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                        borderRadius: '15px',
                                        backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                        // backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : 'rgba(221, 230, 255, 0.3)'
                                    }}
                                >
                                    <CardContent>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Driver Name : {driver.driver_name}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Total IFTA tax : {driver.fuel_tax}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>
                                            State IFTA info :{' '}
                                            <IconButton onClick={() => handleClickOpenModal(driver.dsp_drv_ifta_id, driver.driver_name, driver.driver_id)}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))
                        )}

                        <TablePagination
                            showFirstButton
                            showLastButton
                            component="div"
                            count={totalCount}
                            page={currPage - 1}
                            // onPageChange={handleChangePage}
                            onPageChange={(event, newPage) => {
                                handleChangePage(event, newPage);
                                fetchData({ page: newPage, pageSize: cardPerPage });
                            }}
                            rowsPerPage={cardPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                '.MuiTablePagination-toolbar': {
                                    display: 'grid',
                                    gridTemplateColumns: '4rem 11rem',
                                    gridTemplateRows: 'auto auto',
                                    alignItems: 'center',
                                    gap: '8px' // Adjust as needed
                                },
                                '.MuiTablePagination-selectLabel': {
                                    gridColumn: '2/3',
                                    gridRow: '1 / 2',
                                    textWrap: 'nowrap',
                                    textAlign: isSmall ? 'center' : 'end'
                                },
                                '.MuiTablePagination-input': {
                                    gridColumn: '2 / 4',
                                    gridRow: '1 / 2',
                                    marginRight: 0
                                },
                                '.MuiTablePagination-displayedRows': {
                                    gridColumn: '1 / 2',
                                    gridRow: '2 / 3',
                                    textWrap: 'nowrap',
                                    textAlign: isSmall ? 'center' : 'end',
                                    marginLeft: isSmall ? '8vw' : '20vw'
                                },
                                '.MuiTablePagination-actions': {
                                    gridColumn: '1/4',
                                    gridRow: '2 / 3',
                                    display: 'flex',
                                    alignItems: 'center',
                                    // gap: '8px',
                                    justifyContent: 'flex-end'
                                }
                            }}
                        />
                    </>
                ) : isTablet ? (
                    <>
                        {loading ? (
                            <>
                                <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress />
                                </Box>
                            </>
                        ) : (
                            selected &&
                            selected?.map((driver, idx) => (
                                <Card
                                    key={driver.driver_id}
                                    sx={{
                                        marginTop: '2rem',
                                        marginBottom: '1.5rem',
                                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                        borderRadius: '15px',
                                        backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                    }}
                                >
                                    <CardContent>
                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                            }}
                                        >
                                            <Typography sx={{ marginBottom: '.5rem' }}>Driver Name : {driver.driver_name}</Typography>
                                            <Typography sx={{ marginBottom: '.5rem' }}>Total IFTA tax : {driver.fuel_tax}</Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(2, 1fr)',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                            }}
                                        >
                                            <Typography sx={{ marginBottom: '.5rem' }}>
                                                State IFTA info :{' '}
                                                <IconButton onClick={() => handleClickOpenModal(driver.dsp_drv_ifta_id, driver.driver_name, driver.driver_id)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))
                        )}

                        <TablePagination
                            showFirstButton
                            showLastButton
                            component="div"
                            count={totalCount}
                            page={currPage - 1}
                            // onPageChange={handleChangePage}
                            onPageChange={(event, newPage) => {
                                handleChangePage(event, newPage);
                                fetchData({ page: newPage, pageSize: cardPerPage });
                            }}
                            rowsPerPage={cardPerPage}
                            rowsPerPageOptions={[20, 50, 100]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <MaterialTable
                        tableRef={tableRef}
                        style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                        data={(query) =>
                            new Promise((resolve, reject) => {
                                fetchData(query)
                                    .then((result) => resolve(result))
                                    .catch((error) => reject(error));
                            })
                        }
                        title=""
                        columns={[]}
                        icons={TABLE_ICONS}
                        components={{ Row: renderRow, Header: renderHeader }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: <CustomNoRowsOverlay />
                            }
                        }}
                        options={{
                            sorting: true,
                            exportButton: false,

                            toolbar: false,
                            draggable: false,
                            columnResizable: true
                        }}
                    />
                )}
                <ViewIftaModal open={open1} handleCloseModal={handleCloseModal} id={iftaId} driver={driver} />
            </>
        </>
    );
}

export default DriverIftaInfo;
