// project imports

import MainCard from 'ui-component/cards/MainCard';
import ViewLoad from 'ui-component/dispatchCenter/forms/ViewLoad';
import 'ui-component/font/fonts';
import PageLayout from 'layout/PageLayout';
// ==============================|| SAMPLE PAGE ||============================== //

const ViewMyLoadStatus = () => {
    return (
        <MainCard sx={{ height: '100%' }}>
            <PageLayout title="Dispatch Center">
                <ViewLoad />
            </PageLayout>
        </MainCard>
    );
};

export default ViewMyLoadStatus;
