import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Skeleton, TextField, Typography } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { Stack } from '@mui/system';
import BackFormButton from 'ui-component/buttons/BackFormButton';
import { PatternFormat } from 'react-number-format';

import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const OrganizationForm = () => {
    // const { companySetup } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorAdd, setErrorAdd] = useState('');

    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [loading, setLoading] = useState(false);
    const [cityLoading, setCityLoading] = useState(false);
    const [companySetup, setCompanySetup] = useState();
    const [fileObject, setFileObject] = useState(null);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    useEffect(() => {
        if (!companySetup) {
            fetchCompany();
        }

        if (companySetup && !stateList) {
            fetchState();
        }
        if (companySetup && companySetup.state_id && !cityList) {
            getCityList(companySetup.state_id);
        }
        if (companySetup && companySetup.logo) {
            handleFetchFile(companySetup.logo);
        }
    }, [stateList, cityList, companySetup]);

    const fetchState = async () => {
        setLoading(true);
        setCityLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
            setLoading(false);
            setCityLoading(false);
        } catch (error) {
            setLoading(false);
            setCityLoading(false);
        }
    };

    const changeStates = (value) => {
        setFieldValue('state', value);
        getCityList(value);
    };

    const getCityList = async (id) => {
        setCityLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);

            setCityList(data);
            setCityLoading(false);
        } catch (error) {
            setCityLoading(false);
        }
    };

    const fetchCompany = async () => {
        setLoading(true);
        setCityLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);

            setCompanySetup(data);
            setLoading(false);
            setCityLoading(false);
        } catch (error) {
            setLoading(false);
            setCityLoading(false);
        }
    };

    const handleFetchFile = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const fileName = url.split('/').pop();

            // Create a File object from the blob

            const fileObject = new File([blob], fileName, { type: blob.type });

            setFileObject(fileObject);
        } catch (error) {
            console.error('Error fetching or creating File object:', error);
        }
    };

    const OrganizationSchema = Yup.object().shape({
        companyName: Yup.string().required('Company Name is a required field'),
        mc: Yup.string().required('MC# is a required field').typeError('MC# is required'),
        dot: Yup.string().required('DOT# is a required field').typeError('DOT# is required'),
        buildingAddress: Yup.string().required('Address is required').typeError('Address is required'),
        state: Yup.number().required('State is required').typeError('State is required'),
        city: Yup.number().required('City is required').typeError('City is required'),
        zip: Yup.string().required('ZIP Code is required').typeError('ZIP Code is required')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            companyName: companySetup ? companySetup.dsp_company_name : '',
            buildingAddress: companySetup ? companySetup.address : '',

            city: companySetup ? companySetup.city_id : '',
            state: companySetup ? companySetup.state_id : '',
            zip: companySetup ? companySetup.zip_code : '',
            mc: companySetup ? companySetup.mc_no : '',
            ein: companySetup ? companySetup.ein_no : '',

            dot: companySetup ? companySetup.dot_no : '',
            email: companySetup ? companySetup.email : '',
            bankName: companySetup ? companySetup.bank_name : '',
            bankAc: companySetup ? companySetup.acc_no : '',
            cPhone: companySetup ? companySetup.phone : '',
            ext: companySetup ? companySetup.phone_ext : ''
        },
        validationSchema: OrganizationSchema,
        onSubmit: async () => {
            setLoading(true);

            try {
                const setup = new FormData();
                setup.append('dsp_company_name', values.companyName);
                // setup.append('logo', fileObject);
                setup.append('fact_client_no', companySetup.fact_info.fact_client_no);
                setup.append('fact_comp_name', companySetup.fact_info.fact_comp_name);
                setup.append('fact_address', companySetup.fact_info.fact_address);
                setup.append('fact_phone', companySetup.fact_info.fact_phone);
                setup.append('fact_fax', companySetup.fact_info.fact_fax);
                setup.append('address', values.buildingAddress);
                setup.append('country_id', 233);
                setup.append('state_id', values.state);
                setup.append('city_id', values.city);
                setup.append('zip_code', values.zip);
                setup.append('email', values.email);
                setup.append('mc_no', values.mc);
                setup.append('ein_no', values.ein);
                setup.append('dot_no', values.dot);
                setup.append('bank_name', values.bankName);
                setup.append('acc_no', values.bankAc);
                setup.append('phone', values.cPhone);
                setup.append('phone_ext', values.ext);

                const { data } = await axios.post(`/v1/api/users/company/setup/`, setup, config);

                dispatch(openSuccessSnackbar('Organization Details updated successfully'));
                navigate('/company/profile', { replace: true });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrorAdd(error.response.data);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.',
        error: Boolean(touched.phone && errors.phone),
        helperText: touched.phone && errors.phone
    };

    // const url = companySetup && URL.createObjectURL(companySetup.logo);

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography
                                sx={{
                                    mt: '5',
                                    width: '375px',
                                    height: '40px',
                                    fontWeight: '600',
                                    fontSize: '32px',
                                    fontFamily: 'Lexend'
                                }}
                            >
                                Organization Details
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    {/* <LoadingFormButton type="button" btnText="Edit" onClick={() => editOrganization} /> */}
                                    <BackFormButton />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            {/* Customer */}
                            {/* General Info */}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    Organization Information
                                </Typography>
                            </Grid>
                            {/* User Name */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Company Name"
                                        {...getFieldProps('companyName')}
                                        error={Boolean((touched.companyName && errors.companyName) || (errorAdd && errorAdd.dsp_company_name))}
                                        helperText={(touched.companyName && errors.companyName) || (errorAdd && errorAdd.dsp_company_name)}
                                    />
                                )}
                            </Grid>
                            {/* Weight */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Buliding Address"
                                        {...getFieldProps('buildingAddress')}
                                        error={Boolean((touched.buildingAddress && errors.buildingAddress) || (errorAdd && errorAdd.dsp_company_name))}
                                        helperText={(touched.buildingAddress && errors.buildingAddress) || (errorAdd && errorAdd.billing_address)}
                                    />
                                )}
                            </Grid>

                            {/* State */}
                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        select
                                        type="text"
                                        label="State"
                                        value={values.state}
                                        onChange={(e) => changeStates(e.target.value)}
                                        error={Boolean((touched.state && errors.state) || (errorAdd && errorAdd.state_id))}
                                        helperText={(touched.state && errors.state) || (errorAdd && errorAdd.state_id)}
                                    >
                                        <MenuItem value={''}>Select State</MenuItem>
                                        {stateList &&
                                            stateList.map((i) => (
                                                <MenuItem key={i.id} value={i.id}>
                                                    {i.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                )}
                            </Grid>

                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                {loading || cityLoading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        select
                                        type="text"
                                        label="City"
                                        value={values.city}
                                        onChange={(e) => setFieldValue('city', e.target.value)}
                                        error={Boolean(touched.city && errors.city)}
                                        helperText={touched.city && errors.city}
                                    >
                                        <MenuItem value={''}>Select City</MenuItem>
                                        {cityList &&
                                            cityList.map((i) => (
                                                <MenuItem key={i.id} value={i.id}>
                                                    {i.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                )}
                            </Grid>

                            {/* millage */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Zip"
                                        inputProps={{ maxLength: 5 }}
                                        value={values.zip}
                                        onChange={(e) => setFieldValue('zip', !isNaN(e.target.value) ? e.target.value : '')}
                                        error={Boolean((touched.zip && errors.zip) || (errorAdd && errorAdd.zip_code))}
                                        helperText={(touched.zip && errors.zip) || (errorAdd && errorAdd.zip_code)}
                                    ></TextField>
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="MC #"
                                        {...getFieldProps('mc')}
                                        error={Boolean((touched.mc && errors.mc) || (errorAdd && errorAdd.mc_no))}
                                        helperText={(touched.mc && errors.mc) || (errorAdd && errorAdd.mc_no)}
                                    />
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField fullWidth className="text-input" color="orange" type="text" label="EIN" {...getFieldProps('ein')} />
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="DOT #"
                                        {...getFieldProps('dot')}
                                        error={Boolean((touched.dot && errors.dot) || (errorAdd && errorAdd.dot_no))}
                                        helperText={(touched.dot && errors.dot) || (errorAdd && errorAdd.dot_no)}
                                    />
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Email Address"
                                        {...getFieldProps('email')}
                                        error={Boolean((touched.email && errors.email) || (errorAdd && errorAdd.email))}
                                        helperText={(touched.email && errors.email) || (errorAdd && errorAdd.email)}
                                    />
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked name="gilad" />} label="Use Building Address as billing Address" />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </SubCard>
                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    Bank Information
                                </Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField fullWidth className="text-input" color="orange" type="text" label="Bank Name" {...getFieldProps('bankName')} />
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField fullWidth className="text-input" color="orange" type="text" label="Bank A/C #" {...getFieldProps('bankAc')} />
                                )}
                            </Grid>
                        </Grid>
                    </SubCard>
                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    Contact Information
                                </Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <PatternFormat
                                        format="###-###-####"
                                        allowEmptyFormatting
                                        mask="_"
                                        value={values.cPhone}
                                        onValueChange={(value) => setFieldValue('cPhone', value.value)}
                                        customInput={TextField}
                                        {...textFieldProps}
                                    />
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField fullWidth className="text-input" color="orange" type="text" label="Ext." {...getFieldProps('ext')} />
                                )}
                            </Grid>
                        </Grid>
                    </SubCard>

                    <Box sx={{ mt: 2 }} align="center">
                        <AnimateButton>
                            <LoadingFormButton color="orange" type="submit" btnText="Save" load={isSubmitting} />
                        </AnimateButton>
                    </Box>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

OrganizationForm.propTypes = {
    name: PropTypes.string,
    companySetup: PropTypes.any
};

export default OrganizationForm;
