import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import RemoveIcon from '@mui/icons-material/Remove';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, MenuItem, Skeleton, TextField, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { queryClient } from 'index';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import './expenses.css';

const initialValues = {
    customer: '',
    payment_date: '',
    payment_method: '',
    refference_no: '',
    deposit_to: '',
    memo: '',
    amount_received: '',
    attachment: null,
    received_payment_item: [
        {
            invoice: '',
            description: '',
            due_date: format(new Date(), 'yyyy-MM-dd'),
            original_amount: '',
            open_balance: '',
            payment: '',
            open: false
        }
    ]
};
const Schema = Yup.object().shape({
    customer: Yup.string().required('Customer is required'),
    payment_date: Yup.date().typeError('Invalid date').required('Payment date is required'),
    payment_method: Yup.string().required('Payment method is required'),
    // refference_no: Yup.string().required('Refference no is required'),
    // deposit_to: Yup.string().required('Deposit to no is required'),
    // memo: Yup.string().required('Memo is required'),
    // amount_received: Yup.string().required('Amount received is required'),
    // attachment: Yup.mixed().required('attachment is required'),
    received_payment_item: Yup.array().of(
        Yup.object().shape({
            // description: Yup.string().required('Description is required'),
            // due_date: Yup.date().typeError('Invalid date').required('Due date is required'),
            original_amount: Yup.string().required('Original amount is required')
            // open_balance: Yup.string().required('Open balance is required'),
            // payment: Yup.string().required('Payment is required')
        })
    )
});

// Initial values for Formik form validation
function AddReceivedPaymentModal({ addReceivedPaymentDrawer, setAddReceivedPaymentDrawer, changeAddReceivedPaymentStatus, search, page, rowsPerPage }) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const carrierId = loginInfoFromStorage.company_id || 0;

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

    const addAmountDescription = () => {
        formik.setValues((prev) => ({
            ...prev,
            received_payment_item: [
                ...prev.received_payment_item,
                {
                    invoice: '',
                    description: '',
                    due_date: '',
                    original_amount: '',
                    open_balance: '',
                    payment: '',
                    open: false
                }
            ]
        }));
    };

    const addReceivedPayment = async (formData) => {
        return await axios.post('/v1/api/accounting/received-payment/', formData, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
    };

    const getCustomerList = async () => {
        const response = await axios.get('/v1/api/accounting/received-payment/customer-list/', {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response.data;
    };

    const { data: customerList, isLoading: customerLoading } = useQuery({
        queryFn: getCustomerList,
        queryKey: ['customer-list'],
        staleTime: 1000 * 60 * 60 * 5
    });

    const {
        mutate: addReceivedPaymentMutate,
        isPending: postReceivedPaymentPending,
        status: postReceivedPaymentStatus
    } = useMutation({
        mutationFn: addReceivedPayment,
        onSuccess: async () => {
            toast.success('Received payment added successfully');
            setAddReceivedPaymentDrawer(false);
            formik.resetForm();
            await queryClient.invalidateQueries({ queryKey: ['received-payment', search, page, rowsPerPage] });
            return await queryClient.invalidateQueries({ queryKey: ['customer-list'] });
        },
        onError: (error) => {
            if (error.response.data) {
                Object.keys(error.response.data).forEach((field) => {
                    formik.setFieldError(field, error.response.data[field][0]);
                });
            } else {
                toast.error('An unexpected error occurred, please contact with support');
            }
        }
    });

    useEffect(() => {
        changeAddReceivedPaymentStatus(postReceivedPaymentStatus);
    }, [postReceivedPaymentStatus]);

    const removeAmountDescription = (index) => {
        formik.setValues((prev) => ({
            ...prev,
            received_payment_item: prev.received_payment_item.filter((_amount, i) => i !== index)
        }));
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Schema,
        onSubmit: (values, _action) => {
            // Deep clone the values object
            const data = JSON.parse(JSON.stringify(values));

            // Map over the received_payment_item array and transform each item
            data.received_payment_item = data.received_payment_item.map((amountDes) => {
                const new_amount = { ...amountDes };
                delete new_amount['submitted_date'];
                delete new_amount['load'];

                // Remove keys with empty values from the new_amount object
                for (const key in new_amount) {
                    if (new_amount[key] === null || new_amount[key] === undefined || new_amount[key] === '') {
                        delete new_amount[key];
                    }
                }
                if (new_amount.due_date) {
                    new_amount.due_date = format(new Date(new_amount.due_date), 'yyyy-MM-dd');
                }
                return new_amount;
            });

            data.amount_received = values.received_payment_item.reduce((sum, item) => sum + parseFloat(item.payment || 0), 0).toFixed(2);
            // Remove keys with empty values from the data object
            for (const key in data) {
                if (data[key] === null || data[key] === undefined || data[key] === '') {
                    delete data[key];
                }
            }

            if (values.attachment) {
                data.attachment = values.attachment;
            }

            if (data.payment_date) {
                data.payment_date = format(new Date(data.payment_date), 'yyyy-MM-dd');
            }
            if (data.received_payment_item.length === 0) {
                toast.error('Invoices are empty');
                return;
            }
            data.received_payment_item = JSON.stringify(data.received_payment_item);
            // Create a new FormData object
            const formData = new FormData();

            // Append the transformed data to the formData object
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            addReceivedPaymentMutate(formData);
        }
    });

    const getCustomerInvoiceList = async (customer_id) => {
        const response = await axios.get(`/v1/api/accounting/received-payment/customer-invoices/?customer_id=${customer_id}`, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response.data;
    };

    const { data: invoiceList, isLoading: invoiceListLoading } = useQuery({
        queryFn: () => getCustomerInvoiceList(formik.values.customer),
        queryKey: ['customer-invoices', formik.values.customer],
        staleTime: 1000 * 60 * 60 * 5,
        enabled: !!(formik.values.customer !== '')
    });

    useEffect(() => {
        formik.setValues((prev) => ({
            ...prev,
            amount_received: invoiceList?.reduce((sum, invoice) => sum + parseFloat(invoice.tot_amount || 0), 0).toFixed(2),
            received_payment_item:
                invoiceList?.map((invoice) => ({
                    invoice: invoice.dsp_load_inv_id,
                    description: `invoice ${invoice.dsp_load_inv_id}`,
                    load: invoice.load_id__load,
                    submitted_date: format(new Date(invoice.updated_at || new Date()), 'yyyy-MM-dd'),
                    due_date: format(new Date(), 'yyyy-MM-dd'),
                    original_amount: invoice.tot_amount || 0,
                    open_balance: 0,
                    payment: invoice.tot_amount || 0,
                    open: false
                })) || initialValues.received_payment_item
        }));
    }, [invoiceList]);

    const totalAmount = useMemo(() => {
        return formik.values.received_payment_item.reduce((sum, item) => sum + parseFloat(item.payment || 0), 0).toFixed(2);
    }, [formik.values.received_payment_item]);

    return (
        <Drawer
            open={addReceivedPaymentDrawer}
            anchor="right"
            onClose={() => {
                setAddReceivedPaymentDrawer(false);
            }}
        >
            <div style={{ width: isMobile ? '100vw' : '80vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Add new received payment
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setAddReceivedPaymentDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20, alignItems: 'center' }}>
                        {customerLoading ? (
                            <Skeleton height={80} width={'100%'} />
                        ) : (
                            <TextField
                                select
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="customer"
                                label="Customer"
                                value={formik.values.customer}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.customer && formik.errors.customer)}
                                helperText={formik.touched.customer && formik.errors.customer}
                            >
                                <MenuItem value="">{customerList?.length > 0 ? 'Select a customer' : 'No customer available with invoice submitted'}</MenuItem>
                                {customerList?.map((customer) => (
                                    <MenuItem key={customer.dsp_customer_id} value={customer.dsp_customer_id}>
                                        {customer.customer_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                className="text-input"
                                label="Payment Date"
                                openTo="day"
                                views={['year', 'month', 'day']}
                                value={formik.values.payment_date}
                                onChange={(newValue) => {
                                    formik.setFieldValue('payment_date', newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        name="payment_date"
                                        color="orange"
                                        fullWidth
                                        {...params}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.payment_date && formik.errors.payment_date)}
                                        helperText={formik.touched.payment_date && formik.errors.payment_date}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <TextField
                            fullWidth
                            select
                            className="text-input"
                            color="orange"
                            name="payment_method"
                            label="Payment Method"
                            value={formik.values.payment_method}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.payment_method && formik.errors.payment_method)}
                            helperText={formik.touched.payment_method && formik.errors.payment_method}
                        >
                            <MenuItem value={'Bank'}>Bank</MenuItem>
                            <MenuItem value={'Credit Card'}>Credit Card</MenuItem>
                        </TextField>
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="refference_no"
                            label="Refference No"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.refference_no}
                            error={Boolean(formik.touched.refference_no && formik.errors.refference_no)}
                            helperText={formik.touched.refference_no && formik.errors.refference_no}
                        />
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="deposit_to"
                            label="Deposit To"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.deposit_to}
                            error={Boolean(formik.touched.deposit_to && formik.errors.deposit_to)}
                            helperText={formik.touched.deposit_to && formik.errors.deposit_to}
                        />
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="memo"
                            label="Memo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.memo}
                            error={Boolean(formik.touched.memo && formik.errors.memo)}
                            helperText={formik.touched.memo && formik.errors.memo}
                        />
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="amount_received"
                            label="Amount Received"
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={totalAmount}
                            error={Boolean(formik.touched.amount_received && formik.errors.amount_received)}
                            helperText={formik.touched.amount_received && formik.errors.amount_received}
                        />
                    </div>

                    {isMobile || isTablet ? (
                        <>
                            {formik.values.received_payment_item.map((amountDes, index) => (
                                <Card key={index} sx={{ marginBottom: '1.5rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '15px' }}>
                                    <CardContent>
                                        <AnimatePresence>
                                            <motion.div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'column',
                                                    gap: '.4rem'
                                                }}
                                                initial={{ scale: 1, opacity: 0 }}
                                                animate={{ scale: 1.05, opacity: 1 }}
                                                exit={{ scale: 1, opacity: 0.5 }}
                                            >
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <AddIcon
                                                        sx={{
                                                            backgroundColor: 'green',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            fontSize: '20px'
                                                        }}
                                                        // onClick={() => addAmountDescription()}
                                                    />

                                                    <button type="button" disabled style={{ outline: 'none', background: 'transparent', border: 'none' }}>
                                                        <RemoveIcon
                                                            sx={{
                                                                backgroundColor: formik.values.received_payment_item.length == 1 ? '#ff8995' : 'red',
                                                                color: 'white',
                                                                borderRadius: '50%',
                                                                fontSize: '20px'
                                                            }}
                                                            onClick={() => {
                                                                if (formik.values.received_payment_item.length > 1) {
                                                                    removeAmountDescription(index);
                                                                } else {
                                                                    formik.setValues((prev) => ({
                                                                        ...prev,
                                                                        received_payment_item: initialValues.received_payment_item
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                    </button>
                                                </Box>
                                                <Box sx={{ clear: 'both' }} />
                                                <Typography
                                                    sx={{ marginBottom: '.5rem', display: 'flex', gap: '.4rem', alignItems: 'center', whiteSpace: 'nowrap' }}
                                                >
                                                    Description :
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: 'fit-content',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            onBlur={formik.handleBlur}
                                                            name={`received_payment_item[${index}].description`}
                                                            value={amountDes.description}
                                                            onChange={formik.handleChange}
                                                            className={
                                                                formik.touched?.received_payment_item?.[index]?.description &&
                                                                formik.errors?.received_payment_item?.[index]?.description &&
                                                                'form-error'
                                                            }
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                border: '2px solid #80808052',
                                                                borderRight: '2px solid #80808052',
                                                                outline: 0,
                                                                padding: '5px 0',
                                                                height: '100%'
                                                            }}
                                                            placeholder="Enter description"
                                                        />
                                                    </div>
                                                </Typography>

                                                <Typography
                                                    sx={{ marginBottom: '.5rem', display: 'flex', gap: '.4rem', alignItems: 'center', whiteSpace: 'nowrap' }}
                                                >
                                                    Load :{' '}
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        {amountDes.load}
                                                    </div>
                                                </Typography>

                                                <Typography sx={{ marginBottom: '.5rem', display: 'flex', gap: '.4rem', alignItems: 'center' }}>
                                                    Submitted date : {amountDes.submitted_date}
                                                </Typography>

                                                <Typography
                                                    sx={{ marginBottom: '.5rem', display: 'flex', gap: '.4rem', alignItems: 'center', whiteSpace: 'nowrap' }}
                                                >
                                                    Received Date :{' '}
                                                    <input
                                                        type="date"
                                                        onBlur={formik.handleBlur}
                                                        name={`received_payment_item[${index}].due_date`}
                                                        value={amountDes.due_date}
                                                        className={
                                                            formik.touched?.received_payment_item?.[index]?.due_date &&
                                                            formik.errors?.received_payment_item?.[index]?.due_date &&
                                                            'form-error'
                                                        }
                                                        onChange={formik.handleChange}
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter received date"
                                                    />
                                                </Typography>

                                                <Typography sx={{ marginBottom: '.5rem', display: 'flex', gap: '.4rem', alignItems: 'center' }}>
                                                    Original Amount : {amountDes.original_amount}
                                                </Typography>

                                                <Typography sx={{ marginBottom: '.5rem', display: 'flex', gap: '.4rem', alignItems: 'center' }}>
                                                    Open Balance : {amountDes.open_balance}
                                                </Typography>

                                                <Typography sx={{ marginBottom: '.5rem', display: 'flex', gap: '.4rem', alignItems: 'center' }}>
                                                    Payment : {amountDes.payment}
                                                </Typography>
                                            </motion.div>
                                        </AnimatePresence>
                                    </CardContent>
                                </Card>
                            ))}
                        </>
                    ) : (
                        <div>
                            <div style={{ borderBottom: '1px solid #80808052', display: 'flex', justifyContent: 'space-evenly', fontWeight: '600' }}>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}></div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}>#</div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Description</div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Load</div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Submitted date</div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Received Date</div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Original Amount</div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Open Balance</div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Payment</div>
                                <div style={{ textAlign: 'center', width: '10%' }}></div>
                            </div>
                            {invoiceListLoading ? (
                                <>
                                    <Skeleton width={'100%'} height={40} />
                                    <Skeleton width={'100%'} height={40} />
                                    <Skeleton width={'100%'} height={40} />
                                    <Skeleton width={'100%'} height={40} />
                                </>
                            ) : (
                                <AnimatePresence>
                                    {formik.values.received_payment_item.map((amountDes, index) => (
                                        <div key={index}>
                                            <AnimatePresence>
                                                {amountDes.open ? (
                                                    <motion.div
                                                        style={{
                                                            borderBottom: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'space-evenly'
                                                        }}
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0.5 }}
                                                    >
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '10%',
                                                                cursor: 'pointer'
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                        if (i === index) {
                                                                            return { ...amount, open: !amount.open };
                                                                        } else {
                                                                            return amount;
                                                                        }
                                                                    })
                                                                }))
                                                            }
                                                            onKeyDown={(e) => {}}
                                                        >
                                                            <ViewCompactIcon sx={{ cursor: 'pointer' }} />
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '10%',
                                                                cursor: 'pointer'
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                        if (i === index) {
                                                                            return { ...amount, open: !amount.open };
                                                                        } else {
                                                                            return amount;
                                                                        }
                                                                    })
                                                                }))
                                                            }
                                                            onKeyDown={(e) => {}}
                                                        >
                                                            {index + 1}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                        if (i === index) {
                                                                            return { ...amount, open: !amount.open };
                                                                        } else {
                                                                            return amount;
                                                                        }
                                                                    })
                                                                }))
                                                            }
                                                            onKeyDown={(e) => {}}
                                                        >
                                                            {amountDes.description}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                        if (i === index) {
                                                                            return { ...amount, open: !amount.open };
                                                                        } else {
                                                                            return amount;
                                                                        }
                                                                    })
                                                                }))
                                                            }
                                                            onKeyDown={(e) => {}}
                                                        >
                                                            {amountDes.load}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                        if (i === index) {
                                                                            return { ...amount, open: !amount.open };
                                                                        } else {
                                                                            return amount;
                                                                        }
                                                                    })
                                                                }))
                                                            }
                                                            onKeyDown={(e) => {}}
                                                        >
                                                            {amountDes.submitted_date}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                        if (i === index) {
                                                                            return { ...amount, open: !amount.open };
                                                                        } else {
                                                                            return amount;
                                                                        }
                                                                    })
                                                                }))
                                                            }
                                                            onKeyDown={(e) => {}}
                                                        >
                                                            {amountDes.due_date}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                        if (i === index) {
                                                                            return { ...amount, open: !amount.open };
                                                                        } else {
                                                                            return amount;
                                                                        }
                                                                    })
                                                                }))
                                                            }
                                                            onKeyDown={(e) => {}}
                                                        >
                                                            ${amountDes.original_amount}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                        if (i === index) {
                                                                            return { ...amount, open: !amount.open };
                                                                        } else {
                                                                            return amount;
                                                                        }
                                                                    })
                                                                }))
                                                            }
                                                            onKeyDown={(e) => {}}
                                                        >
                                                            ${Number(amountDes.original_amount) - Number(amountDes.payment)}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                        if (i === index) {
                                                                            return { ...amount, open: !amount.open };
                                                                        } else {
                                                                            return amount;
                                                                        }
                                                                    })
                                                                }))
                                                            }
                                                            onKeyDown={(e) => {}}
                                                        >
                                                            ${amountDes.payment}
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }}>
                                                            <RemoveIcon
                                                                sx={{
                                                                    backgroundColor: formik.values.received_payment_item.length == 1 ? '#ff8995' : 'red',
                                                                    color: 'white',
                                                                    borderRadius: '50%',
                                                                    fontSize: '20px'
                                                                }}
                                                                onClick={() => {
                                                                    if (formik.values.received_payment_item.length > 1) {
                                                                        removeAmountDescription(index);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </motion.div>
                                                ) : (
                                                    <motion.div
                                                        style={{
                                                            borderBottom: '1px solid #80808052',
                                                            borderTop: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'space-evenly'
                                                        }}
                                                        initial={{ scale: 1, opacity: 0 }}
                                                        animate={{ scale: 1.05, opacity: 1 }}
                                                        exit={{ scale: 1, opacity: 0.5 }}
                                                    >
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '10%',
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <AddIcon
                                                                sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%', fontSize: '20px' }}
                                                                // onClick={() => addAmountDescription()}
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '10%',
                                                                cursor: 'pointer'
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    received_payment_item: prev.received_payment_item.map((amount, i) => {
                                                                        if (i === index) {
                                                                            return { ...amount, open: !amount.open };
                                                                        } else {
                                                                            return amount;
                                                                        }
                                                                    })
                                                                }))
                                                            }
                                                            onKeyDown={(e) => {}}
                                                        >
                                                            {index + 1}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                        >
                                                            <input
                                                                type="text"
                                                                onBlur={formik.handleBlur}
                                                                name={`received_payment_item[${index}].description`}
                                                                value={amountDes.description}
                                                                onChange={formik.handleChange}
                                                                className={
                                                                    formik.touched?.received_payment_item?.[index]?.description &&
                                                                    formik.errors?.received_payment_item?.[index]?.description &&
                                                                    'form-error'
                                                                }
                                                                style={{
                                                                    textAlign: 'center',
                                                                    width: '100%',
                                                                    border: '2px solid #80808052',
                                                                    borderRight: '2px solid #80808052',
                                                                    outline: 0,
                                                                    padding: '5px 0',
                                                                    height: '100%'
                                                                }}
                                                                placeholder="Enter description"
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                        >
                                                            {amountDes.load}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                        >
                                                            {amountDes.submitted_date}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                        >
                                                            <input
                                                                type="date"
                                                                onBlur={formik.handleBlur}
                                                                name={`received_payment_item[${index}].due_date`}
                                                                value={amountDes.due_date}
                                                                className={
                                                                    formik.touched?.received_payment_item?.[index]?.due_date &&
                                                                    formik.errors?.received_payment_item?.[index]?.due_date &&
                                                                    'form-error'
                                                                }
                                                                onChange={formik.handleChange}
                                                                style={{
                                                                    textAlign: 'center',
                                                                    width: '100%',
                                                                    border: '2px solid #80808052',
                                                                    outline: 0,
                                                                    padding: '5px 0',
                                                                    height: '100%'
                                                                }}
                                                                placeholder="Enter received date"
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                        >
                                                            {amountDes.original_amount}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                        >
                                                            {amountDes.open_balance}
                                                        </div>
                                                        <div
                                                            style={{
                                                                borderRight: '1px solid #80808052',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '25%',
                                                                position: 'relative'
                                                            }}
                                                        >
                                                            {amountDes.payment}
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }}>
                                                            <button
                                                                type="button"
                                                                disabled
                                                                style={{ outline: 'none', background: 'transparent', border: 'none' }}
                                                            >
                                                                <RemoveIcon
                                                                    sx={{
                                                                        backgroundColor: formik.values.received_payment_item.length == 1 ? '#ff8995' : 'red',
                                                                        color: 'white',
                                                                        borderRadius: '50%',
                                                                        fontSize: '20px'
                                                                    }}
                                                                    onClick={() => {
                                                                        if (formik.values.received_payment_item.length > 1) {
                                                                            removeAmountDescription(index);
                                                                        } else {
                                                                            formik.setValues((prev) => ({
                                                                                ...prev,
                                                                                received_payment_item: initialValues.received_payment_item
                                                                            }));
                                                                        }
                                                                    }}
                                                                />
                                                            </button>
                                                        </div>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                    ))}
                                </AnimatePresence>
                            )}
                            {formik.values.received_payment_item.length === 0 && (
                                <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '10px' }}>No invoice available</div>
                            )}
                        </div>
                    )}

                    {isMobile ? (
                        <div style={{ display: 'flex', justifyContent: 'end', gap: 50, fontSize: 20, marginTop: 20, marginRight: 0 }}>
                            <strong>Total</strong>
                            <strong>${totalAmount}</strong>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'end', gap: 100, fontSize: 20, marginTop: 20, marginRight: { xs: 0, sm: 50 } }}>
                            <strong>Total</strong>
                            <strong>${totalAmount}</strong>
                        </div>
                    )}

                    <Box
                        xs={{
                            marginBottom: 20,

                            width: { xs: '100%', sm: '50%' }
                        }}
                    >
                        <Typography variant="h4" sx={{ marginBottom: '.5rem', marginTop: '2rem' }}>
                            Attachment
                        </Typography>
                        <input
                            type="file"
                            onChange={(e) => {
                                formik.setFieldValue('attachment', e.target.files[0]);
                            }}
                            accept=".pdf, .png, .jpg, .jpeg"
                            style={{ display: 'none' }}
                            id="file-input"
                        />
                        <label htmlFor="file-input">
                            <Box
                                sx={{
                                    border: '2px dashed orange',
                                    borderRadius: '5px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    width: { xs: '100%', sm: '50%' },
                                    cursor: 'pointer',
                                    backgroundColor: '#f9f9f9'
                                }}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    formik.setFieldValue('attachment', e.dataTransfer.files[0]);
                                }}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                    <DownloadIcon />
                                    <Typography variant="h6" gutterBottom>
                                        <span style={{ fontWeight: 'bold' }}>Choose a file</span> or drag it here
                                    </Typography>
                                </div>
                                <span style={{ color: 'green' }}>{formik.values.attachment && formik.values.attachment.name}</span>
                            </Box>
                        </label>
                    </Box>
                    <LoadingButton
                        loading={postReceivedPaymentPending}
                        type="submit"
                        fullWidth
                        variant="contained"
                        style={{ backgroundColor: '#EB812E' }}
                        sx={{ marginTop: '1rem' }}
                    >
                        Save received payment
                    </LoadingButton>
                </form>
            </div>
        </Drawer>
    );
}

export default AddReceivedPaymentModal;
