import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { MenuItem, TextField, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useFormik } from 'formik';
import { queryClient } from 'index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import './expenses.css';
import { useState } from 'react';

// Initial values for Formik form validation
const initialValues = {
    account_type: '',
    detail_type: '',
    description: ''
};
const validationSchema = Yup.object().shape({
    account_type: Yup.string().required('Account type is required'),
    detail_type: Yup.string().required('Detail type is required'),
    // description: Yup.string().required('Description is required')
});

function AddPaymentAccountModal({ addPayementAccountDrawer, setAddPayementAccountDrawer }) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const isMobile = useMediaQuery('(max-width:600px)');

    const addPayementAccount = async (formData) => {
        const response = await axios.post('/v1/api/accounting/payment-account-types/', formData, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response;
    };

    const {
        mutate: addPayementAccountMutate,
        isPending: postPayementAccountPending
    } = useMutation({
        mutationFn: addPayementAccount,
        onSuccess: async () => {
            toast.success('Payment account added successfully');
            setAddPayementAccountDrawer(false);
            formik.resetForm();
            return await queryClient.invalidateQueries({ queryKey: ['paymentAccountList'] });
        },
        onError: (error) => {
            if (error.response.data) {
                Object.keys(error.response.data).forEach((field) => {
                    formik.setFieldError(field, error.response.data[field][0]);
                });
            } else {
                toast.error('An unexpected error occurred, please contact with support');
            }
        }
    });

    const [isAccountTypePending, setIsAccountTypePending] = useState(false)
    const checkAccountTypeValidity = async (account_type) => {

        try {
            setIsAccountTypePending(true)
            const response = await axios.post(
                '/v1/api/accounting/payment-account-types/check_account_type/',
                { account_type },
                {
                    headers: {
                        'Content-type': 'multipart/form-data',
                        Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                    }
                }
            );
            if (response.status == 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }finally{
            setIsAccountTypePending(false)
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, action) => {
            // Deep clone the values object
            const data = JSON.parse(JSON.stringify(values));

            data["dispatcher"] = loginInfoFromStorage.company_id
            // Create a new FormData object
            const formData = new FormData();


            // Append the transformed data to the formData object
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            addPayementAccountMutate(formData);
        }
    });

    return (
        <Drawer
            open={addPayementAccountDrawer}
            anchor="right"
            onClose={() => {
                setAddPayementAccountDrawer(false);
            }}
        >
            <div style={{ width: isMobile ? '100vw' : '50vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Add new payment account
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setAddPayementAccountDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <div style={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                select
                                className="text-input"
                                color="orange"
                                name="account_type"
                                label="Account Type"
                                value={formik.values.account_type}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.account_type && formik.errors.account_type)}
                                helperText={formik.touched.account_type && formik.errors.account_type}
                            >
                                <MenuItem value={'Other Current Assets'}>Other Current Assets</MenuItem>
                                <MenuItem value={'Bank'}>Bank</MenuItem>
                                <MenuItem value={'Credit Card'}>Credit Card</MenuItem>
                            </TextField>
                        </div>
                        <div style={{ width: '100%', position: 'relative' }}>
                            <TextField
                                fullWidth
                                select
                                className="text-input"
                                color="orange"
                                name="detail_type"
                                label="Detail Type"
                                value={formik.values.detail_type}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.detail_type && formik.errors.detail_type)}
                                helperText={formik.touched.detail_type && formik.errors.detail_type}
                            >
                                <MenuItem value={'Cash on hand'}>Cash on hand</MenuItem>
                                <MenuItem value={'Checking'}>Checking</MenuItem>
                                <MenuItem value={'Money Market'}>Money Market</MenuItem>
                                <MenuItem value={'Rents Held in Trust'}>Rents Held in Trust</MenuItem>
                                <MenuItem value={'Savings'}>Savings</MenuItem>
                                <MenuItem value={'Trust account'}>Trust account</MenuItem>
                            </TextField>
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="description"
                            label="Description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.description && formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                        />
                    </div>

                    <LoadingButton loading={postPayementAccountPending || isAccountTypePending} type="submit" fullWidth variant="contained" style={{ backgroundColor: '#EB812E', marginTop: 30 }}>
                        Save bill
                    </LoadingButton>
                </form>
            </div>
        </Drawer>
    );
}

export default AddPaymentAccountModal;
