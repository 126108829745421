import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { TextField, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useFormik } from 'formik';
import { queryClient } from 'index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import './expenses.css';

// Initial values for Formik form validation
const initialValues = {
    name: ''
};
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
});

function AddTermsModal({ addTermsDrawer, setAddTermsDrawer }) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const isMobile = useMediaQuery('(max-width:600px)');

    const addTerms = async (formData) => {
        const response = await axios.post('/v1/api/accounting/terms/', formData, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response;
    };

    const { mutate: addTermsMutate, isPending: postTermsPending } = useMutation({
        mutationFn: addTerms,
        onSuccess: async () => {
            toast.success('Terms added successfully');
            setAddTermsDrawer(false);
            formik.resetForm();
            return await queryClient.invalidateQueries({ queryKey: ['terms'] });
        },
        onError: (error) => {
            if (error.response.data) {
                Object.keys(error.response.data).forEach((field) => {
                    formik.setFieldError(field, error.response.data[field][0]);
                });
            } else {
                toast.error('An unexpected error occurred, please contact with support');
            }
        }
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, _action) => {
            // Deep clone the values object
            const data = JSON.parse(JSON.stringify(values));

            data['dispatcher'] = loginInfoFromStorage.company_id;
            // Create a new FormData object
            const formData = new FormData();

            // Append the transformed data to the formData object
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            addTermsMutate(formData);
        }
    });

    return (
        <Drawer
            open={addTermsDrawer}
            anchor="right"
            onClose={() => {
                setAddTermsDrawer(false);
            }}
        >
            <div style={{ width: isMobile ? '100vw' : '50vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Add new terms
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setAddTermsDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="name"
                            label="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.name && formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </div>

                    <LoadingButton loading={postTermsPending} type="submit" fullWidth variant="contained" style={{ backgroundColor: '#EB812E', marginTop: 30 }}>
                        Save terms
                    </LoadingButton>
                </form>
            </div>
        </Drawer>
    );
}

export default AddTermsModal;
