import { format } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material-ui
import { Autocomplete, FormControl, FormHelperText, Grid, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import * as Yup from 'yup';

import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/system';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import SubCard from 'ui-component/cards/SubCard';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const AdminTrailerForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.user_type === 'super_dispatcher_admin' ? '' : loginInfoFromStorage.company_id;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [driverList, setDriverList] = useState();
    const [cmpList, setCmpList] = useState();
    const [loading1, setLoading1] = useState(false);
    const [loading, setLoading] = useState();
    const [trailerData, setTrailerData] = useState();
    const [errorData, setErrorData] = useState();
    const { id } = useParams();
    const [options, setOptions] = useState([]);
    const [cmpOptions, setCmpOptions] = useState([]);
    const [driverValue, setDriverValue] = useState(options[0]);
    const [inputValue, setInputValue] = useState('');
    const [inputCmpValue, setInputCmpValue] = useState('');
    useEffect(() => {
        if (!cmpList) {
            fetchCompany();
        }
        if (!stateList) {
            fetchState();
        }

        if (id && stateList && !trailerData) {
            fetchTrailer(id);
        }

        if (id && trailerData && !cityList) {
            getCityList(trailerData.state_id);
        }

        if (id && trailerData && !driverList) {
            fetchDriver(trailerData.company_id);
        }
    }, [stateList, cmpList, driverList, trailerData, cityList, id]);

    const fetchTrailer = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/fleet/trailer/view/${id}/`, config);

            setTrailerData(data);

            setDriverValue(options[options.findIndex((i) => i.driver_id === data.driver_id)]);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchState = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const changeStates = (value) => {
        setFieldValue('state', value);

        //  setFieldValue('companyCity', '');
        getCityList(value);
    };

    const getCityList = async (id) => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);
            setLoading(false);
            setCityList(data);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchCompany = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/company/list?cmp_type=dispatcher&cmp_type=carrier&page=1&page_size=100&search=`, config);

            setCmpOptions(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchDriver = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/company/drivers/slim?company_id=${id}&page=1&page_size=100&search=`, config);
            setDriverList(data.results);
            setOptions(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const onChangeHandle = async (value) => {
        setLoading1(true);
        const { data } = await axios.get(`/v1/api/users/company/drivers/slim?company_id=${cid}&page=1&page_size=100&search=${value}`, config);
        setLoading1(false);
        setOptions(data.results);
    };

    const onCmpChangeHandle = async (value) => {
        setLoading1(true);
        const { data } = await axios.get(`/v1/api/users/company/list?page=1&page_size=100&search=${value}`, config);
        setLoading1(false);
        setCmpOptions(data.results);
    };

    const TrailerSchema = Yup.object().shape({
        registrationNo: Yup.string().required('Registration# is a required field'),
        trailerOwner: Yup.mixed().required('Trailer Owner is a required field'),
        cmpId: Yup.mixed().required('Company is a required field'),

        trilerNo: Yup.string().required('Triler# is a required field'),
        inServiceDate: Yup.string().required('Service Date is a required field'),
        state: Yup.number().required('State is a required field'),
        city: Yup.number().required('City is a required field')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            cmpId: id && trailerData && cmpOptions ? cmpOptions.filter(({ dsp_company_id }) => dsp_company_id === trailerData.company_id)[0] : '',
            registrationNo: id && trailerData ? trailerData.registration_no : '',
            trilerNo: id && trailerData ? trailerData.trailer_no : '',
            inServiceDate: id && trailerData ? trailerData.service_date : '',

            make: id && trailerData ? trailerData.make : '',
            model: id && trailerData ? trailerData.model : '',
            year: id && trailerData ? trailerData.make_year : moment(new Date()).format('YYYY'),
            vin: id && trailerData ? trailerData.vin_no : '',

            tailerType: id && trailerData ? trailerData.trailer_type : '',
            tailerLenght: id && trailerData ? trailerData.trailer_length : 53,
            trailerOwner: id && trailerData && driverList ? driverList.filter(({ driver_id }) => driver_id === trailerData.driver_id)[0] : '',
            note: id && trailerData ? trailerData.notes : '',
            state: id && trailerData ? trailerData.state_id : '',
            city: id && trailerData ? trailerData.city_id : '',

            isActive: id && trailerData ? trailerData.is_active : true
        },
        validationSchema: TrailerSchema,
        onSubmit: async () => {
            try {
                if (id) {
                    const { data } = await axios.put(
                        `/v1/api/fleet/trailer/view/${id}/`,
                        {
                            registration_no: values.registrationNo,
                            trailer_no: values.trilerNo,
                            service_date: moment(values.inServiceDate).format('YYYY-MM-DD'),
                            make: values.make,
                            model: values.model,
                            make_year: values.year ? format(new Date(values.year), 'yyyy') : format(new Date(), 'yyyy'),
                            vin_no: values.vin,
                            trailer_type: values.tailerType,
                            trailer_length: values.tailerLenght,
                            notes: values.note,
                            country_id: 233,
                            driver_id: values.trailerOwner ? values.trailerOwner.driver_id : '',
                            state_id: values.state,
                            city_id: values.city,
                            company_id: cid === '' ? values.cmpId.dsp_company_id : loginInfoFromStorage.company_id,
                            is_active: values.isActive,
                            is_edit: true
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar('Trailer updated successfully'));
                } else {
                    const { data } = await axios.post(
                        `/v1/api/fleet/trailer/add`,
                        {
                            registration_no: values.registrationNo,
                            trailer_no: values.trilerNo,
                            service_date: moment(values.inServiceDate).format('YYYY-MM-DD'),
                            make: values.make,
                            model: values.model,

                            make_year: values.year ? format(new Date(values.year), 'yyyy') : format(new Date(), 'yyyy'),
                            vin_no: values.vin,
                            trailer_type: values.tailerType,
                            trailer_length: values.tailerLenght,
                            notes: values.note,
                            country_id: 233,
                            driver_id: values.trailerOwner ? values.trailerOwner.driver_id : '',
                            state_id: values.state,
                            city_id: values.city,
                            company_id: cid === '' ? values.cmpId.dsp_company_id : loginInfoFromStorage.company_id,
                            is_active: values.isActive
                        },
                        config
                    );
                    dispatch(openSuccessSnackbar('Trailer added successfully'));
                }

                navigate('/fleet/trailer/all', { replace: true });
            } catch (error) {
                setErrorData(error.response.data);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                <Typography
                                    sx={{
                                        mt: '25px',
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '32px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    Trailer Details - Admin
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Stack direction="row" sx={{ mt: '20px' }} justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <LoadingFormButton load={isSubmitting && !errorData} type="submit" btnText="Save" />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>
                    <SubCard
                        sx={{
                            mt: 5,
                            borderRadius: '8px',
                            mb: 2,
                            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)'
                        }}
                    >
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Company
                                    </Typography>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                className="text-input"
                                                // multiple
                                                id="driver-set"
                                                options={cmpOptions}
                                                loading={loading1}
                                                isOptionEqualToValue={(option, value) => option.dsp_company_id === value.dsp_company_id}
                                                getOptionLabel={(option) => (option.dsp_company_name ? option.dsp_company_name : '')}
                                                inputValue={inputCmpValue}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputCmpValue(newInputValue);
                                                }}
                                                value={values.cmpId}
                                                onChange={(event, newValue) => {
                                                    // setDriverValue(newValue.name);

                                                    if (newValue != null) {
                                                        setFieldValue('cmpId', newValue);
                                                        fetchDriver(newValue.dsp_company_id);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="Company"
                                                        onChange={(ev) => {
                                                            if (ev.target.value !== '' || ev.target.value !== null || ev.target.value.length > 1) {
                                                                onCmpChangeHandle(ev.target.value);
                                                            }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            )
                                                        }}
                                                        error={Boolean(touched.cmpId && errors.cmpId)}
                                                        helperText={touched.cmpId && errors.cmpId}
                                                    />
                                                )}
                                            />
                                            <FormHelperText>Type atleast 2 characters to populate the list</FormHelperText>
                                        </FormControl>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* Basic Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Basic Information
                                    </Typography>
                                </Grid>
                                {/* Registration */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Registration #"
                                            {...getFieldProps('registrationNo')}
                                            error={Boolean(touched.registrationNo && errors.registrationNo)}
                                            helperText={touched.registrationNo && errors.registrationNo}
                                        />
                                    )}
                                </Grid>
                                {/* Weight */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Trailer #"
                                            {...getFieldProps('trilerNo')}
                                            error={Boolean(touched.trilerNo && errors.trilerNo)}
                                            helperText={touched.trilerNo && errors.trilerNo}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                                {/* revenue */}

                                {/* State */}
                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            select
                                            type="text"
                                            label="State"
                                            value={values.state}
                                            onChange={(e) => changeStates(e.target.value)}
                                            error={Boolean(touched.state && errors.state)}
                                            helperText={touched.state && errors.state}
                                        >
                                            {stateList &&
                                                stateList.map((i) => (
                                                    <MenuItem key={i.id} value={i.id}>
                                                        {i.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    )}
                                </Grid>

                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    {loading ? (
                                        <Skeleton variant="rectangular" width="100%" height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            select
                                            type="text"
                                            label="City"
                                            value={values.city}
                                            onChange={(e) => setFieldValue('city', e.target.value)}
                                            error={Boolean(touched.city && errors.city)}
                                            helperText={touched.city && errors.city}
                                            // error={Boolean(
                                            //     (touched.companyCountry && errors.companyCountry) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.country) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.country)
                                            // )}
                                            // helperText={
                                            //     (touched.companyCountry && errors.companyCountry) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.country) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.country)
                                            // }
                                        >
                                            <MenuItem disabled selected>
                                                Select City
                                            </MenuItem>
                                            {cityList &&
                                                cityList.map((i) => (
                                                    <MenuItem key={i.id} value={i.id}>
                                                        {i.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    )}
                                </Grid>

                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                label="Start Date"
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                value={values.inServiceDate}
                                                onChange={(newValue) => {
                                                    setFieldValue('inServiceDate', newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="text-input"
                                                        color="orange"
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(touched.inServiceDate && errors.inServiceDate)}
                                                        helperText={touched.inServiceDate && errors.inServiceDate}
                                                    />
                                                )}
                                                error={Boolean(touched.inServiceDate && errors.inServiceDate)}
                                                helperText={touched.inServiceDate && errors.inServiceDate}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* Vehicle Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Vehicle Information
                                    </Typography>
                                </Grid>
                                {/* make */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Make"
                                            {...getFieldProps('make')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                                {/* Model */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            type="text"
                                            className="text-input"
                                            color="orange"
                                            label="Model"
                                            {...getFieldProps('model')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                                {/* Year */}

                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                label="Year"
                                                openTo="day"
                                                views={['year']}
                                                value={values.year}
                                                onChange={(newValue) => {
                                                    setFieldValue('year', newValue);
                                                }}
                                                renderInput={(params) => <TextField className="text-input" color="orange" fullWidth {...params} error={null} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>
                                {/* vin */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Vin"
                                            {...getFieldProps('vin')}
                                            inputProps={{ maxLength: 17 }}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* Vehicle Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Trailer Information
                                    </Typography>
                                </Grid>
                                {/* Tailer Type */}
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            select
                                            label="Trailer Type"
                                            value={values.tailerType}
                                            onChange={(e) => setFieldValue('tailerType', e.target.value)}
                                        >
                                            <MenuItem value="">Select Trailer Type</MenuItem>
                                            <MenuItem value="Boat Trailer">Boat Trailer</MenuItem>
                                            <MenuItem value="Car Carrier">Car Carrier</MenuItem>
                                            <MenuItem value="Caravan">Caravan</MenuItem>
                                            <MenuItem value="Chemical Tank">Chemical Tank</MenuItem>
                                            <MenuItem value="Conestoga Trailer">Conestoga Trailer</MenuItem>
                                            <MenuItem value="Dolly">Dolly</MenuItem>
                                            <MenuItem value="Double Drop Deck">Double Drop Deck</MenuItem>
                                            <MenuItem value="Dry Van">Dry Van</MenuItem>
                                            <MenuItem value="Dump Trailer">Dump Trailer</MenuItem>
                                            <MenuItem value="Extendable Lowboy">Extendable Lowboy</MenuItem>
                                            <MenuItem value="Flatbed Truck">Flatbed Truck</MenuItem>
                                            <MenuItem value="Food Grade Tank">Food Grade Tank</MenuItem>
                                            <MenuItem value="Horse-trailer">Horse-trailer</MenuItem>
                                            <MenuItem value="Livestock Trailer">Livestock Trailer</MenuItem>
                                            <MenuItem value="Lowboy">Lowboy</MenuItem>
                                            <MenuItem value="Mega-trailer">Mega-trailer</MenuItem>
                                            <MenuItem value="Motorcycle Trailer">Motorcycle Trailer</MenuItem>
                                            <MenuItem value="Petroleum Tank">Petroleum Tank</MenuItem>
                                            <MenuItem value="Reefer">Reefer</MenuItem>
                                            <MenuItem value="Refrigerated (Reefer)">Refrigerated (Reefer)</MenuItem>
                                            <MenuItem value="Refrigerator Truck">Refrigerator Truck</MenuItem>
                                            <MenuItem value="Semi-trailer">Semi-trailer</MenuItem>
                                            <MenuItem value="Standard Flatbed">Standard Flatbed</MenuItem>
                                            <MenuItem value="Standard Lowboy">Standard Lowboy</MenuItem>
                                            <MenuItem value="Step Deck (Drop Deck)">Step Deck (Drop Deck)</MenuItem>
                                        </TextField>
                                    )}
                                </Grid>
                                {/* Tailer Length */}
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Tailer Length"
                                            {...getFieldProps('tailerLenght')}
                                            // error={Boolean(
                                            //     (touched.companyName && errors.companyName) ||
                                            //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // )}
                                            // helperText={
                                            //     (touched.companyName && errors.companyName) ||
                                            //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                            //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                            // }
                                        />
                                    )}
                                </Grid>
                                {/* Tailer Type */}
                                <Grid item lg={4} md={4} sm={4} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                className="text-input"
                                                // multiple
                                                id="driver-set"
                                                options={options}
                                                loading={loading1}
                                                isOptionEqualToValue={(option, value) => option.driver_id === value.driver_id}
                                                getOptionLabel={(option) => (option.name ? option.name : '')}
                                                inputValue={inputValue}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue(newInputValue);
                                                }}
                                                value={values.trailerOwner}
                                                onChange={(event, newValue) => {
                                                    // setDriverValue(newValue.name);

                                                    if (newValue != null) {
                                                        setFieldValue('trailerOwner', newValue);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="Owner"
                                                        onChange={(ev) => {
                                                            if (ev.target.value !== '' || ev.target.value !== null || ev.target.value.length > 1) {
                                                                onChangeHandle(ev.target.value);
                                                            }
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            )
                                                        }}
                                                        error={Boolean(touched.trailerOwner && errors.trailerOwner)}
                                                        helperText={touched.trailerOwner && errors.trailerOwner}
                                                    />
                                                )}
                                            />
                                            <FormHelperText>Type atleast 2 characters to populate the list</FormHelperText>
                                        </FormControl>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Typography
                                sx={{
                                    width: '375px',
                                    height: '40px',
                                    fontWeight: '600',
                                    fontSize: '24px',
                                    fontFamily: 'Lexend'
                                }}
                            >
                                Note
                            </Typography>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        type="textarea"
                                        className="text-input"
                                        color="orange"
                                        multiline
                                        fullWidth
                                        minRows={2}
                                        label="Note"
                                        {...getFieldProps('note')}
                                    />
                                )}
                            </Grid>
                        </SubCard>
                    </SubCard>
                </Form>
            </FormikProvider>
        </>
    );
};

export default AdminTrailerForm;
