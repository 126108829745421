import MaterialTable from '@material-table/core';
import TABLE_ICONS from 'tableIcons';

import { useNavigate } from 'react-router';

import { Button, Card, CardContent, CircularProgress, InputAdornment, Link, Stack, TablePagination, TextField, Typography, useMediaQuery } from '@mui/material';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { createRef, useEffect } from 'react';
import { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';

const AllSafetyComplianceForm = () => {
    const navigate = useNavigate();

    const handleSetDriver = (name) => {
        localStorage.setItem('SafteyDriver', name);
        // Dispatch the action to set the name value
    };
    const handleSetTruck = (name) => {
        localStorage.setItem('SafteyTruck', name);
        // Dispatch the action to set the name value
    };

    const tableRef = createRef();

    const [searchFltr, setSearchFltr] = useState('');
    const [selected, setSelected] = useState([]);

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.user_type === 'super_dispatcher_admin' ? '' : loginInfoFromStorage.company_id;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    function downloadHandler() {
        window.open(`/v1/api/users/company/drivers/download-excel/${cid}`, '_blank');
    }

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Driver Name</th>
                    <th className="headerTd rightHidBor">Date of Hire</th>

                    <th className="headerTd rightHidBor">Date of Termination</th>
                    <th className="headerTd rightHidBor">State</th>
                    <th className="headerTd rightHidBor">Truck</th>
                    <th className="headerTd ">Trailer</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor tablePadding " style={{ backgroundColor: bgClr(rowData) }}>
                    <Link
                        component="button"
                        onClick={() => {
                            handleSetDriver(rowData.data.name);
                            navigate(`/safety-compliance/driver/${rowData.data.driver_id}`);
                        }}
                    >
                        {rowData.data.name}
                    </Link>
                </td>
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.hire_date}
                </td>
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.end_date}
                </td>
                <td className="rowTr rightHidBor tablePadding" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.trailer_info.state}
                </td>
                <td className="rowTr rightHidBor tablePadding " style={{ backgroundColor: bgClr(rowData) }}>
                    <Link
                        component="button"
                        onClick={() => {
                            handleSetTruck(rowData.data.truck_info.truck);
                            navigate(`/safety-compliance/truck/${rowData.data.driver_id}/${rowData.data.truck_info.truck_id}`);
                        }}
                    >
                        {rowData.data.truck_info.truck}
                    </Link>
                </td>
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.trailer_info.trailer}
                </td>
            </tr>
        );
    };

    // const fetchData = async (query) => {
    //     const url = `/v1/api/users/company/drivers/slim?company_id=${cid}&page=${query.page + 1}&page_size=${query.pageSize}&search=${searchFltr}`;
    //     const response = await fetch(url);
    //     const result = await response.json();
    //     setSelected(result.results);

    //     return {
    //         data: result.results,
    //         page: query.page,
    //         totalCount: result.count
    //     };
    // };

    const fetchData = async (query) => {
        setLoading(true); // Start loading

        try {
            const url = `/v1/api/users/company/drivers/slim?company_id=${cid}&page=${query.page + 1}&page_size=${query.pageSize}&search=${searchFltr}`;

            const response = await fetch(url);
            const result = await response.json();

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 10 })
                .then((result) => {
                    setSelected(result.data || {});
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
        // console.log(selected);
    }, [isMobile, isTablet, currPage, cardPerPage]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    return (
        // <PageLayout url="/setup/add-user">
        <>
            <Stack
                sx={{ mt: { xs: 8, sm: 5 }, mb: 2, flexDirection: { xs: 'column', sm: 'row' } }}
                justifyContent="space-between"
                alignItems="baseline"
                spacing={2}
            >
                <TextField
                    type="text"
                    size="small"
                    placeholder="Search"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    // sx={{ width: { xs: '310px', sm: '350px' } }}
                    sx={{ width: { xs: '80vw', sm: '45vw', lg: '20vw' } }}
                    className="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                        // endAdornment: (
                        //     <InputAdornment position="end">
                        //         <MySvgComponent />
                        //     </InputAdornment>
                        // )
                    }}
                />
                <div>
                    <Button
                        variant="contained"
                        sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E', minWidth: { xs: '80vw', sm: '10rem' } }}
                        color="orange"
                        onClick={() => downloadHandler()}
                    >
                        DOWNLOAD
                    </Button>
                </div>
            </Stack>
            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ clear: 'both' }} />
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Driver Name :{' '}
                                        <Link
                                            component="button"
                                            onClick={() => {
                                                handleSetDriver(driver.name);
                                                navigate(`/safety-compliance/driver/${driver.driver_id}`);
                                            }}
                                        >
                                            {driver.name}
                                        </Link>
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Date of Hire : {driver.hire_date}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Date of Termination : {driver.end_date}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>State : {driver.trailer_info.state}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Truck :{' '}
                                        <Link
                                            component="button"
                                            onClick={() => {
                                                handleSetTruck(driver.truck_info.truck);
                                                navigate(`/safety-compliance/truck/${driver.driver_id}/${driver.truck_info.truck_id}`);
                                            }}
                                        >
                                            {driver.truck_info.truck}
                                        </Link>
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Trailer : {driver.trailer_info.trailer}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected?.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginTop: '2rem',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    {/* </Box> */}
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            Driver Name :{' '}
                                            <Link
                                                component="button"
                                                onClick={() => {
                                                    handleSetDriver(driver.name);
                                                    navigate(`/safety-compliance/driver/${driver.driver_id}`);
                                                }}
                                            >
                                                {driver.name}
                                            </Link>{' '}
                                        </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Date of Hire : {driver.hire_date}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Date of Termination : {driver.end_date}</Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>State : {driver.trailer_info.state}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            Truck :{' '}
                                            <Link
                                                component="button"
                                                onClick={() => {
                                                    handleSetTruck(driver.truck_info.truck);
                                                    navigate(`/safety-compliance/truck/${driver.driver_id}/${driver.truck_info.truck_id}`);
                                                }}
                                            >
                                                {driver.truck_info.truck}
                                            </Link>
                                        </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Trailer : {driver.trailer_info.trailer}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    // style={{ height: '100rem', overflowY: 'hidden' }}
                    tableRef={tableRef}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchData(query)
                                .then((result) => resolve(result))
                                .catch((error) => reject(error));
                        })
                    }
                    title="All Truck"
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,
                        filtering: true,
                        toolbar: false,
                        draggable: false,
                        columnResizable: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20]
                    }}
                />
            )}
            {/* </PageLayout> */}
        </>
    );
};

export default AllSafetyComplianceForm;
