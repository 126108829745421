import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { LoadingButton } from '@mui/lab';
import { Button, Card, CardContent, Divider, Grid, MenuItem, Skeleton, TextField, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { queryClient } from 'index';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import AddCategoryModal from './AddCategoryModal';
import AddTermsModal from './AddTermsModal';
import AddVendorModal from './AddVendorModal';
import './expenses.css';
import { useMemo } from 'react';

const initialValues = {
    recurring_bill_amount: [
        {
            category: '',
            category_name: '',
            description: '',
            amount: '',
            open: false,
            category_open: false
        }
    ],
    template_name: '',
    type: 'Scheduled',
    vendor: '',
    vendor_name: '',
    interval_type: 'Monthly',
    interval_month: '1',
    interval_day: '1',
    start_date: '',
    end_date: '',
    mailing_address: '',
    terms: '',
    terms_name: '',
    memo: '',
    attachment: null
};
const validationSchema = Yup.object().shape({
    template_name: Yup.string().required('Template name is required'),
    vendor: Yup.string().required('Vendor is required'),
    start_date: Yup.date().typeError('Invalid date').required('Start date date is required'),
    end_date: Yup.date().typeError('Invalid date').optional(),
    recurring_bill_amount: Yup.array().of(
        Yup.object().shape({
            amount: Yup.string().required('Amount is required')
        })
    )
});
// Initial values for Formik form validation
function AddRecurringBillModal({ addRecurringBillDrawer, setAddRecurringBillDrawer, changeAddRecurringBillStatus, search, page, rowsPerPage }) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');

    const addAmountDescription = (index) => {
        formik.setValues((prev) => ({
            ...prev,
            recurring_bill_amount: [
                ...prev.recurring_bill_amount,
                {
                    category: '',
                    description: '',
                    amount: '',
                    open: false,
                    category_open: false
                }
            ]
        }));
    };
    const getVendorList = async () => {
        const response = await axios.get(`/v1/api/accounting/vendors/?page=1&page_size=500`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });

        return response.data.results;
    };

    const getCategories = async () => {
        const response = await axios.get(`/v1/api/accounting/categories/?page=1&page_size=100`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });

        return response.data.results;
    };

    const getTerms = async () => {
        const response = await axios.get(`/v1/api/accounting/terms/?page=1&page_size=100`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });

        return response.data.results;
    };

    const {
        data: vendors,
        isLoading: vendorsLoading,
        isFetching: vendorsFetching
    } = useQuery({
        queryKey: ['vendors', '', 1, 500],
        queryFn: getVendorList,
        staleTime: 1000 * 60 * 60 * 5
    });

    const {
        data: categories,
        isLoading: categoriesLoading,
        isFetching: categoriesFetching
    } = useQuery({
        queryKey: ['categories'],
        queryFn: getCategories,
        staleTime: 1000 * 60 * 60 * 5
    });

    const {
        data: terms,
        isLoading: termsLoading,
        isFetching: termsFetching
    } = useQuery({
        queryKey: ['terms'],
        queryFn: getTerms,
        staleTime: 1000 * 60 * 60 * 5
    });

    const addExpense = async (formData) => {
        const response = await axios.post('/v1/api/accounting/recurring-bills/', formData, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response;
    };

    const {
        mutateAsync: addRecurringBillMutate,
        isPending: postRecurringBillPending,
        status: postRecurringBillStatus
    } = useMutation({
        mutationFn: addExpense,
        onSuccess: async () => {
            setAddRecurringBillDrawer(false);
            formik.resetForm();
            return await queryClient.invalidateQueries({ queryKey: ['recurring-bills', search, page, rowsPerPage] });
        }
    });

    useEffect(() => {
        changeAddRecurringBillStatus(postRecurringBillStatus);
    }, [postRecurringBillStatus]);

    const removeAmountDescription = (index) => {
        formik.setValues((prev) => ({
            ...prev,
            recurring_bill_amount: prev.recurring_bill_amount.filter((_amount, i) => i !== index)
        }));
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, _action) => {
            // Deep clone the values object
            const data = JSON.parse(JSON.stringify(values));
            delete data.attachment;
            delete data.vendor_name;
            delete data.terms_name;

            if (values.attachment) {
                data.attachment = values.attachment;
            }

            // Map over the recurring_bill_amount array and transform each item
            data.recurring_bill_amount = data.recurring_bill_amount.map((amountDes) => {
                const new_amount = {
                    amount: amountDes.amount
                };
                if (amountDes.category) {
                    new_amount['category'] = amountDes.category;
                }
                if (amountDes.description) {
                    new_amount['description'] = amountDes.description;
                }
                return new_amount;
            });

            if (data.interval_type === 'Yearly') {
                delete data.interval_type;
            }
            if (data.end_date) {
                data.end_date = format(new Date(data.end_date), 'yyyy-MM-dd');
            } else {
                delete data.end_date;
            }

            data.start_date = data.start_date ? format(new Date(data.start_date), 'yyyy-MM-dd') : null;

            data.recurring_bill_amount = JSON.stringify(data.recurring_bill_amount);

            // Create a new FormData object
            const formData = new FormData();

            // Append the transformed data to the formData object
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            toast.promise(addRecurringBillMutate(formData), {
                pending: 'Saving your recurring bill...',
                success: 'Recurring bill saved successfully!',
                error: 'Error saving recurring bill. Please try again.'
            });
        }
    });

    const { values, errors, touched, setFieldValue, handleSubmit, handleBlur, handleChange } = formik;

    const totalAmount = useMemo(() => {
        return formik.values.recurring_bill_amount.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0).toFixed(2);
    }, [formik.values.recurring_bill_amount]);

    const [vendorOpen, setVendorOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);

    const [addVendorDrawer, setAddVendorDrawer] = useState(false);
    const [addCategoryDrawer, setAddCategoryDrawer] = useState(false);
    const [addTermsDrawer, setAddTermsDrawer] = useState(false);

    return (
        <Drawer
            open={addRecurringBillDrawer}
            anchor="right"
            onClose={() => {
                setAddRecurringBillDrawer(false);
            }}
        >
            <div style={{ width: isMobile ? '100vw' : '70vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Add new recurring bill
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setAddRecurringBillDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <form onSubmit={handleSubmit}>
                    {/* <div style={{ width: '100%', display: 'flex', marginBottom: 20, gap: 20 }}> */}

                    <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mb: '1rem' }}>
                        <TextField
                            // style={{ width: '20rem' }}
                            sx={{ width: { xs: '82vw', sm: '55vw', lg: '32vw' } }}
                            className="text-input"
                            color="orange"
                            name="template_name"
                            label="Template Name"
                            value={values.template_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.template_name && errors.template_name)}
                            helperText={touched.template_name && errors.template_name}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                            select
                            sx={{ width: { xs: '82vw', sm: '55vw', lg: '32vw' } }}
                            className="text-input"
                            color="orange"
                            name="type"
                            label="Type"
                            value={values.type}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        >
                            <MenuItem value={'Scheduled'}>Scheduled</MenuItem>
                            <MenuItem value={'UnScheduled'}>UnScheduled</MenuItem>
                        </TextField>
                    </Grid>

                    {/* </div> */}
                    <Box sx={{ position: 'relative', mt: '1rem', width: { xs: '82vw', sm: '55vw', lg: '32vw' } }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="vendor"
                            label="Vendor"
                            value={values.vendor_name}
                            onChange={() => setVendorOpen(true)}
                            onBlur={handleBlur}
                            error={Boolean(touched.vendor && errors.vendor)}
                            helperText={touched.vendor && errors.vendor}
                        />
                        <Button
                            sx={{
                                position: 'absolute',
                                right: 0,
                                minWidth: '2vw',
                                height: '50px',
                                borderWidth: '0px 0px 0px 2px',
                                borderStyle: 'solid',
                                borderColor: '#000',
                                borderRadius: '0px 5px 5px 0px',
                                cursor: 'pointer',
                                backgroundColor: 'transparent'
                            }}
                            type="button"
                            // className="payee-arrow"
                            onClick={() => setVendorOpen((prev) => !prev)}
                        >
                            {vendorOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                        {vendorOpen && (
                            <div className="payee-option">
                                <Button
                                    style={{ color: '#FF7200', display: 'flex', justifyContent: 'start' }}
                                    startIcon={<AddIcon />}
                                    onClick={() => setAddVendorDrawer(true)}
                                >
                                    Add New...
                                </Button>
                                {(vendorsLoading || vendorsFetching) && <Skeleton height={50} />}
                                {vendors?.map((vendor) => (
                                    <button
                                        className="dropdown-button"
                                        key={vendor.id}
                                        onClick={() => {
                                            setFieldValue('vendor', vendor.id);
                                            setFieldValue('vendor_name', vendor.company_name);
                                            setVendorOpen(false);
                                        }}
                                    >
                                        <strong>{vendor.company_name}</strong>
                                        <span>{vendor.title}</span>
                                    </button>
                                ))}
                            </div>
                        )}
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <label htmlFor="payee">Interval</label>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20, marginTop: 15 }}>
                        <Grid container>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <TextField
                                    select
                                    sx={{ width: { xs: '82vw', sm: '55vw', lg: '32vw' } }}
                                    className="text-input"
                                    color="orange"
                                    name="interval_type"
                                    value={values.interval_type}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'Monthly'}>Monthly</MenuItem>
                                    <MenuItem value={'Yearly'}>Yearly</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {values.interval_type === 'Yearly' && (
                                    <TextField
                                        select
                                        sx={{ width: { xs: '82vw', sm: '55vw', lg: '32vw' } }}
                                        className="text-input"
                                        color="orange"
                                        name="interval_month"
                                        value={values.interval_month}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'1'}>January</MenuItem>
                                        <MenuItem value={'2'}>February</MenuItem>
                                        <MenuItem value={'3'}>March</MenuItem>
                                        <MenuItem value={'4'}>April</MenuItem>
                                        <MenuItem value={'5'}>May</MenuItem>
                                        <MenuItem value={'6'}>June</MenuItem>
                                        <MenuItem value={'7'}>July</MenuItem>
                                        <MenuItem value={'8'}>August</MenuItem>
                                        <MenuItem value={'9'}>September</MenuItem>
                                        <MenuItem value={'10'}>October</MenuItem>
                                        <MenuItem value={'11'}>November</MenuItem>
                                        <MenuItem value={'12'}>December</MenuItem>
                                    </TextField>
                                )}

                                <p>on</p>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mb: '1rem', mt: '1rem' }}>
                                <TextField
                                    select
                                    sx={{ width: { xs: '82vw', sm: '55vw', lg: '32vw' } }}
                                    className="text-input"
                                    color="orange"
                                    name="interval_day"
                                    value={values.interval_day}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'1'}>1st</MenuItem>
                                    <MenuItem value={'2'}>2nd</MenuItem>
                                    <MenuItem value={'3'}>3rd</MenuItem>
                                    <MenuItem value={'4'}>4th</MenuItem>
                                    <MenuItem value={'5'}>5th</MenuItem>
                                    <MenuItem value={'6'}>6th</MenuItem>
                                    <MenuItem value={'7'}>7th</MenuItem>
                                    <MenuItem value={'8'}>8th</MenuItem>
                                    <MenuItem value={'9'}>9th</MenuItem>
                                    <MenuItem value={'10'}>10th</MenuItem>
                                    <MenuItem value={'11'}>11th</MenuItem>
                                    <MenuItem value={'12'}>12th</MenuItem>
                                    <MenuItem value={'13'}>13th</MenuItem>
                                    <MenuItem value={'14'}>14th</MenuItem>
                                    <MenuItem value={'15'}>15th</MenuItem>
                                    <MenuItem value={'16'}>16th</MenuItem>
                                    <MenuItem value={'17'}>17th</MenuItem>
                                    <MenuItem value={'18'}>18th</MenuItem>
                                    <MenuItem value={'19'}>19th</MenuItem>
                                    <MenuItem value={'20'}>20th</MenuItem>
                                    <MenuItem value={'21'}>21th</MenuItem>
                                    <MenuItem value={'22'}>22th</MenuItem>
                                    <MenuItem value={'23'}>23th</MenuItem>
                                    <MenuItem value={'24'}>24th</MenuItem>
                                    <MenuItem value={'25'}>25th</MenuItem>
                                    <MenuItem value={'26'}>26th</MenuItem>
                                    <MenuItem value={'27'}>27th</MenuItem>
                                    <MenuItem value={'28'}>28th</MenuItem>
                                </TextField>

                                <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mb: '1rem', mt: '1rem' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="text-input"
                                            label="Start Date"
                                            openTo="day"
                                            views={['year', 'month', 'day']}
                                            value={values.start_date}
                                            onChange={(newValue) => {
                                                setFieldValue('start_date', newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    name="start_date"
                                                    color="orange"
                                                    sx={{ width: { xs: '82vw', sm: '55vw', lg: '32vw' } }}
                                                    {...params}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.start_date && errors.start_date)}
                                                    helperText={touched.start_date && errors.start_date}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="text-input"
                                            label="End Date"
                                            openTo="day"
                                            views={['year', 'month', 'day']}
                                            value={values.end_date}
                                            onChange={(newValue) => {
                                                setFieldValue('end_date', newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    name="end_date"
                                                    color="orange"
                                                    sx={{ width: { xs: '82vw', sm: '55vw', lg: '32vw' } }}
                                                    {...params}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched.end_date && errors.end_date)}
                                                    helperText={touched.end_date && errors.end_date}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider sx={{ my: 2 }} />

                    {/* <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}> */}
                    <Grid container flexDirection={'column'}>
                        {/* <div style={{ width: '20rem' }}> */}
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <label htmlFor="mailing-address" style={{ display: 'block', marginBottom: 5 }}>
                                Mailing address
                            </label>
                            <textarea
                                style={{ width: '100%', resize: 'vertical', border: '2px solid black', borderRadius: 10 }}
                                id="mailing-address"
                                name="mailing-address"
                                rows="4"
                                cols="50"
                            ></textarea>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} mb={'1rem'}>
                            {/* <div style={{ width: '20rem', position: 'relative' }}> */}
                            <label htmlFor="terms" style={{ display: 'block', marginBottom: 5 }}>
                                Terms
                            </label>
                            <TextField
                                fullWidth
                                id="terms"
                                className="text-input"
                                color="orange"
                                name="terms"
                                value={values.terms_name}
                                onChange={() => setTermsOpen(true)}
                                onBlur={handleBlur}
                            />
                            <Button
                                sx={{
                                    position: 'absolute',
                                    right: { xs: '8vw!important', sm: '36vw!important' },
                                    minWidth: '2vw',
                                    height: '50px',
                                    borderWidth: '0px 0px 0px 2px',
                                    borderStyle: 'solid',
                                    borderColor: '#000',
                                    borderRadius: '0px 5px 5px 0px',
                                    cursor: 'pointer',
                                    backgroundColor: 'transparent'
                                }}
                                type="button"
                                // className="payee-arrow"
                                onClick={() => setTermsOpen((prev) => !prev)}
                            >
                                {termsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Button>
                            {termsOpen && (
                                <div className="payee-option">
                                    <Button
                                        style={{ color: '#FF7200', display: 'flex', justifyContent: 'start' }}
                                        startIcon={<AddIcon />}
                                        onClick={() => setAddTermsDrawer(true)}
                                    >
                                        Add New...
                                    </Button>
                                    {(termsLoading || termsFetching) && <Skeleton height={50} />}
                                    {terms?.map((term) => (
                                        <button
                                            className="dropdown-button"
                                            key={term.id}
                                            onClick={() => {
                                                setFieldValue('terms', term.id);
                                                setFieldValue('terms_name', term.name);
                                                setTermsOpen(false);
                                            }}
                                        >
                                            {term.name}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    {/* </div> */}

                    {isMobile || isTablet ? (
                        <>
                            {formik.values.recurring_bill_amount.map((amountDes, index) => (
                                <Card key={index} sx={{ marginBottom: '1.5rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '15px' }}>
                                    <CardContent>
                                        <AnimatePresence>
                                            <motion.div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'column',
                                                    gap: '.4rem'
                                                }}
                                                initial={{ scale: 1, opacity: 0 }}
                                                animate={{ scale: 1.05, opacity: 1 }}
                                                exit={{ scale: 1, opacity: 0.5 }}
                                            >
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <AddIcon
                                                        sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%', fontSize: '20px' }}
                                                        onClick={() => addAmountDescription(index)}
                                                    />

                                                    <button type="button" disabled style={{ outline: 'none', background: 'transparent', border: 'none' }}>
                                                        <RemoveIcon
                                                            sx={{
                                                                backgroundColor: formik.values.recurring_bill_amount.length == 1 ? '#ff8995' : 'red',
                                                                color: 'white',
                                                                borderRadius: '50%',
                                                                fontSize: '20px'
                                                            }}
                                                            onClick={() => {
                                                                if (formik.values.expense_amount.length > 1) {
                                                                    removeAmountDescription(index);
                                                                } else {
                                                                    formik.setValues((prev) => ({
                                                                        ...prev,
                                                                        expense_amount: expenseInitialValues.expense_amount
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                    </button>
                                                </Box>
                                                <Box sx={{ clear: 'both' }} />
                                                <Typography
                                                    sx={{ marginBottom: '.5rem', display: 'flex', gap: '.4rem', alignItems: 'center', whiteSpace: 'nowrap' }}
                                                >
                                                    Category :
                                                    <Box
                                                        sx={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: { xs: '65%', md: '25%' },
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            onBlur={formik.handleBlur}
                                                            name={`recurring_bill_amount[${index}].category`}
                                                            value={amountDes.category_name}
                                                            onChange={() => {
                                                                setFieldValue(`recurring_bill_amount[${index}].category_open`, true);
                                                            }}
                                                            onClick={() => {
                                                                setFieldValue(`recurring_bill_amount[${index}].category_open`, true);
                                                            }}
                                                            className={
                                                                formik.touched?.recurring_bill_amount?.[index]?.category &&
                                                                formik.errors?.recurring_bill_amount?.[index]?.category &&
                                                                'form-error'
                                                            }
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                border: '2px solid #80808052',
                                                                borderRight: '2px solid #80808052',
                                                                outline: 0,
                                                                padding: '5px 0',
                                                                height: '100%'
                                                            }}
                                                            placeholder="Enter category"
                                                        />
                                                        <span
                                                            style={{
                                                                borderTop: '2px solid #80808052',
                                                                borderBottom: '2px solid #80808052',
                                                                borderLeft: '0px',
                                                                borderRight: '0px',
                                                                height: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {amountDes.category_open ? (
                                                                <KeyboardArrowUpIcon
                                                                    sx={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        formik.setValues((prev) => ({
                                                                            ...prev,
                                                                            recurring_bill_amount: prev.recurring_bill_amount.map((amount, i) => {
                                                                                if (i === index) {
                                                                                    return {
                                                                                        ...amount,
                                                                                        category_open: !amount.category_open
                                                                                    };
                                                                                } else {
                                                                                    return amount;
                                                                                }
                                                                            })
                                                                        }));
                                                                    }}
                                                                />
                                                            ) : (
                                                                <KeyboardArrowDownIcon
                                                                    sx={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        formik.setValues((prev) => ({
                                                                            ...prev,
                                                                            recurring_bill_amount: prev.recurring_bill_amount.map((amount, i) => {
                                                                                if (i === index) {
                                                                                    return {
                                                                                        ...amount,
                                                                                        category_open: !amount.category_open
                                                                                    };
                                                                                } else {
                                                                                    return amount;
                                                                                }
                                                                            })
                                                                        }));
                                                                    }}
                                                                />
                                                            )}
                                                        </span>
                                                        {amountDes.category_open && (
                                                            <Box
                                                                sx={{
                                                                    height: { xs: '5vh!important', sm: '4vh!important' },
                                                                    padding: '2px!important'
                                                                }}
                                                                className="category-choice"
                                                            >
                                                                <Button
                                                                    style={{ color: '#FF7200', display: 'flex', justifyContent: 'start' }}
                                                                    startIcon={<AddIcon />}
                                                                    onClick={() => setAddCategoryDrawer(true)}
                                                                >
                                                                    Add New...
                                                                </Button>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                                                    {(categoriesLoading || categoriesFetching) && <Skeleton height={50} />}
                                                                    {categories?.map((category) => (
                                                                        <button
                                                                            className="dropdown-button"
                                                                            key={category.id}
                                                                            style={{ width: '100%' }}
                                                                            onClick={() => {
                                                                                setFieldValue(`recurring_bill_amount[${index}].category`, category.id);
                                                                                setFieldValue(`recurring_bill_amount[${index}].category_name`, category.name);
                                                                                setFieldValue(`recurring_bill_amount[${index}].category_open`, false);
                                                                            }}
                                                                        >
                                                                            {category.name}
                                                                        </button>
                                                                    ))}
                                                                </div>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Typography>

                                                <Typography
                                                    sx={{ marginBottom: '.5rem', display: 'flex', gap: '.4rem', alignItems: 'center', whiteSpace: 'nowrap' }}
                                                >
                                                    Description :{' '}
                                                    <Box
                                                        sx={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: { xs: '65%', md: '25%' },
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            onBlur={handleBlur}
                                                            name={`recurring_bill_amount[${index}].description`}
                                                            value={amountDes.description}
                                                            className={
                                                                formik.touched?.recurring_bill_amount?.[index]?.description &&
                                                                formik.errors?.recurring_bill_amount?.[index]?.description &&
                                                                'form-error'
                                                            }
                                                            onChange={handleChange}
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                border: '2px solid #80808052',
                                                                outline: 0,
                                                                padding: '5px 0',
                                                                height: '100%'
                                                            }}
                                                            placeholder="Enter description"
                                                        />
                                                    </Box>
                                                </Typography>

                                                <Typography sx={{ marginBottom: '.5rem', display: 'flex', gap: '.4rem', alignItems: 'center' }}>
                                                    Amount :{' '}
                                                    <Box
                                                        sx={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: { xs: '65%', md: '25%' },
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="number"
                                                            name={`recurring_bill_amount[${index}].amount`}
                                                            onBlur={handleBlur}
                                                            value={amountDes.amount}
                                                            onChange={handleChange}
                                                            className={
                                                                formik.touched?.recurring_bill_amount?.[index]?.amount &&
                                                                formik.errors?.recurring_bill_amount?.[index]?.amount &&
                                                                'form-error'
                                                            }
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                border: '2px solid #80808052',
                                                                borderLeft: '0px',
                                                                borderRight: '2px solid #80808052',
                                                                outline: 0,
                                                                padding: '5px 0',
                                                                height: '100%'
                                                            }}
                                                            placeholder="Enter the amount"
                                                        />
                                                    </Box>
                                                </Typography>
                                            </motion.div>
                                        </AnimatePresence>
                                    </CardContent>
                                </Card>
                            ))}
                        </>
                    ) : (
                        <div>
                            <div style={{ borderBottom: '1px solid #80808052', display: 'flex', justifyContent: 'space-evenly', fontWeight: '600' }}>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}></div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}>#</div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Category</div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Description</div>
                                <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Amount</div>
                                <div style={{ textAlign: 'center', width: '10%' }}></div>
                            </div>
                            <AnimatePresence>
                                {formik.values.recurring_bill_amount.map((amountDes, index) => (
                                    <div key={index}>
                                        <AnimatePresence>
                                            {amountDes.open ? (
                                                <motion.div
                                                    style={{
                                                        borderBottom: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly'
                                                    }}
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0.5 }}
                                                >
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '10%',
                                                            cursor: 'pointer'
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                        onClick={() =>
                                                            formik.setValues((prev) => ({
                                                                ...prev,
                                                                recurring_bill_amount: prev.recurring_bill_amount.map((amount, i) => {
                                                                    if (i === index) {
                                                                        return { ...amount, open: !amount.open };
                                                                    } else {
                                                                        return amount;
                                                                    }
                                                                })
                                                            }))
                                                        }
                                                        onKeyDown={(e) => {}}
                                                    >
                                                        <ViewCompactIcon sx={{ cursor: 'pointer' }} />
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '10%',
                                                            cursor: 'pointer'
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                        onClick={() =>
                                                            formik.setValues((prev) => ({
                                                                ...prev,
                                                                recurring_bill_amount: prev.recurring_bill_amount.map((amount, i) => {
                                                                    if (i === index) {
                                                                        return { ...amount, open: !amount.open };
                                                                    } else {
                                                                        return amount;
                                                                    }
                                                                })
                                                            }))
                                                        }
                                                        onKeyDown={(e) => {}}
                                                    >
                                                        {index + 1}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                        onClick={() =>
                                                            formik.setValues((prev) => ({
                                                                ...prev,
                                                                recurring_bill_amount: prev.recurring_bill_amount.map((amount, i) => {
                                                                    if (i === index) {
                                                                        return { ...amount, open: !amount.open };
                                                                    } else {
                                                                        return amount;
                                                                    }
                                                                })
                                                            }))
                                                        }
                                                        onKeyDown={(e) => {}}
                                                    >
                                                        {amountDes.category_name}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                        onClick={() =>
                                                            formik.setValues((prev) => ({
                                                                ...prev,
                                                                recurring_bill_amount: prev.recurring_bill_amount.map((amount, i) => {
                                                                    if (i === index) {
                                                                        return { ...amount, open: !amount.open };
                                                                    } else {
                                                                        return amount;
                                                                    }
                                                                })
                                                            }))
                                                        }
                                                        onKeyDown={(e) => {}}
                                                    >
                                                        {amountDes.description}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                        onClick={() =>
                                                            formik.setValues((prev) => ({
                                                                ...prev,
                                                                recurring_bill_amount: prev.recurring_bill_amount.map((amount, i) => {
                                                                    if (i === index) {
                                                                        return { ...amount, open: !amount.open };
                                                                    } else {
                                                                        return amount;
                                                                    }
                                                                })
                                                            }))
                                                        }
                                                        onKeyDown={(e) => {}}
                                                    >
                                                        ${amountDes.amount ? parseFloat(amountDes.amount).toFixed(2) : ''}
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }}>
                                                        <RemoveIcon
                                                            sx={{
                                                                backgroundColor: formik.values.recurring_bill_amount.length == 1 ? '#ff8995' : 'red',
                                                                color: 'white',
                                                                borderRadius: '50%',
                                                                fontSize: '20px'
                                                            }}
                                                            onClick={() => {
                                                                if (formik.values.recurring_bill_amount.length > 1) {
                                                                    removeAmountDescription(index);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </motion.div>
                                            ) : (
                                                <motion.div
                                                    style={{
                                                        borderBottom: '1px solid #80808052',
                                                        borderTop: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly'
                                                    }}
                                                    initial={{ scale: 1, opacity: 0 }}
                                                    animate={{ scale: 1.05, opacity: 1 }}
                                                    exit={{ scale: 1, opacity: 0.5 }}
                                                >
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '10%',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <AddIcon
                                                            sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%', fontSize: '20px' }}
                                                            onClick={() => addAmountDescription(index)}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '10%',
                                                            cursor: 'pointer'
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                        onClick={() =>
                                                            formik.setValues((prev) => ({
                                                                ...prev,
                                                                recurring_bill_amount: prev.recurring_bill_amount.map((amount, i) => {
                                                                    if (i === index) {
                                                                        return { ...amount, open: !amount.open };
                                                                    } else {
                                                                        return amount;
                                                                    }
                                                                })
                                                            }))
                                                        }
                                                        onKeyDown={(e) => {}}
                                                    >
                                                        {index + 1}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            onBlur={handleBlur}
                                                            name={`recurring_bill_amount[${index}].category`}
                                                            value={amountDes.category_name}
                                                            onChange={() => {
                                                                setFieldValue(`recurring_bill_amount[${index}].category_open`, true);
                                                            }}
                                                            onClick={() => {
                                                                setFieldValue(`recurring_bill_amount[${index}].category_open`, true);
                                                            }}
                                                            className={
                                                                formik.touched?.recurring_bill_amount?.[index]?.category &&
                                                                formik.errors?.recurring_bill_amount?.[index]?.category &&
                                                                'form-error'
                                                            }
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                border: '2px solid #80808052',
                                                                borderRight: '2px solid #80808052',
                                                                outline: 0,
                                                                padding: '5px 0',
                                                                height: '100%'
                                                            }}
                                                            placeholder="Enter category"
                                                        />
                                                        <span
                                                            style={{
                                                                borderTop: '2px solid #80808052',
                                                                borderBottom: '2px solid #80808052',
                                                                borderLeft: '0px',
                                                                borderRight: '0px',
                                                                height: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {amountDes.category_open ? (
                                                                <KeyboardArrowUpIcon
                                                                    sx={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        formik.setValues((prev) => ({
                                                                            ...prev,
                                                                            recurring_bill_amount: prev.recurring_bill_amount.map((amount, i) => {
                                                                                if (i === index) {
                                                                                    return { ...amount, category_open: !amount.category_open };
                                                                                } else {
                                                                                    return amount;
                                                                                }
                                                                            })
                                                                        }));
                                                                    }}
                                                                />
                                                            ) : (
                                                                <KeyboardArrowDownIcon
                                                                    sx={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        formik.setValues((prev) => ({
                                                                            ...prev,
                                                                            recurring_bill_amount: prev.recurring_bill_amount.map((amount, i) => {
                                                                                if (i === index) {
                                                                                    return { ...amount, category_open: !amount.category_open };
                                                                                } else {
                                                                                    return amount;
                                                                                }
                                                                            })
                                                                        }));
                                                                    }}
                                                                />
                                                            )}
                                                        </span>
                                                        {amountDes.category_open && (
                                                            <div className="category-choice">
                                                                <Button
                                                                    style={{ color: '#FF7200', display: 'flex', justifyContent: 'start' }}
                                                                    startIcon={<AddIcon />}
                                                                    onClick={() => setAddCategoryDrawer(true)}
                                                                >
                                                                    Add New...
                                                                </Button>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                                                    {(categoriesLoading || categoriesFetching) && <Skeleton height={50} />}
                                                                    {categories?.map((category) => (
                                                                        <button
                                                                            className="dropdown-button"
                                                                            key={category.id}
                                                                            style={{ width: '100%' }}
                                                                            onClick={() => {
                                                                                setFieldValue(`recurring_bill_amount[${index}].category`, category.id);
                                                                                setFieldValue(`recurring_bill_amount[${index}].category_name`, category.name);
                                                                                setFieldValue(`recurring_bill_amount[${index}].category_open`, false);
                                                                            }}
                                                                        >
                                                                            {category.name}
                                                                        </button>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            onBlur={handleBlur}
                                                            name={`recurring_bill_amount[${index}].description`}
                                                            value={amountDes.description}
                                                            className={
                                                                formik.touched?.recurring_bill_amount?.[index]?.description &&
                                                                formik.errors?.recurring_bill_amount?.[index]?.description &&
                                                                'form-error'
                                                            }
                                                            onChange={handleChange}
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                border: '2px solid #80808052',
                                                                outline: 0,
                                                                padding: '5px 0',
                                                                height: '100%'
                                                            }}
                                                            placeholder="Enter description"
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            borderRight: '1px solid #80808052',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '25%',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <input
                                                            type="number"
                                                            name={`recurring_bill_amount[${index}].amount`}
                                                            onBlur={handleBlur}
                                                            value={amountDes.amount}
                                                            onChange={handleChange}
                                                            className={
                                                                formik.touched?.recurring_bill_amount?.[index]?.amount &&
                                                                formik.errors?.recurring_bill_amount?.[index]?.amount &&
                                                                'form-error'
                                                            }
                                                            style={{
                                                                textAlign: 'center',
                                                                width: '100%',
                                                                border: '2px solid #80808052',
                                                                borderLeft: '0px',
                                                                borderRight: '2px solid #80808052',
                                                                outline: 0,
                                                                padding: '5px 0',
                                                                height: '100%'
                                                            }}
                                                            placeholder="Enter the amount"
                                                        />
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }}>
                                                        <RemoveIcon
                                                            sx={{
                                                                backgroundColor: formik.values.recurring_bill_amount.length == 1 ? '#ff8995' : 'red',
                                                                color: 'white',
                                                                borderRadius: '50%',
                                                                fontSize: '20px'
                                                            }}
                                                            onClick={() => {
                                                                if (formik.values.recurring_bill_amount.length > 1) {
                                                                    removeAmountDescription(index);
                                                                } else {
                                                                    formik.setValues((prev) => ({
                                                                        ...prev,
                                                                        recurring_bill_amount: initialValues.recurring_bill_amount
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </div>
                                ))}
                            </AnimatePresence>
                        </div>
                    )}

                    {isMobile ? (
                        <div style={{ display: 'flex', justifyContent: 'end', gap: 50, fontSize: 20, marginTop: 20, marginRight: 0 }}>
                            <strong>Total</strong>
                            <strong>${totalAmount}</strong>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'end', gap: 100, fontSize: 20, marginTop: 20, marginRight: { xs: 0, sm: 50 } }}>
                            <strong>Total</strong>
                            <strong>${totalAmount}</strong>
                        </div>
                    )}

                    {/* <div style={{ display: 'flex', marginTop: 20, gap: 20 }}> */}
                    <Grid container>
                        <div style={{ width: '20rem' }}>
                            <label htmlFor="memo" style={{ display: 'block', marginBottom: 10, fontWeight: 'bold' }}>
                                Memo
                            </label>
                            <textarea
                                style={{ width: '100%', resize: 'vertical', border: '2px solid black', borderRadius: 10 }}
                                id="memo"
                                name="memo"
                                rows="6"
                                cols="50"
                            ></textarea>
                        </div>
                        <div style={{ marginBottom: 20, width: '50%' }}>
                            <Typography variant="h4" mb={1}>
                                Attachment
                            </Typography>
                            <input
                                type="file"
                                onChange={(e) => {
                                    setFieldValue('attachment', e.target.files[0]);
                                }}
                                accept=".pdf, .png, .jpg, .jpeg"
                                style={{ display: 'none' }}
                                id="file-input"
                            />
                            <label htmlFor="file-input">
                                <Box
                                    sx={{
                                        border: '2px dashed orange',
                                        borderRadius: '5px',
                                        padding: '20px',
                                        textAlign: 'center',
                                        width: { xs: '82vw', sm: '62vw', md: '100%' },
                                        cursor: 'pointer',
                                        backgroundColor: '#f9f9f9'
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        setFieldValue('attachment', e.dataTransfer.files[0]);
                                    }}
                                    onDragOver={(e) => e.preventDefault()}
                                >
                                    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                        <DownloadIcon />
                                        <Typography variant="h6" gutterBottom>
                                            <span style={{ fontWeight: 'bold' }}>Choose a file</span> or drag it here
                                        </Typography>
                                    </div>
                                    <span style={{ color: 'green' }}>{formik.values.attachment && formik.values.attachment.name}</span>
                                </Box>
                            </label>
                        </div>
                    </Grid>
                    {/* </div> */}
                    <div style={{ width: '50%' }}>
                        {values.attachment && (
                            <>
                                {typeof values.attachment === 'string' ? (
                                    <>
                                        {values.attachment.endsWith('.pdf') ? (
                                            <embed src={values.attachment} type="application/pdf" width="100%" height="150" />
                                        ) : (
                                            <img src={values.attachment} alt="" style={{ width: '100%', height: '150', objectFit: 'cover' }} />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {values.attachment.type === 'application/pdf' ? (
                                            <embed src={URL.createObjectURL(values.attachment)} type="application/pdf" width="100%" height="150" />
                                        ) : (
                                            <img
                                                src={URL.createObjectURL(values.attachment)}
                                                alt=""
                                                style={{ width: '100%', height: '150', objectFit: 'cover' }}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <LoadingButton loading={postRecurringBillPending} type="submit" fullWidth variant="contained" style={{ backgroundColor: '#EB812E' }}>
                        Save recurring Bill
                    </LoadingButton>
                </form>
            </div>
            {/* Add Category Modal */}
            <AddCategoryModal addCategoryDrawer={addCategoryDrawer} setAddCategoryDrawer={setAddCategoryDrawer} />

            {/* Add Vendor Modal */}
            <AddVendorModal
                addDrawerOpen={addVendorDrawer}
                setAddDrawerOpen={setAddVendorDrawer}
                addVendorStatusChange={() => {}}
                search={''}
                page={1}
                rowsPerPage={500}
            />

            {/* Add Terms Modal */}
            <AddTermsModal addTermsDrawer={addTermsDrawer} setAddTermsDrawer={setAddTermsDrawer} />
        </Drawer>
    );
}

export default AddRecurringBillModal;
