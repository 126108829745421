// project imports
import { useEffect } from 'react';

import MainCard from 'ui-component/cards/MainCard';

import { useDispatch, useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';

import PageLayout from 'layout/PageLayout';
import AllMechanicDriverForm from 'ui-component/mechanic/forms/AllMechanicDriverForm';

// ==============================|| SAMPLE PAGE ||============================== //

const AllMechanicDriver = () => {
   
    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    return (
        <>
            <MainCard>
            <PageLayout title="Driver">
               

                <AllMechanicDriverForm/>
                </PageLayout>
            </MainCard>
        </>
    );
};

export default AllMechanicDriver;
