import React from 'react';

import ViewTruckForm from 'ui-component/fleet/forms/ViewTruckForm';
import PageLayoutWithBack from 'layout/PageLayoutWithBack';
import CardLayout from 'layout/CardLayout';

function ViewTruck() {
    return (
        <PageLayoutWithBack>
            {/* <CardLayout> */}
            <ViewTruckForm />
            {/* </CardLayout> */}
        </PageLayoutWithBack>
    );
}

export default ViewTruck;
