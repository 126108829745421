
import PageLayoutWithBack from 'layout/PageLayoutWithBack';

import DriverForm from 'ui-component/fleet/forms/DriverForm';

function EditDriver() {
    return (
        <PageLayoutWithBack>
            {/* <CardLayout cardHeadTitle="Edit Driver"> */}
            <DriverForm />
            {/* </CardLayout> */}
        </PageLayoutWithBack>
    );
}

export default EditDriver;
