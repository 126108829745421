import { useState } from 'react';

import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
// material-ui
// import { useTheme } from '@mui/material/styles';
import { Button, FormControl, Grid, IconButton, InputAdornment, MenuItem, Skeleton, TextField, Typography } from '@mui/material';

// project imports
// import useScriptRef from 'hooks/useScriptRef';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// third party
import * as Yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { Stack } from '@mui/system';

import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import taxFile from '../../../assets/docs/tax-rates.csv';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const AddTaxConfigForm = () => {
    // const theme = useTheme();
    // const scriptedRef = useScriptRef();
    // const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [taxList, setTaxList] = useState();
    const [loading, setLoading] = useState(false);

    const [quaterFilter, setQuaterFilter] = useState('');
    const [yearFilter, setYearFilter] = useState('');
    const dispatch = useDispatch();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const taxConfig = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const FaqSchema = Yup.object().shape({});

    const fetchTaxList = async () => {
        if (yearFilter === '') {
            dispatch(openErrorSnackbar('Please Select A Year'));
        } else if (quaterFilter === 0 || quaterFilter === '') {
            dispatch(openErrorSnackbar('Please Select A Quarter'));
        }
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/state/tax/view?year=${format(new Date(yearFilter), 'yyyy')}&quarter=${quaterFilter}`, config);
            setTaxList(data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            stateSelect: taxList ? taxList : [{}],

            taxData: '',
            fileName: '',
            tax_file: ''
        },
        validationSchema: FaqSchema,
        onSubmit: async () => {
            try {
                const { data } = await axios.post(
                    `/v1/api/users/state/tax/add/`,
                    {
                        tax_file: values.taxData
                    },
                    taxConfig
                );
                dispatch(openSuccessSnackbar('Tax Data uploaded successfully'));

                // navigate('/fleet/all-truck', { replace: true });
            } catch (error) {
                console.log(error);
            }
            // );
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setFileName(event.target.files[0].name);
    };

    const handleSubmitdata = async (event) => {
        event.preventDefault();

        try {
            const { data } = await axios.post(
                `/v1/api/users/state/tax/add/`,
                {
                    tax_file: file
                },
                taxConfig
            );
            dispatch(openSuccessSnackbar('Tax Data uploaded successfully'));

            // navigate('/fleet/all-truck', { replace: true });
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                        sx={{
                            mt: '20px',
                            width: '375px',
                            height: '40px',
                            fontWeight: '600',
                            fontSize: '32px',
                            fontFamily: 'Lexend'
                        }}
                    >
                        TAX Configuration
                    </Typography>
                </Grid>
            </Grid>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '15px' }}>
                {loginInfoFromStorage && loginInfoFromStorage.company_id === 1 && (
                    <form autoComplete="off" onSubmit={handleSubmitdata} encType="multipart/form-data">
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <TextField
                                        className="text-input"
                                        color="orange"
                                        fullWidth
                                        value={fileName}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end">
                                                        <Button component="label" color="orange" startIcon={<CloudUploadIcon />}>
                                                            Upload
                                                            <input hidden type="file" accept="text/csv" onInputCapture={(e) => handleFileChange(e)} />
                                                        </Button>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        placeholder="File Upload"
                                    />
                                    <Typography variant="caption">
                                        You can refer to this{' '}
                                        <Link to={taxFile} download="sample_tax_file.csv" target="_blank" rel="noreferrer">
                                            Sample file
                                        </Link>
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                        <FormControl variant="outlined" align="right">
                                            <LoadingFormButton type="submit" btnText="Save" variant="contained" component="span" />
                                        </FormControl>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </SubCard>
                    </form>
                )}
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="text-input"
                                            openTo="day"
                                            views={['year']}
                                            label="Year"
                                            value={yearFilter}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            onChange={(date) => {
                                                setYearFilter(date);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    className="text-input"
                                                    color="orange"
                                                    fullWidth
                                                    {...params}
                                                    error={Boolean(touched.startDate && errors.startDate)}
                                                    helperText={touched.startDate && errors.startDate}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        select
                                        label="quater"
                                        value={quaterFilter}
                                        onChange={(e) => {
                                            setQuaterFilter(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={0} disabled>
                                            Select Quater
                                        </MenuItem>

                                        <MenuItem value={1}>1st Quater </MenuItem>
                                        <MenuItem value={2}>2nd Quater </MenuItem>
                                        <MenuItem value={3}>3rd Quater </MenuItem>
                                        <MenuItem value={4}>4th Quater </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                        <FormControl variant="outlined" align="right">
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    mr: 2,
                                                    width: '79px',
                                                    height: '36px',
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    fontFamily: 'Lexend',
                                                    color: 'white',
                                                    backgroundColor: '#EB812E',
                                                    lineHeight: '20px'
                                                }}
                                                color="orange"
                                                component="span"
                                                onClick={() => fetchTaxList()}
                                            >
                                                Search
                                            </Button>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </SubCard>
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    State Selection
                                </Typography>
                            </Grid>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : taxList ? (
                                <FieldArray name="stateSelect">
                                    {() => (
                                        <>
                                            {values.stateSelect.length > 0 &&
                                                values.stateSelect.map((i, index) => (
                                                    <div key={index}>
                                                        <Grid container spacing={3} sx={{ mt: 1 }}>
                                                            {/* <Grid item container spacing={2} xs={10} sm={10} lg={10} md={10}>
                                                <Grid item xs={12}> */}
                                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                <Typography variant="h4">{values.stateSelect[index].state_name}</Typography>
                                                            </Grid>
                                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                <Typography variant="h4">{values.stateSelect[index].state_iso2}</Typography>
                                                            </Grid>
                                                            {/* City */}
                                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    type="text"
                                                                    className="text-input"
                                                                    label="Surcharge"
                                                                    value={values.stateSelect[index].surcharge}
                                                                    onChange={(e) => setFieldValue(`stateSelect[${index}].surcharge`, e.target.value)}
                                                                    inputProps={{ readOnly: true }}
                                                                />
                                                            </Grid>
                                                            {/* Total Gallos */}
                                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    type="text"
                                                                    className="text-input"
                                                                    label="Tax Rate"
                                                                    value={values.stateSelect[index].tax_rate}
                                                                    onChange={(e) => setFieldValue(`stateSelect[${index}].tax_rate`, e.target.value)}
                                                                    inputProps={{ readOnly: true }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        {/* </Grid> */}
                                                    </div>
                                                ))}
                                        </>
                                    )}
                                </FieldArray>
                            ) : (
                                <div>Please select Year & Quarter to see Tax Rates</div>
                            )}
                        </SubCard>
                    </Form>
                </FormikProvider>
            </SubCard>
            {/* </Grid> */}
        </>
    );
};

export default AddTaxConfigForm;
