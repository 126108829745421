import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { LoadingButton } from '@mui/lab';
import { Button, MenuItem, Skeleton, TextField, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { queryClient } from 'index';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import AddCategoryModal from './AddCategoryModal';
import AddPaymentAccountModal from './AddPaymentAccountModal';
import AddVendorModal from './AddVendorModal';
import './expenses.css';
import { useMemo } from 'react';

// Initial values for Formik form validation
const expenseSchema = Yup.object().shape({
    payee: Yup.string().required('Payee is required'),
    payment_account: Yup.string().required('Payment account is required'),
    payment_date: Yup.date().typeError('Invalid date').required('Payment date is required'),
    payment_method: Yup.string().required('Payment method is required'),
    // ref_no: Yup.string().required('Ref no is required'),
    // memo: Yup.string().required('Memo is required'),
    // attachment: Yup.mixed().required('attachment is required'),
    expense_amount: Yup.array().of(
        Yup.object().shape({
            // category: Yup.string().required('Category is required'),
            // description: Yup.string().required('Description is required'),
            amount: Yup.string().required('Amount is required')
        })
    )
});
function EditExpenseModal({ editDrawerOpen, setEditDrawerOpen, expense, setClickedOptionId, changeEditExpenseStatus, search, page, rowsPerPage }) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const isMobile = useMediaQuery('(max-width:600px)');

    const getVendorList = async () => {
        const response = await axios.get(`/v1/api/accounting/vendors/?page=1&page_size=500`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });

        return response.data.results;
    };

    const getPaymentAccoutList = async () => {
        const response = await axios.get(`/v1/api/accounting/payment-account-types/?page=1&page_size=100`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });

        return response.data.results;
    };

    const getCategories = async () => {
        const response = await axios.get(`/v1/api/accounting/categories/?page=1&page_size=100`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });

        return response.data.results;
    };

    const {
        data: vendors,
        isLoading: vendorsLoading,
        isFetching: vendorsFetching
    } = useQuery({
        queryKey: ['vendors', '', 1, 500],
        queryFn: getVendorList,
        staleTime: 1000 * 60 * 60 * 5
    });

    const {
        data: paymentAccountList,
        isLoading: paymentAccountListLoading,
        isFetching: paymentAccountListFetching
    } = useQuery({
        queryKey: ['paymentAccountList'],
        queryFn: getPaymentAccoutList,
        staleTime: 1000 * 60 * 60 * 5
    });

    const {
        data: categories,
        isLoading: categoriesLoading,
        isFetching: categoriesFetching
    } = useQuery({
        queryKey: ['categories'],
        queryFn: getCategories,
        staleTime: 1000 * 60 * 60 * 5
    });

    const expenseInitialValues = {
        id: expense?.id,
        payee: vendors?.filter((vendor) => vendor.id === expense.vendor)?.[0]?.company_name,
        payment_account: paymentAccountList?.filter((payment) => payment.id === expense.payment_account)?.[0]?.id,
        payment_account_type: paymentAccountList?.filter((payment) => payment.id === expense.payment_account)?.[0]?.account_type,
        payment_date: expense?.payment_account,
        vendor: expense?.vendor,
        payment_method: expense?.payment_method,
        ref_no: expense?.ref_no,
        memo: expense?.memo,
        attachment: expense?.attachment,
        expense_amount: expense?.expense_amount?.map((item) => ({
            ...item,
            amount: parseFloat(item.amount).toFixed(2)
        }))
    };
    if (!expense.expense_amount[0]) {
        expenseInitialValues['expense_amount'] = [
            {
                category: '',
                category_name: '',
                description: '',
                amount: '',
                open: true,
                category_open: false
            }
        ];
    }

    const editExpense = async (formData) => {
        const response = await axios.put(`/v1/api/accounting/expenses/${expense.id}/`, formData, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response;
    };

    const {
        mutate: editExpenseMutate,
        isPending: postExpensePending,
        error: editExpenseError,
        status: editExpenseStatus
    } = useMutation({
        mutationFn: editExpense,
        onSuccess: async () => {
            setEditDrawerOpen(false);
            formik.resetForm();
            setClickedOptionId((prev) => ({ id: expense.id, open: !prev.open }));
            changeEditExpenseStatus({
                id: expense?.id,
                status: 'idle'
            });
            toast.success('Expense updated successfully.');
            return await queryClient.invalidateQueries({ queryKey: ['expenses', search, page, rowsPerPage] });
        }
    });

    // passing the editBill status to parent for better user experience
    useEffect(() => {
        changeEditExpenseStatus({
            id: expense?.id,
            status: editExpenseStatus
        });
    }, [editExpenseStatus]);

    if (editExpenseError?.response?.staus == 404) {
        toast.error('Item not found');
    } else if (editExpenseError) {
        toast.error('Please contact with support');
    }

    const formik = useFormik({
        initialValues: expenseInitialValues,
        validationSchema: expenseSchema,
        onSubmit: (values, action) => {
            // Deep clone the values object
            const data = JSON.parse(JSON.stringify(values));

            // Map over the expense_amount array and transform each item
            data.expense_amount = data.expense_amount.map((amountDes) => {
                const new_amount = {
                    amount: amountDes.amount
                };
                if (amountDes.category) {
                    new_amount['category'] = amountDes.category;
                }
                if (amountDes.description) {
                    new_amount['description'] = amountDes.description;
                }
                if (amountDes.id) {
                    new_amount['id'] = amountDes.id;
                }
                return new_amount;
            });

            delete data.payment_account_type;
            delete data.payee;
            delete data.attachment;

            data.payment_date = format(new Date(data.payment_date), 'yyyy-MM-dd');
            data.expense_amount = JSON.stringify(data.expense_amount);

            // Create a new FormData object
            const formData = new FormData();

            // Append the transformed data to the formData object
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            if (typeof values.attachment !== 'string' && values.attachment !== null) {
                // Append the attachment to the FormData
                formData.append('attachment', values.attachment);
            }

            editExpenseMutate(formData);
        }
    });

    const addAmountDescription = (index) => {
        formik.setValues((prev) => ({
            ...prev,
            expense_amount: [
                ...prev.expense_amount,
                {
                    category: '',
                    description: '',
                    amount: '',
                    open: true
                }
            ]
        }));
    };

    const removeAmountDescription = (index) => {
        formik.setValues((prev) => ({
            ...prev,
            expense_amount: prev.expense_amount.filter((_amount, i) => i !== index)
        }));
    };

    const totalAmount = useMemo(() => {
        return formik.values.expense_amount.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0).toFixed(2);
    }, [formik.values.expense_amount]);

    const [payeeOpen, setPayeeOpen] = useState(false);
    const [paymentAccountOpen, setPaymentAccountOpen] = useState(false);
    const [addPayementAccountDrawer, setAddPayementAccountDrawer] = useState(false);
    const [addCategoryDrawer, setAddCategoryDrawer] = useState(false);
    const [addVendorDrawer, setAddVendorDrawer] = useState(false);

    return (
        <Drawer
            open={editDrawerOpen}
            anchor="right"
            onClose={() => {
                setEditDrawerOpen(false);
            }}
        >
            <div style={{ width: isMobile ? '100vw' : '70vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Edit expense
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setEditDrawerOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <div style={{ width: '100%', position: 'relative' }}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="payee"
                                label="Payee"
                                value={formik.values.payee}
                                onChange={() => setPayeeOpen(true)}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.payee && formik.errors.payee)}
                                helperText={formik.touched.payee && formik.errors.payee}
                            />
                            <button type="button" className="payee-arrow" onClick={() => setPayeeOpen((prev) => !prev)}>
                                {payeeOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </button>
                            {payeeOpen && (
                                <div className="payee-option">
                                    <Button
                                        style={{ color: '#FF7200', display: 'flex', justifyContent: 'start' }}
                                        startIcon={<AddIcon />}
                                        onClick={() => setAddVendorDrawer(true)}
                                    >
                                        Add New...
                                    </Button>
                                    {(vendorsLoading || vendorsFetching) && <Skeleton height={50} />}
                                    {vendors?.map((vendor) => (
                                        <button
                                            className="dropdown-button"
                                            key={vendor.id}
                                            onClick={() => {
                                                formik.setFieldValue('vendor', vendor.id);
                                                formik.setFieldValue('payee', vendor.company_name);
                                                setPayeeOpen(false);
                                            }}
                                        >
                                            <strong>{vendor.company_name}</strong>
                                            <span>{vendor.title}</span>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                        <AddVendorModal
                            addDrawerOpen={addVendorDrawer}
                            setAddDrawerOpen={setAddVendorDrawer}
                            addVendorStatusChange={() => {}}
                            search={''}
                            page={1}
                            rowsPerPage={500}
                        />
                        <div style={{ width: '100%', position: 'relative' }}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="payment_account_type"
                                label="Payment Account"
                                value={formik.values.payment_account_type}
                                onBlur={formik.handleBlur}
                                onChange={() => setPaymentAccountOpen(true)}
                                error={Boolean(formik.touched.payment_account_type && formik.errors.payment_account_type)}
                                helperText={formik.touched.payment_account_type && formik.errors.payment_account_type}
                            />
                            <button type="button" className="payee-arrow" onClick={() => setPaymentAccountOpen((prev) => !prev)}>
                                {paymentAccountOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </button>
                            {paymentAccountOpen && (
                                <div className="payee-option">
                                    <Button
                                        style={{ color: '#FF7200', display: 'flex', justifyContent: 'start' }}
                                        startIcon={<AddIcon />}
                                        onClick={() => setAddPayementAccountDrawer(true)}
                                    >
                                        Add New...
                                    </Button>
                                    {(paymentAccountListLoading || paymentAccountListFetching) && <Skeleton height={50} />}
                                    {paymentAccountList?.map((paymentAccount) => (
                                        <button
                                            className="dropdown-button"
                                            key={paymentAccount.id}
                                            onClick={() => {
                                                formik.setFieldValue('payment_account', paymentAccount.id);
                                                formik.setFieldValue('payment_account_type', paymentAccount.account_type);
                                                setPaymentAccountOpen(false);
                                            }}
                                        >
                                            {paymentAccount.account_type}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                className="text-input"
                                label="Payment Date"
                                openTo="day"
                                views={['year', 'month', 'day']}
                                value={formik.values.payment_date}
                                onChange={(newValue) => {
                                    formik.setFieldValue('payment_date', newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        name="payment_date"
                                        color="orange"
                                        fullWidth
                                        {...params}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.payment_date && formik.errors.payment_date)}
                                        helperText={formik.touched.payment_date && formik.errors.payment_date}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <TextField
                            fullWidth
                            select
                            className="text-input"
                            color="orange"
                            name="payment_method"
                            label="Payment Method"
                            value={formik.values.payment_method}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.payment_method && formik.errors.payment_method)}
                            helperText={formik.touched.payment_method && formik.errors.payment_method}
                        >
                            <MenuItem value={'Bank'}>Bank</MenuItem>
                            <MenuItem value={'Credit Card'}>Credit Card</MenuItem>
                        </TextField>
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="ref_no"
                            label="Ref No"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ref_no}
                            error={Boolean(formik.touched.ref_no && formik.errors.ref_no)}
                            helperText={formik.touched.ref_no && formik.errors.ref_no}
                        />
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="memo"
                            label="Memo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.memo}
                            error={Boolean(formik.touched.memo && formik.errors.memo)}
                            helperText={formik.touched.memo && formik.errors.memo}
                        />
                    </div>

                    <div>
                        <div style={{ borderBottom: '1px solid #80808052', display: 'flex', justifyContent: 'space-evenly', fontWeight: '600' }}>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}></div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '10%' }}>#</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Category</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Description</div>
                            <div style={{ borderRight: '1px solid #80808052', textAlign: 'center', width: '25%' }}>Amount</div>
                            <div style={{ textAlign: 'center', width: '10%' }}></div>
                        </div>
                        <AnimatePresence>
                            {formik.values.expense_amount.map((amountDes, index) => (
                                <div key={index}>
                                    <AnimatePresence>
                                        {amountDes.open ? (
                                            <motion.div
                                                style={{
                                                    borderBottom: '1px solid #80808052',
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0.5 }}
                                            >
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            expense_amount: prev.expense_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    <ViewCompactIcon sx={{ cursor: 'pointer' }} />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            expense_amount: prev.expense_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {index + 1}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            expense_amount: prev.expense_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {amountDes.category}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            expense_amount: prev.expense_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {amountDes.description}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            expense_amount: prev.expense_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    ${amountDes.amount ? parseFloat(amountDes.amount).toFixed(2) : ''}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }}>
                                                    <RemoveIcon
                                                        sx={{
                                                            backgroundColor: formik.values.expense_amount.length == 1 ? '#ff8995' : 'red',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            fontSize: '20px'
                                                        }}
                                                        onClick={() => {
                                                            if (formik.values.expense_amount.length > 1) {
                                                                removeAmountDescription(index);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </motion.div>
                                        ) : (
                                            <motion.div
                                                style={{
                                                    borderBottom: '1px solid #80808052',
                                                    borderTop: '1px solid #80808052',
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly'
                                                }}
                                                initial={{ scale: 1, opacity: 0 }}
                                                animate={{ scale: 1.05, opacity: 1 }}
                                                exit={{ scale: 1, opacity: 0.5 }}
                                            >
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%'
                                                    }}
                                                >
                                                    <AddIcon
                                                        sx={{ backgroundColor: 'green', color: 'white', borderRadius: '50%', fontSize: '20px' }}
                                                        onClick={() => addAmountDescription(index)}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        formik.setValues((prev) => ({
                                                            ...prev,
                                                            expense_amount: prev.expense_amount.map((amount, i) => {
                                                                if (i === index) {
                                                                    return { ...amount, open: !amount.open };
                                                                } else {
                                                                    return amount;
                                                                }
                                                            })
                                                        }))
                                                    }
                                                    onKeyDown={(e) => {}}
                                                >
                                                    {index + 1}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        onBlur={formik.handleBlur}
                                                        name={`expense_amount[${index}].category`}
                                                        value={amountDes.category_name}
                                                        onChange={() => {
                                                            formik.setFieldValue(`expense_amount[${index}].category_open`, true);
                                                        }}
                                                        onClick={() => {
                                                            formik.setFieldValue(`expense_amount[${index}].category_open`, true);
                                                        }}
                                                        className={
                                                            formik.touched?.expense_amount?.[index]?.category &&
                                                            formik.errors?.expense_amount?.[index]?.category &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderRight: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter category"
                                                    />
                                                    <span
                                                        style={{
                                                            borderTop: '2px solid #80808052',
                                                            borderBottom: '2px solid #80808052',
                                                            borderLeft: '0px',
                                                            borderRight: '0px',
                                                            height: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        {amountDes.category_open ? (
                                                            <KeyboardArrowUpIcon
                                                                sx={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    formik.setValues((prev) => ({
                                                                        ...prev,
                                                                        expense_amount: prev.expense_amount.map((amount, i) => {
                                                                            if (i === index) {
                                                                                return { ...amount, category_open: !amount.category_open };
                                                                            } else {
                                                                                return amount;
                                                                            }
                                                                        })
                                                                    }));
                                                                }}
                                                            />
                                                        ) : (
                                                            <KeyboardArrowDownIcon
                                                                sx={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    formik.setValues((prev) => ({
                                                                        ...prev,
                                                                        expense_amount: prev.expense_amount.map((amount, i) => {
                                                                            if (i === index) {
                                                                                return { ...amount, category_open: !amount.category_open };
                                                                            } else {
                                                                                return amount;
                                                                            }
                                                                        })
                                                                    }));
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                    {amountDes.category_open && (
                                                        <div className="category-choice">
                                                            <Button
                                                                style={{ color: '#FF7200', display: 'flex', justifyContent: 'start' }}
                                                                startIcon={<AddIcon />}
                                                                onClick={() => setAddCategoryDrawer(true)}
                                                            >
                                                                Add New..
                                                            </Button>
                                                            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                                                                {(categoriesLoading || categoriesFetching) && <Skeleton height={50} />}
                                                                {categories?.map((category) => (
                                                                    <button
                                                                        className="dropdown-button"
                                                                        key={category.id}
                                                                        style={{ width: '100%' }}
                                                                        onClick={() => {
                                                                            formik.setFieldValue(`expense_amount[${index}].category`, category.id);
                                                                            formik.setFieldValue(`expense_amount[${index}].category_name`, category.name);
                                                                            formik.setFieldValue(`expense_amount[${index}].category_open`, false);
                                                                        }}
                                                                    >
                                                                        {category.name}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        onBlur={formik.handleBlur}
                                                        name={`expense_amount[${index}].description`}
                                                        value={amountDes.description}
                                                        className={
                                                            formik.touched?.expense_amount?.[index]?.description &&
                                                            formik.errors?.expense_amount?.[index]?.description &&
                                                            'form-error'
                                                        }
                                                        onChange={formik.handleChange}
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter description"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        borderRight: '1px solid #80808052',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '25%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <input
                                                        type="number"
                                                        name={`expense_amount[${index}].amount`}
                                                        onBlur={formik.handleBlur}
                                                        value={amountDes.amount}
                                                        onChange={formik.handleChange}
                                                        className={
                                                            formik.touched?.expense_amount?.[index]?.amount &&
                                                            formik.errors?.expense_amount?.[index]?.amount &&
                                                            'form-error'
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                            width: '100%',
                                                            border: '2px solid #80808052',
                                                            borderLeft: '0px',
                                                            borderRight: '2px solid #80808052',
                                                            outline: 0,
                                                            padding: '5px 0',
                                                            height: '100%'
                                                        }}
                                                        placeholder="Enter the amount"
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }}>
                                                    <RemoveIcon
                                                        sx={{
                                                            backgroundColor: formik.values.expense_amount.length == 1 ? '#ff8995' : 'red',
                                                            color: 'white',
                                                            borderRadius: '50%',
                                                            fontSize: '20px'
                                                        }}
                                                        onClick={() => {
                                                            if (formik.values.expense_amount.length > 1) {
                                                                removeAmountDescription(index);
                                                            } else {
                                                                formik.setValues((prev) => ({
                                                                    ...prev,
                                                                    expense_amount: expenseInitialValues.expense_amount
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            ))}
                        </AnimatePresence>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: 100, fontSize: 20, marginTop: 20, marginRight: 150 }}>
                        <strong>Total</strong>
                        <strong>${totalAmount}</strong>
                    </div>
                    <div style={{ marginBottom: 20, marginTop: 50, display: 'flex', alignItems: 'center', gap: 10 }}>
                        <div style={{ width: '50%' }}>
                            <Typography variant="h4">Attachment</Typography>
                            <input
                                type="file"
                                onChange={(e) => {
                                    formik.setFieldValue('attachment', e.target.files[0]);
                                }}
                                accept=".pdf, .png, .jpg, .jpeg"
                                style={{ display: 'none' }}
                                id="file-input"
                            />
                            <label htmlFor="file-input">
                                <Box
                                    sx={{
                                        border: '2px dashed orange',
                                        borderRadius: '5px',
                                        padding: '20px',
                                        textAlign: 'center',
                                        width: '100%',
                                        cursor: 'pointer',
                                        backgroundColor: '#f9f9f9'
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        formik.setFieldValue('attachment', e.dataTransfer.files[0]);
                                    }}
                                    onDragOver={(e) => e.preventDefault()}
                                >
                                    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                        <DownloadIcon />
                                        <Typography variant="h6" gutterBottom>
                                            <span style={{ fontWeight: 'bold' }}>Choose a file</span> or drag it here
                                        </Typography>
                                    </div>
                                    <span style={{ color: 'green' }}>{formik.values.attachment && formik.values.attachment.name}</span>
                                </Box>
                            </label>
                        </div>
                        <div style={{ width: '50%' }}>
                            {formik.values.attachment && (
                                <>
                                    {typeof formik.values.attachment === 'string' ? (
                                        <>
                                            {formik.values.attachment.endsWith('.pdf') ? (
                                                <embed src={formik.values.attachment} type="application/pdf" width="100%" height="150" />
                                            ) : (
                                                <img src={formik.values.attachment} alt="" style={{ width: '100%', height: '150', objectFit: 'cover' }} />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {formik.values.attachment.type === 'application/pdf' ? (
                                                <embed src={URL.createObjectURL(formik.values.attachment)} type="application/pdf" width="100%" height="150" />
                                            ) : (
                                                <img
                                                    src={URL.createObjectURL(formik.values.attachment)}
                                                    alt=""
                                                    style={{ width: '100%', height: '150', objectFit: 'cover' }}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <LoadingButton loading={postExpensePending} type="submit" fullWidth variant="contained" style={{ backgroundColor: '#EB812E' }}>
                        Save expense
                    </LoadingButton>
                </form>
            </div>
            {/* Add Payment Account Modal */}
            <AddPaymentAccountModal addPayementAccountDrawer={addPayementAccountDrawer} setAddPayementAccountDrawer={setAddPayementAccountDrawer} />

            {/* Add Category Modal */}
            <AddCategoryModal addCategoryDrawer={addCategoryDrawer} setAddCategoryDrawer={setAddCategoryDrawer} />
        </Drawer>
    );
}

export default EditExpenseModal;
