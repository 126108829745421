import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import { Button, Drawer, TextField, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { queryClient } from 'index';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useEffect } from 'react';

const validationSchema = Yup.object({
    dropped_columns: Yup.array().of(
        Yup.object().shape({
            column_name: Yup.string().required('Column Name is required').min(2, 'Minimum 2 character')
        })
    )
});

function AddDroppedColumnModal({ addColumnDrawer, setAddColumnDrawer, droppedColumns }) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const isMobile = useMediaQuery('(max-width:600px)');

    const formik = useFormik({
        initialValues: {
            dropped_columns: [{ column_name: '' }]
        },
        validationSchema,
        onSubmit: (values, actions) => {
            const data = values.dropped_columns.map((col) => col.column_name);
            addDroppedColumnsMutate(data);
            actions.resetForm();
            setAddColumnDrawer(false);
        }
    });

    useEffect(()=>{
        if(droppedColumns?.dropped_columns){
            formik.setValues((prev=>({
                ...prev,
                dropped_columns: droppedColumns?.dropped_columns?.map((col) => ({ column_name: col }))
            })))
        }

    }, [droppedColumns?.dropped_columns])

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, isSubmitting } = formik;

    const postDroppedColumns = async (formData) => {
        const { data } = await axios.post(
            '/v1/api/accounting/fuel-reports/dropped_columns/',
            { dropped_columns: formData },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage.token}`
                }
            }
        );

        return data;
    };

    const { mutate: addDroppedColumnsMutate } = useMutation({
        mutationFn: postDroppedColumns,
        onSuccess: async () => {
            toast.success('Dropped columns updated successfully');
            setAddColumnDrawer(false);
            return await queryClient.invalidateQueries({ queryKey: ['dropped_columns'] });
        },
        onError: () => {
            toast.error('An unexpected error occurred, please contact with support');
        }
    });

    return (
        <Drawer
            open={addColumnDrawer}
            anchor="right"
            onClose={() => {
                setAddColumnDrawer(false);
            }}
        >
            <Box style={{ width: isMobile ? '100vw' : '50vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Column will be removed
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setAddColumnDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <FieldArray name="dropped_columns">
                            {({ remove, push }) => (
                                <>
                                    {values.dropped_columns.map((column, index) => (
                                        <Box key={index} sx={{ display: 'flex', gap: 5, alignItems: 'center', mb: 2 }}>
                                            <TextField
                                                fullWidth
                                                className="text-input"
                                                color="orange"
                                                name={`dropped_columns.${index}.column_name`}
                                                label="Column Name"
                                                value={values.dropped_columns[index].column_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.dropped_columns?.[index]?.column_name && errors.dropped_columns?.[index]?.column_name)}
                                                helperText={touched.dropped_columns?.[index]?.column_name && errors.dropped_columns?.[index]?.column_name}
                                            />
                                            <Button variant="contained" style={{ background: 'white', color: 'red' }} onClick={() => remove(index)}>
                                                <RemoveIcon />
                                            </Button>
                                        </Box>
                                    ))}
                                    <Button variant="contained" style={{ background: 'white', color: 'green' }} onClick={() => push({ column_name: '' })}>
                                        <AddIcon /> Add row
                                    </Button>
                                </>
                            )}
                        </FieldArray>
                        <Box mt={2}>
                            <LoadingButton loading={isSubmitting} fullWidth type="submit" variant="contained" color="orange" sx={{ color: 'white' }}>
                                Submit
                            </LoadingButton>
                        </Box>
                    </Form>
                </FormikProvider>
            </Box>
        </Drawer>
    );
}

export default AddDroppedColumnModal;
