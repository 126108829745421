import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: [], // for active default menu
    opened: true,
    logo: '',
    userName: ''
};

export const customizationSlice = createSlice({
    name: 'customization',
    initialState,
    reducers: {
        handleMenu: (state, action) => {
            state.opened = action.payload;
        },
        menuOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        setLogo: (state, action) => {
            state.logo = action.payload;
        },
        setName: (state, action) => {
            state.userName = action.payload;
        },
        resetLogo: (state) => {
            state.logo = '';
        },
        resetName: (state) => {
            state.userName = '';
        }
    }
});

export const { handleMenu, menuOpen, setLogo, resetLogo, setName, resetName } = customizationSlice.actions;

export const allCustomization = (state) => state.customization;

export default customizationSlice.reducer;
