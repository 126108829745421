import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import { forwardRef } from 'react';

import AddDQFileTypeForm from 'ui-component/safetyCompliance/AddDQFileTypeForm';

import AddVQFileTypeForm from 'ui-component/safetyCompliance/AddVQFileTypeForm';
import AddFileTypeForm from 'ui-component/setup/forms/AddFileTypeForm';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function NewFileTypeModal({ fullWidth, fullScreen, maxWidth, open, handleClose, type, fileId = '', safteyDocList, reloadHandler }) {
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogTitle>
                <Typography variant="h4" color="primary">
                    Add New File Type
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                {type === 'dq' ? (
                    <AddDQFileTypeForm handleClose={handleClose} safteyDocList={safteyDocList} />
                ) : type === 'vq' ? (
                    <AddVQFileTypeForm handleClose={handleClose} safteyDocList={safteyDocList} />
                ) : (
                    <AddFileTypeForm handleClose={handleClose} fileId={fileId} reloadHandler={reloadHandler} />
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

NewFileTypeModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'sm'
};

export default NewFileTypeModal;
