import React from 'react';
import 'ui-component/font/fonts';
import TruckForm from 'ui-component/fleet/forms/TruckForm';
import MainCard from 'ui-component/cards/MainCard';
import { Box } from '@mui/system';
import { Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLayout from 'layout/PageLayout';
import AdminTrailerForm from 'ui-component/fleet/forms/AdminTrailerForm';
import AdminTruckForm from 'ui-component/fleet/forms/AdminTruckForm';
import { Link } from 'react-router-dom';
import user_types from 'constant/user_types';
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '8px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '18px',
                            fontWeight: 900,
                            marginRight: '5px',
                            color: 'white'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});
const AddTruck = () => {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.user_type === 'super_dispatcher_admin' ? '' : loginInfoFromStorage.company_id;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const driver_view = permissions.driver && permissions.driver.view ? true : false;
    const truck_view = permissions.truck && permissions.truck.view ? true : false;
    const trailer_view = permissions.trailer && permissions.trailer.view ? true : false;
    const owner_view = permissions.assetowner && permissions.assetowner.view ? true : false;
    const [value, setValue] = useState((driver_view || user_types.includes(user_type)) + 0);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainCard>
            <PageLayout title="Fleet">
                <Box sx={{ border: 'none', backgroundColor: 'transparent' }}>
                    <ThemeProvider theme={theme}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { display: 'none' } }}
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '1rem'
                                }
                            }}
                        >
                            {(driver_view || user_types.includes(user_type)) && <Tab component={Link} label="Driver" to="/fleet/driver/all" />}
                            {(truck_view || user_types.includes(user_type)) && <Tab component={Link} label="Truck" to="/fleet/truck/all" />}
                            {(trailer_view || user_types.includes(user_type)) && <Tab component={Link} label="Trailer" to="/fleet/trailer/all" />}
                            {(owner_view || user_types.includes(user_type)) && <Tab component={Link} label="Owner" to="/fleet/owner/all" />}
                        </Tabs>
                    </ThemeProvider>
                </Box>

                {cid === '' ? <AdminTruckForm /> : <TruckForm />}
            </PageLayout>
        </MainCard>
    );
};

export default AddTruck;
