import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Box, FormControl, Grid, Skeleton, TextField, Typography } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';

import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import * as Yup from 'yup';

import axios from 'axios';
import SubCard from 'ui-component/cards/SubCard';

import { Stack } from '@mui/system';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import BackFormButton from 'ui-component/buttons/BackFormButton';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const UserProfileForm = (props) => {
    const navigate = useNavigate();
    const [errorAdd, setErrorAdd] = useState('');
    const [loading, setLoading] = useState(false);
    const [userProfile, setUserProfile] = useState();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    useEffect(() => {
        if (!userProfile) {
            fetchUser();
        }
    }, [userProfile]);

    const fetchUser = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/profile/`, config);

            setUserProfile(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };


    const OrganizationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is a required field'),
        lastName: Yup.string().required('Last Name is a required field'),
        email: Yup.string().required('Email is a required field'),
        phone: Yup.string().required('Phone is a required field').typeError('Phone is required')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            firstName: userProfile ? userProfile.first_name : '',
            lastName: userProfile ? userProfile.last_name : '',
            email: userProfile ? userProfile.email : '',
            phone: userProfile ? userProfile.phone : ''
        },
        validationSchema: OrganizationSchema,
        onSubmit: async (values, action) => {
            setLoading(true);
            try {
                const setup = new FormData();
                setup.append('first_name', values.firstName);
                setup.append('last_name', values.lastName);
                setup.append('email', values.email);
                setup.append('username', userProfile && userProfile.username);
                setup.append('company_id', userProfile && userProfile.company_id);

                setup.append('user_type', userProfile && userProfile.user_type);
                setup.append('phone', values.phone);
                setup.append('is_edit', true);
                setup.append('is_active', true);

                const { data } = await axios.put(`/v1/api/users/profile/`, setup, config);
                setUserProfile(data);
                action.resetForm()
                toast.success("Profile updated successfully")
                navigate('/user/profile');
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrorAdd(error.response.data);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.',
        error: Boolean(touched.phone && errors.phone),
        helperText: touched.phone && errors.phone
    };

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography
                                sx={{
                                    mt: '5',
                                    width: '375px',
                                    height: '40px',
                                    fontWeight: '600',
                                    fontSize: '32px',
                                    fontFamily: 'Lexend'
                                }}
                            >
                                User Profile
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <BackFormButton />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    User Information
                                </Typography>
                            </Grid>
                            {/* First Name */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="First Name"
                                        {...getFieldProps('firstName')}
                                        error={Boolean((touched.firstName && errors.firstName) || (errorAdd && errorAdd.first_name))}
                                        helperText={(touched.firstName && errors.firstName) || (errorAdd && errorAdd.first_name)}
                                    />
                                )}
                            </Grid>

                            {/* Last Name */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Last Name"
                                        {...getFieldProps('lastName')}
                                        error={Boolean((touched.lastName && errors.lastName) || (errorAdd && errorAdd.last_name))}
                                        helperText={(touched.lastName && errors.lastName) || (errorAdd && errorAdd.last_name)}
                                    />
                                )}
                            </Grid>

                            {/* email */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Email"
                                        {...getFieldProps('email')}
                                        error={Boolean((touched.email && errors.email) || (errorAdd && errorAdd.email))}
                                        helperText={(touched.email && errors.email) || (errorAdd && errorAdd.email)}
                                    />
                                )}
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <PatternFormat
                                        format="###-###-####"
                                        allowEmptyFormatting
                                        mask="_"
                                        value={values.phone}
                                        onValueChange={(value) => setFieldValue('phone', value.formattedValue)}
                                        customInput={TextField}
                                        {...textFieldProps}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <LoadingFormButton color="orange" type="submit" btnText="Update Information" load={isSubmitting} />
                            </AnimateButton>
                        </Box>
                    </SubCard>
                </Form>
            </FormikProvider>
        </>
    );
};

export default UserProfileForm;
