import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

import PropTypes from 'prop-types';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const MypaymentsViewForm = ({ handleClose, paymentInfo }) => {
    return (
        <>
            <Box>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Typography
                        sx={{
                            mb: 5,
                            textAlign: 'center',
                            width: '375px',
                            height: '40px',
                            fontWeight: '600',
                            fontSize: '32px',
                            fontFamily: 'Lexend'
                        }}
                    >
                        Payments Details
                    </Typography>
                </Stack>

                <Grid container spacing={3}>
                    {/* DQ type */}

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h3">{paymentInfo && paymentInfo.cust_name}</Typography>
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography variant="h4">Load No</Typography>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <Typography variant="body1">{paymentInfo && paymentInfo.load_no}</Typography>
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography variant="h4">Invoice</Typography>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <Typography variant="body1">{paymentInfo && paymentInfo.invoice_id}</Typography>
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography variant="h4">Amount Paid</Typography>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <Typography variant="body1">{paymentInfo && paymentInfo.amount}</Typography>
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography variant="h4">Payment Date</Typography>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <Typography variant="body1">
                            {paymentInfo.payment_date ? format(new Date(paymentInfo.payment_date + 'T00:00'), 'dd/MM/yyyy') : 'N/A'}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Typography variant="h4">Invoice Date</Typography>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <Typography variant="body1">{paymentInfo.inv_date ? format(new Date(paymentInfo.inv_date), 'dd/MM/yyyy') : 'N/A'}</Typography>
                    </Grid>
                </Grid>

                <Box sx={{ mt: 2 }} align="center">
                    <AnimateButton>
                        <Button
                            variant="contained"
                            sx={{
                                mr: 2,
                                color: 'white',
                                backgroundColor: '#EB812E'
                            }}
                            color="orange"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </AnimateButton>
                </Box>
            </Box>
        </>
    );
};

MypaymentsViewForm.propTypes = {
    reloadData: PropTypes.any,
    id: PropTypes.any,
    docId: PropTypes.any
};

export default MypaymentsViewForm;
