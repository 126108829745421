// project imports
import { useState, useEffect } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import MainCard from 'ui-component/cards/MainCard';

import { useDispatch, useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';

import { useNavigate } from 'react-router';

import AllExpenseItemForm from 'ui-component/setup/forms/AllExpenseItemForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLayout from 'layout/PageLayout';
import user_types from 'constant/user_types';
// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '8px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '18px',
                            fontWeight: 900,
                            marginRight: '5px',
                            color: 'white'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});
const AllExpenseItem = () => {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions;

    const users_view = permissions.dispatcheradmin && permissions.dispatcheradmin.view ? true : false;
    const integration_view = permissions.integration && permissions.integration.view ? true : false;
    const data_import_view = permissions.data_import && permissions.data_import.view ? true : false;
    const invoice_expense_item_view = permissions.invoice_expense_item && permissions.invoice_expense_item.view ? true : false;
    const settlement_expense_item_view = permissions.settlement_expense_item && permissions.settlement_expense_item.view ? true : false;

    const [value, setValue] = useState(user_types.includes(user_type) ? 3 : users_view + integration_view + data_import_view + 0);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    return (
        <>
            <MainCard>
                <PageLayout title="Setup">
                    <Box sx={{ border: 'none', background: 'transparent' }}>
                        <ThemeProvider theme={theme}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                TabIndicatorProps={{ style: { display: 'none' } }}
                                sx={{
                                    '.MuiTabs-flexContainer': {
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '.5rem'
                                    }
                                }}
                            >
                                {(user_type == 'mechanic_admin' || users_view || user_types.includes(user_type)) && (
                                    <Tab label="Users" onClick={() => navigate(`/setup/user/all`)} />
                                )}
                                {((user_type !== 'mechanic_admin' && integration_view) || user_types.includes(user_type)) && (
                                    <Tab label="Integration" onClick={() => navigate(`/setup/integration`)} />
                                )}
                                {((user_type !== 'mechanic_admin' && data_import_view) || user_types.includes(user_type)) && (
                                    <Tab label="Data Import" onClick={() => navigate(`/setup/data/import`)} />
                                )}
                                {((user_type !== 'mechanic_admin' && invoice_expense_item_view) || user_types.includes(user_type)) && (
                                    <Tab label="Invoice Expense Item" onClick={() => navigate(`/setup/invoice/expense/item`)} />
                                )}
                                {((user_type !== 'mechanic_admin' && settlement_expense_item_view) || user_types.includes(user_type)) && (
                                    <Tab label="Settlement Expense Item" onClick={() => navigate(`/setup/settlement/expense/item`)} />
                                )}
                                {/* <Tab label="File Configuration" onClick={() => navigate(`/setup/configuration/file`)} /> */}
                            </Tabs>
                        </ThemeProvider>
                    </Box>

                    <AllExpenseItemForm />
                </PageLayout>
            </MainCard>
        </>
    );
};

export default AllExpenseItem;
