import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// project imports
import { getMenuItems } from 'menu-items';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';

import { drawerWidth } from 'store/constant';
// import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import dashboard_bg from 'assets/images/dashboard_bg/dashboard_bg@3x.png';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleMenu, setLogo, setName } from 'store/ui/customization';
import ErrorSnackbar from 'ui-component/snack/ErrorSnackbar';
import SuccessSnackbar from 'ui-component/snack/SuccessSnackbar';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            padding: 0,
            marginRight: '1px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const logo = useSelector((state) => state.customization.logo);

    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        // dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
        dispatch(handleMenu(!leftDrawerOpened));
    };

    const navigate = useNavigate();
    const navigation = getMenuItems();

    const isLoggedIn = localStorage.getItem('loginUserInfo') || null;

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login', { replace: true });
        }
        if (!logo || logo === '') {
            fetchCompany();
        }
    }, [matchDownMd, isLoggedIn, navigate, logo]);

    const fetchCompany = async () => {
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);
            dispatch(setLogo(data.logo));
            dispatch(setName(data.dsp_company_name));
        } catch (error) {
            console.log(error.response.data);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {/* <Box sx={{ display: 'flex', overflowX: 'hidden' }}> */}
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main
                theme={theme}
                open={leftDrawerOpened}
                sx={{
                    backgroundImage: `url(${dashboard_bg}) `,
                    backgroundSize: 'Cover',

                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}
            >
                <SuccessSnackbar />
                <ErrorSnackbar />
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            {/* <Customization /> */}
            {/* toast flash message */}
            <ToastContainer theme="colored" />
        </Box>
    );
};

export default MainLayout;
