import { MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function ProfitLossStackedChart({ send, isReady }) {
    const currentYear = Number(new Date().getFullYear());
    const years = [currentYear, currentYear - 1, currentYear - 2];

    const [year, setYear] = useState(currentYear);
    const [startMonth, setStartMonth] = useState(1);
    const [endMonth, setEndMonth] = useState(12);

    const dashboardState = useSelector((state) => state.dashboard);
    const [isRequest, setIsRequest] = useState(true);

    const timeFrame = dashboardState.profitLoss?.time_frame || ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    let profit = dashboardState.profitLoss?.profit || [1, 2, 0, 4, 5, 6, 7, 8, 9, 10];
    let loss = dashboardState.profitLoss?.loss || [1, 2, -5, 4, 5, 6, 7, 8, 9, 10];

    const isMobile = window.innerWidth < 600;
    // const isTablet = window.innerWidth >= 600 && window.innerWidth <= 1024;
    // const isLaptop = window.innerWidth >= 1024 && window.innerWidth <= 1200;

    const isTablet = window.innerWidth >= 600 && window.innerWidth < 1024;
    const isLaptop = window.innerWidth >= 1024 && window.innerWidth <= 1200;

    const option = {
        color: ['#142953', 'red'],
        animationDuration: 1500,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#283b56'
                }
            },
            textStyle: {
                fontSize: isMobile ? 8 : isTablet ? 16 : 12
            }
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            data: timeFrame,
            name: 'Day',
            axisLine: { onZero: true },
            splitLine: { show: false },
            splitArea: { show: false },
            axisLabel: {
                fontSize: isMobile ? 10 : isTablet ? 16 : 12
            }
        },
        yAxis: {
            axisLabel: {
                fontSize: isMobile ? 9 : isTablet ? 18 : isLaptop ? 10 : 12
            }
        },
        grid: {
            bottom: isMobile ? 60 : isTablet ? 65 : isLaptop ? 85 : 50,
            top: isMobile ? 40 : isTablet ? 35 : isLaptop ? 20 : 50,
            left: isMobile ? 30 : isTablet ? 55 : 50,
            right: isMobile ? 38 : isTablet ? 40 : 60
        },
        series: [
            {
                name: 'Profit',
                type: 'bar',
                stack: 'one',
                barMaxWidth: isMobile ? 15 : 30,
                barGap: isMobile ? '5%' : '10%',
                data: profit
            },
            {
                name: 'Loss',
                type: 'bar',
                stack: 'one',
                barMaxWidth: isMobile ? 15 : 30,
                barGap: isMobile ? '5%' : '10%',
                data: loss
            }
        ]
    };

    const handleYearChange = (e) => {
        setIsRequest(true);
        setYear(e.target.value);
    };

    const handleStartMonthChange = (e) => {
        setIsRequest(true);
        setStartMonth(e.target.value);
    };

    const handleEndMonthChange = (e) => {
        setIsRequest(true);
        setEndMonth(e.target.value);
    };

    useEffect(() => {
        if (isRequest && isReady) {
            send({ event_type: 'profit_loss', year: Number(year), start_month: Number(startMonth), end_month: Number(endMonth) });
            setIsRequest(false);
        }
    }, [isReady, year, startMonth, endMonth]);
    return (
        // <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '20px', borderRadius: '15px', height: { xs: '70vh', md: '30.5vh', lg: '37.8vh' } }}>
        <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '20px', borderRadius: '15px', height: { xs: '70vh', sm: '45.8vh', lg: '37.8vh' } }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: { sm: 'start', lg: 'center' },
                    flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        lg: 'row'
                    },
                    gap: { md: '1rem' }
                }}
            >
                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                <Typography
                    sx={{
                        fontSize: { xs: '6vw', lg: '1.7vw', sm: '3vw' },
                        margin: 0
                    }}
                >
                    Profit and Loss
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: { xs: 1, sm: 2 },
                        mt: { xs: '1rem', sm: '0' }
                    }}
                >
                    <TextField select label="Year" value={year} onChange={handleYearChange}>
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField select label="Start Month" value={startMonth} onChange={handleStartMonthChange}>
                        {monthList.map((startMonth, index) => (
                            <MenuItem key={startMonth} value={index + 1}>
                                {startMonth}
                                {/* <Typography sx={{ fontSize: { xs: '3.3vw', sm: '1.6vw', lg: '1vw' } }}>{startMonth}</Typography> */}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField select label="End Month" value={endMonth} onChange={handleEndMonthChange}>
                        {monthList.map((endMonth, index) => (
                            <MenuItem key={endMonth} value={index + 1}>
                                {endMonth}
                                {/* <Typography sx={{ fontSize: { xs: '3.3vw', sm: '1.6vw', lg: '1vw' } }}>{endMonth}</Typography> */}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
            {dashboardState.profitLoss !== undefined ? (
                // <Box sx={{ height: '100%', width: '100%' }}>
                <ReactECharts option={option} style={{ height: '75%', width: '100%', marginTop: 0 }} />
            ) : (
                //  <ReactECharts option={option} style={{ height: '280px', marginTop: 0 }} />

                // </Box>
                <Skeleton style={{ height: '280px', marginTop: '-32px' }} />
                // <Skeleton style={{ height: '280px', marginTop: 0 }} />
            )}

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: { xs: '-1rem 0', md: '-4.1vh 0', lg: '-1.3rem 0', xl: '-.9rem 0' }
                    // margin: { xs: '-1rem 0', md: '-1rem 0', lg: '0px 100px' }
                }}
            >
                <div style={{ textAlign: 'center' }}>
                    <h4 style={{ margin: 0, padding: 0 }}>${dashboardState.profitLoss?.net_income || 0}</h4>
                    <h5 style={{ margin: 0, padding: 0 }}>Net Income</h5>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <h4 style={{ margin: 0, padding: 0 }}>${dashboardState.profitLoss?.net_expense || 0}</h4>
                    <h5 style={{ margin: 0, padding: 0 }}>Expenses</h5>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <h4 style={{ margin: 0, padding: 0 }}>${dashboardState.profitLoss?.net_revenue || 0}</h4>
                    <h5 style={{ margin: 0, padding: 0 }}>Revenues</h5>
                </div>
            </Box>
        </Box>
    );
}

export default ProfitLossStackedChart;
