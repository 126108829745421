import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui
// import { useTheme } from '@mui/material/styles';
import { Button, FormControl, FormHelperText, Grid, IconButton, MenuItem, Skeleton, TextField, Typography } from '@mui/material';

// project imports
// import useScriptRef from 'hooks/useScriptRef';

// third party
import * as Yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { Stack } from '@mui/system';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const AddIFTAForm = () => {
    // const theme = useTheme();
    // const scriptedRef = useScriptRef();
    // const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [driverList, setDriverList] = useState();
    const [loading, setLoading] = useState(false);
    const [driverLoading, setDriverLoading] = useState(false);
    const [stateLoading, setStateLoading] = useState(false);
    const [eldLoading, setEldLoading] = useState(false);

    const [addError, setAddError] = useState();
    const [stateList, setStateList] = useState();

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const ELDSchema = Yup.object().shape({
        driver: Yup.number().required('Driver is a required field'),
        startDate: Yup.string().required('Start Date is a required field'),
        endDate: Yup.string().required('End Date is a required field')
    });

    const IFTASchema = Yup.object().shape({
        stateSelect: Yup.array().of(
            Yup.object().shape({
                state_id: Yup.number().required('This field is required'),
                total_mileage: Yup.number().required('This field is required'),
                total_gallons: Yup.number().required('This field is required')
            })
        )
    });

    const stateSelection = { state_id: '', tot_millage: '', tot_gallons: '' };
    useEffect(() => {
        if (!driverList) {
            fetchDriverList();
        }
        if (driverList && !stateList) {
            fetchStateList();
        }
    }, [driverList, stateList]);

    const fetchDriverList = async () => {
        setDriverLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100`, config);
            setDriverList(data.results);
            setDriverLoading(false);
        } catch (error) {
            setDriverLoading(false);
            // console.log(error);
        }
    };
    const fetchStateList = async () => {
        setStateLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);
            setStateList(data);
            setStateLoading(false);
        } catch (error) {
            setStateLoading(false);
            // console.log(error.response.data);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            stateSelect: [{ state_id: '', total_mileage: '', total_gallons: 0.0 }]
        },
        validationSchema: IFTASchema,

        onSubmit: async () => {
            try {
                const { data } = await axios.post(
                    `/v1/api/accounting/ifta/add/`,
                    {
                        start_date: format(new Date(formik1.values.startDate), 'yyyy-MM-dd'),
                        end_date: format(new Date(formik1.values.endDate), 'yyyy-MM-dd'),

                        driver_id: formik1.values.driver,
                        company_id: loginInfoFromStorage.company_id,
                        is_active: true,
                        is_edit: true,
                        ifta_items: values.stateSelect.map(({ state_id, total_mileage, total_gallons }) => ({
                            state_id: state_id,
                            tot_millage: Number(total_mileage),
                            tot_gallons: Number(total_gallons)
                        }))
                    },
                    config
                );
                dispatch(openSuccessSnackbar('IFTA added successfully'));
                navigate(`/accounting/ifta/all`, { replace: true });
            } catch (error) {
                setAddError(error.response.data);
            }
            // );
        }
    });

    const formik1 = useFormik({
        enableReinitialize: true,

        initialValues: {
            driver: '',
            startDate: '',
            endDate: ''
        },
        validationSchema: ELDSchema,

        onSubmit: async () => {
            setEldLoading(true);
            try {
                const { data } = await axios.post(
                    `/v1/api/accounting/ifta/eld-get/`,
                    {
                        start_date: format(new Date(formik1.values.startDate), 'yyyy-MM-dd'),
                        end_date: format(new Date(formik1.values.endDate), 'yyyy-MM-dd'),

                        driver_id: formik1.values.driver
                    },
                    config
                );
                setEldLoading(false);
                setFieldValue('stateSelect', data);
                dispatch(openSuccessSnackbar('IFTA data fetched successfully'));
            } catch (error) {
                setEldLoading(false);
                setAddError(error.response.data);
                if (error.response.data?.error) {
                    dispatch(openErrorSnackbar(error.response.data?.error));
                }
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit } = formik;

    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                        sx={{
                            mt: '20px',
                            width: '375px',
                            height: '40px',
                            fontWeight: '600',
                            fontSize: '32px',
                            fontFamily: 'Lexend'
                        }}
                    >
                        IFTA
                    </Typography>
                </Grid>
            </Grid>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                <FormikProvider value={formik1}>
                    <Form autoComplete="off" onSubmit={formik1.handleSubmit} encType="multipart/form-data">
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {/* Customer */}

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Driver Section
                                    </Typography>
                                </Grid>
                                {/* Driver */}
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    {driverLoading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            select
                                            label="Driver"
                                            value={formik1.values.driver}
                                            onChange={(e) => {
                                                formik1.setFieldValue('driver', e.target.value);
                                            }}
                                            error={Boolean((formik1.touched.driver && formik1.errors.driver) || (addError && addError.driver_id))}
                                            helperText={(formik1.touched.driver && formik1.errors.driver) || (addError && addError.driver_id)}
                                        >
                                            <MenuItem value={0}>Select Driver</MenuItem>
                                            {driverList && driverList.map((i) => <MenuItem value={i.driver_id}>{i.name}</MenuItem>)}
                                        </TextField>
                                    )}
                                </Grid>

                                {/* Start Date */}
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="text-input"
                                            label="Start Date"
                                            openTo="day"
                                            views={['year', 'month', 'day']}
                                            value={formik1.values.startDate}
                                            onChange={(newValue) => {
                                                formik1.setFieldValue(`startDate`, newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    color="orange"
                                                    fullWidth
                                                    {...params}
                                                    error={Boolean(
                                                        (formik1.touched.startDate && formik1.errors.startDate) || (addError && addError.start_date)
                                                    )}
                                                    helperText={(formik1.touched.startDate && formik1.errors.startDate) || (addError && addError.start_date)}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                {/* Start Date */}
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="text-input"
                                            label="End Date"
                                            openTo="day"
                                            views={['year', 'month', 'day']}
                                            value={formik1.values.endDate}
                                            onChange={(newValue) => {
                                                formik1.setFieldValue(`endDate`, newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    color="orange"
                                                    fullWidth
                                                    {...params}
                                                    error={Boolean((formik1.touched.endDate && formik1.errors.endDate) || (addError && addError.end_date))}
                                                    helperText={(formik1.touched.endDate && formik1.errors.endDate) || (addError && addError.end_date)}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item lg={2} md={2} sm={12} xs={12} sx={{ mt: 1 }}>
                                    <LoadingFormButton
                                        load={formik1.isSubmitting || eldLoading}
                                        type="submit"
                                        btnText="Get Report"
                                        variant="contained"
                                        component="span"
                                    />
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Form>
                </FormikProvider>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3} sx={{ mb: 4 }}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Typography
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        State Selection
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                        <FormControl variant="outlined" align="right">
                                            <LoadingFormButton load={isSubmitting} type="submit" btnText="Save" variant="contained" component="span" />
                                        </FormControl>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <FieldArray name="stateSelect">
                                {({ push, remove }) => (
                                    <>
                                        {values.stateSelect.length > 0 &&
                                            values.stateSelect.map((i, index) => {
                                                const stateErrors = (errors.stateSelect?.length && errors.stateSelect[index]) || {};
                                                const stateTouched = (touched.stateSelect?.length && touched.stateSelect[index]) || {};
                                                return (
                                                    <div key={index}>
                                                        <Grid container spacing={3} sx={{ mb: 5 }}>
                                                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                                                {stateLoading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        select
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="State"
                                                                        value={values.stateSelect[index].state_id}
                                                                        onChange={(e) => setFieldValue(`stateSelect[${index}].state_id`, e.target.value)}
                                                                        error={Boolean(stateTouched.state_id && stateErrors.state_id)}
                                                                        helperText={stateTouched.state_id && stateErrors.state_id}
                                                                    >
                                                                        <MenuItem value="">Select State</MenuItem>
                                                                        {stateList && stateList.map((i) => <MenuItem value={i.id}>{i.name}</MenuItem>)}
                                                                    </TextField>
                                                                )}
                                                            </Grid>
                                                            {/* City */}
                                                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    type="text"
                                                                    className="text-input"
                                                                    label="Total Mileage"
                                                                    value={values.stateSelect[index].total_mileage}
                                                                    onChange={(e) =>
                                                                        setFieldValue(
                                                                            `stateSelect[${index}].total_mileage`,
                                                                            !isNaN(e.target.value) ? e.target.value : ''
                                                                        )
                                                                    }
                                                                    error={Boolean(stateTouched.total_mileage && stateErrors.total_mileage)}
                                                                    helperText={stateTouched.total_mileage && stateErrors.total_mileage}
                                                                />
                                                            </Grid>
                                                            {/* Total Gallos */}
                                                            <Grid item lg={3} md={11} sm={11} xs={11}>
                                                                <TextField
                                                                    fullWidth
                                                                    type="text"
                                                                    className="text-input"
                                                                    label="Total Gallons"
                                                                    value={values.stateSelect[index].total_gallons}
                                                                    onChange={(e) =>
                                                                        setFieldValue(
                                                                            `stateSelect[${index}].total_gallons`,
                                                                            !isNaN(e.target.value) ? e.target.value : 0
                                                                        )
                                                                    }
                                                                    error={Boolean(stateTouched.total_gallons && stateErrors.total_gallons)}
                                                                    helperText={stateTouched.total_gallons && stateErrors.total_gallons}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={1} md={1} sm={1} xs={1}>
                                                                {values.stateSelect.length > 1 && (
                                                                    <IconButton
                                                                        disabled={isSubmitting}
                                                                        onClick={() => remove(index)}
                                                                        size="small"
                                                                        color="error"
                                                                    >
                                                                        <DeleteForeverIcon fontSize="large" />
                                                                    </IconButton>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        {/* </Grid> */}
                                                    </div>
                                                );
                                            })}
                                        <Grid item xs={6}>
                                            <Button
                                                disabled={isSubmitting}
                                                variant="contained"
                                                color="orange"
                                                onClick={() => push(stateSelection)}
                                                size="medium"
                                                sx={{
                                                    fontFamily: 'Lexend',
                                                    mt: 1,
                                                    color: 'white',
                                                    height: '36px',
                                                    fontSize: '16px',
                                                    borderRadius: '4px'
                                                }}
                                            >
                                                Add State
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </FieldArray>
                        </SubCard>
                    </Form>
                </FormikProvider>
            </SubCard>
            {/* </Grid> */}
        </>
    );
};

export default AddIFTAForm;
