import { MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function DriverMoneyPaidChart({ send, isReady }) {
    const [selectedOption, setSelectedOption] = useState('yearly');
    const dashboardState = useSelector((state) => state.dashboard);
    const [isRequest, setIsRequest] = useState(true);

    const isMobile = window.innerWidth < 600;
    const isTablet = window.innerWidth >= 600 && window.innerWidth <= 1024;
    const option = {
        animationDuration: 1500,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#283b56'
                }
            }
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        color: ['#F48120'],
        grid: {
            left: '5%',
            right: '4%',
            bottom: '20%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: dashboardState.driversSettlement?.drivers || ['Driver1', 'Driver2', 'Driver3', 'Driver4', 'Driver5'],
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    fontSize: isMobile ? 10 : isTablet ? 13 : 12
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    fontSize: isMobile ? 10 : isTablet ? 13 : 12
                }
            }
        ],
        grid: {
            bottom: isMobile ? 60 : 50,
            top: isMobile ? 40 : 50,
            left: isMobile ? 38 : 50,
            right: isMobile ? 54 : 60
        },
        series: [
            {
                name: 'Money Received',
                type: 'bar',
                data: dashboardState.driversSettlement?.settlements || [180, 52, 200, 334, 390],
                barMaxWidth: isMobile ? 20 : 30
            }
        ]
    };

    const handleChange = (e) => {
        setIsRequest(true);
        setSelectedOption(e.target.value);
    };

    useEffect(() => {
        if (isRequest && isReady) {
            send({ event_type: 'drivers_settlement', quarter: selectedOption });
            setIsRequest(false);
        }
    }, [isReady, selectedOption]);

    return (
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '20px', borderRadius: '15px', height: '400px' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: { xs: 'start', sm: 'center' },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    }
                }}
            >
                {' '}
                <Typography
                    sx={{
                        fontSize: { xs: '6vw', lg: '1.7vw', sm: '3vw' },
                        margin: 0
                    }}
                >
                    Driver Payments
                </Typography>
                <TextField
                    select
                    value={selectedOption}
                    onChange={handleChange}
                    sx={{
                        padding: { xs: '0', sm: '10px 20px' },
                        borderRadius: '5px',
                        fontWeight: '800',
                        WebkitBackgroundOrigin: 'initial',
                        mt: { xs: '1rem', sm: '0' }
                    }}
                >
                    <MenuItem value={'weekly'}>Current Week</MenuItem>
                    <MenuItem value={'monthly'}>Current Month</MenuItem>
                    <MenuItem value={'yearly'}>Current Year</MenuItem>
                </TextField>
            </Box>
            {dashboardState.driversSettlement?.drivers?.length === 0 ? (
                <div style={{ display: 'grid', height: '100%', placeItems: 'center' }}>No Driver Found</div>
            ) : (
                <>
                    {dashboardState.driversSettlement !== undefined ? (
                        <ReactECharts option={option} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
                    ) : (
                        <Skeleton height={'100%'} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
                    )}
                </>
            )}
        </div>
    );
}

export default DriverMoneyPaidChart;
