import { useEffect, useState } from 'react';

import {
    Autocomplete,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Skeleton,
    TextField,
    Typography
} from '@mui/material';

import { Stack } from '@mui/system';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import * as Yup from 'yup';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import SubCard from 'ui-component/cards/SubCard';

import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const TruckForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [truckDetails, setTruckDetails] = useState();
    const [driverList, setDriverList] = useState();
    const [ownerList, setOwnerList] = useState();
    const [stateList, setStateList] = useState();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const truckConfig = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!stateList) {
            fetchState();
        }
        if (!ownerList) {
            fetchOwner();
        }
        if (!driverList) {
            fetchDriver();
        }
        if (id && driverList && !truckDetails) {
            fetchTruck(id);
        }
    }, [ownerList, driverList, stateList, truckDetails, id]);

    const fetchDriver = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `/v1/api/users/company/drivers/slim?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100&search=`,
                config
            );
            setDriverList(data.results);
            setOptions(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchState = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchTruck = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/fleet/truck/view/${id}/`, config);

            setTruckDetails(data);
            const drv = data.driver_id;
            const drvOi = drv.map((i) => driverList.filter(({ driver_id }) => driver_id === i)[0]);

            setFieldValue('driver', drvOi);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchOwner = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/fleet/owner/list?company_id=${loginInfoFromStorage.company_id}&page=1&page_size=100&search=`, config);

            setOwnerList(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    let aFiles = [
        {
            drv_doc_id: 0,
            stf_doc_type_name: 'Every Three Month Inspection',
            stf_doc_type: 'every_three_month_inspection',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            stf_doc_type_name: 'Annual Periodic Inspection',
            stf_doc_type: 'annual_periodic_inspection',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            stf_doc_type_name: 'Reports of Any Maintenance',
            stf_doc_type: 'reports_of_any_maintenance',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            stf_doc_type_name: 'Road Side Inspection Report',
            stf_doc_type: 'road_side_inspection_report',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        }
    ];

    let qFiles = [
        {
            drv_doc_id: 0,
            stf_doc_type_name: 'Insurance',
            stf_doc_type: 'insurance',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            stf_doc_type_name: 'Annual Inspection',
            stf_doc_type: 'annual_inspection',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            stf_doc_type_name: 'Cab Card',
            stf_doc_type: 'cab_card',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            stf_doc_type_name: 'Initial Inspection',
            stf_doc_type: 'initial_inspection',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        },
        {
            drv_doc_id: 0,
            stf_doc_type_name: 'Registration Card',
            stf_doc_type: 'registration_card',
            sft_doc: '',
            sft_doc_name: '',
            doc_status: 'Not Submitted'
        }
    ];

    const TruckSchema = Yup.object().shape({
        // driver: Yup.mixed().required('driver is a required field'),
        primaryDriver: Yup.mixed().required('Primary Driver is a required field'),
        unit: Yup.string().required('unit is a required field'),
        licenseNo: Yup.string().required('license No is a required field'),
        licenseState: Yup.number().required('license No is a required field').typeError('license No is a required field'),
        vinNo: Yup.string().required('vin No is a required field'),
        owner: Yup.string().required('Owner is a required field'),
        ownFuel: Yup.boolean().required('Own Fuel Card is a required field'),
        has_discounted_price: Yup.boolean(),
        dispatcher_expense: Yup.number().required('Dispatcher expense is required'),
        insurance_expense: Yup.number().required('Insurance expense is required'),
        settlement_type: Yup.string().required('Settlement Type is required'),
        settlement_value: Yup.number().when('settlement_type', {
            is: 'flat', // if settlement_type is 'flat'
            then: Yup.number().default(0), // then set default value to 0
            otherwise: Yup.number().required('This field is required') // otherwise, make it required
        })
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            unit: id && truckDetails ? truckDetails.unit_no : '',
            primaryDriver: id && truckDetails && driverList ? driverList.filter(({ driver_id }) => driver_id === truckDetails.prm_driver_id)[0] : '',
            driver: id && truckDetails && driverList ? truckDetails.driver_id.map((i) => driverList.filter(({ driver_id }) => driver_id === i)[0]) : [],
            make: id && truckDetails ? truckDetails.make : '',
            year: id && truckDetails ? truckDetails.make_year : '',
            licenseNo: id && truckDetails ? truckDetails.license_no : '',
            owner: id && truckDetails ? truckDetails.owner_id : '',
            licenseState: id && truckDetails ? truckDetails.state_id : '',
            fuelCard: id && truckDetails ? truckDetails.fuel_card : '',
            ownFuel: id && truckDetails ? truckDetails.own_fuel_card : false,
            model: id && truckDetails ? truckDetails.model : '',
            vinNo: id && truckDetails ? truckDetails.vin_no : '',
            filesQ: id && truckDetails ? truckDetails.sft_q_docs : qFiles,
            filesA: id && truckDetails ? truckDetails.sft_a_docs : aFiles,
            has_discounted_price: id && truckDetails ? truckDetails.has_discounted_price : false,
            dispatcher_expense: id && truckDetails ? truckDetails.dispatcher_expense : '',
            insurance_expense: id && truckDetails ? truckDetails.insurance_expense : '',
            settlement_type: id && truckDetails ? truckDetails.settlement_type : '',
            settlement_value: id && truckDetails ? truckDetails.settlement_value : ''
        },
        validationSchema: TruckSchema,
        onSubmit: async (values) => {
            try {
                const exists = values.driver.find(({ driver_id }) => driver_id === values.primaryDriver.driver_id);
                if (exists) {
                    setFieldError('driver', 'Primary Driver and Co-Driver should not be same');
                    return;
                }

                const truckDet = {
                    driver_id: values.driver ? values.driver : [],
                    unit_no: values.unit,
                    make: values.make,
                    model: values.model,
                    make_year: values.year ? format(new Date(values.year), 'yyyy') : '',
                    license_no: values.licenseNo,
                    vin_no: values.vinNo,
                    company_id: loginInfoFromStorage.company_id,
                    state_id: values.licenseState,
                    owner_id: values.owner,
                    fuel_card: values.fuelCard,
                    prm_driver_id: values.primaryDriver,
                    // company_id: 39,
                    is_active: true,
                    is_edit: true,
                    own_fuel_card: values.ownFuel,
                    has_discounted_price: values.has_discounted_price,
                    dispatcher_expense: values.dispatcher_expense,
                    insurance_expense: values.insurance_expense,
                    settlement_type: values.settlement_type,
                    settlement_value: values.settlement_type === 'flat' && !values.settlement_value ? 0 : values.settlement_value
                };

                const setup = new FormData();

                values.filesQ.forEach((i, index) => {
                    setup.append(`sft_q_docs[${index}].sft_doc`, i.sft_doc);
                    setup.append(`sft_q_docs[${index}].sft_doc_name`, i.sft_doc_name);
                    setup.append(`sft_q_docs[${index}].drv_doc_id`, i.drv_doc_id);
                    setup.append(`sft_q_docs[${index}].stf_doc_type`, i.stf_doc_type);
                    setup.append(`sft_q_docs[${index}].stf_doc_type_name`, i.stf_doc_type_name);
                });

                values.filesA.forEach((i, index) => {
                    setup.append(`sft_a_docs[${index}].sft_doc`, i.sft_doc);
                    setup.append(`sft_a_docs[${index}].sft_doc_name`, i.sft_doc_name);
                    setup.append(`sft_a_docs[${index}].drv_doc_id`, i.drv_doc_id);
                    setup.append(`sft_a_docs[${index}].stf_doc_type`, i.stf_doc_type);
                    setup.append(`sft_a_docs[${index}].stf_doc_type_name`, i.stf_doc_type_name);
                });

                setup.append('truckData', JSON.stringify(truckDet));

                if (!id) {
                    await axios.post(`/v1/api/fleet/truck/add`, setup, truckConfig);
                    toast.success('Truck added successfully');
                } else {
                    await axios.put(`/v1/api/fleet/truck/view/${id}/`, setup, truckConfig);
                    toast.success('Truck updated successfully');
                }
                setTimeout(() => {
                    navigate('/fleet/truck/all');
                }, 1000);
            } catch (error) {
                toast.error('Please contact with support');
                console.log(error.response.data || error);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit, setFieldError } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                <Typography
                                    sx={{
                                        mt: '20px',
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '32px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    Truck Details
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Stack direction="row" sx={{ mt: '20px' }} justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <LoadingFormButton load={isSubmitting} type="submit" btnText="Save" />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>
                    <SubCard
                        sx={{
                            mt: 5,
                            borderRadius: '8px',
                            mb: 2,
                            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)'
                        }}
                    >
                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            // width: '19.5vw',
                                            // width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        General Information
                                    </Typography>
                                </Grid>
                                {/* Driver */}
                                {/* Unit No. */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="UNIT#"
                                            {...getFieldProps('unit')}
                                            error={Boolean(touched.unit && errors.unit)}
                                            helperText={touched.unit && errors.unit}
                                        />
                                    )}
                                </Grid>
                                {/* Make */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField fullWidth className="text-input" color="orange" type="text" label="Make" {...getFieldProps('make')} />
                                    )}
                                </Grid>
                                {/* year */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                className="text-input"
                                                label="Year"
                                                openTo="day"
                                                views={['year']}
                                                value={values.year}
                                                onChange={(newValue) => {
                                                    setFieldValue('year', newValue);
                                                }}
                                                renderInput={(params) => <TextField className="text-input" color="orange" fullWidth {...params} error={null} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>
                                {/* license state */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            select
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="License State"
                                            {...getFieldProps('licenseState')}
                                            error={Boolean(touched.licenseState && errors.licenseState)}
                                            helperText={touched.licenseState && errors.licenseState}
                                        >
                                            <MenuItem value="">Select State</MenuItem>
                                            {stateList &&
                                                stateList.map((i) => (
                                                    <MenuItem key={i.name} value={i.id}>
                                                        {i.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    )}
                                </Grid>
                                {/* License */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="License Plate"
                                            {...getFieldProps('licenseNo')}
                                            error={Boolean(touched.licenseNo && errors.licenseNo)}
                                            helperText={touched.licenseNo && errors.licenseNo}
                                        />
                                    )}
                                </Grid>
                                {/* model */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField fullWidth className="text-input" color="orange" type="text" label="Model" {...getFieldProps('model')} />
                                    )}
                                </Grid>
                                {/* Vin */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="VIN Number"
                                            {...getFieldProps('vinNo')}
                                            error={Boolean(touched.vinNo && errors.vinNo)}
                                            helperText={touched.vinNo && errors.vinNo}
                                            inputProps={{ maxLength: 17 }}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            select
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Truck Owner"
                                            {...getFieldProps('owner')}
                                            error={Boolean(touched.owner && errors.owner)}
                                            helperText={touched.owner && errors.owner}
                                        >
                                            <MenuItem value="">Select Owner</MenuItem>
                                            {ownerList &&
                                                ownerList.map((i) => (
                                                    <MenuItem id={i.owner_id} value={i.owner_id}>
                                                        {i.user.first_name} {i.user.last_name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="number"
                                            label="Dispatcher Expense in %"
                                            {...getFieldProps('dispatcher_expense')}
                                            error={Boolean(touched.dispatcher_expense && errors.dispatcher_expense)}
                                            helperText={touched.dispatcher_expense && errors.dispatcher_expense}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="number"
                                            label="Insurance expense"
                                            {...getFieldProps('insurance_expense')}
                                            error={Boolean(touched.insurance_expense && errors.insurance_expense)}
                                            helperText={touched.insurance_expense && errors.insurance_expense}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            select
                                            label="Settlement Type"
                                            name="settlement_type"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.settlement_type}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.errors.settlement_type && formik.touched.settlement_type)}
                                            helperText={formik.touched.settlement_type && formik.errors.settlement_type}
                                        >
                                            <MenuItem value="flat">Flat</MenuItem>
                                            <MenuItem value="split">Split</MenuItem>
                                            <MenuItem value="mileage">Mileage</MenuItem>
                                        </TextField>
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            label="Settlement Value"
                                            name="settlement_value"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.settlement_value}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.errors.settlement_value && formik.touched.settlement_value)}
                                            helperText={formik.touched.settlement_value && formik.errors.settlement_value}
                                        />
                                    )}
                                </Grid>
                                {/* Vin */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="Fuel Card Number"
                                            {...getFieldProps('fuelCard')}
                                            error={Boolean(touched.fuelCard && errors.fuelCard)}
                                            helperText={touched.fuelCard && errors.fuelCard}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <>
                                            <FormLabel id="fuel-card-label" className="radio-label">
                                                Self Fuel Card?
                                            </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="fuel-card"
                                                // defaultValue="Weekly"
                                                name="fuel-card"
                                                row
                                                className="radio-group"
                                                value={values.ownFuel}
                                                onChange={(e) => setFieldValue('ownFuel', e.target.value)}
                                            >
                                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>

                        <SubCard
                            sx={{
                                mt: 5,
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        Drivers
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <>
                                            <Autocomplete
                                                className="text-input"
                                                fullWidth
                                                options={options && options.length > 0 ? options : []}
                                                value={values.primaryDriver}
                                                getOptionLabel={(option) => (option ? option.name : '')}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="Select Primary Driver"
                                                        value={values.primaryDriver}
                                                        error={Boolean(touched.primaryDriver && errors.primaryDriver)}
                                                        helperText={touched.primaryDriver && errors.primaryDriver}
                                                    />
                                                )}
                                                onChange={(event, values) => {
                                                    setFieldValue('primaryDriver', values);
                                                }}
                                            />
                                        </>
                                    )}
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <>
                                            <Autocomplete
                                                className="text-input"
                                                multiple
                                                fullWidth
                                                options={options && options.length > 0 ? options : []}
                                                value={values.driver}
                                                getOptionLabel={(option) => (option ? option.name : '')}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label="Select Co-Drivers"
                                                        value={values.driver}
                                                        error={Boolean(touched.driver && errors.driver)}
                                                        helperText={touched.driver && errors.driver}
                                                    />
                                                )}
                                                onChange={(event, values) => {
                                                    setFieldValue('driver', values);
                                                }}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>

                        <Grid container spacing={3}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <SubCard
                                    sx={{
                                        mt: 5,
                                        borderRadius: '8px',
                                        mb: 2,
                                        boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography
                                                sx={{
                                                    width: '375px',
                                                    height: '40px',
                                                    fontWeight: '600',
                                                    fontSize: '24px',
                                                    fontFamily: 'Lexend'
                                                }}
                                            >
                                                Qualification File
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            {loading ? (
                                                <Skeleton height="50px" />
                                            ) : (
                                                <FieldArray name="filesQ">
                                                    {() => (
                                                        <Grid container spacing={2}>
                                                            {values.filesQ.map((_file, index) => (
                                                                <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                                                    <TextField
                                                                        className="text-input"
                                                                        color="orange"
                                                                        sx={{
                                                                            '.MuiOutlinedInput-root': {
                                                                                display: 'flex',
                                                                                alignItems: 'self-start',
                                                                                flexDirection: { xs: 'column', sm: 'row' }
                                                                            },
                                                                            '.MuiInputBase-input': {
                                                                                height: { xs: '3.4375em', sm: '1.4375em' }
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                        value={values.filesQ[index].sft_doc_name}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton edge="end">
                                                                                        <Button
                                                                                            component="label"
                                                                                            color="orange"
                                                                                            startIcon={<CloudUploadIcon />}
                                                                                            sx={{ mt: { xs: '-2.7rem', sm: '3rem' } }}
                                                                                        >
                                                                                            Upload
                                                                                            <input
                                                                                                hidden
                                                                                                type="file"
                                                                                                onInputCapture={(event) => {
                                                                                                    setFieldValue(
                                                                                                        `filesQ[${index}].sft_doc`,
                                                                                                        event.target.files[0]
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        `filesQ[${index}].sft_doc_name`,
                                                                                                        event.target.files[0].name
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        `filesQ[${index}].drv_doc_id`,
                                                                                                        values.filesQ[index].drv_doc_id
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </Button>
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        label={values.filesQ[index].doc_name || values.filesQ[index].stf_doc_type_name}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </FieldArray>
                                            )}
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <SubCard
                                    sx={{
                                        mt: 5,
                                        borderRadius: '8px',
                                        mb: 2,
                                        boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography
                                                sx={{
                                                    width: '375px',
                                                    height: '40px',
                                                    fontWeight: '600',
                                                    fontSize: '24px',
                                                    fontFamily: 'Lexend'
                                                }}
                                            >
                                                Additional File
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            {loading ? (
                                                <Skeleton height="50px" />
                                            ) : (
                                                <FieldArray name="filesA">
                                                    {() => (
                                                        <Grid container spacing={2}>
                                                            {values.filesA.map((file, index) => (
                                                                <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                                                    <TextField
                                                                        className="text-input"
                                                                        color="orange"
                                                                        sx={{
                                                                            '.MuiOutlinedInput-root': {
                                                                                display: 'flex',
                                                                                alignItems: 'self-start',
                                                                                flexDirection: { xs: 'column', sm: 'row' }
                                                                            },
                                                                            '.MuiInputBase-input': {
                                                                                height: { xs: '3.4375em', sm: '1.4375em' }
                                                                            }
                                                                        }}
                                                                        fullWidth
                                                                        value={values.filesA[index].sft_doc_name}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton edge="end">
                                                                                        <Button
                                                                                            component="label"
                                                                                            color="orange"
                                                                                            startIcon={<CloudUploadIcon />}
                                                                                            sx={{ mt: { xs: '-2.7rem', sm: '3rem' } }}
                                                                                        >
                                                                                            Upload
                                                                                            <input
                                                                                                hidden
                                                                                                type="file"
                                                                                                onInputCapture={(event) => {
                                                                                                    setFieldValue(
                                                                                                        `filesA[${index}].sft_doc`,
                                                                                                        event.target.files[0]
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        `filesA[${index}].sft_doc_name`,
                                                                                                        event.target.files[0].name
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        `filesA[${index}].drv_doc_id`,
                                                                                                        values.filesA[index].drv_doc_id
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </Button>
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        label={values.filesA[index].doc_name || values.filesA[index].stf_doc_type_name}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </FieldArray>
                                            )}
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Form>
            </FormikProvider>
        </>
    );
};

export default TruckForm;
