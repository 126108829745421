import React from 'react';

import ViewCustomerForm from 'ui-component/dispatchCenter/forms/ViewCustomerForm';
import 'ui-component/font/fonts';
import PageLayoutWithBack from 'layout/PageLayoutWithBack';
function ViewCustomer() {
    return (
        <PageLayoutWithBack>
            <ViewCustomerForm />
        </PageLayoutWithBack>
    );
}

export default ViewCustomer;
