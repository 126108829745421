import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import AuthLayout from 'layout/AuthLayout';
import Param from 'views/pages/authentication/authentication/Param';
import CustomLayout from 'layout/CustomLayout';



// ==============================|| AUTHENTICATION ROUTING ||============================== //

const CustomRoutes = {
    path: '/',
    element: <CustomLayout/>,
    children: [
       
        {
            path: 'eld/res',
            element: <Param />
        }
    ]
};

export default CustomRoutes;
