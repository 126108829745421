import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import { TextField, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useFormik } from 'formik';
import { queryClient } from 'index';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import './expenses.css';
import { NumberOnlyInput } from 'utils/NumberOnlyInput';
import { useState } from 'react';

// Initial values for Formik form validation
const vendorInitialValues = {
    company_name: '',
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    phone_number: '',
    address: '',
    note: '',
    attachment: null
};
const vendorSchema = Yup.object().shape({
    company_name: Yup.string().required('Company name is required'),
    // first_name: Yup.string().required('First name is required'),
    // last_name: Yup.string().required('Last name is required'),
    // title: Yup.string().required('Title is required'),
    // email: Yup.string().email().required('Email is required'),
    phone_number: Yup.string()
        .nullable()
        .test('is-valid-length', 'Please enter a valid phone number', (value) => !value || value.length === 10)
    // address: Yup.string().required('Address is required'),
    // note: Yup.string().required('Note is required')
    // attachment: Yup.mixed().required('attachment is required')
});
// Initial values for Formik form validation
function AddVendorModal({ addDrawerOpen, setAddDrawerOpen, addVendorStatusChange, search, page, rowsPerPage }) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const isMobile = useMediaQuery('(max-width:600px)');

    const addVendor = async (formData) => {
        const response = await axios.post('/v1/api/accounting/vendors/', formData, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response;
    };

    const {
        mutate: addVendorMutate,
        isPending: addVendorPending,
        status: addVendorStatus
    } = useMutation({
        mutationFn: addVendor,
        onSuccess: async () => {
            toast.success('vendor added successfully');
            setAddDrawerOpen(false);
            formik.resetForm();
            return await queryClient.invalidateQueries({ queryKey: ['vendors', search, page, rowsPerPage] });
        },
        onError: (error) => {
            if (error.response.data) {
                Object.keys(error.response.data).forEach((field) => {
                    formik.setFieldError(field, error.response.data[field][0]);
                });
            } else {
                toast.error('An unexpected error occurred, please contact with support');
            }
        }
    });

    useEffect(() => {
        addVendorStatusChange(addVendorStatus);
    }, [addVendorStatus]);

    const formik = useFormik({
        initialValues: vendorInitialValues,
        validationSchema: vendorSchema,
        onSubmit: async (values, action) => {
            // Deep clone the values object
            const data = JSON.parse(JSON.stringify(values));
            delete data.attachment;

            const formData = new FormData();

            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            if (values.attachment) {
                // Append the attachment to the FormData
                formData.append('attachment', values.attachment);
            }

            addVendorMutate(formData);
        }
    });

    return (
        <Drawer
            open={addDrawerOpen}
            anchor="right"
            onClose={() => {
                setAddDrawerOpen(false);
            }}
        >
            <div style={{ width: isMobile ? '100vw' : '70vw', padding: '30px 30px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h3" sx={{ mb: 3 }}>
                        Add new vendor
                    </Typography>
                    <Tooltip title="Close">
                        <IconButton onClick={() => setAddDrawerOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <div style={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="company_name"
                                label="Company Name"
                                value={formik.values.company_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.company_name && formik.errors.company_name)}
                                helperText={formik.touched.company_name && formik.errors.company_name}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="first_name"
                                label="First Name"
                                value={formik.values.first_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.first_name && formik.errors.first_name)}
                                helperText={formik.touched.first_name && formik.errors.first_name}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <div style={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                name="last_name"
                                label="Last Name"
                                value={formik.values.last_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.last_name && formik.errors.last_name)}
                                helperText={formik.touched.last_name && formik.errors.last_name}
                            />
                        </div>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="title"
                            label="Title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.title && formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                        />
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="email"
                            label="Email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            error={Boolean(formik.touched.email && formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="phone_number"
                            label="Phone Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onInput={NumberOnlyInput}
                            value={formik.values.phone_number}
                            error={Boolean(formik.touched.phone_number && formik.errors.phone_number)}
                            helperText={formik.touched.phone_number && formik.errors.phone_number}
                        />
                    </div>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="address"
                            label="Address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                            error={Boolean(formik.touched.address && formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                        />
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            name="note"
                            label="Note"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.note}
                            error={Boolean(formik.touched.note && formik.errors.note)}
                            helperText={formik.touched.note && formik.errors.note}
                        />
                    </div>

                    <div style={{ marginBottom: 20, marginTop: 50, display: 'flex', alignItems: 'center', gap: 10 }}>
                        <div style={{ width: '50%' }}>
                            <Typography variant="h4">Attachment</Typography>
                            <input
                                type="file"
                                onChange={(e) => {
                                    formik.setFieldValue('attachment', e.target.files[0]);
                                }}
                                accept=".pdf, .png, .jpg, .jpeg"
                                style={{ display: 'none' }}
                                id="file-input"
                            />
                            <label htmlFor="file-input">
                                <Box
                                    sx={{
                                        border: '2px dashed orange',
                                        borderRadius: '5px',
                                        padding: '20px',
                                        textAlign: 'center',
                                        width: { xs: '82vw', sm: '62vw', md: '100%' },
                                        cursor: 'pointer',
                                        backgroundColor: '#f9f9f9'
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        formik.setFieldValue('attachment', e.dataTransfer.files[0]);
                                    }}
                                    onDragOver={(e) => e.preventDefault()}
                                >
                                    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                        <DownloadIcon />
                                        <Typography variant="h6" gutterBottom>
                                            <span style={{ fontWeight: 'bold' }}>Choose a file</span> or drag it here
                                        </Typography>
                                    </div>
                                    <span style={{ color: 'green' }}>{formik.values.attachment && formik.values.attachment.name}</span>
                                </Box>
                            </label>
                        </div>
                        <div style={{ width: '50%' }}>
                            {formik.values.attachment && (
                                <>
                                    {typeof formik.values.attachment === 'string' ? (
                                        <>
                                            {formik.values.attachment.endsWith('.pdf') ? (
                                                <embed src={formik.values.attachment} type="application/pdf" width="100%" height="150" />
                                            ) : (
                                                <img src={formik.values.attachment} alt="" style={{ width: '100%', height: '150', objectFit: 'cover' }} />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {formik.values.attachment.type === 'application/pdf' ? (
                                                <embed src={URL.createObjectURL(formik.values.attachment)} type="application/pdf" width="100%" height="150" />
                                            ) : (
                                                <img
                                                    src={URL.createObjectURL(formik.values.attachment)}
                                                    alt=""
                                                    style={{ width: '100%', height: '150', objectFit: 'cover' }}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <LoadingButton loading={addVendorPending} type="submit" fullWidth variant="contained" style={{ backgroundColor: '#EB812E' }}>
                        Save Vendor
                    </LoadingButton>
                </form>
            </div>
        </Drawer>
    );
}

export default AddVendorModal;
