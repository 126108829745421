import { useState, useEffect } from 'react';

// material-ui

import { Box, Grid, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

// project imports

// third party

import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';
import { PatternFormat } from 'react-number-format';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />
        }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    '.MuiTabs-flexContainer': {
        flexWrap: 'wrap'
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 60,
        width: '100%'
        // backgroundColor: '#0A95FF'
    },
    '& .Mui-selected': {
        color: '#0A95FF',
        background: '#98c1e094',
        borderRadius: '2rem'
    },
    '& .MuiButtonBase-root': {
        minHeight: '50px'
    }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16,
    marginRight: theme.spacing(1),
    color: '#697586',
    '&.Mui-selected': {
        color: '#0A95FF'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
}));

const ModalViewCustomerForm = ({ id }) => {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [loading, setLoading] = useState();
    const [customerData, setCustomerData] = useState();

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchCustomer = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/dispatch/customer/view/${id}/`, config);

            setCustomerData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!customerData) {
            fetchCustomer(id);
        }
    }, [customerData, id]);

    return (
        <>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                <Grid container spacing={3} sx={{ px: '20px', mb: 3 }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <Typography variant="h3" sx={{ color: '#0A95FF' }}>
                                {id && customerData && customerData.customer_name}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        {loading ? <Skeleton height="50px" /> : <Typography variant="h4">MC</Typography>}
                    </Grid>

                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <Typography variant="body1" sx={{ color: '#0A95FF' }}>
                                {id && customerData && customerData.mc_no}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        {loading ? <Skeleton height="50px" /> : <Typography variant="h4">DOT</Typography>}
                    </Grid>

                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <Typography variant="body1" sx={{ color: '#0A95FF' }}>
                                {id && customerData && customerData.dot_no}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        {loading ? <Skeleton height="50px" /> : <Typography variant="h4">Email</Typography>}
                    </Grid>

                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        {loading ? (
                            <Skeleton height="50px" />
                        ) : (
                            <Typography variant="body1" sx={{ color: '#0A95FF' }}>
                                {id && customerData && customerData.billing_email}
                            </Typography>
                        )}
                    </Grid>
                </Grid>

                <StyledTabs value={value} onChange={handleChange} aria-label="customer view">
                    <StyledTab label="Contacts" icon={<PermContactCalendarIcon />} iconPosition="start" value="1" />
                    <StyledTab label="Address" icon={<HomeIcon />} iconPosition="start" value="2" />
                    <StyledTab label="Invoicing" icon={<AccountBalanceIcon />} iconPosition="start" value="3" />
                </StyledTabs>
                <Box sx={{ px: '20px' }}>
                    {value == 1 && (
                        <Grid container spacing={1} sx={{ mt: 3 }}>
                            <Grid item lg={2} md={2} sm={12} xs={12}>
                                {loading ? <Skeleton height="50px" /> : <Typography variant="h5">Phone</Typography>}
                            </Grid>

                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="body1" sx={{ color: '#0A95FF' }}>
                                        {id && customerData && <PatternFormat value={customerData.phone} displayType={'text'} format="###-###-####" />}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="h5" sx={{ mt: 2 }}>
                                        Fax
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="body1" sx={{ color: '#0A95FF', mt: 2 }}>
                                        {id && customerData && customerData.fax}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {value == 2 && (
                        <Grid container spacing={1} sx={{ mt: 3 }}>
                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? <Skeleton height="50px" /> : <Typography variant="h5">Address</Typography>}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? <Skeleton height="50px" /> : <Typography variant="body1">{id && customerData && customerData.address}</Typography>}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="h5" sx={{ mt: 2 }}>
                                        State
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        {id && customerData && customerData.state_name}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="h5" sx={{ mt: 2 }}>
                                        City
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        {id && customerData && customerData.city_name}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="h5" sx={{ mt: 2 }}>
                                        ZIP Code
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        {id && customerData && customerData.zip_code}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {value == 3 && (
                        <Grid container spacing={1} sx={{ mt: 3 }}>
                            <Grid item lg={2} md={2} sm={12} xs={12}>
                                {loading ? <Skeleton height="50px" /> : <Typography variant="h5">Invoicing Methods</Typography>}
                            </Grid>

                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="body1">{id && customerData && customerData.invoice_method}</Typography>
                                )}
                            </Grid>

                            <Grid item lg={2} md={2} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="h5" sx={{ mt: 2 }}>
                                        Notes
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        {id && customerData && customerData.notes}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </SubCard>
        </>
    );
};

export default ModalViewCustomerForm;
