import { Button, Skeleton } from '@mui/material';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';
import { useState } from 'react';
import TruckIcon from '../../assets/images/cargo-truck.png';
import SyncIcon from '@mui/icons-material/Sync';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import PushPinIcon from '@mui/icons-material/PushPin';
import PlaceIcon from '@mui/icons-material/Place';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import React from 'react';

const containerStyle = {
    width: '100%',
    height: '85vh'
};

const HomeMapView = (props) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDDLFPFqXmtMzrzbPJrMn5osR4PA2swrtM'
    });
    const navigate = useNavigate();
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [isMapLoading, setIsMapLoading] = useState(false);
    const [locationName, setLocationName] = useState('');
    const [center, setCenter] = useState({ lat: 39.739235, lng: -104.99025 })

    const [loadData, setLoadData] = useState(null);
    const [isLoadLoading, setIsLoadLoading] = useState(false);

    const [vehicles, setVehicles] = useState([]);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;

    if (!loginInfoFromStorage) {
        navigate(`/login`, { replace: true });
    }
    const fetchData = async () => {
        if (loginInfoFromStorage.user_type === 'dispatcher_admin' || loginInfoFromStorage.user_type === 'accountant') {
            try {
                setIsMapLoading(true);
                const { data } = await axios.get(`/v1/api/users/get-drivers-map-locations/`, {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${loginInfoFromStorage.token}`
                    }
                });
                setVehicles(data.vehicles);
                toast.success('Location updated successfully');
                setIsMapLoading(false);
            } catch (error) {
                setIsMapLoading(false);
                if (error?.response?.data?.error) {
                    toast.error(error?.response?.data?.error);
                } else {
                    toast.error('Please contact with support');
                }
                console.log(error);
            }
        }
    };
    const fetchLoadInfo = async (ELDId) => {
        if (ELDId) {
            setLoadData(null);
            try {
                setIsLoadLoading(true);
                const { data } = await axios.get(`/v1/api/users/get-load-info-by-eld-id/${ELDId}`, {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${loginInfoFromStorage.token}`
                    }
                });
                setLoadData(data);
                setIsLoadLoading(false);
                toast.success('Load info updated successfully');
            } catch (error) {
                setIsLoadLoading(false);
                if (error.response.data?.error) {
                    toast.error(error.response.data?.error);
                }
            }
        }
    };

    const fetchLocationNameByLatLon = async (latitude, longitude) => {
        try {
            const apiKey = 'AIzaSyDDLFPFqXmtMzrzbPJrMn5osR4PA2swrtM';
            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
            const { data } = await axios.get(url);
            setLocationName(data.results[0].formatted_address);
        } catch (error) {}
    };

    useEffect(() => {
        // fetchData();
        if(vehicles.length > 0){
            setCenter({
                lat: vehicles[0]?.vehicle?.current_location?.lat,
                lng: vehicles[0]?.vehicle?.current_location?.lon
            })
        }
    }, []);

    return (
        <>
            {isLoaded && (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={4}
                    options={{
                        zoomControl: true,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false
                    }}
                >
                    <MarkerClusterer>
                        {(clusterer) =>
                            vehicles?.map(
                                (vehicle, index) =>
                                    vehicle?.vehicle && (
                                        <Marker
                                            key={index}
                                            position={{
                                                lat: vehicle?.vehicle?.current_location?.lat,
                                                lng: vehicle?.vehicle?.current_location?.lon
                                            }}
                                            clusterer={clusterer}
                                            icon={{
                                                url: TruckIcon,
                                                scaledSize: new window.google.maps.Size(40, 40), // Adjust the size of the marker icon
                                                labelOrigin: new window.google.maps.Point(15, 0) // Adjust the position of the label within the icon
                                            }}
                                            label={{
                                                text: `${vehicle?.vehicle?.unit_no}`,
                                                color: 'red',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                            }}
                                            onClick={() => {
                                                setSelectedMarker(vehicle);
                                                fetchLoadInfo(vehicle?.vehicle?.id);
                                                fetchLocationNameByLatLon(vehicle?.vehicle?.current_location?.lat, vehicle?.vehicle?.current_location?.lon);
                                                setCenter({ lat: vehicle?.vehicle?.current_location?.lat, lng: vehicle?.vehicle?.current_location?.lon })
                                            }}
                                        />
                                    )
                            )
                        }
                    </MarkerClusterer>

                    {selectedMarker && (
                        <InfoWindow
                            position={{ lat: selectedMarker?.vehicle?.current_location?.lat, lng: selectedMarker?.vehicle?.current_location?.lon }}
                            options={{
                                pixelOffset: new google.maps.Size(0, -40),
                                closeButton: false,
                            }}
                            onCloseClick={() => {
                                setSelectedMarker(null);
                            }}
                        >
                            <Box style={{ backgroundColor: '#0000001c', padding: '12px' }}>
                                <div style={{ backgroundColor: 'white', display: 'flex', padding: '10px', flexDirection: 'column', borderRadius: '5px' }}>
                                    <div style={{ display: 'flex', gap: '30px' }}>
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                <PushPinIcon style={{ color: '#0b5b0bbd' }} />
                                                {isLoadLoading ? (
                                                    <Skeleton height={'30px'} width={'100px'} />
                                                ) : (
                                                    <div>
                                                        <h5 style={{ margin: '0px' }}>
                                                            {locationName !== ''
                                                                ? locationName.match(/.{1,15}/g).map((substr, index) => (
                                                                      <React.Fragment key={index}>
                                                                          {substr}
                                                                          <br />
                                                                      </React.Fragment>
                                                                  ))
                                                                : ''}
                                                        </h5>
                                                    </div>
                                                )}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                <PlaceIcon style={{ color: '#ff0000a3' }} />
                                                {isLoadLoading ? (
                                                    <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                ) : (
                                                    <div>
                                                        <h3 style={{ margin: '0px' }}>ETA </h3>
                                                        <span style={{ color: '#00000091', fontWeight: '500', fontSize: '12px' }}>
                                                            {loadData && loadData.map_data.eta}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                <LocalShippingIcon />
                                                {isLoadLoading ? (
                                                    <Skeleton height={'30px'} width={'100px'} />
                                                ) : (
                                                    <h3 style={{ margin: '0px' }}>{loadData && loadData.driver_info.name}</h3>
                                                )}
                                            </div>
                                            <div style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>
                                                {isLoadLoading ? (
                                                    <Skeleton height={'30px'} width={'100px'} />
                                                ) : (
                                                    `Load # No: ${loadData != null ? loadData.load : ''}`
                                                )}
                                            </div>
                                            <div style={{ textAlign: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                {isLoadLoading ? (
                                                    <Skeleton height={'30px'} width={'100px'} />
                                                ) : (
                                                    <>
                                                        Status
                                                        <button
                                                            style={{
                                                                backgroundColor: '#adff2f70',
                                                                border: 'none',
                                                                textTransform: 'uppercase',
                                                                padding: '5px 10px',
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            {loadData != null ? loadData.load_status : 'New'}
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                            <div style={{ textAlign: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                {isLoadLoading ? (
                                                    <Skeleton height={'30px'} width={'100px'} />
                                                ) : (
                                                    <>
                                                        Bol-Status
                                                        <button
                                                            style={{
                                                                backgroundColor: '#adff2f70',
                                                                border: 'none',
                                                                textTransform: 'uppercase',
                                                                padding: '5px 10px',
                                                                fontWeight: '600'
                                                            }}
                                                        >
                                                            {loadData != null ? loadData.bol_status : 'New'}
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '15px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <span style={{ color: '#00000091', fontWeight: '500', fontSize: '15px', marginTop: '20px', display: 'block' }}>
                                            {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
                                        </span>

                                        <Button
                                            variant="contained"
                                            sx={{ color: 'white', backgroundColor: '#EB812E' }}
                                            size="small"
                                            color="orange"
                                            onClick={() => {
                                                setSelectedMarker(null)
                                            }}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </div>
                            </Box>
                        </InfoWindow>
                    )}
                </GoogleMap>
            )}
            <Tooltip title="Get Location Update" placement="top-start">
                <div
                    style={{
                        position: 'absolute',
                        bottom: '22vh',
                        right: '2.7vw',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px'
                    }}
                >
                    {isMapLoading ? (
                        <CircularProgress size={50} />
                    ) : (
                        <SyncIcon
                            style={{
                                cursor: 'pointer',
                                color: 'orange',
                                fontSize: '50px'
                            }}
                            onClick={fetchData}
                        />
                    )}
                </div>
            </Tooltip>
        </>
    );
};

export default HomeMapView;