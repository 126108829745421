import { Button, Dialog, DialogActions, DialogContent, Slide } from '@mui/material';
import { forwardRef } from 'react';
import InvDocloadForm from 'ui-component/dispatchCenter/forms/InvDocloadForm';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function InvDocModal({ fullWidth, fullScreen, maxWidth, open, handleClose, id }) {
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogContent dividers>
                <InvDocloadForm id={id} handleClose={handleClose} />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    sx={{
                        mr: 2,
                        color: 'white',
                        backgroundColor: '#EB812E',
                        color: 'white',
                        fontFamily: 'Lexend',
                        fontWeight: 400,
                        lineHeight: '20px',

                        fontStyle: 'normal',

                        height: '36px',
                        fontSize: '16px',
                        borderRadius: '4px'
                    }}
                    color="orange"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

InvDocModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'md'
};

export default InvDocModal;
