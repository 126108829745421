import { useEffect } from 'react';

import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Button, IconButton, Menu, MenuItem, Stack, TextField } from '@mui/material';

import axios from 'axios';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

import { useRef } from 'react';
import { createRef } from 'react';
import { useState } from 'react';

import UserFilter from 'ui-component/filters/UserFilter';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import NewFileTypeModal from 'ui-component/modals/NewFileTypeModal';

const AllFileConfigurationForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // eslint-disable-next-line prefer-const

    const tableRef = createRef();

    const anchorRef = useRef(null);
    const [caRef, setCaRef] = useState(null);

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [checked, setChecked] = useState(['doh']);
    const [statusChecked, setStatusChecked] = useState([]);
    const [yearFilter, setYearFilter] = useState('');

    const [searchFltr, setSearchFltr] = useState('');

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;

    const [fileId, setFileId] = useState();

    const handleCloseModal = () => {
        setOpen1(false);
    };
    const handleClickOpenModal = () => {
        setFileId('');
        setOpen1(true);
    };

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const deleteFile = async (id) => {
        try {
            await axios
                .delete(`/v1/api/fleet/safety/doc-type-view/${id}/`, {
                    data: {},
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${loginInfoFromStorage.token}`
                    }
                })
                .then(dispatch(openSuccessSnackbar('File Type deleted sucessfully')));
            tableRef.current.onQueryChange();
            // navigate('/setup/configuration/file', { replace: true });
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        if (newChecked1.includes('active') && newChecked1.includes('inactive')) {
            setSearchSts();
        } else if (newChecked1.includes('active')) {
            setSearchSts(true);
        } else if (newChecked1.includes('inactive')) {
            setSearchSts(false);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const editFile = (id) => {
        setFileId(id);

        setOpen1(true);
    };

    const viewUser = (id) => {
        setCaRef(null);
        navigate(`/setup/user/view/${id}`);
    };

    const fetchFileData = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/fleet/safety/doc-type-view/${fileId}/`, config);
            setCustList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
          
        }
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd ">File Name</th>
                    <th className="headerTd ">Type</th>
                    <th className="headerTd ">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.stf_doc_type_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.doc_type === 'dq' ? 'Driver' : 'Vehicle'}
                </td>
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <PopupState variant="popover" popupId={`demoMenu-${rowData.data.dis_admin_id}`}>
                        {(popupState) => (
                            <div>
                                <IconButton {...bindTrigger(popupState)} size="large">
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    {...bindMenu(popupState)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    <MenuItem onClick={() => editFile(rowData.data.sft_doc_type_id)}>Edit</MenuItem>
                                    {!rowData.data.is_qualf ? (
                                        <MenuItem onClick={() => deleteFile(rowData.data.sft_doc_type_id)}>Remove </MenuItem>
                                    ) : (
                                        ''
                                    )}
                                </Menu>
                            </div>
                        )}
                    </PopupState>
                </td>
            </tr>
        );
    };

    return (
        // <PageLayout url="/setup/add-user">
        <>
            <Stack sx={{ mt: 5, mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                <TextField
                    type="text"
                    label="Search"
                    size="small"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    sx={{ width: '300px' }}
                />
                <div>
                    {/* <Button
                        variant="contained"
                        sx={{ mr: 2 }}
                        color="orange"
                        onClick={handleToggle}
                        ref={anchorRef}
                        endIcon={<FilterAltIcon />}
                    >
                        FILTER
                    </Button> */}
                    <Button variant="contained" sx={{ mr: 2 }} color="orange" onClick={handleClickOpenModal}>
                        ADD FILE TYPE
                    </Button>
                </div>
            </Stack>
            <MaterialTable
                tableRef={tableRef}
                data={(query) =>
                    new Promise((resolve, reject) => {
                        const url = `/v1/api/fleet/safety/doc-type-all/list`;

                        fetch(url, config)
                            .then((response) => response.json())
                            .then((result) => {
                             
                                resolve({
                                    data: result
                                    // page: query.page,
                                    // totalCount: result.count
                                });
                            });
                    })
                }
                title="All File Type"
                columns={[]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    sorting: true,
                    exportButton: false,

                    toolbar: false,
                    draggable: false,
                    columnResizable: true,
                    pageSize: 50,
                    pageSizeOptions: [50]
                }}
            />
            {/* </PageLayout> */}
            <UserFilter
                open={open}
                userRef={anchorRef}
                checked={checked}
                handleClose={handleClose}
                handleChecked={handleChecked}
                yearFilter={yearFilter}
                yearHandler={(e) => {
                    setYearFilter(e);
                    setSearchYear(e);
                }}
                statusChecked={statusChecked}
                handleStatus={handleStatus}
                filterHandler={() => {
                    tableRef.current.onQueryChange();
                }}
            />

            <NewFileTypeModal
                open={open1}
                handleClose={handleCloseModal}
                type="all"
                fileId={fileId}
                reloadHandler={() => {
                    tableRef.current.onQueryChange();
                }}
            />
        </>
    );
};

export default AllFileConfigurationForm;
