import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Slide,
    TextField,
    Typography
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MaterialTable from '@material-table/core';
import { createRef, useEffect, useState } from 'react';
import { forwardRef } from 'react';
import TABLE_ICONS from 'tableIcons';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import Status from 'ui-component/buttons/Status';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { PatternFormat } from 'react-number-format';
import axios from 'axios';
import moment from 'moment';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DrvLoadData({ data, onAzSave }) {
    const tableRef = createRef();
    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
        // responseType: 'arraybuffer'
    };

    const [ldData, setLdData] = useState(data);

    const [showForm, setShowForm] = useState(false);
    const [ldRow, setLdRow] = useState(null);

    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [ssnShow, setSsnShow] = useState(true);

    useEffect(() => {
        if (!stateList) {
            fetchState();
        }
    }, [stateList]);

    const handleDelete = (rowData) => {
        setLdData(ldData.filter((item) => item.email !== rowData.email));
    };

    const fetchState = async () => {
        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
        } catch (error) {
            console.log(error.response.data);
        }
    };
    const changeStates = (value) => {
        formik.setFieldValue('state', value);

        //  setFieldValue('companyCity', '');
        getCityList(value);
    };

    const getCityList = async (id) => {
        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);
            setCityList(data);
        } catch (error) {
            console.log(error.response.data);
        }
    };

    const driveSchema = Yup.object().shape({
        first_name: Yup.string()
            .required('First Name is a required field')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
        last_name: Yup.string()
            .required('Last Name is a required field')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
        email: Yup.string().email().required('Email is a required field'),
        phone: Yup.string().required('Phone is a required field'),
        cdl_no: Yup.string().required('CDL# is a required field').nullable(true),
        acc_no: Yup.string().matches(/^\d+$/, { message: 'This should be a valid number' }).max(20, 'Bank account must be less than 20 ').nullable(true),

        ssn_no: Yup.string().matches(/^\d+$/, { message: 'This should be a valid number' }).max(10, 'ssn must be less than 10').nullable(true)
    });

    const formik = useFormik({
        initialValues: ldRow ? ldRow : {},
        validationSchema: driveSchema,
        onSubmit: (values) => {
            try {
                setLdData(ldData.map((item) => (item.id === values.id ? values : item)));
                setShowForm(false);
                setLdRow(null);
            } catch (error) {
                console.log(error);
            }
        }
    });

    const handleEdit = (rowData) => {
        try {
            console.log(rowData);
            setShowForm(true);
            formik.setValues(rowData);
        } catch (error) {
            console.log(error);
        }
    };
    const formatDate = (dt) => {
        console.log(typeof dt);

        if (dt instanceof Date) {
            return format(dt, 'yyyy-MM-dd');
        } else if (dt !== '') {
            return dt;
        } else if (dt instanceof Number || dt instanceof String) {
            return moment(dt).format('YYYY-MM-DD');
        } else {
            return '';
        }
    };

    // console.log(formik.values, ldRow);
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor ">
                        <span style={{ color: 'red' }}>*</span>Name
                    </th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>Phone
                    </th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>Email
                    </th>
                    <th className="headerTd rightHidBor">Hire Date</th>
                    <th className="headerTd rightHidBor">Termination Date</th>
                    <th className="headerTd rightHidBor">Address</th>
                    <th className="headerTd rightHidBor">
                        <span style={{ color: 'red' }}>*</span>CDL No
                    </th>
                    <th className="headerTd rightHidBor">SSN No</th>
                    <th className="headerTd rightHidBor">Acc No</th>
                    <th className="headerTd rightHidBor">Import Status</th>
                    <th className="headerTd rightHidBor">Remark</th>
                    <th className="headerTd">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.first_name} {rowData.data.last_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.phone}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.email}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.hire_date !== '' && moment(rowData.data.hire_date).format('L')}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.date_termination !== '' && moment(rowData.data.date_termination).format('L')}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.address} {rowData.data.city} {rowData.data.state} {rowData.data.zip_code}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.cdl_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.ssn_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.acc_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {<Status text={rowData.data.upload_status} />}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.remark}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => handleEdit(rowData.data)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(rowData.data)}>
                        <DeleteIcon />
                    </IconButton>
                </td>
                {/* Custom cell or additional cells */}
            </tr>
        );
    };

    const formatTime = (tm) => {
        if (tm instanceof Date) {
            return format(tm, 'HH:mm');
        } else if (tm !== '') {
            return tm;
        } else {
            return '';
        }
    };

    const LetterOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const AlphanumericOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z0-9 ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.',
        error: Boolean(formik.touched.phone && formik.errors.phone),
        helperText: formik.touched.phone && formik.errors.phone
    };
    return (
        <>
            {showForm && (
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container sx={{ my: 3 }} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Load Data</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField className="text-input" color="orange" fullWidth type="text" label="Email" {...formik.getFieldProps('email')} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="First Name"
                                    {...formik.getFieldProps('first_name')}
                                    onInput={LetterOnlyHandler}
                                    error={Boolean(formik.touched.first_name && formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Last Name"
                                    {...formik.getFieldProps('last_name')}
                                    onInput={LetterOnlyHandler}
                                    error={Boolean(formik.touched.last_name && formik.errors.last_name)}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <PatternFormat
                                    format="###-###-####"
                                    allowEmptyFormatting
                                    mask="_"
                                    value={formik.values.phone}
                                    onValueChange={(value) => formik.setFieldValue('phone', value.value)}
                                    customInput={TextField}
                                    {...textFieldProps}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Full US Address"
                                    {...formik.getFieldProps('address')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField className="text-input" color="orange" fullWidth type="text" label="ZIP Code" {...formik.getFieldProps('zip_code')} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="State"
                                    placeholder="TX"
                                    {...formik.getFieldProps('state')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="City"
                                    placeholder="Dallas"
                                    {...formik.getFieldProps('city')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="Zip"
                                    inputProps={{ maxLength: 5 }}
                                    value={formik.values.zip}
                                    onChange={(e) => formik.setFieldValue('zip_code', e.target.value)}
                                    onInput={NumberOnlyHandler}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Hire Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={formik.values.hire_date}
                                        onChange={(newValue) => {
                                            formik.setFieldValue(`hire_date`, newValue);
                                        }}
                                        renderInput={(params) => <TextField color="orange" fullWidth {...params} error={null} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Termination Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={formik.values.date_termination}
                                        onChange={(newValue) => {
                                            formik.setFieldValue(`date_termination`, newValue);
                                        }}
                                        renderInput={(params) => <TextField color="orange" fullWidth {...params} error={null} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="CDL #"
                                    {...formik.getFieldProps('cdl_no')}
                                    error={Boolean(formik.touched.cdl_no && formik.errors.cdl_no)}
                                    helperText={formik.touched.cdl_no && formik.errors.cdl_no}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type={ssnShow ? 'password' : 'text'}
                                    label="SSN"
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton aria-label="toggle password visibility" onClick={() => setSsnShow(!ssnShow)} edge="end">
                                                <InputAdornment position="end">{ssnShow ? <Visibility /> : <VisibilityOff />}</InputAdornment>
                                            </IconButton>
                                        ),
                                        autoComplete: false
                                    }}
                                    {...formik.getFieldProps('ssn_no')}
                                    onInput={AlphanumericOnlyHandler}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Bank Account Number"
                                    {...formik.getFieldProps('acc_no')}
                                    onInput={AlphanumericOnlyHandler}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        color: 'white',
                                        fontFamily: 'Lexend',
                                        fontWeight: 500,
                                        lineHeight: '1.3rem',
                                        fontSize: '16px',
                                        fontStyle: 'normal'
                                    }}
                                    color="orange"
                                    size="large"
                                    type="submit"
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            )}
            <Typography variant="h5">
                <span style={{ color: 'red' }}>*</span> marks mandatory fields
            </Typography>
            <MaterialTable
                tableRef={tableRef}
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                data={ldData}
                title="All Truck"
                columns={[]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    sorting: true,
                    exportButton: false,
                    filtering: true,
                    toolbar: false,
                    draggable: false,
                    columnResizable: true,
                    paging: false
                }}
            />
            <Button
                variant="contained"
                sx={{
                    color: 'white',
                    fontFamily: 'Lexend',
                    fontWeight: 500,
                    lineHeight: '1.3rem',
                    fontSize: '16px',
                    fontStyle: 'normal'
                }}
                color="orange"
                size="large"
                type="button"
                onClick={() => onAzSave(ldData)}
            >
                Save Data
            </Button>
        </>
    );
}

export default DrvLoadData;
