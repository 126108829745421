import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Slide,
    TextField,
    Typography
} from '@mui/material';
import * as Yup from 'yup';
import { forwardRef } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openErrorSnackbar } from 'store/ui/snackbarSlice';
import { LoadingButton } from '@mui/lab';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function InstallFuel({ fullWidth, fullScreen, maxWidth, open, handleCloseModal, data }) {
    const [showPassword, setShowPassword] = useState(false);
    const [addError, setAddError] = useState();

    const dispatch = useDispatch();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const LoginSchema = Yup.object().shape({
        email: Yup.string().max(255).required('User Name is required'),
        password: Yup.string().max(255).required('Password is required')
        // isActive: Yup.boolean().required('Status is a required field')
    });

    // const config = {
    //     headers: {
    //         'Content-type': 'application/json'
    //     }
    // };
    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: LoginSchema,
        onSubmit: async () => {
            console.log('submit');
            try {
                const { data } = await axios.post(
                    `/v1/api/users/wex-login/`,
                    {
                        username: values.email,
                        password: values.password
                    },
                    config
                );
                // localStorage.setItem('loginUserInfo', JSON.stringify(data));
                // navigate('/dashboard', { replace: true });
                handleCloseModal();
            } catch (error) {
                console.log(error.response.data);
                dispatch(openErrorSnackbar(error.response.data.error));
            }
        }
    });
    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <DialogTitle>Login to WEX</DialogTitle>
                    <DialogContent dividers>
                        <Grid container>
                            <Grid item lg={12} sx={{ mb: 2 }}>
                                {/* <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                                    <InputLabel htmlFor="outlined-adornment-email-login">Username</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-email-login"
                                        className="text-input"
                                        type="text"
                                        margin="normal"
                                        {...getFieldProps('email')}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </FormControl> */}
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    type="text"
                                    label="Username"
                                    {...getFieldProps('email')}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>
                            <Grid item lg={12}>
                                <FormControl fullWidth error={Boolean(touched.password && errors.password)}>
                                    <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password-login"
                                        className="text-input"
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        value={values.password}
                                        onChange={(e) => {
                                            setFieldValue('password', e.target.value);
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        inputProps={{}}
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText error id="standard-weight-helper-text-password-login">
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            loading={isSubmitting}
                            variant="contained"
                            type="submit"
                            sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                            color="orange"
                        >
                            Login
                        </LoadingButton>
                        <Button
                            variant="contained"
                            type="button"
                            sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                            color="orange"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    );
}

InstallFuel.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'md'
};

export default InstallFuel;
