import React from 'react';
import ViewTrailerForm from 'ui-component/fleet/forms/ViewTrailerForm';
import PageLayoutWithBack from 'layout/PageLayoutWithBack';

function ViewTrailer() {
    return (
        <PageLayoutWithBack>
            <ViewTrailerForm />
        </PageLayoutWithBack>
    );
}

export default ViewTrailer;
