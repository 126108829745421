import { useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import { Button, FormControl, Grid, IconButton, InputAdornment, MenuItem, Skeleton, TextField, Typography, useTheme } from '@mui/material';

// project imports

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { Stack } from '@mui/system';

import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';
import PropTypes from 'prop-types';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const InvDocloadForm = ({ id, handleClose }) => {
    const theme = useTheme();
    // const { id } = useParams();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;

    const dispatch = useDispatch();

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    const FaqSchema = Yup.object().shape({
        docType: Yup.string().required('This field is required'),
        doc: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            docType: '',
            doc: '',
            docName: ''
        },
        validationSchema: FaqSchema,
        onSubmit: async () => {
            try {
                const formData = new FormData();

                formData.append('bol_doc', values.doc);
                formData.append('doc_type', values.docType);
                formData.append('bol_doc_name', values.docName);
                formData.append('dsp_load_id', id);
                formData.append('company_id', loginInfoFromStorage.company_id);
                const { data } = await axios.post(`/v1/api/dispatch/load-doc/add/${id}/`, formData, config);
                dispatch(openSuccessSnackbar('Load Other Doc Updated successfully'));

                handleClose();
            } catch (error) {
                
                // setAddError(error.response.data.error);
                if (error.response.data.street) {
                    dispatch(openErrorSnackbar('One of the given address was invalid'));
                } else {
                    dispatch(openErrorSnackbar('Something Went Wrong!'));
                }
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

  

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography variant="h2">Add Other Doc </Typography>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <LoadingFormButton btnText="Save" type="submit" variant="contained" component="span" size="large" loading={isSubmitting} />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            {/* Ratecon upload */}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h2"> Documents</Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Stack direction="row" justifyContent="center" spacing={2} alignItems="center" sx={{ my: 2 }}>
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        select
                                        label="Type"
                                        value={values.docType}
                                        onChange={(e) => {
                                            setFieldValue(`docType`, e.target.value);
                                        }}
                                    >
                                        <MenuItem value="other_doc">Other Doc</MenuItem>
                                    </TextField>
                                    <TextField
                                        className="text-input"
                                        color="orange"
                                        fullWidth
                                        value={values.docName}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end">
                                                        <Button component="label" color="orange" startIcon={<CloudUploadIcon />}>
                                                            Upload
                                                            <input
                                                                hidden
                                                                type="file"
                                                                onInputCapture={(event) => {
                                                                    setFieldValue(`doc`, event.target.files[0]);
                                                                    setFieldValue(`docName`, event.target.files[0].name);
                                                                }}
                                                            />
                                                        </Button>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        label=" Documents"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </SubCard>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

InvDocloadForm.propTypes = {
    handleClose: PropTypes.func,
    id: PropTypes.any
};

export default InvDocloadForm;
