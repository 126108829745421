import { fontFamily } from '@mui/system';
import ReactECharts from 'echarts-for-react';

function SafetyPercentage({ title, parcentage }) {
    const underFifty = [
        { value: parcentage, name: ' ' },
        { value: 100 - parcentage, name: parcentage + '%' }
    ];

    const fiftyOrAbove = [
        { value: parcentage, name: parcentage + '%' },
        { value: 100 - parcentage, name: ` ${parcentage}%` }
    ];

    const option = {
        animationDuration: 1500,
        title: {
            text: title,
            left: 'center',
            padding: 10,
            textStyle: {
                fontSize: 20,
                fontWeight: 400,
                fontFamily: 'Lexend, sans-serif'
            }
        },
        series: [
            {
                label: {
                    show: true,
                    fontSize: 30,
                    position: 'center',
                    color: 'black'
                },
                color: ['#91CC75', '#DEDEDE'],
                type: 'pie',
                radius: ['50%', '70%'],
                center: ['50%', '60%'],
                startAngle: 180,
                endAngle: 360,
                data: parcentage < 50 ? underFifty : fiftyOrAbove
            }
        ]
    };

    return (
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '20px', borderRadius: '15px', height: '250px' }}>
            <ReactECharts option={option} />
        </div>
    );
}

export default SafetyPercentage;
