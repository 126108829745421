import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

// project imports
import logo from 'assets/images/logo.jpeg';

// import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
// import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const PublicHeader = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}

            <Link href="https://infinitytruck.in/" sx={{ display: 'contents' }}>
                <Box component="img" src={logo} sx={{ width: 235, height: 70, cursor: 'pointer' }} />
            </Link>
            <Box
                sx={{
                    background: theme.palette.primary.main,
                    display: 'flex',

                    height: '80px',
                    ml: '15px',
                    xs: 'block',
                    width: 1
                }}
            >
                {/* header search */}
                {/* <SearchSection /> */}
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ flexGrow: 1 }} />

                {/* notification & profile */}
                {/* <NotificationSection /> */}
                {/* <ProfileSection /> */}
            </Box>
        </>
    );
};

PublicHeader.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default PublicHeader;
