import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import {
    Autocomplete,
    Box,
      FormControl,
  
    Grid,
   
    Skeleton,
    TextField,
    Typography
} from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { format } from 'date-fns';
// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';

import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { Stack } from '@mui/system';
import { useNavigate, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import BackFormButton from 'ui-component/buttons/BackFormButton';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const ScheduleForm = ({ props }) => {
    // const { companySetup } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorAdd, setErrorAdd] = useState('');
   
    const [loading1, setLoading1] = useState(false);

    const [loading, setLoading] = useState(false);

    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [mechanicList, setMechanicList] = useState();
    const [scheduleData, setScheduleData] = useState();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.company_id;
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const configJson = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^0-9 ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    useEffect(() => {
        if (!mechanicList) {
            fetchMechanic();
        }

        if (mechanicList && id) {
            fetchScheule();
        }
    }, [mechanicList, id]);

    const fetchMechanic = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/mechanic/add-mechanic`, config);

            setMechanicList(data);
            setOptions(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
          
        }
    };

    const fetchScheule = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/mechanic/mcn-schedule/view/${id}/`, config);

            setScheduleData(data);
            // setFieldValue('mechanic', mechanicList.filter(
            //     ({ mechanic_id }) =>
            //     mechanic_id === scheduleData.mechanic_id
            // )[0])

            setLoading(false);
        } catch (error) {
            setLoading(false);
           
        }
    };

    const handleMechanicsChange = (event, newValues) => {
        if (newValues) {
            setFieldValue('mechanics', newValues);
        }
    };

    const OrganizationSchema = Yup.object().shape({
        mechanics: Yup.mixed().required('Mechanic is required'),
        startDate: Yup.string().required('Date is required'),
        endDate: Yup.string().required('Date is required')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            mechanics: id && scheduleData && mechanicList ? mechanicList.filter(({ mechanic_id }) => mechanic_id === scheduleData.mechanic_id)[0] : '',
            startDate: scheduleData ? scheduleData.scdl_start_date : '',
            endDate: scheduleData ? scheduleData.scdl_end_date : '',
            phone: scheduleData ? scheduleData.phone : ''
        },
        validationSchema: OrganizationSchema,
        onSubmit: async () => {
            setLoading(true);
            try {
                if (id) {
                    const { data } = await axios.post(
                        `/v1/api/mechanic/mcn-schedule/view/${id}/`,
                        {
                            phone: values.phone,
                            mcn_company_id: values.mechanics.mcn_company_id,

                            mechanic_id: values.mechanics.mechanic_id,
                            scdl_start_date: values.startDate ? format(new Date(values.startDate), 'yyyy-MM-dd') : '',
                            scdl_end_date: values.endDate ? format(new Date(values.endDate), 'yyyy-MM-dd') : '',
                            created_by: loginInfoFromStorage.user_id,
                            is_active: true,
                            is_edit: true
                        },
                        configJson
                    );

                    dispatch(openSuccessSnackbar('Schedule Details updated successfully'));
                    navigate('/mechanic/schedule', { replace: true });
                    setLoading(false);
                } else {
                    const { data } = await axios.post(
                        `/v1/api/mechanic/mcn-schedule/add`,
                        {
                            phone: values.phone,
                            mcn_company_id: values.mechanics.mcn_company_id,

                            mechanic_id: values.mechanics.mechanic_id,
                            scdl_start_date: values.startDate ? format(new Date(values.startDate), 'yyyy-MM-dd') : '',
                            scdl_end_date: values.endDate ? format(new Date(values.endDate), 'yyyy-MM-dd') : '',
                            created_by: loginInfoFromStorage.user_id,
                            is_active: true,
                            is_edit: true
                        },
                        configJson
                    );

                    dispatch(openSuccessSnackbar('Schedule Details added successfully'));
                    navigate('/mechanic/schedule', { replace: true });
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                setErrorAdd(error.response.data);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.',
        error: Boolean(touched.phone && errors.phone),
        helperText: touched.phone && errors.phone
    };

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography
                                sx={{
                                    mt: '5',
                                    width: '375px',
                                    height: '40px',
                                    fontWeight: '600',
                                    fontSize: '32px',
                                    fontFamily: 'Lexend'
                                }}
                            >
                                Schedule
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    {/* <LoadingFormButton type="button" btnText="Edit" onClick={() => editOrganization} /> */}
                                    <BackFormButton />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            {/* Customer */}
                            {/* General Info */}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    New Oncall Scheduler
                                </Typography>
                            </Grid>

                            {/* User Name */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            className="text-input"
                                            value={values.mechanics}
                                            id="tags-outlined"
                                            options={options}
                                            isOptionEqualToValue={(option, value) => option.mechanic_id === value.mechanic_id}
                                            getOptionLabel={(option) => (option.mcn_name ? option.mcn_name : '')}
                                            filterSelectedOptions
                                            onChange={handleMechanicsChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Mechanic"
                                                    placeholder="Mechanic list"
                                                    error={Boolean((touched.mechanics && errors.mechanics) || errorAdd.mechanic_id)}
                                                    helperText={(touched.mechanics && errors.mechanics) || errorAdd.mechanic_id}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading || loading1 ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField fullWidth className="text-input" color="orange" type="text" label="Phone Number" {...getFieldProps('phone')} />
                                )}
                            </Grid>
                            {/* Weight */}

                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="text-input"
                                            label="Date of Maintenance"
                                            openTo="day"
                                            views={['year', 'month', 'day']}
                                            value={values.startDate}
                                            onChange={(newValue) => {
                                                setFieldValue('startDate', newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    className="text-input"
                                                    color="orange"
                                                    fullWidth
                                                    {...params}
                                                    error={Boolean((touched.startDate && errors.startDate) || errorAdd.scdl_start_date)}
                                                    helperText={(touched.startDate && errors.startDate) || errorAdd.scdl_start_date}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="text-input"
                                            label="Next Date of Maintenance"
                                            openTo="day"
                                            views={['year', 'month', 'day']}
                                            value={values.endDate}
                                            onChange={(newValue) => {
                                                setFieldValue('endDate', newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    className="text-input"
                                                    color="orange"
                                                    fullWidth
                                                    {...params}
                                                    error={Boolean((touched.startDate && errors.startDate) || errorAdd.scdl_end_date)}
                                                    helperText={(touched.startDate && errors.startDate) || errorAdd.scdl_end_date}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            </Grid>
                        </Grid>
                    </SubCard>

                    <Box sx={{ mt: 2 }} align="center">
                        <AnimateButton>
                            <LoadingFormButton color="orange" type="submit" btnText="Save" load={isSubmitting} />
                        </AnimateButton>
                    </Box>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

ScheduleForm.propTypes = {
    name: PropTypes.string,
    companySetup: PropTypes.any
};

export default ScheduleForm;
