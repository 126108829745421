import { Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';

import AllTrailerForm from 'ui-component/fleet/forms/AllTrailerForm';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLayout from 'layout/PageLayout';
import { Link } from 'react-router-dom';
import user_types from 'constant/user_types';
// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '8px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '18px',
                            fontWeight: 900,
                            marginRight: '5px',
                            color: 'white'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});
const AllTrailer = () => {
    const loginInfoFromStorage = JSON.parse(localStorage.getItem('loginUserInfo')) || {};
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const driver_view = permissions.driver && permissions.driver.view ? true : false;
    const truck_view = permissions.truck && permissions.truck.view ? true : false;
    const trailer_view = permissions.trailer && permissions.trailer.view ? true : false;
    const owner_view = permissions.assetowner && permissions.assetowner.view ? true : false;
    const [value, setValue] = useState(user_types.includes(user_type) ? 2 : driver_view + truck_view + 0);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainCard>
            <PageLayout title="Fleet">
                <Box sx={{ border: 'none', background: 'transparent' }}>
                    <ThemeProvider theme={theme}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{ style: { display: 'none' } }}
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '1rem'
                                }
                            }}
                        >
                            {(driver_view || user_types.includes(user_type)) && <Tab component={Link} label="Driver" to="/fleet/driver/all" />}
                            {(truck_view || user_types.includes(user_type)) && <Tab component={Link} label="Truck" to="/fleet/truck/all" />}
                            {(trailer_view || user_types.includes(user_type)) && <Tab component={Link} label="Trailer" to="/fleet/trailer/all" />}
                            {(owner_view || user_types.includes(user_type)) && <Tab component={Link} label="Owner" to="/fleet/owner/all" />}
                        </Tabs>
                    </ThemeProvider>

                    <AllTrailerForm />
                </Box>
            </PageLayout>
        </MainCard>
    );
};

export default AllTrailer;
