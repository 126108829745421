import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
// material-ui

import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Skeleton,
    TextField,
    Typography
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// project imports

// third party
import * as Yup from 'yup';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { Stack } from '@mui/system';
import SubCard from 'ui-component/cards/SubCard';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { useDispatch } from 'react-redux';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { PatternFormat } from 'react-number-format';
import { forwardRef } from 'react';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <PatternFormat
            {...other}
            allowEmptyFormatting
            getInputRef={ref}
            format="###-###-####"
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            // isNumericString
        />
    );
});

const OwnerForm = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    const driverConfig = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [showAccd, setShowAccd] = useState(false);
    const [driverDetails, setDriverDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [dError, setDError] = useState();
    const { id } = useParams();
    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [ssnShow, setSsnShow] = useState(true);

    useEffect(() => {
        if (id && !driverDetails) {
            fetchOwner(id);
        }
    }, [driverDetails, id]);

    const fetchOwner = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/fleet/owner/view/${id}`, config);
            setDriverDetails(data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const driveSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is a required field')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
        lastName: Yup.string()
            .required('Last Name is a required field')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
        email: Yup.string().email().required('Email is a required field'),
        phone: Yup.string()
            .required('Phone is a required field')
            .matches(/^[0-9-]+$/, 'Invalid Phone Number Format'),
        bankAc: Yup.string().max(20)
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            firstName: id && driverDetails ? driverDetails.user.first_name : '',
            lastName: id && driverDetails ? driverDetails.user.last_name : '',
            email: id && driverDetails ? driverDetails.user.email : '',
            phone: id && driverDetails ? driverDetails.user.phone : '',
            address: id && driverDetails ? driverDetails.address : '',
            bankAc: id && driverDetails ? driverDetails.bank_acc : '',
            isActive: driverDetails ? driverDetails.is_active : 'true'
        },
        validationSchema: driveSchema,
        onSubmit: async () => {
            try {
                const user = {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    email: values.email,
                    username: values.email,
                    phone: values.phone,
                    user_type: id ? driverDetails.owner_type : 'owner',
                    company_id: loginInfoFromStorage.company_id,

                    is_edit: id ? true : false
                };

                const driverDet = {
                    address: values.address,
                    bank_acc: values.bankAc,

                    company_id: loginInfoFromStorage.company_id,
                    user: user,
                    owner_type: id ? driverDetails.owner_type : 'owner',
                    // company_id: 39,
                    user_id: id && driverDetails && driverDetails.user.user_id,
                    is_active: true,
                    is_edit: id ? true : false
                };

                // setup.append('is_active', values.isActive === 'true' ? true : false);

                // setup.append('is_edit', id ? true : false);

                if (!id) {
                    const { data } = await axios.post(`/v1/api/fleet/owner/add`, driverDet, driverConfig);
                    dispatch(openSuccessSnackbar('Owner added successfully'));
                } else {
                    const { data } = await axios.put(`/v1/api/fleet/owner/view/${id}`, driverDet, driverConfig);
                    dispatch(openSuccessSnackbar('Owner updated successfully'));
                }
                navigate('/fleet/owner/all', { replace: true });
            } catch (error) {
                setDError(error.response.data);
                dispatch(openErrorSnackbar('Something Went Wrong!!'));
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.',
        error: Boolean(touched.phone && errors.phone) || (dError && dError.phone),
        helperText: (touched.phone && errors.phone) || (dError && dError.phone)
    };
    const LetterOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const AlphanumericOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z0-9 ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    console.info(errors);
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: '1rem' }}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                <Typography
                                    sx={{
                                        mt: '20px',
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '32px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    Owner Details
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Stack direction="row" sx={{ mt: '20px' }} justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    <LoadingFormButton load={isSubmitting && !dError} type="submit" btnText="Save" />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>
                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                        <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2, boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)' }}>
                            <Grid container spacing={3}>
                                {/* Customer */}
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            // width: '375px',
                                            height: '40px',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend'
                                        }}
                                    >
                                        General Information
                                    </Typography>
                                </Grid>

                                {/* Weight */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            type="text"
                                            label="First Name"
                                            {...getFieldProps('firstName')}
                                            onInput={LetterOnlyHandler}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                        />
                                    )}
                                </Grid>
                                {/* revenue */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            type="text"
                                            label="Last Name"
                                            {...getFieldProps('lastName')}
                                            onInput={LetterOnlyHandler}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        />
                                    )}
                                </Grid>
                                {/* Load */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            type="text"
                                            label="Email"
                                            {...getFieldProps('email')}
                                            error={Boolean(touched.email && errors.email) || (dError && dError.email)}
                                            helperText={(touched.email && errors.email) || (dError && dError.email)}
                                        />
                                    )}
                                </Grid>
                                {/* millage */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <PatternFormat
                                            format="###-###-####"
                                            allowEmptyFormatting
                                            mask="_"
                                            value={values.phone}
                                            onValueChange={(value) => setFieldValue('phone', value.value)}
                                            customInput={TextField}
                                            {...textFieldProps}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            type="text"
                                            label="Full US Address"
                                            {...getFieldProps('address')}
                                            error={Boolean(touched.address && errors.address)}
                                            helperText={touched.address && errors.address}
                                        />
                                    )}
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            className="text-input"
                                            color="orange"
                                            fullWidth
                                            type="text"
                                            label="Bank Account Number"
                                            {...getFieldProps('bankAc')}
                                            onInput={NumberOnlyHandler}
                                            error={Boolean(touched.bankAc && errors.bankAc)}
                                            helperText={touched.bankAc && errors.bankAc}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                    </SubCard>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

export default OwnerForm;
