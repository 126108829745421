import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import MaterialTable from '@material-table/core';
import { createRef } from 'react';
import { forwardRef } from 'react';
import TABLE_ICONS from 'tableIcons';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import Status from 'ui-component/buttons/Status';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function ELDImportTruck({ fullWidth, fullScreen, maxWidth, open, handleCloseModal, data }) {
    const tableRef = createRef();
    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Unit</th>
                    <th className="headerTd rightHidBor">Make</th>
                    <th className="headerTd rightHidBor">Year</th>
                    <th className="headerTd rightHidBor">Model</th>
                    <th className="headerTd rightHidBor">Vin No</th>
                    <th className="headerTd rightHidBor">License</th>
                    <th className="headerTd rightHidBor">Driver</th>
                    <th className="headerTd rightHidBor">Status</th>
                    <th className="headerTd rightHidBor">Import Status</th>
                    <th className="headerTd">Remark</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.unit}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.make}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.make_year}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.model}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.vin_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.license_no}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="InActive" />}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.upload_status === 'success' ? <Status text="Success" /> : <Status text="Failed" />}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.remarks}
                </td>
                {/* Custom cell or additional cells */}
            </tr>
        );
    };
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogTitle>Import Status</DialogTitle>
            <DialogContent dividers>
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                    data={data}
                    title="All Truck"
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,
                        filtering: true,
                        toolbar: false,
                        draggable: false,
                        columnResizable: true,
                        paging: false
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleCloseModal}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ELDImportTruck.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'md'
};

export default ELDImportTruck;
