import {
    Box,
    Button,
    Card,
    CardMedia,
    // Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    // Grid,
    IconButton,
    Stack,
   
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
function PdfDocPrevModal({ open, handleClose, docPreview,  approveHandler, loading, type }) {
    const [currentUrlIndex, setCurrentUrlIndex] = useState(0);

    const handleNextClick = () => {
        if (currentUrlIndex < docPreview.length - 1) {
            setCurrentUrlIndex(currentUrlIndex + 1);
        }
    };

    const handlePreviousClick = () => {
        if (currentUrlIndex > 0) {
            setCurrentUrlIndex(currentUrlIndex - 1);
        }
    };

    let currentUrl = docPreview[currentUrlIndex];

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
            <DialogTitle sx={{ direction: 'row', alignItems: 'center' }}>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
                    {/* <Typography variant="h5" color="#000" fontWeight={500} fontFamily="Jost" fontSize="24px">
                        {modalText}
                    </Typography> */}

                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Box sx={{ mt: 3 }}>
                    <DialogContent dividers>
                        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                            <IconButton color="secondary" aria-label="add an alarm" onClick={handlePreviousClick} disabled={currentUrlIndex === 0}>
                                <ArrowBackIosIcon fontSize="medium" />
                            </IconButton>

                            {(docPreview && currentUrlIndex && docPreview[currentUrlIndex].split('.').pop() === 'jpeg') ||
                            (docPreview && currentUrlIndex && docPreview[currentUrlIndex].split('.').pop() === 'jpg') ||
                            (docPreview && currentUrlIndex && docPreview[currentUrlIndex].split('.').pop() === 'png') ? (
                                <Card>
                                    <CardMedia component="img" style={{ objectFit: 'cover' }} image={docPreview[currentUrlIndex]} alt={`Image `} />
                                </Card>
                            ) : (
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                    <Viewer fileUrl={docPreview[currentUrlIndex]} />
                                </Worker>
                            )}
                            <IconButton
                                color="secondary"
                                aria-label="add an alarm"
                                onClick={handleNextClick}
                                disabled={currentUrlIndex === docPreview.length - 1}
                            >
                                <ArrowForwardIosIcon fontSize="medium" />
                            </IconButton>
                        </Stack>
                    </DialogContent>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        mr: 2,
                        color: 'white',
                        backgroundColor: '#EB812E',
                        fontFamily: 'Lexend',
                        fontWeight: 400,
                        lineHeight: '20px',
                        height: '36px',
                        fontSize: '16px',
                        borderRadius: '4px'
                    }}
                    color="orange"
                    variant="contained"
                    onClick={handleClose}
                >
                    cancel
                </Button>
                {type === 'other' ? (
                    <LoadingFormButton
                        type="button"
                        variant="contained"
                        sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                        color="orange"
                        load={loading}
                        onClickHandler={approveHandler}
                        btnText="Confirm"
                    />
                ) : (
                    ''
                )}
            </DialogActions>
        </Dialog>
    );
}

export default PdfDocPrevModal;
