import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Skeleton,
    TextField,
    Typography
} from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { format } from 'date-fns';
// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { Stack } from '@mui/system';
import BackFormButton from 'ui-component/buttons/BackFormButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const MaintenanceForm = () => {
    // const { companySetup } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorAdd, setErrorAdd] = useState('');
    
    const [loading1, setLoading1] = useState(false);
    const [docTypelist, setDocTypelist] = useState();
    const [loading, setLoading] = useState(false);
    const [driverList, setDriverList] = useState();
    const [maintenanceData, setMaintenanceData] = useState();
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.company_id;
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const configJson = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };


    useEffect(() => {
        if (!driverList) {
            fetchDriver();
        }

        if (driverList && id) {
            alert(id);
            fetchMaintenance();
        }
    }, [docTypelist, driverList, id, maintenanceData]);

    const fetchMaintenance = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/mechanic/mcn-maintenance/view/${id}`, config);

            setMaintenanceData(data.results);

            setLoading(false);
        } catch (error) {
            setLoading(false);
          
        }
    };

    const fetchDriver = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/mechanic/mcn-srv-driver-map/list?page=1&page_size=100&search=&mcn_company_id=${cid}`, config);
         
            setDriverList(data.results);
            setOptions(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
           
        }
    };

    const fetchDocType = async (driverData) => {
        setLoading1(true);

        try {
            const { data } = await axios.get(
                `/v1/api/fleet/safety/public-doc-type-vq/list/${driverData.company_id}/`,

                configJson
            );

            setDocTypelist(data);

            setLoading1(false);
        } catch (error) {
            setLoading1(false);
          
        }
    };

    const OrganizationSchema = Yup.object().shape({
        driver: Yup.mixed().required('Driver is required'),
        companyName: Yup.string().required('Company Name is Required'),
        plate: Yup.string().required('License Plate is Required'),
        maintenanceType: Yup.number().required('Maintanence Type is required').typeError('Maintanence Type is required'),
        maintenanceDate: Yup.string().required('Maintanence Date is required'),
        docName: Yup.string().required('File is required')
        // maintenanceType: Yup.string().required('Maintanence is required')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            companyName: '',
            maintenanceType: '',
            maintenanceDate: '',
            nextMaintenanceDate: '',
            driver: '',

            plate: '',
            doc: '',
            docName: ''
        },
        validationSchema: OrganizationSchema,
        onSubmit: async () => {
            setLoading(true);
            try {
                const { data } = await axios.post(
                    `/v1/api/mechanic/mcn-maintenance/add`,
                    {
                        mcn_company_id: loginInfoFromStorage.company_id,
                        company_id: values.driver.company_id,
                        mcn_srv_driver_id: values.driver.mcn_srv_driver_id,
                        truck_id: values.driver.truck_license.truck_id,
                        sft_doc_type_id: values.maintenanceType,
                        maintenance_date: values.maintenanceDate ? format(new Date(values.maintenanceDate), 'yyyy-MM-dd') : '',
                        next_maintenance_date: values.nextMaintenanceDate ? format(new Date(values.nextMaintenanceDate), 'yyyy-MM-dd') : '',
                        sft_doc: values.doc,
                        sft_doc_name: values.docName,
                        created_by: loginInfoFromStorage.user_id,
                        is_active: true,
                        is_edit: true
                    },
                    config
                );

                dispatch(openSuccessSnackbar('Maintenance Details added successfully'));
                navigate('/mechanic/maintenance', { replace: true });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrorAdd(error.response.data);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

  

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography
                                sx={{
                                    mt: '5',
                                    width: '375px',
                                    height: '40px',
                                    fontWeight: '600',
                                    fontSize: '32px',
                                    fontFamily: 'Lexend'
                                }}
                            >
                                Maintenance Details
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    {/* <LoadingFormButton type="button" btnText="Edit" onClick={() => editOrganization} /> */}
                                    <BackFormButton />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            {/* Customer */}
                            {/* General Info */}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        width: '375px',
                                        height: '40px',
                                        fontWeight: '600',
                                        fontSize: '24px',
                                        fontFamily: 'Lexend'
                                    }}
                                >
                                    Add Maintenance Form
                                </Typography>
                            </Grid>

                            {/* User Name */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            className="text-input"
                                            id="driver-set"
                                            options={options}
                                            loading={loading1}
                                            isOptionEqualToValue={(option, value) => option.mcn_srv_drv_id === value.mcn_srv_drv_id}
                                            getOptionLabel={(option) => (option.driver_name ? option.driver_name : '')}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            value={values.driver}
                                            onChange={(event, newValue) => {
                                                // setDriverValue(newValue.name);

                                                if (newValue != null) {
                                                    setFieldValue('driver', newValue);
                                                    setFieldValue('plate', newValue.truck_license.license_no);
                                                    setFieldValue('companyName', newValue.driver_cmp_name.company_name);

                                                    fetchDocType(newValue);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Driver"
                                                    onChange={(ev) => {
                                                        if (ev.target.value !== '' || ev.target.value !== null || ev.target.value.length > 1) {
                                                            onChangeHandle(ev.target.value);
                                                        }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                    error={Boolean(touched.driver && errors.driver)}
                                                    helperText={touched.driver && errors.driver}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading || loading1 ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        inputProps={{ readOnly: true }}
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Company Name"
                                        {...getFieldProps('companyName')}
                                        error={Boolean((touched.companyName && errors.companyName) || (errorAdd && errorAdd.state_id))}
                                        helperText={(touched.companyName && errors.companyName) || (errorAdd && errorAdd.state_id)}
                                    />
                                )}
                            </Grid>
                            {/* Weight */}
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading1 || loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        inputProps={{ readOnly: true }}
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Plate#"
                                        {...getFieldProps('plate')}
                                        error={Boolean((touched.plate && errors.plate) || (errorAdd && errorAdd.state_id))}
                                        helperText={(touched.plate && errors.plate) || (errorAdd && errorAdd.state_id)}
                                    />
                                )}
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading || loading1 ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        select
                                        type="text"
                                        label="Maintenance Type"
                                        value={values.maintenanceType}
                                        onChange={(e) => {
                                            setFieldValue('maintenanceType', e.target.value);
                                        }}
                                        error={Boolean((touched.maintenanceType && errors.maintenanceType) || (errorAdd && errorAdd.state_id))}
                                        helperText={(touched.maintenanceType && errors.maintenanceType) || (errorAdd && errorAdd.state_id)}
                                    >
                                        <MenuItem value={''}>Select Maintenance Type</MenuItem>
                                        {docTypelist &&
                                            docTypelist.map((i) => (
                                                <MenuItem key={i.sft_doc_type_id} value={i.sft_doc_type_id}>
                                                    {i.stf_doc_type_name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="text-input"
                                            label="Start Date"
                                            openTo="day"
                                            views={['year', 'month', 'day']}
                                            value={values.maintenanceDate}
                                            onChange={(newValue) => {
                                                setFieldValue('maintenanceDate', newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    className="text-input"
                                                    color="orange"
                                                    fullWidth
                                                    {...params}
                                                    error={Boolean(touched.maintenanceDate && errors.maintenanceDate)}
                                                    helperText={touched.maintenanceDate && errors.maintenanceDate}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="text-input"
                                            label="End Date"
                                            openTo="day"
                                            views={['year', 'month', 'day']}
                                            value={values.nextMaintenanceDate}
                                            onChange={(newValue) => {
                                                setFieldValue('nextMaintenanceDate', newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    className="text-input"
                                                    color="orange"
                                                    fullWidth
                                                    {...params}
                                                    error={Boolean(touched.nextMaintenanceDate && errors.nextMaintenanceDate)}
                                                    helperText={touched.nextMaintenanceDate && errors.nextMaintenanceDate}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        className="text-input"
                                        color="orange"
                                        fullWidth
                                        value={values.docName}
                                        error={Boolean(touched.docName && errors.docName)}
                                        helperText={touched.docName && errors.docName}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end">
                                                        <Button component="label" color="orange" startIcon={<CloudUploadIcon />}>
                                                            Uplaod
                                                            <input
                                                                hidden
                                                                type="file"
                                                                onInputCapture={(event) => {
                                                                    setFieldValue('doc', event.currentTarget.files[0]);
                                                                    setFieldValue('docName', event.currentTarget.files[0].name);
                                                                }}
                                                            />
                                                        </Button>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        label=" Document"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </SubCard>

                    <Box sx={{ mt: 2 }} align="center">
                        <AnimateButton>
                            <LoadingFormButton color="orange" type="submit" btnText="Save" load={isSubmitting} />
                        </AnimateButton>
                    </Box>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

MaintenanceForm.propTypes = {
    name: PropTypes.string,
    companySetup: PropTypes.any
};

export default MaintenanceForm;
