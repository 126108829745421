import React from 'react';
import ViewMemberForm from 'ui-component/setup/forms/ViewMemberForm';
import PageLayoutWithBack from 'layout/PageLayoutWithBack';

function ViewMember() {
    return (
        <PageLayoutWithBack>
            <ViewMemberForm />
        </PageLayoutWithBack>
    );
}

export default ViewMember;
