import { Stack, Typography } from '@mui/material';
import React from 'react';

function ViewFields({ sx, viewText, icon, title }) {
    return (
        <Stack direction="column" justifyContent="center" alignItems="flex-start" sx={{ mt: 2, height: '30px', ...sx }}>
            <Typography
                variant="h4"
                sx={{ mb: 1, mt: 2 }}
                sx={{
                    fontWeight: '400',
                    fontSize: '16px',
                    fontFamily: 'Lexend'
                }}
            >
                {title}
            </Typography>
            <Typography variant="body1">{viewText}</Typography>

            <div>{icon}</div>
        </Stack>
    );
}

export default ViewFields;
