import { SvgIcon } from '@mui/material';
import React from 'react';

function AccountingIcon() {
    return (
        <SvgIcon>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_8003_25349)">
                    <path d="M18.7578 5.30579H23.5289C22.6658 4.44063 19.7044 1.48204 18.7578 0.539062V5.30579Z" fill="#142953" />
                    <path
                        d="M27.1794 12.1953H0.820304C0.367278 12.1953 0 12.5626 0 13.0156V21.9843C0 22.4373 0.367278 22.8046 0.820304 22.8046H27.1794C27.6324 22.8046 27.9997 22.4373 27.9997 21.9843V13.0156C27.9997 12.5625 27.6325 12.1953 27.1794 12.1953ZM9.18741 15.2578H7.82024V20.5624C7.82024 21.0154 7.45296 21.3827 6.99993 21.3827C6.5469 21.3827 6.17963 21.0154 6.17963 20.5624V15.2578H4.81245C4.35943 15.2578 3.99215 14.8905 3.99215 14.4375C3.99215 13.9845 4.35943 13.6172 4.81245 13.6172H9.18741C9.64044 13.6172 10.0077 13.9845 10.0077 14.4375C10.0077 14.8905 9.64044 15.2578 9.18741 15.2578ZM16.6945 21.3303C16.2706 21.4896 15.7975 21.2749 15.6382 20.8508L15.3451 20.0702H12.6569L12.3609 20.8526C12.2368 21.1808 11.9248 21.3829 11.5935 21.3829C11.0243 21.3829 10.6223 20.8116 10.8264 20.2721C13.2155 13.9561 13.1115 14.2306 13.1181 14.2145C13.2667 13.8513 13.6163 13.6171 14.0081 13.6171C14.4234 13.6171 14.7747 13.8797 14.9066 14.2366L17.1741 20.2739C17.3333 20.6981 17.1187 21.171 16.6945 21.3303ZM23.188 21.3828C22.932 21.3828 22.6799 21.2633 22.5198 21.0392L20.9998 18.9113L19.4798 21.0392C19.3197 21.2633 19.0675 21.3828 18.8116 21.3828C18.1519 21.3828 17.7552 20.6311 18.1448 20.0856L19.9917 17.4999L18.1448 14.9143C17.8815 14.5456 17.9669 14.0333 18.3355 13.77C18.7041 13.5067 19.2165 13.592 19.4798 13.9607L20.9998 16.0886L22.5198 13.9607C22.7831 13.5921 23.2956 13.5067 23.6641 13.77C24.0327 14.0333 24.1182 14.5456 23.8548 14.9143L22.0079 17.4999L23.8548 20.0856C24.2445 20.6312 23.8476 21.3828 23.188 21.3828Z"
                        fill="#142953"
                    />
                    <path
                        d="M3.99219 26.3594C3.99219 27.264 4.72816 28 5.6328 28H22.367C23.2716 28 24.0076 27.264 24.0076 26.3594V24.4453H3.99219V26.3594Z"
                        fill="#142953"
                    />
                    <path
                        d="M24.0076 6.94548H17.9374C17.4843 6.94548 17.1171 6.5782 17.1171 6.12518V0.00034705C15.8658 -0.00036388 12.8645 0.000237676 5.6328 0.000237676C4.72816 0.000237676 3.99219 0.790902 3.99219 1.69553V10.5548H24.0076V6.94548ZM12.2499 8.69547H8.31246C7.85943 8.69547 7.49215 8.32819 7.49215 7.87516C7.49215 7.42213 7.85943 7.05486 8.31246 7.05486H12.2499C12.7029 7.05486 13.0702 7.42213 13.0702 7.87516C13.0702 8.32819 12.7029 8.69547 12.2499 8.69547ZM12.2499 5.1955H8.31246C7.85943 5.1955 7.49215 4.82822 7.49215 4.37519C7.49215 3.92217 7.85943 3.55489 8.31246 3.55489H12.2499C12.7029 3.55489 13.0702 3.92217 13.0702 4.37519C13.0702 4.82822 12.7029 5.1955 12.2499 5.1955Z"
                        fill="#142953"
                    />
                    <path d="M13.2773 18.4294H14.7288L14.0057 16.5039L13.2773 18.4294Z" fill="#142953" />
                </g>
                <defs>
                    <clipPath id="clip0_8003_25349">
                        <rect width="28" height="28" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}

export default AccountingIcon;
