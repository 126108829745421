
import MainCard from 'ui-component/cards/MainCard';
import ScheduleForm from 'ui-component/mechanic/forms/ScheduleForm';



function Schedule() {
    return (
        <MainCard>
      <ScheduleForm/>
        </MainCard>
    );
}

export default Schedule;
