import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LoopIcon from '@mui/icons-material/Loop';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, TextField, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { queryClient } from 'index';
import PropTypes from 'prop-types';
import * as React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddBillModal from './AddBillModal';
import EditBillModal from './EditBillModal';
import TableRowAnimation from './TableRowAnimation';
import './expenses.css';
import { useLocation, useNavigate } from 'react-router';
import debounce from 'lodash.debounce';
import { useMemo } from 'react';
import user_types from 'constant/user_types';

const headCells = [
    {
        id: 'vendor',
        label: 'Vendor'
    },
    {
        id: 'bill_no',
        label: 'Bill No'
    },
    {
        id: 'bill_date',
        label: 'Bill Date'
    },
    {
        id: 'due_date',
        label: 'Due Date'
    },

    {
        id: 'category',
        label: 'Category'
    },
    {
        id: 'Amount',
        label: 'Total Amount'
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
        <TableHead>
            <TableRow sx={{ backgroundColor: '#DDE6FF' }}>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ))}
                <TableCell key={'action'} sortDirection={false}>
                    Actions
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const { numSelected, handleBulkDelete, setAddBillDrawer, deleteBulkPending } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const params = new URLSearchParams(queryParams);

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>

                {((permissions.bill && permissions.bill.delete) || user_types.includes(user_type)) && (
                    <Tooltip title="Delete">
                        <IconButton onClick={handleBulkDelete} disabled={deleteBulkPending}>
                            {deleteBulkPending ? <LoopIcon className="rotation-icon" /> : <DeleteIcon />}
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 2,
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: '1rem', sm: 0 }
                }}
            >
                <TextField
                    type="text"
                    onChange={debounce((e) => {
                        params.set('search', e.target.value);
                        navigate({ search: `?${params.toString()}` });
                    }, 1000)}
                    size="small"
                    placeholder="Search..."
                    // sx={{ width: { xs: '310px', sm: '350px' } }}
                    sx={{ width: { xs: '74vw', sm: '45vw', lg: '20vw' } }}
                    className="search-input"
                />

                {((permissions.bill && permissions.bill.add) || user_types.includes(user_type)) && (
                    <Button
                        variant="contained"
                        onClick={() => setAddBillDrawer(true)}
                        startIcon={<AddIcon />}
                        color="orange"
                        sx={{
                            color: 'white',
                            // minWidth: { xs: '19.3rem', sm: '10rem' }
                            maxWidth: { xs: '18rem', sm: '11rem' }
                        }}
                    >
                        Add new Bill
                    </Button>
                )}
            </Box>
        </Toolbar>
    );
}

function Bills() {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const params = new URLSearchParams(queryParams);
    const search = params.get('search') || '';
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const fetchBills = async () => {
        const response = await axios.get(`/v1/api/accounting/bills/?page=${page + 1}&page_size=${rowsPerPage}&search=${search}`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
        return response.data;
    };
    const [addStatus, setAddStatus] = React.useState(null);
    const [editStatus, setEditStatus] = React.useState({
        id: null,
        status: null
    });

    const changeAddStatus = (status) => {
        setAddStatus(status);
    };

    const changeEditStatus = (status) => {
        setEditStatus(status);
    };

    const { data: billList, isLoading } = useQuery({
        queryKey: ['bills', search, page, rowsPerPage],
        queryFn: fetchBills,
        staleTime: 1000 * 60 * 60
    });

    const [clickedOptionId, setClickedOptionId] = React.useState({ id: null, open: false });

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = billList?.results?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (_event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const deleteBulkBill = async (formData) => {
        try {
            const response = await axios.post('/v1/api/accounting/bills/bulk_delete/', formData, {
                headers: {
                    Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                }
            });
            await queryClient.invalidateQueries({ queryKey: ['bills', search, page, rowsPerPage] });
            return response;
        } catch (error) {
            throw new Error("Can't delete the item");
        }
    };

    const handleBulkDelete = async () => {
        if (selected.length == 0) {
            toast.error('0 Item selected');
            return;
        }
        await toast.promise(deleteBulkBill({ bill_ids: selected }), {
            pending: `Deleting ${selected.length} Item...`,
            success: `${selected.length} Item deleted successfully`,
            error: `Failed to delete Item`
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const visibleRows = billList?.results;

    const deleteBill = async (id) => {
        return await axios.delete(`/v1/api/accounting/bills/${id}`, {
            headers: {
                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
            }
        });
    };

    const { mutate: deleteExpenseMutate, isPending: deleteExpensePending } = useMutation({
        mutationFn: deleteBill,
        onSuccess: async () => {
            toast.success('Bill deleted successfully.');
            return await queryClient.invalidateQueries({ queryKey: ['bills', search, page, rowsPerPage] });
        },
        onError: (error) => {
            toast.error(error.response.data.detail || "Can't deleted");
        }
    });

    const getSumOfAmount = (amounts) => {
        if (!Array.isArray(amounts)) {
            return 0; // Or return a placeholder value if desired
        }
        return parseFloat(amounts.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)).toFixed(2);
    };

    const getSumCategory = (categories) => {
        if (!Array.isArray(categories)) {
            return '';
        }
        const newCategories = categories.map((item) => item.category_name);
        const uniqueCategories = new Set(newCategories);

        if (uniqueCategories.size === 1) {
            return Array.from(uniqueCategories)[0]; // All categories are the same, return the first one
        } else {
            return '-- split --'; // Different categories present, return the separator
        }
    };

    const totalAmount = useMemo(() => {
        return billList?.results?.reduce((accumulator, current) => accumulator + current?.bill_amount.reduce((acc, curr) => acc + curr.amount, 0), 0);
    }, [billList]);

    const [editBillDrawer, setEditBillDrawer] = React.useState(false);
    const [deleteBulkPending, setDeleteBulkPending] = React.useState(false);
    const [addBillDrawer, setAddBillDrawer] = React.useState(false);

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    handleBulkDelete={handleBulkDelete}
                    deleteBulkPending={deleteBulkPending}
                    setAddBillDrawer={setAddBillDrawer}
                />
                {isMobile ? (
                    visibleRows?.map((driver, idx) => {
                        const isItemSelected = isSelected(driver.id);
                        const labelId = idx;
                        return (
                            <>
                                <h1>httt</h1>
                                <Card
                                    key={driver.driver_id}
                                    sx={{
                                        marginBottom: '1.5rem',
                                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                        borderRadius: '15px',
                                        backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                    }}
                                >
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                            <Checkbox
                                                onClick={(event) => handleClick(event, driver.id)}
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId
                                                }}
                                            />
                                            <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                                {(popupState) => (
                                                    <div style={{ float: 'right', marginTop: '1px' }}>
                                                        <IconButton {...bindTrigger(popupState)} size="large">
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                        <Menu
                                                            {...bindMenu(popupState)}
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                        >
                                                            {((permissions.driver && permissions.driver.change) || user_types.includes(user_type)) && (
                                                                <MenuItem onClick={() => setEditDrawerOpen(true)}>Edit Expense</MenuItem>
                                                            )}
                                                            {/* <MenuItem onClick={() => viewDriver(driver.driver_id, popupState)}>Delete Expense</MenuItem> */}

                                                            <MenuItem disabled={deleteExpensePending} onClick={() => deleteExpenseMutate(driver.id)}>
                                                                {deleteExpensePending ? 'Loop Expense' : 'Delete Expense'}
                                                            </MenuItem>
                                                            <MenuItem onClick={() => setClickedOptionId((prev) => ({ id: driver.id, open: !prev.open }))}>
                                                                Close
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </Box>

                                        <Box sx={{ clear: 'both' }} />

                                        <Typography sx={{ marginBottom: '.5rem' }}>Vendor : {driver.vendor_name}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Bill No : {driver.bill_no}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Bill Date : {driver.bill_date}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Due Date : {driver.due_date}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Category : {getSumCategory(driver.bill_amount)}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem' }}>Total Amount : ${getSumOfAmount(driver.bill_amount)}</Typography>
                                    </CardContent>
                                </Card>
                                <Box sx={{ padding: '1rem', fontSize: '4vw', fontWeight: 600 }}>Total $&nbsp;{Number(totalAmount || 0).toFixed(2)}</Box>
                            </>
                        );
                    })
                ) : isTablet ? (
                    <>
                        {isLoading ? (
                            <>
                                <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress />
                                </Box>
                            </>
                        ) : (
                            visibleRows?.map((driver, idx) => {
                                const isItemSelected = isSelected(driver.id);
                                const labelId = idx;
                                return (
                                    <Card
                                        key={driver.driver_id}
                                        sx={{
                                            marginTop: '2rem',
                                            marginBottom: '1.5rem',
                                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                            borderRadius: '15px',
                                            backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                        }}
                                    >
                                        <CardContent>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                                <Checkbox
                                                    sx={{ padding: 0 }}
                                                    onClick={(event) => handleClick(event, driver.id)}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                                <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                                    {(popupState) => (
                                                        <div style={{ float: 'right', marginTop: '1px' }}>
                                                            <IconButton {...bindTrigger(popupState)} size="large">
                                                                <MoreHorizIcon />
                                                            </IconButton>
                                                            <Menu
                                                                {...bindMenu(popupState)}
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                            >
                                                                {((permissions.driver && permissions.driver.change) || user_types.includes(user_type)) && (
                                                                    <MenuItem onClick={() => setEditDrawerOpen(true)}>Edit Expense</MenuItem>
                                                                )}
                                                                {/* <MenuItem onClick={() => viewDriver(driver.driver_id, popupState)}>Delete Expense</MenuItem> */}

                                                                <MenuItem disabled={deleteExpensePending} onClick={() => deleteExpenseMutate(driver.id)}>
                                                                    {deleteExpensePending ? 'Loop Expense' : 'Delete Expense'}
                                                                </MenuItem>
                                                                <MenuItem onClick={() => setClickedOptionId((prev) => ({ id: driver.id, open: !prev.open }))}>
                                                                    Close
                                                                </MenuItem>
                                                            </Menu>
                                                        </div>
                                                    )}
                                                </PopupState>
                                            </Box>

                                            {/* </Box> */}
                                            <Box sx={{ clear: 'both' }} />

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Vendor : {driver.vendor_name}</Typography>

                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Bill No : {driver.bill_no}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Bill Date : {driver.bill_date}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw', alignItems: 'center' }}>
                                                    Due Date : {driver.due_date}{' '}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                                    Category : {getSumCategory(driver.bill_amount)}{' '}
                                                </Typography>
                                                <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw', alignItems: 'center' }}>
                                                    Total Amount : ${getSumOfAmount(driver.bill_amount)}{' '}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                );
                            })
                        )}
                        <Box sx={{ padding: '1rem', fontSize: '4vw', fontWeight: 600 }}>Total $&nbsp;{Number(totalAmount || 0).toFixed(2)}</Box>
                    </>
                ) : (
                    <TableContainer>
                        <table className="expense-table" style={{ minWidth: 750, borderSpacing: '0 10px' }} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={billList?.results?.length || 0}
                            />
                            <AnimatePresence>
                                {isLoading ? (
                                    // laoding table skeleton
                                    <motion.tbody
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0.5, height: 0 }}
                                    >
                                        <TableRowAnimation row={4} />
                                    </motion.tbody>
                                ) : (
                                    <motion.tbody
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0.5, height: 0 }}
                                    >
                                        {addStatus == 'pending' && <TableRowAnimation />}
                                        {visibleRows?.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            if (editStatus.id === row.id && editStatus.status == 'pending') {
                                                return <TableRowAnimation />;
                                            } else {
                                                return (
                                                    <TableRow
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        sx={{
                                                            backgroundColor: isItemSelected ? '#8080801f' : '',
                                                            paddingTop: 0,
                                                            '&:hover': {
                                                                backgroundColor: '#8080801f'
                                                            }
                                                        }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                onClick={(event) => handleClick(event, row.id)}
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{row.vendor_name}</TableCell>
                                                        <TableCell>{row.bill_no}</TableCell>
                                                        <TableCell>{row.bill_date}</TableCell>
                                                        <TableCell id={labelId}>{row.due_date}</TableCell>
                                                        <TableCell>{getSumCategory(row.bill_amount)}</TableCell>
                                                        <TableCell>${getSumOfAmount(row.bill_amount)}</TableCell>
                                                        <TableCell sx={{ position: 'relative' }}>
                                                            <AnimatePresence>
                                                                {clickedOptionId.open && clickedOptionId.id === row.id && (
                                                                    <motion.div
                                                                        initial={{
                                                                            scale: 0
                                                                        }}
                                                                        animate={{
                                                                            scale: 1
                                                                        }}
                                                                        exit={{
                                                                            scale: 0
                                                                        }}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            right: '35%',
                                                                            bottom: 5,
                                                                            display: 'flex',
                                                                            gap: 10,
                                                                            alignItems: 'center',
                                                                            background: '#0b172e',
                                                                            borderRadius: 10,
                                                                            padding: 0
                                                                        }}
                                                                    >
                                                                        {((permissions.bill && permissions.bill.change) || user_types.includes(user_type)) && (
                                                                            <Tooltip title="Edit Bill" placement="top">
                                                                                <IconButton onClick={() => setEditBillDrawer(true)}>
                                                                                    <DriveFileRenameOutlineIcon
                                                                                        sx={{
                                                                                            bgcolor: '#8080806b',
                                                                                            color: 'white',
                                                                                            borderRadius: '50%',
                                                                                            padding: 0.5,
                                                                                            fontSize: 35
                                                                                        }}
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                        {/* Edit bill modal */}
                                                                        <EditBillModal
                                                                            editBillDrawer={editBillDrawer}
                                                                            setEditBillDrawer={setEditBillDrawer}
                                                                            bill={row}
                                                                            setClickedOptionId={setClickedOptionId}
                                                                            changeEditStatus={changeEditStatus}
                                                                            search={search}
                                                                            page={page}
                                                                            rowsPerPage={rowsPerPage}
                                                                        />

                                                                        {permissions.bill && permissions.bill.delete && (
                                                                            <Tooltip title="Delete Bill" placement="top">
                                                                                <IconButton
                                                                                    disabled={deleteExpensePending}
                                                                                    onClick={() => deleteExpenseMutate(row.id)}
                                                                                >
                                                                                    {deleteExpensePending ? (
                                                                                        <LoopIcon
                                                                                            className="rotation-icon"
                                                                                            sx={{
                                                                                                bgcolor: '#8080806b',
                                                                                                color: deleteExpensePending ? 'gray' : 'white',
                                                                                                borderRadius: '50%',
                                                                                                padding: 0.5,
                                                                                                fontSize: 35
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <DeleteIcon
                                                                                            sx={{
                                                                                                bgcolor: '#8080806b',
                                                                                                color: deleteExpensePending ? 'gray' : 'white',
                                                                                                borderRadius: '50%',
                                                                                                padding: 0.5,
                                                                                                fontSize: 35
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                        <Tooltip title="Close" placement="top">
                                                                            <IconButton
                                                                                onClick={() => setClickedOptionId((prev) => ({ id: row.id, open: !prev.open }))}
                                                                            >
                                                                                <CloseIcon
                                                                                    sx={{
                                                                                        bgcolor: '#8080806b',
                                                                                        color: 'white',
                                                                                        borderRadius: '50%',
                                                                                        padding: 0.5,
                                                                                        fontSize: 35,
                                                                                        zIndex: 2
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </motion.div>
                                                                )}
                                                            </AnimatePresence>
                                                            <Tooltip title="Open Option" placement="right-start">
                                                                <IconButton onClick={() => setClickedOptionId((prev) => ({ id: row.id, open: !prev.open }))}>
                                                                    <MoreHorizIcon sx={{ fontSize: 35, padding: 0.5 }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        })}
                                        <tr className="total-row">
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell colSpan={2} sx={{ fontWeight: 'bolder' }}>
                                                Total
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bolder' }}>$&nbsp;{Number(totalAmount || 0).toFixed(2)}</TableCell>
                                            <TableCell></TableCell>
                                        </tr>
                                    </motion.tbody>
                                )}
                            </AnimatePresence>
                        </table>
                    </TableContainer>
                )}
                {billList?.results?.length === 0 && !isLoading && (
                    <Typography variant="h3" align="center" sx={{ py: 3, backgroundColor: '#14295317' }}>
                        No bills found
                    </Typography>
                )}

                {isMobile ? (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={billList?.count || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: '30vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                ) : (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={billList?.count || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Paper>
            {/* Add Bill Modal */}
            <AddBillModal
                addBillDrawer={addBillDrawer}
                setAddBillDrawer={setAddBillDrawer}
                changeAddStatus={changeAddStatus}
                search={search}
                page={page}
                rowsPerPage={rowsPerPage}
            />
        </Box>
    );
}

export default Bills;
