import { MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function RevenueDoughnutChart({ send, isReady }) {
    const currentYear = Number(new Date().getFullYear());
    const years = [currentYear, currentYear - 1, currentYear - 2];

    const [year, setYear] = useState(currentYear);
    const dashboardState = useSelector((state) => state.dashboard);
    const [isRequest, setIsRequest] = useState(true);

    const isMobile = window.innerWidth < 600;
    const isTablet = window.innerWidth >= 600 && window.innerWidth <= 1024;

    const option = {
        animationDuration: 1500,
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        tooltip: {
            trigger: 'item'
        },
        color: ['#5AC47C', '#142953', '#D3505F', '#f48120'],
        series: [
            {
                name: 'Revenues',
                type: 'pie',
                radius: isMobile ? '50%' : isTablet ? '65%' : '75%',
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                data: dashboardState.revenues || [
                    { value: 0, name: 'deposited invoices' },
                    { value: 0, name: 'sales money' }
                ]
            }
        ]
    };

    const handleYearChange = (e) => {
        setIsRequest(true);
        setYear(e.target.value);
    };

    useEffect(() => {
        if (isRequest && isReady) {
            send({ event_type: 'revenues', year: Number(year) });
            setIsRequest(false);
        }
    }, [isReady, year]);

    return (
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '20px', borderRadius: '15px', height: '400px' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: { xs: 'start', sm: 'center' },
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    }
                }}
            >
                {' '}
                <Typography
                    sx={{
                        fontSize: { xs: '6vw', lg: '1.7vw', sm: '3vw' },
                        margin: 0
                    }}
                >
                    All Revenues
                </Typography>
                <TextField select label="Year" value={year} onChange={handleYearChange} sx={{ mt: { xs: '2rem', sm: '0' } }}>
                    {years.map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            {dashboardState.revenues !== undefined ? (
                <ReactECharts option={option} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
            ) : (
                <Skeleton height={'100%'} sx={{ mt: { xs: '-3rem', sm: '0' } }} />
            )}
        </div>
    );
}

export default RevenueDoughnutChart;
