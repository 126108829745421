import { getDashboardData } from './dashboard';
import pages from './pages';
import utilities from './utilities';

import other from './other';

// ==============================|| MENU ITEMS ||============================== //



export const getMenuItems = () => {
    const dashboard = getDashboardData();
    return {
        items: [dashboard]
    };
};

