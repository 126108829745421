import LocalShipping from '@mui/icons-material/LocalShipping';
import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function TruckCount({ send, isReady }) {
    const dashboardState = useSelector((state) => state.dashboard);
    const [isRequest, setIsRequest] = useState(true);

    useEffect(() => {
        if (dashboardState.truckCount === undefined && isRequest && isReady) {
            send({ event_type: 'truck_count' });
            setIsRequest(false);
        }
    }, [isReady]);

    return (
        // <div style={{ width: '100%', borderRadius: 5, boxShadow: '0px 0px 4px 2px #142953', padding: '15px 20px' }}>
        <div style={{ width: '100%', borderRadius: 5, boxShadow: '0px 0px 4px 2px #142953', padding: '15px 20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', alignItems: 'center' }}>
                <h4 style={{ fontSize: '17px', color: '#142953' }}>Total Trucks</h4>
                <LocalShipping style={{ fontSize: '50px', color: '#142953' }} />
            </div>
            {dashboardState.truckCount !== undefined || dashboardState.truckCount === 0 ? (
                <strong style={{ fontSize: '20px', color: '#142953' }}>{dashboardState.truckCount}</strong>
            ) : (
                <Skeleton height={30} />
            )}
        </div>
    );
}

export default TruckCount;
