import { useState, useEffect } from 'react';

import { Box, Button, Card, CardContent, Grid, Skeleton, Stack, Tab, Typography } from '@mui/material';

import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import motive_logo from 'assets/images/motive.png';
import wex_logo from 'assets/images/wex.webp';
import ELDImportTruck from 'ui-component/modals/ELDImportTruck';
import ELDImportDriver from 'ui-component/modals/ELDImportDriver';
import InstallFuel from 'ui-component/modals/InstallFuel';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const IntegrationForm = () => {
    const [value, setValue] = useState('1');
    const [loading, setLoading] = useState(false);
    const [companySetup, setCompanySetup] = useState('');
    const [truckModal, setTruckModal] = useState(false);
    const [truckImpSt, setTruckImpSt] = useState([]);
    const [driverModal, setDriverModal] = useState(false);
    const [driverImpSt, setDriverImpSt] = useState([]);

    const [fuelModal, setFuelModal] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const openTruckModal = () => {
        setTruckModal(true);
    };

    const closeTruckModal = () => {
        setTruckModal(false);
    };

    const openDriverModal = () => {
        setDriverModal(true);
    };

    const closeDriverModal = () => {
        setDriverModal(false);
    };

    const openFuelModal = () => {
        setFuelModal(true);
    };

    const closeFuelModal = () => {
        setFuelModal(false);
        fetchCompany();
    };

    const routeChange = () => {
        let path = `https://app.gomotive.com/en-US/#/app-marketplace/app`;
        window.location.replace(path);
    };
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
        // responseType: 'arraybuffer'
    };

    useEffect(() => {
        if (!companySetup) {
            fetchCompany();
        }
    }, []);

    const fetchCompany = async () => {
        setLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);

            setCompanySetup(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const importDrivers = async () => {
        setLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/drivers-eld/add/`, config);
            setDriverImpSt(data);
            openDriverModal();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const importTrucks = async () => {
        setLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/fleet/trailer-import-eld/add`, config);

            setTruckImpSt(data);
            openTruckModal();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const syncFuel = async () => {
        setLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/sync-wex-transaction/`, config);
            // localStorage.setItem('loginUserInfo', JSON.stringify(data));
            // navigate('/dashboard', { replace: true });
            // handleCloseModal();
            dispatch(openSuccessSnackbar('Fuel Synced Successfully'));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error.response.data);
            dispatch(openErrorSnackbar(error.response.data.error));
        }
    };

    return (
        <>
            <SubCard sx={{ mt: 5, background: 'transparent', borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                <TabContext value={value}>
                    <Box sx={{ border: 'none', background: 'transparent' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab sx={{ fontSize: '16px', fontWeight: 500 }} label="ELD" value="1" />
                            <Tab sx={{ fontSize: '16px', fontWeight: 500 }} label="Fuel" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ p: { xs: '0', sm: '24px' } }}>
                        <Grid container spacing={3}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Card sx={{ maxWidth: 345 }}>
                                    <img src={motive_logo} alt="dsfdsf" loading="lazy" style={{ maxWidth: '100%', height: 'auto' }} />

                                    <CardContent sx={{ textAlign: 'center' }}>
                                        <Typography gutterBottom variant="h3" component="div">
                                            Go Motive
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                                            Integrate with motive and connect your fleet for real time visibility and proper communication.
                                        </Typography>

                                        <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="center">
                                            {loading ? (
                                                <Skeleton height="50px" />
                                            ) : (
                                                <Button
                                                    disabled={companySetup.is_eld_integrated}
                                                    onClick={routeChange}
                                                    variant="contained"
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor: '#EB812E',
                                                        width: { xs: '100%', sm: '50%' },
                                                        fontWeight: 500,
                                                        fontSize: 14
                                                    }}
                                                    color="orange"
                                                >
                                                    {companySetup.is_eld_integrated ? 'Integrated' : 'Install'}
                                                </Button>
                                            )}

                                            {loading ? (
                                                <Skeleton height="50px" />
                                            ) : (
                                                <Button
                                                    disabled={!companySetup.is_eld_integrated}
                                                    onClick={() => importDrivers()}
                                                    variant="contained"
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor: '#EB812E',
                                                        width: { xs: '100%', sm: '50%' },
                                                        fontWeight: 500,
                                                        fontSize: 14
                                                    }}
                                                    color="orange"
                                                >
                                                    Sync Drivers
                                                </Button>
                                            )}
                                            {loading ? (
                                                <Skeleton height="50px" />
                                            ) : (
                                                <Button
                                                    disabled={!companySetup.is_eld_integrated}
                                                    onClick={() => importTrucks()}
                                                    variant="contained"
                                                    sx={{
                                                        color: 'white',
                                                        backgroundColor: '#EB812E',
                                                        width: { xs: '100%', sm: '50%' },
                                                        fontWeight: 500,
                                                        fontSize: 14
                                                    }}
                                                    color="orange"
                                                >
                                                    Sync Trucks
                                                </Button>
                                            )}
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <Grid container spacing={3}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <Card sx={{ maxWidth: 345 }}>
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            <img src={wex_logo} alt="dsfdsf" loading="lazy" width="200px" height="200px" />
                                        </Stack>

                                        <CardContent sx={{ textAlign: 'center' }}>
                                            <Typography gutterBottom variant="h3" component="div">
                                                Wex Fuel Card
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                                                Add your Wex Fuel card here!.
                                            </Typography>

                                            <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="center">
                                                <Button
                                                    disabled={companySetup.is_fuel_integrated}
                                                    onClick={openFuelModal}
                                                    variant="contained"
                                                    sx={{ color: 'white', backgroundColor: '#EB812E', width: '50%', fontWeight: 500, fontSize: 14 }}
                                                    color="orange"
                                                >
                                                    {companySetup.is_fuel_integrated ? 'Integrated' : 'Install'}
                                                </Button>
                                                {/* <Button
                                                    disabled={!companySetup.is_fuel_integrated}
                                                    onClick={() => syncFuel()}
                                                    variant="contained"
                                                    sx={{ color: 'white', backgroundColor: '#EB812E', width: '50%', fontWeight: 500, fontSize: 14 }}
                                                    color="orange"
                                                >
                                                    Sync Fuel Data
                                                </Button> */}
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </SubCard>

            <ELDImportTruck open={truckModal} handleCloseModal={closeTruckModal} data={truckImpSt} />
            <ELDImportDriver open={driverModal} handleCloseModal={closeDriverModal} data={driverImpSt} />
            <InstallFuel open={fuelModal} handleCloseModal={closeFuelModal} />
        </>
    );
};

export default IntegrationForm;
