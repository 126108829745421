import { Dialog, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import { forwardRef } from 'react';
import SettlementPreViewForm from 'ui-component/accounting/forms/SettlementPreViewForm';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function PreViewSettlememntModal({ fullWidth, fullScreen, maxWidth, open, handleCloseModal, stlmData, id }) {
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogTitle>
                <Typography variant="h4" color="primary">
                    Driver Settlement Details
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <SettlementPreViewForm settlementData={stlmData} handleCloseModal={handleCloseModal} id={id} />
            </DialogContent>
        </Dialog>
    );
}

PreViewSettlememntModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'md'
};

export default PreViewSettlememntModal;
