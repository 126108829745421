// project imports
import { useEffect } from 'react';

import MainCard from 'ui-component/cards/MainCard';

import { useDispatch, useSelector } from 'react-redux';
import { getCompanySetup, selectCompanySetup } from 'store/slice/companySlice';
import PageLayout from 'layout/PageLayout';
import AllMechanicForm from 'ui-component/mechanic/forms/AllMechanicForm';

// ==============================|| SAMPLE PAGE ||============================== //

const AllMechanics = () => {
    const dispatch = useDispatch();
    const { companySetup } = useSelector(selectCompanySetup);

    useEffect(() => {
        if (!companySetup) {
            dispatch(getCompanySetup());
        }
    }, [companySetup, dispatch]);

    return (
        <>
            <MainCard>
                <PageLayout title="Mechanic">
                    <AllMechanicForm companySetup={companySetup} />
                </PageLayout>
            </MainCard>
        </>
    );
};

export default AllMechanics;
