import { Grid, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SafetyPercentage from './SafetyPercentage';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';

function DriverGroup({ send, isReady }) {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const dashboardState = useSelector((state) => state.dashboard);
    const [isRequest, setIsRequest] = useState(true);

    const safetyCompliance = dashboardState.safetyCompliance;

    const [driver, setDriver] = useState('');

    const fetchDrivers = async () => {
        const response = await axios.get('v1/api/dashboard/driver-list/', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${loginInfoFromStorage.token}`
            }
        });

        return response.data;
    };

    const {
        data: drivers,
        isLoading,
        isFetching
    } = useQuery({
        queryKey: ['driver-list'],
        queryFn: fetchDrivers,
        staleTime: 60 * 60 * 5 * 1000
    });

    const truck = useMemo(() => {
        return Array.isArray(drivers) ? drivers.find((drvr) => drvr.driver_id === driver)?.truck_no : undefined;
    }, [drivers, driver]);

    useEffect(() => {
        if (isRequest && isReady && driver) {
            send({ event_type: 'safety_compliance', driver_id: driver });
            setIsRequest(false);
        }
    }, [isReady, driver]);

    return (
        <Box>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '25px' }}>
                {isLoading || isFetching ? (
                    <Skeleton height="70px" width={500} />
                ) : (
                    <>
                        <TextField
                            select
                            label="Driver Name"
                            value={driver}
                            onChange={(e) => {
                                setIsRequest(true);
                                setDriver(e.target.value);
                            }}
                            sx={{ width: '250px' }}
                            fullWidth
                            className="text-input"
                            color="orange"
                        >
                            <MenuItem value="">Select Driver</MenuItem>
                            {Array.isArray(drivers) &&
                                drivers.map((drvr) => (
                                    <MenuItem key={drvr.driver_id} value={drvr.driver_id}>
                                        {`${drvr.first_name} ${drvr.last_name}`}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: '500',
                                fontSize: { xs: '11px', sm: '24px' },
                                fontFamily: 'Lexend',
                                background: 'orange',
                                padding: { xs: '15px 16px', sm: '5px 20px' },
                                borderRadius: '5px'
                            }}
                            color="white"
                        >
                            Truck # : {truck && truck}
                        </Typography>
                    </>
                )}
            </Box>
            <Grid container spacing={2.5} sx={{ my: 1 }}>
                <Grid item xs={12} md={4} lg={4}>
                    <SafetyPercentage title={'Driver Compliance'} parcentage={safetyCompliance?.driver_compliance || 0} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <SafetyPercentage title={'Vehicle Safety'} parcentage={safetyCompliance?.vehicles_safety || 0} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <SafetyPercentage title={'Company Safety'} parcentage={safetyCompliance?.company_safety || 0} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default DriverGroup;
