import { SvgIcon } from '@mui/material';
import React from 'react';

function SafetyIcon() {
    return (
        <SvgIcon>
            <svg width="28" height="28" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.921 8.66406C10.9792 8.66406 8.58594 11.0573 8.58594 13.9989C8.58594 16.9407 10.9792 19.3339 13.921 19.3339C16.8625 19.3339 19.2558 16.9407 19.2558 13.9989C19.2558 11.0573 16.8625 8.66406 13.921 8.66406ZM17.0883 12.9942L13.2664 16.8161C13.106 16.9766 12.8956 17.0569 12.6854 17.0569C12.4752 17.0569 12.265 16.9768 12.1045 16.8161L10.644 15.3556C10.3231 15.0349 10.3231 14.5148 10.644 14.1939C10.9646 13.873 11.4848 13.873 11.8057 14.1939L12.6854 15.0736L15.9267 11.8325C16.2473 11.5117 16.7675 11.5117 17.0883 11.8325C17.4092 12.1532 17.4092 12.6733 17.0883 12.9942Z"
                    fill="#142953"
                />
                <path
                    d="M25.8157 7.59711L25.8148 7.57532C25.8027 7.30637 25.7945 7.02182 25.7896 6.70524C25.7668 5.16139 24.5395 3.88435 22.9954 3.79805C19.7762 3.61839 17.2857 2.56865 15.1579 0.494589L15.1397 0.477286C14.4456 -0.159095 13.3987 -0.159095 12.7044 0.477286L12.6862 0.494589C10.5583 2.56865 8.06794 3.61839 4.84865 3.79826C3.3048 3.88435 2.07732 5.16139 2.05446 6.70545C2.04976 7.0199 2.04143 7.30445 2.02926 7.57532L2.02797 7.62595C1.96538 10.9087 1.88762 14.9942 3.25438 18.7025C4.0059 20.7417 5.14409 22.5144 6.63709 23.9715C8.33753 25.6309 10.5648 26.9483 13.2568 27.887C13.3444 27.9175 13.4354 27.9423 13.5281 27.9609C13.6589 27.9869 13.7905 28 13.922 28C14.0536 28 14.1854 27.9869 14.316 27.9609C14.4087 27.9423 14.5003 27.9173 14.5883 27.8865C17.2772 26.9462 19.5021 25.6281 21.201 23.9689C22.6934 22.5114 23.8316 20.7383 24.5837 18.6986C25.9556 14.9793 25.8781 10.886 25.8157 7.59711ZM13.9216 20.9784C10.0739 20.9784 6.94364 17.8482 6.94364 14.0004C6.94364 10.1527 10.0739 7.02246 13.9216 7.02246C17.7692 7.02246 20.8996 10.1527 20.8996 14.0004C20.8996 17.8482 17.7692 20.9784 13.9216 20.9784Z"
                    fill="#142953"
                />
            </svg>
        </SvgIcon>
    );
}

export default SafetyIcon;
