import { SvgIcon } from '@mui/material';
import React from 'react';

function SetupIcon() {
    return (
        <SvgIcon>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_8003_25363)">
                    <path
                        d="M12.6093 15.7585C13.1749 15.3809 13.6872 14.9205 14.1311 14.3926L15.7315 15.3799L17.1887 12.6836L15.5915 11.6984C15.7946 11.0361 15.9141 10.3336 15.9365 9.60536L17.7535 9.2631L17.2472 6.19696L15.4305 6.53922C15.1794 5.87448 14.8426 5.25637 14.4341 4.70089L15.6217 3.18917L13.3891 1.18802L12.2025 2.69845C11.623 2.36669 10.9953 2.11869 10.3339 1.96987V0H7.41947V1.96987C6.75797 2.11869 6.13036 2.36669 5.55075 2.69845L4.36425 1.18802L2.13162 3.18924L3.31906 4.70096C2.91064 5.25644 2.5738 5.87463 2.32273 6.5393L0.506017 6.19703L0 9.26317L1.81678 9.60543C1.83918 10.3337 1.95869 11.0361 2.16177 11.6986L0.56444 12.6837L2.02173 15.38L3.6222 14.393C4.06599 14.9206 4.57829 15.3812 5.144 15.7587L4.51224 17.6127L7.25102 18.6774L7.8819 16.8259C8.20704 16.875 8.53906 16.9011 8.87669 16.9011C9.21433 16.9011 9.54641 16.8752 9.87155 16.8259L10.5024 18.6775L13.241 17.6125L12.6093 15.7585ZM8.87656 13.3305C6.82428 13.3305 5.15462 11.547 5.15462 9.3545C5.15462 7.16219 6.82428 5.37848 8.87656 5.37848C10.9288 5.37848 12.5985 7.16219 12.5985 9.3545C12.5985 11.547 10.9289 13.3305 8.87656 13.3305Z"
                        fill="#142953"
                    />
                    <path
                        d="M25.1362 18.0978L23.8799 18.5319C23.6403 18.0871 23.3442 17.6844 23.0032 17.3338L23.7053 16.1367L21.9375 14.9534L21.2357 16.1495C20.7943 15.9754 20.3265 15.8658 19.8436 15.8303L19.6615 14.4336L17.5952 14.7413L17.7773 16.138C17.3222 16.3131 16.9 16.5555 16.5198 16.8518L15.5391 15.906L14.1412 17.5604L15.1228 18.5072C14.8847 18.9441 14.7032 19.4178 14.5867 19.9158L13.2669 19.8648L13.1914 22.0919L14.5111 22.1431C14.5944 22.6571 14.7442 23.1421 14.9495 23.5906L13.9082 24.4577L15.1906 26.2154L16.234 25.3468C16.5975 25.674 17.0034 25.9463 17.4393 26.1545L17.1627 27.5358L19.2029 28.0014L19.4792 26.6222C19.7142 26.6229 19.9519 26.6059 20.1912 26.5703C20.4307 26.5347 20.6638 26.481 20.8896 26.4121L21.5083 27.6583L23.3515 26.6141L22.7321 25.3665C23.0984 25.039 23.4186 24.6583 23.6847 24.2374L24.9106 24.7684L25.6944 22.703L24.471 22.173C24.5535 21.682 24.5732 21.1713 24.522 20.6525L25.7787 20.218L25.1362 18.0978ZM19.8613 24.0389C18.4061 24.2553 17.0573 23.1671 16.8547 21.6126C16.6516 20.0581 17.6706 18.6171 19.1258 18.4008C20.5809 18.184 21.9294 19.2724 22.1325 20.8271C22.335 22.3814 21.3164 23.822 19.8613 24.0389Z"
                        fill="#142953"
                    />
                    <path
                        d="M27.9512 10.3754L28.0002 8.61671L26.9583 8.58365C26.893 8.19604 26.7763 7.81222 26.605 7.44318L27.4232 6.75266L26.4024 5.37234L25.5844 6.06257C25.2926 5.80358 24.9699 5.59242 24.6273 5.43304L24.8388 4.34113L23.2263 3.98438L23.0144 5.07557C22.6428 5.07614 22.2687 5.13248 21.902 5.2478L21.408 4.26758L19.9578 5.10192L20.4518 6.08228C20.1602 6.34549 19.9099 6.64884 19.7048 6.9796L18.7358 6.56757L18.1267 8.20233L19.0965 8.615C19.0327 9.00831 19.0203 9.41177 19.0622 9.81459L18.0723 10.1643L18.5891 11.8349L19.579 11.4853C19.7729 11.8414 20.0086 12.1563 20.2759 12.428L19.7284 13.3754L21.1297 14.2999L21.6784 13.3507C22.0317 13.4861 22.4022 13.5686 22.7782 13.5945L22.9288 14.698L24.5586 14.4437L24.4085 13.3416C24.5824 13.2733 24.7541 13.191 24.9221 13.0943C25.0903 12.9978 25.2491 12.8896 25.3983 12.7723L26.1769 13.5128L27.2723 12.1991L26.4931 11.4576C26.68 11.1077 26.819 10.7318 26.9078 10.3423L27.9512 10.3754ZM24.0268 11.3178C23.0053 11.9053 21.727 11.4958 21.1774 10.405C20.6272 9.31408 21.0106 7.94826 22.0321 7.36106C23.053 6.77344 24.3312 7.18326 24.8814 8.27416C25.431 9.36514 25.0478 10.7303 24.0268 11.3178Z"
                        fill="#142953"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_8003_25363">
                        <rect width="28" height="28" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}

export default SetupIcon;
