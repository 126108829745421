import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Slide,
    TextField,
    Typography
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MaterialTable from '@material-table/core';
import { createRef, useEffect, useState } from 'react';
import { forwardRef } from 'react';
import TABLE_ICONS from 'tableIcons';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import Status from 'ui-component/buttons/Status';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { PatternFormat } from 'react-number-format';
import axios from 'axios';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function LdLoadData({ data, onSave }) {
    const tableRef = createRef();
    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
        // responseType: 'arraybuffer'
    };

    const [ldData, setLdData] = useState(data);

    const [showForm, setShowForm] = useState(false);
    const [ldRow, setLdRow] = useState(null);

    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [ssnShow, setSsnShow] = useState(true);

    useEffect(() => {
        if (!stateList) {
            fetchState();
        }
    }, [stateList]);

    const handleDelete = (rowData) => {
        setLdData(ldData.filter((item) => item.id !== rowData.id));
    };

    const fetchState = async () => {
        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
        } catch (error) {
            console.log(error.response.data);
        }
    };
    const changeStates = (value) => {
        formik.setFieldValue('state', value);

        //  setFieldValue('companyCity', '');
        getCityList(value);
    };

    const getCityList = async (id) => {
        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);
            setCityList(data);
        } catch (error) {
            console.log(error.response.data);
        }
    };

    const LoadSchema = Yup.object().shape({
        bol: Yup.string().required('Load No is required'),
        customer: Yup.string().required('Customer is required'),
        truck: Yup.string().required('Truck No is required'),
        driver_email: Yup.string().required('Driver is required'),
        total: Yup.string().required('Revenue is required'),
        pickup: Yup.string().required('PickUp is required'),
        pickup_address: Yup.string().required('PickUp Address is required'),
        pickup_zip: Yup.string().required('PickUp  ZIP is required'),
        from: Yup.string().required('From is required'),
        delivery: Yup.string().required('Delivery is required'),
        delivery_address: Yup.string().required('Delivery Address is required'),
        delivery_zip: Yup.string().required('Delivery  ZIP is required'),
        to: Yup.string().required('To is required')
    });

    const formik = useFormik({
        initialValues: ldRow ? ldRow : {},
        validationSchema: LoadSchema,
        onSubmit: (values) => {
            try {
                setLdData(ldData.map((item) => (item.id === values.id ? values : item)));
                setShowForm(false);
                setLdRow(null);
            } catch (error) {
                console.log(error);
            }
        }
    });

    const handleEdit = (rowData) => {
        try {
            console.log(rowData);
            setShowForm(true);
            formik.setValues(rowData);
        } catch (error) {
            console.log(error);
        }
    };

    // console.log(formik.values, ldRow);
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor ">
                        {' '}
                        <span style={{ color: 'red' }}>*</span>Load ID
                    </th>
                    <th className="headerTd rightHidBor">
                        {' '}
                        <span style={{ color: 'red' }}>*</span>Pickup Date
                    </th>
                    <th className="headerTd rightHidBor">
                        {' '}
                        <span style={{ color: 'red' }}>*</span>From
                    </th>
                    <th className="headerTd rightHidBor">
                        {' '}
                        <span style={{ color: 'red' }}>*</span>Delivery Date
                    </th>
                    <th className="headerTd rightHidBor">
                        {' '}
                        <span style={{ color: 'red' }}>*</span>To
                    </th>
                    <th className="headerTd rightHidBor">
                        {' '}
                        <span style={{ color: 'red' }}>*</span>Customer
                    </th>
                    <th className="headerTd rightHidBor">
                        {' '}
                        <span style={{ color: 'red' }}>*</span>Truck
                    </th>
                    <th className="headerTd rightHidBor">
                        {' '}
                        <span style={{ color: 'red' }}>*</span>Driver Email
                    </th>
                    <th className="headerTd rightHidBor">
                        {' '}
                        <span style={{ color: 'red' }}>*</span>Revenue
                    </th>
                    <th className="headerTd rightHidBor">Import Status</th>
                    <th className="headerTd rightHidBor">Remark</th>
                    <th className="headerTd">Action</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.bol}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.pickup !== '' ? format(new Date(rowData.data.pickup), 'yyyy-MM-dd') : ''}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.pickup_address} {rowData.data.from} {rowData.data.pickup_zip}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.delivery !== '' ? format(new Date(rowData.data.delivery), 'yyyy-MM-dd') : ''}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.delivery_address} {rowData.data.to} {rowData.data.delivery_zip}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.customer}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.truck}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_email}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.total}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Status text={rowData.data.upload_status} />
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.remark}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => handleEdit(rowData.data)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(rowData.data)}>
                        <DeleteIcon />
                    </IconButton>
                </td>
                {/* Custom cell or additional cells */}
            </tr>
        );
    };

    const formatDate = (dt) => {
        if (dt instanceof Date) {
            return format(dt, 'yyyy-MM-dd');
        } else if (dt !== '') {
            return dt;
        } else {
            return '';
        }
    };

    const formatTime = (tm) => {
        if (tm instanceof Date) {
            return format(tm, 'HH:mm');
        } else if (tm !== '') {
            return tm;
        } else {
            return '';
        }
    };

    const LetterOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const AlphanumericOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^A-Za-z0-9 ]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.'
    };
    return (
        <>
            {showForm && (
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Grid container sx={{ my: 3 }} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Load Data</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Load ID"
                                    {...formik.getFieldProps('bol')}
                                    error={Boolean(formik.touched.bol && formik.errors.bol)}
                                    helperText={formik.touched.bol && formik.errors.bol}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Revenue"
                                    {...formik.getFieldProps('total')}
                                    error={Boolean(formik.touched.total && formik.errors.total)}
                                    helperText={formik.touched.total && formik.errors.total}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Customer"
                                    {...formik.getFieldProps('customer')}
                                    error={Boolean(formik.touched.customer && formik.errors.customer)}
                                    helperText={formik.touched.customer && formik.errors.customer}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Truck Unit"
                                    {...formik.getFieldProps('truck')}
                                    error={Boolean(formik.touched.truck && formik.errors.truck)}
                                    helperText={formik.touched.truck && formik.errors.truck}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Driver Email"
                                    {...formik.getFieldProps('driver_email')}
                                    error={Boolean(formik.touched.driver_email && formik.errors.driver_email)}
                                    helperText={formik.touched.driver_email && formik.errors.driver_email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4">Pickup</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Pickup Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={formik.values.pickup}
                                        onChange={(newValue) => {
                                            formik.setFieldValue(`pickup`, newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                color="orange"
                                                fullWidth
                                                {...params}
                                                error={Boolean(formik.touched.pickup && formik.errors.pickup)}
                                                helperText={formik.touched.pickup && formik.errors.pickup}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="Pickup Address"
                                    {...formik.getFieldProps('pickup_address')}
                                    error={Boolean(formik.touched.pickup_address && formik.errors.pickup_address)}
                                    helperText={formik.touched.pickup_address && formik.errors.pickup_address}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Pickup Zip"
                                    {...formik.getFieldProps('pickup_zip')}
                                    error={Boolean(formik.touched.pickup_zip && formik.errors.pickup_zip)}
                                    helperText={formik.touched.pickup_zip && formik.errors.pickup_zip}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="From"
                                    placeholder="DALLAS, TX"
                                    {...formik.getFieldProps('from')}
                                    error={Boolean(formik.touched.from && formik.errors.from)}
                                    helperText={formik.touched.from && formik.errors.from}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h4">Delivery</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Delivery Date"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={formik.values.delivery}
                                        onChange={(newValue) => {
                                            formik.setFieldValue(`delivery`, newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                color="orange"
                                                fullWidth
                                                {...params}
                                                error={Boolean(formik.touched.delivery && formik.errors.delivery)}
                                                helperText={formik.touched.delivery && formik.errors.delivery}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="Delivery Address"
                                    {...formik.getFieldProps('delivery_address')}
                                    error={Boolean(formik.touched.delivery_address && formik.errors.delivery_address)}
                                    helperText={formik.touched.delivery_address && formik.errors.delivery_address}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="Delivery Zip"
                                    {...formik.getFieldProps('delivery_zip')}
                                    error={Boolean(formik.touched.delivery_zip && formik.errors.delivery_zip)}
                                    helperText={formik.touched.delivery_zip && formik.errors.delivery_zip}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    type="text"
                                    label="To"
                                    placeholder="DALLAS, TX"
                                    {...formik.getFieldProps('to')}
                                    error={Boolean(formik.touched.to && formik.errors.to)}
                                    helperText={formik.touched.to && formik.errors.to}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        color: 'white',
                                        fontFamily: 'Lexend',
                                        fontWeight: 500,
                                        lineHeight: '1.3rem',
                                        fontSize: '16px',
                                        fontStyle: 'normal'
                                    }}
                                    color="orange"
                                    size="large"
                                    type="submit"
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            )}
            <Typography variant="h5">
                <span style={{ color: 'red' }}>*</span> marks mandatory fields
            </Typography>
            <MaterialTable
                tableRef={tableRef}
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                data={ldData}
                title="All Truck"
                columns={[]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    sorting: true,
                    exportButton: false,
                    filtering: false,
                    toolbar: false,
                    draggable: false,
                    columnResizable: true,
                    paging: false
                }}
            />
            <Button
                variant="contained"
                sx={{
                    color: 'white',
                    fontFamily: 'Lexend',
                    fontWeight: 500,
                    lineHeight: '1.3rem',
                    fontSize: '16px',
                    fontStyle: 'normal'
                }}
                color="orange"
                size="large"
                type="button"
                onClick={() => onSave(ldData)}
            >
                Save Data
            </Button>
        </>
    );
}

export default LdLoadData;
