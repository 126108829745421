import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    // boxShadow: 24,
    p: 4
};
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Modal,
    Skeleton,
    Stack,
    TablePagination,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createRef, useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import user_types from 'constant/user_types';

const initialValues = {
    quarter: '',
    year: ''
};

const Schema = Yup.object().shape({
    quarter: Yup.string().required('Quarter is required'),
    year: Yup.string().required('Year is required')
});

const AllIFTAForm = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const tableRef = createRef();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions;

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    const [generateModal, setGenerateModal] = useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: async (values, action) => {
            setGenerateModal(false);
            toast.success('Generating Quarter Report. Please wait.', {
                theme: 'light'
            });
            try {
                const response = await axios.get(`/v1/api/accounting/generate-ifta-quarter/${values.year}/${values.quarter}/`, config);
                if (response.status === 200) {
                    toast.success('Fuel tax updated successfully');
                } else {
                    toast.error(response?.data);
                }
            } catch (error) {
                console.log(error);
                toast.error("Can't updated please contact support");
            }
            action.resetForm();
            formik.setSubmitting(false);
            if (tableRef.current) {
                tableRef.current.onQueryChange();
            } else {
                window.location.reload();
            }
        }
    });

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Quarter</th>
                    <th className="headerTd rightHidBor">Year</th>

                    <th className="headerTd rightHidBor">Total fuel tax</th>
                    <th className="headerTd rightHidBor">Driver IFTA info</th>

                    <th className="headerTd ">State IFTA info</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.quarter}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.year}
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.total_fuel_tax}
                </td>
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => navigate(`/accounting/ifta/driver/${rowData.data.year}/${rowData.data.quarter}`)}>
                        <VisibilityIcon />
                    </IconButton>
                </td>

                {/* Custom cell or additional cells */}
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    <IconButton onClick={() => navigate(`/accounting/ifta/state/${rowData.data.year}/${rowData.data.quarter}`)}>
                        <VisibilityIcon />
                    </IconButton>
                </td>
            </tr>
        );
    };

    // const fetchData = async (query) => {
    //     const url = `/v1/api/accounting/ifta-quarters/?page=${query.page + 1}&page_size=${query.pageSize}`;
    //     const response = await fetch(url, config);
    //     const result = await response.json();
    //     setSelected(result.results);
    //     return {
    //         data: result.results,
    //         page: query.page,
    //         totalCount: result.count
    //     };
    // };

    const fetchData = async (query) => {
        setLoading(true); // Start loading

        try {
            const url = `/v1/api/accounting/ifta-quarters/?page=${query.page + 1}&page_size=${query.pageSize}`;
            const response = await fetch(url, config);
            const result = await response.json();

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 10 })
                .then((result) => {
                    setSelected(result.data || []);
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
        // console.log(selected);
    }, [isMobile, isTablet, currPage, cardPerPage]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    return (
        <>
            <Stack
                sx={{ mt: 5, mb: 2 }}
                direction={matchDownMd ? 'column' : 'row'}
                justifyContent="end"
                alignItems={matchDownMd ? 'flex-start' : 'center'}
                spacing={4}
            >
                <div>
                    {((permissions.dspdriverifta && permissions.dspdriverifta.add) || user_types.includes(user_type)) && (
                        <Button
                            variant="contained"
                            onClick={() => navigate('/accounting/ifta/new/add')}
                            startIcon={<AddIcon />}
                            color="orange"
                            sx={{ color: 'white', mr: 3 }}
                        >
                            Add Ifta
                        </Button>
                    )}
                    <LoadingButton
                        loading={formik.isSubmitting}
                        variant="contained"
                        color="orange"
                        sx={{ mr: 2, color: 'white' }}
                        onClick={() => setGenerateModal(true)}
                    >
                        Generate
                    </LoadingButton>
                </div>
            </Stack>
            {formik.isSubmitting && <LinearProgress style={{ width: '100%', backgroundColor: 'orange' }} />}
            {isMobile ? (
                <>
                    {loading ? (
                        <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ clear: 'both' }} />

                                    <Typography sx={{ marginBottom: '1rem' }}>Quarter : {driver.quarter}</Typography>
                                    <Typography sx={{ marginBottom: '1rem' }}>Year : {driver.year}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Total fuel tax : {driver.total_fuel_tax}</Typography>
                                    <Typography>
                                        Driver IFTA info :{' '}
                                        <IconButton onClick={() => navigate(`/accounting/ifta/driver/${driver.year}/${driver.quarter}`)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Typography>
                                    <Typography>
                                        State IFTA info :{' '}
                                        <IconButton onClick={() => navigate(`/accounting/ifta/state/${driver.year}/${driver.quarter}`)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected?.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginTop: '2rem',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Quarter : {driver.quarter} </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Year : {driver.year}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            Driver IFTA info :{' '}
                                            <IconButton onClick={() => navigate(`/accounting/ifta/driver/${driver.year}/${driver.quarter}`)}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            State IFTA info :{' '}
                                            <IconButton onClick={() => navigate(`/accounting/ifta/state/${driver.year}/${driver.quarter}`)}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '.6rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Total fuel tax : {driver.total_fuel_tax}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[20, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                    // data={(query) =>
                    //     new Promise((resolve, reject) => {
                    //         const url = `/v1/api/accounting/ifta-quarters/?page=${query.page + 1}&page_size=${query.pageSize}`;

                    //         fetch(url, config)
                    //             .then((response) => response.json())
                    //             .then((result) => {
                    //                 resolve({
                    //                     data: result.results,
                    //                     page: query.page,
                    //                     totalCount: result.count
                    //                 });
                    //             });
                    //     })
                    // }
                    data={(query) => fetchData(query)}
                    title=""
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,

                        toolbar: false,
                        draggable: false,
                        columnResizable: true
                    }}
                />
            )}
            <Modal open={generateModal} onClose={() => setGenerateModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <TextField
                                        fullWidth
                                        select
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Year"
                                        value={formik.values.year}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name={'year'}
                                        error={formik.touched.year && formik.errors.year}
                                        helperText={formik.touched.year && formik.errors.year}
                                    >
                                        {Array.from({ length: new Date().getFullYear() + 1 - 2024 }, (_, index) => (
                                            <MenuItem key={index} value={(2024 + index).toString()}>
                                                {(2024 + index).toString()}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {loading ? (
                                    <Skeleton height="50px" />
                                ) : (
                                    <>
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            color="orange"
                                            select
                                            type="text"
                                            label="Quarter"
                                            value={formik.values.quarter}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            name={'quarter'}
                                            error={formik.touched.quarter && formik.errors.quarter}
                                            helperText={formik.touched.quarter && formik.errors.quarter}
                                        >
                                            <MenuItem value={'Q1'}>Q1</MenuItem>
                                            <MenuItem value={'Q2'}>Q2</MenuItem>
                                            <MenuItem value={'Q3'}>Q3</MenuItem>
                                            <MenuItem value={'Q4'}>Q4</MenuItem>
                                        </TextField>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Button type="submit" disabled={formik.isSubmitting} variant="contained" fullWidth color="orange" sx={{ color: 'white', mt: 2 }}>
                            Generate
                        </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default AllIFTAForm;
