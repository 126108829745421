// project imports

import { Tab, Tabs, } from '@mui/material';
import { Box } from '@mui/system';

import { useState } from 'react';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import EditloadForm from 'ui-component/dispatchCenter/forms/EditloadForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLayout from 'layout/PageLayout';
import user_types from 'constant/user_types';
// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontWeight: 400,
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '4px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '16px',
                            fontWeight: 400,
                            marginRight: '5px',
                            color: 'white',
                            textTransform: 'capitalize'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});
const EditMyLoad = () => {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const load_view = permissions.dspcompanyload && permissions.dspcompanyload.view ? true : false;
    const customer_view = permissions.dspcompanycustomer && permissions.dspcompanycustomer.view ? true : false;
    const navigate = useNavigate();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainCard>
          <PageLayout title="Dispatch Center">
            <Box sx={{ border: 'none', backgroundColor: 'transparent' }}>
                <ThemeProvider theme={theme}>
                    <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                            {(load_view || user_types.includes(user_type)) && <Tab label="My Loads" onClick={() => navigate(`/dispatch-center`)} />}
                            {/* <Tab label="Load Board" onClick={() => navigate(`/dispatch-center`)} /> */}
                            {(customer_view || user_types.includes(user_type)) && <Tab label="Customer" onClick={() => navigate(`/dispatch-center/customer/all`)} />}
                    </Tabs>
                </ThemeProvider>
            </Box>

            <EditloadForm />
            </PageLayout>
        </MainCard>
    );
};

export default EditMyLoad;
