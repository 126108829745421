import { Divider, List, ListItemText, Stack, useTheme, Box, ListItem, TextField, Button, Popover, Switch, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PropTypes from 'prop-types';

function AllInvoiceTrackingFilter({
    filterHandler,
    checked,
    handleChecked,
    startDate,
    startDateHandler,
    endDate,
    endDateHandler,
   
    statusChecked,
    handleStatus,
   
    reset
}) {
    const theme = useTheme();
    return (
        <PopupState variant="popover" popupId="filter-popup">
            {(popupState) => (
                <>
                    <Button
                        variant="contained"
                        sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                        color="orange"
                        startIcon={<FilterAltIcon />}
                        {...bindTrigger(popupState)}
                    >
                        FILTER
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                            {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                            <Box sx={{ px: 2 }}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        maxWidth: 350,
                                        minWidth: 300,
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            minWidth: '100%'
                                        },
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5
                                        }
                                    }}
                                >
                                    <Divider variant="middle" component="li" />
                                 
                                    <ListItem>
                                      
                                        <ListItemText id="switch-list-label-bluetooth" primary="Payment Status" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('status')}
                                            checked={checked.indexOf('status') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-bluetooth'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('status') !== -1 ? (
                                        <ListItem>
                                            <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="flex-start">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('pending')}
                                                                checked={statusChecked.indexOf('pending') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="Pending"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('submitted')}
                                                                checked={statusChecked.indexOf('submitted') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="submitted"
                                                    />
                                                     <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('approved')}
                                                                checked={statusChecked.indexOf('approved') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="approved"
                                                    />
                                                      <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('reject')}
                                                                checked={statusChecked.indexOf('reject') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="Reject"
                                                    />
                                                </FormGroup>
                                            </Stack>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        <ListItemText primary="Payment Date" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('dop')}
                                            checked={checked.indexOf('dop') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('dop') !== -1 ? (
                                        <>
                                            <ListItem>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            className="text-input"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            label="Start Date"
                                                            value={startDate}
                                                            onChange={startDateHandler}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    className="text-input"
                                                                    color="orange"
                                                                    fullWidth
                                                                    {...params}
                                                                    error={null}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDateTimePicker
                                                            className="text-input"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            label="End Date"
                                                            value={endDate}
                                                            onChange={endDateHandler}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    className="text-input"
                                                                    color="orange"
                                                                    fullWidth
                                                                    {...params}
                                                                    error={null}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Stack>
                                            </ListItem>
                                        </>
                                    ) : (
                                        ''
                                    )}

                                   

                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={20}>
                                            <Button variant="outlined" onClick={reset}>
                                                Reset
                                            </Button>
                                            <Button
                                                sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                                variant="contained"
                                                color="orange"
                                                onClick={() => {
                                                    filterHandler();
                                                    popupState.close();
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </Stack>

                                        <Divider sx={{ mt: 2 }} />
                                    </ListItem>
                                </List>
                            </Box>
                        </MainCard>
                    </Popover>
                </>
            )}
        </PopupState>
    );
}

AllInvoiceTrackingFilter.propTypes = {
    filterHandler: PropTypes.func,
    handleChecked: PropTypes.func,
    startDateHandler: PropTypes.func,
    endDateHandler: PropTypes.func,
    checked: PropTypes.any,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    reset: PropTypes.func
};

export default AllInvoiceTrackingFilter;
