import { SvgIcon } from '@mui/material';
import React from 'react';

function ReportIcon() {
    return (
        <SvgIcon>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 0V7H24.5L17.5 0Z" fill="#142953" />
                <path
                    d="M17.5 8.75C16.5358 8.75 15.75 7.96425 15.75 7V0H5.25C4.28575 0 3.5 0.78575 3.5 1.75V26.25C3.5 27.216 4.28575 28 5.25 28H22.75C23.716 28 24.5 27.216 24.5 26.25V8.75H17.5ZM10.5 24.5H7V19.25H10.5V24.5ZM15.75 24.5H12.25V15.75H15.75V24.5ZM21 24.5H17.5V12.25H21V24.5Z"
                    fill="#142953"
                />
                <path d="M17.5 0V7H24.5L17.5 0Z" fill="#142953" />
                <path
                    d="M17.5 8.75C16.5358 8.75 15.75 7.96425 15.75 7V0H5.25C4.28575 0 3.5 0.78575 3.5 1.75V26.25C3.5 27.216 4.28575 28 5.25 28H22.75C23.716 28 24.5 27.216 24.5 26.25V8.75H17.5ZM10.5 24.5H7V19.25H10.5V24.5ZM15.75 24.5H12.25V15.75H15.75V24.5ZM21 24.5H17.5V12.25H21V24.5Z"
                    fill="#142953"
                />
            </svg>
        </SvgIcon>
    );
}

export default ReportIcon;
