import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// material-ui
import { Grid, Link, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';

import DocModal from 'ui-component/modals/DocModal';

import { Worker, Viewer } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import ViewTextFields from 'ui-component/ViewTextFields';
import { Box, height, Stack } from '@mui/system';
import { PatternFormat } from 'react-number-format';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />
        }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 60,
        width: '100%'
        // backgroundColor: '#0A95FF'
    },
    '& .Mui-selected': {
        color: '#0A95FF',
        background: '#98c1e094',
        borderRadius: '2rem'
    },
    '& .MuiButtonBase-root': {
        minHeight: '50px'
    }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16,
    marginRight: theme.spacing(1),
    color: '#697586',
    '&.Mui-selected': {
        color: '#0A95FF'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
}));

const DriverForm = () => {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [driverDetails, setDriverDetails] = useState();
    const [loading, setLoading] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);

    const [fileName, setFileName] = useState('');
    const { id } = useParams();

    const handleClickOpen = (file) => {
        setFileName(file);
        setModalOpen(true);
    };

    const handleClose = () => {
        // setFileName();
        setModalOpen(false);
    };

    function handleDownloadModal() {
        setModalOpen(false);
        window.open(fileName, '_blank');
        setFileName('');
    }

    function handleDownloadClick(file) {
        if (file) {
            window.open(file, '_blank');
        }
    }

    useEffect(() => {
        if (id && !driverDetails) {
            fetchDriver(id);
        }
        console.log(driverDetails);
    }, [driverDetails, id]);

    const fetchDriver = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/users/company/driver/${id}/`, config);
            setDriverDetails(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                <Grid container spacing={1}>
                    {/* Customer */}
                    {/* General Info */}

                    <Box
                        sx={{
                            width: '100%',
                            typography: 'body1',
                            border: 'none',
                            backgroundColor: 'transparent'
                        }}
                    >
                        <div style={{ padding: 15 }}>
                            <Typography variant="h2" sx={{ mt: 3, fontWeight: 600 }} color="#0A95FF">
                                {id && driverDetails ? driverDetails.user.first_name : ''} {id && driverDetails ? driverDetails.user.last_name : ''}
                            </Typography>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
                                    CDL No.
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 3,
                                        fontWeight: 500,
                                        marginLeft: 4
                                    }}
                                >
                                    {id && driverDetails ? driverDetails.cdl_no : ''}
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
                                    Hire Date
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 3,
                                        fontWeight: 500,
                                        marginLeft: 4
                                    }}
                                >
                                    {id && driverDetails ? driverDetails.hire_date : ''}
                                </Typography>
                            </Stack>
                            {driverDetails && driverDetails.end_date && (
                                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                    <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
                                        Date Of Termination
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            mt: 3,
                                            fontWeight: 500,
                                            marginLeft: 4
                                        }}
                                    >
                                        {id && driverDetails ? driverDetails.end_date : ''}
                                    </Typography>
                                </Stack>
                            )}

                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
                                    Status
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 3,
                                        fontWeight: 500,
                                        marginLeft: 4
                                    }}
                                    color={driverDetails && driverDetails.is_active ? '#59bd79' : '#d3505f'}
                                >
                                    {id && driverDetails && driverDetails.is_active ? 'Active' : 'Inactive'}
                                </Typography>
                            </Stack>
                        </div>
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="styled tabs example"
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    flexWrap: 'wrap'
                                }
                            }}
                        >
                            <StyledTab label="Contacts" icon={<PermContactCalendarIcon />} iconPosition="start" value="1" />
                            <StyledTab label="Address" icon={<HomeIcon />} iconPosition="start" value="2" />
                            <StyledTab label="Bank" icon={<AccountBalanceIcon />} iconPosition="start" value="3" />
                            <StyledTab label="Qualification" icon={<AssessmentIcon />} iconPosition="start" value="4" />
                        </StyledTabs>
                        <Box sx={{ px: '20px' }}>
                            {value == 1 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={5} xs={5}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Email Id
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `mailto:${
                                                            id && driverDetails && driverDetails.user.email ? driverDetails.user.email : ''
                                                        }`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {id && driverDetails && driverDetails.user.email ? driverDetails.user.email : 'N/A'}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={5} xs={5}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Phone Number
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `tel:${id && driverDetails && driverDetails.user.phone}`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {id && driverDetails && driverDetails.user.phone ? (
                                                        <PatternFormat value={driverDetails.user.phone} displayType={'text'} format="###-###-####" />
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 2 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Full Us Address
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {id && driverDetails ? driverDetails.address : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                State
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {id && driverDetails ? driverDetails.state_name : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                City
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {id && driverDetails ? driverDetails.city_name : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Zip
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {id && driverDetails ? driverDetails.zip_code : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {value == 3 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                SSN#
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {id && driverDetails ? driverDetails.ssn_no : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Bank Account Number
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {id && driverDetails ? driverDetails.acc_no : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {value == 4 && (
                                <Grid container spacing={3}>
                                    {driverDetails && driverDetails.safety_docs ? (
                                        driverDetails.safety_docs.map((data, index) => (
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <ViewTextFields
                                                    file={data.sft_doc !== 'null' ? data.sft_doc : ''}
                                                    fileName={data.sft_doc_name !== 'null' ? data.sft_doc_name : ''}
                                                    viewText={data.doc_name}
                                                    handleClickOpen={handleClickOpen}
                                                    handleDownloadClick={handleDownloadClick}
                                                />
                                            </Grid>
                                        ))
                                    ) : (
                                        <Skeleton height="50px" />
                                    )}

                                    {driverDetails && driverDetails.had_accident && (
                                        <>
                                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                                {loading ? (
                                                    <Skeleton height="50px" />
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            mt: 3,
                                                            fontFamily: 'Lexend'
                                                        }}
                                                    >
                                                        Date Of Accident
                                                    </Typography>
                                                )}
                                                {loading ? (
                                                    <Skeleton height="50px" />
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            mt: 3,
                                                            fontFamily: 'Lexend'
                                                        }}
                                                    >
                                                        Type of Accident
                                                    </Typography>
                                                )}
                                                {loading ? (
                                                    <Skeleton height="50px" />
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            mt: 3,
                                                            fontFamily: 'Lexend'
                                                        }}
                                                    >
                                                        Severity and lose
                                                    </Typography>
                                                )}
                                            </Grid>

                                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                                {loading ? (
                                                    <Skeleton height="50px" />
                                                ) : (
                                                    <Typography sx={{ mt: 3 }}>
                                                        <span
                                                            style={{
                                                                width: '228px',
                                                                height: '20px',
                                                                color: '#5C5C5C',
                                                                fontFamily: 'Lexend'
                                                            }}
                                                        >
                                                            {id && driverDetails && driverDetails.accd_date}
                                                        </span>
                                                    </Typography>
                                                )}

                                                {loading ? (
                                                    <Skeleton height="50px" />
                                                ) : (
                                                    <Typography sx={{ mt: 3 }}>
                                                        <span
                                                            style={{
                                                                width: '228px',
                                                                height: '20px',
                                                                color: '#5C5C5C',
                                                                fontFamily: 'Lexend'
                                                            }}
                                                        >
                                                            {id && driverDetails && driverDetails.accd_type}
                                                        </span>
                                                    </Typography>
                                                )}
                                                {loading ? (
                                                    <Skeleton height="50px" />
                                                ) : (
                                                    <Typography sx={{ mt: 3 }}>
                                                        <span
                                                            style={{
                                                                width: '228px',
                                                                height: '20px',
                                                                color: '#5C5C5C',
                                                                fontFamily: 'Lexend'
                                                            }}
                                                        >
                                                            {id && driverDetails && driverDetails.severity}
                                                        </span>
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                {loading ? (
                                                    <Skeleton height="50px" />
                                                ) : (
                                                    <ViewTextFields
                                                        sx={{ mt: 8 }}
                                                        file={driverDetails && driverDetails.spprt_doc !== 'null' ? driverDetails.spprt_doc : ''}
                                                        fileName={driverDetails && driverDetails.spprt_doc_name !== 'null' ? driverDetails.spprt_doc_name : ''}
                                                        viewText="Supporting Document"
                                                        handleClickOpen={handleClickOpen}
                                                        handleDownloadClick={handleDownloadClick}
                                                    />
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </SubCard>

            <DocModal
                open={modalOpen}
                fullWidth
                fullScreen
                maxWidth="md"
                handleClose={() => handleClose()}
                handleDownload={() => handleDownloadModal()}
                fileName={fileName}
                content={
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer fileUrl={fileName} />
                    </Worker>
                }
            />
        </>
    );
};

export default DriverForm;
