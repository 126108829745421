import {
    Divider,
    List,
    ListItemText,
    Stack,
    useTheme,
    Box,
    Switch,
    ListItem,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Popover,
    MenuItem,
    useMediaQuery
} from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PropTypes from 'prop-types';
function TrailerFilter({
    checked,
    handleChecked,
    trailerType,
    trailerTypeHandler,
    filterHandler,
    handleStatus,
    statusChecked,
    reset,
    yearFilter,
    yearHandler
}) {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <PopupState variant="popover" popupId="filter-popup">
            {(popupState) => (
                <>
                    <Button
                        variant="contained"
                        sx={{ mr: matchDownMd ? 1 : 2, color: 'white', backgroundColor: '#EB812E' }}
                        color="orange"
                        startIcon={<FilterAltIcon />}
                        // size={matchDownMd ? 'small' : 'medium'}
                        {...bindTrigger(popupState)}
                    >
                        FILTER
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                            {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                            <Box sx={{ px: 2 }}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        maxWidth: 350,
                                        minWidth: 300,
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            minWidth: '100%'
                                        },
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5
                                        }
                                    }}
                                >
                                    <ListItem>
                                        {/* <ListItemIcon><WifiIcon /></ListItemIcon> */}

                                        <ListItemText primary="Trailer Type" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('trailerType')}
                                            checked={checked.indexOf('trailerType') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('trailerType') !== -1 ? (
                                        <>
                                            <ListItem>
                                                <TextField
                                                    fullWidth
                                                    className="text-input"
                                                    color="orange"
                                                    select
                                                    type="text"
                                                    label="Trailer Type"
                                                    value={trailerType}
                                                    onChange={trailerTypeHandler}
                                                >
                                                    <MenuItem value="">Select Tailer Type</MenuItem>
                                                    <MenuItem value="Boat Trailer">Boat Trailer</MenuItem>
                                                    <MenuItem value="Car Carrier">Car Carrier</MenuItem>
                                                    <MenuItem value="Caravan">Caravan</MenuItem>
                                                    <MenuItem value="Chemical Tank">Chemical Tank</MenuItem>
                                                    <MenuItem value="Conestoga Trailer">Conestoga Trailer</MenuItem>
                                                    <MenuItem value="Dolly">Dolly</MenuItem>
                                                    <MenuItem value="Double Drop Deck">Double Drop Deck</MenuItem>
                                                    <MenuItem value="Dry Van">Dry Van</MenuItem>
                                                    <MenuItem value="Dump Trailer">Dump Trailer</MenuItem>
                                                    <MenuItem value="Extendable Lowboy">Extendable Lowboy</MenuItem>
                                                    <MenuItem value="Flatbed Truck">Flatbed Truck</MenuItem>
                                                    <MenuItem value="Food Grade Tank">Food Grade Tank</MenuItem>
                                                    <MenuItem value="Horse-trailer">Horse-trailer</MenuItem>
                                                    <MenuItem value="Livestock Trailer">Livestock Trailer</MenuItem>
                                                    <MenuItem value="Lowboy">Lowboy</MenuItem>
                                                    <MenuItem value="Mega-trailer">Mega-trailer</MenuItem>
                                                    <MenuItem value="Motorcycle Trailer">Motorcycle Trailer</MenuItem>
                                                    <MenuItem value="Petroleum Tank">Petroleum Tank</MenuItem>
                                                    <MenuItem value="Reefer">Reefer</MenuItem>
                                                    <MenuItem value="Refrigerated (Reefer)">Refrigerated (Reefer)</MenuItem>
                                                    <MenuItem value="Refrigerator Truck">Refrigerator Truck</MenuItem>
                                                    <MenuItem value="Semi-trailer">Semi-trailer</MenuItem>
                                                    <MenuItem value="Standard Flatbed">Standard Flatbed</MenuItem>
                                                    <MenuItem value="Standard Lowboy">Standard Lowboy</MenuItem>
                                                    <MenuItem value="Step Deck (Drop Deck)">Step Deck (Drop Deck)</MenuItem>
                                                </TextField>
                                            </ListItem>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        {/* <ListItemIcon><WifiIcon /></ListItemIcon> */}

                                        <ListItemText primary="Year" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('year')}
                                            checked={checked.indexOf('year') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-wifi'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('year') !== -1 ? (
                                        <>
                                            <ListItem>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        className="text-input"
                                                        openTo="day"
                                                        views={['year']}
                                                        label="Year"
                                                        value={yearFilter}
                                                        onChange={yearHandler}
                                                        renderInput={(params) => (
                                                            <TextField className="text-input" color="orange" fullWidth {...params} error={null} />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </ListItem>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        {/* <ListItemIcon>
                                                <BluetoothIcon />
                                            </ListItemIcon> */}
                                        <ListItemText id="switch-list-label-bluetooth" primary="Status" />
                                        <Switch
                                            edge="end"
                                            onChange={handleChecked('status')}
                                            checked={checked.indexOf('status') !== -1}
                                            inputProps={{
                                                'aria-labelledby': 'switch-list-label-bluetooth'
                                            }}
                                        />
                                    </ListItem>
                                    {checked.indexOf('status') !== -1 ? (
                                        <ListItem>
                                            <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="flex-start">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('active')}
                                                                checked={statusChecked.indexOf('active') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="Active"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={handleStatus('inactive')}
                                                                checked={statusChecked.indexOf('inactive') !== -1}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label="Inactive"
                                                    />
                                                </FormGroup>
                                            </Stack>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}

                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={20}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    popupState.close();
                                                    reset();
                                                }}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }}
                                                variant="contained"
                                                color="orange"
                                                onClick={() => {
                                                    filterHandler();
                                                    popupState.close();
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </Stack>
                                    </ListItem>
                                    <Divider variant="middle" component="li" />
                                </List>
                            </Box>
                        </MainCard>
                    </Popover>
                </>
            )}
        </PopupState>
    );
}

TrailerFilter.propTypes = {
    handleChecked: PropTypes.func,
    trailerTypeHandler: PropTypes.func,
    filterHandler: PropTypes.func,
    yearFilter: PropTypes.func,
    yearHandler: PropTypes.func,
    trailerType: PropTypes.any,
    handleStatus: PropTypes.any,
    statusChecked: PropTypes.func,
    reset: PropTypes.func,
    checked: PropTypes.any
};
export default TrailerFilter;
