// project imports
import { Box } from '@mui/system';
import PageLayout from 'layout/PageLayout';

import MainCard from 'ui-component/cards/MainCard';
import 'ui-component/font/fonts';
import { createTheme } from '@mui/material/styles';
import AuditLogs from 'ui-component/auditLog/forms/AuditLogs';
// ==============================|| SAMPLE PAGE ||============================== //
const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontWeight: 400,
                    color: '#142953',
                    minHeight: '36px',
                    padding: '8px 10px',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#EB812E',
                        borderRadius: '4px',
                        borderBottom: 'none',
                        lineHeight: 1,

                        display: 'block',
                        '&:before': {
                            content: '"|"',
                            width: '6px',
                            height: '16px',
                            fontSize: '16px',
                            fontWeight: 400,
                            marginRight: '5px',
                            color: 'white',
                            textTransform: 'capitalize'
                        }
                    }
                },
                indicator: {
                    display: 'none' // Hide the tab indicator/border
                }
            }
        }
    }
});

const AuditLog = () => {

    return (
        <MainCard>
            <PageLayout title="Audit Log">
                <Box sx={{ border: 'none', backgroundColor: 'transparent' }}>
                <AuditLogs />
                </Box>
            </PageLayout>
        </MainCard>
    );
};

export default AuditLog;
