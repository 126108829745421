import { Skeleton, TableRow } from '@mui/material';

function TableRowAnimation({ column, row }) {
    const columns = column || 8;
    const rows = row || 1;

    return (
        <>
            {[...Array(rows)].map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                    {[...Array(columns)].map((_, colIndex) => (
                        <th key={colIndex} style={{ height: 40 }}>
                            <Skeleton variant="text" style={{ width: '100%', height: '100%' }} />
                        </th>
                    ))}
                </TableRow>
            ))}
        </>
    );
}

export default TableRowAnimation;
