import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';

import { createRef } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, CircularProgress, Divider, Grid, Skeleton, TablePagination, TextField, Typography, useMediaQuery } from '@mui/material';
import { Box, Stack, useTheme } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';

const AllSettlementViewForm = ({ startDate, endDate, driver, stlmId }) => {
    const tableRef = createRef();
    const [loading, setLoading] = useState(false);
    const [settlementData, setSettlementData] = useState('');
    const [fuelData, setFuelData] = useState();
    const [driverId, setDriverId] = useState('');
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const [selected, setSelected] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    useEffect(() => {
        if (stlmId && !settlementData) {
            fetchSettlement();
        }

        if (stlmId && settlementData && !fuelData) {
            fetchFuelData();
        }

        if (stlmId && settlementData && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }, [settlementData, stlmId, fuelData]);

    const fetchSettlement = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/accounting/stlm/view/${stlmId}`, config);
            setDriverId(data.stlm_data.driver_id);
            setSettlementData(data.stlm_data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            // console.log(error);
        }
    };

    const fetchFuelData = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/accounting/fuel-reports/?page=1&page_size=5&stlm_id=${stlmId}`, config);
            // setDriverId(data.stlm_data.driver_id);
            setFuelData(data.results);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            // console.log(error);
        }
    };

    const totalIncome =
        settlementData.drivers && settlementData.drivers ? settlementData.drivers.reduce((acc, item) => parseFloat(acc) + parseFloat(item.net_income), 0) : 0;
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd ">Load</th>
                    <th className="headerTd ">Pickup Date</th>
                    <th className="headerTd ">Delivery Date</th>
                    <th className="headerTd ">From</th>
                    <th className="headerTd ">To</th>
                    <th className="headerTd ">Revenue</th>
                    <th className="headerTd ">Driver Name</th>
                    <th className="headerTd ">Customer Name</th>
                </tr>
            </thead>
        );
    };
    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.load}
                </td>
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.pickup.date}
                </td>
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.delivery.date}
                </td>
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.pickup.location}
                </td>
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.delivery.location}
                </td>
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.revenue}
                </td>
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.driver_name}
                </td>
                <td className="rowTr " style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.cust_name}
                </td>
            </tr>
        );
    };

    const renderFuelHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor">Date</th>
                    <th className="headerTd rightHidBor">Invoice</th>
                    <th className="headerTd rightHidBor">Total Gallons</th>
                    <th className="headerTd rightHidBor">Total Amount</th>
                    <th className="headerTd rightHidBor">Location</th>
                </tr>
            </thead>
        );
    };
    const renderFuelRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.transaction_date}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.invoice}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.total_gallons}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.total_amount}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.location_name}, {rowData.data.city_name} {rowData.data.state_name}
                </td>
            </tr>
        );
    };

    // const fetchData = async (query) => {
    //     setLoading(true);
    //     try {
    //         const url = `/v1/api/dispatch/load/list?page=${query.page + 1}&page_size=${query.pageSize}&company_id=${
    //             loginInfoFromStorage.company_id
    //         }&bol_status=&load_status=Delivered&invoice_status=&created_start=&created_end=&price_start=&price_end=&pickup_start=${
    //             settlementData ? settlementData.start_date : ''
    //         }&pickup_end=&delivery_start=&delivery_end=${settlementData ? settlementData.end_date : ''}&driver=${
    //             settlementData && settlementData.driver_id ? settlementData.driver_id : 0
    //         }`;

    //         const response = await fetch(url, {
    //             method: 'get',
    //             headers: new Headers({
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${loginInfoFromStorage.token}`
    //             })
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }

    //         const result = await response.json();
    //         setSelected(result.results || []);
    //         setTotalCount(result.count || 0);

    //         return {
    //             data: result.results,
    //             page: query.page,
    //             totalCount: result.count
    //         };
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         return {
    //             data: [],
    //             page: query.page,
    //             totalCount: 0
    //         };
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     if ((isMobile || isTablet) && !selected.length) {
    //         fetchData({ page: currPage, pageSize: cardPerPage })
    //             .then((result) => {
    //                 setSelected(result.data || []);
    //                 setTotalCount(result.totalCount || 0);
    //             })
    //             .catch((error) => console.error('Error fetching data:', error));
    //     }
    // }, [isMobile, isTablet]);

    const fetchData = async ({ page }) => {
        try {
            const response = await fetch(
                `/v1/api/dispatch/load/list?page=${page + 1}&page_size=100&company_id=${
                    loginInfoFromStorage.company_id
                }&bol_status=&load_status=Delivered&invoice_status=&created_start=&created_end=&price_start=&price_end=&pickup_start=${
                    settlementData ? settlementData.start_date : ''
                }&pickup_end=&delivery_start=&delivery_end=${settlementData ? settlementData.end_date : ''}&driver=${
                    settlementData && settlementData.driver_id ? settlementData.driver_id : 0
                }`,
                {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                    }
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setSelected(result.results);
            return {
                data: result.results,
                page: page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: page,
                totalCount: 0
            };
        }
    };
    // console.log(selected);

    useEffect(() => {
        if (isMobile || isTablet) {
            console.log('Fetching data...');

            fetchData({ page: 0 });
        }
    }, [isMobile, isTablet]);

    return (
        <>
            {loading ? (
                <Skeleton height="200px" />
            ) : (
                <>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                        <Typography variant="subtitle1"> Total Revenue</Typography>
                        <Typography variant="body2" color="green" sx={{ fontWeight: 'bold' }}>
                            {' '}
                            {settlementData && settlementData.total_revenue}
                        </Typography>
                    </Stack>
                    {settlementData &&
                        settlementData.stlm_expenses.map((i) => (
                            <div key={i.stlm_exp_item_id}>
                                <Divider sx={{ my: 1 }} />
                                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                                    <Typography variant="subtitle1">{i.exp_name}</Typography>
                                    <Typography variant="body1" color="error">
                                        {' '}
                                        {i.is_percent ? parseFloat((settlementData.total_revenue * i.amount) / 100).toFixed(2) : i.amount}
                                    </Typography>
                                </Stack>
                            </div>
                        ))}
                    <Divider sx={{ my: 1 }} />
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                        <Typography variant="subtitle1"> Net Income</Typography>
                        <Typography variant="body2" color="green" sx={{ fontWeight: 'bold' }}>
                            {' '}
                            {settlementData && settlementData.income}
                        </Typography>
                    </Stack>
                </>
            )}

            <Stack direction="row" sx={{ mt: 5 }} justifyContent="center" alignItems="center" spacing={2}>
                <Typography align="center" fontWeight={500} fontSize={18}>
                    Driver Salary
                </Typography>
            </Stack>

            <Divider sx={{ mt: 3 }} />
            {loading ? (
                <Skeleton height="200px" />
            ) : (
                <>
                    {isMobile ? (
                        settlementData &&
                        settlementData.drivers.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ clear: 'both' }} />

                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Driver : {driver.driver_name !== '' ? driver.driver_name : driver.owner_name}
                                    </Typography>
                                    {settlementData && settlementData.stlm_type == 'split' ? <TableCell align="center">{driver.percent} %</TableCell> : ''}

                                    {settlementData && settlementData.stlm_type == 'mileage' ? (
                                        <>
                                            <TableCell align="center">{driver.mileage} </TableCell>
                                            <TableCell align="center">{driver.mileage_multiplier} </TableCell>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    <Typography sx={{ marginBottom: '.5rem' }}>Income : {driver.income}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Deduction : {driver.deduction}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Reason : {driver.deduction_reason}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Bonus : {driver.bonus_amt}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Bonus Explanation : {driver.bonus_reason}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Reimbursement : {driver.rmb_amt}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Reimbursement Explanation : {driver.rmb_reason}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Net Income : {driver.net_income}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    ) : isTablet ? (
                        <>
                            {loading ? (
                                <>
                                    <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                        <CircularProgress />
                                    </Box>
                                </>
                            ) : (
                                settlementData &&
                                settlementData.drivers.map((driver, idx) => (
                                    <Card
                                        key={driver.driver_id}
                                        sx={{
                                            marginTop: '2rem',
                                            marginBottom: '1.5rem',
                                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                            borderRadius: '15px',
                                            backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                        }}
                                    >
                                        <CardContent>
                                            <Box sx={{ clear: 'both' }} />

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>
                                                    Driver : {driver.driver_name !== '' ? driver.driver_name : driver.owner_name}
                                                </Typography>
                                                {settlementData && settlementData.stlm_type == 'split' ? (
                                                    <TableCell align="center">{driver.percent} %</TableCell>
                                                ) : (
                                                    ''
                                                )}

                                                {settlementData && settlementData.stlm_type == 'mileage' ? (
                                                    <>
                                                        <TableCell align="center">{driver.mileage} </TableCell>
                                                        <TableCell align="center">{driver.mileage_multiplier} </TableCell>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                <Typography sx={{ marginBottom: '.5rem' }}>Income : {driver.income}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Deduction : {driver.deduction}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Reason : {driver.deduction_reason}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Bonus : {driver.bonus_amt}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Bonus Explanation : {driver.bonus_reason}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Reimbursement : {driver.rmb_amt}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Reimbursement Explanation : {driver.rmb_reason}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Net Income : {driver.net_income}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                ))
                            )}
                        </>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Driver</TableCell>
                                        {settlementData && settlementData.stlm_type == 'split' ? <TableCell align="center">Percentage</TableCell> : ''}
                                        {settlementData && settlementData.stlm_type == 'mileage' ? (
                                            <>
                                                <TableCell align="center">Mileage</TableCell>
                                                <TableCell align="center">Mileage Multiplier</TableCell>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        <TableCell align="center">Income</TableCell>
                                        <TableCell align="center">Deduction</TableCell>
                                        <TableCell align="center">Reason</TableCell>
                                        <TableCell align="center">Bonus</TableCell>
                                        <TableCell align="center">Bonus Explanation</TableCell>
                                        <TableCell align="center">Reimbursement</TableCell>
                                        <TableCell align="center">Reimbursement Explanation</TableCell>
                                        <TableCell align="right">Net Income</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {settlementData &&
                                        settlementData.drivers.map((item, index) => (
                                            <>
                                                <TableRow key={index} sx={{ border: 0 }}>
                                                    <TableCell component="tr" scope="row">
                                                        {item.driver_name !== '' ? item.driver_name : item.owner_name}
                                                    </TableCell>

                                                    {settlementData && settlementData.stlm_type == 'split' ? (
                                                        <TableCell align="center">{item.percent} %</TableCell>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {settlementData && settlementData.stlm_type == 'mileage' ? (
                                                        <>
                                                            <TableCell align="center">{item.mileage} </TableCell>
                                                            <TableCell align="center">{item.mileage_multiplier} </TableCell>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <TableCell align="center">{item.income}</TableCell>
                                                    <TableCell align="center">{item.deduction}</TableCell>
                                                    <TableCell align="center">{item.deduction_reason}</TableCell>
                                                    <TableCell align="center">{item.bonus_amt}</TableCell>
                                                    <TableCell align="center">{item.bonus_reason}</TableCell>
                                                    <TableCell align="center">{item.rmb_amt}</TableCell>
                                                    <TableCell align="center">{item.rmb_reason}</TableCell>
                                                    <TableCell align="right">{item.net_income}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </>
            )}
            {/* 
            <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={2}>
                <Typography>Gross Net Income : {totalIncome.toFixed(2)}</Typography>
            </Stack> */}

            <Stack direction="row" sx={{ mt: 5 }} justifyContent="center" alignItems="center" spacing={2}>
                <Typography align="center" fontWeight={500} fontSize={18}>
                    Onwer Income
                </Typography>
                <Divider sx={{ mt: 5 }} />
            </Stack>
            <Divider sx={{ mt: 3 }} />
            {loading ? (
                <Skeleton height="200px" />
            ) : (
                <>
                    {isMobile ? (
                        settlementData &&
                        settlementData.owner.map((item, idx) => (
                            <Card
                                key={idx}
                                sx={{
                                    mt: 3,
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ clear: 'both' }} />

                                    <Typography sx={{ marginBottom: '.5rem' }}>Owner : {item.owner_name !== '' ? item.owner_name : 0.0}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Deduction : {item.deduction}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Reason : {item.deduction_reason}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Net Income : {item.net_income}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    ) : isTablet ? (
                        <>
                            {loading ? (
                                <>
                                    <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                        <CircularProgress />
                                    </Box>
                                </>
                            ) : (
                                settlementData &&
                                settlementData.owner.map((item, idx) => (
                                    <Card
                                        key={idx}
                                        sx={{
                                            marginTop: '2rem',
                                            marginBottom: '1.5rem',
                                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                            borderRadius: '15px',
                                            backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                        }}
                                    >
                                        <CardContent>
                                            <Box sx={{ clear: 'both' }} />

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Owner : {item.owner_name !== '' ? item.owner_name : 0.0}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Deduction : {item.deduction}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography sx={{ marginBottom: '.5rem' }}>Reason : {item.deduction_reason}</Typography>
                                                <Typography sx={{ marginBottom: '.5rem' }}>Net Income : {item.net_income}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                ))
                            )}
                        </>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Owner</TableCell>
                                        <TableCell align="center">Deduction</TableCell>
                                        <TableCell align="center">Reason</TableCell>
                                        <TableCell align="right">Net Income</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {settlementData &&
                                        settlementData.owner.map((item, index) => (
                                            <>
                                                <TableRow key={index} sx={{ border: 0 }}>
                                                    <TableCell component="tr" scope="row">
                                                        {item.owner_name !== '' ? item.owner_name : 0.0}
                                                    </TableCell>
                                                    <TableCell align="center">{item.deduction}</TableCell>
                                                    <TableCell align="center">{item.deduction_reason}</TableCell>
                                                    <TableCell align="right">{item.net_income}</TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </>
            )}

            <Typography sx={{ mt: 5 }} align="center" fontWeight={500} fontSize={18}>
                Fuel Expenses
            </Typography>
            <Divider sx={{ mt: 3 }} />
            {loading ? (
                <Skeleton height="200px" />
            ) : (
                <>
                    {' '}
                    {isMobile || isTablet ? (
                        <>
                            {loading ? (
                                <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <h1>{fuelData}</h1>
                            )}
                        </>
                    ) : (
                        <MaterialTable
                            // tableRef={tableRef}
                            style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                            data={fuelData ? fuelData : []}
                            title="Fuel Expenses"
                            columns={[]}
                            icons={TABLE_ICONS}
                            components={{ Row: renderFuelRow, Header: renderFuelHeader }}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: <CustomNoRowsOverlay />
                                }
                            }}
                            options={{
                                sorting: true,
                                exportButton: false,

                                toolbar: false,
                                draggable: false,
                                columnResizable: true
                            }}
                        />
                    )}
                </>
            )}

            <Typography sx={{ mt: 5 }} align="center" fontWeight={500} fontSize={18}>
                Loads Delivered
            </Typography>
            <Divider sx={{ mt: 3 }} />
            {loading ? (
                <Skeleton height="200px" />
            ) : (
                <>
                    {isMobile ? (
                        selected &&
                        selected.map((item, idx) => (
                            <Card
                                key={idx}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                    // backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : 'rgba(221, 230, 255, 0.3)'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ clear: 'both' }} />
                                    <Typography variant="h6">Load : {item.load}</Typography>
                                    <Typography variant="h6">Pickup Date : {item.pickup.date}</Typography>
                                    <Typography variant="h6">Delivery Date : {item.delivery.date}</Typography>
                                    <Typography variant="h6">From : {item.pickup.location}</Typography>
                                    <Typography variant="h6">To : {item.delivery.location}</Typography>
                                    <Typography variant="h6">Revenue : {item.revenue}</Typography>
                                    <Typography variant="h6">Drvier Name : {item.driver_name}</Typography>
                                    <Typography variant="h6">Customer Name : {item.cust_name}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    ) : isTablet ? (
                        <>
                            {loading ? (
                                <>
                                    <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                        <CircularProgress />
                                    </Box>
                                </>
                            ) : (
                                selected &&
                                selected.map((item, idx) => (
                                    <Card
                                        key={idx}
                                        sx={{
                                            marginTop: '2rem',
                                            marginBottom: '1.5rem',
                                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                            borderRadius: '15px',
                                            backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                        }}
                                    >
                                        <CardContent>
                                            <Box sx={{ clear: 'both' }} />

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography variant="h6">Load : {item.load}</Typography>
                                                <Typography variant="h6">Pickup Date : {item.pickup.date}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography variant="h6">Delivery Date : {item.delivery.date}</Typography>
                                                <Typography variant="h6">From : {item.pickup.location}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography variant="h6">To : {item.delivery.location}</Typography>
                                                <Typography variant="h6">Revenue : {item.revenue}</Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                                    gap: '0.5rem',
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                <Typography variant="h6">Drvier Name : {item.driver_name}</Typography>
                                                <Typography variant="h6">Customer Name : {item.cust_name}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                ))
                            )}
                        </>
                    ) : (
                        // <MaterialTable
                        //     tableRef={tableRef}
                        //     style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                        //     data={fetchData}
                        //     // data={(query) =>
                        //     //     new Promise((resolve, reject) => {
                        //     //         fetch(
                        //     //             `/v1/api/dispatch/load/list?page=1&page_size=100&company_id=${
                        //     //                 loginInfoFromStorage.company_id
                        //     //             }&bol_status=&load_status=Delivered&invoice_status=&created_start=&created_end=&price_start=&price_end=&pickup_start=${
                        //     //                 settlementData ? settlementData.start_date : ''
                        //     //             }&pickup_end=&delivery_start=&delivery_end=${settlementData ? settlementData.end_date : ''}&driver=${
                        //     //                 settlementData && settlementData.driver_id ? settlementData.driver_id : 0
                        //     //             }`,
                        //     //             {
                        //     //                 method: 'get',
                        //     //                 headers: new Headers({
                        //     //                     'Content-Type': 'application/json',
                        //     //                     Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                        //     //                 })
                        //     //             }
                        //     //         )
                        //     //             .then((response) => {
                        //     //                 if (!response.ok) {
                        //     //                     throw new Error(`HTTP error! status: ${response.status}`);
                        //     //                 }
                        //     //                 return response.json();
                        //     //             })
                        //     //             .then((result) => {
                        //     //                 resolve({
                        //     //                     data: result.results,
                        //     //                     page: query.page,
                        //     //                     totalCount: result.count
                        //     //                 });
                        //     //             })
                        //     //             .catch((error) => {
                        //     //                 console.error('Error fetching data:', error);
                        //     //                 reject({
                        //     //                     data: [],
                        //     //                     page: query.page,
                        //     //                     totalCount: 0
                        //     //                 });
                        //     //             });
                        //     //     })
                        //     // }
                        //     title=""
                        //     columns={[]}
                        //     icons={TABLE_ICONS}
                        //     components={{ Row: renderRow, Header: renderHeader }}
                        //     localization={{
                        //         body: {
                        //             emptyDataSourceMessage: <CustomNoRowsOverlay />
                        //         }
                        //     }}
                        //     options={{
                        //         sorting: true,
                        //         exportButton: false,
                        //         toolbar: false,
                        //         draggable: false,
                        //         columnResizable: true
                        //     }}
                        // />

                        <MaterialTable
                            tableRef={tableRef}
                            style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    fetchData(query)
                                        .then((result) => {
                                            resolve(result);
                                        })
                                        .catch(() => {
                                            reject({
                                                data: [],
                                                page: query.page,
                                                totalCount: 0
                                            });
                                        });
                                })
                            }
                            title=""
                            columns={[]}
                            icons={TABLE_ICONS}
                            components={{ Row: renderRow, Header: renderHeader }}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: <CustomNoRowsOverlay />
                                }
                            }}
                            options={{
                                sorting: true,
                                exportButton: false,
                                toolbar: false,
                                draggable: false,
                                columnResizable: true
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};

AllSettlementViewForm.propTypes = {
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    driver: PropTypes.any
};
export default AllSettlementViewForm;
