import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router';
import 'ui-component/font/fonts';

import Bills from './components/Bills';
import Expenses from './components/Expenses';
import SalesReceipt from './components/SalesReceipt';
import Vendors from './components/Vendors';
import RecurringBill from './components/RecurringBill';
import user_types from 'constant/user_types';

const SubExternalExpenses = () => {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const params = new URLSearchParams(queryParams);

    const expenseTab = queryParams.get('page') || 'expenses';

    useEffect(() => {
        if (!queryParams.has('page')) {
            params.set('page', 'expenses');
            navigate({ search: `?${params.toString()}` }, { replace: true });
        }
    }, [queryParams, navigate, params]);

    const handleExpenseTabChange = (_event, newValue) => {
        params.set('page', newValue);
        params.delete('search');
        navigate({ search: `?${params.toString()}` });
    };

    // External expenses menu
    const expense_view = permissions.expense && permissions.expense.view ? true : false;
    const bill_view = permissions.bill && permissions.bill.view ? true : false;
    const recurringbill_view = permissions.recurringbill && permissions.recurringbill.view ? true : false;
    const vendor_view = permissions.vendor && permissions.vendor.view ? true : false;
    const salesreceipt_view = permissions.salesreceipt && permissions.salesreceipt.view ? true : false;

    return (
        <>
            {/* Expenses navigation bar */}

            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={expenseTab || 'expenses'}
                    onChange={handleExpenseTabChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{
                        '.MuiTabs-flexContainer': {
                            display: 'flex',
                            flexWrap: 'wrap',
                            mb: { xs: '1rem', sm: 0 }
                        },
                        '.MuiTabs-indicator': {
                            background: 'white'
                        }
                    }}
                >
                    {(expense_view || user_types.includes(user_type)) && <Tab value="expenses" label="Expenses" />}
                    {(bill_view || user_types.includes(user_type)) && <Tab value="bills" label="Bills" />}
                    {(recurringbill_view || user_types.includes(user_type)) && <Tab value="recurring-bill" label="Recurring Bill" />}
                    {(vendor_view || user_types.includes(user_type)) && <Tab value="vendors" label="Vendors" />}
                    {(salesreceipt_view || user_types.includes(user_type)) && <Tab value="sales-receipt" label="Sales Receipt" />}
                </Tabs>
            </Box>

            <OptionShouldRender value={expenseTab} />
        </>
    );
};

const OptionShouldRender = ({ value }) => {
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const expense_view = permissions.expense && permissions.expense.view ? true : false;
    const bill_view = permissions.bill && permissions.bill.view ? true : false;
    const recurringbill_view = permissions.recurringbill && permissions.recurringbill.view ? true : false;
    const vendor_view = permissions.vendor && permissions.vendor.view ? true : false;
    const salesreceipt_view = permissions.salesreceipt && permissions.salesreceipt.view ? true : false;

    switch (value) {
        case 'expenses':
            return (expense_view || user_types.includes(user_type)) && <Expenses />;
        case 'bills':
            return (bill_view || user_types.includes(user_type)) && <Bills />;
        case 'vendors':
            return (vendor_view || user_types.includes(user_type)) && <Vendors />;
        case 'sales-receipt':
            return (salesreceipt_view || user_types.includes(user_type)) && <SalesReceipt />;
        case 'recurring-bill':
            return (recurringbill_view || user_types.includes(user_type)) && <RecurringBill />;
        default:
            return expense_view || user_types.includes(user_type) ? (
                <Expenses />
            ) : bill_view || user_types.includes(user_type) ? (
                <Bills />
            ) : vendor_view || user_types.includes(user_type) ? (
                <Vendors />
            ) : salesreceipt_view || user_types.includes(user_type) ? (
                <SalesReceipt />
            ) : recurringbill_view || user_types.includes(user_type) ? (
                <RecurringBill />
            ) : null;
    }
};

export default SubExternalExpenses;
